import gql from "graphql-tag";

export const INTEGRATION_SETTINGS = gql`
  query($integrationName: String!) {
    integrationSettings(integrationName: $integrationName) {
      integrationName
      integrationSettings
    }
  }
`;
