import React from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProposalProductsCardTypeChip } from "../../../ProposalProductsCardTypeChip";

interface Props {
  logo?: string;
  proposalProduct: ProposalProduct;
  onOpenProductCatalogDialog: VoidFunction;
}

export const CardHeader = ({ logo, proposalProduct, onOpenProductCatalogDialog }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.titleContainer}>
      <Box className="title">
        <IconButton className="dndIcon" size="large">
          <DragHandleIcon style={{ color: "black" }} />
        </IconButton>

        {logo && <img className={classes.productLogo} src={logo} alt="Proposal product logo" />}

        <Box className="titleWithVendorName">
          <Typography className="productName" variant="h6">
            {proposalProduct.title}

            {proposalProduct.aftermarketProduct?.productCategory && (
              <ProposalProductsCardTypeChip
                productCategory={proposalProduct.aftermarketProduct.productCategory}
                productType={proposalProduct.aftermarketProduct.productType}
              />
            )}
          </Typography>

          {proposalProduct.aftermarketProduct?.aftermarketVendorApiChannel && (
            <Typography className="productName" variant="body2">
              {proposalProduct.aftermarketProduct?.aftermarketVendorApiChannel}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Tooltip title="Click to see product details">
          <IconButton onClick={onOpenProductCatalogDialog} size="large">
            <InfoOutlined color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  titleContainer: {
    gap: "6px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",

    "& .title": {
      gap: "0.5rem",
      display: "flex",
      alignItems: "center",

      "& .titleWithVendorName": {
        display: "flex",
        flexDirection: "column",

        "& .productName > .MuiChip-root": {
          marginBottom: "3px",
          marginLeft: "0.5rem",
        },
      },
    },
  },
  productLogo: {
    width: "36px",
    height: "36px",
  },
});
