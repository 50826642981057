import React, { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Skeleton } from "@mui/material";
import { Paper, Box, Typography, Button, ButtonProps, PaperProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ContentViewType } from "../../../global";
import { isDesktop, isMobile } from "../../../utils/contentViewType";

interface Props extends Omit<PaperProps, "title"> {
  title?: ReactNode;
  subTitle?: string;
  isLoading?: boolean;
  actionBtn?: ReactNode;
  containerClassName?: string;
  contentViewType?: ContentViewType;
  actionBtnProps?: ButtonProps & { label: string; isHidden?: boolean };
}

export const CardContainer = ({
  title,
  subTitle,
  isLoading,
  children,
  actionBtn,
  actionBtnProps,
  containerClassName,
  contentViewType = ContentViewType.Desktop,
  ...paperProps
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.container, containerClassName)} {...paperProps}>
      <Box
        className={clsx(classes.header, {
          [classes.headerDesktop]: isDesktop(contentViewType),
          [classes.headerMobile]: isMobile(contentViewType),
        })}>
        {typeof title === "string" ? (
          <Typography component="span" variant="h6">
            {title}
          </Typography>
        ) : (
          title
        )}

        {actionBtn || (
          <>
            {actionBtnProps && !actionBtnProps?.isHidden && (
              <Button size="small" {...actionBtnProps}>
                {actionBtnProps.label}
              </Button>
            )}
          </>
        )}
      </Box>
      {subTitle && (
        <Typography component="span" variant="subtitle2" color="textSecondary">
          {subTitle}
        </Typography>
      )}
      <Box sx={{ height: "100%" }}>
        {isLoading ? <Skeleton variant="rectangular" height="100%" width="100%" /> : children}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "16px",
    height: "100%",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerDesktop: {
    flexDirection: "row",
  },
  headerMobile: {
    gap: "8px",
    flexDirection: "column",
  },
});
