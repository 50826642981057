import React, { ReactNode, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GET_PROPOSAL_MENUS_WITH_OPTIONS, GET_PROPOSAL_PRODUCTS } from "modules/aftermarketMenuConstructor/api";
import { ProposalProductsResponse, ProposalsDataResponse } from "modules/aftermarketMenuConstructor/api/types";
import { AddEditMenuTemplateUrlParams, MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { FormProvider, useForm } from "react-hook-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AddEditMenuOptionDialog, SaveAsConfirmationDialog, ShareMenuConfirmationDialog } from "../../dialogs";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { ModalsKeys } from "../../../../../global";
import { ProductCatalogDialog, ProposalProductsDialog } from "modules/desking";
import { ModalsContainer } from "../../../../../components/shared/Modals";
import { GeneralInformationCard, HeaderCard, ProductsCard, MenuOptionsCard } from "../../components";
import { ConstructorPricingInfoDialog } from "modules/desking/ui/dialogs/ProposalProducts/ConstructorPricingInfoDialog";

export const AddEditMenuTemplatePage = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading);
  const isMenuSectionCollapsed = useSelector((state: any) =>
    menuConstructorSelectors.isSectionCollapsed(state, "menu")
  );

  const { hasWriteAccess, hasReadOnlyAccess } = useAftermarketPermissions();

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.AftermarketAddEditMenuOptionDialog]: <AddEditMenuOptionDialog />,
    [ModalsKeys.ProposalProductsDialog]: <ProposalProductsDialog />,
    [ModalsKeys.ProductCatalogDialog]: <ProductCatalogDialog />,
    [ModalsKeys.ConstructorPricingInfoDialog]: <ConstructorPricingInfoDialog />,
    [ModalsKeys.AftermarketSaveAsDialog]: <SaveAsConfirmationDialog />,
    [ModalsKeys.AftermarketShareMenuConfirmationDialog]: <ShareMenuConfirmationDialog />,
  };

  const { proposalMenuId } = useParams<AddEditMenuTemplateUrlParams>();

  const form = useForm<MenuTemplateGeneralFormInputs>({
    defaultValues: {
      name: "",
      description: "",
      partnerLinksIdsToShareMenu: [],
      linkedToCriterias: [],
    },
  });

  const { loading: proposalMenuLoading } = useQuery<ProposalsDataResponse>(GET_PROPOSAL_MENUS_WITH_OPTIONS, {
    skip: !proposalMenuId,
    variables: { input: { proposalMenuId } },
    fetchPolicy: "no-cache",
    onCompleted(response) {
      if (!Array.isArray(response?.proposalMenus)) return;
      const proposalMenu = response.proposalMenus[0];
      dispatch(menuConstructorActions.setMenu(proposalMenu));
      dispatch(menuConstructorActions.setInitialMenu(proposalMenu));
      form.reset({
        name: proposalMenu.name,
        description: proposalMenu.description,
        partnerLinksIdsToShareMenu: proposalMenu.linkedToCriterias?.map(
          linkedToCriteria => linkedToCriteria.partnerLinkId
        ),
        linkedToCriterias: proposalMenu.linkedToCriterias?.map(linkedToCriteria => ({
          partnerLinkId: linkedToCriteria.partnerLinkId,
          isReadOnly: linkedToCriteria.isReadOnly,
        })),
      });
    },
  });

  useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    onCompleted(response) {
      if (!Array.isArray(response?.proposalProducts)) return;
      dispatch(menuConstructorActions.setProducts(response.proposalProducts));
    },
  });

  useEffect(() => {
    dispatch(menuConstructorActions.setIsLoading(proposalMenuLoading));
  }, [proposalMenuLoading]);

  useEffect(
    () => () => {
      dispatch(menuConstructorActions.clearState());
    },
    [dispatch]
  );

  return (
    <>
      {hasWriteAccess || hasReadOnlyAccess ? (
        <DndProvider backend={HTML5Backend}>
          <FormProvider {...form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <HeaderCard />
              </Grid>

              <Grid item xs={12} className={clsx({ [classes.skeleton]: isLoading })}>
                <GeneralInformationCard />
              </Grid>

              {isMenuSectionCollapsed && (
                <Grid item xs={6} className={clsx({ [classes.skeleton]: isLoading })}>
                  <ProductsCard />
                </Grid>
              )}

              <Grid item xs={!isMenuSectionCollapsed ? 12 : 6} className={clsx({ [classes.skeleton]: isLoading })}>
                <MenuOptionsCard />
              </Grid>
            </Grid>

            <ModalsContainer modalsWithKeys={modalsWithKeys} />
          </FormProvider>
        </DndProvider>
      ) : (
        <h3>Access Denied</h3>
      )}
    </>
  );
};

const useStyles = makeStyles({
  skeleton: {
    height: 150,
  },
});
