import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const ButtonAction = ({ fact, disabled, label, onClick, tooltip }: EquipmentDynamicFieldsProps) => (
  <Box style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Tooltip title={tooltip ? tooltip : ""}>
      <Button
        variant="contained"
        disabled={disabled}
        onClick={() => {
          onClick?.(fact);
        }}>
        {label}
      </Button>
    </Tooltip>
  </Box>
);
