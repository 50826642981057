import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close, PriorityHigh } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import _ from "lodash";
import formatString from "format-string-by-pattern";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import Check from "@mui/icons-material/Check";

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
}));

const RESEND_REGISTRATION_EMAIL = gql`
  mutation SendLenderUserInvitationEmail($email: String, $firstName: String, $lenderProfileId: String) {
    sendLenderUserInvitationEmail(email: $email, firstName: $firstName, lenderProfileId: $lenderProfileId)
  }
`;

const LocationsTree = ({ locations, selectedLocationIds, onChange, className, disabled, onFocus, onBlur }) => {
  const options = _.map(locations, location => {
    return {
      ...location,
      checked: _.includes(selectedLocationIds, location.locationId),
    };
  });
  const tree = buildTree(options);
  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: onChange,
    mode: "multiSelect",
    texts: _.some(options, "checked") ? {} : { placeholder: "Choose Locations" }, // hotfix - to show placeholder
  });

  return (
    <div className={className} onClick={e => e.stopPropagation()}>
      <LocationsTreeViewDropdown
        tree={tree}
        treeSelectOptions={treeSelectOptions}
        disabled={disabled}
        onFocus={e => onFocus(e)}
        onBlur={e => onBlur(e)}
      />
    </div>
  );
};

const ContactModal = ({
  contact,
  open,
  handleClose,
  create,
  edit,
  addLenderProfileUser,
  updateLenderProfileUser,
  removeLenderProfileUser,
  isPrimaryContact,
  lenderProfileId,
  locations,
  refetchContacts,
  territoryManagement,
  lenderContacts,
}) => {
  const classes = styles();
  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);
  const [loading, setLoading] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [removingContact, setRemovingContact] = useState(false);
  const [invitationResponse, setInvitationResponse] = useState(null);
  const [modalContact, setModalContact] = useState({});
  const [receivesNotifications, setReceivesNotifications] = useState(true);
  const [sharedInbox, setSharedInbox] = useState(false);
  const [existingEmailError, setExistingEmailError] = useState(false);
  const [height, setHeight] = useState(null);
  let disableEdit =
    (userProfile.vendorContactRole !== "credit_mgr" && userProfile.vendorContactRole !== "admin") || (!create && !edit);

  const [resendRegistrationEmail] = useMutation(RESEND_REGISTRATION_EMAIL, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (contact) {
      setModalContact({ ...contact });
      setReceivesNotifications(!!contact.receivesNotifications);
      setSharedInbox(!!contact.notificationContact);

      if (contact.accessDealerLocationIds) {
        setSelectedLocationIds(contact.accessDealerLocationIds);
      }
    }
  }, [contact]);

  const onClose = () => {
    handleClose();
    setTimeout(() => {
      setModalContact({});
      setSharedInbox(false);
      setReceivesNotifications(true);
      setSelectedLocationIds([]);
      setInvitationResponse(null);
    }, 500);
  };

  const handleResendInvitation = async () => {
    setLoading(true);

    let response = await resendRegistrationEmail({
      variables: {
        email: modalContact.email,
        firstName: modalContact.firstName,
        lenderProfileId,
      },
    });

    if (!response?.data?.sendLenderUserInvitationEmail) {
      setTimeout(() => {
        setInvitationResponse("error");
        setLoading(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setInvitationResponse("success");
        setLoading(false);
      }, 2000);
    }
  };

  const handleSubmit = async () => {
    if (_.isEmpty(modalContact)) {
      return;
    }

    setLoading(true);

    if (create) {
      await addLenderProfileUser({
        variables: {
          input: {
            lenderProfileId,
            lenderUserConnectionMatch: {
              email: sharedInbox ? undefined : modalContact.email,
              firstName: sharedInbox ? undefined : modalContact.firstName,
              lastName: sharedInbox ? undefined : modalContact.lastName,
              phoneNumber: sharedInbox ? undefined : modalContact.phoneNumber,
              title: sharedInbox ? undefined : modalContact.title,
            },
            receivesNotifications,
            notificationContact: sharedInbox ? modalContact.email : undefined,
            accessDealerLocationIds: selectedLocationIds || [],
          },
        },
      });
    } else {
      await updateLenderProfileUser({
        variables: {
          input: {
            lenderProfileUserId: contact.lenderProfileToUserId,
            accessDealerLocationIds: selectedLocationIds || [],
            receivesNotifications,
            firstName: modalContact.firstName,
            lastName: modalContact.lastName,
            phoneNumber: modalContact.phoneNumber,
            title: modalContact.title,
          },
        },
      });
    }
    refetchContacts();
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm">
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
        <h4>
          <b>Contact</b>
        </h4>
        <IconButton className={classes.closeBtn} onClick={onClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      {loading ? (
        <DialogContent
          style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: 320 }}>
          <CircularProgress size={72} />
        </DialogContent>
      ) : (
        <>
          {removingContact ? (
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 320,
              }}>
              <h4>Are you sure you want to remove this contact?</h4>
              <div style={{ display: "flex", gap: 4, marginTop: 24 }}>
                <Button color="primary" onClick={() => setRemovingContact(false)}>
                  No
                </Button>
                <Button
                  onClick={async () => {
                    setLoading(true);
                    await removeLenderProfileUser({
                      variables: {
                        lenderProfileToUserId: contact.lenderProfileToUserId,
                      },
                    });
                    refetchContacts();
                    setRemovingContact(false);
                    setLoading(false);
                    onClose();
                  }}>
                  Yes
                </Button>
              </div>
            </DialogContent>
          ) : (
            <>
              {invitationResponse ? (
                <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {invitationResponse === "error" ? (
                    <>
                      <PriorityHigh style={{ fontSize: 64, color: "orange" }} />
                      <h4>Something went wrong.</h4>
                    </>
                  ) : (
                    <>
                      <Check style={{ fontSize: 64, color: "#4caf50" }} />
                      <h4>Invitation Sent.</h4>
                    </>
                  )}
                </DialogContent>
              ) : (
                <DialogContent style={{ height: height }}>
                  {!sharedInbox && (
                    <div style={{ display: "flex", width: "100%", gap: 12 }}>
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label={"First Name"}
                        required={true}
                        value={modalContact?.firstName || contact?.firstName}
                        style={{ width: "100%" }}
                        onChange={e => setModalContact({ ...modalContact, firstName: e.target.value })}
                        variant="outlined"
                        disabled={disableEdit || isPrimaryContact || contact?.status === "active"}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label={"Last Name"}
                        required={true}
                        value={modalContact?.lastName || contact?.lastName}
                        onChange={e => setModalContact({ ...modalContact, lastName: e.target.value })}
                        style={{ width: "100%" }}
                        variant="outlined"
                        disabled={disableEdit || isPrimaryContact || contact?.status === "active"}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </div>
                  )}
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    label={"Title"}
                    required={true}
                    value={modalContact?.title || contact?.title}
                    onChange={e => setModalContact({ ...modalContact, title: e.target.value })}
                    style={{ width: "100%" }}
                    variant="outlined"
                    disabled={disableEdit || isPrimaryContact || contact?.status === "active"}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    label={"Email"}
                    required={true}
                    value={modalContact?.email || contact?.email || contact?.notificationContact}
                    onChange={e => {
                      if (lenderContacts.filter(c => c.email === e.target.value).length > 0) {
                        setExistingEmailError(true);
                      } else {
                        setExistingEmailError(false);
                      }
                      setModalContact({ ...modalContact, email: e.target.value });
                    }}
                    style={{ width: "100%" }}
                    variant="outlined"
                    disabled={disableEdit || !create || isPrimaryContact || contact?.status === "active"}
                    error={existingEmailError}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                  {!sharedInbox && (
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      label={"Phone Number"}
                      required={true}
                      value={modalContact?.phoneNumber || contact?.phoneNumber}
                      style={{ width: "100%" }}
                      variant="outlined"
                      disabled={disableEdit || isPrimaryContact || contact?.status === "active"}
                      slotProps={{
                        htmlInput: {
                          onChange: e =>
                            setModalContact({
                              ...modalContact,
                              phoneNumber: formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, "")),
                            }),
                        },

                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                  {/* <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                    <InputLabel
                      id={`dealer-reps-select-label`}
                      className={classes.selectLabel}
                      disabled={disableEdit}
                      // error={fieldsEmpty}
                      shrink={true}
                    >
                      Connected Reps
                    </InputLabel>
                    <Select
                      labelId={`dealer-reps-select-label`}
                      id={`dealer-reps-select`}
                      value={modalContact.accessDealerUserIds || []}
                      multiple
                      label="Connected Reps"
                      className={classes.selectStyles}
                      onChange={e => setModalContact({ ...modalContact, accessDealerUserIds: e.target.value })}
                      disabled={disableEdit}
                      renderValue={val =>
                        _.filter(account?.contacts, c => val.includes(c.id))
                          .map(c => c.fullName)
                          .join(", ")
                      }
                    >
                      {account?.contacts
                        ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
                        .map(contact => (
                          <MenuItem key={contact.id} value={contact.id}>
                            <Checkbox checked={!!modalContact?.accessDealerUserIds?.includes(contact.id)} />
                            {contact.fullName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  {territoryManagement && (
                    <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                      <InputLabel id={`dealer-reps-select-label`} style={{ fontSize: 12, transform: "none" }}>
                        Territory
                      </InputLabel>
                      <LocationsTree
                        className={classes.locationTree}
                        locations={locations}
                        selectedLocationIds={selectedLocationIds}
                        onChange={obj => {
                          if (obj.checked) setSelectedLocationIds([...selectedLocationIds, obj.value]);
                          else setSelectedLocationIds(selectedLocationIds.filter(id => id !== obj.value));
                        }}
                        disabled={disableEdit}
                        onFocus={() => setHeight(500)}
                        onBlur={() => setHeight(null)}
                      />
                    </FormControl>
                  )}
                  {!disableEdit && !isPrimaryContact && !removingContact && (
                    <div style={{ display: "flex" }}>
                      {!sharedInbox && (
                        <Tooltip title="Send credit submission notifications to this contact?">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              padding: "16px 0",
                            }}>
                            <label
                              style={{
                                marginRight: 4,
                                color: receivesNotifications ? "rgba(0,0,0,0.85)" : "",
                                fontWeight: 400,
                              }}>
                              {receivesNotifications ? "Notifications Enabled" : "Notifications Disabled"}
                            </label>
                            <Switch
                              value={receivesNotifications}
                              defaultChecked={receivesNotifications}
                              onChange={() => setReceivesNotifications(!receivesNotifications)}
                              color="primary"
                            />
                          </div>
                        </Tooltip>
                      )}
                      {create && (
                        <Tooltip title="Send credit submissions notifications to a shared lender inbox? (No lender user registration notifications will be sent)">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              padding: "16px 0",
                            }}>
                            <label
                              style={{ marginRight: 4, color: sharedInbox ? "rgba(0,0,0,0.85)" : "", fontWeight: 400 }}>
                              {sharedInbox ? "Shared Inbox" : "Not Shared Inbox"}
                            </label>
                            <Switch value={sharedInbox} onChange={() => setSharedInbox(!sharedInbox)} color="primary" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </DialogContent>
              )}
            </>
          )}
        </>
      )}
      <DialogActions>
        {existingEmailError && (
          <p style={{ color: "#f44336", margin: "0 auto", fontWeight: 500 }}>
            A contact with this email already exists.
          </p>
        )}
        {!invitationResponse && !isPrimaryContact && !loading && (
          <>
            {!create && !disableEdit && !removingContact && (
              <Button style={{ color: "#f44336" }} onClick={() => setRemovingContact(true)} disabled={disableEdit}>
                Remove
              </Button>
            )}
            {!create && !disableEdit && (
              <Button color="primary" onClick={() => handleResendInvitation()} disabled={disableEdit}>
                Resend Invitation
              </Button>
            )}
            {!removingContact && (
              <Button color="primary" onClick={() => handleSubmit()} disabled={disableEdit || existingEmailError}>
                {create ? "Create" : "Update"}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ContactModal;
