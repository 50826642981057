import { useContext } from "react";
import { saleModeContext } from "./saleModeContext";

export function useSaleMode() {
  const context = useContext(saleModeContext);

  if (!context) throw new Error("Please use this hook in provider");

  return context;
}
