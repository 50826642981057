import React from "react";
import { Column } from "react-table";
import { DefaultTableCell } from "components/Table/DefaultTableCell";
import { VODocument } from "../../types";

export function createTableColumns(): Array<Column<VODocument>> {
  return [
    {
      sortable: false,
      Header: "File name",
      accessor: "docName",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Uploaded By",
      accessor: "createdBy",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Uploaded On",
      accessor: "createdDateTime",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Type",
      accessor: "docType",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Description",
      accessor: "docDescription",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
  ];
}
