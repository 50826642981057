import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FinanceProgramForm } from "../../../../schema";
import { QuillInputField } from "components/form";
import ReactQuill from "react-quill";

interface Props {
  isReadOnly: boolean;
}

export const InsuranceCoverageRequirements = ({ isReadOnly }: Props) => {
  const { control, getValues, setValue, watch } = useFormContext<FinanceProgramForm>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="span" variant="body1">
          Coverage Requirements:
        </Typography>
        <ReactQuill
          value={getValues("program.fifth.insuranceCoverage.coverageRequirements")}
          readOnly={isReadOnly}
          onChange={(value, delta, source, editor) => {
            setValue("program.fifth.insuranceCoverage.coverageRequirements", value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" variant="body1">
          Certificate Requirements:
        </Typography>
        <ReactQuill
          value={getValues("program.fifth.insuranceCoverage.certificateRequirements")}
          readOnly={isReadOnly}
          onChange={(value, delta, source, editor) => {
            setValue("program.fifth.insuranceCoverage.certificateRequirements", value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" variant="body1">
          Other Instructions:
        </Typography>
        <ReactQuill
          value={getValues("program.fifth.insuranceCoverage.otherInstructions")}
          readOnly={isReadOnly}
          onChange={(value, delta, source, editor) => {
            setValue("program.fifth.insuranceCoverage.otherInstructions", value);
          }}
        />
      </Grid>
    </Grid>
  );
};
