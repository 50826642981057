import { useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import makeStyles from "@mui/styles/makeStyles";
//Core Components
import Button from "components/CustomButtons/Button";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import Tile from "../../components/Tile/Tile";
import ActivityModal from "./ActivityModal";
import DragIndicator from "assets/img/drag-indicator.svg";
import TableSearch from "components/Table/TableSearch";

const UPSERT_ACTIVITY = gql`
  mutation ($input: UpsertActivityInput!) {
    upsertActivity(input: $input)
  }
`;
const QUERY_ACTIVITIES = gql`
  query ($dynamicsId: ID!) {
    activities(dynamicsId: $dynamicsId) {
      activityId
      createdBy
      activityStatus
      party
      notes
      fullName
      createdDateTime
    }
  }
`;

const styles = theme => ({
  root: {
    "& .MuiInput-underline:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  right: {
    textAlign: "right",
  },
  greenButton: {
    margin: 0,
    float: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
  },
  fixReactTable: {
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
});

const priorityColors = {
  urgent: "#bb3354",
  high: "#bb3354",
  medium: "#71589a",
  low: "#69a1bd",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

const useStyles = makeStyles(styles);

export default function ActivityList({ vo, account, userProfile, tileTitle, creditSubId }) {
  const classes = useStyles();
  const [activities, setActivities] = useState([]);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [activity, setActivity] = useState(null);
  const searchRef = React.useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [filteredActivities, setFilteredActivities] = useState([]);

  const {
    loading,
    error,
    data: activityData,
    refetch,
  } = useQuery(QUERY_ACTIVITIES, {
    context: { authRequired: true },
    variables: {
      dynamicsId: creditSubId,
    },
  });

  if (loading) console.log("Loading activities...");
  if (error) console.log(`Error! ${error.message}`);

  useEffect(() => {
    if (activityData) {
      setActivities(activityData.activities);
    }
  }, [activityData]);

  const filterActivities = activities => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return activities;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = activities.filter(el => {
      if (typeof el.fullName === "string" || typeof el.party === "string" || typeof el.notes === "string") {
        return (
          (el.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.party || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.notes || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  useEffect(() => {
    setFilteredActivities(filterActivities(activities || []));
  }, [activities, searchRef.current.currentSearch]);

  const [upsertActivity] = useMutation(UPSERT_ACTIVITY, {
    context: { authRequired: true },
  });

  const handleOpenActivityModal = () => {
    setOpenActivityModal(true);
  };

  const handleCloseActivityModal = () => {
    setOpenActivityModal(false);
    setActivityId(null);
    setActivity(null);
  };

  const handleAddActivity = async payload => {
    try {
      await upsertActivity({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveActivity = async payload => {
    try {
      await upsertActivity({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenActivity = value => {
    setActivityId(value.activityId);
    setActivity(value);
    handleOpenActivityModal();
  };

  return (
    <div className={classes.root}>
      {!!account && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tile
              title={!tileTitle ? "Activity" : tileTitle}
              useCollapsing={true}
              titleButtonComponent={
                <Button
                  className={classes.greenButton}
                  color="primary"
                  size={"sm"}
                  onClick={e => {
                    e.preventDefault();
                    handleOpenActivityModal();
                  }}>
                  Add
                </Button>
              }>
              {!!activities && activities && (
                <TableSearch
                  setSavedListSettings={setSavedListSettings}
                  searchRef={searchRef}
                  savedListSettings={savedListSettings}
                />
              )}
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.fixReactTable}>
                  {!!activities && activities ? (
                    <ReactTable
                      color="primary"
                      data={filteredActivities}
                      getTrProps={(state, rowInfo, column, instance) => ({
                        style: { cursor: "pointer" },
                        onClick: e => handleOpenActivity(rowInfo.original),
                      })}
                      filterable
                      columns={[
                        {
                          Header: "Created On",
                          accessor: "createdDateTime",
                          Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                          Filter: () => <></>,
                        },
                        {
                          Header: "Created By",
                          accessor: "fullName",
                          Filter: () => null,
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Party",
                          accessor: "party",
                          Filter: () => null,
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Notes",
                          accessor: "notes",
                          Filter: () => null,
                        },
                        {
                          Header: "Status",
                          accessor: "activityStatus",
                          Filter: () => null,
                        },
                      ]}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      customCellClasses={[classes.right]}
                      customClassesForCells={[5]}
                      className="-striped -highlight"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Modal
                open={openActivityModal}
                onClose={handleCloseActivityModal}
                aria-labelledby="Activity Form"
                aria-describedby="Input">
                <div>
                  <ActivityModal
                    vo={vo}
                    handleClose={handleCloseActivityModal}
                    handleAddActivity={handleAddActivity}
                    handleSaveActivity={handleSaveActivity}
                    activityId={activityId}
                    activitiesData={activities}
                    creditSubId={creditSubId}
                    refetch={refetch}
                    open={openActivityModal}
                    activity={activity}
                  />
                </div>
              </Modal>
            </Tile>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
