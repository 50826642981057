import React from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { FormInputSelect } from "../../../../../components/form";
import { BaseModalProps, SelectOption } from "../../../../../global";

interface Props extends BaseModalProps {
  dealerValue: string;
  onConfirm: VoidFunction;
  dealerOptions: SelectOption[];
  onSelectChanged: (dealerId: string) => void;
}

export const AddDealerDialog = ({ isOpen, onClose, onConfirm, onSelectChanged, dealerOptions, dealerValue }: Props) => {
  const classes = useStyles();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box className={classes.title}>
          <Typography component="span" variant="h6">
            Add Dealer
          </Typography>

          <IconButton size="small" onClick={onClose}>
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box className={classes.content}>
          <FormInputSelect
            size="small"
            label="Dealer"
            variant="standard"
            value={dealerValue}
            options={dealerOptions}
            onChange={onSelectChanged}
            disabled={!dealerOptions?.length}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: "12px 0",
  },
});
