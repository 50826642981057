import gql from "graphql-tag";

export const Q_AFTERMARKET_PRODUCTS = gql`
  query GetAftermarketProducts($accountId: ID!) {
    aftermarketProducts(accountId: $accountId) {
      aftermarketProductId
      accountIdCreatedBy
      productName
      partnerLinks {
        name
        partnerLinkId
      }
      aftermarketVendorApiChannel
      productCategory
      productType
      criteria
      productDescriptionExternal
      productDescriptionInternal
      createdDateTime
      productLogoUrl
      addons {
        aftermarketProductId
        productName
      }
      externalId
      aftermarketProductCatalogDetails {
        productSummary
        limitationsOfLiability
        eligibilityAndInclusions
        legalDisclaimer
        paragraphDescription
        coverageDetails
      }
    }
  }
`;

export const Q_PARTNER_LINKS = gql`
  query GetDealerLinks($accountId: ID!) {
    aftermarketDealerPartnerLink(accountId: $accountId) {
      partnerLinkId
      name
      logo
      partnerProfile {
        name
      }
    }
  }
`;

export const M_CREATE_AFTERMARKET_PRODUCTS = gql`
  mutation CreateAftermarketProduct($input: CreateAftermarketProductInput!) {
    createAftermarketProduct(input: $input) {
      success
    }
  }
`;

export const M_UPDATE_AFTERMARKET_PRODUCTS = gql`
  mutation UpdateAftermarketProduct($input: UpdateAftermarketProductInput!) {
    updateAftermarketProduct(input: $input) {
      aftermarketProductId
      success
    }
  }
`;
