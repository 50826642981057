import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const SignatureselectField = ({ fieldConfig }) => {
  const fieldId = uuidv4();
  return (
    <FormControl fullWidth={fieldConfig.fullWidth}>
      <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
        {_.get(fieldConfig, "displayLabel.en", "")}
      </InputLabel>
      <Select
        fullWidth={fieldConfig.fullWidth}
        name={fieldConfig.fieldName}
        disabled={fieldConfig.disabled}
        value={""}
        labelId={fieldConfig.fieldName}
        style={{ maxHeight: "40vh", pointerEvents: "none" }}
        required={fieldConfig.required}
        variant="standard">
        {[].map((owner, index) => {
          return (
            <MenuItem key={`${fieldId}${index}`} value={`${index}`}>
              OPTION
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{_.get(fieldConfig, "helperText.en", "")}</FormHelperText>
    </FormControl>
  );
};

export { SignatureselectField };
