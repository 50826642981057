import React from "react";
import Conversation from "./Conversation";
import makeStyles from "@mui/styles/makeStyles";
const { Button, CircularProgress } = require("@mui/material");
const { Grid } = require("@mui/material");

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "20px",
    backgroundColor: "#fff",
    padding: "30px 25px 10px",
    borderRadius: "5px",
  },
  titleStyles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    fontSize: "26px",
    fontWeight: "300",
    color: "#555555",
  },
  tasksTitleText: {
    fontWeight: "500",
    textTransform: "uppercase",
    fontSize: "20px",
  },
  addButtonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: "300",
    fontSize: "12px",
    boxShadow: "0px 2px 2px rgba(55, 133, 244, 0.25)",
    borderRadius: "3px",
  },
  emptyConversations: {
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "26px",
    color: "#555555",
    height: "500px",
  },
  dialogContent: {
    padding: "10px 24px 26px",
  },
  dialogButton: {
    display: "flex",
    marginTop: "16px",
  },
  conversationsContainer: {},
}));

export const ConversationsHistory = ({ conversations, loading, onConversationAdd }) => {
  const classes = useStyles();

  let conversationsList = [];

  if (!loading) {
    conversationsList = conversations;
  }

  if (loading) {
    return (
      <Grid container className={classes.mainContainer}>
        <Grid container xs={12} sm={12} md={12} lg={12} className={classes.emptyConversations}>
          <CircularProgress size={80} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.mainContainer}>
      <Grid container xs={12} sm={12} md={12} lg={12} className={classes.titleStyles}>
        <div className={classes.titleText}>Conversations History</div>
        <Button
          className={classes.addButtonStyles}
          disabled={loading}
          onClick={() => {
            onConversationAdd && onConversationAdd();
          }}>
          ADD CONVERSATION
        </Button>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} className={classes.conversationsContainer}>
        {conversationsList.length === 0 ? (
          <Grid container xs={12} sm={12} md={12} lg={12} className={classes.emptyConversations}>
            You don't have any conversations
          </Grid>
        ) : (
          conversationsList.map((conversation, i) => (
            <Conversation
              conversation={conversation}
              key={i}
              isLastConversationInList={conversationsList.length - 1 === i}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};
