import React, { useEffect, useState } from "react";
import Button from "../../components/CustomButtons/Button.js";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import formatString from "format-string-by-pattern";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CardFooter from "../../components/Card/CardFooter";
import { getURLParameter } from "utils.js";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import TrnsactLogo from "assets/img/trnsact-logo-dark.png";
import InputLabel from "@mui/material/InputLabel";

const config = require("../../config");

const useStyles = makeStyles(styles);

const SIGNUP_USER_MUTATION = gql`
  mutation (
    $email: String!
    $firstName: String
    $lastName: String
    $title: String
    $phone: String
    $mobilePhone: String
  ) {
    signupUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        title: $title
        phone: $phone
        mobilePhone: $mobilePhone
      }
    ) {
      status
      message
    }
  }
`;

const GET_PARTNER = gql`
  query ($dynamicsId: ID) {
    partner(dynamicsId: $dynamicsId) {
      partnerProfileId
      partnerDynamicsProfileId
      partnerAccountId
      partnerAccountDynamicsId
      parentAccountId
      parentPartnerDynamicsId
      name
      partnerLogo
    }
  }
`;

function FormattedPhoneNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ###-####"
      mask="_"
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

export default function SignUp() {
  const classes = useStyles();
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const partnerId = getURLParameter("partnerId");
  const [openErrorPopup, setOpenErrorPopup] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);
  const [showDialogText, setShowDialogText] = React.useState(false);
  const [isValidValues, setValidValues] = useState(false);
  const [showDialogIcon, setShowDialogIcon] = React.useState(false);
  const [isMobileNumber, setMobileNumber] = useState(false);
  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const [values, setValues] = useState({
    firstName: null,
    lastName: null,
    title: null,
    email: null,
    phone: null,
    mobilePhone: null,
    companyName: null,
    submitSuccess: null,
    submitError: null,
  });

  const [partnerInfo, setPartnerInfo] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const queryContext = { authRequired: true };

  const handleAcceptedTerms = () => {
    if (termsAccepted) {
      setTermsAccepted(false);
    } else {
      setTermsAccepted(true);
    }
  };

  const isValidPhoneNumber = field => field && field.replace(/[^\d]/g, "").length < 10;

  const handleOpenTC = () => {
    setShowModal(true);
  };
  const handleCloseTC = () => {
    setShowModal(false);
  };

  const [requestSignupUser, { data, error, loading }] = useMutation(SIGNUP_USER_MUTATION, {
    context: { authRequired: false },
  });

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoading(false);
  };

  useEffect(() => {
    if (
      (values.firstName &&
        values.lastName &&
        values.email &&
        values.title &&
        values.companyName &&
        values.phone &&
        values.phone.replace(/[^\d]/g, "").length >= 10) ||
      (values.mobilePhone && values.mobilePhone.replace(/[^\d]/g, "").length >= 10)
    ) {
      setValidValues(true);
    } else {
      setValidValues(false);
    }
  }, [values]);

  const upperCaseFirstLetters = value => {
    let formattedValue = value.split("");

    return formattedValue
      .map((letter, i) => {
        if (i === 0 || formattedValue[i - 1] === " " || formattedValue[i - 1] === ".") {
          return letter.toUpperCase();
        }

        return letter.toLowerCase();
      })
      .join("");
  };

  const handleSubmit = event => {
    event.preventDefault();
    setOpenLoading(true);
    setOpenSuccessPopup(true);
    setOpenErrorPopup(true);

    requestSignupUser({
      variables: {
        email: values["email"],
        firstName: values["firstName"],
        lastName: values["lastName"],
        title: values["title"],
        phone: values["phone"] && values["phone"].replace(/[\D]+/g, ""),
        mobilePhone: isMobileNumber ? values["phone"] && values["phone"].replace(/[\D]+/g, "") : null,
      },
    });
  };

  const [getPartnerData, { data: partnerData, loading: partnerLoading, error: partnerError }] = useLazyQuery(
    GET_PARTNER,
    {
      context: { authRequired: false },
      variables: { dynamicsId: partnerId },
    }
  );

  useEffect(() => {
    if (partnerId) {
      getPartnerData();
      if (partnerData) {
        setPartnerInfo(partnerData.partner);
      }
    }
  }, [getPartnerData, partnerData]);

  function closePopupField() {
    if (!loading) {
      setOpenSuccessPopup(false);
      setOpenErrorPopup(false);
      setOpenLoading(false);
      setShowDialogText(false);
      setShowDialogIcon(false);
    }
  }

  useEffect(() => {
    if (!loading && (openErrorPopup || openSuccessPopup)) {
      setTimeout(() => setShowDialogText(true), 1000);
      setTimeout(() => setShowDialogIcon(true), 2000);
    }
  }, [loading, openErrorPopup, openSuccessPopup]);

  return (
    <div className={classes.container}>
      {!!partnerId && !!partnerInfo ? (
        <Grid container sx={{ justifyContent: "center" }}>
          <GridItem xs={12} sm={6}>
            <Grid container sx={{ justifyContent: "center" }} alignItems="center">
              <GridItem xs={12}>
                <Typography align="center" variant="h5">
                  WELCOME TO
                </Typography>
              </GridItem>
              {partnerInfo && (
                <GridItem>
                  <div className={classes.vendorLogoWrapper}>
                    <img
                      style={{
                        maxHeight: "200px",
                        maxWidth: "100%",
                      }}
                      src={
                        partnerInfo.partnerLogo && partnerInfo.partnerLogo.includes("/")
                          ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partnerInfo.partnerLogo}`
                          : `https://credit-app-images.s3-us-west-2.amazonaws.com/${partnerInfo.partnerLogo}`
                      }
                      alt="Partner Logo"
                    />
                  </div>
                </GridItem>
              )}
              <GridItem xs={12}>
                <Typography align="center" variant="h4">
                  DEALER PORTAL
                </Typography>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>1</span> Engage with Enhanced Leads from Construction Equipment
                  Guide
                </div>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>2</span> Enable Instant Reponse bot and two-way SMS with all Leads
                </div>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>3</span> View real-time status of all opportunties
                </div>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>4</span> Add your lenders and submit credit applications digitally
                </div>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>5</span> Enable Secure Digital Credit Application on your website
                </div>
              </GridItem>
              <div style={{ padding: "10px" }} />
              <GridItem xs={12}>
                <div className={classes.signUpPoint}>
                  <span className={classes.dcrPoints}>6</span> Access powerful reporting tools to analyze your sales
                  conversion
                </div>
              </GridItem>
              <div style={{ padding: "20px" }} />
              <GridItem xs={12}>
                <div style={{ textAlign: "center", fontSize: "16px" }}>
                  For assistance, please contact your CEG Account Manager,{" "}
                  <span style={{ fontWeight: "bold" }}>Chris Martin</span>, at (714) 689-9562 or{" "}
                  <a href="mailto:CMartin@trnsact.com">CMartin@trnsact.com</a>
                </div>
              </GridItem>
              <br />
              <br />
              <GridItem style={{ marginTop: "15px" }}>
                <div style={{ textAlign: "center" }}>Powered by:</div>
                <img src={TrnsactLogo} className={classes.poweredByTrnsactLogo} alt="Powered By Trnsact" />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              <Card login className={classes[cardAnimation]}>
                <CardBody style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                  <GridContainer>
                    <GridItem xs={12} style={{ margin: "25px 0 20px" }}>
                      <Typography variant="h5" align="center" className={classes.signUpHeader}>
                        Sign Up
                      </Typography>
                    </GridItem>
                    <GridItem lg={6} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>First Name</InputLabel>
                      <CustomInput
                        id="firstName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "firstName",
                          onChange: handleInputChange,
                          autoComplete: "fname",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={6} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Last Name</InputLabel>
                      <CustomInput
                        id="lastName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "lastName",
                          onChange: handleInputChange,
                          autoComplete: "lname",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Email Address</InputLabel>
                      <CustomInput
                        id="email"
                        lowerCase={true}
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "email",
                          type: "email",
                          onChange: handleInputChange,
                          autoComplete: "email",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Title</InputLabel>
                      <CustomInput
                        id="title"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "title",
                          onChange: handleInputChange,
                          autoComplete: "title",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Company Name</InputLabel>
                      <CustomInput
                        id="companyName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "companyName",
                          onChange: handleInputChange,
                          autoComplete: "companyName",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Phone Number</InputLabel>
                      <CustomInput
                        id="phone"
                        formControlProps={{ fullWidth: true, required: true }}
                        error={isValidPhoneNumber(values.phone)}
                        errorMessage={
                          isValidPhoneNumber(values.phone)
                            ? "Please ensure phone number has been entered correctly."
                            : null
                        }
                        inputProps={{
                          name: "phone",
                          value: values.phone,
                          onChange: e => {
                            const value = formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, ""));
                            handleInputChange({
                              target: { value, name: "phone" },
                            });
                          },
                          autoComplete: "phone",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={5} className={classes.phoneNumberCheckbox}>
                      <Grid container>
                        <GridItem className={classes.CheckboxContainer}>
                          <Grid container alignItems="center">
                            <Checkbox
                              className={classes.checkboxPhone}
                              color="primary"
                              checked={isMobileNumber}
                              onChange={() => {
                                setMobileNumber(prevData => !prevData);
                              }}
                            />
                            <span className={classes.checkboxLabel}>Mobile</span>
                          </Grid>
                        </GridItem>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem>
                      <Grid container alignItems="center">
                        <Checkbox
                          className={classes.checkbox}
                          label="Checkbox Label"
                          color="primary"
                          name="checkbox"
                          value="control"
                          onClick={() => handleAcceptedTerms()}
                        />
                        <div className={classes.acceptTerms}>
                          I agree with{" "}
                          <span className={classes.terms} onClick={() => handleOpenTC()}>
                            Terms & Conditions
                          </span>
                        </div>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.signUpButton}
                      disabled={!termsAccepted || !isValidValues}>
                      Sign Up
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </Grid>
      ) : (
        <Grid container sx={{ justifyContent: "center" }}>
          <GridItem xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              <Card login className={classes[cardAnimation]}>
                <CardBody style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                  <GridContainer>
                    <GridItem xs={12} style={{ margin: "25px 0 20px" }}>
                      <Typography variant="h5" align="center" className={classes.signUpHeader}>
                        Sign Up
                      </Typography>
                    </GridItem>
                    <GridItem lg={6} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>First Name</InputLabel>
                      <CustomInput
                        id="firstName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "firstName",
                          value: values.firstName,
                          onChange: e => {
                            const value = upperCaseFirstLetters(e.target.value);
                            handleInputChange({
                              target: { value, name: "firstName" },
                            });
                          },
                          autoComplete: "fname",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={6} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Last Name</InputLabel>
                      <CustomInput
                        id="lastName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "lastName",
                          value: values.lastName,
                          onChange: e => {
                            const value = upperCaseFirstLetters(e.target.value);
                            handleInputChange({
                              target: { value, name: "lastName" },
                            });
                          },
                          autoComplete: "lname",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Email Address</InputLabel>
                      <CustomInput
                        id="email"
                        lowerCase={true}
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "email",
                          type: "email",
                          onChange: handleInputChange,
                          autoComplete: "email",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Title</InputLabel>
                      <CustomInput
                        id="title"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "title",
                          onChange: handleInputChange,
                          autoComplete: "title",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Company Name</InputLabel>
                      <CustomInput
                        id="companyName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "companyName",
                          onChange: handleInputChange,
                          autoComplete: "companyName",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Phone Number</InputLabel>
                      <CustomInput
                        id="phone"
                        formControlProps={{ fullWidth: true, required: true }}
                        error={isValidPhoneNumber(values.phone)}
                        errorMessage={
                          isValidPhoneNumber(values.phone)
                            ? "Please ensure phone number has been entered correctly."
                            : null
                        }
                        inputProps={{
                          name: "phone",
                          value: values.phone,
                          onChange: e => {
                            const value = formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, ""));
                            handleInputChange({
                              target: { value, name: "phone" },
                            });
                          },
                          autoComplete: "phone",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={5} className={classes.phoneNumberCheckbox}>
                      <Grid container>
                        <GridItem className={classes.CheckboxContainer}>
                          <Grid container alignItems="center">
                            <Checkbox
                              className={classes.checkboxPhone}
                              color="primary"
                              checked={isMobileNumber}
                              onChange={() => {
                                setMobileNumber(prevData => !prevData);
                              }}
                            />
                            <span className={classes.checkboxLabel}>Mobile</span>
                          </Grid>
                        </GridItem>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem>
                      <Grid container alignItems="center">
                        <Checkbox
                          className={classes.checkbox}
                          label="Checkbox Label"
                          color="primary"
                          name="checkbox"
                          value="control"
                          onClick={() => handleAcceptedTerms()}
                        />
                        <div className={classes.acceptTerms}>
                          I agree with{" "}
                          <span className={classes.terms} onClick={() => handleOpenTC()}>
                            Terms & Conditions
                          </span>
                        </div>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.signUpButton}
                      disabled={!termsAccepted || !isValidValues}>
                      Sign Up
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </Grid>
      )}
      {loading && (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={openLoading}
            autoHideDuration={6000}
            onClose={handleLoadingSnackbarClose}>
            <Alert onClose={handleLoadingSnackbarClose} severity="info">
              Loading...
            </Alert>
          </Snackbar>
        </>
      )}
      <Dialog
        open={openLoading || openErrorPopup || openSuccessPopup}
        onClose={closePopupField}
        classes={{
          paper: classes.popupStyles,
        }}>
        {loading && (
          <>
            <DialogTitle className={classes.popupTitle}>Waiting...</DialogTitle>
            <DialogContent className={classes.popupContentStyles}>
              Your data is checking, please wait a few seconds
              <CircularProgress className={classes.popupSpiner} size={80} />
            </DialogContent>
          </>
        )}
        {error && (
          <>
            <DialogTitle className={`${classes.popupTitle} ${classes.animatedRender}`}>Error!!!</DialogTitle>
            <DialogContent
              className={classNames(classes.popupContentWithIconStyles, {
                [classes.animatedRender]: showDialogText,
                [classes.popupContentStyles]: showDialogText,
              })}>
              Sorry, but something went wrong
              <CancelOutlinedIcon
                className={classNames(classes.popupIconError, classes.popupIcon, {
                  [classes.animatedRender]: showDialogIcon,
                })}
                id="dialog-icon"
              />
            </DialogContent>
          </>
        )}
        {data && (
          <>
            <DialogTitle className={`${classes.popupTitle} ${classes.animatedRender}`}>
              {data.signupUser.status ? "Perfect" : "Error"}
            </DialogTitle>
            <DialogContent
              className={classNames(classes.popupContentWithIconStyles, {
                [classes.animatedRender]: showDialogText,
              })}>
              {data.signupUser.status ? (
                <div>
                  You successfully signed up!
                  <br />
                  Please expect an email with next steps to set your password and log in.
                </div>
              ) : (
                <span>
                  This email {values.email} is already associated with a registered user. You may reset your password{" "}
                  <Link to="/forgotpassword" className={classes.resetLink}>
                    here
                  </Link>
                </span>
              )}

              {data.signupUser.status ? (
                <>
                  <CheckCircleOutlinedIcon
                    className={classNames(classes.popupIconSuccess, classes.popupIcon, {
                      [classes.animatedRender]: showDialogIcon,
                    })}
                    id="dialog-icon"
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      return window.location.replace("https://dcr.ai/");
                    }}>
                    Ok
                  </Button>
                </>
              ) : (
                <ErrorOutlineIcon
                  className={classNames(classes.popupIconError, classes.popupIcon, {
                    [classes.animatedRender]: showDialogIcon,
                  })}
                  id="dialog-icon"
                />
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
      <Modal
        open={showModal}
        onClose={handleCloseTC}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div className={classes.modalCard}>
          <div style={{ justifyContent: "left" }}>{termsAndCondition}</div>
          <br />
          <Button color="primary" onClick={() => handleCloseTC()}>
            Close
          </Button>
          <br />
        </div>
      </Modal>
    </div>
  );
}

const termsAndCondition = (
  <>
    <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
    <h4>
      Following Terms and Conditions are applicable to the Base and Enterprise versions of Trnsact’s
      Software-as-a-Service licenses and products. PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE SAAS PRODUCTS.
      THIS SAAS TERMS AND CONDITIONS CONSTITUTES A LEGAL AND ENFORCEABLE CONTRACT BETWEEN CUSTOMER AND Trnsact. BY
      INDICATING CONSENT ELECTRONICALLY, OR ACCESSING OR OTHERWISE USING THE SAAS PRODUCTS, CUSTOMER AGREES TO THE TERMS
      AND CONDITIONS OF THIS AGREEMENT.
    </h4>
    <div>
      <span style={{ fontWeight: "bold" }}>1.Contracting Parties.</span> This Agreement is binding between
      Dealer/Manufacturer, as specified above, referred to hereinafter as (“Dealer/Manufacturer/Customer”), and Dealer
      Credit Resources, Inc (“Trnsact”).{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>2.Use.</span> Subject to payment of all applicable fees set forth in the
      Trnsact Licensing Agreement or payment in accordance with an Indirect Order through Trnsact’s Distribution Partner
      and the terms and conditions of this Agreement, Trnsact grants Customer, during the Subscription Term, a
      non-exclusive, non-transferable right to access and use Trnsact SaaS Products and applicable Documentation solely
      for Customer’s and its Affiliates’ internal business purposes in accordance with the Documentation and in the
      quantity specified in the Licensing Agreement.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>3.Access.</span> Customer is responsible for ensuring: (i) that only
      Authorized Users have access to the SaaS Products, (ii) that such Authorized Users have been trained in proper use
      of the Trnsact SaaS Products, and (iii) proper usage of passwords, tokens, and access procedures with respect to
      logging into the SaaS Products. Trnsact reserves the right to refuse registration of, or to cancel, login IDs that
      it reasonably believes to violate the terms and conditions set forth in this Agreement. In addition to the rights
      set forth in this Agreement, Trnsact may suspend Customer’s access and use of the SaaS Products if there is an
      unusual and material spike or increase in Customer’s use of the SaaS Products and Trnsact reasonably suspects or
      knows that such traffic or use is fraudulent or materially and negatively impacting the operating capability of
      the SaaS Products.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>4.Products & Services.</span> Customer is entitled to use the above-described
      Module(s) created and supported by Trnsact, including all new features created and released, without an increase
      in the monthly fee. Additional new Modules may be incorporated into the ‘System’ which may increase the monthly
      fee to the Customer. The addition of new sites will increase the monthly fee to the Customer. It is required by
      the customer to maintain at least one site at all times overall. In addition, it is required by the customer to
      purchase at least one site license per physical location.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>5.System.</span> The use of the “System” herein, shall mean the Trnsact
      software system and all associated training and marketing materials provided by Trnsact. The customer is aware
      that the System may be updated and/or enhanced during the term of the agreement; and as such the look, feel,
      usability, and features may change during the agreement period.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>6.License.</span> Terms and Conditions outlined in this agreement are
      applicable to Trnsact’s Base as well as Enterprise versions of Software-as-a-Service licenses. The Base License
      covers single vendor, dealer, and/or location). The Enterprise version of the license covers multiple vendors,
      dealers, stores, and locations of a single larger legal entity. Payment and additional terms related to Base and
      Enterprise SaaS licenses are set forth in the separate Trnsact Licensing Agreement and Pricing Schedules.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>7.Service Term & Termination.</span> The Term of this Agreement shall begin
      on the day of execution of this agreement and shall continue for a period on one year unless specified otherwise
      in the “Purchase Terms” section above (the “Agreement Period”), and renew automatically unless either party
      provides sixty (60) days prior written notice to the other of its intent not to renew the agreement to the end of
      the initial agreement period or then current Renewal Period. After the initial term, the Renewal Period will be
      year to year. In order to cancel or opt out of the contract after the initial term, sixty (60) days written notice
      is required. Upon any termination hereunder all rights to use Trnsact terminate immediately.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>8.Technology Support. Shall be provided Mon.</span> – Fri. from 8 am PST to 5
      pm PST.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>9.Setup Fees, Last Period Deposit, Implementation & Training.</span> The
      setup fees specified above include Setup, Integration, Training, and Data Import where required. A separate fee
      will be charged for custom system integrations, if any. A setup fee equal to three times the monthly site fee is
      due each time a new site is added. It is necessary that the Customer provide the required source files where
      requested and complete tasks assigned to them during the implementation.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>10.Scheduling Onsite Implementation.</span> When requested, due to scheduling
      of resources and travel considerations, once the implementation date has been confirmed with the Customer, Trnsact
      cannot change the implementation date without an additional expense to the Customer. A minimum implementation date
      change fee of $1,500 plus applicable travel related change fees will apply. Usage fees will start on the actual
      implementation start date.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>11.Training.</span> Customer shall receive unlimited access to the on-line
      training for each module and feature of Trnsact. Also, additional live training via web-conferencing will be
      scheduled for select modules/features after the Customer has completed viewing all applicable on-line training
      videos. If the Customer has additional questions or clarifications regarding Trnsact, they may call the support
      line at no additional charge.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>12.Customer Responsibilities.</span> The Customer is responsible for
      determining whether the System will achieve the results the Customer desires and maintaining the necessary
      computer system required to access the System. During the Term of the Agreement, Dealer agrees to not introduce
      another financing sources to any of its customers unless the customer application has already been to Trnsact and
      Trnsact has declined in writing to offer financing from any of its lenders. All information provided to Company by
      Dealer concerning any applicant in question is true, accurate, complete, and not misleading. All the Equipment
      described in such Contract has been delivered to the location stated in the Contract, was new at the time of such
      delivery unless otherwise disclosed to Company in writing and approved by Company in writing and has been
      unconditionally accepted by the Customer in a condition satisfactory to the Customer. The Customer is not in
      default with respect to any other agreement with Vendor and, Vendor is aware of no event which with the passing of
      time or the giving of notice or both would constitute an event of default by Customer thereunder. Vendor has not
      and will not pay any rent or debt service payable by the Customer under the Contract.
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>13.Payment.</span> Customer will pay all licensing fees, plus appropriate
      taxes charged by the city, county, state in which they reside, or any tax imposed on the sale of the license and
      services provided by Trnsact, to Trnsact on the first day of the month and are considered past due after the 15th
      day of the month. All invoices for incidental and custom development work must be paid within thirty (30) days of
      date of invoice.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>14.Grant of License.</span> Trnsact hereby grants, and the Customer accepts
      on behalf of itself and its employees, a nonexclusive, license to use the Trnsact Software and System for the
      limited purpose of its internal business purposes during the term of the Agreement.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>15.Authorized Use.</span> Customer shall cause all persons (“Users”)
      obtaining access to the System to access the System solely in accordance with the terms hereof. A minimum of one
      User must be maintained per physical location during the length of the Agreement or Renewal period. Users are
      authorized to share licenses, however, when two people share a User, Trnsact cannot track which of these people
      created, modified, or approved records in the system. Also, when two or more people share a User, they have access
      to the same records and data in Trnsact (i.e., Security and Audit Tracking is done on the User level.){" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>16.Custom Development.</span> In addition to the setup and implementation of
      Trnsact’s SaaS products, Trnsact may provide custom software development services to the Customer. The pricing,
      development schedule, and additional terms may be documented in a separate Licensing Agreements and Scope of Work
      document.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>17.Representations and Warranties.</span> Each party represents and warrants
      to the other that it is duly organized, validly existing, and in good standing under the laws of the jurisdiction
      of its incorporation or organization. Each party represents and warrants to the other that its officers are duly
      authorized to execute and deliver this Agreement and any other documents contemplated hereby, and they will
      continue to be duly authorized to perform all of such party’s obligations under this Agreement and under each
      instrument and document delivered in connection with this Agreement and that the obligations set forth in this
      Agreement and any such other documents are its valid and binding obligations, enforceable in accordance with their
      terms.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>18.Confidentiality.</span> Confidential Information means nonpublic
      information that the disclosing party designates as being confidential or which under the circumstances
      surrounding disclosure should be treated as confidential (“Confidential Information”). Confidential Information
      includes, without limitation: information relating to the disclosing party's software or hardware products which
      may include source code, API data files, documentation, specifications, databases, networks, system design, file
      layouts, tool combinations and development methods as well as information relating to the disclosing party's
      business or financial affairs, which may include business methods, marketing strategies, pricing, competitor
      information, product development strategies and methods, customer lists and financial results. Confidential
      Information also includes information received from others that the disclosing party is obligated to treat as
      confidential and oral information that is identified by the disclosing party as confidential. Confidential
      Information disclosed by a subsidiary of the disclosing party and/or its agents is covered by this Agreement.
      Confidential Information includes all tangible materials which contain Confidential Information whether written or
      printed documents, computer disks or tapes whether user or machine-readable. The terms and provisions of this
      Confidentiality Section shall survive any termination of the services agreement for any reason{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>19.Intellectual Property Rights.</span> In the course of this Agreement,
      Trnsact may create or use enhancements, discoveries, processes, methods, designs, software code and know-how,
      whether or not copyrightable or patentable, which Trnsact conceived while working with you during the course of
      its ongoing business activities. In addition, Trnsact may independently develop enhancements, processes, methods,
      designs or know-how during the term of this Agreement. The parties mutually acknowledge that Trnsact shall own all
      right, title and interest in and to such enhancements, processes, methods, designs, and know-how including without
      limitation the intellectual property rights relating thereto, and may use such enhancements, processes, methods,
      designs and know-how in their business operations with other customers, without limitation. Trnsact shall own all
      intellectual property developed or created during this Agreement, including but not limited to all code developed
      for the Customer.
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>20.General Warranty Disclaimer.</span> Trnsact makes and the Customer
      receives no warranties, express, implied, or statutory, except as expressly set forth herein. The system and all
      services are provided "as is," without warranty of any kind, either express or implied, including without
      limitation, any warranties concerning the availability, accuracy, usefulness, or content of information, products
      or services or any warranties of merchantability or fitness for a particular purpose. Customer acknowledges that
      data transmission and storage is subject to the likelihood of human and machine errors, omissions, delays, and
      losses, including inadvertent loss of data or damage to media, that may give rise to loss or damage. In addition,
      Customer agrees that Trnsact does not represent that the System or any Services will be uninterrupted, without
      omissions, or error free.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>21.Limitation of Liability.</span> Neither Trnsact nor any of its employees,
      agents, successors, assigns, affiliates, or consultants or service providers, shall be liable to the Customer or
      any third party for any indirect, incidental, special or consequential damages arising out of use of the System,
      the performance of the Services, or inability to gain access to or use the System, or out of any breach of any
      warranty. The limitation of liability shall include, without limitation, damages for loss of business profits,
      business interruption, loss of business information, or other pecuniary loss arising out of this services
      agreement or the performance or failure to perform support or Services, even if Trnsact or Customer has been
      advised of the possibility of such damages. The foregoing allocation of risk and limitation of liability has been
      negotiated and agreed by the parties and forms the basis of their willingness to enter this transaction.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>22.Independent Contractor.</span> Trnsact and Customer are independent
      contractors. Neither Trnsact nor Customer are, or shall be deemed for any purpose to be, employees or agents of
      the other and neither party shall have the power or authority to bind the other party to any contract or
      obligation.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>23.Governing Law.</span> This Agreement shall be governed by and construed in
      accordance with the laws of the State of California. Except as provided in Section 24, Customer and Trnsact
      consent to the jurisdiction of the state courts of the State of California located in Orange County and the U.S.
      District Court for the Central District of California. Each party acknowledges that it has read this Agreement and
      agrees that this Agreement is the complete and exclusive statement of the parties and supersedes and merges all
      prior proposals understandings and agreements, oral or written, between the parties relating to the subject matter
      hereof, including without limitation, the terms of any customer request for proposal or the standard printed terms
      on any Customer purchase order. No modification, amendment, supplement to or waiver of this Agreement shall be
      binding upon the parties hereto unless made in writing and duly signed by both parties.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>24.Severability.</span> In the event any one or more of the provisions of
      this Agreement is held to be invalid or otherwise unenforceable, the enforceability of the remaining provisions
      shall be unimpaired.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>25.Assignment.</span> This Agreement shall be binding upon and inure to the
      benefit of the parties hereto and their respective heirs, personal representatives, successors and permitted
      assigns; No party shall assign this Agreement or any of its rights and obligations hereunder without the prior
      written consent of the other parties; provided, however, that either party may assign this Agreement and all of
      its rights and obligations hereunder to a purchaser of all or substantially all of the assets of such party who
      expressly assumes all of such party's obligations under this Agreement in connection with such assignment.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>26.Survival.</span> The obligations of confidentiality imposed herein shall
      survive termination of this Agreement for a period of four (4) years. Any other provisions of this Agreement that
      by their nature extend beyond the expiration or termination of this Agreement shall survive the expiration or
      termination of this Agreement and shall remain in effect until all such obligations are satisfied.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>27.Arbitration.</span> Except for collection actions for payment of fees and
      for the right of either party to apply to a court of competent jurisdiction for a temporary restraining order, a
      preliminary injunction, or other equitable relief to preserve the status quo or prevent irreparable harm, any
      controversy or claim arising out of or relating to this agreement or to its breach shall be settled by arbitration
      by a panel of three arbitrators in accordance with American Arbitration Rules, pursuant to an arbitration held in
      Orange County, California, and judgment upon the award rendered by the arbitrator may be entered in any court of
      competent jurisdiction. The prevailing party shall be entitled to receive from the other party its attorney’s fees
      and costs incurred in connection with any action, proceeding or arbitration hereunder.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>28.Force Majeure.</span> Trnsact shall not be responsible for failure to
      perform in a timely manner under this services agreement when its failure results from any of the following
      causes: Acts of God or public enemies, civil war, insurrection or riot, fire, flood, explosion, earthquake or
      serious accident, strike, labor trouble or work interruption or any cause beyond its reasonable control.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>29.User Content.</span> You may have the opportunity to submit information to
      Trnsact by posting to a public area of Trnsact Websites or by other submission to us through email or electronic
      communication facilitated by Trnsact. These may include feedbacks, reviews, inventions, techniques, know-how,
      data, materials or other postings or communications (collectively, “User Content”). By submitting any such User
      Content, you grant Trnsact and Trnsact affiliates the royaltyfree, perpetual, irrevocable, nonexclusive right and
      license to use, reproduce, modify, adapt, aggregate, translate, prepare derivative works from, publicly display,
      publicly perform and distribute such User Content (in whole or in part) alone or as incorporated into other works
      in any form, media or technology now known or hereafter developed, on a worldwide basis. You also grant Trnsact
      and its affiliates the right to use the name that you use in connection with such submission or post. You
      represent and warrant that you own or otherwise control all of the rights to the User Content that you submit to
      us, the User Content is accurate and that the use of the User Content you supply does not violate Trnsact’s Terms
      of Use and will not cause injury or damage to any person or entity. You permit other users of Trnsact Websites to
      access, view, store or reproduce your User Content.{" "}
    </div>
    <br />
    <div>
      <span style={{ fontWeight: "bold" }}>30.Definitions.</span> Following definitions apply to this agreement.{" "}
      <ul>
        <li>
          <b>SaaS:</b> “SaaS Products” means the software-as-a-service products specified in the Licensing Agreement as
          further described in the Documentation (including any updates and upgrades to the SaaS Products provided by
          Trnsact in its sole discretion, and any software, systems and locally-installed software agents and connectors
          that interact with the SaaS Products as may be provided by Trnsact in connection with the SaaS Products),
          provided that any free trial SaaS software, proof of concept of the SaaS Products, beta version of the SaaS
          Products, or any other free-of-charge software product (“Trial Version”) will not be subject to this Agreement
          and instead will be governed by the applicable terms of service embedded in, or provided with, such Trial
          Version{" "}
        </li>
        <br />
        <li>
          <b>Subscription Term:</b> “Subscription Term” means the period during which Customer is subscribed to
          Trnsact’s SaaS Products.{" "}
        </li>
        <br />
        <li>
          <b>Third Party Material:</b> “Third-Party Materials” means open-source software programs that are made
          available by third parties under their respective OSS Licenses.{" "}
        </li>
      </ul>{" "}
    </div>
  </>
);
