import { AftermarketProductTypes, PrisingConfig, PrisingConfigByVendor } from "../types";

const defaultConfig: PrisingConfig = {
  isShowRate: true,
  isRateDisabled: false,
  isShowCost: true,
  isCostDisabled: false,
  isShowMarkup: true,
  isMarkupDisabled: false,
  isShowRetail: true,
  isRetailDisabled: false,
};

export function getPrisingConfigByVendor(
  configByVendors: Partial<PrisingConfigByVendor>,
  vendor: AftermarketProductTypes
): PrisingConfig {
  const config = configByVendors[vendor];

  if (!config) return defaultConfig;

  return { ...defaultConfig, ...config };
}
