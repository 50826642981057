import React, { useCallback } from "react";
import { Control } from "react-hook-form/dist/types/form";
import { TextFieldProps, Tooltip } from "@mui/material";
import { Controller, RegisterOptions } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FormInput } from "../FormInput";
import debounce from "lodash/debounce";

interface Props<FormValues extends FieldValues = FieldValues, FormatterValue extends string | number = string> {
  label?: string;
  tooltip?: string;
  rules?: Omit<RegisterOptions<FormValues>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
  control: Control<FormValues>;
  formatter?: (value: string) => FormatterValue;
  name: UseControllerProps<FormValues>["name"];
  inputProps?: Omit<TextFieldProps, "label">;
  extraAction?: { cb: (nextValue: FormatterValue) => void; debounceTime?: number };
}

export const InputField = <
  FormValues extends FieldValues = FieldValues,
  FormatterValue extends string | number = string,
>({
  name,
  label,
  rules,
  control,
  tooltip,
  formatter,
  extraAction,
  inputProps = {},
}: Props<FormValues, FormatterValue>) => {
  const debouncedExtraAction = useCallback(
    debounce((value: FormatterValue) => {
      extraAction?.cb?.(value);
    }, extraAction?.debounceTime ?? 0),
    [extraAction]
  );

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { ref, onChange, ...field }, fieldState }) => (
        <Tooltip title={tooltip || ""} arrow disableHoverListener={!tooltip}>
          <FormInput<FormatterValue>
            label={label}
            inputRef={ref}
            formatter={formatter}
            error={!!inputProps?.error || !!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ""}
            InputLabelProps={{
              shrink: inputProps.type === "date" || !!field.value || field.value === 0,
            }}
            {...inputProps}
            {...field}
            onChange={value => {
              onChange(value);
              debouncedExtraAction(value as FormatterValue);
            }}
          />
        </Tooltip>
      )}
    />
  );
};
