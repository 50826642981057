import React from "react";
import { Modal } from "@mui/material";
import NewCreditAppModal from "../../../pages/VendorOpportunity/NewCreditAppModal";

export const NewCreditAppDocumentsModal = ({ creditApp, isOpen, onClose, ...other }: any) => {
  return (
    <Modal onClose={onClose} open={isOpen} aria-describedby="Summary" aria-labelledby="Credit Application">
      {creditApp ? <NewCreditAppModal {...creditApp} {...other} /> : <></>}
    </Modal>
  );
};
