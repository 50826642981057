import React from "react";
import { useSelector } from "react-redux";
import { BaseTable } from "modules/proposals";
import { NetworkStatus, useQuery } from "@apollo/client";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { GET_PROPOSALS_TABLE_DATA } from "../../api";
import { ProposalsTableDataResponse, ProposalsTableDataVariables } from "../../api/types";

const config = require("../../../../config");

interface Props {
  vendorGUID: string;
}

export const ProposalsTable = ({ vendorGUID }: Props) => {
  const voId = useSelector((state: any) => state.vo.vendorOpportunityId);

  const { data, loading, networkStatus } = useQuery<ProposalsTableDataResponse, ProposalsTableDataVariables>(
    GET_PROPOSALS_TABLE_DATA,
    {
      skip: !voId,
      variables: { voId },
      nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  const getProposalLink = (proposal: Proposal) => {
    window.open(
      `https://app${
        config.environmentName === "dev" ? ".dev" : ""
      }.trnsact.com/ps/?vendorGUID=${vendorGUID}&proposalId=${proposal.id}&viewOnly=true`,
      "_blank"
    );
  };

  const handleGoToProposal = (proposal: Proposal) => {
    window.open(`/proposalDetail/${proposal.id}?from=/vo/${voId}`, "_blank");
  };

  return (
    <CardContainer title="Proposals">
      <BaseTable
        onGoToProposal={handleGoToProposal}
        onGoToProposalDetail={getProposalLink}
        tableData={(data?.proposals as any) ?? []}
        loading={loading || networkStatus === NetworkStatus.refetch}
      />
    </CardContainer>
  );
};
