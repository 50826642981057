import React, { useMemo } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ReadOnlyQuill } from "./ReadOnlyQuill";
import { ProductConfig } from "modules/desking/types";

interface Props {
  product: ProposalProduct;
  productConfiguration: ProductConfig;
}

function getCoverageTermHumanReadableText(coverageTerm: string | undefined): string | null {
  if (!coverageTerm) {
    return null;
  }
  return parseInt(coverageTerm).toString() === coverageTerm ? `${coverageTerm} months` : coverageTerm;
}

export const ProductCatalogDetails = ({ product, productConfiguration }: Props) => {
  const classes = useStyles();

  const catalogDetails =
    productConfiguration?.aftermarketProduct?.aftermarketProductCatalogDetails ??
    product?.aftermarketProduct?.aftermarketProductCatalogDetails;

  const { isProductSummaryDataExist, isCoverageTermDataExist, productSummary, coverageTerm } = useMemo(() => {
    const data = {
      productSummary: catalogDetails?.productSummary,
      coverageTerm: getCoverageTermHumanReadableText(productConfiguration?.COVERAGE_TERM),
    };

    return {
      ...data,
      isProductSummaryDataExist: data.productSummary,
      isCoverageTermDataExist: data.coverageTerm,
    };
  }, [product, productConfiguration]);

  return (
    <>
      {isCoverageTermDataExist && (
        <Box className={classes.container}>
          <span>
            <span className={classes.bold}>Coverage Term:</span> {coverageTerm}
          </span>
        </Box>
      )}
      {isProductSummaryDataExist && (
        <Box className={classes.container}>{productSummary && <ReadOnlyQuill value={productSummary} />}</Box>
      )}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
  bold: {
    fontWeight: "bold",
  },
});
