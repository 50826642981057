import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";

export function AmountField({ fieldConfig }) {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      style={{ pointerEvents: "none" }}
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant="standard"
      label={_.get(fieldConfig, "displayLabel.en", "")}
      required={fieldConfig.required}
      value={value}
      error={false}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
    />
  );
}
