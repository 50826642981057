import { SimpleTextCell } from "components/Table/Cells/SimpleTextCell";
import {
  AftermarketPartnerIntegrationSettingsDialogData,
  DealerManagementCellArgs,
  DealerManagementPartnerLink,
} from "modules/aftermarketPartner/types";
import { Column } from "react-table";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export function createTableColumns(
  handleOpenIntegrationSettingsDialog: (data?: AftermarketPartnerIntegrationSettingsDialogData) => void
): Column<DealerManagementPartnerLink>[] {
  const isNTPAccount = useSelector((state: any) => state.aftermarketPartner.isNTPAccount);

  return [
    {
      sortable: true,
      Header: "Dealer Name",
      accessor: "accountName",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.accountName} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.status} />,
    },
    {
      Header: "Active Menus",
      accessor: "activeMenus",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.activeMenus?.join(", ")} />,
    },
    {
      Header: "Recent Quotes",
      accessor: "recentQuotes",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.recentQuotes} />,
    },
    {
      Header: "Recent Contracts",
      accessor: "recentContracts",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.recentContracts} />,
    },
    {
      Header: "Products Sold",
      accessor: "productsSold",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.productsSold} />,
    },
    {
      Header: "Total Volume",
      accessor: "totalVolume",
      Cell: ({ original }: DealerManagementCellArgs) => <SimpleTextCell value={original.totalVolume} />,
    },
    {
      show: isNTPAccount,
      sortable: false,
      Cell: ({ original }: DealerManagementCellArgs) => (
        <Button size="small" onClick={() => handleOpenIntegrationSettingsDialog({ partnerLink: original })}>
          Integration Setting
        </Button>
      ),
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
