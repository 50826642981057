import React, { useState } from "react";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import _ from "lodash";
export const PercentField = ({ fieldConfig }) => {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant="standard"
      label={_.get(fieldConfig, "displayLabel.en", "")}
      required={fieldConfig.required}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      error={false}
      value={value}
      style={{ pointerEvents: "none" }}
      slotProps={{
        input: {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        },
      }}
    />
  );
};
