import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CurrencyInputField, FormRadioGroup, SelectField } from "components/form";
import { useSaleMode } from "../../../../../../context";
import { deskingActions, deskingSelectors } from "../../../../../../model";
import { determineMaxApprovalAmountNotification } from "../../../../../../lib";
import { existingProgramTypeOptions, styleFormRadioGroupProps } from "../../../../../../constants";
import {
  BuilderViewType,
  CreateProposalFinanceExistingOptions,
  FinanceProgramFormValues,
  SaleType,
} from "../../../../../../types";

export const InitializeWorkflow = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { isCash, onChangeSaleType } = useSaleMode();

  const submissions = useSelector(deskingSelectors.submissions);
  const isSubmissionsLoading = useSelector(deskingSelectors.isSubmissionsLoading);
  const submissionsOptions = useSelector(deskingSelectors.submissionsOptions);

  const { control, resetField, setValue } = useFormContext<FinanceProgramFormValues>();
  const [financeAmount, maxApprovalAmount] = useWatch({
    control,
    name: ["saleDetails.financeAmount", "submission.maxApprovalAmount"],
  });

  const handleChangeWorkflowStatus = (value: string) => {
    if (value === CreateProposalFinanceExistingOptions.No) resetField("financeQuote");

    if (isCash) {
      setValue("saleDetails.saleType", SaleType.Financing);
      resetField("financeQuote.menu");
      onChangeSaleType(SaleType.Financing);
      dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.Finance }));
      dispatch(deskingActions.resetMenuBuilder());
    }
  };

  return (
    <>
      <SelectField
        control={control}
        label="Select Submission"
        options={submissionsOptions}
        isLoading={isSubmissionsLoading}
        name="submission.selectedSubmissionId"
        extraAction={selectedId => {
          const submission = submissions[selectedId] ?? null;

          setValue("submission.maxApprovalAmount", submission?.approvedAmount);
          dispatch(deskingActions.selectSubmission({ submission }));
        }}
      />

      <CurrencyInputField
        control={control}
        label="Max Approval Amount"
        name="submission.maxApprovalAmount"
        notify={determineMaxApprovalAmountNotification(financeAmount, maxApprovalAmount)}
        textFieldProps={{
          placeholder: "Enter amount...",
        }}
      />

      <Box className={classes.container}>
        <Typography variant="subtitle2" component="span">
          Use Saved Program?
        </Typography>

        <Controller
          control={control}
          name="financeQuote.createProposalWithExistingProgram"
          render={({ field }) => (
            <FormRadioGroup
              row
              options={existingProgramTypeOptions}
              {...styleFormRadioGroupProps}
              {...field}
              onChange={(event, value) => {
                handleChangeWorkflowStatus(value);
                field.onChange(event, value);
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
  },
});
