import React from "react";
import { Controller, RegisterOptions } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types/fields";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

export interface Props<FormValues extends FieldValues = FieldValues> extends NumberFormatProps {
  label: string;
  range?: [number, number];
  control: Control<FormValues>;
  textFieldProps?: TextFieldProps;
  rules?: RegisterOptions<FormValues>;
  name: UseControllerProps<FormValues>["name"];
}

export const NumberFormatField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  range,
  rules,
  control,
  textFieldProps = {},
  ...numberFormatProps
}: Props<FormValues>) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field: { ref, value, onChange, ...field }, fieldState }) => (
      <NumberFormat
        label={label}
        inputRef={ref}
        variant="standard"
        customInput={TextField}
        {...field}
        {...(textFieldProps as any)}
        error={!!textFieldProps?.error || !!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : ""}
        value={parseFloat(value)}
        onValueChange={({ floatValue }) => {
          let newValue = floatValue;

          if (newValue === undefined) return onChange(null);

          if (range) {
            if (newValue > range[1]) newValue = range[1];
            if (newValue < range[0]) newValue = range[0];
          }

          onChange(newValue);
        }}
        {...(numberFormatProps ?? {})}
      />
    )}
  />
);
