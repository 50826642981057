import React, { ReactNode } from "react";
import { FormSummaryInfo } from "../FormSummaryInfo";
import { FinanceProgramForm } from "../../../../../schema";

import { documentsByKey } from "../../../../../../../constants";
import { get } from "lodash";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const SubmissionSettingsSummaryPart = ({ financeProgramFormValues: program }: Props) => {
  const formData = program.fourth.submissionSettings.excludedDocuments;

  const renderData: Record<string, ReactNode> = {
    "Document Types Excluded": formData.length
      ? formData.map(s => get(documentsByKey, `[${s}].text`)).join(", ")
      : "Global configuration applied",
  };

  return <FormSummaryInfo data={renderData} />;
};
