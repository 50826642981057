import React, { useEffect, useState } from "react";
import _ from "lodash";
import { isEmailValid } from "utils";
import { Button, Dialog, DialogActions, LinearProgress } from "@mui/material";
import { GuaranteeLayout } from "./GuaranteeLayout";
import { initialContactFormValues } from "../../constants";
import { AddNewContactLayout } from "./AddNewContactLayout";
import { ContactFormValues, ContactType } from "../../types";
import { formatSSNInput } from "../../../../formatters";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onSave: any;
  mode: "add-contact" | "guarantee";
  defaultContactType?: ContactType;
  defaultFormValues?: ContactFormValues;
}

export const ContactModal = ({
  isOpen,
  isLoading,
  onClose,
  onSave,
  mode,
  defaultContactType,
  defaultFormValues,
}: Props) => {
  const SSN_LENGTH = 11;

  const [contactType, setContactType] = useState<ContactType>("contact-only");
  const [contactFormValues, setContactFormValues] = useState<ContactFormValues>(initialContactFormValues);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      const nextFormValues = defaultFormValues || initialContactFormValues;

      setContactType(defaultContactType || "contact-only");
      setContactFormValues({ ...nextFormValues, ssn: formatSSNInput(nextFormValues?.ssn ?? "") });
      setFormIsValid(false);
    }
  }, [defaultContactType, defaultFormValues, isOpen]);

  useEffect(() => {
    const requiredFields = ["firstName", "lastName", "email"] as const;
    const validationRulesFields = ["email", "ssn"] as const;

    const requiredFieldsValid = requiredFields.every(field => !!contactFormValues[field]);

    const validationFieldsValid = validationRulesFields.every(field => {
      const fieldValue = contactFormValues[field];

      switch (field) {
        case "ssn":
          return fieldValue ? fieldValue.length === SSN_LENGTH : true;
        case "email":
          return fieldValue ? isEmailValid(fieldValue) : true;
        default:
          return false;
      }
    });

    setFormIsValid(requiredFieldsValid && validationFieldsValid);
  }, [contactFormValues]);

  const handleSave = () => {
    const formValuesToPass = ["contact-only", "signor-only"].includes(contactType)
      ? _.pick(contactFormValues, ["firstName", "lastName", "email"])
      : contactFormValues;
    onSave(formValuesToPass, contactType);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      {mode === "add-contact" && (
        <AddNewContactLayout
          contactFormValues={contactFormValues}
          contactType={contactType}
          setContactFormValues={setContactFormValues}
          setContactType={setContactType}
          isLoading={isLoading}
        />
      )}

      {mode === "guarantee" && (
        <GuaranteeLayout
          contactFormValues={contactFormValues}
          contactType={contactType}
          setContactFormValues={setContactFormValues}
          setContactType={setContactType}
          isLoading={isLoading}
        />
      )}

      <DialogActions>
        {!isLoading ? (
          <>
            <Button variant="outlined" color="primary" size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" size="small" disabled={!formIsValid} onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};
