import React from "react";
import moment from "moment/moment";
import { Column } from "react-table";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import { DefaultTableCell } from "../../../Table/DefaultTableCell";
import { PageTypes } from "../../../../global";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { TableCell } from "../../../Table/TableCell";

interface Options {
  handleResend: any;
  pageType: PageTypes;
}

export function createTableColumns(options: Options): Column<Proposal>[] {
  const { handleResend, pageType } = options;

  const commonCells: Column<Proposal>[] = [
    {
      Header: "Created On",
      accessor: "createdDateTime",
      Cell: ({ value }: any) => (
        <DefaultTableCell value={value} formatter={() => (value ? moment(value).format("ll") : "")} />
      ),
    },
    {
      Header: "Company",
      accessor: "businessName",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      Header: "Contact",
      accessor: "contactName",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      Header: "Amount",
      accessor: "amountRequested",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
  ];

  const csColumns: Column<Proposal>[] = [
    {
      Header: "Owner",
      accessor: "salesPersonName",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      Header: "Resend",
      Cell: (props: any) => (
        <TableCell>
          <IconButton onClick={e => handleResend(e, props.original)} size="small">
            <SendIcon color="primary" />
          </IconButton>
        </TableCell>
      ),
    },
  ];

  const voColumns: Column<Proposal>[] = [
    {
      Header: "Accepted Payment",
      accessor: "acceptedPayment",
    },
    {
      Header: "Owner",
      accessor: "salesPersonName",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
  ];

  const additionColumnsByPageType: Record<PageTypes, Column<Proposal>[]> = {
    [PageTypes.CreditSubmission]: csColumns,
    [PageTypes.VendorOpportunities]: voColumns,
  };

  return [...commonCells, ...additionColumnsByPageType[pageType]].map(header => ({
    ...header,
    headerStyle: { fontWeight: "500", padding: "0.5rem" },
  }));
}
