import { useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import makeStyles from "@mui/styles/makeStyles";
//Core Components
import Button from "components/CustomButtons/Button";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import Tile from "../../components/Tile/Tile";
import FDRModal from "./FDRModal";
import SendIcon from "@mui/icons-material/Send";
import SendContractModal from "./SendContractModal";
import axios from "axios";
import { DCR_API_BASE_SERVER_URL } from "config";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicator from "assets/img/drag-indicator.svg";
import TableSearch from "components/Table/TableSearch";

const UPSERT_FDR = gql`
  mutation ($fdrInput: UpsertFdrInput!) {
    upsertFdr(input: $fdrInput)
  }
`;

const QUERY_FDRS = gql`
  query ($dynamicsTransactionId: ID!) {
    fdrs(dynamicsTransactionId: $dynamicsTransactionId) {
      fdrId
      dynamicsFdrId
      dynamicsTransactionId
      dynamicsLesseeAccountId
      dba
      lesseeName
      lesseePhone
      billingAddress
      billingCity
      billingState
      billingZip
      billingCounty
      bizAddressSameAsEqptAddress
      eqptAddress
      eqptCity
      eqptState
      eqptZip
      eqptCounty
      type
      fdrStatus
      taxTreatment
      edocStatus
      fundingCutOffDate
      automated
      agreementNumber
      signor0SendingParty
      signorsName
      signorsLegalTitle
      isSignorPg
      numOfPgs
      pg1Name
      pg1HomeAddress
      pg1HomePhone
      pg1Cell
      pg2Name
      pg2HomeAddress
      pg2HomePhone
      pg2Cell
      pg3Name
      pg4Name
      fedTaxId
      insurancePolicyNum
      insuranceAgent
      insurancePhone
      invoiceNumber
      invoiceDate
      numberOfAdvancedPayments
      salesRepDocFee
      taxRate
      payment
      term
      checkDepositAmount
      scheduleA
      ninetyDaysDeferred
      docInvoiceTotal
      salesTax
      differenceToCollect
      advanceRent
      bankName
      bankAcctHolderName
      bankAcctNum
      bankAbbaNum
      totalEqptCost
      totalTaxAmt
      freightLaborInstall
      invoiceGrandTotal
      dynamicsLenderId
      discountedOrBrokered
      buyout
      buyrate
      numOfAdvPaymentsAmounts
      sellRate
      points
      estimatedTotalGM
      preFunding
      preFundingPercent
      preFundingSource
      apr
      titled
      newUsed
      salesLeaseback
      llcAgreementRequest
      privatePartySale
      inspectionRequirement
      bankDocFees
      createdByDynamicsAccountId
      createdByDynamicsUserId
      createdDateTime
      modifiedDateTime
      agreementStatus
      sent
      owner
      signedOn
      agreementId
      docusignEnvelopeId
      signorsEmail
    }
  }
`;

const DOC_SETS = gql`
  query ($accountId: ID) {
    docSets(accountId: $accountId) {
      docSetId
      name
      docusignTemplateIds
      approvalTerms
      description
      status
      active
      createdBy
      templateName
    }
  }
`;

const TEMPLATES = gql`
  query ($accountId: ID) {
    templates(accountId: $accountId) {
      templateId
      docusignTemplateId
      name
      description
      status
      active
      createdBy
      source
    }
  }
`;

const DELETE_FDR = gql`
  mutation ($id: ID!) {
    deleteFdr(id: $id)
  }
`;

const styles = theme => ({
  root: {
    "& .MuiInput-underline:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  right: {
    textAlign: "right",
  },
  greenButton: {
    margin: 0,
    float: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
  },
  fixReactTable: {
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
});

const priorityColors = {
  urgent: "#bb3354",
  high: "#bb3354",
  medium: "#71589a",
  low: "#69a1bd",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

const useStyles = makeStyles(styles);

export default function FDRList({ vo, account, userProfile, tileTitle, dynamicsTransactionId, cs }) {
  const classes = useStyles();
  const [fdrs, setFdrs] = useState([]);
  const [openFDRModal, setOpenFDRModal] = useState(false);
  const [fdrId, setFdrId] = useState(null);
  const [fdr, setFdr] = useState(null);
  const [openDocSendModal, setOpenDocSendModal] = useState(false);
  const [availableDocSets, setAvailableDocSets] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const searchRef = React.useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [filteredFdrs, setFilteredFdrs] = useState([]);

  const {
    loading,
    error,
    data: fdrData,
    refetch,
  } = useQuery(QUERY_FDRS, {
    context: { authRequired: true },
    variables: {
      dynamicsTransactionId: dynamicsTransactionId,
    },
  });

  if (loading) console.log("Loading fdrs...");
  if (error) console.log(`Error! ${error.message}`);

  const {
    data: docSets,
    loading: docSetsLoading,
    error: docSetsError,
  } = useQuery(DOC_SETS, {
    variables: {
      accountId: account.id,
    },
  });
  if (docSetsLoading) console.log("Loading docSetsLoading...");
  if (docSetsError) console.log(`Error! ${docSetsError.message}`);

  const {
    data: templates,
    refetch: templatesRefetch,
    loading: templatesLoading,
    error: templatesError,
  } = useQuery(TEMPLATES, {
    variables: {
      accountId: account.id,
    },
  });

  if (templatesLoading) console.log("Loading templates...");
  if (templatesError) console.log(`Error! ${templatesError.message}`);

  useEffect(() => {
    if (fdrData) {
      setFdrs(fdrData.fdrs);
    }
  }, [fdrData]);

  const filterFdrs = fdrs => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return fdrs;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = fdrs.filter(el => {
      if (
        typeof el.lesseeName === "string" ||
        typeof el.sent === "string" ||
        typeof el.agreementStatus === "string" ||
        typeof el.fdrStatus === "string"
      ) {
        return (
          (el.lesseeName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.sent || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.agreementStatus || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.fdrStatus || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  useEffect(() => {
    setFilteredFdrs(filterFdrs(fdrs || []));
  }, [fdrs, searchRef.current.currentSearch]);

  useEffect(() => {
    if (docSets) {
      setAvailableDocSets(docSets);
    }
  }, [docSets]);

  useEffect(() => {
    if (templates) {
      setAllTemplates(templates);
    }
  }, [templates]);

  const [upsertFdr] = useMutation(UPSERT_FDR, {
    context: { authRequired: true },
  });

  const [deleteFdr] = useMutation(DELETE_FDR, {
    context: { authRequired: true },
  });

  const handleOpenFDRModal = () => {
    setOpenFDRModal(true);
  };

  const handleCloseFDRModal = () => {
    setOpenFDRModal(false);
    setFdrId(null);
    setFdr(null);
  };

  const handleAddFDR = async payload => {
    try {
      await upsertFdr({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveFDR = async payload => {
    try {
      await upsertFdr({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenFDR = value => {
    setFdrId(value.fdrId);
    setFdr(value);
    handleOpenFDRModal();
  };

  const handleOpenSendDocsModal = () => {
    setOpenDocSendModal(true);
  };
  const handleCloseSendDocsModal = () => {
    setOpenDocSendModal(false);
  };

  const handleSendDocs = (e, fdr) => {
    e.stopPropagation();
    handleOpenSendDocsModal();
    setFdr(fdr);
    setFdrId(fdr.fdrId);
  };

  const handleDeleteFdr = async (e, fdr) => {
    e.stopPropagation();
    try {
      await deleteFdr({
        variables: {
          id: fdr.fdrId,
        },
      });
      await refetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = fdr => {
    try {
      return axios
        .post(
          `${DCR_API_BASE_SERVER_URL}/download-envelope?envelopeId=${fdr.docusignEnvelopeId}`,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then(response => {
          const fileURL = window.URL.createObjectURL(response.data, { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("target", "_blank");
          link.setAttribute("download", "envelope.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => console.log(error));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.root}>
      {!!account && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tile
              title={!tileTitle ? "Final Doc Requests" : tileTitle}
              useCollapsing={true}
              titleButtonComponent={
                <Button
                  className={classes.greenButton}
                  color="primary"
                  size={"sm"}
                  onClick={e => {
                    e.preventDefault();
                    handleOpenFDRModal();
                  }}>
                  Add
                </Button>
              }>
              {!!fdrs && fdrs && (
                <TableSearch
                  setSavedListSettings={setSavedListSettings}
                  searchRef={searchRef}
                  savedListSettings={savedListSettings}
                />
              )}
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.fixReactTable}>
                  {!!fdrs && fdrs ? (
                    <ReactTable
                      color="primary"
                      data={filteredFdrs}
                      getTrProps={(state, rowInfo, column, instance) => ({
                        style: { cursor: "pointer" },
                        onClick: e => {
                          handleOpenFDR(rowInfo.original);
                        },
                      })}
                      columns={[
                        {
                          Header: "Created On",
                          accessor: "createdDateTime",
                          Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                          Filter: () => <></>,
                        },
                        {
                          Header: "Lessee Name",
                          accessor: "lesseeName",
                          Filter: () => null,
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Status",
                          accessor: "fdrStatus",
                          Filter: () => null,
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Type",
                          accessor: "type",
                          Filter: () => null,
                        },
                        {
                          Header: "Send Docs",
                          Cell: props => {
                            return (
                              <>
                                <SendIcon onClick={e => handleSendDocs(e, props.original)} color="primary"></SendIcon>
                              </>
                            );
                          },
                        },
                        {
                          Header: "Sent On",
                          accessor: "sent",
                          Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                          Filter: () => null,
                        },
                        {
                          Header: "Agreement Status",
                          accessor: "agreementStatus",
                          Filter: () => null,
                        },
                        {
                          Header: "Signed On",
                          accessor: "signedOn",
                          Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                          Filter: () => null,
                        },
                        // {
                        // 	Header: "Download Docs",
                        // 	Cell: props => {
                        // 		return (
                        // 			<>
                        // 				{props.original.docusignEnvelopeId ? (
                        // 					<CloudDownloadIcon onClick={() => handleDownload(props.original)} color="primary"></CloudDownloadIcon>
                        // 				) : (
                        // 					null
                        // 				)}
                        // 			</>
                        // 		);
                        // 	},
                        // },
                        {
                          Header: "Delete FDR",
                          Cell: props => {
                            return props.original.agreementStatus !== "sent" ? (
                              <>
                                <DeleteIcon
                                  onClick={e => handleDeleteFdr(e, props.original)}
                                  color="primary"></DeleteIcon>
                              </>
                            ) : null;
                          },
                        },
                      ]}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      customCellClasses={[classes.right]}
                      customClassesForCells={[5]}
                      className="-striped -highlight"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Modal
                open={openFDRModal}
                onClose={handleCloseFDRModal}
                aria-labelledby="FDR Form"
                aria-describedby="Input">
                <div>
                  <FDRModal
                    vo={vo}
                    handleClose={handleCloseFDRModal}
                    handleAddFDR={handleAddFDR}
                    handleSaveFDR={handleSaveFDR}
                    fdrId={fdrId}
                    dynamicsTransactionId={dynamicsTransactionId}
                    refetch={refetch}
                    open={openFDRModal}
                    fdr={fdr}
                    cs={cs}
                  />
                </div>
              </Modal>
              <Modal
                open={openDocSendModal}
                onClose={handleCloseSendDocsModal}
                aria-labelledby="Send Contract"
                aria-describedby="Input">
                <div>
                  <SendContractModal
                    vo={vo}
                    handleClose={handleCloseSendDocsModal}
                    fdrId={fdrId}
                    dynamicsTransactionId={dynamicsTransactionId}
                    refetch={refetch}
                    open={openDocSendModal}
                    fdr={fdr}
                    docSets={availableDocSets ? availableDocSets.docSets : null}
                    templates={allTemplates ? allTemplates.templates : null}
                  />
                </div>
              </Modal>
            </Tile>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
