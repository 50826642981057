import React from "react";
import { Typography } from "@mui/material";
import { ProposalMenuLinkedCriteria } from "@trnsact/trnsact-shared-types";

interface Props {
  linkedToCriterias: ProposalMenuLinkedCriteria[];
}

export const DealersCell = ({ linkedToCriterias }: Props) => {
  return (
    linkedToCriterias?.map(linkedToCriteria => (
      <Typography variant="body2">{linkedToCriteria.account.name}</Typography>
    )) ?? (
      <Typography align="center" component="span" variant="body2">
        {" "}
        -{" "}
      </Typography>
    )
  );
};
