import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vendorContactRoleHumanReadable } from "../../../utils";

const config = require("../../../config");

const EmailSignaturesInstructions = () => {
  let useStyles = makeStyles({
    linkExample: {
      padding: "5px",
      display: "block",
      margin: "5px 0",
      fontWeight: "bolder",
    },

    instructions: {
      "& > li": {
        padding: "5px",
        fontWeight: 400,
      },
    },
    imgSalesQuoteImg: {
      width: "30vw",
      borderRadius: "10px",
    },
  });
  let classes = useStyles();

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const vendorProfile = useSelector(state => state.vp);

  const generateEmailHTML = () => {
    return `<table style="width: 450px; font-size: 10pt; font-family: Arial, sans-serif;" cellpadding="0" cellspacing="0"
      border="0">
      <tbody>
          <tr>
              <td style="font-size: 10pt; font-family: Arial, sans-serif;; border-right: 3px solid; border-right-color: #2196f3; width:180px; padding-right: 10px; vertical-align: top;" valign="top">
                  <p style="margin-top:32px; margin-bottom:0; line-height:1.0">
                      <strong><span
                              style="font-size: 12pt; font-family: Arial, sans-serif;; color:#2196f3;">${
                                userProfile.firstName
                              }
                              ${userProfile.lastName}<br></span></strong>
                      <span style="font-family: Arial, sans-serif;; font-size:9pt; color:#000000;">${
                        vendorContactRoleHumanReadable[userProfile.vendorContactRole]
                      }</span>
                  </p>
              </td>
              <td valign="top">
                  <table cellpadding="0" cellspacing="0" border="0">
                      <tbody>
                          <tr>
                              <td style="font-size: 10pt; color:#444444; font-family: Arial, sans-serif;; padding-bottom: 5px; padding-left: 30px; vertical-align: top; line-height:1.3"
                                  valign="top">
                                  <span style="color: #000000;"><strong>M</strong></span><span
                                      style="font-size: 9pt; font-family: Arial, sans-serif;; color:#000000;">
                                      ${userProfile.mobilePhoneNumber}<br></span>
                                  <span style="color: #000000;"><strong>P</strong></span><span
                                      style="font-size: 9pt; font-family: Arial, sans-serif;; color:#000000;">
                                      ${userProfile.phoneNumber}<br></span>
                                  <span style="color: #000000;"><strong>E</strong></span><span
                                      style="font-size: 9pt; font-family: Arial, sans-serif;; color:#000000;">
                                      ${userProfile.email}</span>
                              </td>
                          </tr>
                          <tr>
                              <td style="font-size: 10pt; color:#0079ac; font-family: Arial, sans-serif;; padding-bottom: 5px; padding-left: 30px; vertical-align: top; line-height:1.3"
                                  valign="top">
                                  <strong
                                      style="font-family: Arial, sans-serif;; font-size:9pt; color:#000000;">${
                                        account.name
                                      }</strong>

                                  <br>
                                  <span
                                      style="font-size: 10pt; font-family: Arial, sans-serif;; color: #000000;">${
                                        account.address
                                      }<br /></span>
                                  

                              </td>
                          </tr>
                          <tr>
                              <td style="font-size: 10pt; font-family: Arial, sans-serif;; padding-bottom: 5px; padding-top: 5px; padding-left: 30px; vertical-align: top; color: #0079ac;"
                                  valign="top">
                                  <a href="${config.REACT_APP_OCA_BASE_URL}/oca/?vendorGUID=${
                                    account.dynamicsAccountId
                                  }" target="_blank" rel="noopener"
                                      style="text-decoration:none;">
                                      <strong style="text-decoration: underline;font-size: 9pt; font-family: Arial, sans-serif;; color: #2196f3;">EASY FINANCING AVAILABLE</strong></a>
                              </td>
                          </tr>
              </td>
      </tbody>
  </table>
  </tr>
  </tbody>
</table>`;
  };
  return (
    <Grid container spacing={4}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography>
          Show your customers that financing is available in your emails with a signature application link. Clicking on
          the link takes visitors to your Secure Credit Application.
        </Typography>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container>
          <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
            <p>Email Signature Preview:</p>
            <div
              dangerouslySetInnerHTML={{
                __html: generateEmailHTML(),
              }}></div>
            {/* <img src={salesQuoteImg} className={classes.imgSalesQuoteImg} /> */}
          </Grid>
          <Grid item xl={7} lg={6} md={5} sm={5} xs={5}>
            <Typography variant="h5">Instructions:</Typography>

            <ol className={classes.instructions}>
              <li>
                Email signatures are HTML tables that you can copy & paste inside your email client, this template was
                built for compatiblity with most modern email clients.
              </li>
              <li>
                <SyntaxHighlighter language="xml">{generateEmailHTML()}</SyntaxHighlighter>
              </li>
              <li>On the left side there's a preview of the rendered signature.</li>
            </ol>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailSignaturesInstructions;
