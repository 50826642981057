import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { grey } from "@mui/material/colors";
import { Info } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useForceUpdate } from "hooks/useForceUpdate";
import { Box, Tooltip, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FinanceProgramFormValues } from "../../../types";
import { financeProgramDefaultDescription } from "../../../constants";

interface Props {
  financeProgramDescription: string;
}

export const OptionSummaryDetails = ({ financeProgramDescription }: Props) => {
  const classes = useStyles();

  useForceUpdate([financeProgramDescription]);

  const { getValues, setValue } = useFormContext<FinanceProgramFormValues>();

  useEffect(() => {
    if (!getValues("financeProgramDescription")) {
      setValue("financeProgramDescription", financeProgramDescription);
    }

    return () => {
      setValue("financeProgramDescription", "");
    };
  }, [setValue, getValues, financeProgramDescription]);

  return (
    <Box className={classes.container}>
      <Box className={classes.panel}>
        <Typography component="span" variant="subtitle2">
          Finance Program Description
        </Typography>

        <Tooltip title={financeProgramDefaultDescription}>
          <Info fontSize="small" color="primary" />
        </Tooltip>
      </Box>

      <Box className={classes.root}>
        <ReactQuill
          value={getValues("financeProgramDescription")}
          theme="snow"
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ],
          }}
          onChange={value => {
            setValue("financeProgramDescription", value);
          }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  panel: {
    gap: "0.5rem",
    display: "flex",
    padding: "0.5rem",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    "& .ql-tooltip.ql-hidden": {
      display: "none",
    },
  },
});
