import React from "react";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { TasksTable } from "./TasksTable";
import { useVOTasks } from "../hooks/useVOTasks";

interface Props {
  VOId: string;
}

export const TasksCard = ({ VOId }: Props) => {
  const { tasks, isVOTasksLoading } = useVOTasks({ VOId });

  return (
    <CardContainer title="Tasks" isLoading={isVOTasksLoading}>
      <TasksTable tableData={tasks.filter(task => task.regarding === "Trade Reference")} isLoading={isVOTasksLoading} />
    </CardContainer>
  );
};
