import gql from "graphql-tag";

export const GET_PARTNERS_PROPOSALS = gql`
  query GetPartnersProposals($accountId: ID!) {
    partnersProposals(accountId: $accountId) {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
      account {
        name
        dynamicsAccountId
      }
    }
  }
`;
