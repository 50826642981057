class ImmutableUtils {
  public removeKeyFromObj<Object extends object, Key extends keyof Object>(obj: Object, key: Key): Omit<Object, Key> {
    const { [key]: _, ...rest } = obj;
    return rest;
  }

  public swapItems<Item>(items: Item[], from: number, to: number) {
    const orderedMenuOptions = [...items];
    orderedMenuOptions.splice(from, 1);
    orderedMenuOptions.splice(to, 0, items[from]);

    return orderedMenuOptions;
  }
}

export const immutableUtils = new ImmutableUtils();
