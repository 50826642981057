import { useMemo } from "react";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { AftermarketCatalogDetailsKeys } from "../types";
import { checkIfQuillValueIsEmpty } from "utils/quillUtils";

export function useProductCatalogDetails(product: ProposalProduct) {
  const aftermarketProductCatalogDetails = product?.aftermarketProduct?.aftermarketProductCatalogDetails;

  const { productCategory, productCatalogDetailsKeysWithData } = useMemo(
    () => ({
      productCategory: product?.aftermarketProduct?.productCategory,
      productCatalogDetailsKeysWithData: Object.values(AftermarketCatalogDetailsKeys).filter(
        key => !checkIfQuillValueIsEmpty(aftermarketProductCatalogDetails?.[key])
      ),
    }),
    [product]
  );

  return {
    productCategory,
    productCatalogDetailsKeysWithData,
  };
}
