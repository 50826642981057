import { portalConfigurationTypes } from "./pages/Prequal/constants";

export const SESSION_TIMEOUT_COOKIE_NAME = "userSession";

export const initialPortalConfigTypes = [
  portalConfigurationTypes.scorecard,
  portalConfigurationTypes.leads,
  portalConfigurationTypes.opportunities,
  portalConfigurationTypes.userManagement,
  portalConfigurationTypes.securityManagement,
  portalConfigurationTypes.healthCheck,
  portalConfigurationTypes.creditReports,
  portalConfigurationTypes.documents,
  portalConfigurationTypes.portalSetup,
  portalConfigurationTypes.prescreen,
  portalConfigurationTypes.voChecklistTemplate,
  portalConfigurationTypes.voManagement,
  portalConfigurationTypes.aftermarket,
  portalConfigurationTypes.submissionsHubManagement,
];

export const CADMPortalConfigTypes = [portalConfigurationTypes.CADMCommercial, portalConfigurationTypes.CADMIndividual];

export const lenderTypesSettings = [
  { code: 100, title: "Bank", letter: "A", color: "#458e46" },
  { code: 200, title: "Captive", letter: "A+", color: "#458e46" },
  { code: 300, title: "Non-Bank Lender", letter: "B", color: "#eeba45" },
  { code: 400, title: "Broker", letter: "C", color: "#8f1b14" },
];

export const DateFormats = {
  Full: "dddd, MMMM D, YYYY", // Full date (e.g., "Monday, November 12, 2024")
  Long: "MMMM D, YYYY", // Long date (e.g., "November 12, 2024")
  Medium: "MMM D, YYYY", // Medium date (e.g., "Nov 12, 2024")
  Short: "MM/DD/YYYY", // Short date (e.g., "11/12/2024")
  TimeWithFullDate: "dddd, MMMM D, YYYY [at] h:mm A", // Full date with time (e.g., "Monday, November 12, 2024 at 9:30 AM")
  TimeWithShortDate: "MM/DD/YYYY h:mm A", // Short date with time (e.g., "11/12/2024 9:30 AM")
  YearMonth: "YYYY-MM", // Year and month (e.g., "2024-11")
  MonthDay: "MMMM D", // Month and day (e.g., "November 12")
  MonthDayYear: "MM/DD/YYYY", // Month, day, and year in short format (e.g., "11/12/2024")
  ISO: "YYYY-MM-DDTHH:mm:ssZ", // ISO 8601 format (e.g., "2024-11-12T09:30:00Z")
  Time24Hour: "HH:mm", // 24-hour time (e.g., "13:45")
  Time12Hour: "hh:mm A", // 12-hour time with AM/PM (e.g., "01:45 PM")
  DateTimeShort24Hour: "MM/DD/YYYY HH:mm", // Short date with 24-hour time (e.g., "11/12/2024 13:45")
  DateTimeShort12Hour: "MM/DD/YYYY hh:mm A", // Short date with 12-hour time (e.g., "11/12/2024 01:45 PM")
  DateTimeMedium24Hour: "MMM D, YYYY HH:mm", // Medium date with 24-hour time (e.g., "Nov 12, 2024 13:45")
  DateTimeMedium12Hour: "MMM D, YYYY hh:mm A", // Medium date with 12-hour time (e.g., "Nov 12, 2024 01:45 PM")
  WeekdayDate: "dddd, MMM D", // Weekday and date (e.g., "Monday, Nov 12")
  DayMonthYear: "D MMMM YYYY", // Day, month, and year (e.g., "12 November 2024")
  MonthYear: "MMMM YYYY", // Month and year (e.g., "November 2024")
  DayShortMonthYear: "D MMM YYYY", // Day, short month, and year (e.g., "12 Nov 2024")
  FullWithSeconds: "dddd, MMMM D, YYYY [at] h:mm:ss A", // Full date with seconds (e.g., "Monday, November 12, 2024 at 9:30:45 AM")
  ShortWithSeconds: "MM/DD/YYYY hh:mm:ss A", // Short date with seconds (e.g., "11/12/2024 01:45:30 PM")
};

export const documentsByKey = {
  quote: { key: "quote", value: "quote", text: "Quote" },
  invoice: { key: "invoice", value: "invoice", text: "Invoice" },
  buyers_order: { key: "buyers_order", value: "buyers order", text: "Buyer's Order" },
  decision_letter: { key: "decision_letter", value: "decision letter", text: "Decision Letter" },
  insurance_certificate: {
    key: "insurance_certificate",
    value: "insurance certificate",
    text: "Insurance Certificate",
  },
  driver_license: { key: "driver_license", value: "driver license", text: "Driver License" },
  bank_statements: { key: "bank_statements", value: "bank statements", text: "Bank Statements" },
  tax_returns: { key: "tax_returns", value: "tax returns", text: "Tax Returns" },
  equipment_specs: { key: "equipment_specs", value: "equipment specs", text: "Equipment Specs" },
  sales_tax_exemption: { key: "sales_tax_exemption", value: "sales tax exemption", text: "Sales Tax Exemption" },
  credit_app_scan: { key: "credit_app_scan", value: "credit app scan", text: "Credit App Scan" },
  other: { key: "other", value: "other", text: "Other" },

  credit_report: { key: "credit_report", value: "Credit Report", text: "Credit Report" },
  compliance: { key: "compliance", value: "Compliance", text: "Compliance" },
  addendum: { key: "addendum", value: "Addendum", text: "Addendum" },
  financial: { key: "financial", value: "Financial", text: "Financial Doc" },
};
