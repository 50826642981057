import React from "react";
import { logError } from "utils/logger";
import { Nullable, ValueOf } from "global";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useNotifications } from "modules/notification";
import { FormProvider, useForm } from "react-hook-form";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { useVO } from "../../hooks/useVO";
import { CreditLineStatuses } from "../../types";
import { CreditLineFormValues } from "../model";
import { CreditLineForm } from "./CreditLineForm";
import { CreditLineNotes } from "./CreditLineNotes";
import { UpdateVOVariables } from "../../api/types";

interface Props {
  VOId: string;
  userName: string;
  notes: Nullable<string>;
  VOAmount: Nullable<number>;
  creditAppAmount: Nullable<number>;
  status: CreditLineStatuses;
  isCreditLineRequested: boolean;
}

export const CreditLineCard = ({
  VOId,
  status,
  userName,
  VOAmount,
  creditAppAmount,
  isCreditLineRequested,
  notes,
}: Props) => {
  const classes = useStyles();

  const { showNotification } = useNotifications();

  const form = useForm<CreditLineFormValues>({
    defaultValues: {
      status,
      notes: "",
      amount: VOAmount ?? 0,
      allNotes: notes ?? "",
    },
  });

  const { handleUpdateVO, isVOUpdating } = useVO();

  const handleChangeCreditLine = async <Key extends keyof ValueOf<UpdateVOVariables, "fieldsObj">>(
    data: Record<Key, ValueOf<UpdateVOVariables, "fieldsObj">[Key]>
  ) => {
    try {
      await handleUpdateVO({ VOId, fieldsObj: data });
      showNotification("Credit line successfully updated!");
    } catch (error) {
      logError(error);
      showNotification("Updated credit line failed!", { type: "error" });
    }
  };

  return (
    <CardContainer title="Credit Line">
      <>
        {!isCreditLineRequested ? (
          <Typography>Not Requested</Typography>
        ) : (
          <form className={classes.cardContainer}>
            <FormProvider {...form}>
              <CreditLineForm
                status={status}
                userName={userName}
                amount={creditAppAmount}
                onCreditLineUpdate={handleChangeCreditLine}
              />

              <CreditLineNotes isLoading={isVOUpdating} onCreditLineUpdate={handleChangeCreditLine} />
            </FormProvider>
          </form>
        )}
      </>
    </CardContainer>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",

    "& .lineSection": {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",

      "& .form": {
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
      },
    },

    "& .notesSection": {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",

      "& .customNote": {
        gap: "1rem",
        display: "flex",
        alignItems: "flex-end",

        "& > .addNotesBtn": {
          minWidth: 120,
        },
      },
    },
  },
});
