import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { FormControl, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../components/CustomButtons/Button";
import SendIcon from "@mui/icons-material/Send";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import mixpanel from "../../mixpanel";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const SEND_REP_OCA = gql`
  mutation ($input: sendVendorContactLinkInput!) {
    sendVendorContactLink(input: $input) {
      success
      errorMessage
    }
  }
`;
const SEND_FEATURE_REQUEST = gql`
  mutation ($input: sendFeatureRequestInput!) {
    sendFeatureRequest(input: $input) {
      success
      errorMessage
    }
  }
`;

function TextMaskCustom(props) {
  return <NumberFormat {...props} format="(###) ###-####" />;
}

const modalStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  requestBtn: {
    textAlign: "center",
  },
}));

const setCursor = target => {
  setTimeout(() => target.setSelectionRange(0, 0));
};

const SimpleDialog = props => {
  const classes = modalStyles();
  const { onClose, open, sendFeatureRequest, userProfile, account, setRequestSent } = props;

  const handleClose = () => {
    onClose();
  };

  const executeRequest = async () => {
    try {
      await sendFeatureRequest({
        variables: {
          input: {
            feature: "Spanish OCA",
            dynamicsContactId: userProfile.dynamicsContactId,
            accountName: account.name,
          },
        },
      });
      mixpanel.track("Spanish OCA Request", { account: account.name });
      setRequestSent(true);

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="feature-dialog-title" open={open}>
      <DialogTitle id="feature-dialog-title">Feature Request</DialogTitle>
      {onClose ? (
        <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      ) : null}
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <DialogContentText>Want to offer your customer a Spanish language credit application?</DialogContentText>
          </Grid>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
            <Button onClick={executeRequest} className={classes.requestBtn}>
              Click here to request this feature
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SendLink = ({ userProfile, account, vp }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  // const [applicantName, setApplicantName] = useState('');
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  // const [applicantNameError, setApplicantNameError] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("Required");
  const [sendVia, setSendVia] = useState("email");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmountRequested] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const handleChangedEmail = event => setEmail(event.target.value);
  // const handleChangedApplicantName = event => setApplicantName(event.target.value);
  const handleChangedBusinessName = event => setBusinessName(event.target.value);
  const handleChangedPhone = event => setPhone(event.target.value);
  // const handleChangedAmount = event => setAmountRequested(event.target.value);
  const handleChangedFirstName = event => setFirstName(event.target.value);
  const handleChangedLastName = event => setLastName(event.target.value);
  const handleSubmit = event => {
    event.preventDefault();
    sendLink().then(res => {});
  };
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);
  const [sendOCALink] = useMutation(SEND_REP_OCA, {
    context: { authRequired: true },
  });
  const [sendFeatureRequest] = useMutation(SEND_FEATURE_REQUEST, {
    context: { authRequired: true },
  });

  const [requestSent, setRequestSent] = useState(false);
  const [open, setOpen] = useState(false);

  const validate = (email, firstName, lastName) => {
    if (!email && !firstName && !lastName) {
      setEmailError(true);
      setFirstNameError(true);
      setLastNameError(true);
      return false;
    }
    if (!email) {
      setEmailError(true);
      return false;
    }
    if (!firstName) {
      setFirstNameError(true);
      return false;
    } else {
      setFirstNameError(false);
    }
    if (!lastName) {
      setLastNameError(true);
      return false;
    } else {
      setLastNameError(false);
    }
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("Required");
      return true;
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
      return false;
    }
  };

  const validateText = (phoneNum, first, last) => {
    var phoneNumFormatted = phoneNum.replace(/\D/g, "");
    if (!phoneNum && !firstName && !lastName) {
      setPhoneError(true);
      setFirstNameError(true);
      setLastNameError(true);
      return false;
    }
    if (!phoneNum) {
      setPhoneError(true);
      return false;
    }
    if (!first) {
      setFirstNameError(true);
      return false;
    } else {
      setFirstNameError(false);
    }
    if (!last) {
      setLastNameError(true);
      return false;
    } else {
      setLastNameError(false);
    }
    if (phoneNum && phoneNumFormatted.length === 10) {
      setPhoneError(false);
      setPhoneErrorMessage("Required");
      return true;
    } else {
      setPhoneError(true);
      setPhoneErrorMessage("Please enter a valid 10 digit phone number");
      return false;
    }
  };

  const requestFeature = async () => {
    setOpen(true);
  };

  const sendLink = async () => {
    if (sendVia === "email") {
      if (validate(email, firstName, lastName)) {
        try {
          await handleOpenModal();
          await handleSubmissionMessage({
            title: "Sending",
            message: "Please wait. Sending your credit app link...",
          });
          if (!amount && amount !== 0) {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  businessName: businessName || null,
                  phone: !phone ? null : phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "email",
                  accountName: account.name,
                },
              },
            });
          } else {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  amountRequested: amount,
                  businessName: businessName || null,
                  phone: !phone ? null : phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "email",
                  accountName: account.name,
                },
              },
            });
          }

          mixpanel.track("App Sent", { sentVia: "email" });

          await handleSubmissionMessage({
            title: "Success",
            message: "Sent Successfully",
          });
        } catch (err) {
          console.log(err);
          await handleSubmissionMessage({
            title: "Error",
            message: "There was something wrong with your request",
          });
        }
      }
    }
    if (sendVia === "textMessage") {
      if (validateText(phone, firstName, lastName)) {
        try {
          await handleOpenModal();
          await handleSubmissionMessage({
            title: "Sending",
            message: "Please wait. Sending your credit app link...",
          });
          if (!amount) {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  businessName: businessName || null,
                  phone: !phone ? null : phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "text",
                  to: phone,
                  accountName: account.name,
                },
              },
            }).then(resp => {
              if (!resp.data.sendVendorContactLink.success) {
                handleSubmissionMessage({
                  title: "Error",
                  message: resp.data.sendVendorContactLink.errorMessage,
                });
              } else {
                handleSubmissionMessage({
                  title: "Success",
                  message: "Sent Successfully",
                });
              }
            });
          } else {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  amountRequested: amount,
                  businessName: businessName || null,
                  phone: phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "text",
                  to: phone,
                  accountName: account.name,
                },
              },
            }).then(resp => {
              if (!resp.data.sendVendorContactLink.success) {
                handleSubmissionMessage({
                  title: "Error",
                  message: resp.data.sendVendorContactLink.errorMessage,
                });
              } else {
                handleSubmissionMessage({
                  title: "Success",
                  message: "Sent Successfully",
                });
              }
            });
          }
          mixpanel.track("App Sent", { sentVia: "text" });
        } catch (err) {
          console.log(err);
          await handleSubmissionMessage({
            title: "Error",
            message: "There was something wrong with your request",
          });
        }
      }
    }
    if (sendVia === "both") {
      if (validateText(phone, firstName, lastName) && validate(email, firstName, lastName)) {
        try {
          await handleOpenModal();
          await handleSubmissionMessage({
            title: "Sending",
            message: "Please wait. Sending your credit app link...",
          });
          if (!amount) {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  businessName: businessName || null,
                  phone: !phone ? null : phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "both",
                  to: phone,
                  accountName: account.name,
                },
              },
            }).then(resp => {
              if (!resp.data.sendVendorContactLink.success) {
                handleSubmissionMessage({
                  title: "Error",
                  message: resp.data.sendVendorContactLink.errorMessage,
                });
              } else {
                handleSubmissionMessage({
                  title: "Success",
                  message: "Sent Successfully",
                });
              }
            });
          } else {
            await sendOCALink({
              variables: {
                input: {
                  email,
                  firstName,
                  lastName,
                  amountRequested: amount,
                  businessName,
                  phone: phone.replace(/\D/g, ""),
                  dynamicsContactId: userProfile.dynamicsContactId,
                  type: "both",
                  to: phone,
                  accountName: account.name,
                },
              },
            }).then(resp => {
              if (!resp.data.sendVendorContactLink.success) {
                handleSubmissionMessage({
                  title: "Error",
                  message: resp.data.sendVendorContactLink.errorMessage,
                });
              } else {
                handleSubmissionMessage({
                  title: "Success",
                  message: "Sent Successfully",
                });
              }
            });
          }

          mixpanel.track("App Sent", { sentVia: "text and email" });

          await handleSubmissionMessage({
            title: "Success",
            message: "Sent Successfully",
          });
        } catch (err) {
          console.log(err);
          await handleSubmissionMessage({
            title: "Error",
            message: "There was something wrong with your request",
          });
        }
      }
    }
    if (sendVia === "request") {
      try {
        handleOpenModal();
        handleSubmissionMessage({
          title: "Sending",
          message: "Please wait. Sending your feature request...",
        });
        sendFeatureRequest({
          variables: {
            input: {
              feature: "text OCA to potential applicant",
              dynamicsContactId: userProfile.dynamicsContactId,
              accountName: account.name,
            },
          },
        });
        handleSubmissionMessage({
          title: "Success",
          message: "Sent Successfully",
        });
      } catch (err) {
        console.log(err);
        handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    }
  };
  const handleSetVia = method => setSendVia(method);
  const handleCloseModalRequestFeature = () => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Grid>
        <Paper>
          <CardHeader title="Send your credit app link" />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <SimpleDialog
                        userProfile={userProfile}
                        account={account}
                        sendFeatureRequest={sendFeatureRequest}
                        setRequestSent={setRequestSent}
                        open={open}
                        onClose={handleCloseModalRequestFeature}
                      />
                      <Grid item lg={3} xl={3} md={3} sm={3} xs={3}>
                        <Button color={sendVia === "email" ? "primary" : null} onClick={() => handleSetVia("email")}>
                          Via Email
                        </Button>
                      </Grid>
                      <Grid item lg={3} xl={3} md={3} sm={3} xs={3}>
                        <Button
                          color={sendVia === "textMessage" ? "primary" : null}
                          onClick={() => handleSetVia("textMessage")}>
                          Via Text
                        </Button>
                      </Grid>
                      <Grid item lg={3} xl={3} md={3} sm={3} xs={3}>
                        <Button color={sendVia === "both" ? "primary" : null} onClick={() => handleSetVia("both")}>
                          via Text and Email
                        </Button>
                      </Grid>
                      <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
                        {!!vp.altLanguageOca && vp.altLanguageOca !== "show" && (
                          <Button onClick={requestFeature} disabled={requestSent}>
                            {requestSent
                              ? "Spanish Credit Application Requested"
                              : "Spanish Credit Application Feature"}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                {sendVia === "email" && (
                  <React.Fragment>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={firstNameError === true}
                            label="Applicant First Name"
                            value={firstName}
                            onChange={handleChangedFirstName}
                            required={true}
                            type="text"
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={lastNameError === true}
                            label="Applicant Last Name"
                            value={lastName}
                            onChange={handleChangedLastName}
                            type="text"
                            required={true}
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={emailError === true}
                            label="email"
                            value={email}
                            onChange={handleChangedEmail}
                            type="text"
                            required={true}
                            helperText={emailErrorMessage}
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Applicant Business Name"
                            value={businessName}
                            onChange={handleChangedBusinessName}
                            type="text"
                            helperText="Optional"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <InputLabel htmlFor="formatted-phone-input" shrink={phone.replace(/\D/g, "").length > 0}>
                            Applicant Phone Number
                          </InputLabel>
                          <Input
                            value={phone}
                            onChange={handleChangedPhone}
                            name="phone"
                            id="formatted-phone-input"
                            inputComponent={TextMaskCustom}
                            error={phoneError}
                          />
                          <FormHelperText id="component-error-text">Optional</FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Amount Requested"
                            value={amount}
                            onChange={event => setAmountRequested(parseFloat(event.target.value) || "")}
                            helperText="Optional"
                          />
                        </FormControl>

                        <Button
                          color="primary"
                          className={classes.submitButton}
                          endIcon={<SendIcon />}
                          onClick={handleSubmit}
                          type="submit">
                          Send Credit App Link
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
                {sendVia === "textMessage" && (
                  <React.Fragment>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={firstNameError === true}
                            label="Applicant First Name"
                            value={firstName}
                            onChange={handleChangedFirstName}
                            required={true}
                            type="text"
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={lastNameError === true}
                            label="Applicant Last Name"
                            value={lastName}
                            onChange={handleChangedLastName}
                            type="text"
                            required={true}
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders} error={phoneError}>
                          <InputLabel htmlFor="formatted-phone-input" shrink={phone.replace(/\D/g, "").length > 0}>
                            Applicant Phone Number
                          </InputLabel>
                          <Input
                            value={phone}
                            onChange={handleChangedPhone}
                            name="phone"
                            id="formatted-phone-input"
                            inputComponent={TextMaskCustom}
                            error={phoneError}
                          />
                          <FormHelperText id="component-error-text">
                            {phoneError ? phoneErrorMessage : "Required"}
                          </FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Applicant Business Name"
                            value={businessName}
                            onChange={handleChangedBusinessName}
                            type="text"
                            helperText="Optional"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="email"
                            value={email}
                            onChange={handleChangedEmail}
                            type="text"
                            helperText="Optional"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Amount Requested"
                            value={amount}
                            onChange={event => setAmountRequested(parseFloat(event.target.value) || "")}
                            helperText="Optional"
                          />
                        </FormControl>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Button
                              color="primary"
                              className={classes.submitButton}
                              endIcon={<SendIcon />}
                              onClick={handleSubmit}
                              type="submit">
                              Send Credit App Link
                            </Button>
                          </Grid>
                          <Grid item lg={5} sm={6} md={5} xs={6}></Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
                {sendVia === "both" && (
                  <React.Fragment>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={firstNameError === true}
                            label="Applicant First Name"
                            value={firstName}
                            onChange={handleChangedFirstName}
                            required={true}
                            type="text"
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={lastNameError === true}
                            label="Applicant Last Name"
                            value={lastName}
                            onChange={handleChangedLastName}
                            type="text"
                            required={true}
                            helperText="Required"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            error={emailError === true}
                            label="email"
                            value={email}
                            onChange={handleChangedEmail}
                            type="text"
                            required={true}
                            helperText={emailErrorMessage}
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders} error={phoneError}>
                          <InputLabel htmlFor="formatted-phone-input" shrink={phone.replace(/\D/g, "").length > 0}>
                            Applicant Phone Number
                          </InputLabel>
                          <Input
                            value={phone}
                            onChange={handleChangedPhone}
                            name="phone"
                            id="formatted-phone-input"
                            inputComponent={TextMaskCustom}
                            error={phoneError}
                          />
                          <FormHelperText id="component-error-text">
                            {phoneError ? phoneErrorMessage : "Required"}
                          </FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Applicant Business Name"
                            value={businessName}
                            onChange={handleChangedBusinessName}
                            type="text"
                            helperText="Optional"
                          />
                        </FormControl>
                        <FormControl className={classes.formControlLenders}>
                          <TextField
                            label="Amount Requested"
                            value={amount}
                            onChange={event => setAmountRequested(parseFloat(event.target.value) || "")}
                            helperText="Optional"
                          />
                        </FormControl>

                        <Button
                          color="primary"
                          className={classes.submitButton}
                          endIcon={<SendIcon />}
                          onClick={handleSubmit}
                          type="submit">
                          Send Credit App Link
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
                {sendVia !== "email" && sendVia !== "textMessage" && sendVia !== "both" && (
                  <TableRow>
                    <TableCell>
                      Your account currently is not set up to send the credit application via text. If you would like to
                      gain access to this feature please click the button to request this feature.
                      <br />
                      <Button
                        color="primary"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        type="submit">
                        Request Feature
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Paper>
      </Grid>
      <MessageModal
        isOpen={openMessageModal}
        handleCloseModal={handleCloseModal}
        title={submissionMessage.title}
        message={submissionMessage.message}
      />
    </Grid>
  );
};

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
});

export default connect(mapStateToProps, null)(SendLink);
