import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  message: string;
}

export const NotificationAlert = ({ message }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.fillAlert}>
      <InfoIcon color="primary" />

      <Typography component="span" variant="subtitle2" color="primary">
        {message}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  fillAlert: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    paddingTop: "0.25rem",
  },
});
