import { createSelector } from "reselect";
import { SelectOption } from "global";
import * as _ from "lodash";
import { FACT_NAMES_STRING, EQUIPMENT_CLASS, EQUIPMENT_MAKE } from "@trnsact/business-criteria";
import { DeskingState } from "./types";
import { sortEntitiesByModifiedDate } from "../lib";
import { FormSections, ProductConfig } from "../types";
import { capitalizeFirstLetter } from "../../../formatters";

interface State {
  desking: DeskingState;
}

// Finance program

const financePrograms = (state: State) => state.desking.financePrograms;
const financeProgramsSelectors = (state: State): SelectOption[] =>
  sortEntitiesByModifiedDate(Object.values(financePrograms(state))).map(program => ({
    label: program.nameInternal,
    value: program.financeProgramId,
  }));

// Proposal menu

const term = (state: State) => state.desking.term;
const menuTerms = (state: State) => Object.keys(state.desking.menuBuilder);
const builder = (state: State) => state.desking.menuBuilder;
const financePrising = (state: State) => state.desking.financePrising;
const menuPricing = (state: State) => state.desking.menuPricing;
const proposalMenus = (state: State) => state.desking.proposalMenus ?? {};
const proposalMenuById = (state: State, id: string | undefined) => (id ? state.desking.proposalMenus?.[id] : null);
const layout = (state: State) => state.desking.layout;

const proposalMenuByCurrentTerm = (state: State) => {
  const builderValue = builder(state);
  const termValue = term(state);

  return builderValue?.[termValue?.term] ?? null;
};

const isPrisingExist = (state: State) => {
  const financePrisingValue = financePrising(state);
  const menuPricingValue = menuPricing(state);

  return !!Object.keys({ ...financePrisingValue, ...menuPricingValue }).length;
};

const menuByTerm = (state: State) => {
  const builderValue = builder(state);
  const termValue = term(state);

  return builderValue?.[termValue?.term]?.menuOptions ?? [];
};

const allUniqProductsFromBuilder = (state: State) => {
  const builderValue = builder(state);

  return Object.values(builderValue)
    .map(menuBuilder => menuBuilder.menuOptions.map(option => option.products))
    .flat(2);
};

const selectedMenuOption = (state: State) => {
  const menuOptions = menuByTerm(state);
  const { selectedMenuOptions } = layout(state);

  return menuOptions.find(option => option.name === selectedMenuOptions) ?? null;
};

// Proposals products

const selectedProposalsProducts = (state: State) => state.desking.proposalProducts.selectedProducts;
const selectedProposalsProductsIds = (state: State) => Object.keys(state.desking.proposalProducts.selectedProducts);
const selectedProposalsProductsArray = (state: State) => Object.values(state.desking.proposalProducts.selectedProducts);

const selectedProductsFactsForCheck = (state: State) => state.desking.proposalProducts.productsFactsToCheck;
const selectedProductsFactsSkipped = (state: State) => state.desking.proposalProducts.productFactsSkipped;

export const productsFactsForCheckArray = createSelector(
  [selectedProposalsProductsIds, allUniqProductsFromBuilder, selectedProductsFactsForCheck],
  (selectedProducts, allProductsFromBuilder, selectedProductsFacts) => {
    const allProductsSet = new Set(allProductsFromBuilder.map(product => product.proposalProductId));

    return Object.entries(selectedProductsFacts)
      .filter(([productId]) => allProductsSet.has(productId) || selectedProducts.includes(productId))
      .flatMap(([_, facts]) => facts);
  }
);

const menuOptionsGroupedByName = (state: State) => {
  const menuOption = menuByTerm(state);

  return (menuOption.reduce((acc: any, option: any) => {
    acc[option.name] = option;

    return acc;
  }, {}) ?? {}) as Record<string, any[]>;
};

const isProposalsProductsLoading = (state: State) => state.desking.proposalProducts.isLoading;
const isNeedRunJsonEngine = (state: State) => state.desking.isNeedRunJsonEngine;
const proposalsProducts = (state: State) => state.desking.proposalProducts.products;

const proposalProductConfigurations = (state: State) => state.desking.proposalProducts.productsConfiguration ?? null;
const productConfigurationById = (state: State, productId: string) =>
  state.desking.proposalProducts.productsConfiguration[productId];
const selectedProductsConfigurations = (state: State) => {
  const selectedProductsIds = selectedProposalsProductsIds(state);

  return selectedProductsIds.reduce<Record<string, ProductConfig>>((acc, productId) => {
    acc[productId] = productConfigurationById(state, productId);
    return acc;
  }, {});
};
const proposalProductConfigurationsInMenu = (state: State) => {
  const termValue = term(state);

  return state.desking.proposalProducts.productsConfigurationInMenu?.[termValue?.term] ?? null;
};

const proposalsProductsIds = (state: State) =>
  state.desking.proposalProducts.products.map(product => product.proposalProductId);

const commonProductsConfiguration = (state: State) => state.desking.proposalProducts.commonProductsConfiguration;

const isNeedRenderDynamicFields = (state: State) => !!productsFactsForCheckArray(state).length;

// Other

const recalculateStep = (state: State) => state.desking.recalculateStep;
const isSectionOpen = (state: State, section: FormSections) => state.desking.layout.sections?.[section]?.isOpen ?? true;
const isSectionCollapsed = (state: State, section: FormSections) =>
  state.desking.layout.sections?.[section]?.isCollapsed ?? true;
const sectionsStatuses = (state: State, sections: FormSections[]) =>
  sections.reduce<Partial<Record<FormSections, boolean>>>((acc, section) => {
    acc[section] = state.desking.layout.sections?.[section]?.isOpen ?? true;
    return acc;
  }, {});
const creditAppLocation = (state: State) => state.desking.creditAppLocation;
const equipment = (state: State) => state.desking.equipment.all;
const currentEquipment = (state: State) => state.desking.equipment.current;
const menuBuilderViewType = (state: State) => state.desking.menuBuilderViewType;
const isEditEquipmentStatus = (state: State) => state.desking.equipment.isEdit;
const equipmentDataById = (state: State, id: string) =>
  state.desking.equipment.all.find(equipment => equipment.equipmentId === id);

const selectFactsByKey = (factKey: string) =>
  createSelector(
    [productsFactsForCheckArray],
    facts => facts.find(fact => fact.factKey === factKey && Array.isArray(fact.options))?.options ?? []
  );

export const makeEquipmentOptions = createSelector(
  [currentEquipment, selectFactsByKey(FACT_NAMES_STRING.EQUIPMENT_MAKE)],
  (equipment, options): SelectOption[] => {
    if (!equipment) return [];

    const makeOptions = [
      ...options
        .map(option => ({ value: option, label: option }))
        .concat(...Object.values(EQUIPMENT_MAKE).map(option => ({ value: option, label: option }))),
    ];

    return _.sortBy(_.uniqBy<SelectOption>(makeOptions, "value"), "label");
  }
);

export const classEquipmentOptions = createSelector(
  [currentEquipment, selectFactsByKey(FACT_NAMES_STRING.EQUIPMENT_CLASS)],
  (equipment, options): SelectOption[] => {
    if (!equipment) return [];

    const classOptions = [
      { label: equipment.classType, value: equipment.classType },
      ...options
        .map(option => ({ value: option, label: option }))
        .concat(...Object.values(EQUIPMENT_CLASS).map(option => ({ value: option, label: option }))),
    ];

    return _.sortBy(_.uniqBy<SelectOption>(classOptions, "value"), "label");
  }
);

const equipmentSelectOptions = (state: State): SelectOption[] =>
  state.desking.equipment.all.map(equipment => ({
    label: [
      capitalizeFirstLetter(equipment?.newUsed ?? ""),
      equipment?.year,
      equipment?.make,
      equipment?.model,
      equipment?.serialNumberVin,
      equipment?.mileage,
    ]
      .filter(Boolean)
      .join(" "),
    value: equipment.equipmentId,
  })) ?? [];

const productsInMenuPrice = (state: State) => {
  const selectedMenu = selectedMenuOption(state);

  if (!selectedMenu) return 0;

  const productsConfigurationsInMenu = proposalProductConfigurationsInMenu(state);

  return Object.values(productsConfigurationsInMenu[selectedMenu.name]).reduce<number>(
    (price, config) => (price += +config?.retailCost),
    0
  );
};

const financeProgramDescription = (state: State) => {
  const term = state.desking.term.term;
  return state.desking.menuBuilder?.[term]?.menuOptions[0]?.menuOptionDetail ?? "";
};

const submissionsOptions = (state: State) => state.desking.submission.options;
const selectedSubmission = (state: State) => state.desking.submission.selectedSubmission;
const isSubmissionsLoading = (state: State) => state.desking.submission.isLoading;
const submissions = (state: State) => state.desking.submission.submissions;

export const deskingSelectors = {
  isPrisingExist,
  term,
  menuTerms,
  creditAppLocation,
  equipment,
  menuByTerm,
  currentEquipment,
  isSectionOpen,
  financePrograms,
  proposalMenus,
  recalculateStep,
  selectedProductsFactsForCheck,
  selectedProductsFactsSkipped,
  productsFactsForCheckArray,
  proposalMenuById,
  proposalsProducts,
  proposalsProductsIds,
  equipmentDataById,
  menuOptionsGroupedByName,
  selectedMenuOption,
  equipmentSelectOptions,
  financePrising,
  menuPricing,
  financeProgramsSelectors,
  selectedProposalsProducts,
  isNeedRenderDynamicFields,
  proposalMenuByCurrentTerm,
  sectionsStatuses,
  isProposalsProductsLoading,
  selectedProposalsProductsIds,
  selectedProposalsProductsArray,
  proposalProductConfigurations,
  selectedProductsConfigurations,
  proposalProductConfigurationsInMenu,
  isNeedRunJsonEngine,
  commonProductsConfiguration,
  productsInMenuPrice,
  builder,
  menuBuilderViewType,
  makeEquipmentOptions,
  isEditEquipmentStatus,
  classEquipmentOptions,
  isSectionCollapsed,
  financeProgramDescription,
  submissionsOptions,
  submissions,
  selectedSubmission,
  isSubmissionsLoading,
};
