import { ContactFormValues, RecertificationFormValues } from "./types";

export const initialContactFormValues: ContactFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: null,
  title: "",
  ssn: "",
  percOwner: "",
  workPhone: "",
  mobilePhone: "",
  homePhone: "",
  address: "",
  city: "",
  state: "",
  postalCode: "",
};

export const iniitialRecertificationFormValues: RecertificationFormValues = {
  recertificationOption: "send-task",
  recertificationDate: "",
  recertificationContactIds: [],
  file: null,
};
