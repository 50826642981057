import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { FormControl } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "components/CustomButtons/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
// import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 600,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 600,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
}));

const CREATE_NEW_LENDER_PROFILE = gql`
  mutation ($input: NewLenderProfileInput!) {
    createDealerLenderProfile(input: $input) {
      lenderProfileId
    }
  }
`;

const useUiStyles = makeStyles(styles);

const AddDealers = ({ account, vp }) => {
  const classes = useStyles();
  const uiClasses = useUiStyles();
  const [email, setEmail] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [emailError, setEmailError] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [channel, setChannel] = useState(100);
  const [lenderName, setLenderName] = useState("");
  const [lenderContactName, setLenderContactName] = useState("");
  const [lenderNameError, setLenderNameError] = useState("");
  const [lenderContactNameError, setLenderContactNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const handleChangedEmail = event => {
    setEmail(event.target.value);
  };
  const handleChangedChannel = event => {
    setChannel(event.target.value);
  };
  const handleChangedLenderName = event => {
    setLenderName(event.target.value);
  };
  const handleChangeSetLenderContactName = event => {
    setLenderContactName(event.target.value);
  };
  const handleChangedDescription = event => {
    setDescription(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    sendLenderProfile();
  };
  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };
  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };
  const [createLenderProfile] = useMutation(CREATE_NEW_LENDER_PROFILE, {
    context: { authRequired: true },
  });

  const validate = (email, lenderContactName, lenderName, description) => {
    if (!email && !lenderContactName && !lenderName && !description) {
      setEmailError(true);
      setLenderNameError(true);
      setLenderContactNameError(true);
      setDescriptionError(true);
      return false;
    }
    if (!email) {
      setEmailError(true);
      return false;
    }
    if (!lenderContactName) {
      setLenderContactNameError(true);
      return false;
    } else {
      setLenderContactNameError(false);
    }
    if (!lenderName) {
      setLenderNameError(true);
      return false;
    } else {
      setLenderNameError(false);
    }
    if (!description) {
      setDescriptionError(true);
      return false;
    } else {
      setDescriptionError(false);
    }
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("");
      return true;
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
      return false;
    }
  };
  const sendLenderProfile = async () => {
    if (validate(email, lenderName, lenderContactName, description)) {
      try {
        await handleOpenModal();
        await handleSubmissionMessage({
          title: "Sending",
          message: "Please wait. Sending your credit app link...",
        });
        await createLenderProfile({
          variables: {
            input: {
              name: lenderName,
              lenderContact: lenderContactName,
              lenderContactInfo: email,
              channel: channel,
              // accountId: account.dynamicsAccountId,
              description: description,
              // vendorProfileId: vp.dynamicsId,
              referredBy: 200,
            },
          },
        });
        await handleSubmissionMessage({
          title: "Success",
          message: "Request Sent Successfully. Please allow 24 hours to process.",
        });
      } catch (err) {
        console.log(err);
        await handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    }
  };

  return (
    <Grid container>
      <Grid>
        <Paper>
          <CardHeader title="Add New Dealer" />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <React.Fragment>
                  <TableRow>
                    <p style={{ marginTop: 16, marginLeft: 16 }}>
                      Request a new dealer to be added to Trnsact and connected to your portal.
                    </p>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={lenderNameError === true}
                          label="Dealer Name"
                          value={lenderName}
                          onChange={handleChangedLenderName}
                          required={true}
                          type="text"
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={lenderContactNameError === true}
                          label="Dealer Contact"
                          value={lenderContactName}
                          onChange={handleChangeSetLenderContactName}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={emailError === true}
                          label="Dealer Contact Email"
                          value={email}
                          onChange={handleChangedEmail}
                          type="text"
                          required={true}
                          helperText={emailErrorMessage}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <InputLabel htmlFor="simple-select">Submission Method</InputLabel>
                        <Select
                          classes={{ select: uiClasses.select }}
                          value={channel}
                          onChange={handleChangedChannel}
                          required={true}
                          inputProps={{
                            name: "channel",
                            id: "channelid",
                            required: true,
                            helpertext: "Required",
                          }}>
                          <MenuItem value={100}>Email</MenuItem>
                          <MenuItem value={200}>Internal Tracking Only</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={descriptionError === true}
                          label="Description"
                          value={description}
                          onChange={handleChangedDescription}
                          multiline
                          rows={2}
                          type="text"
                          required={true}
                          // helperText="Required"
                          helperText="Please share your relationship history with this Dealer (if any)."
                          variant="filled"
                        />
                      </FormControl>
                      <Button color="primary" className={classes.submitButton} onClick={handleSubmit} type="submit">
                        Send Dealer Request
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              </TableBody>
            </Table>
          </CardContent>
        </Paper>
      </Grid>
      <MessageModal
        isOpen={openMessageModal}
        handleCloseModal={handleCloseModal}
        title={submissionMessage.title}
        message={submissionMessage.message}
      />
    </Grid>
  );
};

export default AddDealers;

// const mapStateToProps = (state) => ({
//   account: state.account,
//   vp: state.vp,
// });

// export default connect(
//   mapStateToProps,
//   null
// )(AddLender);
