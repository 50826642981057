import React, { useMemo } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DetailsCellDefaultProps } from "../../../../types";
import { Visibility } from "@mui/icons-material";

interface Props extends DetailsCellDefaultProps {
  onOpenDetailsDialog: any;
}

export const DetailsActionCell = ({ details, onOpenDetailsDialog }: Props) => {
  const menuOption = useMemo(() => {
    const selectedMenuOption = details?.paymentCriteriaSelected?.menuOption;
    const menuOption = details?.paymentCriteria?.menu?.menuOption;

    return selectedMenuOption ?? menuOption;
  }, [details]);

  return (
    <Box>
      <Tooltip title="Open details dialog">
        <IconButton size="small" color="primary" onClick={() => onOpenDetailsDialog(menuOption)}>
          <Visibility color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
