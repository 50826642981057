import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import SecureOCAInstructions from "./secureOCA";
import ProductWidgetInstruction from "./productWidget";
import SalesQuotesInstructions from "./salesQuotes";
import EmailSignaturesInstructions from "./emailSignatures";

const _ = require("lodash");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles(theme => ({}));

const WebsiteIntegrationTool = props => {
  const classes = useStyles();

  const [tourEnabled, setTourEnabled] = useState(props.tourEnabled);
  //Tab manager
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Secure Credit Application" />
          <Tab label="eCommerce Product Widget" />
          <Tab label="Sales Quotes" />
          <Tab label="Email Signatures" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SecureOCAInstructions />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductWidgetInstruction />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SalesQuotesInstructions />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EmailSignaturesInstructions />
      </TabPanel>
    </div>
  );
};

export default WebsiteIntegrationTool;
