import React, { useRef, useState } from "react";
import ReactTable from "react-table";
import moment from "moment";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import makeStyles from "@mui/styles/makeStyles";
import { Query } from "@apollo/client/react/components";
import Tile from "../../components/Tile/Tile";
import ViewListIcon from "@mui/icons-material/ViewList";
import { formatCurrency } from "../../utils";
import _ from "lodash";
import TableSearch from "components/Table/TableSearch.js";
import DragIndicator from "assets/img/drag-indicator.svg";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
};

const useStyles = makeStyles(styles);

const GET_VO_LIST = gql`
  query {
    vendorOpportunitiesAdmin(limit: 100) {
      vendorOpportunityId
      dynamicsVendorOpportunityId
      createdDate
      invoiceTotal
      applicationStage
      accountPrimaryContactName
      accountPrimaryContactId
      invoiceStatus
      statusDetail
      name
      potentialCustomer {
        name
      }
      salesRepresentative {
        fullName
      }
      equipmentList
      equipmentDescription
    }
  }
`;

function PageTransactionsList(props) {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 10 });
  const [pageSizeValue, setPageSizeValue] = useState(10);

  function handleVORowClick(vendorOpportunityId) {
    props.history.push(`/adminvo/${vendorOpportunityId}`);
  }

  const filterVOListData = VOListData => {
    const { currentSearch } = searchRef.current;

    console.log(VOListData, "filterVOListData");

    if (currentSearch.length === 0) {
      return VOListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = VOListData.vendorOpportunitiesAdmin.filter(el => {
      if (
        typeof el.createdDate === "string" ||
        typeof el.customerName === "string" ||
        typeof el.name === "string" ||
        typeof el.applicationStage === "string" ||
        typeof el.salesRep === "string" ||
        typeof el.invoiceTotal === "number" ||
        typeof el.equipmentList === "string"
      ) {
        return (
          (el.createdDate || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.customerName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.name || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.applicationStage || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.salesRep || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.invoiceTotal) || "").includes(lowerCaseSearch) ||
          (el.equipmentList || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return { ...VOListData, vendorOpportunitiesAdmin: [...searchData] };
  };

  function processVOListData(VOListData) {
    if (VOListData && _.isArray(VOListData.vendorOpportunitiesAdmin)) {
      VOListData.vendorOpportunitiesAdmin.forEach(item => {
        if (item.applicationStage === "pre_qualified") {
          item.applicationStage = "prequalified";
        }
        if (!item.applicationStage) {
          item.applicationStage = null;
        } else {
          item.applicationStage = item.applicationStage.charAt(0).toUpperCase() + item.applicationStage.slice(1);
        }
        if (item.potentialCustomer) {
          item.customerName = item.potentialCustomer.name;
        }
        if (item.salesRepresentative === null) {
          item.salesRep = "";
        } else {
          item.salesRep = item.salesRepresentative.fullName;
        }
        if (item.equipmentList === null) {
          item.equipmentList = item.equipmentDescription;
        }
        item.actions = null;
      });
      return [...VOListData.vendorOpportunitiesAdmin];
    }

    return [];
  }

  return (
    <Query context={{ authRequired: true }} query={GET_VO_LIST} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return <h3>{String(error)}</h3>;

        const voListData = processVOListData(filterVOListData(data));
        return (
          <div className={classes.root}>
            <GridContainer>
              <GridItem xs={12} className={classes.fixReactTable}>
                <Tile titleIcon={<ViewListIcon />}>
                  <TableSearch
                    setPageNumber={setPageNumber}
                    setSavedListSettings={setSavedListSettings}
                    searchRef={searchRef}
                    savedListSettings={savedListSettings}
                  />
                  <ReactTable
                    data={voListData}
                    getTrProps={(state, rowInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => handleVORowClick(rowInfo.original.vendorOpportunityId),
                    })}
                    columns={[
                      {
                        Header: "Created On",
                        accessor: "createdDate",
                        Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                        Filter: () => <></>,
                        resizable: false,
                      },
                      { Header: "Customer", accessor: "customerName" },
                      { Header: "Account Name", accessor: "name" },
                      { Header: "Stage", accessor: "applicationStage" },
                      { Header: "Sales Rep", accessor: "salesRep" },
                      {
                        Header: "Amount",
                        accessor: "invoiceTotal",
                        Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
                      },
                      {
                        Header: "Equipment Details",
                        accessor: "equipmentList",
                        Cell: props => <div>{props.value ? props.value : ""}</div>,
                      },
                    ]}
                    defaultPageSize={savedListSettings.pageSize}
                    pageSize={pageSizeValue}
                    onPageChange={changePage => {
                      setPageNumber(changePage);
                    }}
                    onPageSizeChange={changePageSize => {
                      setPageSizeValue(changePageSize);
                      setPageNumber(0);
                    }}
                    page={pageNumber}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    customCellClasses={[classes.right]}
                    customClassesForCells={[5]}
                    className="-striped -highlight"
                  />
                </Tile>
              </GridItem>
            </GridContainer>
          </div>
        );
      }}
    </Query>
  );
}

export default PageTransactionsList;
