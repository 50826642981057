import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CS_REFETCH } from "redux/types";
import gql from "graphql-tag";
import { formatCurrency } from "utils";

import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
  ListItemButton,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import { GuarantorListWithCreditPull } from "components/GuarantorListWithCreditPull";
import _ from "lodash";

const QUERY_VENDOR_PROFILE = gql`
  query ($id: ID, $dynamicsId: ID, $userProfileId: ID, $VOId: ID) {
    vendorProfile(id: $id, dynamicsId: $dynamicsId, userProfileId: $userProfileId, VOId: $VOId) {
      id
      name
      dynamicsId
      showDocMgt
      showLenderDocsAccessMgmt
      softPullInDealerPortal
      ofacRedFlagComplianceAccess
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      logo
      dcrDisclosureLanguage
      dcrDisclosureTerms
    }
  }
`;

const POST_APPLICATION_STAGE = gql`
  mutation ($entityId: String!, $stage: Int) {
    updateLenderApplicationStage(entityId: $entityId, stage: $stage)
  }
`;

interface Props {
  cs: any;
  vo: any;
  creditApplication: any;
  lendersVendorProfile: any;
  setVendorProfile: any;
  location: any;
  isCreditReportsHiddenForUserRole: any;
  refetchCreditApp: any;
  handleOpenCreditReportDocument: any;
  goToCADView: VoidFunction;
}

export default function ApplicationSummary_V2({
  cs,
  vo,
  creditApplication,
  lendersVendorProfile,
  setVendorProfile,
  location,
  isCreditReportsHiddenForUserRole,
  refetchCreditApp,
  handleOpenCreditReportDocument,
  goToCADView,
}: Props) {
  const APPLICANT_OPEN_STATE_KEY: string = "applicant";
  const SUBMITTED_BY_OPEN_STATE_KEY: string = "submittedBy";

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const refetchCS = useSelector((state: any) => state.cs.refetch);
  const userProfile = useSelector((state: any) => state.userProfile);

  const applicationStageOptions = [
    { code: 804790003, label: "Lender Approved" },
    { code: 804790007, label: "Docs Out" },
    { code: 804790008, label: "Docs In" },
    { code: 804790009, label: "Funding Review" },
    { code: 804790010, label: "Funding Items Requested" },
    { code: 804790020, label: "Funded" },
  ];
  const decisionObj = _.find(applicationStageOptions, { label: cs.stage });

  const [listItemOpenState, setListItemOpenState] = useState<any>({});
  const [appStage, setAppStage] = useState(decisionObj ? decisionObj.code : 804790003);

  const [updateLenderApplicationStage] = useMutation(POST_APPLICATION_STAGE);
  const { data: dataVendorProfile } = useQuery(QUERY_VENDOR_PROFILE, {
    variables: {
      VOId: cs.voId,
    },
    context: { authRequired: true },
  });

  useEffect(() => {
    if (dataVendorProfile !== null && dataVendorProfile !== undefined) {
      setVendorProfile(dataVendorProfile);
    }
  }, [dataVendorProfile]);

  const handleViewDetails = () => {
    goToCADView();
  };

  const lookupStage = (
    <Select
      value={appStage}
      onChange={(e: any) => {
        setAppStage(e.target.value);
        handleSetStage(e.target.value);
      }}
      inputProps={{
        name: "stage",
        id: "stage",
      }}>
      {applicationStageOptions.map(({ code, label }, i) => {
        return (
          <MenuItem value={code} key={i}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );

  const handleSetStage = async (stageCode: any) => {
    try {
      const result = await updateLenderApplicationStage({
        variables: {
          entityId: cs.creditSubId.toString(),
          stage: stageCode,
        },
      });

      dispatch({
        type: SET_CS_REFETCH,
        payload: { refetch: !refetchCS },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getApplicantLayout = () => {
    if (_.isEmpty(vo) || _.isEmpty(creditApplication)) {
      return;
    }

    const ownersToShow = _([...[creditApplication.primaryContact], ...creditApplication.creditApplicationOwner])
      .compact()
      .uniqBy("ownerPgId")
      .value();

    return (
      <>
        <ListItemButton
          onClick={() => {
            setListItemOpenState((prev: any) => {
              return {
                ...prev,
                [APPLICANT_OPEN_STATE_KEY]: !prev[APPLICANT_OPEN_STATE_KEY],
              };
            });
          }}
          key={"applicant"}>
          <Grid container>
            <Grid item xs={6} style={{ display: "flex" }}>
              <RowNameText text={"Applicant"} />
              {listItemOpenState[APPLICANT_OPEN_STATE_KEY] ? <ExpandLess /> : <ExpandMore />}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => {
                  history.push(`/accountDetails/${vo.potentialCustomer.id}`);
                }}>
                {cs.applicant}
              </Typography>
            </Grid>
          </Grid>
        </ListItemButton>

        <Divider />
        <Collapse in={listItemOpenState[APPLICANT_OPEN_STATE_KEY]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {ownersToShow.map((owner, idx) => {
              return (
                <React.Fragment key={owner.ownerPgId}>
                  <ListItem className={classes.nestedListItem}>
                    <Grid item xs={6} style={{ paddingLeft: 15 }}>
                      <RowNameText text={`Contact ${idx + 1}`} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        className={classes.link}
                        onClick={() => {
                          history.push(`/ownerPgDetails/${owner.ownerPgId}`);
                        }}>
                        {`${owner.firstName} ${owner.lastName}`}
                      </Typography>

                      {owner.pc && <Chip style={{ marginLeft: "8px" }} variant="outlined" size="small" label="POC" />}
                      {owner.signature && (
                        <Chip style={{ marginLeft: "8px" }} variant="outlined" size="small" label="Signor" />
                      )}
                      {owner.pg && (
                        <Chip style={{ marginLeft: "8px" }} variant="outlined" size="small" label="Guarantor" />
                      )}
                    </Grid>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  };

  const getDealerContactsLayout = () => {
    if (_.isEmpty(vo)) {
      return;
    }

    const dealerUsersToShow = _([vo.salesRepresentative, vo.salesManager, vo.financeManager])
      .compact()
      .uniqBy("email")
      .value();

    return dealerUsersToShow.map((user, idx) => {
      return (
        <>
          <ListItemButton
            onClick={() => {
              setListItemOpenState((prev: any) => {
                return {
                  ...prev,
                  [user.id]: !prev[user.id],
                };
              });
            }}
            key={user.id}>
            <Grid item xs={6} style={{ display: "flex" }}>
              <RowNameText text={`Dealer Contact ${idx + 1}`} />
              {listItemOpenState[user.id] ? <ExpandLess /> : <ExpandMore />}
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <AccountCircleIcon style={{ color: "#4994ec", marginRight: 8 }} />
              <RowValueText text={`${user.fullName}${user.title ? `, ${user.title}` : ``} `} />
            </Grid>
          </ListItemButton>
          <Divider />
          <Collapse in={listItemOpenState[user.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {user.phoneNumber && (
                <ListItem className={classes.nestedListItem}>
                  <Grid item xs={6} style={{ paddingLeft: 15 }}>
                    <RowNameText text={"Phone"} />
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex" }}>
                    <PhoneIcon fontSize="small" style={{ marginRight: 8 }} />
                    <RowValueText text={user.phoneNumber} />
                  </Grid>
                </ListItem>
              )}
              <Divider />
              {user.email && (
                <ListItem className={classes.nestedListItem}>
                  <Grid item xs={6} style={{ paddingLeft: 15 }}>
                    <RowNameText text={"Email"} />
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex" }}>
                    <EmailIcon fontSize="small" style={{ marginRight: 8 }} />
                    <RowValueText text={user.email} />
                  </Grid>
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      );
    });
  };

  const getSubmittedByLayout = () => {
    if (_.isEmpty(vo)) {
      return;
    }

    return (
      <>
        <ListItemButton
          onClick={() => {
            setListItemOpenState((prev: any) => {
              return {
                ...prev,
                [SUBMITTED_BY_OPEN_STATE_KEY]: !prev[SUBMITTED_BY_OPEN_STATE_KEY],
              };
            });
          }}
          key={"submittedBy"}>
          <Grid container>
            <Grid item xs={6} style={{ display: "flex" }}>
              <RowNameText text={"Submitted By"} />
              {listItemOpenState[SUBMITTED_BY_OPEN_STATE_KEY] ? <ExpandLess /> : <ExpandMore />}
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <AccountCircleIcon style={{ color: "#4994ec", marginRight: 8 }} />
              <RowValueText text={cs.contact} />
            </Grid>
          </Grid>
        </ListItemButton>

        <Divider />
        <Collapse in={listItemOpenState[SUBMITTED_BY_OPEN_STATE_KEY]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {cs.contactPhone && (
              <>
                <Divider />
                <ListItem className={classes.nestedListItem}>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingLeft: 15 }}>
                      <RowNameText text={"Phone"} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <PhoneIcon fontSize="small" style={{ marginRight: 8 }} />
                      <RowValueText text={cs.contactPhone} />
                    </Grid>
                  </Grid>
                </ListItem>
              </>
            )}
            {cs.contactEmail && (
              <>
                <Divider />
                <ListItem className={classes.nestedListItem}>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingLeft: 15 }}>
                      <RowNameText text={"Email"} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <EmailIcon fontSize="small" style={{ marginRight: 8 }} />
                      <RowValueText text={cs.contactEmail} />
                    </Grid>
                  </Grid>
                </ListItem>
              </>
            )}
            <Divider />
          </List>
        </Collapse>
      </>
    );
  };

  const isLoading = !cs || !vo || !creditApplication;

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          style={{ margin: "10px 10px 0 10px" }}
          title="Application Summary"
          action={
            !isLoading && (
              <Button variant="outlined" color="primary" size="small" onClick={handleViewDetails}>
                View Details
              </Button>
            )
          }
        />
        <CardContent>
          {isLoading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            <List component="nav" aria-labelledby="nested-list-subheader">
              {getApplicantLayout()}
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Request Amount"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={cs.amountRequested ? formatCurrency(cs.amountRequested.toFixed(2)) : ""} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Guarantor(s)"} />
                  </Grid>
                  <Grid item xs={6}>
                    <GuarantorListWithCreditPull
                      vo={vo}
                      creditApp={creditApplication}
                      creditSubmission={cs}
                      vp={lendersVendorProfile}
                      refetchCreditApp={refetchCreditApp}
                      handleOpenCreditReportDocument={handleOpenCreditReportDocument}
                      isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
                      key={"guarantor-list-with-credit-pull"}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Submitted On"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={cs.submissionDate} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Dealer"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={cs.dealer} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              {location && (
                <ListItem>
                  <Grid container>
                    <Grid item xs={6}>
                      <RowNameText text={"Dealer Location"} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <LocationOnSharpIcon fontSize="small" style={{ marginRight: 8 }} />
                      <RowValueText text={location.locationName} />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
              <Divider />
              {cs.syndicatedBy ? getDealerContactsLayout() : getSubmittedByLayout()}
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Stage"} />
                  </Grid>
                  <Grid item xs={6}>
                    {cs.decision === "Approved" ? lookupStage : <RowValueText text={cs.stage} />}
                  </Grid>
                </Grid>
              </ListItem>
              {cs.masterLenderName && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <RowNameText text={"Syndicated From"} />
                      </Grid>
                      <Grid item xs={6}>
                        <RowValueText text={cs.masterLenderName} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
              {cs.syndicatedBy && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <RowNameText text={"Syndicated By"} />
                      </Grid>
                      <Grid item xs={6}>
                        <RowValueText text={cs.syndicatedBy.fullName} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          )}
        </CardContent>
      </Card>
    </>
  );
}

const RowNameText = ({ text }: { text: string }) => {
  const classes = useStyles();
  return <Typography className={classes.listRowName}>{text || ""}</Typography>;
};

const RowValueText = ({ text, typographyProps }: { text: string; typographyProps?: any }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.listRowValue} {...typographyProps}>
      {text || ""}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  listRowName: {
    color: "#666666",
    fontWeight: 500,
  },
  listRowValue: {
    color: "#212121",
  },
  nestedListItem: {
    background: "#fafafa",
  },
  card: {
    margin: 0,
  },
}));
