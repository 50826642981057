import React from "react";
import clsx from "clsx";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import { createTableColumns } from "./tableConfig";
import { PageTypes } from "../../../../global";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { CustomPagination } from "../../../Table/Pagination";
import { usePagination } from "../../../../hooks/usePagination";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  handleResend: any;
  processedData: any[];
  pageType: PageTypes;
  isTableDataLoading: boolean;
}

export const ProposalTable = ({ processedData, handleResend, isTableDataLoading, pageType }: Props) => {
  const history = useHistory();

  const classes = useStyles();

  const paginationProps = usePagination(processedData?.length);

  const columns = createTableColumns({ handleResend, pageType });

  const handleProposalRowClick = (propId: string) => {
    history.push(`/proposalDetail/${propId}`);
  };

  return (
    <ReactTable<Proposal>
      data={processedData}
      getTrProps={(_: any, rowInfo: any) => ({
        style: { cursor: "pointer" },
        onClick: () => handleProposalRowClick(rowInfo.original.id),
      })}
      columns={columns}
      showPaginationBottom
      showPaginationTop={false}
      loading={isTableDataLoading}
      loadingText={"Loading proposals"}
      PaginationComponent={CustomPagination}
      className={clsx("-highlight", classes.table)}
      noDataText={isTableDataLoading ? "" : "There are no proposals"}
      {...paginationProps}
    />
  );
};

const useStyles = makeStyles({
  table: {
    minHeight: "30rem",

    "& .rt-thead": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },

    "& .rt-tr": {
      margin: 0,
      minHeight: "4rem",
    },

    "& .rt-td": {
      margin: 0,
    },
  },
});
