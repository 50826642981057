import React from "react";
import { TableView } from "../TableView";
import { HubProposal } from "../../../api/types";
import { createTableColumns } from "./tableConfig";

interface Props {
  loading?: boolean;
  tableData: HubProposal[];
  onGoToProposal: (proposal: HubProposal) => void;
  onGoToProposalDetail: (proposal: HubProposal) => void;
}

export const HubTable = ({ tableData, onGoToProposal, onGoToProposalDetail, loading }: Props) => {
  const columns = createTableColumns({
    tableData,
    onGoToProposal,
    onGoToProposalDetail,
  });

  return (
    <TableView<HubProposal>
      loading={loading}
      columns={columns}
      minHeight="33.5rem"
      tableData={tableData}
      noData="No data about proposals"
      loadingText="Proposals loading..."
    />
  );
};
