import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import ReactTable from "react-table";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import { Query } from "@apollo/client/react/components";
import Tile from "../../components/Tile/Tile";
import Button from "../../components/CustomButtons/Button";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { formatCurrency, OpportunityTypes, vendorContactRole as vendorContactRoles } from "../../utils";
import { FINANCING_TYPE } from "@trnsact/trnsact-shared-types";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "../../mixpanel";
import CircularProgress from "@mui/material/CircularProgress";
import rocketBk from "assets/img/rocket-ft.png";
import rocketGreen from "assets/img/rocket-ft-green.png";
import rocketGrey from "assets/img/rocket-ft-grey.png";
import rocketFastTrack from "assets/img/big-rocket.svg";
import TableSearch from "components/Table/TableSearch";
import DragIndicator from "assets/img/drag-indicator.svg";
import { SETUP_FAST_TRACK_MODAL } from "../../redux/types";
import { MobileContext } from "contexts/MobileContext";
import MobileList from "components/Mobile/MobileOpportunitiesList.js";
import FastTrackInfoModal from "components/FastTrackInfoModal";
import LocationsTreeViewCheckboxes from "components/LocationsTreeViewCheckboxes/LocationsTreeViewCheckboxes";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import { theme } from "../../theme";
import { FormInputSelect } from "../../components/form";
import { logError } from "../../utils/logger";
import { portalConfigurationTypes } from "../Prequal/constants";
import { portalConfigurationsSelectors } from "../../redux/portalConfigurationReducer";
import { useHistory } from "react-router-dom";

const config = require("../../config");

const optionsButton = [
  {
    title: "Unarchive",
    value: 100,
  },
  {
    title: "Archive, Duplicate",
    value: 201,
  },
  {
    title: "Archive, Testing",
    value: 200,
  },
  {
    title: "Archive, Other",
    value: 202,
  },
];

const GET_VO_LIST = gql`
  query VendorOpportunitiesList($offset: Int) {
    vendorOpportunities(limit: 500, offset: $offset) {
      id
      vendorOpportunityId
      dynamicsVendorOpportunityId
      createdOn
      displayStatus
      invoiceTotal
      applicationStage
      accountPrimaryContactName
      accountPrimaryContactId
      invoiceStatus
      statusDetail
      transactionStage
      applicationType
      applyingFor
      partnerName
      dealerName
      partnerRepName
      partnerLogo
      entityType
      location {
        locationName
        locationId
        category
        locationParentId
      }
      owner {
        firstName
        lastName
        email
        phone
      }
      contact {
        firstName
        lastName
      }
      potentialCustomer {
        name
      }
      salesRepresentative {
        id
        fullName
      }
      salesManager {
        id
        fullName
      }
      financeManager {
        id
        fullName
      }
      equipmentList
      equipmentDescription
      fastTrackResults {
        creditSubmissionId
        fastTrackId
        applicationStatus
        rate
        maxApprovalAmount
        approvedBy
        inputs {
          key
          value
        }
        outputs {
          key
          value
        }
      }
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const UPDATE_ROW_DISPLAY_STATUS = gql`
  mutation ($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const Q_CREDIT_APP = gql`
  query ($VOId: ID!) {
    creditApplication(VOId: $VOId) {
      firstName
      lastName
      email
      phone
    }
  }
`;

function PageTransactionsList() {
  const history = useHistory();

  const mobileContext = useContext(MobileContext);
  const classes = useStyles();
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [resized, setResized] = useState(undefined);
  const [moreVOs, setMoreVOs] = useState([]);
  const [offset, setOffset] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const prevData = useRef([]);
  const dragged = useRef(null);
  const [accountType, setAccountType] = useState("Vendor");
  const updatedVORef = useRef(null);

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const vp = useSelector(state => state.vp);
  const opportunitiesPortalConfigurations = useSelector(state =>
    portalConfigurationsSelectors.configDataByType(state, portalConfigurationTypes.opportunities)
  );

  const [dateRange, setDateRange] = useState([{ startDate: new Date(), endDate: null, key: "selection" }]);

  const [getCreditApp, { data: creditAppData }] = useLazyQuery(Q_CREDIT_APP, { fetchPolicy: "no-cache" });

  const fetchVOsLazyMode = data => {
    addDnDToColumns();

    const moreVosAvailable = data.vendorOpportunities.length === 500;

    if (moreVosAvailable) {
      console.log(`Current offset for VOs is: ${offset}`);
      const newOffset = offset + 500;
      console.log(`Next offset for VOs is: ${newOffset}`);
      setOffset(newOffset);
      getMoreVOs({ variables: { offset: newOffset } });
    }
  };

  const [getMoreVOs, { data: moreVOData, loading: moreVOsLoading }] = useLazyQuery(GET_VO_LIST, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      fetchVOsLazyMode(data);
    },
  });

  const [showLocation, setShowLocation] = useState(true);
  const [showDateRange, setShowDateRange] = useState(false);
  const resizedDealerList = useRef([
    { id: "createdOn", value: 200 },
    { id: "fastTrack", value: 200 },
    { id: "customerName", value: 300 },
    { id: "equipmentList", value: 165 },
    { id: "contact", value: 240 },
    { id: "signor", value: 140 },
    { id: "applicationStage", value: 160 },
    { id: "transactionStage", value: 220 },
    { id: "salesRep", value: 140 },
    { id: "salesManager", value: 170 },
    { id: "financeManager", value: 180 },
    { id: "Location", value: 220 },
    { id: "invoiceTotal", value: 140 },
    { id: "equipmentList", value: 140 },
    { id: "actions", value: 140 },
    { id: "invoiceStatus.name", value: 140 },
    { id: "statusDetail", value: 140 },
    { id: "applicationType", value: 140 },
    { id: "source", value: 140 },
    { id: "entityType", value: 180 },
    { id: "displayStatus", value: 200 },
  ]);

  const resizedPartnerList = useRef([
    { id: "createdOn", value: 200 },
    { id: "customerName", value: 350 },
    { id: "dealerName", value: 300 },
    { id: "equipmentList", value: 165 },
    { id: "applicationStage", value: 200 },
    { id: "transactionStage", value: 200 },
    { id: "invoiceTotal", value: 120 },
    { id: "equipmentList", value: 140 },
    { id: "actions", value: 140 },
    { id: "statusDetail", value: 140 },
    { id: "displayStatus", value: 140 },
    { id: "salesRep", value: 140 },
    { id: "salesManager", value: 170 },
    { id: "financeManager", value: 180 },
    { id: "partnerRepName", value: 140 },
  ]);

  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  const IS_FAST_TRACK_ENABLED =
    vp.fastTrackSetting === "YES_SET_CAMUNDA_PROCESS_CODE" ||
    vp.fastTrackSetting === "YES_AUTOMATED_FOR_DEALER" ||
    vp.fastTrackSetting === "YES_BUT_DEALER_MUST_TRIGGER";

  const [refreshingList, setRefreshingList] = useState(null);
  const [locations, setLocations] = useState();
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [fastTrackInfo, setFastTrackInfo] = useState(false);

  const tabsSettings = [
    {
      key: "myOpportunities",
      label: "My Opportunities",
      hiddenFor: [vendorContactRoles.sales_rep],
    },
    {
      key: "allOpportunities",
      label: "All Opportunities",
      hiddenFor: [vendorContactRoles.sales_rep],
    },
    {
      key: "unassigned",
      label: "Unassigned",
      hiddenFor: [vendorContactRoles.sales_rep],
    },
  ];

  const visibleTabs = _.reject(tabsSettings, tab => _.includes(tab.hiddenFor, userProfile.vendorContactRole));
  const [tabsToShow, setTabsToShow] = useState(visibleTabs);

  let activeTabSetting = JSON.parse(window.localStorage.getItem("activeTabSetting"));
  const [activeTab, setAсtiveTab] = useState(
    activeTabSetting === null ? _.first(tabsToShow) : tabsToShow[activeTabSetting]
  );

  const dispatch = useDispatch();

  const handleDataRangeOpen = () => {
    setShowDateRange(true);
  };

  const handleCloseDataRange = () => {
    setShowDateRange(false);
  };

  useEffect(() => {
    setRefreshingList(moreVOsLoading);
  }, [moreVOsLoading]);

  const handleEasyButton = (e, id) => {
    e.preventDefault();
    getCreditApp({
      variables: {
        VOId: id,
      },
    });
  };

  function handleFastTrackClick(e, vendorOpportunityDatabaseId) {
    e.stopPropagation();
    window.location.href = `/vo/${vendorOpportunityDatabaseId}?fastTrackModal=true`;
  }

  if (creditAppData) {
    dispatch({
      type: SETUP_FAST_TRACK_MODAL,
      payload: {
        creditAppData,
      },
    });
  }

  const renderFastTrackBtn = props => {
    let rocketImg = null;
    let btn = null;
    const isCashSale = props.row._original.entityType === "CASH_SALE";

    if (!isCashSale) {
      if (props.row._original.fastTrackResults && props.row._original.fastTrackResults.length > 0) {
        switch (props.row._original.fastTrackResults[0].applicationStatus) {
          case "AWAITING_LENDER_APPROVAL":
            rocketImg = rocketGrey;
            btn = (
              <Button
                size="sm"
                onClick={e => handleEasyButton(e, props.row._original.id)}
                className={classes.btnFunded}
                style={{ background: "none", color: "#000", boxShadow: "none" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Awaiting Lender
              </Button>
            );
            break;
          case "PROPOSAL_SENT":
          case "PROPOSAL_VIEWED":
            rocketImg = rocketGrey;
            btn = (
              <Button
                size="sm"
                onClick={e => handleEasyButton(e, props.row._original.id)}
                className={classes.btnFunded}
                style={{ background: "none", color: "#000", boxShadow: "none" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Proposal Sent
              </Button>
            );
            break;
          case "AWAITING_DEALER_APPROVAL":
            rocketImg = rocketBk;
            btn = (
              <Tooltip title="Preview & Send financing options to your customer">
                <Button
                  size="sm"
                  onClick={e => handleEasyButton(e, props.row._original.id)}
                  className={classes.btnFunded}
                  style={{ background: "#834bff", fontWeight: "bold" }}>
                  <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                  Send Proposal
                </Button>
              </Tooltip>
            );
            break;
          case "REQUIRES_DEALER_ATTENTION":
            rocketImg = rocketBk;
            btn = (
              <Button
                color={"primary"}
                size="sm"
                onClick={e => handleEasyButton(e, props.row._original.id)}
                className={classes.btnFunded}
                style={{ background: "orange" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Requires Attention
              </Button>
            );
            break;
          case "PROPOSAL_ACCEPTED":
            rocketImg = rocketGreen;
            btn = (
              <span className={classes.fundedFastLbl}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Proposal Accepted
              </span>
            );
            break;
          case "LENDER_DECLINED":
            rocketImg = rocketGrey;
            btn = (
              <span style={{ background: "none", color: "red", opacity: "0.7", boxShadow: "none", fontWeight: "600" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Lender Declined
              </span>
            );
            break;
          case "PRESCREEN_FAILED":
            rocketImg = rocketGrey;
            btn = (
              <span
                className={classes.btnFunded}
                style={{ background: "none", color: "red", opacity: "0.7", boxShadow: "none", fontWeight: "600" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Prescreen Failed
              </span>
            );
            break;
          case "CANCELED":
            rocketImg = rocketGrey;
            btn = (
              <span style={{ background: "none", color: "red", opacity: "0.7", boxShadow: "none", fontWeight: "600" }}>
                <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
                Canceled
              </span>
            );
            break;
        }
      } else {
        // If no fasttrack results, render button to start the FT process
        rocketImg = rocketBk;
        btn = (
          <Tooltip title="Auto-submit this deal to your preferred lender waterfall">
            <Button
              color={"primary"}
              size="sm"
              onClick={e => {
                if (props.row._original.entityType !== "CASH_SALE") handleFastTrackClick(e, props.row._original.id);
              }}
              className={classes.btnFunded}
              style={{ fontWeight: "bold" }}>
              <img src={rocketImg} className={classes.iconEasyBtn} alt="" />
              Fast Track It!
            </Button>
          </Tooltip>
        );
      }
      return <div>{btn}</div>;
    }
    return <div></div>;
  };

  const initialDealerColumns = [
    {
      Header: "Created On",
      accessor: "createdOn",
      minWidth: 150,
      resizable: false,
      sortMethod: (a, b) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        return dateA - dateB;
      },
      Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
      Filter: item =>
        item.filter && item.filter.value[0].endDate === null ? (
          <Button color="primary" className={classes.dateRangeButton} onClick={handleDataRangeOpen}>
            Filter by Date
          </Button>
        ) : (
          <div onClick={handleDataRangeOpen}>{`${moment(item.filter && item.filter.value[0].startDate).format(
            "ll hh:mma"
          )} - ${moment(item.filter && item.filter.value[0].endDate).format("ll hh:mma")}`}</div>
        ),
      filterMethod: (filter, row) => {
        const createdOn = new Date(row.createdOn);
        const startDate = filter.value[0].startDate;
        const endDate = filter.value[0].endDate;
        if (endDate) {
          if (startDate <= createdOn && createdOn <= endDate) {
            return row;
          }
        } else {
          return true;
        }
      },
      visible: true,
    },
    {
      Header: "Source",
      id: "source",
      minWidth: 150,
      accessor: "partnerLogo",
      visible: false,
      Cell: props => (
        <div className={classes.partnerLogo}>
          {props.value ? (
            <img src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${props.value}`} />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      Header: props => (
        <div className={classes.headerFastTrack}>
          <img className={classes.ftIcon} src={rocketFastTrack} />
          <span>FastTrack</span>
          <InfoIcon
            style={{ fontSize: 20, color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => setFastTrackInfo(true)}
          />
        </div>
      ),
      accessor: "fastTrack",
      disableSortBy: true,
      sortable: false,
      minWidth: 240,
      Cell: props => renderFastTrackBtn(props),
      visible: IS_FAST_TRACK_ENABLED,
      id: "fastTrack",
    },
    {
      Header: "Customer",
      accessor: "customerName",
      id: "customer",
      minWidth: 150,
      visible: true,
    },
    {
      Header: "Contact",
      id: "contact",
      minWidth: 150,
      Cell: props => {
        if (
          (props.original &&
            props.original.contact &&
            props.original.contact.lastName &&
            props.original.contact.firstName) ||
          props.original.accountPrimaryContactName
        ) {
          if (props.original.contact.firstName && props.original.contact.lastName) {
            return `${props.original.contact.firstName} ${props.original.contact.lastName}`;
          } else {
            return `${props.original.accountPrimaryContactName}`;
          }
        }

        return "";
      },
      Filter: () => <></>,
      visible: accountType === "Partner" ? false : true,
    },
    {
      Header: "Main Signor",
      id: "signor",
      Cell: props => {
        if (_.get(props.original, "owner.firstName") && _.get(props.original, "owner.lastName")) {
          return `${props.original.owner.firstName} ${props.original.owner.lastName}`;
        }

        return "";
      },
      Filter: () => <></>,
      visible: false,
    },
    {
      Header: "Stage",
      accessor: "applicationStage",
      id: "applicationStage",
      minWidth: 150,
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.applicationStage),
    },
    {
      Header: "Status",
      id: "transactionStage",
      minWidth: 150,
      accessor: "transactionStage",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.transactionStage),
    },
    {
      Header: "Opportunity Type",
      id: "entityType",
      minWidth: 200,
      accessor: "entityType",
      Cell: props => <div>{props.value ? OpportunityTypes[props.value] : ""}</div>,
      visible: opportunitiesPortalConfigurations?.jsonDefinition.config.allowManualVoCreation,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.entityType),
    },
    {
      Header: "Amount",
      accessor: "invoiceTotal",
      id: "amount",
      minWidth: 150,
      Cell: props => <div>{props.value ? formatCurrency(props.value) : ""}</div>,
      visible: true,
    },
    {
      Header: "Sales Rep",
      id: "salesRep",
      minWidth: 150,
      accessor: "salesRep",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.salesRep),
    },
    {
      Header: "Sales Manager",
      id: "salesManager",
      minWidth: 170,
      accessor: "salesManager.fullName",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.salesManager),
    },
    {
      Header: "Finance Manager",
      id: "financeManager",
      minWidth: 180,
      accessor: "financeManager.fullName",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.financeManager),
    },
    {
      Header: "Location",
      id: "location",
      minWidth: 150,
      accessor: "location",
      visible: showLocation,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.location.locationName),
      Cell: props => (
        <div>{props.original && !!props.original.location ? props.original.location.locationName : null}</div>
      ),
    },
    {
      Header: "Equipment Details",
      id: "equipment details",
      minWidth: 150,
      accessor: "equipmentList",
      Cell: props => <div>{props.value ? props.value : ""}</div>,
      visible: false,
    },
    {
      Header: "Actions",
      id: "actions",
      minWidth: 150,
      accessor: "actions",
      Filter: () => <></>,
      visible: false,
    },
    {
      Header: "Invoice Status",
      id: "invoice status",
      minWidth: 150,
      accessor: "invoiceStatus.name",
      Filter: () => <></>,
      visible: false,
    },
    {
      Header: "Status Detail",
      id: "status detail",
      minWidth: 150,
      accessor: "statusDetail",
      Filter: () => <></>,
      visible: false,
    },
    {
      Header: "Applicant Type",
      id: "applicationType",
      minWidth: 150,
      accessor: "applicationType",
      visible: false,
      filterMethod: (filter, row) => filter.value.includes(row.applicationType),
    },
    {
      Header: "Applying For",
      id: "applyingFor",
      minWidth: 150,
      accessor: "applyingFor",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => _.intersectionBy(row.applyingFor, filter.value).length > 0,
      Cell: props => (
        <div>
          {props.value
            ? _(props.value)
                .map(item => FINANCING_TYPE[item] || item)
                .join(", ")
            : ""}
        </div>
      ),
    },
    {
      Header: "Display",
      id: "display",
      minWidth: 200,
      accessor: "displayStatus",
      filterable: true,
      filterMethod: (filter, row) => {
        const displayStatuses = {
          100: "Active",
          200: "Testing",
          201: "Duplicate",
          202: "Other",
        };

        if (filter.value.includes("Archive")) {
          return filter.value.includes(displayStatuses[row.display]) || [200, 201, 202].includes(row.display);
        }

        return filter.value.includes(displayStatuses[row.display]);
      },
      Cell: props => {
        return (
          <FormInputSelect
            size="small"
            variant="standard"
            value={props.value}
            onChange={async nextValue => {
              await handleOptionClick(nextValue, props.row);
            }}
            options={renderMenuOptions(props.value)}
          />
        );
      },
      visible: true,
    },
  ];
  const initialPartnerColumns = [
    {
      Header: "Created On",
      accessor: "createdOn",
      sortMethod: (a, b) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        return dateA - dateB;
      },
      Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
      Filter: item =>
        item.filter && item.filter.value[0].endDate === null ? (
          <Button color="primary" className={classes.dateRangeButton} onClick={handleDataRangeOpen}>
            Filter by Date
          </Button>
        ) : (
          <div onClick={handleDataRangeOpen}>{`${moment(item.filter && item.filter.value[0].startDate).format(
            "ll hh:mma"
          )} - ${moment(item.filter && item.filter.value[0].endDate).format("ll hh:mma")}`}</div>
        ),
      filterMethod: (filter, row) => {
        const createdOn = new Date(row.createdOn);
        const startDate = filter.value[0].startDate;
        const endDate = filter.value[0].endDate;
        if (endDate) {
          if (startDate <= createdOn && createdOn <= endDate) {
            return row;
          }
        } else {
          return true;
        }
      },
      visible: true,
    },
    {
      Header: "Dealer Name",
      id: "dealerName",
      accessor: "dealerName",
      visible: true,
      Cell: props => <div>{props.original.dealerName ? props.original.dealerName : ""}</div>,
      filterMethod: (filter, row) => {
        const value = filter.value.toLowerCase();
        const dealerName = row.dealerName.toLowerCase();
        if (dealerName.includes(value)) {
          return row;
        }
        return false;
      },
    },
    {
      Header: "Customer",
      id: "customer",
      minWidth: 150,
      accessor: "customerName",
      visible: true,
    },
    {
      Header: "Dealer Rep",
      id: "dealer rep",
      minWidth: 150,
      accessor: "salesRep",
      visible: true,
    },
    {
      Header: "Sales Manager",
      id: "salesManager",
      minWidth: 170,
      accessor: "salesManager.fullName",
      visible: true,
    },
    {
      Header: "Finance Manager",
      id: "financeManager",
      minWidth: 180,
      accessor: "financeManager.fullName",
      visible: true,
    },
    {
      Header: "Partner Rep",
      id: "partenr rep",
      minWidth: 150,
      accessor: "partnerRepName",
      visible: true,
    },
    {
      Header: "Stage",
      id: "applicationStage",
      minWidth: 150,
      accessor: "applicationStage",
      visible: true,
      filterable: true,
      filterMethod: (filter, row) => filter.value.includes(row.applicationStage),
    },
    {
      Header: "Status",
      id: "transactionStage",
      minWidth: 150,
      accessor: "transactionStage",
      visible: true,
      filterMethod: (filter, row) => filter.value.includes(row.transactionStage),
    },
    {
      Header: "Amount",
      id: "amount",
      minWidth: 150,
      accessor: "invoiceTotal",
      Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
      visible: true,
    },
    {
      Header: "Equipment Details",
      id: "equipment details",
      minWidth: 150,
      accessor: "equipmentList",
      Cell: props => <div>{props.value ? props.value : ""}</div>,
      visible: false,
    },
    {
      Header: "Actions",
      id: "actions",
      minWidth: 150,
      accessor: "actions",
      Filter: () => <></>,
      visible: false,
    },
    {
      Header: "Invoice Status",
      id: "invoice status",
      minWidth: 150,
      accessor: "invoiceStatus.name",
      Filter: () => <></>,
      visible: false,
    },
  ];

  const [columns, setColumns] = useState(initialDealerColumns);

  useEffect(() => {
    if (account.accountType) {
      setAccountType(account.accountType);
      setColumns(account.accountType === "Partner" ? initialPartnerColumns : initialDealerColumns);
      if (account.accountType === "Partner") {
        setVisibleColumns(initialPartnerColumns.filter(col => col.visible));
        setSelectedValues(initialPartnerColumns.filter(item => item.visible).map(item => item.Header));
      }
    }
  }, [account]);

  useEffect(() => {
    if (!!vp && vp.multiLocationManagement) {
      setShowLocation(true);
    } else {
      setShowLocation(false);
    }
  }, [vp]);

  let parsedLocalStoreData = JSON.parse(window.localStorage.getItem("savedColumns"));

  // Added all methods from columns to parsedLocalStoreData
  if (parsedLocalStoreData) {
    parsedLocalStoreData = parsedLocalStoreData.map(column => {
      const columnWithOptions = columns.find(col => {
        return col.id === column.id;
      });
      return { ...columnWithOptions, resizable: true };
    });
    parsedLocalStoreData[0] = {
      ...parsedLocalStoreData[0],
      resizable: false,
    };
    if (parsedLocalStoreData.indexOf(undefined) !== -1) {
      parsedLocalStoreData = null;
    }
  }

  // TODO current implementation allows updating the table only in this way
  const [updateDisplayStatus] = useMutation(UPDATE_ROW_DISPLAY_STATUS, {
    context: { authRequired: true },
    refetchQueries: [{ query: GET_VO_LIST }],
  });

  const handleOptionClick = async (value, selectedRow) => {
    try {
      await updateDisplayStatus({
        variables: {
          fieldsObj: {
            displayStatus: value,
          },
          VOId: selectedRow._original.vendorOpportunityId,
        },
      });

      updatedVORef.current = selectedRow._original;
      updatedVORef.current.displayStatus = value;
    } catch (err) {
      logError(err);
    }
  };

  const [columnsFilters, setColumnsFilters] = useState(
    JSON.parse(window.localStorage.getItem("columnsFilterListSettings")) === null
      ? [
          {
            header: "Stage",
            value: [""],
          },
          {
            header: "Status",
            value: [""],
          },
          {
            header: "Opportunity Type",
            value: [""],
          },
          {
            header: "Sales Rep",
            value: [""],
          },
          {
            header: "Sales Manager",
            value: [""],
          },
          {
            header: "Finance Manager",
            value: [""],
          },
          {
            header: "Applicant Type",
            value: [""],
          },
          {
            header: "Applying For",
            value: [""],
          },
          {
            header: "Source",
            value: [""],
          },
          {
            header: "Display",
            value: ["Active"],
            defaultValue: ["Active"],
          },
          {
            header: "Dealer Rep",
            value: [""],
          },
          {
            header: "Dealer Name",
            value: [""],
          },
          {
            header: "Location",
            value: [""],
          },
        ]
      : JSON.parse(window.localStorage.getItem("columnsFilterListSettings"))
  );
  const [visibleColumns, setVisibleColumns] = useState(parsedLocalStoreData || columns.filter(col => col.visible));
  const [selectedValues, setSelectedValues] = useState(
    visibleColumns.map(col => {
      if (typeof col.Header === "string") {
        return col.Header;
      } else {
        return "FastTrack";
      }
    })
  );

  function addDnDToColumns() {
    setTimeout(() => {
      const headers = document.querySelectorAll(".rt-resizable-header-content");
      if (headers && headers.length > 0) {
        headers.forEach((header, i) => {
          header.setAttribute("draggable", true);
          header.style.cursor = "move";
          header.style.overflow = "hidden";

          header.ondragstart = e => {
            e.stopPropagation();
            dragged.current = i;
          };

          header.ondrag = e => e.stopPropagation();

          header.ondragend = e => {
            e.stopPropagation();
            setTimeout(() => (dragged.current = null));
          };

          header.ondragover = e => {
            e.preventDefault();
          };

          header.ondrop = e => {
            e.preventDefault();
            const reordered = visibleColumns.slice();
            reordered[dragged.current + 1] = visibleColumns[i + 1];
            reordered[i + 1] = visibleColumns[dragged.current + 1];

            setVisibleColumns(reordered);
            window.localStorage.setItem("savedColumns", JSON.stringify(reordered));
          };
        });
      }
    });
  }

  useEffect(() => {
    addDnDToColumns();
  });

  const [savedListSettings, setSavedListSettings] = useState(
    JSON.parse(window.localStorage.getItem("transactionListSettings")) === null
      ? {
          filter: [],
          sorting: [],
          page: 0,
          pageSize: 10,
        }
      : JSON.parse(window.localStorage.getItem("transactionListSettings"))
  );
  const [pageNumber, setPageNumber] = useState(savedListSettings.page);
  const [pageSizeValue, setPageSizeValue] = useState(savedListSettings.pageSize);
  const stagesSel = [],
    statusesSel = [],
    salesRepsSel = [
      {
        value: null,
        text: "(Not Assigned)",
      },
    ],
    salesManagerSel = [
      {
        value: undefined,
        text: "(Not Assigned)",
      },
    ],
    financeManagerSel = [
      {
        value: undefined,
        text: "(Not Assigned)",
      },
    ],
    applicationTypeSel = [],
    displayOptions = [
      { text: "Active", value: "Active" },
      { text: "Archive", value: "Archive" },
      { text: "Duplicate", value: "Duplicate" },
      { text: "Testing", value: "Testing" },
      { text: "Other", value: "Other" },
    ],
    sourceSel = [],
    entityTypeSel = [
      { text: "Cash Sale", value: "CASH_SALE" },
      { text: "Credit App", value: "CREDIT_APP" },
    ],
    partnerRepsSel = [],
    dealerNameSel = [],
    applyingForSel = [];

  function handleVORowClick(event, vendorOpportunityId) {
    const isNeedOpenVoDetailsInNewTab = event.ctrlKey;
    const voDetailsPath = `/vo/${vendorOpportunityId}`;

    console.log("VO DETAIL OPENED EVENT FIRED");
    mixpanel.track("VO DETAIL OPENED");

    if (isNeedOpenVoDetailsInNewTab) {
      window.open(voDetailsPath, "_blank");
    } else {
      history.push(voDetailsPath);
    }
  }

  function selectColumns(e) {
    const selected = e.target.value;
    let addedValue = null;
    if (selected.length > selectedValues.length) {
      addedValue = selected[selected.length - 1];
    }

    setSelectedValues(selected);

    let freshColumns = columns.map(col => {
      if (!!col && !!col.Header) {
        if (selected.includes(col.Header)) {
          return { ...col, visible: true, resizable: true };
        }
      }
      if (selected.includes("FastTrack")) {
        return { ...col, visible: true, resizable: true };
      }

      return { ...col, visible: false, resizable: true };
    });

    freshColumns[0] = {
      ...freshColumns[0],
      resizable: false,
    };

    if (addedValue) {
      const oldIndex = freshColumns.findIndex(col => col.Header === addedValue);
      const tempColumn = freshColumns[oldIndex];
      freshColumns = freshColumns.filter((_, index) => index !== oldIndex).concat([tempColumn]);
    }
    setColumns(freshColumns);

    const updatedVisibleColumns = freshColumns
      .filter(col => {
        if (col) {
          if (typeof col.Header === "string") {
            return selected.includes(col.Header);
          } else {
            return selected.includes("FastTrack");
          }
        }
      })
      .map(i => ({ ...i, resizable: true }));

    updatedVisibleColumns[0] = {
      ...updatedVisibleColumns[0],
      resizable: false,
    };
    setVisibleColumns(updatedVisibleColumns);

    window.localStorage.setItem("savedColumns", JSON.stringify(updatedVisibleColumns));
  }

  const renderMenuOptions = displayStatus => {
    if (displayStatus !== 100) {
      return optionsButton
        .filter(option => !Array.isArray(option.value))
        .map(({ title, value }) => ({ label: title, value }));
    } else {
      return optionsButton
        .filter(option => !Array.isArray(option.value))
        .map(({ title, value }) => ({ label: value === 100 ? "Active" : title, value }));
    }
  };

  function processVOListData(vendorOpportunities) {
    if (_.isArray(vendorOpportunities)) {
      const customSet = new Set();
      const salesRepsSet = new Set();
      const salesManagerSet = new Set();
      const financeManagerSet = new Set();
      const statusesSet = new Set();
      const applicationTypesSet = new Set();
      const sourceSet = new Set();
      const partnerRepsSet = new Set();
      const dealerNameSet = new Set();

      vendorOpportunities.forEach(item => {
        try {
          if (item.applicationStage === "pre_qualified") {
            item.applicationStage = "prequalified";
          }
          if (item.applicationStage === "dealer_cancelled") {
            item.applicationStage = "dealer Cancelled";
          }
          if (item.applicationStage === "customer_cancelled") {
            item.applicationStage = "customer Cancelled";
          }
          item.applicationStage =
            item.applicationStage && item.applicationStage.charAt(0).toUpperCase() + item.applicationStage.slice(1);

          if (item.applicationType) {
            if (
              item.applicationType == "COMMERCIAL" ||
              item.applicationType == "COMMERCIAL_WITH_GUARANTOR" ||
              item.applicationType == "COMMERCIAL_WITH_MULTIPLE_GUARANTORS"
            ) {
              item.applicationType = "Commercial";
            }
            if (item.applicationType === "INDIVIDUAL") {
              item.applicationType = "Individual";
            }
            if (item.applicationType === "INDIVIDUAL_WITH_SECOND_GUARANTOR") {
              item.applicationType = "Joint";
            }
          }

          const stages = !!item.applicationStage && item.applicationStage;
          const salesRep = !!item.salesRepresentative && item.salesRepresentative.fullName;
          const statuses = !!item.transactionStage && item.transactionStage;
          const salesManagerFullName = _.get(item, "salesManager.fullName");
          const financeManagerFullName = _.get(item, "financeManager.fullName");
          const { applicationType, applyingFor, dealerName, partnerName, partnerRepName = "None Assigned" } = item;

          if (stages && !customSet.has(stages)) {
            stagesSel.push({
              value: stages,
              text: stages ? stages : "None",
            });
            customSet.add(stages);
          }

          if (salesRep && !salesRepsSet.has(salesRep)) {
            salesRepsSel.push({
              value: salesRep,
              text: salesRep ? salesRep : "None",
            });
            salesRepsSet.add(salesRep);
          }

          if (salesManagerFullName && !salesManagerSet.has(salesManagerFullName)) {
            salesManagerSel.push({
              value: salesManagerFullName,
              text: salesManagerFullName,
            });
            salesManagerSet.add(salesManagerFullName);
          }

          if (financeManagerFullName && !financeManagerSet.has(financeManagerFullName)) {
            financeManagerSel.push({
              value: financeManagerFullName,
              text: financeManagerFullName,
            });
            financeManagerSet.add(financeManagerFullName);
          }

          if (statuses && !statusesSet.has(statuses)) {
            statusesSel.push({
              value: statuses,
              text: statuses ? statuses : "None",
            });
            statusesSet.add(statuses);
          }

          if (applicationType && !applicationTypesSet.has(applicationType)) {
            applicationTypeSel.push({
              value: applicationType,
              text: applicationType ? applicationType : "None",
            });
            applicationTypesSet.add(applicationType);
          }

          if (partnerName && !sourceSet.has(partnerName)) {
            sourceSel.push({
              value: partnerName,
              text: partnerName ? partnerName : "None",
            });
            sourceSet.add(partnerName);
          }

          if (partnerRepName && !partnerRepsSet.has(partnerRepName)) {
            partnerRepsSel.push({
              value: partnerRepName,
              text: partnerRepName ? partnerRepName : "None",
            });
            partnerRepsSet.add(partnerRepName);
          }

          if (dealerName && !dealerNameSet.has(dealerName)) {
            dealerNameSel.push({
              value: dealerName,
              text: dealerName,
            });
            dealerNameSet.add(dealerName);
          }

          if (_.some(applyingFor)) {
            _.forEach(applyingFor, item => {
              if (_.find(applyingForSel, { value: item })) {
                return;
              }
              applyingForSel.push({
                value: item,
                text: FINANCING_TYPE[item] || item,
              });
            });
          }

          if (item.potentialCustomer) {
            item.customerName = item.potentialCustomer.name;
          }
          item.salesRep = item.salesRepresentative ? item.salesRepresentative.fullName : null;
          if (item.equipmentList === null) {
            item.equipmentList = item.equipmentDescription;
          }
          item.actions = null;
        } catch (error) {
          console.log(error);
        }
      });

      return vendorOpportunities;
    }
    return [];
  }

  const getSalesAutocomplete = (options, header, onChange) => {
    return (
      <Autocomplete
        options={options}
        getOptionLabel={option => option.text}
        multiple
        disableClearable={true}
        fullWidth={true}
        className={classes.filterAutocomplete}
        renderInput={params => <TextField {...params} />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Typography key={key} {...tagProps} className={"autocompleteRenderTags"}>
                {_(value).map("text").join(", ")}
              </Typography>
            );
          })
        }
        onChange={(e, value) => {
          const getValuesToSet = value => {
            const valuesToSet = _.map(value, "value");
            if (valuesToSet.length > 1 && valuesToSet[0] === "") {
              // if some other option besides "Show All" selected
              // make all besides "Show All" selected
              return _.reject(valuesToSet, value => value === "");
            }
            if (valuesToSet[valuesToSet.length - 1] === "") {
              // if "Show All" selected
              // make only "Show All" option selected
              return _.filter(valuesToSet, value => value === "");
            }
            return valuesToSet;
          };

          const valuesToSet = getValuesToSet(value);
          onChange(valuesToSet);
          setColumnsFilters(prevArray =>
            prevArray.map(col =>
              col.header === header
                ? {
                    ...col,
                    value: valuesToSet,
                  }
                : col
            )
          );
        }}
        renderOption={(props, option, state, ownerState) => {
          const { key, ...optionProps } = props;
          return (
            <ListItem key={key} {...optionProps}>
              <Checkbox checked={state.selected} />
              <ListItemText primary={option.text} />
            </ListItem>
          );
        }}
        value={_.filter(options, o => {
          const currentSelectedFilters = _.get(
            columnsFilters.find(col => col.header === header),
            "value"
          );
          return _.includes(currentSelectedFilters, o.value);
        })}
        slots={{
          popper: props => <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />,
        }}
      />
    );
  };

  const getLocationsAutocomplete = (locations, header, onChange) => {
    const onNodesSelect = selectedNodes => {
      const valuesToSet = _.map(selectedNodes, node => {
        if (node.value === "show-all") {
          return "";
        }
        if (node.value === "not-assigned") {
          return null;
        }
        return node.label;
      });
      onChange(valuesToSet);
      setColumnsFilters(prevArray =>
        prevArray.map(col =>
          col.header === header
            ? {
                ...col,
                value: valuesToSet,
              }
            : col
        )
      );
    };

    const getSelectedFilterDisplayLabel = value => {
      if (value === "") {
        return "Show All";
      }
      if (value === null) {
        return "(Not Assigned)";
      }
      return value;
    };

    const handleChange = (currentNode, selectedNodes) => {
      /*     console.log(currentNode);
      console.log(selectedNodes); */
      currentNode.value === "show-all"
        ? onNodesSelect(currentNode.checked ? [{ value: "show-all", label: "Show All" }] : [])
        : onNodesSelect(_.reject(selectedNodes, n => n.value === "show-all"));
    };

    const currentSelectedFilters = _.get(
      columnsFilters.find(col => col.header === header),
      "value"
    );

    const isShowAll = currentSelectedFilters.length === 1 && currentSelectedFilters[0] === "";
    const options = _.map(locations, location => {
      return {
        ...location,
        checked: isShowAll ? false : _.includes(currentSelectedFilters, location.locationName),
      };
    });
    const tree = [
      { value: "show-all", label: "Show All", checked: isShowAll },
      { value: "not-assigned", label: "(Not Assigned)", checked: _.includes(currentSelectedFilters, null) },
      ...buildTree(options),
    ];
    const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
      onChange: handleChange,
      showDropdown: "always",
      mode: "hierarchical",
    });
    return (
      <LocationsTreeViewCheckboxes
        tree={tree}
        treeSelectOptions={treeSelectOptions}
        currentSelectedValues={currentSelectedFilters}
        getSelectedValuesDisplayLabel={getSelectedFilterDisplayLabel}
      />
    );
  };

  const getSimpleSelect = (sel, header, onChange) => {
    return (
      <Select
        multiple
        className={classes.filterSelect}
        renderValue={() => {
          const currentFilter = _.find(columnsFilters, { header });
          if (_.get(currentFilter, "value[0]") === "") {
            return "Show All";
          }
          return _(currentFilter)
            .get("value")
            .map(value => {
              if (value === null) {
                return "(Not Assigned)";
              }
              const selectedFilterOption = _.find(sel, { value: value });
              return selectedFilterOption?.text || value;
            })
            .join(", ");
        }}
        onChange={e => {
          onChange(e.target.value);
          let value =
            e.target.value.length > 1 && e.target.value[0] === ""
              ? e.target.value.filter(val => val !== "")
              : e.target.value;
          if (value[value.length - 1] === "") {
            value = value.filter(val => val === "");
          }
          setColumnsFilters(prevArray =>
            prevArray.map(col =>
              col.header === header
                ? {
                    ...col,
                    value: value,
                  }
                : col
            )
          );
        }}
        value={_.get(
          columnsFilters.find(col => col.header === header),
          "value",
          []
        )}
        style={{ width: "100%" }}
        MenuProps={{
          getContentAnchorEl: null,
          variant: "menu",
        }}>
        <MenuItem value="">
          <Checkbox
            checked={
              _.get(
                columnsFilters.find(col => col.header === header),
                "value",
                null
              ) &&
              _.get(
                columnsFilters.find(col => col.header === header),
                "value"
              ).includes("")
            }
            style={{ color: theme.palette.primary.main }}
          />
          <ListItemText primary="Show All" />
        </MenuItem>
        {sel.map((item, index) => {
          return (
            <MenuItem key={"stage-" + index} value={item.value} name={item.text}>
              <Checkbox
                checked={
                  _.get(
                    columnsFilters.find(col => col.header === header),
                    "value",
                    null
                  ) &&
                  _.get(
                    columnsFilters.find(col => col.header === header),
                    "value"
                  ).includes(item.value)
                }
                style={{ color: theme.palette.primary.main }}
              />
              <ListItemText primary={item.text} />
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  function createFiltersColumns(sel, header) {
    const filter = ({ onChange }) => {
      if (_.isEmpty(sel)) {
        return null;
      }
      if (_.includes(["Sales Rep", "Sales Manager", "Finance Manager"], header)) {
        const options = [{ value: "", text: "Show All" }, ...sel];
        return getSalesAutocomplete(options, header, onChange);
      }
      if (header === "Location") {
        const options = [...sel];
        return getLocationsAutocomplete(options, header, onChange);
      }
      return getSimpleSelect(sel, header, onChange);
    };
    return filter;
  }

  function addFiltersToColumns(col) {
    switch (col.Header) {
      case "Stage": {
        col.Filter = createFiltersColumns(stagesSel, col.Header);
        return col;
      }
      case "Status": {
        col.Filter = createFiltersColumns(statusesSel, col.Header);
        return col;
      }
      case "Sales Rep": {
        col.Filter = createFiltersColumns(_.orderBy(salesRepsSel, "text"), col.Header);
        return col;
      }
      case "Sales Manager": {
        col.Filter = createFiltersColumns(_.orderBy(salesManagerSel, "text"), col.Header);
        return col;
      }
      case "Finance Manager": {
        col.Filter = createFiltersColumns(_.orderBy(financeManagerSel, "text"), col.Header);
        return col;
      }
      case "Applicant Type": {
        col.Filter = createFiltersColumns(applicationTypeSel, col.Header);
        return col;
      }
      case "Source": {
        col.Filter = createFiltersColumns(sourceSel, col.Header);
        return col;
      }
      case "Opportunity Type": {
        col.Filter = createFiltersColumns(entityTypeSel, col.Header);
        return col;
      }
      case "Display": {
        col.Filter = createFiltersColumns(displayOptions, col.Header);
        return col;
      }
      case "Dealer Name": {
        col.Filter = createFiltersColumns(dealerNameSel, col.Header);
        return col;
      }
      case "Applying For": {
        col.Filter = createFiltersColumns(applyingForSel, col.Header);
        return col;
      }
      case "Location": {
        col.Filter = createFiltersColumns(locations, col.Header);
        return col;
      }
      default: {
        return col;
      }
    }
  }

  function filterVOBySearchTerm(vendorOpportunities) {
    const { prevSearch, currentSearch } = searchRef.current;

    if (prevSearch === currentSearch) {
      return prevData.current;
    }

    if (currentSearch.length === 0) {
      return vendorOpportunities;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = vendorOpportunities.filter(el => {
      return (
        (el.owner && (`${el.owner.firstName} ${el.owner.lastName}` || "").toLowerCase().includes(lowerCaseSearch)) ||
        (el.owner && (el.owner.email || "").includes(lowerCaseSearch)) ||
        (el.owner && (el.owner.phone || "").replace(/[^0-9]/g, "").includes(lowerCaseSearch)) ||
        (el.contact &&
          (`${el.contact.firstName} ${el.contact.lastName}` || "").toLowerCase().includes(lowerCaseSearch)) ||
        (el.location && (el.location.locationName || "").toLowerCase().includes(lowerCaseSearch)) ||
        (el.potentialCustomer?.name || "").toLowerCase().includes(lowerCaseSearch) ||
        (el.equipmentDescription || "").toLowerCase().includes(lowerCaseSearch) ||
        (el.statusDetail || "").toLowerCase().includes(lowerCaseSearch) ||
        (el.applicationType || "").toLowerCase().includes(lowerCaseSearch) ||
        (el.partnerName || "").toLowerCase().includes(lowerCaseSearch) ||
        (formatCurrency(el.invoiceTotal) || "").includes(lowerCaseSearch)
      );
    });

    prevData.current = searchData;

    return searchData;
  }

  const handleActiveTabToggle = (event, newValue) => {
    if (newValue !== null) {
      window.localStorage.setItem("activeTabSetting", JSON.stringify(_.indexOf(tabsToShow, newValue)));
      setAсtiveTab(newValue);
    }
  };

  const checkIfVOVisibleForActiveTab = vo => {
    if (_.get(activeTab, "key") === "myOpportunities") {
      return (
        userProfile.id === _.get(vo, "salesRepresentative.id") ||
        userProfile.id === _.get(vo, "salesManager.id") ||
        userProfile.id === _.get(vo, "financeManager.id")
      );
    }
    if (_.get(activeTab, "key") === "unassigned") {
      return !_.get(vo, "salesRepresentative.id");
    }
    return true; // no tabs (for sales reps), or allOpportunities active tab for others
  };

  useEffect(() => {
    if (moreVOData?.vendorOpportunities?.length > 0) {
      const vos = moreVOData.vendorOpportunities;
      setMoreVOs([...moreVOs, ...vos]);
    }
  }, [moreVOData]);

  useEffect(() => {
    console.log("VO LIST OPENED EVENT FIRED");
    mixpanel.track("VO LIST OPENED");
    setFilters(JSON.parse(localStorage.getItem("transactionListSettings"))?.filter);
    localStorage.getItem("columnsFilterListSettings") &&
      setColumnsFilters(JSON.parse(localStorage.getItem("columnsFilterListSettings")));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("transactionListSettings", JSON.stringify(savedListSettings));
  }, [savedListSettings]);

  useEffect(() => {
    window.localStorage.setItem("columnsFilterListSettings", JSON.stringify(columnsFilters));
  }, [columnsFilters]);

  useEffect(() => {
    const getColumnHeader = c => (c && c.id === "fastTrack" ? "FastTrack" : c.Header);
    const availableColumns = _.filter(columns, c => (c && c.id === "fastTrack" ? IS_FAST_TRACK_ENABLED : true));
    const unsortedHeaders = _.map(availableColumns, getColumnHeader);
    const visibleColumnsHeaders = _.map(visibleColumns, getColumnHeader);
    setColumnsOptions(_(visibleColumnsHeaders).concat(unsortedHeaders).uniq().value());
  }, [visibleColumns]);

  useEffect(() => {
    setFilters(prevData => {
      if (!_.isEmpty(prevData)) {
        const newData = prevData.filter(item => {
          return item.id !== "createdOn";
        });
        newData.push({ id: "createdOn", value: { ...dateRange } });
        return newData;
      } else {
        return [
          { id: "createdOn", value: { ...dateRange } },
          { id: "display", value: ["Active"] },
        ];
      }
    });
  }, [dateRange, prevData]);

  useEffect(() => {
    const locations = _.get(locationsDataResponse, "locations");
    if (_.isEmpty(locations)) {
      return;
    }
    setLocations(locations);
  }, [locationsDataResponse]);

  const handleSaveSettings = (aspect, e) => {
    let saved = {};
    saved[aspect] = e;
    setSavedListSettings({ ...savedListSettings, ...saved });
  };
  const handleResetFilters = () => {
    if (accountType === "Vendor") {
      setResized(resizedDealerList.current);
    } else {
      setResized(resizedPartnerList.current);
    }
    setFilters([]);
    setDateRange(prevData => prevData.map(val => ({ ...val, endDate: null })));
    setSorting([]);
    setPageNumber(0);
    setPageSizeValue(10);
    savedListSettings.page = 0;
    savedListSettings.pageSize = 10;
    setTimeout(() => {
      setResized(undefined);
    });
    setColumnsFilters(columnsFilters.map(col => ({ ...col, value: col.defaultValue || [""] })));

    setSavedListSettings({ ...savedListSettings, filter: [] });
    searchRef.current = {
      ...searchRef.current,
      currentSearch: "",
    };
    if (accountType === "Vendor") {
      setVisibleColumns(initialDealerColumns.filter(col => col.visible));
      setSelectedValues(
        initialDealerColumns
          .filter(item => item.visible)
          .map(item => {
            if (typeof item.Header === "string") {
              return item.Header;
            } else {
              return "FastTrack";
            }
          })
      );
    } else {
      setVisibleColumns(initialPartnerColumns.filter(col => col.visible));
      setSelectedValues(
        initialPartnerColumns
          .filter(item => item.visible)
          .map(item => {
            if (typeof item.Header === "string") {
              return item.Header;
            } else {
              return "FastTrack";
            }
          })
      );
    }
    window.localStorage.removeItem("savedColumns");
  };

  return (
    <Query
      query={GET_VO_LIST}
      nextFetchPolicy="cache-first"
      fetchPolicy="cache-and-network"
      context={{ authRequired: true }}
      onCompleted={fetchVOsLazyMode}>
      {({ loading, error, data: vendorOpportunitiesData }) => {
        if (error) return <h3>{String(error)}</h3>;
        const originalOpportunities = _.get(vendorOpportunitiesData, "vendorOpportunities", []);
        const allVendorOpportunities = [...originalOpportunities, ...moreVOs];
        const filteredByTab = _.filter(allVendorOpportunities, checkIfVOVisibleForActiveTab);
        const filteredBySearchTerm = filterVOBySearchTerm(filteredByTab);
        const filteredBySearchTermCopy = _.cloneDeep(filteredBySearchTerm); // @apollo/client disallow directly mutating of response data
        const vendorOpportunities = processVOListData(filteredBySearchTermCopy).map(vo => {
          if (!updatedVORef.current) {
            return vo;
          }

          if (updatedVORef.current.vendorOpportunityId === vo.id) {
            const updated = updatedVORef.current;
            updatedVORef.current = null;

            return updated;
          }

          return vo;
        });

        if (mobileContext.isMobile) {
          return (
            <MobileList
              items={vendorOpportunities}
              primaryKey="customerName"
              subPrimaryKey1="createdOn"
              subPrimaryKey2="transactionStage"
              secondaryKey="invoiceTotal"
              onListItemClick={item => {
                history.push(`/vo/${item.vendorOpportunityId}`);
              }}
            />
          );
        }

        return (
          <div className={classes.root}>
            <Grid>
              <Grid item xs={12}>
                {_.some(tabsToShow) && (
                  <ToggleButtonGroup
                    value={activeTab}
                    exclusive
                    onChange={handleActiveTabToggle}
                    className={classes.toggleButtonGroup}>
                    {tabsToShow.map(tabSetting => (
                      <ToggleButton
                        key={tabSetting.key}
                        value={tabSetting}
                        aria-label={tabSetting.key}
                        className={classes.toggleButton}>
                        {tabSetting.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
                <Tile className={classes.voListTile}>
                  {refreshingList || moreVOsLoading ? (
                    <span>
                      <CircularProgress size={15} /> Auto Refreshing list ...
                    </span>
                  ) : null}
                  <div className={classes.filterOptions}>
                    <TableSearch
                      setPageNumber={setPageNumber}
                      setSavedListSettings={setSavedListSettings}
                      searchRef={searchRef}
                      savedListSettings={savedListSettings}
                      searchForm={classes.searchForm}
                    />
                    <div className={classes.selectOptions}>
                      <FormControl className={classes.selectContainer}>
                        <InputLabel style={{ color: theme.palette.primary.main }}>Columns Selector</InputLabel>
                        <Select
                          className={classes.selection}
                          multiple
                          value={selectedValues}
                          onChange={selectColumns}
                          input={<Input placeholder="Select columns" />}
                          renderValue={() =>
                            selectedValues.length === columns.length ? "All" : selectedValues.join(", ")
                          }
                          MenuProps={{
                            getContentAnchorEl: null,
                            variant: "menu",
                          }}>
                          {columnsOptions.map(header => {
                            return (
                              <MenuItem key={header} value={header} name={header}>
                                <Checkbox
                                  checked={selectedValues.includes(header)}
                                  style={{ color: theme.palette.primary.main }}
                                />
                                <ListItemText primary={header} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Button
                        className={classes.filterButton}
                        size="sm"
                        color="primary"
                        onClick={handleResetFilters}
                        style={{ marginLeft: 8, marginBottom: -11 }}>
                        Reset
                      </Button>
                    </div>
                  </div>
                  <div className={classes.fixReactTable}>
                    <ReactTable
                      color="primary"
                      sorted={sorting}
                      resized={resized}
                      loading={loading}
                      page={pageNumber}
                      filtered={filters}
                      showPageJump={false}
                      pageSize={pageSizeValue}
                      data={vendorOpportunities}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      customClassesForCells={[5]}
                      style={{ color: "#3333333" }}
                      className="-striped -highlight"
                      customCellClasses={[classes.right]}
                      defaultSorted={savedListSettings.sorting}
                      defaultPageSize={savedListSettings.pageSize}
                      columns={visibleColumns.map(addFiltersToColumns)}
                      noDataText={loading ? null : <h3>No results with current settings</h3>}
                      defaultResized={accountType === "Vendor" ? resizedDealerList.current : resizedPartnerList.current}
                      onFilteredChange={changedFilter => {
                        const updatedFilters = changedFilter.filter(item => item.value[item.value.length - 1] !== "");
                        handleSaveSettings("filter", updatedFilters);
                        setFilters(updatedFilters);
                        setPageNumber(0);
                      }}
                      onSortedChange={changedSorted => {
                        handleSaveSettings("sorting", changedSorted);
                        setSorting(changedSorted);
                      }}
                      onPageChange={changePage => {
                        handleSaveSettings("page", changePage);
                        setPageNumber(changePage);
                      }}
                      onPageSizeChange={changePageSize => {
                        handleSaveSettings("pageSize", changePageSize);
                        setPageSizeValue(changePageSize);
                        setPageNumber(0);
                      }}
                      getTdProps={(state, rowInfo, column, instance) => {
                        if (accountType === "Partner") {
                          return {};
                        } else {
                          return {
                            style: { cursor: "pointer" },
                            onContextMenu: e => {
                              e.preventDefault();
                              e.stopPropagation();

                              window.open(`/vo/${rowInfo.original.vendorOpportunityId}`, "_blank");
                            },
                            onClick: e => {
                              e.preventDefault();
                              e.stopPropagation();

                              const fastTrackStatus = _.get(
                                rowInfo,
                                "original.fastTrackResults[0].applicationStatus",
                                ""
                              );

                              if (
                                column.id === "fastTrack" &&
                                rowInfo.original.entityType !== "CASH_SALE" &&
                                fastTrackStatus !== "PRESCREEN_FAILED"
                              ) {
                                handleFastTrackClick(e, rowInfo.original.vendorOpportunityId);
                                return;
                              }
                              if (column.id === "display") {
                                return;
                              } else {
                                if (rowInfo?.original) handleVORowClick(e, rowInfo.original.vendorOpportunityId);
                              }
                            },
                          };
                        }
                      }}
                    />
                    <Menu
                      open={showDateRange}
                      keepMounted
                      onClose={handleCloseDataRange}
                      className={classes.dateRangeMenu}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                          setDateRange([{ ...item.selection }]);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                      />
                    </Menu>
                  </div>
                </Tile>
              </Grid>
            </Grid>
            <FastTrackInfoModal open={fastTrackInfo} onClose={() => setFastTrackInfo(false)} />
          </div>
        );
      }}
    </Query>
  );
}

const useStyles = makeStyles(theme => ({
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable": {
      maxHeight: "calc(100vh - 338px)",
    },
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
      minWidth: "80px !important",
      maxWidth: "350px !important",
      marginRight: "12px !important",
      fontWeight: 500,
    },
    "& .ReactTable .rt-thead": {
      width: "fit-content",
    },
    "& .ReactTable .pagination-bottom .-pageJump input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .ReactTable .rt-tbody": {
      width: "fit-content",
    },
    "& .ReactTable .rt-td": {
      minWidth: "80px !important",
      maxWidth: "350px !important",
      marginRight: "12px !important",
      fontWeight: 500,
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "26px",
    },
  },
  filterOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: "10px",
  },
  filterSelect: {
    height: 16,
    fontSize: 14,
  },
  filterAutocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      height: "16px",
      flexWrap: "nowrap",
    },
    "& .MuiTextField-root input": {
      backgroundImage: "none !important",
    },
    "& .autocompleteRenderTags": {
      fontSize: "14px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      flex: "1 0 100%",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    "& .Mui-focused .autocompleteRenderTags": {
      display: "none",
    },
  },
  selectOptions: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  searchForm: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "15px",
    marginRight: "15px",
    marginTop: "25px",
    marginBottom: "10px",
    height: "30px",
    width: "250px",
  },
  selection: {
    width: "180px",
    marginRight: "16px",
    alignSelf: "center",
    height: "30px",
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
  table: {
    top: "30px !important",
  },
  statusMenu: {
    marginLeft: "20px",
    marginTop: "35px",
  },
  dateRangeMenu: {
    marginTop: "-10px",
    marginLeft: "150px",
  },
  dateRangeButton: {
    padding: "5px 15px",
  },
  displayStatusMenu: {
    padding: 0,
  },
  ftIcon: {
    width: "32px",
    height: "32px",
  },
  headerFastTrack: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  iconEasyBtn: {
    height: "16px",
    marginRight: "4px",
  },
  btnFunded: {
    lineHeight: 0,
    fontSize: "14px",
    height: "18px",
    zIndex: "100",
    padding: "12px 8px",
    fontWeight: "bold",
    textTransform: "none",
    letterSpacing: 1,
  },
  fundedFastLbl: {
    color: "#54a954",
    marginLeft: "8px",
    fontWeight: "bold",
  },
  partnerLogo: {
    height: 40,
    width: 130,
    "& img": {
      height: "inherit",
      width: "inherit",
      objectFit: "contain",
    },
  },
  toggleButtonGroup: {
    width: "100%",
    background: "white",
  },
  toggleButton: {
    flexGrow: 1,
    color: "rgba(0,0,0,0.87)",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&.MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
      borderBottomLeftRadius: 0,
    },
    "&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      borderBottomRightRadius: 0,
      marginRight: "-1px",
    },
  },
  voListTile: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default PageTransactionsList;
