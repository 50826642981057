import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { Task } from "@trnsact/trnsact-shared-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TasksMobileEntity } from "../types";
import { priorityColors, taskStatusMap } from "../../../constants/tasks";

export const mobileEntityAdapter = (entity: Task): TasksMobileEntity => ({
  id: entity.taskId!,
  title: "Task name",
  original: entity,
  dateAndTime: entity?.createdDateTime ? moment(entity.createdDateTime).format("ll") : null,
  dataForView: [
    {
      type: "default",
      label: "Assigned to",
      value: entity.assignedToApplicant
        ? (entity?.assignedToUser ?? "Applicant")
        : (entity?.assignedToUser ?? "Dealer"),
    },
    {
      type: "default",
      label: "Regarding",
      value: entity.regarding,
    },
    {
      type: "default",
      label: "Completed on",
      value: entity?.completedDateTime,
      formatter: value => {
        if (typeof value === "string") return moment(value).format("ll");
        return null;
      },
    },
    {
      type: "component",
      label: "Status",
      value: entity.status,
      component: ({ value }) => (
        <Box style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <CheckCircleIcon fontSize="small" style={{ color: priorityColors[value] }} />

          <Typography variant="body2">{(taskStatusMap as any)?.[value] ?? value}</Typography>
        </Box>
      ),
    },
    {
      type: "component",
      label: "Priority",
      value: entity.priority,
      component: ({ value }) => (
        <Box style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <FlagIcon fontSize="small" style={{ color: priorityColors[value] }} />

          <Typography variant="body2" style={{ textTransform: "capitalize" }}>
            {value}
          </Typography>
        </Box>
      ),
    },
  ],
});
