import React from "react";
import ReactTable from "react-table";
import makeStyles from "@mui/styles/makeStyles";
import TablePagination from "./TablePagination";

const styles = theme => ({
  fixReactTable: {
    width: "100%",
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
      paddingBottom: 12,
    },
    "& .ReactTable .rt-th div": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      minHeight: 16,
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      "&:hover": {
        backgroundImage: "linear-gradient(#00000040, #00000040)",
        backgroundSize: "2px 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      },
      "&:active": {
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
        backgroundSize: "2px 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      },
    },
    "& .ReactTable .rt-tbody": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
    "& .ReactTable .rt-td": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      position: "relative",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      content: `""`,
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-desc > div:first-of-type:after": {
      content: `"\\2794"`,
      top: 0,
      transform: "rotate(90deg)",
      color: "rgba(0,0,0,0.5)",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-asc > div:first-of-type:after": {
      content: `"\\2794"`,
      top: 0,
      transform: "rotate(-90deg)",
      color: "rgba(0,0,0,0.5)",
    },
  },
});

const useStyles = makeStyles(styles);

const Table = props => {
  const classes = useStyles();

  return (
    <div className={classes.fixReactTable}>
      <ReactTable {...props} PaginationComponent={TablePagination} />
    </div>
  );
};

export default Table;
