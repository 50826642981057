import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Grid from "@mui/material/Grid";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector from "@mui/material/StepConnector";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DoneIcon from "@mui/icons-material/Done";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import { connect } from "react-redux";
import { SET_CS_REFETCH } from "../../redux/types";

const statusCode = {
  803370000: "Awaiting Review",
  803370001: "Requested Additional Information",
  803370002: "Declined",
  803370004: "Pre-Approved",
  803370003: "Approved",
};
const statusStepWithoutCancel = ["Awaiting Review", "Requested Additional Information", "Pre-Approved", "Approved"];
const statusStepWithDecline = ["Declined"];
const useQontoStepIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  container: {
    width: "calc(100% - 60px)",
    margin: "0px 30px 30px",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16739)",
    borderRadius: "7px",
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ? <CheckIcon className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorLibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    },
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorLibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#eaeaf0",
    zIndex: 1,
    color: "#fff",
    width: "30px !important",
    height: "30px !important",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
  },
  stepLabel: {},
}));

const useColorLibStepIconWithDeclineStyles = makeStyles({
  root: {
    backgroundColor: "#7f7f7f",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "#7f7f7f",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "#7f7f7f",
  },
  declinedActive: {
    backgroundImage: "#AE0000",
    backgroundColor: "#AE0000",
  },
  declinedComplete: {
    backgroundImage: "#AE0000",
    backgroundColor: "#AE0000",
  },
});

function ColorLibStepIcon(props) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentIndIcon fontSize="small" />,
    2: <DoneIcon fontSize="small" />,
    3: <ThumbUpIcon fontSize="small" />,
    4: <LocalAtmIcon fontSize="small" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorLibStepIconWithDecline(props) {
  const classes = useColorLibStepIconWithDeclineStyles();
  const { active, completed } = props;

  const icons = {
    1: <ClearIcon fontSize="small" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.declinedActive]: active,
        [classes.declinedComplete]: completed,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorLibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getSteps(isDeclined) {
  if (isDeclined) return statusStepWithDecline;
  return statusStepWithoutCancel;
}

function ProgressStepper({ creditDecision, setCreditDecision, creditSubStage, setCreditSubStage }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [isDeclined, setIsDeclined] = useState(false);
  const useStyles = makeStyles(theme => ({
    stepper: {
      border: 0,
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: matches ? "6px" : 0,
      borderTopRightRadius: matches ? 0 : "6px",
      borderBottomRightRadius: 0,
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
      padding: "20px 0",
      "& .MuiTypography-displayBlock": {
        marginTop: 4,
        fontSize: 12,
        fontWeight: 300,
        color: "#000",
      },
    },
    modifier: {
      backgroundColor: "rgba(128, 128, 128, 0.12)",
      paddingTop: "auto",
      border: 0,
      borderTopRightRadius: matches ? "6px" : 0,
      borderBottomRightRadius: "6px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: matches ? 0 : "6px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "245px",
      height: "100%",
      margin: "0px 0px 20px 20px",
      justifyContent: "center",
    },
    selectLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: "#3C4858",
      lineHeight: "14px",
    },
  }));
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const steps = getSteps(isDeclined);
  const [status, setStatus] = useState(creditDecision);
  const [stage, setStage] = useState(creditSubStage);

  async function handleChange(e) {
    setStatus(e.target.value);
    setCreditDecision(e.target.value);
  }

  async function handleStageChange(e) {
    setStage(e.target.value);
    setCreditSubStage(e.target.value);
  }

  useEffect(() => {
    const isDeclinedValue = status === 803370002;
    setIsDeclined(isDeclinedValue);
    if (isDeclinedValue) {
      setActiveStep(statusStepWithDecline.indexOf(statusCode[status]));
    } else {
      switch (status) {
        case 803370000:
          setActiveStep(0);
          break;
        case 803370001:
          setActiveStep(1);
          break;
        case 803370004:
          setActiveStep(2);
          break;
        case 803370003:
          setActiveStep(3);
          break;
        default:
          setActiveStep(0);
          break;
      }
    }
  }, [activeStep, status]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Stepper
            className={classes.stepper}
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorLibConnector />}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  slots={{
                    stepIcon: !isDeclined ? ColorLibStepIcon : ColorLibStepIconWithDecline,
                  }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} className={classes.modifier} style={{ position: "relative" }}>
          <div className={classes.mainContainer}>
            <div className={classes.selectLabel}>STAGE</div>
            <FormControl className={classes.formControl}>
              <Select
                value={stage}
                onChange={handleStageChange}
                displayEmpty
                className={classes.selectLabel}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem value={100}>Awaiting Transmission to Lender</MenuItem>
                <MenuItem value={101}>Awaiting PreQual Transmission to Lender</MenuItem>
                <MenuItem value={105}>Draft Application Created</MenuItem>
                <MenuItem value={300}>Submission Failed</MenuItem>
                <MenuItem value={804790000}>Submitted to Lender</MenuItem>
                <MenuItem value={804790001}>Lender Review</MenuItem>
                <MenuItem value={804790002}>Lender - Additional Info Requested</MenuItem>
                <MenuItem value={804790013}>Lender Pre-Approved</MenuItem>
                <MenuItem value={804790003}>Lender Approved</MenuItem>
                <MenuItem value={804790004}>Lender Declined</MenuItem>
                <MenuItem value={804790005}>Dealer Accepted</MenuItem>
                <MenuItem value={804790006}>Dealer Cancelled</MenuItem>
                <MenuItem value={804790007}>Docs Out</MenuItem>
                <MenuItem value={804790008}>Docs In</MenuItem>
                <MenuItem value={804790009}>Funding Review</MenuItem>
                <MenuItem value={804790010}>Funding Items Requested</MenuItem>
                <MenuItem value={804790020}>Funded</MenuItem>
                <MenuItem value={804790011}>Customer Cancelled</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  userProfile: state.userProfile,
});

const mapDispatchToProps = {
  SET_CS_REFETCH,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressStepper);
