import React, { useEffect, useState } from "react";
import { IconButton, TextareaAutosize } from "@mui/material";
import Button from "components/CustomButtons/Button";
import makeStyles from "@mui/styles/makeStyles";
import { Modal } from "@mui/material";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import Tile from "../../components/Tile/Tile";
import _ from "lodash";
import NotesTable from "components/NotesTable";
import moment from "moment";
import { CardContainer } from "../../components/shared/CardContainer/CardContainer";

const GET_VENDOR_OPPORTUNITY_NOTES_QUERY = gql`
  query VendorOpportunityNotes($id: ID!) {
    vendorOpportunity(id: $id) {
      notes
    }
  }
`;

const useStyles = makeStyles(theme => ({
  notesBox: {
    overflowY: "auto",
    marginTop: "10px",
    resize: "none",
    width: "100%",
    minHeight: "100px",
    maxHeight: "200px",
    border: "1px solid #DDD",
    borderRadius: "10px",
    padding: "5px",
  },
  addButtonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: "300",
    fontSize: "12px",
    boxShadow: "0px 2px 2px rgba(55, 133, 244, 0.25)",
    borderRadius: "3px",
  },
  submitButtonStyles: {
    marginTop: 20,
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 125,
    maxWidth: "70%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: "-0,56px",
  },
  closeButton: {
    margin: "0 0 auto",
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  surface: {
    position: "absolute",
    width: "50%",
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
}));

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const UPDATE_VENDOR_OPPORTUNITY_QUERY = gql`
  mutation ($VOId: String!, $fieldsObj: UpdateVendorOpportunityInput!) {
    updateVendorOpportunity(VOId: $VOId, fieldsObj: $fieldsObj)
  }
`;

export default function NotesCard({ vo, userProfile }) {
  const classes = useStyles();

  const [notes, setNotes] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [newNote, setNewNote] = useState({});

  const { loading, error, data } = useQuery(GET_VENDOR_OPPORTUNITY_NOTES_QUERY, {
    variables: {
      id: vo.vendorOpportunityId,
    },
  });

  const [updateVendorOpportunity] = useMutation(UPDATE_VENDOR_OPPORTUNITY_QUERY, {
    context: { authRequired: true },
  });

  const saveVendorOpportunity = async notes => {
    try {
      await updateVendorOpportunity({
        variables: {
          VOId: vo.vendorOpportunityId,
          fieldsObj: { notes },
        },
      });
    } catch (err) {
      window.NREUM.noticeError(err);
    }
  };

  const handleNoteDelete = note => {
    let newNotes = [...notes];
    _.remove(newNotes, note);
    saveVendorOpportunity(JSON.stringify(newNotes));
    setNotes(newNotes);
  };

  const handleNoteUpdate = (original, newNote) => {
    let newNotes = [...notes];
    const index = _.findIndex(newNotes, original);
    newNotes.splice(index, 1, {
      note: newNote,
      createdOn: original.createdOn,
      createdBy: original.createdBy,
    });
    saveVendorOpportunity(JSON.stringify(newNotes));
    setNotes(newNotes);
  };

  const submitNewNote = () => {
    const note = {
      note: newNote,
      createdOn: moment().format(),
      createdBy: userProfile.fullName,
    };

    const newNotes = [...notes, note];
    saveVendorOpportunity(JSON.stringify(newNotes));
    setNotes(newNotes);
    setNewNote({});
  };

  useEffect(() => {
    const notes = _.get(data, "vendorOpportunity.notes");
    if (_.isEmpty(notes)) {
      return;
    }
    try {
      setNotes(JSON.parse(notes));
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  return (
    <>
      <CardContainer
        title="Notes"
        actionBtnProps={{
          label: "ADD NOTE",
          onClick: () => {
            setOpenNoteModal(true);
          },
        }}>
        <NotesTable notes={notes} onNoteDelete={handleNoteDelete} onNoteUpdate={handleNoteUpdate} />
      </CardContainer>
      <Modal
        open={openNoteModal}
        onClose={() => setOpenNoteModal(false)}
        aria-labelledby="Create New Note"
        aria-describedby="Input"
        disableEnforceFocus={true}>
        <div style={modalStyle} className={classes.surface}>
          <Tile
            title={"Create New Note"}
            maxHeightLimitByScreen={true}
            titleButtonComponent={
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setOpenNoteModal(false)}
                size="large">
                <CloseIcon />
              </IconButton>
            }>
            <TextareaAutosize onChange={e => setNewNote(e.target.value)} className={classes.notesBox} />
            <Button
              className={classes.submitButtonStyles}
              onClick={() => {
                setOpenNoteModal(false);
                submitNewNote();
              }}>
              SAVE
            </Button>
          </Tile>
        </div>
      </Modal>
    </>
  );
}
