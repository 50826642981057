import { useCallback, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FinanceProgramFormValues, PaymentOptionTerm } from "../types";

export function useFinanceProgramTerms() {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const [financeQuoteTerms, customTermTemporaryValue] = useWatch({
    control,
    name: ["financeQuote.terms", "financeQuote.customTermTemporaryValue"],
  });

  const terms = useMemo(() => {
    const uniqueTerms = Array.from(
      new Set([...financeQuoteTerms, customTermTemporaryValue].filter(Boolean) as string[])
    );

    return uniqueTerms.sort((a, b) => +b - +a);
  }, [financeQuoteTerms, customTermTemporaryValue]);

  const [currentTerm, setCurrentTerm] = useState<PaymentOptionTerm>({
    index: 0,
    term: terms[0] || "",
  });

  const currentIndex = terms.indexOf(currentTerm.term);
  const nextTermValue = terms[currentIndex - 1] || null;
  const backTermValue = terms[currentIndex + 1] || null;

  useMemo(() => {
    if (customTermTemporaryValue) {
      const index = terms.indexOf(customTermTemporaryValue);

      if (index !== -1) {
        setCurrentTerm({ index, term: customTermTemporaryValue });
      }
    } else if (terms.length && currentIndex === -1) {
      setCurrentTerm({ index: 0, term: terms[0] });
    }
  }, [customTermTemporaryValue, terms]);

  useMemo(() => {
    if (currentIndex !== currentTerm.index) {
      setCurrentTerm(prev => ({ index: currentIndex, term: prev.term }));
    }
  }, [terms]);

  const handleUpdateTerm = useCallback(
    (action: "add" | "sub") => {
      const newIndex = action === "sub" ? currentTerm.index + 1 : currentTerm.index - 1;

      if (terms[newIndex] !== undefined) {
        setCurrentTerm({ index: newIndex, term: terms[newIndex] });
      }
    },
    [terms, currentTerm]
  );

  return {
    handleUpdateTerm,
    currentTerm: currentTerm.term,
    isForwardDisabled: currentIndex <= 0,
    isBackwardDisabled: currentIndex === -1 || currentIndex >= terms.length - 1,
    forwardTooltip: nextTermValue ? `Go to ${nextTermValue}` : "",
    backwardTooltip: backTermValue ? `Back to ${backTermValue}` : "",
  };
}
