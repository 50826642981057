import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { DocumentContext } from "../../contexts/DocumentContext";
import { convertToDate, formatCurrency, getNested } from "../../utils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import BlockIcon from '@mui/icons-material/Block';
// import PersonIcon from '@mui/icons-material/Person';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Tile from "components/Tile/Tile";
import CustomerSummaryCard from "./CustomerSummaryCard";
import EquipmentDescription from "./EquipmentDescription";
import LenderSubmissionView from "./LenderSubmissionView";
import ProgressStepper from "./ProgressStepper";
import Documents from "./Documents";
import ContactCard from "./ContactCard";
import InvoiceCard from "./InvoiceCard";
import Table from "../../components/Table/Table";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { setRefetch, setValue } from "../../redux/actions";
import { connect } from "react-redux";

const GET_CREDIT_APP = gql`
  query ($VOId: ID!) {
    creditApplication(VOId: $VOId) {
      id
      dynamicsId
      amountRequested
      firstName
      lastName
      email
      businessName
      title
      phone
      address
      city
      state
      postalCode
      equipmentDescription
    }
  }
`;

const queryContext = { authRequired: true };
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  breadCrumbs: {
    fontWeight: 300,
  },
  defaultTextWeight: {
    fontWeight: 300,
  },
  a: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[1],
    },
  },
}));
const invoiceDetailAPIKey = [
  {
    label: "Equipment Cost (Subtotal)",
    apiKey: "equipmentCost",
    format: "currency",
  },
  {
    label: "Other Charges (Installation, shipping, etc)",
    apiKey: "freightLaborInstallation",
    format: "currency",
  },
  {
    label: "Trade-in / Down Payment",
    apiKey: "downPayment",
    format: "currency",
  },
  {
    label: "Sales Tax",
    apiKey: "salesTaxOnInvoice",
    format: "currency",
  },
  { label: "Invoice Total", apiKey: "invoiceTotal", format: "currency" },
];
const deliveryTimelineAPIKey = [
  { label: "Delivery Status", apiKey: "deliveryStatus", format: "upperCase" },
  { label: "Order Date", apiKey: "orderDate", format: "date" },
  {
    label: "Expected Delivery Date",
    apiKey: "expectedDeliveryDate",
    format: "date",
  },
  {
    label: "Equipment Acceptance Date",
    apiKey: "equipmentAcceptanceDate",
    format: "date",
  },
  { label: "Invoice Status", apiKey: "invoiceStatus" },
  { label: "Delivery Date", apiKey: "deliveryDate", format: "date" },
];
const contactsTimeLineAPIKey = [
  { label: "Sales Rep", apiKey: "salesRepresentative.fullName" },
  { label: "Sales Manager", apiKey: "salesManger.fullName" },
];

function DetailsAdmin(props) {
  const voId = props.match.params.id;
  const { account, vendorProfile, reduxSetValue } = props;
  // const [voStatusIcon, setStatusIcon] = useState(<PersonIcon />);
  // const [chipColor, setChipColor] = useState();
  const [lastDocument, setLastDocument] = useState();

  const [creditApp, setCreditApp] = useState({ creditApplication: {} });

  const { data: creditAppData, refetch: refetchCreditApp } = useQuery(GET_CREDIT_APP, {
    variables: {
      VOId: voId,
    },
  });

  useEffect(() => {
    setCreditApp(creditAppData);
  }, [creditAppData]);

  const determineVOStatusIcon = data => {
    switch (data.vendorOpportunityAdmin.applicationStage) {
      case "prospect":
        // setStatusIcon(<PersonIcon />);
        // setChipColor('');
        break;
      case "approved":
        // setStatusIcon(<ThumbUpIcon />);
        // setChipColor('green');
        break;
      case "pre_qualified":
        // setStatusIcon(<ThumbUpIcon />);
        // setChipColor('');
        break;
      case "funded":
        // setStatusIcon(<AccountBalanceIcon />);
        // setChipColor('green');
        break;
      case "cancelled":
        // setStatusIcon(<BlockIcon />);
        // setChipColor('rgb(220, 0, 78)');
        break;
      default:
        // setStatusIcon(<PersonIcon />);
        break;
    }
  };

  const classes = useStyles();

  return (
    <Query
      fetchPolicy="no-cache"
      onCompleted={data => {
        determineVOStatusIcon(data);
      }}
      query={gql`
        query ($voId: ID!) {
          vendorOpportunityAdmin(id: $voId) {
            accountProfileContact {
              fullName
            }
            transactionId
            applicationStage
            createdDate
            statusDetail
            deliveryDate
            deliveryStatus
            downPayment
            equipmentApprovalStatus
            equipmentAcceptanceDate
            equipmentDescription
            equipmentCondition
            equipmentCost
            equipmentModelYear
            expectedDeliveryDate
            freightLaborInstallation
            invoiceStatus
            invoiceTotal
            orderDate
            dealerMarkUpRate
            dealerMarkUpAmount
            name
            potentialCustomer {
              name
            }
            salesManager {
              id
              fullName
              dynamicsContactId
              firstName
              lastName
              email
            }
            salesRepresentative {
              id
              fullName
              dynamicsContactId
              firstName
              lastName
              email
            }
            salesTaxOnInvoice
            vendorOpportunityId
            dynamicsVendorOpportunityId
            dynamicsVOStatus
            dynamicsVOStage
            transactionStage
            voProposal {
              productId
              rate
              riskTier
              markupDealer
              amountRequested
              paymentCards {
                productName
                productHeader
                term
                rate
                payment
                rateFactor
                riskTier
                amountMin
                amountMax
                docFee
                advancePayment
                markupInternal
                productId
              }
            }
          }
        }
      `}
      context={queryContext}
      variables={{ voId }}>
      {({ loading, error, data, refetch: refetchVO }) => {
        if (loading) return <CircularProgress />;
        if (error) return <Typography>{String(error)}</Typography>;
        const vo = data.vendorOpportunityAdmin;
        if (vo.applicationStage === "pre_qualified") {
          vo.applicationStage = "prequalified";
        }
        reduxSetValue("vo", vo);
        const parseTableData = ({ label, apiKey, format }, i) => {
          const rowData = getNested(vo, apiKey);
          if (rowData) {
            if (format === "upperCase") {
              let formattedString;
              if (rowData.includes("_")) {
                formattedString = vo[apiKey]
                  .toLowerCase()
                  .split("_")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ");
              } else {
                formattedString = rowData
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ");
              }
              return [label, formattedString];
            } else if (format === "currency") {
              const formattedString = formatCurrency(rowData);
              return [label, formattedString];
            } else if (format === "date") {
              let inputDate = rowData;
              inputDate = inputDate.split("-");
              const outputDate = new Date(inputDate[0], inputDate[1], inputDate[2]).toLocaleDateString();
              return [label, outputDate];
            } else {
              return [label, rowData];
            }
          } else {
            return [label, null];
          }
        };
        const invoiceDetailTableBody = invoiceDetailAPIKey.map(parseTableData);
        const deliveryTimeLineTableBody = deliveryTimelineAPIKey.map(parseTableData);
        const contactsTableBody = contactsTimeLineAPIKey.map(parseTableData);
        const dateTimeZone = vo.createdDate;
        const formattedDate = new Date(Date.parse(dateTimeZone));
        const dateString = convertToDate(formattedDate);
        const customerName = _.get(vo, "potentialCustomer.name", "");

        return (
          <DocumentContext.Provider
            value={{
              lastDoc: lastDocument,
              setLastDocument,
            }} /*set up a document context for FileUploadModal and UploadDocument to communicate*/
          >
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid>
                    <Breadcrumbs aria-label="breadcrumb" style={{ flex: 1 }}>
                      <a href="/vo" className={classes.defaultTextWeight + " " + classes.a}>
                        Opportunity View
                      </a>
                      <span className={classes.defaultTextWeight}>Detail</span>
                    </Breadcrumbs>
                  </Grid>
                  <Grid>
                    <span className={classes.defaultTextWeight}>Created On: {dateString}</span>
                  </Grid>
                  <Grid item>
                    <h3>
                      Opportunity details: <br /> Customer Name: {customerName} <br />
                      Account: {vo.name}
                    </h3>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <ProgressStepper vo={vo} isCancelled={vo.dynamicsVOStatus === 400} refetchVO={refetchVO} />
                </Grid>
                <CustomerSummaryCard
                  name={customerName}
                  vo={vo}
                  creditApp={creditApp}
                  vp={vendorProfile}
                  userProfile={account.userProfile}
                  account={account.account}
                  refetchCreditApp={refetchCreditApp}
                />
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <InvoiceCard
                    invoiceDetailTableBody={invoiceDetailTableBody}
                    userProfile={account.userProfile}
                    account={account.account}
                    salesManager={vo.salesManager}
                    salesRepresentative={vo.salesRepresentative}
                    vo={vo}
                    refetchVO={refetchVO}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <EquipmentDescription name={customerName} vo={vo} account={account} vendorProfile={vendorProfile} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Tile title="Status Details">
                    <Table
                      tableHeaderColor="primary"
                      tableData={[["Status Details", vo.statusDetail ? vo.statusDetail : "N/A"]]}
                      colorsColls={["primary"]}
                    />
                  </Tile>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Tile title="Delivery Timeline">
                    <Table tableHeaderColor="primary" tableData={deliveryTimeLineTableBody} colorsColls={["primary"]} />
                  </Tile>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ContactCard
                    contactsTableBody={contactsTableBody}
                    userProfile={account.userProfile}
                    account={account.account}
                    salesManager={vo.salesManager}
                    salesRepresentative={vo.salesRepresentative}
                    vo={vo}
                    refetchVO={refetchVO}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!vendorProfile ? (
                    "no VendorProfile loaded"
                  ) : vendorProfile.vendorProfile.showDocMgt === 2 ? (
                    <Documents
                      vo={vo}
                      account={account}
                      vendorProfile={vendorProfile}
                      refetchCreditApp={refetchCreditApp}
                    />
                  ) : (
                    <div />
                  )}
                  {/* <Documents vo={vo} account={account}/> */}
                </Grid>
              </Grid>

              {!!vendorProfile && !!account && !!account.userProfile ? (
                vendorProfile.vendorProfile.showLenderSubmission === "all_roles" ||
                (vendorProfile.vendorProfile.showLenderSubmission === "credit_manager_only" &&
                  account.userProfile.vendorContactRole === "credit_mgr") ||
                (vendorProfile.vendorProfile.showLenderSubmission === "all_managers" &&
                  (account.userProfile.vendorContactRole === "credit_mgr" ||
                    account.userProfile.vendorContactRole === "sales_mgr" ||
                    account.userProfile.vendorContactRole === "admin" ||
                    account.userProfile.vendorContactRole === "executive")) ? (
                  <Grid container>
                    <Grid item lg={12}>
                      <LenderSubmissionView account={account.account} vo={vo} userProfile={account.userProfile} />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {/*!!vendorProfile && !!vendorProfile.vendorProfile
                ? (<Grid container>
                    {vendorProfile.vendorProfile.showMarginTool === "Hide" || vendorProfile.vendorProfile.showMarginTool == null ? null : (
                      <Grid item lg={5}>
                        <FinanceMargin vo={vo} refetch={refetch}/>
                      </Grid>
                    )}
                    {vendorProfile.vendorProfile.showPsOptions === "show"
                      ? (<PaymentCards vo={vo}/>)
                      : null
                    }
                  </Grid>)
                : (<></>)
              */}
            </div>
          </DocumentContext.Provider>
        );
      }}
    </Query>
  );
}

const mapStateToProps = state => {
  return {
    vo: state.vo,
    vendorProfile: state.vp,
    account: state.account,
  };
};

const mapDispatchToProps = {
  setRefetch,
  reduxSetValue: setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsAdmin);
