import { gql } from "@apollo/client";

export const M_SEND_PROPOSAL = gql`
  mutation ($input: SendProposalInput!) {
    sendProposalViaEmail(input: $input) {
      success
      errorMessage
    }
  }
`;

export const Q_PROPOSAL = gql`
  query Proposal($id: ID) {
    proposal(id: $id) {
      id
      productId
      rate
      riskTier
      saleType
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      status
      createdDateTime
      firstName
      lastName
      email
      vendorSalespersonId
      monthlyPayment
      termLength
      numOfAdvancePayments
      interestRate
      productSelected
      apr
      lender
      docFee
      amountDue
      gbbCalculatedOptions
      type
      gbbAcceptedTerms
      gbbAcceptedCalculatedOption
      gbbAcceptedMonthlyPayment
      gbbAcceptedTotalAmount
      monthlyPayment
      acceptedAftermarketOptions
      fullName
      paymentId
      category
      financeProgramId
      paymentCards {
        productId
        productName
        productHeader
        term
        rate
        payment
        rateFactor
        riskTier
        amountMin
        amountMax
        docFee
        advancePayment
        markupInternal
        markupDealer
        lender
        downPaymentAmount
      }
      lenderPaymentOptions {
        paymentId
        proposalId
        lenderId
        term
        residual
        lenderType
        payment
        paymentCriteria
        paymentCriteriaSelected {
          name
          description
          proposalMenuId
          menuOption {
            name
            description
            payment {
              paymentAmountPerPeriod
            }
            # proposalMenuOptionId ### not implemented
            titleColor
            titleBgColor
            products {
              title
              cost
              retailCost
              thirdPartyUrl
              proposalProductId
            }
          }
        }
      }
      vendorOpportunity {
        equipmentDescription
      }
      contact {
        email
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        title
        availability
      }
      vendorProfile {
        logo
        styles
      }
      contracts {
        proposalContractId
      }
      createdSnapshot
    }
  }
`;

export const DEACTIVATE_PROPOSAL = gql`
  mutation ($id: ID!) {
    deactivateProposal(id: $id)
  }
`;

export const UPDATE_FASTTRACK_STATUS = gql`
  mutation ($VOId: String!) {
    updateVendorOpportunity(VOId: $VOId, fieldsObj: { fastTrackStatus: AWAITING_DEALER_APPROVAL })
  }
`;

export const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      insuranceCoverage {
        coverageRequirements
        certificateRequirements
        otherInstructions
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
      submissionSettings {
        excludedDocuments
      }
    }
  }
`;

export const Q_Prescreen_Criteria = gql`
  query PreScreenCriteria($accountId: ID!) {
    prescreenCriteria(accountId: $accountId) {
      prescreenCriteriaId
      name
      accountId
      userProfileIdCreatedBy
      jsonCriteria
      active
      notes
      guidelines
      userProfileIdCreatedBy
      createdDateTime
    }
  }
`;

export const M_Create_Prescreen_Criteria = gql`
  mutation CreatePrescreenCriteria($input: CreatePrescreenCriteriaInput!) {
    createPrescreenCriteria(input: $input) {
      prescreenCriteriaId
    }
  }
`;

export const UPDATE_FINANCE_PROGRAM = gql`
  mutation UpdateFinanceProgram($input: UpdateFinanceProgramInput!) {
    updateFinanceProgram(input: $input) {
      financeProgramId
    }
  }
`;
