import React from "react";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "../../../../Table/TableCell";
import { VendorContactRole } from "@trnsact/trnsact-shared-types";

export const ActionsCell = ({ row, userProfile, handleOpenLenderDetailModalView, resubmitCreditApp }: any) => {
  const classes = useStyles();

  if (!!row && !!userProfile && userProfile.vendorContactRole !== VendorContactRole.SalesRep) {
    return (
      <TableCell>
        <Button
          size="small"
          color="primary"
          variant="text"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            handleOpenLenderDetailModalView(row);
          }}>
          View
        </Button>

        {row.apiResult === "failure" && (
          <Button
            size="small"
            className={classes.buttonDetail}
            onClick={() => {
              resubmitCreditApp(row);
            }}>
            RE-SUBMIT
            <span style={{ padding: "0px 5px", color: "#C4C4C4" }}>|</span>
          </Button>
        )}
      </TableCell>
    );
  }

  return <TableCell>-</TableCell>;
};

const useStyles = makeStyles(theme => ({
  buttonDetail: {
    display: "inline-block",
    float: "right",
    color: theme.palette.primary.main,
    paddingLeft: 0,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
