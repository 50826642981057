import React, { ChangeEventHandler } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";

interface Props {
  error: any;
  quoteInfo: any;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export const QuoteInfoSection = ({ quoteInfo, error, onChange }: Props) => {
  const formatPhone = (val: string | undefined | null) => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  return (
    <Stack sx={{ width: "100%", gap: "1rem", padding: "0.5rem 0.5rem 0 0.5rem" }}>
      <Typography variant="h6" component="span">
        Quote Info
      </Typography>

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          onChange={onChange}
          name="equipmentDescription"
          label="Equipment Description"
          error={error.equipmentDescription}
          value={quoteInfo.equipmentDescription ? quoteInfo.equipmentDescription : ""}
          helperText={error.equipmentDescription ? "Min Length is 4 characters long" : false}
        />

        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="businessName"
          onChange={onChange}
          label="Business Name (optional)"
          value={quoteInfo.businessName ? quoteInfo.businessName : ""}
        />

        <TextField
          required
          fullWidth
          size="small"
          name="firstName"
          label="First Name"
          variant="outlined"
          onChange={onChange}
          error={error.firstName}
          value={quoteInfo.firstName ? quoteInfo.firstName : ""}
          helperText={error.firstName ? "Min Length is 4 characters long" : false}
        />

        <TextField
          required
          fullWidth
          size="small"
          name="lastName"
          label="Last Name"
          variant="outlined"
          onChange={onChange}
          error={error.lastName}
          value={quoteInfo.lastName ? quoteInfo.lastName : ""}
          helperText={error.lastName ? "Min Length is 4 characters long" : false}
        />

        <TextField
          required
          fullWidth
          size="small"
          name="phone"
          label="Phone"
          variant="outlined"
          error={error.phone}
          value={quoteInfo.phone ? quoteInfo.phone : ""}
          helperText={error.phone ? "Please enter a valid phone number" : false}
          onChange={e => {
            e.target.value = formatPhone(e.target.value);
            onChange(e);
          }}
        />

        <TextField
          required
          fullWidth
          size="small"
          name="email"
          label="Email"
          variant="outlined"
          error={error.email}
          onChange={onChange}
          value={quoteInfo.email ? quoteInfo.email : ""}
          helperText={error.email ? "Please enter a valid email" : false}
        />
      </Box>
    </Stack>
  );
};
