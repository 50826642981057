import React from "react";
import { ModalsKeys } from "global";
import ReactQuill from "react-quill";
import { useModal } from "hooks/useModal";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { DialogActions, Box, Grid, Dialog, DialogTitle, Typography, DialogContent, Button } from "@mui/material";
import { formatCurrency } from "../../../../utils";
import { MenuOptionConfirmationModalProductCard } from "../components/product/MenuOptionConfirmationModalProductCard";

export const ProposalMenuOptionDetailsDialog = () => {
  const {
    isOpen,
    handleClose,
    data: { menuOption },
  } = useModal(ModalsKeys.ProposalMenuOptionDetailsDialog);

  const classes = useStyles();

  const amountDueToday = menuOption.payment.amountDueToday || 0;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography
          variant="h5"
          align="center"
          className={classes.menuOptionName}
          style={{ backgroundColor: menuOption.titleBgColor!, color: menuOption.titleColor! }}>
          {menuOption.name}
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <>
          <Typography variant="h6" component="span">
            Finance Program Description
          </Typography>

          <ReactQuill readOnly theme="bubble" value={menuOption.menuOptionDetail} modules={{ toolbar: false }} />
        </>

        {menuOption.products && (
          <>
            <Typography variant="h6" component="span">
              Products Included
            </Typography>

            {menuOption.products.map((product: any) => (
              <MenuOptionConfirmationModalProductCard
                product={product}
                key={product.proposalProductId}
                primaryColor={menuOption.titleBgColor!}
              />
            ))}
          </>
        )}

        <Grid container>
          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>
              Due in Advance
            </Typography>

            <Typography variant="h6" className={classes.conditionValue}>
              {(amountDueToday || amountDueToday === 0) && formatCurrency(amountDueToday.toFixed(2))}
            </Typography>
          </Grid>

          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>
              Monthly Payment
            </Typography>

            <Grid container justifyContent="center" alignItems="center" className={classes.conditionValue}>
              <Typography variant="h6">
                {formatCurrency(parseFloat(menuOption.payment.paymentAmountPerPeriod.toString()).toFixed(2))}
              </Typography>

              <Typography variant="body2" style={{ marginLeft: 6 }}>
                per month
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>
              Term Length
            </Typography>

            <Grid container justifyContent="center" alignItems="center" className={classes.conditionValue}>
              <Typography variant="h6">{menuOption.payment.term}</Typography>

              <Typography variant="body2" style={{ marginLeft: 6 }}>
                months
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.dialogActions,
        }}>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme>({
  menuOptionName: {
    borderRadius: 4,
    padding: "4px 8px",
    textAlign: "center",
  },
  conditionContainer: {
    padding: "8px 16px",
    background: "#F5F5F5",
  },
  conditionTitle: {
    paddingBottom: 8,
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  conditionValue: {
    paddingTop: 8,
    textAlign: "center",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
});
