import { SelectOption } from "global";
import { ProposalMenu, ProposalMenuOption, ProposalMenuOptionType } from "@trnsact/trnsact-shared-types";
import { MenuConstructorState } from "../model/types";
import { MenuOptionToAddEdit, MenuTemplatesTableFilters } from "../types";
import { AftermarketProductTypes, PrisingConfigByVendor } from "../../desking/types";

export const menuTemplatesTableInitialFilters: MenuTemplatesTableFilters = { search: "" };

export const initialMenuOptionOnAddEdit: MenuOptionToAddEdit = {
  name: "",
  description: "",
  products: [],
  titleColor: "#000000",
  titleBgColor: "#ffffff",
  archived: false,
  type: ProposalMenuOptionType.Common,
};

export const initialMenuOptions: MenuOptionToAddEdit[] = [
  {
    name: "GOLD",
    description: "",
    products: [],
    titleColor: "#ffffff",
    titleBgColor: "#f7cb45",
    archived: false,
    type: ProposalMenuOptionType.Common,
    ordinal: 0,
  },
  {
    name: "SILVER",
    description: "",
    products: [],
    titleColor: "#ffffff",
    titleBgColor: "#bfbfbf",
    archived: false,
    type: ProposalMenuOptionType.Common,
    ordinal: 1,
  },
  {
    name: "BRONZE",
    description: "",
    products: [],
    titleColor: "#ffffff",
    titleBgColor: "#c28342",
    archived: false,
    type: ProposalMenuOptionType.Common,
    ordinal: 2,
  },
  {
    name: "STANDARD",
    description: "",
    products: [],
    titleColor: "#ffffff",
    titleBgColor: "#333333",
    archived: false,
    type: ProposalMenuOptionType.Common,
    ordinal: 3,
  },
  {
    name: "UNPROTECTED",
    description: "",
    products: [],
    titleColor: "#ffffff",
    titleBgColor: "#000000",
    archived: false,
    type: ProposalMenuOptionType.Declination,
    ordinal: 4,
  },
];

export const initialProductsInMenuConfiguration = initialMenuOptions.reduce<
  MenuConstructorState["proposalProducts"]["productsConfigurationInMenu"]
>((initialValue, { name }) => {
  if (typeof name === "string") initialValue[name] = {};
  return initialValue;
}, {});

export const initialProposalMenu: Partial<ProposalMenu> = {
  name: "",
  description: "",
  menuOptions: initialMenuOptions as ProposalMenuOption[],
};

export const menuOptionTypeOptions: SelectOption[] = [
  { label: "Common", value: ProposalMenuOptionType.Common },
  { label: "Declination", value: ProposalMenuOptionType.Declination },
];

export const prisingConfigByVendor: Partial<PrisingConfigByVendor> = {
  [AftermarketProductTypes.Ntp]: {
    isShowCost: false,
    isCostDisabled: true,
    isShowRate: false,
    isShowRetail: false,
  },
  [AftermarketProductTypes.Gaig]: {
    isShowRate: false,
    isCostDisabled: true,
    isShowCost: false,
    isShowRetail: false,
  },
  [AftermarketProductTypes.Simple]: {
    isCostDisabled: true,
    isShowRate: false,
  },
};
