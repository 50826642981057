import React, { useState } from "react";
import { blue } from "@mui/material/colors";
import { Add, Remove } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, Typography } from "@mui/material";
import { AftermarketMenuOption } from "../../../types";
import { MenuOptionProductDescriptions } from "./MenuOptionProductDescriptions";

interface Props {
  menuOption: AftermarketMenuOption;
}

export const MenuOptionProductsDescriptions = ({ menuOption }: Props) => {
  const classes = useStyles();

  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(false);

  return (
    <Box>
      <Box className={classes.header}>
        <IconButton size="small" onClick={() => setIsSectionOpen(prev => !prev)}>
          {isSectionOpen ? <Remove /> : <Add />}
        </IconButton>

        <Typography component="span" variant="subtitle2">
          Product Description(s)
        </Typography>
      </Box>

      {isSectionOpen &&
        menuOption?.products?.length &&
        menuOption?.products.map(product => (
          <MenuOptionProductDescriptions key={product.proposalProductId} menuOption={menuOption} product={product} />
        ))}
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: blue["50"],
  },
});
