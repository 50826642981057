import React from "react";
import { Nullable } from "global";
import moment, { Moment } from "moment";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
  extraAction?: (nextValue: Nullable<Moment>) => void;
}

export const DateField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  extraAction,
  ...other
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...field } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={label}
            inputRef={ref}
            sx={{ width: "100% " }}
            {...field}
            {...other}
            onChange={(value, context) => {
              onChange(value, context);
              extraAction?.(value);
            }}
            value={field.value ? moment(field.value) : null}
          />
        </LocalizationProvider>
      )}
    />
  );
};
