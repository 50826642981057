import React, { FC } from "react";
import { RulesEngineProvider } from "../../../context";
import { getPrisingConfigByVendor } from "../../../lib";
import { GAIGProductCard, NTPProductCard, SimpleProductCard } from "./products";
import { AftermarketProductTypes, ProposalProductCardProps, ProposalProductCardByVendorProps } from "../../../types";

export const ProposalProductsCard = ({ prisingConfigByVendor, ...props }: ProposalProductCardProps) => {
  const vendorType = props.product.aftermarketProduct?.aftermarketVendorApiChannel as
    | AftermarketProductTypes
    | undefined;

  const productsCards: Record<AftermarketProductTypes, FC<ProposalProductCardByVendorProps>> = {
    [AftermarketProductTypes.Ntp]: NTPProductCard,
    [AftermarketProductTypes.Gaig]: GAIGProductCard,
    [AftermarketProductTypes.Simple]: SimpleProductCard,
  };

  const Card = productsCards[vendorType ?? AftermarketProductTypes.Simple];
  const prisingConfig = getPrisingConfigByVendor(prisingConfigByVendor, vendorType ?? AftermarketProductTypes.Simple);

  return (
    <RulesEngineProvider>
      <Card prisingConfig={prisingConfig} {...props} />
    </RulesEngineProvider>
  );
};
