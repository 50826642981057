import { gql } from "@apollo/client";

export const GET_CONTRACT_DETAILS = gql`
  query ProposalContractsDetails($id: ID) {
    details: proposal(id: $id) {
      contracts {
        proposalContractId
        proposalId
        userProfileIdContractStartedBy
        contractStartedDateTime
        proposalContractAftermarketVendorData {
          proposalContractAftermarketVendorDataId
          proposalContractId
          aftermarketVendorApiChannel
          dataPayload
          dataResponse
          status
        }
      }
    }
  }
`;
