import React, { ReactNode, useMemo } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Button, ButtonGroup, Tooltip } from "@mui/material";
import { Menu } from "./Menu";
import { Finance } from "./finance/Finance";
import { useSaleMode } from "../../../../../context";
import { deskingSelectors } from "../../../../../model";
import { FormSectionHeader } from "../../../../components";
import {
  FinanceProgramFormValues,
  FormSectionProps,
  FormSections,
  SaleStructureType,
  SaleType,
} from "../../../../../types";

export const Structure = ({ sectionName, onClearStructureAndMenuOptions }: FormSectionProps) => {
  const classes = useStyles();

  const { saleStructureType, onChangeStructureType } = useSaleMode();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const isSectionCollapsed = useSelector((state: any) => deskingSelectors.isSectionCollapsed(state, sectionName));
  const isBuilderSectionCollapsed = useSelector((state: any) =>
    deskingSelectors.isSectionCollapsed(state, FormSections.MenuBuilder)
  );

  const { control } = useFormContext<FinanceProgramFormValues>();
  const [financeQuote, saleDetails] = useWatch({ control, name: ["financeQuote", "saleDetails"] });

  const isMenuSectionDisabled = useMemo(() => {
    if (saleDetails.saleType === SaleType.Cash) return false;

    const isFinanceProgramSelected = financeQuote.createProposalWithExistingProgram !== null;
    const isCalculationDepsExists = !!saleDetails.financeAmount || !!financeQuote.terms.length;

    return !isFinanceProgramSelected || !isCalculationDepsExists;
  }, [financeQuote, saleDetails]);

  const renderNestedSectionByMode: Record<SaleStructureType, ReactNode> = {
    [SaleStructureType.Finance]: <Finance />,
    [SaleStructureType.AftermarketMenu]: <Menu />,
  };

  return (
    <Box className={clsx("section", !isBuilderSectionCollapsed && "sectionHidden")}>
      <FormSectionHeader
        name="Structure"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        isSectionCollapsed={isSectionCollapsed}
        extraAction={
          <Button size="small" color="primary" variant="contained" onClick={onClearStructureAndMenuOptions}>
            Reset
          </Button>
        }
      />

      <Box className="sectionFields">
        <ButtonGroup fullWidth>
          <Button
            color="primary"
            variant={saleStructureType === SaleStructureType.AftermarketMenu ? "outlined" : "contained"}
            onClick={() => {
              onChangeStructureType(SaleStructureType.Finance);
            }}>
            Finance
          </Button>

          <Tooltip
            arrow
            disableHoverListener={!isMenuSectionDisabled}
            title="Please fill in Terms in Months and Buy Rate section first to access the Menu section">
            <span className={classes.disableTooltipWrapper}>
              <Button
                color="primary"
                disabled={isMenuSectionDisabled}
                variant={saleStructureType === SaleStructureType.Finance ? "outlined" : "contained"}
                onClick={() => {
                  onChangeStructureType(SaleStructureType.AftermarketMenu);
                }}>
                Menu
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>

        <Box className="sectionFields">{renderNestedSectionByMode[saleStructureType]}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  disableTooltipWrapper: {
    width: "100%",
    display: "block",

    "& > button": {
      width: "100%",
    },
  },
});
