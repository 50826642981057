import React, { useEffect, useState } from "react";
import _ from "lodash";
import rgbHex from "rgb-hex";
import { Auth } from "aws-amplify";
import ColorThief from "colorthief";
import { FORM_ERROR } from "final-form";
import Tile from "components/Tile/Tile";
import { Field, Form } from "react-final-form";
import InfoIcon from "@mui/icons-material/Info";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import formatString from "format-string-by-pattern";
import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from "modules/notification";
import { useMutation, useQuery } from "@apollo/client";
import ColorSchemaPicker from "components/ColorSchemaPicker";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import PersonalLinkAndQRCode from "components/PersonalLinkAndQRCode";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import {
  defaultColorSchema,
  formatPhoneNumber,
  getHeadersFromAuth,
  setFieldValue,
  validateValue,
  vendorContactRole,
} from "utils";
import { SET_ACCOUNT } from "../../redux/types";
import { adminRoles } from "../Prequal/constants";
import {
  GET_REP_LIST,
  M_UPDATE_VP,
  UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_INFO_CRM,
  UPDATE_COMPANY_PROFILE_LP,
  UPDATE_COMPANY_PROFILE_VP,
} from "./api";
import { OcaColorSchema } from "./OcaColorSchema";
import clsx from "clsx";

const config = require("../../config.js");

export const CompanyProfile = () => {
  const classes = useStyles();

  const { showNotification } = useNotifications();

  const [disableUpdate, setDisabledUpdate] = useState<boolean>(false);
  const [dominantColor, setDominantColor] = useState<string>("");
  const [secondaryColorHex, setSecondaryColorHex] = useState<string>("");
  const [dominantColorComplement, setDominantColorComplement] = useState<string>("");
  const [onLoad, setOnLoad] = useState<boolean>(false);

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const vendorProfile = useSelector((state: any) => state.vp);
  const lenderProfile = useSelector((state: any) => state.lenderProfile);
  const userProfile = useSelector((state: any) => state.userProfile);

  const vpStyles = _.merge({}, defaultColorSchema, vendorProfile.styles);
  const [styles, setStyles] = useState<any>(vpStyles);

  const [fields, setFields] = useState<any>({
    id: {
      value: null,
    },
    logo: {
      value: null,
    },
    file: {
      value: null,
      validations: [{ type: "required" }],
    },
    defaultOcaContact: {
      value: null,
    },
    ocaLink: {
      value: null,
    },
  });

  const [updateVP] = useMutation(M_UPDATE_VP, { context: { authRequired: true } });

  const [updateCompanyInfo, { loading: companyInfoLoading }] = useMutation(UPDATE_COMPANY_INFO, {
    context: { authRequired: true },
    onCompleted() {
      dispatch({ type: SET_ACCOUNT, payload: { ...account, phone: phoneValue } });
      showNotification("Successfully updated your profile.");
    },
  });

  const [updateCompanyCRMInfo, { loading: companyCRMInfoLoading }] = useMutation(UPDATE_COMPANY_INFO_CRM, {
    context: { authRequired: true },
    onCompleted() {
      showNotification("Successfully updated your CRM profile.");
    },
  });

  const setValue = (value: any, fieldName: any) => {
    return setFieldValue(value, fieldName, fields, setFields);
  };

  const [formValid, setFormValid] = useState<boolean>(false);
  const [companyProfile, setCompanyProfile] = useState<any>({});
  const [companyReps, setCompanyReps] = useState<any[]>([]);
  const [domainValue, setDomainValue] = useState<string>(account.domain ?? "");
  const [userLogo, setUserLogo] = useState<any>(fields.logo.value);
  const [phoneValue, setPhoneValue] = useState<any>(
    account.phone ? formatString("(999) 999-9999", account.phone.replace(/[^\d-() ]/, "")) : ""
  );

  const adminRolesToUpdateLogoAndOCAColors = [adminRoles.super, adminRoles.singleAccountOnly];
  const isUserAbleToUpdateLogoAndOCAColors = _.includes(adminRolesToUpdateLogoAndOCAColors, userProfile.adminRole);

  function checkFormValidation() {
    let status = true;

    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });

    setFormValid(status);
    return formValid;
  }

  const handleSavingVPStyles = async () => {
    console.log("Executed handleSavingVPStyles");
    await updateVP({
      variables: {
        entityId: vendorProfile.id,
        fieldsObj: {
          styles,
        },
      },
    });
  };

  const _executeGraphQLRequest = async (formData: any) => {
    const authHeaders = await getHeadersFromAuth(Auth);
    return fetch(config.DCR_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        ...authHeaders,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        response = response || {};
        if (response.errors && response.errors.length) {
          const errors = response.errors.map((e: any) => e.message).join("; ");
          console.error("Error saving profile:", errors);
          showNotification("Error saving profile!", { type: "error" });
        } else {
          showNotification("Successfully uploaded your profile!");
          window.location.reload();
        }
      })
      .catch(() => {
        const ret: any = {};
        ret[FORM_ERROR] = "Error saving profile!";
      });
  };

  const handleSaveLenderProfileLogo = async () => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: UPDATE_COMPANY_PROFILE_LP,
        variables: {
          entityId: lenderProfile.id,
          fieldsObj: {
            logo: fields.logo.value,
          },
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        "0": ["variables.file"],
      })
    );
    formData.append("0", fields.file.value);

    return _executeGraphQLRequest(formData);
  };

  const handleSaveVendorProfileLogo = async () => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: UPDATE_COMPANY_PROFILE_VP,
        variables: {
          entityId: fields.id.value,
          fieldsObj: {
            logo: fields.logo.value,
            logoPrimaryColor: dominantColor,
            logoComplementaryColor: dominantColorComplement,
          },
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        "0": ["variables.file"],
      })
    );
    formData.append("0", fields.file.value);

    return _executeGraphQLRequest(formData);
  };

  const submitFields = async () => {
    const accountType = account.accountType;

    if (disableUpdate) {
      showNotification("Only Admin Can Change Profile Information", { type: "error" });
      return;
    }

    const logoFieldValue = _.get(fields, "logo.value", false);
    const hasLogoDataToUpload = logoFieldValue && logoFieldValue.startsWith("data:image");

    if (hasLogoDataToUpload) {
      if (accountType === "Lender") await handleSaveLenderProfileLogo();
      await handleSaveVendorProfileLogo();
    }

    await handleSavingVPStyles();
  };

  useEffect(() => {
    if (vendorProfile) {
      setCompanyProfile(vendorProfile);

      setFields({
        id: {
          value: vendorProfile.id,
        },
        logo: {
          value: vendorProfile.logo ? vendorProfile.logo : "",
          validationStatus: "",
        },
        defaultOcaContact: {
          value: vendorProfile.defaultOcaContact ? vendorProfile.defaultOcaContact : "",
          validationStatus: "",
        },
        file: {
          value: null,
          validations: [{ type: "required" }],
        },
        ocaLink: {
          value: account.dynamicsAccountId
            ? config.REACT_APP_OCA_BASE_URL + "/oca/?vendorGUID=" + account.dynamicsAccountId
            : "",
        },
      });
    } else {
      if (lenderProfile) {
        setCompanyProfile(lenderProfile);
        setFields({
          id: {
            value: lenderProfile.id,
          },
          logo: {
            value: lenderProfile.logo ? lenderProfile.logo : "",
            validationStatus: "",
          },
          defaultOcaContact: {
            value: "",
            validationStatus: "",
          },
          file: {
            value: null,
            validations: [{ type: "required" }],
          },
          ocaLink: {
            value: "",
          },
        });
      }
    }
  }, [account, userProfile, vendorProfile]);

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.vendorContactRole == vendorContactRole.sales_rep ||
        userProfile.vendorContactRole == vendorContactRole.sales_mgr
      ) {
        setDisabledUpdate(true);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (fields?.file?.value) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const avatar: any = document.getElementById("avatar");
        avatar.src = e.target.result;
        setValue(e.target.result, "logo");
        companyProfile.logo = e.target.result;
        checkFormValidation();
      };
      reader.readAsDataURL(fields.file.value);
      checkFormValidation();
    }
  }, [fields.file.value]);

  const { data: repsData } = useQuery(GET_REP_LIST, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (repsData) {
      const companyReps = _.cloneDeep(repsData.salesRepsForAdmin)?.map((item: any) => {
        try {
          item.value = item.dynamicsContactId;
          if (item.title == null) {
            item.label = item.fullName;
          } else {
            item.label = item.fullName + ", " + item.title;
          }
          return item;
        } catch (err) {
          console.error(err);
          return null;
        }
      });
      setCompanyReps(companyReps);
    } else {
      setCompanyReps([]);
    }
  }, [repsData]);

  const handleSavingContact = async () => {
    try {
      await updateVP({
        variables: {
          entityId: vendorProfile.id,
          fieldsObj: {
            defaultOcaContact: fields.defaultOcaContact.value,
          },
        },
      });
    } catch (e) {
      showNotification("Error saving profile!", { type: "error" });
    }
  };

  function uploadFileChangedColor(event: any) {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = function (e: any) {
        const imgElement = document.createElement("img");
        imgElement.src = e.target.result;

        imgElement.onload = function () {
          //Color computation
          const colorThief = new ColorThief();

          const colors = colorThief.getPalette(imgElement, 2) || [0, 0, 0];
          const primaryColor = colors[0];
          const secondaryColor = colors[1];

          setDominantColor(`#${rgbHex(primaryColor[0], primaryColor[1], primaryColor[2])}`);
          setSecondaryColorHex(`#${rgbHex(secondaryColor[0], secondaryColor[1], secondaryColor[2])}`);
          //Background color is always White
          setDominantColorComplement(`#FFFFFF`);
        };
      };

      reader.readAsDataURL(file);
    }
  }

  function uploadButtonHandler() {
    const img = document.getElementById("uploadLogo");
    setValue(vendorProfile.id, "id");
    img?.click();
  }

  function uploadFileChanged(event: any) {
    uploadFileChangedColor(event);
    setValue(event.target.files[0], "file");
    setOnLoad(true);
    setUserLogo(fields.logo.value);
  }

  const required = (value: any) => (value ? undefined : "Required");
  const isValidPhoneNumber = (field: any) => field && field.replace(/[^\d]/g, "").length < 10;

  const handleOnSubmit = async () => {
    console.log("Executed handle on submit");

    const phone = phoneValue.replace(/[\D]+/g, "");

    try {
      let updateCompanyInfoPayload = {
        variables: {
          dynamicsId: account.dynamicsAccountId,
          fieldsObj: {
            phone,
          },
        },
      };

      if (isSuperAdmin) {
        console.log("updateCompanyInfo");
        updateCompanyInfo({
          variables: {
            dynamicsId: account.dynamicsAccountId,
            fieldsObj: {
              domain: domainValue,
              phone,
            },
          },
        });
      } else {
        console.log("updateCompanyInfo for non super admin");
        updateCompanyInfo(updateCompanyInfoPayload);
      }

      updateCompanyCRMInfo({
        variables: {
          entityId: account.dynamicsAccountId,
          fieldsObj: {
            telephone1: phone,
          },
        },
      });

      submitFields();
    } catch (error) {
      console.error(error);
    }
  };

  const composeValidators =
    (...validators: any) =>
    (value: any) =>
      validators.reduce((error: any, validator: any) => error || validator(value), undefined);

  const canEdit =
    userProfile.vendorContactRole === vendorContactRole.credit_mgr ||
    userProfile.vendorContactRole === vendorContactRole.admin;

  const [isSuperAdmin] = useState(userProfile.adminRole === adminRoles.super);

  const handleCalculateOcaColors = () => {
    setStyles({ ...styles, color: dominantColor, secColor: secondaryColorHex });
  };

  return (
    <Form
      onSubmit={submitFields}
      validate={() => checkFormValidation() as any}
      initialValues={companyProfile}
      render={({ handleSubmit }) => (
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit()?.then(res => console.log(res));
          }}>
          <Grid container spacing={1} className={classes.companyProfile}>
            <Grid container xs={12} className={classes.companyInfo}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant={"h5"} className={classes.companyProfileHeader}>
                        Company Info
                      </Typography>
                    </Grid>
                    <Form
                      onSubmit={handleOnSubmit}
                      render={({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <Field name="companyName" validate={required} defaultValue={account.name}>
                              {({ input, meta }) => (
                                <TextField {...input} label="Company Name" error={!meta.valid} disabled />
                              )}
                            </Field>
                            <Field
                              name="companyPhone"
                              validate={composeValidators(required, isValidPhoneNumber)}
                              defaultValue={account.phone}>
                              {({ input, meta }) => (
                                <TextField
                                  {...input}
                                  label="Company Phone"
                                  error={!meta.valid}
                                  inputProps={{
                                    onChange: (e: any) => {
                                      const value = formatString(
                                        "(999) 999-9999",
                                        e.target.value.replace(/[^\d-() ]/, "")
                                      );
                                      setPhoneValue(value);
                                    },
                                    value: phoneValue,
                                  }}
                                  helperText={
                                    isValidPhoneNumber(values.companyPhone)
                                      ? "Please ensure phone number has been entered correctly."
                                      : null
                                  }
                                  disabled={!canEdit}
                                />
                              )}
                            </Field>
                            {isSuperAdmin ? (
                              <Grid item lg={12} md={12} xs={12}>
                                <TextField
                                  label="Domain"
                                  fullWidth={true}
                                  inputProps={{
                                    onChange: (e: any) => {
                                      setDomainValue(e.target.value);
                                    },
                                    value: domainValue,
                                  }}
                                  helperText={!domainValue ? "Enter a valid Domain, e.g: trucks.com" : null}
                                  disabled={!canEdit}
                                />
                              </Grid>
                            ) : null}
                            <Grid item xs={12}>
                              <InputLabel>OCA Color Schema:</InputLabel>
                              {isUserAbleToUpdateLogoAndOCAColors ? (
                                <ColorSchemaPicker colorSchema={styles} setColorSchema={setStyles} />
                              ) : (
                                <span style={{ marginLeft: "10px" }}>Please contact your Admin to change</span>
                              )}
                            </Grid>

                            <Grid item xs={12} className={classes.companyProfilePhotoContainer}>
                              <Tile className={classes.companyProfilePhotoTile} classBody={classes.tileBody}>
                                <input
                                  accept="image/*"
                                  id="uploadLogo"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={uploadFileChanged}
                                />
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={7}>
                                    <img
                                      id="avatar"
                                      alt="Company Logo"
                                      style={
                                        fields.logo.value
                                          ? { maxHeight: "200px", maxWidth: "100%" }
                                          : { height: "250px", width: "450px" }
                                      }
                                      src={
                                        !fields.logo.value
                                          ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                          : typeof fields.logo.value === "string"
                                            ? fields.logo.value.indexOf("data:image") === -1
                                              ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${fields.logo.value}`
                                              : fields.logo.value
                                            : fields.logo.value
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    {dominantColor && dominantColorComplement && (
                                      <OcaColorSchema
                                        dominantColor={dominantColor}
                                        disableUpdate={disableUpdate}
                                        secondaryColorHex={secondaryColorHex}
                                        onCalculateColors={handleCalculateOcaColors}
                                      />
                                    )}

                                    {!isUserAbleToUpdateLogoAndOCAColors ? (
                                      <span>Please contact your Admin to update the Company's logo</span>
                                    ) : (
                                      <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>
                                        {!onLoad ? (
                                          <Button
                                            variant="contained"
                                            disabled={disableUpdate}
                                            onClick={uploadButtonHandler}
                                            endIcon={<PhotoCameraIcon />}>
                                            Upload
                                          </Button>
                                        ) : (
                                          <Grid container style={{ columnGap: "1rem", justifyContent: "flex-end" }}>
                                            <Button
                                              type="submit"
                                              variant="contained"
                                              onClick={() => {
                                                setOnLoad(false);
                                                handleSubmit();
                                              }}>
                                              {submitting ? "Submitting" : "Save"}
                                            </Button>

                                            <Button
                                              variant="outlined"
                                              onClick={() => {
                                                setOnLoad(false);
                                                setDominantColor("");
                                                setDominantColorComplement("");
                                                fields.logo.value = userLogo;
                                              }}>
                                              Cancel
                                            </Button>
                                          </Grid>
                                        )}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              </Tile>
                            </Grid>
                          </div>
                          {canEdit && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={form.submit}
                              className={clsx(classes.linkButton, classes.saveBtn)}
                              disabled={submitting || companyInfoLoading || companyCRMInfoLoading}>
                              Save
                            </Button>
                          )}
                        </form>
                      )}
                    />
                  </Grid>
                  {!_.isEmpty(vendorProfile) && (
                    <PersonalLinkAndQRCode
                      personalLink={fields.ocaLink.value}
                      linkHeader="Company Credit Application Link"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} xl={8} md={8} sm={12} xs={12} className={classes.companyInfo} style={{ padding: "20px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                  <Typography variant={"h5"} className={classes.companyProfileHeader} style={{ margin: "0" }}>
                    Primary Contact
                  </Typography>
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                  <b>Name:</b> {account.primaryContactName}
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                  <b>Phone:</b> {formatPhoneNumber(account.primaryContactPhone)}
                </Grid>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                  <b>Email:</b> {account.primaryContactEmail}
                </Grid>
              </Grid>
              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <Grid container style={{ justifyContent: "space-between", alignItems: "flex-end" }}>
                  <Grid item md={6}>
                    {companyReps.length !== 0 && userProfile.vendorContactRole !== vendorContactRole.sales_rep && (
                      <FormControl style={{ paddingTop: "16px" }} fullWidth>
                        <Grid container alignItems="center">
                          <InputLabel
                            style={{
                              color: "#AAAAAA !important",
                              fontWeight: "400",
                              position: "unset",
                              width: "max-content",
                              letterSpacing: "unset",
                            }}
                            id="vendor-salesperson-id-label">
                            Default Contact
                          </InputLabel>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">Default Contact</Typography>
                                {
                                  "Select your Team Member who you'd like to have displayed on all default communications where a specific Sales Representative has yet to be assigned."
                                }{" "}
                              </React.Fragment>
                            }>
                            <InfoIcon className={classes.defaultIcon} color="primary"></InfoIcon>
                          </HtmlTooltip>
                        </Grid>
                        <Select
                          disabled
                          id="defaultOcaContact"
                          label="Default Contact"
                          name="defaultOcaContact"
                          variant="standard"
                          autoWidth
                          required
                          inputProps={{
                            type: "text",
                            name: "defaultOcaContact",
                            value: fields.defaultOcaContact.value,
                            onChange: (e: any) => {
                              setValue(e.target.value, "defaultOcaContact");
                            },
                          }}>
                          {!!companyReps && companyReps.length > 1
                            ? companyReps.map(({ value, label }) => (
                                <MenuItem value={value} key={value}>
                                  {label}
                                </MenuItem>
                              ))
                            : "No Reps Available"}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles({
  companyProfile: {
    paddingLeft: "10px!important",
    marginTop: "50px",
  },
  defaultIcon: {
    position: "relative",
    right: "17px",
    maxWidth: "15px",
  },
  defaultContactSelector: {
    paddingBottom: "16px",
  },
  companyInfo: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "25px",
    marginBottom: "50px",
    fontSize: "12px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12)",
    justifyContent: "space-between",
  },
  companyProfilePhotoTile: {
    boxShadow: "none",
  },
  companyProfilePhotoContainer: {
    margin: "10px 0 0",
  },
  companyProfilePhoto: {
    padding: 0,
  },
  companyProfileButton: {
    backgroundColor: "#3E83E6",
    borderRadius: "7px",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
  },
  companyProfileHeader: {
    fontSize: "26px",
    fontWeight: 300,
    marginBottom: "15px",
  },
  companyProfileCancelButton: {
    backgroundColor: "#999",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
    marginLeft: "20px",
  },
  openFormLabel: {
    fontSize: 12,
    marginTop: 10,
    fontWeight: 700,
    color: "#3c4858 !important",
    marginBottom: 10,
  },
  linkButton: {
    padding: "10px 15px",
    marginRight: 20,
    width: "200px",
  },
  companyLink: {
    paddingLeft: "8px !important",
  },
  tileBody: {
    padding: "0",
  },
  saveBtn: {
    marginTop: "25px",
  },
  primaryContactSaveBtn: {
    margin: "0",
  },
});
