import { Nullable } from "global";
import Delta from "quill-delta";
import { Quill } from "react-quill";

export const checkIfQuillValueIsEmpty = (quillValue?: Delta | Nullable<string>) => {
  try {
    if (!quillValue) {
      return true;
    }
    const regex = /(<([^>]+)>)/gi;
    let htmlStringToCheck;
    if (typeof quillValue === "string") {
      htmlStringToCheck = quillValue;
    } else {
      const tempQuill = new Quill(document.createElement("div"));
      // @ts-ignore
      tempQuill.setContents(quillValue);
      htmlStringToCheck = tempQuill.root.innerHTML?.replace(regex, "");
    }
    return !htmlStringToCheck.replace(regex, "").length;
  } catch (e) {
    return true;
  }
};
