import React, { useEffect, useState } from "react";
import { Area } from "./components/Area/Area";
import { FormBuilder } from "./components/FormBuilder/FormBuilder";
import { Button, Grid, Alert } from "@mui/material";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import _ from "lodash";
import { TemplateSelector } from "./components/TemplateSelector/TemplateSelector";
import makeStyles from "@mui/styles/makeStyles";
import { TemplateClone } from "./components/TemplateClone/index";
import { Questionnaire } from "./components/Questionnaire/Questionnaire";
import { v4 as uuidv4 } from "uuid";
import {
  amountConfig,
  applyingBehalf,
  applyingSelect,
  cellHomePhone,
  contractorLicenseNum,
  creditLineAmountRequested,
  documentType,
  driversLicenseNum,
  emailConfig,
  equipmentFinancing,
  equipmentNumInFleet,
  grossMonthlyIncome,
  homeEmail,
  homePhone,
  homeType,
  manualBusType,
  monthlyHousingPayment,
  netWorth,
  numOfEmployees,
  optionBusType,
  ownerOperator,
  ownerSelect,
  pastExperience,
  phoneConfig,
  ssnExempt,
  timeAtAddressMonths,
  timeAtAddressYears,
  uploadDocuments,
} from "./untils/constants";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const config = require("../../config.js");
const OCA_URL = config.REACT_APP_OCA_BASE_URL;
const QUERY_OCA_CONFIG = gql`
  query GetOcaConfig($dynamicsId: ID) {
    publicAccount(dynamicsId: $dynamicsId) {
      vendorProfile {
        id
        ocaTemplate {
          ocaTemplateId
          name
          jsonDefinition
          active
          jsonSettings
        }
      }
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const CREATE_OCA_TEMPLATE = gql`
  mutation ($input: UpsertOCATemplateInput!) {
    createOCATemplate(input: $input)
  }
`;

const EDIT_OCA_TEMPLATE = gql`
  mutation ($input: UpsertOCATemplateInput!) {
    updateOCATemplate(input: $input)
  }
`;

const DELETE_OCA_TEMPLATE = gql`
  mutation ($input: DeleteOcaTemplateInput!) {
    deleteOcaTemplate(input: $input)
  }
`;

const useStyles = makeStyles(() => ({
  customizeSelect: {
    display: "flex",
  },
  selectLabel: {
    position: "absolute",
    fontSize: 12,
  },
  formControll: {
    minWidth: "200px",
  },
  area: {
    width: "calc(100% - 400px)",
  },
  sideNav: {
    width: "400px",
  },
  iframeContainerOCA: {
    width: "100%",
    height: "100%",
    padding: "20px",
    borderColor: "#CCC",
  },
  iframeContentOCA: {
    width: "100%",
    height: "100%",
  },
}));

export function Constructor({ accountData }) {
  const classes = useStyles();

  const [config, setConfig] = useState([{}]);
  const [customizationMode, setCustomizationMode] = useState(false);
  const [selectTemplateMode, setSelectTemplateMode] = useState(false);
  const [startFromScratch, setStartFromScratch] = useState(false);
  const [editTemplateMode, setEditTemplateMode] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [initialItem, setInitialItem] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [apiNames, setApiNames] = useState([]);
  const [isOpenQuestionnaire, setOpenQuestionnaire] = useState(false);
  const [isStartPage, setStartPage] = useState(true);
  const [responseSuccess, setResponseSuccess] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [ocaType, setOcaType] = useState("Standard");
  const [customTemplates, setCustomTemplates] = useState([]);
  const [modalMessage, setModalMessage] = useState("Successfully updated the OCA!");
  const [activeTemplate, setActiveTemplate] = useState(_.get(customTemplates, "[0]", ""));
  const [editTemplateSettingsMode, setEditTemplateSettingsMode] = useState(false);
  const [locations, setLocations] = useState(null);

  const [openBackdrop, setOpenBackdrop] = useState(true);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const [questionValues, setQuestionValues] = useState({
    isSupSpanishLang: "",
    isCapAmountReq: accountData.vendorProfile.amountRequested || "",
    primaryIndustry: "",
    isDownloadDL: accountData.vendorProfile.dlUploadRequired || "",
    minOwnership: accountData.vendorProfile.ownershipRequirement || "",
    isReceiveApp: accountData.vendorProfile.isConsumerApp || "",
    enableSubLenders: accountData.vendorProfile.dlRequired || "",
    isPromptReqFin: "",
    captureType: "",
    signature: "",
    isAgreeTerms: "",
    industryGroupType: accountData.vendorProfile.industryGroupType || "generic",
  });

  const {
    data: vendorOcaTemplates,
    refetch: refetchOcaTemplates,
    loading: reloadingOcaTemplates,
  } = useQuery(QUERY_OCA_CONFIG, {
    variables: {
      dynamicsId: accountData.account.dynamicsAccountId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (reloadingOcaTemplates) {
      setOpenBackdrop(true);
    } else {
      setOpenBackdrop(false);
    }
  }, [reloadingOcaTemplates]);

  const [createOcaTemplate] = useMutation(CREATE_OCA_TEMPLATE, {
    context: { authRequired: true },
  });

  const [editOcaTemplate] = useMutation(EDIT_OCA_TEMPLATE, {
    context: { authRequired: true },
  });

  const [deleteOcaTemplate] = useMutation(DELETE_OCA_TEMPLATE, {
    context: { authRequired: true },
  });

  const handleEditTemplate = async (templateTitle, templateSettings) => {
    try {
      await editOcaTemplate({
        variables: {
          input: {
            ocaTemplateId: selectedTemplate.ocaTemplateId,
            name: templateTitle || selectedTemplate.name,
            jsonDefinition: config,
            active: selectedTemplate.active,
            jsonSettings: templateSettings || selectedTemplate.settings,
          },
        },
      })
        .then(() => {
          setResponseSuccess(true);
          setModalMessage("Successfully updated the account!");
        })
        .then(() => refetchOcaTemplates());
    } catch (err) {
      setResponseSuccess(false);
      setModalMessage("Something went wrong!");
    }
  };

  const handleMakeTemplateActive = async (templateId, templateName, templateConfig) => {
    if (activeTemplate && activeTemplate.ocaTemplateId !== templateId) {
      setResponseSuccess(false);
      setModalMessage("You already have an active template");
      setOpenModal(true);
      return false;
    }
    try {
      editOcaTemplate({
        variables: {
          input: {
            ocaTemplateId: templateId,
            name: templateName,
            jsonDefinition: templateConfig,
            active: activeTemplate && activeTemplate.ocaTemplateId === templateId ? false : true,
          },
        },
      }).then(() => refetchOcaTemplates());
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateNewTemplate = async templateTitle => {
    try {
      await createOcaTemplate({
        variables: {
          input: {
            vendorProfileId: accountData.vendorProfile.id,
            name: templateTitle,
            jsonDefinition: config,
            active: false,
          },
        },
      })
        .then(() => {
          setResponseSuccess(true);
          setModalMessage("Successfully updated the account!");
        })
        .then(() => refetchOcaTemplates());
    } catch (err) {
      setResponseSuccess(false);
      setModalMessage("Something went wrong!");
    }
  };

  const handleCreateGlobalTemplate = (page, section, position, fieldConfig) => {
    const newConfig = [...config];

    if (newConfig[page].sections[section]) {
      newConfig[page].sections[section].fields.splice(position, 0, fieldConfig);
    }

    setConfig(newConfig);
  };

  const handleChangeTemplateList = (templateTitle, templateSettings) => {
    if (editTemplateMode || editTemplateSettingsMode) {
      handleEditTemplate(templateTitle, templateSettings);
    } else {
      handleCreateNewTemplate(templateTitle);
    }
    setSelectTemplateMode(true);
    setCustomizationMode(false);
    setStartPage(true);
    setOpenModal(true);
    setEditTemplateMode(false);
    setEditTemplateSettingsMode(false);
  };

  const deleteCreatedTemplate = () => {
    setSelectTemplateMode(true);
    setCustomizationMode(false);
    setStartPage(true);
  };

  const selectNewTemplate = templateConfig => {
    setConfig(templateConfig);
    setSelectTemplateMode(false);
    setOpenQuestionnaire(true);
  };

  const copyOcaTemplate = async ocaTemplateToCopy => {
    const ocaTemplateToSave = {
      ...ocaTemplateToCopy,
      ocaTemplateId: null,
      vendorProfileId: accountData.vendorProfile.id,
      name: ocaTemplateToCopy.name + " (CLONED)",
      active: false,
    };
    delete ocaTemplateToSave.__typename;
    const response = await createOcaTemplate({
      variables: {
        input: ocaTemplateToSave,
      },
    });

    const newOcaTemplateId = _.get(response, "data.createOCATemplate", null);

    if (newOcaTemplateId) {
      const newTemplate = {
        ...ocaTemplateToSave,
        ocaTemplateId: newOcaTemplateId,
      };
      setCustomTemplates([newTemplate, ...customTemplates]);
      setResponseSuccess(true);
      setStartFromScratch(true);
      setSelectTemplateMode(true);
    } else {
      setModalMessage(`Error when trying to clone oca template!`);
      setResponseSuccess(false);
    }
  };
  const editTemplate = template => {
    setEditTemplateMode(true);
    setSelectTemplateMode(false);
    setStartPage(false);
    _.forEach(template.jsonDefinition, page => (page.id = page.id || uuidv4())); // id needs to determine which page to update
    setConfig(template.jsonDefinition);
    setSelectedTemplate(template);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const buildNewTemplate = () => {
    setStartPage(false);
    setOpenQuestionnaire(false);

    if (questionValues.isCapAmountReq === "show" || accountData.vendorProfile.amountRequested === "show") {
      handleCreateGlobalTemplate(0, 0, 1, amountConfig);
    } else if (
      questionValues.isCapAmountReq === "equipPlusCreditLineOptions" ||
      questionValues.isCapAmountReq === "equipPlusCreditLineOptions"
    ) {
      handleCreateGlobalTemplate(0, 0, 2, applyingSelect);
      handleCreateGlobalTemplate(0, 0, 3, creditLineAmountRequested);
      handleCreateGlobalTemplate(0, 0, 4, equipmentFinancing);
    }

    if (ocaType === "Standard") {
      if (
        questionValues.captureType === "option" ||
        accountData.vendorProfile.showIndustryOptionset !== "show - input"
      ) {
        handleCreateGlobalTemplate(2, 0, 3, optionBusType);
      } else {
        handleCreateGlobalTemplate(2, 0, 3, manualBusType);
      }
      if (questionValues.isReceiveApp === "Yes") {
        handleCreateGlobalTemplate(2, 0, 0, applyingBehalf);
        handleCreateGlobalTemplate(2, 0, 1, ownerOperator);
      }
      if (
        (questionValues.enableSubLenders === "Yes" || accountData.vendorProfile.dlUploadRequired) &&
        questionValues.minOwnership.toLowerCase() !== ""
      ) {
        handleCreateGlobalTemplate(6, 1, 5, phoneConfig);
        handleCreateGlobalTemplate(6, 1, 6, emailConfig);
        handleCreateGlobalTemplate(6, 1, 7, driversLicenseNum);
        handleCreateGlobalTemplate(6, 1, 9, grossMonthlyIncome);
        handleCreateGlobalTemplate(6, 2, 4, homePhone);
      }
      if (questionValues.isDownloadDL === "Yes" || accountData.vendorProfile.dlUploadRequired) {
        handleCreateGlobalTemplate(6, 1, 8, uploadDocuments);
        handleCreateGlobalTemplate(7, 0, 1, uploadDocuments);
        handleCreateGlobalTemplate(7, 0, 0, documentType);
      }
      if (
        questionValues.minOwnership.toLowerCase() !== "" &&
        questionValues.industryGroupType.toLowerCase() === "trucking"
      ) {
        handleCreateGlobalTemplate(6, 0, 0, ownerSelect);
        handleCreateGlobalTemplate(6, 1, 5, homeType);
        handleCreateGlobalTemplate(6, 1, 5, timeAtAddressYears);
        handleCreateGlobalTemplate(6, 1, 5, timeAtAddressMonths);
      }
      if (questionValues.minOwnership.toLowerCase() === "") {
        handleCreateGlobalTemplate(6, 0, 0, ssnExempt);
        handleCreateGlobalTemplate(6, 1, 5, homeEmail);
        handleCreateGlobalTemplate(6, 1, 6, cellHomePhone);
        handleCreateGlobalTemplate(6, 1, 7, driversLicenseNum);
      } else {
        handleCreateGlobalTemplate(6, 0, 0, ownerSelect);
      }
    } else if (ocaType === "Simple") {
      if (questionValues.industryGroupType.toLowerCase() === "trucking") {
        handleCreateGlobalTemplate(5, 0, 0, contractorLicenseNum);
        handleCreateGlobalTemplate(4, 0, 2, pastExperience);
      }
      if (
        questionValues.industryGroupType.toLowerCase() === "trucking" ||
        questionValues.industryGroupType === "construction" ||
        questionValues.industryGroupType === "CAT" ||
        questionValues.industryGroupType === "ag"
      ) {
        handleCreateGlobalTemplate(5, 0, 0, equipmentNumInFleet);
      }
      if (
        (questionValues.enableSubLenders === "Yes" || accountData.vendorProfile.dlUploadRequired) &&
        questionValues.minOwnership.toLowerCase() !== ""
      ) {
        handleCreateGlobalTemplate(6, 1, 5, phoneConfig);
        handleCreateGlobalTemplate(6, 1, 6, emailConfig);
        handleCreateGlobalTemplate(6, 1, 7, driversLicenseNum);
        handleCreateGlobalTemplate(6, 1, 9, grossMonthlyIncome);
        handleCreateGlobalTemplate(6, 2, 4, homePhone);
      }
      if (questionValues.isDownloadDL === "Yes" || accountData.vendorProfile.dlUploadRequired) {
        handleCreateGlobalTemplate(6, 1, 8, uploadDocuments);
        handleCreateGlobalTemplate(6, 0, 1, uploadDocuments);
        handleCreateGlobalTemplate(6, 0, 0, documentType);
      }
      if (
        questionValues.minOwnership.toLowerCase() !== "" &&
        questionValues.industryGroupType.toLowerCase() === "trucking"
      ) {
        handleCreateGlobalTemplate(6, 0, 0, ownerSelect);
        handleCreateGlobalTemplate(6, 1, 5, homeType);
        handleCreateGlobalTemplate(6, 1, 5, timeAtAddressYears);
        handleCreateGlobalTemplate(6, 1, 5, timeAtAddressMonths);
      }
      if (questionValues.minOwnership.toLowerCase() === "") {
        handleCreateGlobalTemplate(6, 0, 0, ssnExempt);
        handleCreateGlobalTemplate(6, 1, 5, homeEmail);
        handleCreateGlobalTemplate(6, 1, 6, cellHomePhone);
        handleCreateGlobalTemplate(6, 1, 7, driversLicenseNum);
      } else {
        handleCreateGlobalTemplate(6, 0, 0, ownerSelect);
      }
    } else {
      handleCreateGlobalTemplate(3, 0, 1, numOfEmployees);

      if (questionValues.industryGroupType.toLowerCase() === "trucking") {
        handleCreateGlobalTemplate(5, 0, 0, contractorLicenseNum);
        handleCreateGlobalTemplate(4, 0, 2, pastExperience);
      }
      if (questionValues.industryGroupType === "CAT") {
        handleCreateGlobalTemplate(7, 1, 5, netWorth);
        handleCreateGlobalTemplate(7, 1, 6, grossMonthlyIncome);
        handleCreateGlobalTemplate(7, 1, 7, monthlyHousingPayment);
      }
      if (
        questionValues.industryGroupType.toLowerCase() === "trucking" ||
        questionValues.industryGroupType === "construction" ||
        questionValues.industryGroupType === "CAT" ||
        questionValues.industryGroupType === "ag"
      ) {
        handleCreateGlobalTemplate(5, 0, 0, equipmentNumInFleet);
      }
      if (questionValues.enableSubLenders === "Yes" && questionValues.minOwnership.toLowerCase() !== "") {
        handleCreateGlobalTemplate(7, 1, 5, phoneConfig);
        handleCreateGlobalTemplate(7, 1, 6, emailConfig);
        handleCreateGlobalTemplate(7, 1, 7, driversLicenseNum);
        handleCreateGlobalTemplate(7, 2, 4, homePhone);
      }
      if (questionValues.isDownloadDL === "Yes" || accountData.vendorProfile.dlUploadRequired) {
        handleCreateGlobalTemplate(7, 1, 8, uploadDocuments);
        handleCreateGlobalTemplate(8, 0, 0, documentType);
        handleCreateGlobalTemplate(8, 0, 1, uploadDocuments);
      }
      if (
        questionValues.minOwnership.toLowerCase() === "25" ||
        (questionValues.minOwnership.toLowerCase() === "51" &&
          questionValues.industryGroupType.toLowerCase() === "trucking")
      ) {
        handleCreateGlobalTemplate(7, 0, 0, ownerSelect);
        handleCreateGlobalTemplate(7, 1, 5, homeType);
        handleCreateGlobalTemplate(7, 1, 5, timeAtAddressYears);
        handleCreateGlobalTemplate(7, 1, 5, timeAtAddressMonths);
      }
      if (questionValues.minOwnership.toLowerCase() === "") {
        handleCreateGlobalTemplate(7, 0, 0, ssnExempt);
        handleCreateGlobalTemplate(7, 1, 5, homeEmail);
        handleCreateGlobalTemplate(7, 1, 6, cellHomePhone);
        handleCreateGlobalTemplate(7, 1, 7, driversLicenseNum);
      } else {
        handleCreateGlobalTemplate(7, 0, 0, ownerSelect);
      }
    }
  };

  const selectNewElement = value => {
    setCustomizationMode(true);
    setSelectedItem(value);
    setInitialItem(value);
  };
  useEffect(() => {
    try {
      const observer = new MutationObserver((mutations, obs) => {
        const jiraWidget = document.getElementById("jsd-widget");
        if (jiraWidget) {
          jiraWidget.remove();
          obs.disconnect();
          return;
        }
      });

      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (vendorOcaTemplates) {
      const templatesAvailable = vendorOcaTemplates.publicAccount.vendorProfile.ocaTemplate;
      setCustomTemplates(vendorOcaTemplates.publicAccount.vendorProfile.ocaTemplate);
      if (!_.isEmpty(templatesAvailable)) {
        setStartFromScratch(true);
        setSelectTemplateMode(true);
      }
    }
  }, [vendorOcaTemplates]);

  useEffect(() => {
    if (customTemplates) {
      setActiveTemplate(customTemplates.find(template => template.active));
    }
  }, [customTemplates]);

  useEffect(() => {
    fieldNamesDictionary();
  }, [config]);

  useEffect(() => {
    if (!locationsDataResponse || _.isEmpty(locationsDataResponse.locations)) {
      return;
    }
    setLocations(locationsDataResponse.locations);
  }, [locationsDataResponse]);

  const fieldNamesDictionary = () => {
    if (!_.isEmpty(config)) {
      setApiNames(
        _.uniqBy(
          _.flattenDeep(
            config.map(page =>
              (page.sections || []).map(section =>
                (section.fields || []).map(field => {
                  return { fieldName: field.config.fieldName, fieldOptions: field.config.fieldOptions || [] };
                })
              )
            )
          ),
          "fieldName"
        )
      );
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Backdrop sx={{ color: "#FFF", zIndex: 1000 }} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="primary" size={"large"} />
      </Backdrop>
      <Grid container style={{ height: "100vh" }}>
        <Grid item className={classes.area}>
          {isStartPage && !startFromScratch ? (
            <TemplateClone
              copyOcaTemplate={copyOcaTemplate}
              setStartFromScratch={setStartFromScratch}
              setSelectTemplateMode={setSelectTemplateMode}
            />
          ) : null}

          {!selectTemplateMode && !isStartPage ? (
            <Area
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              config={config}
              setConfig={setConfig}
              setSelectedItem={selectNewElement}
            />
          ) : null}

          {selectTemplateMode && isStartPage ? (
            <Grid item className={classes.iframeContainerOCA}>
              <Grid container justifyContent="space-around">
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <h5>Live Preview: </h5>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12} container justifyContent="space-between">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      try {
                        const f = document.getElementById("ocaFrame");
                        f.src = f.src;
                      } catch (e) {
                        console.log(e);
                      }
                    }}>
                    Reload Page
                  </Button>

                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      window.open("/pcw", "_blank");
                    }}>
                    PCW: CADM
                  </Button>
                </Grid>
              </Grid>

              <iframe
                id="ocaFrame"
                className={classes.iframeContentOCA}
                width={100}
                height={100}
                sandbox={"allow-scripts allow-top-navigation allow-same-origin"}
                src={`${OCA_URL}/credit-app?vendorGUID=${accountData.account.dynamicsAccountId}`}
              />
            </Grid>
          ) : null}

          {startFromScratch ? (
            <Questionnaire
              questionValues={questionValues}
              setQuestionValues={setQuestionValues}
              buildNewTemplate={buildNewTemplate}
              isOpenQuestionnaire={isOpenQuestionnaire}
              selectTemplateMode={selectTemplateMode}
              setSelectTemplateMode={setSelectTemplateMode}
            />
          ) : null}
        </Grid>
        {/* Template Selector */}
        {selectTemplateMode && !isOpenQuestionnaire && (
          <Grid className={classes.sideNav}>
            <TemplateSelector
              setOcaType={setOcaType}
              selectNewTemplate={selectNewTemplate}
              customTemplates={customTemplates}
              editTemplate={editTemplate}
              activeTemplate={activeTemplate}
              accountData={accountData}
              handleMakeTemplateActive={handleMakeTemplateActive}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              reloadingOcaTemplates={reloadingOcaTemplates}
              refetchOcaTemplates={refetchOcaTemplates}
              deleteOcaTemplate={deleteOcaTemplate}
              setStartFromScratch={setStartFromScratch}
              setSelectTemplateMode={setSelectTemplateMode}
            />
          </Grid>
        )}
        {/* Right SideBar */}
        {!selectTemplateMode && !isOpenQuestionnaire && !isStartPage && (
          <Grid item className={classes.sideNav}>
            <FormBuilder
              handleChangeTemplateList={handleChangeTemplateList}
              prevTemplateName={_.get(selectedTemplate, "name", "")}
              selectedSection={selectedSection}
              deleteCreatedTemplate={deleteCreatedTemplate}
              selectNewElement={selectNewElement}
              config={config}
              apiNames={apiNames}
              setConfig={setConfig}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setCustomizationMode={setCustomizationMode}
              initialItem={initialItem}
              customizationMode={customizationMode}
              selectNewTemplate={selectNewTemplate}
              setEditTemplateMode={setEditTemplateMode}
              editTemplateSettingsMode={editTemplateSettingsMode}
              setEditTemplateSettingsMode={setEditTemplateSettingsMode}
              selectedTemplate={selectedTemplate}
              locations={locations}
            />
          </Grid>
        )}
        <Snackbar open={openModal} autoHideDuration={3000} onClose={handleCloseModal}>
          <Alert onClose={handleCloseModal} severity={responseSuccess ? "success" : "error"} variant="filled">
            {modalMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </DndProvider>
  );
}
