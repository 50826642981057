import React from "react";
import { FieldNotify } from "global";
import { Controller } from "react-hook-form";
import { TextFieldProps } from "@mui/material";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FormInputSelect } from "../FormInputSelect";

interface Props<FormValues extends FieldValues = FieldValues> extends Omit<TextFieldProps, "select" | "onChange"> {
  label?: string;
  options: any;
  isLoading?: boolean;
  notify?: FieldNotify;
  placeholder?: string;
  control: Control<FormValues>;
  extraAction?: (nextValue: string) => void;
  name: UseControllerProps<FormValues>["name"];
}

export const SelectField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  options,
  control,
  extraAction,
  isLoading = false,
  notify,
  ...other
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...field }, fieldState }) => (
        <FormInputSelect
          label={label}
          variant="standard"
          notify={notify}
          options={options}
          isLoading={isLoading}
          {...other}
          {...field}
          error={!!other?.error || !!fieldState.error}
          inputRef={ref}
          onChange={value => {
            onChange(value);
            extraAction?.(value);
          }}
        />
      )}
    />
  );
};
