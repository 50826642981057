import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import { PropsWithChildren } from "react";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        color: "primary",
        variant: "outlined",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0c84fc",
      light: "#54a8fc",
      dark: "#085cb0",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff4081",
      main: "#f50057",
      dark: "#c51162",
    },
  },
});

const ThemeWrapper = ({ children }: PropsWithChildren) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
