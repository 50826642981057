import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { indigo } from "@mui/material/colors";
import React from "react";
import { DocumentItemMobileEntity } from "../types";

export const mobileEntityAdapter = (entity: any): DocumentItemMobileEntity => ({
  original: entity,
  id: entity.documentId,
  title: entity.docName,
  dateAndTime: entity.createdDateTime,
  specialPanel: entity?.specialPanel,
  titleIcon: <InsertDriveFileIcon style={{ color: indigo[200] }} fontSize="small" />,
  dataForView: [
    { type: "default", label: "Uploaded by", value: entity.createdBy },
    { label: "Type", type: "default", value: entity.docType },
    { type: "default", label: "Description", value: entity.docDescription },
  ],
});
