import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { gql } from "@apollo/client";
import LinearProgress from "@mui/material/LinearProgress";
import { theme } from "../../../theme";

const EXECUTE_LENDER_MATRIX = gql`
  mutation ExecuteLenderMatrix($VOId: ID!) {
    executeLenderMatrix(VOId: $VOId)
  }
`;

const LenderMatrix = vendorOpportunityId => {
  const [executeLenderMatrix, { data, loading, error }] = useMutation(EXECUTE_LENDER_MATRIX);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExecuteLenderMatrix = () => {
    console.log(`vendorOpportunityId: ${JSON.stringify(vendorOpportunityId)}`);
    executeLenderMatrix({ variables: { VOId: vendorOpportunityId.vendorOpportunityId } });
    setIsModalOpen(true);
  };

  return (
    <div>
      {!loading && (
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: theme.palette.primary.main }}
          onClick={handleExecuteLenderMatrix}>
          Match Lenders
        </Button>
      )}
      {loading && <LinearProgress />}
      {error && <Typography variant="h6">Error: {error.message}</Typography>}
      {data &&
        data.executeLenderMatrix.promptResponse.results &&
        Array.isArray(data.executeLenderMatrix.promptResponse.results) && (
          <div style={{ paddingTop: "15px " }}>
            <TableContainer component={Paper} style={{ border: "2px solid #000" }}>
              <Table style={{ border: "2px solid #000" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Lender Profile Name</TableCell>
                    <TableCell>Matched</TableCell>
                    <TableCell>Reasons</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.executeLenderMatrix.promptResponse.results.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.lenderProfileName}</TableCell>
                      <TableCell style={{ color: row.matched ? "green" : "red" }}>
                        {row.matched ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{Array.isArray(row.reasons) ? row.reasons.join(", ") : row.reasons}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      {data && !data.executeLenderMatrix.promptResponse.results && (
        <div style={{ paddingTop: "15px " }}>The prompt did not return a machine readable value.</div>
      )}
      {data && data.executeLenderMatrix.promptResponseRaw && (
        <Grid container style={{ paddingTop: "15px " }}>
          <Grid item xs={2} style={{ fontWeight: "bold" }}>
            Raw Response
          </Grid>
          <Grid item xs={10}>
            <Box border={1} borderRadius="4px" padding={1}>
              {data.executeLenderMatrix.promptResponseRaw}
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default LenderMatrix;
