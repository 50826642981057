export function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

export const formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
  if (typeof amount === "number") {
    amount = amount.toString();
  }
  if (!amount) return null;
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    amount = amount.replace(/,/g, ""); // 1125, but a string, so convert it to number
    amount = parseInt(amount, 10);

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const parseIntMoneyString = moneyString => parseFloat(moneyString.split(",").join(""));

export const parseCurrency = value => {
  value = value.replace(/\$/g, "");
  value = value.replace(/,/g, "");
  const lastCharacter = value[value.length - 1];
  if (/\d/.test(lastCharacter)) {
    if (value.includes(".")) {
      const decimalLocation = value.indexOf(".");
      const trailingDigits = value.slice(decimalLocation + 1);
      if (trailingDigits.length <= 2) {
        return value;
      } else {
        // Truncate third trailing digit
        return value.slice(0, -1);
      }
    } else {
      return value;
    }
  } else if (lastCharacter === ".") {
    // Check that there is only one decimal
    if ((value.match(/\./g) || []).length > 1) {
      // Remove extra decimal
      return value.slice(0, -1);
    } else {
      return value;
    }
  } else {
    return value.slice(0, -1);
  }
};

export const formatCurrency = value => {
  if (typeof value === "number") value = value.toString();
  if (value && value !== "$" && value !== "0") {
    // Remove commas and dollar sign
    value = value.replace(/\$/g, "");
    value = value.replace(/,/g, "");
    if (value.includes(".")) {
      const decimalLocation = value.indexOf(".");

      // Add thousands seperators
      let digitsBeforeDecimal = value.slice(0, decimalLocation);
      digitsBeforeDecimal = Number(digitsBeforeDecimal).toLocaleString();

      const trailingDigits = value.slice(decimalLocation + 1);
      if (trailingDigits.length <= 2) {
        return `$${digitsBeforeDecimal}.${trailingDigits.padEnd(2, 0)}`;
      } else {
        // Truncate third trailing digit
        return `$${digitsBeforeDecimal}.${trailingDigits.slice(0, 2)}`;
      }
    } else {
      // Add thousands seperator
      const valueWithCommas = Number(value).toLocaleString();
      return `$${valueWithCommas}`;
    }
  } else return "";
};

export const getNested = (theObject, path, separator) => {
  // Allows strings to be used to retreive nested objects
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, theObject);
  } catch (err) {
    return undefined;
  }
};

export const vendorContactRoleHumanReadable = {
  sales_rep: "Sales Representative",
  sales_mgr: "Sales Manager",
  admin: "Administrator",
  executive: "Executive",
  credit_mgr: "Credit Manager",
  support_person: "Support Person",
};

export const legalStructureOption = [
  { value: "803370001", label: "Corporation" },
  { value: "803370002", label: "LLC" },
  { value: "803370000", label: "Sole Proprietorship" },
  { value: "803370003", label: "Partnership" },
  { value: "100000000", label: "Municipality" },
];

export const tibOptions = [
  { value: "803370000", label: "Pre-Startup" },
  { value: "803370001", label: "0-6 Months" },
  { value: "803370002", label: "6-12 Months" },
  { value: "803370003", label: "1-2 Years" },
  { value: "803370004", label: "2-3 Years" },
  { value: "803370005", label: "3-5 Years" },
  { value: "803370006", label: "5-10 Years" },
  { value: "803370007", label: "10+ Years" },
];

export const prequalOption = [
  { value: "100000003", label: "Approved" },
  { value: "100000002", label: "Pre-Approved" },
  { value: "100000000", label: "Probation Review" },
  { value: "100000004", label: "Declined" },
  { value: "100000005", label: "Review Required" },
];

export const toTitleCase = str => {
  if (!str) {
    return "";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const camelToSpaces = str => {
  const spaces = str
    // insert a space before all caps
    .replace(/([A-Z])/g, " $1")
    // uppercase the first character
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
  return spaces;
};

export const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const validationTypes = {
  email: value => {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  },
  minLen: (value, length) => {
    return String(value).length >= length;
  },
  maxLen: (value, length) => {
    return String(value).length <= length;
  },
  required: value => {
    return !!value && String(value).length > 0;
  },
};

export const validateValue = (value, validations) => {
  let status = true;
  validations.forEach(validation => {
    if (!validationTypes[validation.type](value, validation.value)) {
      status = false;
    }
  });
  return status;
};

export const convertToDate = dateString => {
  let newDate = new Date(dateString);
  let dd = newDate.getDate();
  let mm = newDate.getMonth() + 1;
  let yyyy = newDate.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return `${mm}-${dd}-${yyyy}`;
};

export const setFieldValue = (value, fieldName, fields, setFields) => {
  // "fields" and "setFields" - values of useState hook with structure
  //  {
  //    name: {
  //      value: '',
  //        validationStatus: '',
  //        format: formatFunc,
  //        validations: [{type: validationType}]
  //    }
  //  }
  const field = fields[fieldName];
  const newFieldsObj = { ...fields };
  newFieldsObj[fieldName] = {};
  let validationStatus;
  if (!!field.format && typeof field.format === "function") {
    newFieldsObj[fieldName].format = field.format;
    value = field.format(value);
  }
  if (!!field.validations && field.validations.length) {
    validationStatus = validateValue(value, field.validations) ? "success" : "error";
    newFieldsObj[fieldName].validations = field.validations;
    newFieldsObj[fieldName].validationStatus = validationStatus;
  }
  newFieldsObj[fieldName].value = value;
  setFields(newFieldsObj);
};

export const checkObjNotClear = obj => {
  return !!obj && Object.keys(obj).length;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const calcCreditProducts = ({ monthOptions, buyRate, points, loanAmount }) => {
  const creditProducts = monthOptions.map((months, i) => {
    const { sellPayment, simpleInterest, standardSingleDown } = calculateRatesAndPayments(
      loanAmount,
      months,
      buyRate,
      points
    );
    const deferredPayment = numberWithCommas(parseInt(sellPayment * 1.03)); // * 1.03 is for 90 day deferred
    return {
      months,
      payment: numberWithCommas(parseInt(sellPayment)),
      deferredPayment,
      simpleInterestRate: simpleInterest,
      standardSingleDown: numberWithCommas(parseInt(standardSingleDown)),
      rate: 0,
    };
  });
  return creditProducts;
};

export const lookupInterestRate = RUId => {
  switch (RUId) {
    case "674be5a4-94f7-11d1-4256-c2d716bf8d55": // A+ Default
      return { buyRate: 0.0524, points: 0 };
    case "dbf6c8e5-2403-b0c6-8ea6-47ec8fa0d75 d": // A Default
      return { buyRate: 0.09, points: 0 };
    case "ac3df399-f99f-1396-4518-77d26a8fbc29": // B Default
      return { buyRate: 0.12, points: 0 };
    case "c6aebca3-a572-da9f-b632-2e90398d527e": // C Default
      return { buyRate: 0.18, points: 0 };
    case "ddecaa54-1711-2622-207d-ccbea906746b": // D Default
      return { buyRate: 0.25, points: 0 };
    case "e55778e1-4c01-ee84-b42c-5e58c59cc333": // A+ Dental
      return { buyRate: 0.065, points: 0.07 };
    case "119ac09e-571a-5ade-9c5f-dcbd4ac16143": // A Dental
      return { buyRate: 0.075, points: 0.08 };
    case "bf0063b2-278c-ec32-8e0c-e7937d750db3": // B Dental
      return { buyRate: 0.095, points: 0.09 };
    case "fce1a8ae-cd90-e31f-42d8-0afee8db9374": // C Dental
      return { buyRate: 0.165, points: 0.1 };
    case "3acc06fd-0a76-1285-c734-8ef85609a86d": // D Dental
      return { buyRate: 0.205, points: 0.1 };
    default:
      return { buyRate: 0.09, points: 0 };
  }
};

export const calculateRatesAndPayments = (presentValue, terms, buyRate, points) => {
  presentValue = parseFloat(presentValue);
  const buyRatePayment = calculateBuyRatePayment(buyRate / 12, terms, presentValue);
  const sellPayment = buyRatePayment * (1 + points);
  const simpleInterest = calculateSimpleInterestRate(sellPayment, terms, presentValue);
  const sellRate = calcRate(terms, -sellPayment, presentValue) * 12;
  const standardSingleDown = calculateMonthlyPayment(terms, sellRate, presentValue, 0);
  return {
    buyRatePayment,
    sellPayment,
    simpleInterest,
    sellRate,
    standardSingleDown,
  };
};

const calculateSimpleInterestRate = (monthlyPayment, length, amountRequested) => {
  return ((monthlyPayment * length - amountRequested) * 12) / amountRequested / length;
};

const calcRate = (periods, payment, present, future, type, guess) => {
  // https://gist.github.com/kucukharf/677d8f21660efaa33f72
  guess = guess === undefined ? 0.01 : guess;
  future = future === undefined ? 0 : future;
  type = type === undefined ? 0 : type;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 10;

  // Implement Newton's method
  var y,
    y0,
    y1,
    x0,
    x1 = 0,
    f = 0,
    i = 0;
  var rate = guess;
  if (Math.abs(rate) < epsMax) {
    y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = x0 = 0;
  x1 = rate;
  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    ++i;
  }
  return rate;
};

const calculateMonthlyPayment = (termLength, interest, amountRequested, numAdvancedPayments = 1) => {
  const interestByMonth = interest / 12;
  // See formula at the bottom of http://www.tvmcalcs.com/index.php/calculators/apps/lease_payments
  let monthlyPayment =
    amountRequested /
    ((1 - 1 / Math.pow(1 + interestByMonth, termLength - numAdvancedPayments)) / interestByMonth + numAdvancedPayments);
  monthlyPayment = parseInt(monthlyPayment);
  if (monthlyPayment === Infinity || isNaN(monthlyPayment)) {
    monthlyPayment = 0;
  }
  return monthlyPayment;
};

// const numberWithCommas = x => {
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

const calculateBuyRatePayment = (R, n, Pv) => {
  // https://superuser.com/questions/871404/what-would-be-the-the-mathematical-equivalent-of-this-excel-formula-pmt
  // R = Periodic Interest Rate = BuyRate / 12;
  // n = Total # of interest periods;
  // Pv = Present Value;
  // P = monthly payment
  const P = (Pv * R) / (1 - Math.pow(1 + R, -n));
  return P;
};

export const residualPMT = (ir, np, pv, fv, type) => {
  //https://stackoverflow.com/questions/5294074/pmt-function-in-javascript user: vault
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
};

export const isTwoDecimalsFormat = event => {
  const regex = new RegExp(/^\d*\.?\d{0,2}$/g);
  const specialKeys = ["Backspace", "Tab", "End", "Home", "-", "ArrowLeft", "ArrowRight", "Del", "Delete"];

  if (specialKeys.indexOf(event.key) !== -1) {
    return;
  }

  const current = event.target.value.replaceAll(",", "");
  const position = event.target.selectionStart;
  const next = [current.slice(0, position), event.key === "Decimal" ? "." : event.key, current.slice(position)].join(
    ""
  );

  if (next && !String(next).match(regex)) {
    return false;
  }

  return true;
};

export const numberWithCommas = x => {
  if (x.includes(".")) {
    const indexOfPoint = x.indexOf(".");
    const valueBeforePoint = x.slice(0, indexOfPoint);
    const valueAfterPoint = x.slice(indexOfPoint);
    return valueBeforePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + valueAfterPoint.toString();
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const translateStageCodes = code => {
  const dynamicsCreditSubmissionStatusCodes = {
    804790000: "Submitted to Lender",
    804790001: "Lender Reviewing",
    804790002: "Lender - Additional Info Requested",
    804790003: "Lender Approved",
    804790004: "Lender Declined",
    804790005: "Dealer Accepted",
    804790006: "Dealer Cancelled",
    804790007: "Docs Out",
    804790008: "Docs in",
    804790009: "Funding Review",
    804790010: "Funding Items Requested",
    804790011: "Customer Cancelled",
    804790013: "Lender Pre-Approved",
    804790020: "Funded",
    105: "Draft Application Created",
    100: "Awaiting Transmission to Lender",
    300: "Submission Failed",
    101: "Awaiting PreQual Transmission to Lender",
  };
  return code in dynamicsCreditSubmissionStatusCodes ? dynamicsCreditSubmissionStatusCodes[code] : "";
};
