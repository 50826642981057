import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";
import { FormControlLabel, Switch } from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";
import Button from "components/CustomButtons/Button.js";
import NewVOModal from "components/NewVOModal";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import rocketFastTrack from "assets/img/big-rocket.svg";
import FastTrackModal from "components/FastTrackModal";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import FastTrackInfoModal from "components/FastTrackInfoModal";
import { theme } from "../../theme";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import { CreateNewOpportunityButton } from "../../modules/vendorOpportunity";

const useStyles = makeStyles(styles);

const fastTrackStatusesToShowFastTrack = [
  "AWAITING_LENDER_APPROVAL",
  "AWAITING_DEALER_APPROVAL",
  "PROPOSAL_SENT",
  "PROPOSAL_VIEWED",
  "PROPOSAL_ACCEPTED",
  "FUNDED_FAST",
  "PRESCREEN_FAILED",
];

export default function AdminNavbar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { color, rtlActive, brandText, fastTrackOption, isMobile } = props;
  const appBarClasses = cx({ [" " + classes[color]]: color });
  const sidebarMinimize = classes.sidebarMinimize + " " + cx({ [classes.sidebarMinimizeRTL]: rtlActive });
  const [enableFastTrack, setEnableFastTrack] = useState(false);
  const [isNewVOModalOpen, setIsNewVOModalOpen] = useState(false);
  const [fastTrackStatus, setFastTrackStatus] = useState("");
  const [fastTrackInfo, setFastTrackInfo] = useState(false);
  const [fastTrackModal, setFastTrackModal] = useState(false);
  const [voReady, setVoReady] = useState(false);

  const currentVo = useSelector(state => state.vo);
  const vp = useSelector(state => state.vp);

  const [isFastTrackEnabled, setIsFastTrackEnabled] = useState(false);

  const creditApp = useSelector(state => state.creditApp);

  useEffect(() => {
    if (window && !voReady)
      window.addEventListener("voUpdated", e => {
        setVoReady(e.detail.updated);
      });
  }, []);

  useEffect(() => {
    if (currentVo) {
      let fastTrackStatus = _.get(currentVo, "fastTrackStatus", null);
      setFastTrackStatus(fastTrackStatus);
      setEnableFastTrack(currentVo.entityType !== "CASH_SALE");
      setIsFastTrackEnabled(
        vp.fastTrackSetting === "YES_SET_CAMUNDA_PROCESS_CODE" ||
          vp.fastTrackSetting === "YES_AUTOMATED_FOR_DEALER" ||
          vp.fastTrackSetting === "YES_BUT_DEALER_MUST_TRIGGER"
      );
    }
  }, [currentVo]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const fastTrackModalOpen = queryParams.get("fastTrackModal");

    if (voReady && fastTrackModalOpen === "true" && !_.isEmpty(creditApp) && !_.isEmpty(currentVo)) {
      setTimeout(() => {
        if (queryParams.has("fastTrackModal")) {
          queryParams.delete("fastTrackModal");
          history.replace({
            search: queryParams.toString(),
          });
        }

        handleOpenFastTrackModal();
      }, 1500);
    }
  }, [creditApp, currentVo, voReady]);

  const handleOpenFastTrackModal = e => {
    if (e) e.preventDefault();

    setFastTrackModal(true);
  };

  const renderFastTrackStatus = fastTrackStatus => {
    switch (fastTrackStatus) {
      case "AWAITING_LENDER_APPROVAL":
        return (
          <>
            <div className={classes.fastTrackTextColor}>This opportunity has been</div>
            <div className={classes.fastTrackTextStyles}>fast tracked</div>
          </>
        );
      case "AWAITING_DEALER_APPROVAL":
        return (
          <>
            <div className={classes.fastTrackTextStyles} style={{ color: "#fff" }}>
              Send Proposal
            </div>
            <div className={classes.fastTrackTextColor} style={{ color: "#fff" }}>
              to the customer
            </div>
          </>
        );
      case "REQUIRES_DEALER_ATTENTION":
        return (
          <>
            <div className={classes.fastTrackTextColor}>This opportunity</div>
            <div className={classes.fastTrackTextStyles} style={{ color: "orange" }}>
              Requires Attention
            </div>
          </>
        );
      case "PROPOSAL_SENT":
        return (
          <>
            <div className={classes.fastTrackTextColor}>Proposal</div>
            <div className={classes.fastTrackTextStyles}>Sent</div>
            <div className={classes.fastTrackTextColor}>to customer</div>
          </>
        );
      case "PROPOSAL_VIEWED":
        return (
          <>
            <div className={classes.fastTrackTextColor}>Proposal</div>
            <div className={classes.fastTrackTextStyles}>Viewed</div>
            <div className={classes.fastTrackTextColor}>by customer</div>
          </>
        );
      case "PROPOSAL_ACCEPTED":
        return (
          <>
            <div className={classes.fastTrackTextColor}>Proposal</div>
            <div className={classes.fastTrackTextStyles} style={{ color: "#54a954" }}>
              Accepted
            </div>
            <div className={classes.fastTrackTextColor}>by customer</div>
          </>
        );
      case "LENDER_DECLINED":
        return (
          <>
            <div className={classes.fastTrackTextStyles} style={{ color: "red" }}>
              Declined
            </div>
            <div className={classes.fastTrackTextColor}>by lender</div>
          </>
        );
      case "PRESCREEN_FAILED":
        return (
          <>
            <div className={classes.fastTrackTextStyles} style={{ color: "red" }}>
              Failed Prescreen
            </div>
            <div className={classes.fastTrackTextColor}>- please see notes</div>
          </>
        );
      case "CANCELED":
        return (
          <>
            <div className={classes.fastTrackTextColor}>This opportunity has been</div>
            <div className={classes.fastTrackTextStyles} style={{ color: "red" }}>
              Canceled
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={classes.fastTrackTextStyles} style={{ color: "#fff" }}>
              fast track
            </div>
            <div className={classes.fastTrackTextColor} style={{ color: "#fff" }}>
              this opportunity
            </div>
          </>
        );
    }
  };

  const renderFastTrackNavbar = (isDesktop = false, fastTrackStatus, voReady) =>
    voReady &&
    fastTrackOption &&
    isFastTrackEnabled &&
    enableFastTrack &&
    !_.isEmpty(currentVo) && (
      <>
        <div className={isDesktop ? classes.fastTrackDesktopView : classes.fastTrackMobileView}>
          <Button
            style={{
              margin: 0,
              padding: "6px 8px",
              fontSize: "0.875rem",
              background:
                fastTrackStatus === "AWAITING_DEALER_APPROVAL"
                  ? "rgb(131, 75, 255)"
                  : fastTrackStatus === null
                    ? `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`
                    : "none",
              boxShadow: fastTrackStatus !== "AWAITING_DEALER_APPROVAL" || !!fastTrackStatus ? "none" : undefined,
            }}
            onClick={e => {
              if (fastTrackStatus !== "PRESCREEN_FAILED") handleOpenFastTrackModal(e);
            }}>
            <div style={{ display: "flex", columnGap: 5, alignItems: "center", margin: 0, padding: 0 }}>
              <img src={rocketFastTrack} alt="fast track icon" className={classes.fastTrackImageStyles} />
              {renderFastTrackStatus(fastTrackStatus)}
            </div>
          </Button>
          <InfoIcon
            style={{ fontSize: 20, color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => setFastTrackInfo(true)}
          />
        </div>
      </>
    );

  return (
    <>
      <AppBar
        className={classes.appBar + appBarClasses}
        style={
          isMobile ? { position: "absolute", backgroundColor: "rgb(45,45,45)", color: "#fff", borderRadius: 0 } : {}
        }>
        <Toolbar className={classes.container}>
          <Hidden mdDown implementation="css">
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <Hidden mdUp implementation="css">
            <div className={sidebarMinimize}>
              <Button justIcon round color="white" onClick={props.sidebarMobActive}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            </div>
          </Hidden>
          <div className={classes.flex}>
            <Hidden smDown implementation="css">
              <div className={classes.fastTrackDesktopWrapper}>
                <div id="pageLayoutTitle" className={classes.brandTextStyles}>
                  {brandText}
                </div>
                {renderFastTrackNavbar(true, fastTrackStatus, voReady)}
              </div>
            </Hidden>
            <Hidden smUp implementation="css">
              <h2 style={{ paddingLeft: "20px", fontSize: "1.6em", marginBlockStart: 0, marginBlockEnd: 0 }}>
                {brandText}
              </h2>
              {renderFastTrackNavbar(null, fastTrackStatus, voReady)}
            </Hidden>
          </div>
          <CreateNewOpportunityButton handleClick={() => setIsNewVOModalOpen(true)} />
        </Toolbar>
      </AppBar>
      {vp && currentVo && creditApp && (
        <FastTrackModal
          open={fastTrackModal}
          onClose={() => setFastTrackModal(false)}
          currentVo={currentVo}
          creditApp={creditApp}
          globalVp={vp}
        />
      )}
      <FastTrackInfoModal open={fastTrackInfo} onClose={() => setFastTrackInfo(false)} showButton={false} />
      <NewVOModal open={isNewVOModalOpen} onClose={() => setIsNewVOModalOpen(false)} />
    </>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  sidebarMobActive: PropTypes.func,
  mobActive: PropTypes.bool,
  routeType: PropTypes.string,
  fastTrackOption: PropTypes.any,
  isMobile: PropTypes.bool,
};
