import _ from "lodash";
import { ValueOf } from "global";
import { FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { EquipmentSourcedRequiredOptionsValue, Fact, FinanceProgramFormValues } from "../types";

export const equipmentConditions = [
  { label: "New", value: "new" },
  { label: "Used", value: "used" },
  { label: "Demo", value: "demo" },
  { label: "Reconditioned", value: "reconditioned" },
];

export const equipmentYears = _.range(1990, new Date().getFullYear() + 3).map(value => ({ label: `${value}`, value }));

export const equipmentDependentFields: (keyof ValueOf<Pick<FinanceProgramFormValues, "equipment">, "equipment">)[] = [
  "year",
  "make",
  "model",
  "class",
  "stockId",
  "newUsed",
  "quantity",
  "description",
  "retailPrice",
  "serialNumberVin",
  "totalWholesaleCost",
];

// Required Options for Dynamic Fields. Will be added to options even if the current product set does not support it.
export const requiredOptionsForDynamicFields: Record<Fact, string[]> = {
  [FACT_NAMES_STRING.AFTERMARKET_PRODUCT_SOURCE]: [
    EquipmentSourcedRequiredOptionsValue.DTRSourced,
    EquipmentSourcedRequiredOptionsValue.NonDTRSourced,
  ],
};

export const factsKeyMapToFieldLabel: Record<Fact, string> = {
  [FACT_NAMES_STRING.AFTERMARKET_PRODUCT_SOURCE]: "EQUIPMENT SOURCE",
  [FACT_NAMES_STRING.COVERAGE_TERM]: "Set Coverage Term to Finance Term",
  [FACT_NAMES_NUMERIC.DEAL_SIZE]: "AMOUNT ($)",
};
