import React from "react";
import { TextField } from "mui-rff";
import InputAdornment from "@mui/material/InputAdornment";
import { formatMoney, parseIntMoneyString } from "../utils";

const CurrencyInput = ({ name, label, value }) => {
  return (
    <TextField
      name={name}
      label={label}
      fieldProps={{
        value: value ?? null,
        format: v => formatMoney(v),
        parse: v => parseIntMoneyString(v),
        fullWidth: true,
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};

export default CurrencyInput;
