import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

// material-ui components
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
// import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";

// core components
import UpdateMessageModal from "../../components/UpdateMessageModal";
import Button from "../../components/CustomButtons/Button";
import Tile from "../../components/Tile/Tile";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);
const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 500,
  },
  paper: {
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  margin: {
    marginBottom: "8px",
  },
  marginDivider: {
    marginTop: "33px",
    marginBottom: "8px",
  },
  invoiceTotalMargin: {
    "& .MuiDivider-root": {
      border: "solid 10px black",
      marginBottom: "8px",
      marginTop: "16px",
      fontWeight: 900,
    },
  },
  mainContainer: {
    "& label": {
      fontWeight: 600,
      fontSize: "20px",
    },
    "& .MuiFormControl-root": {
      marginBottom: 0,
    },
  },
}));

const UPDATE_DELIVERY_TIMELINE_MUTATION = gql`
  mutation ($fieldsObj: UpdateDeliveryTimelineInput!, $entityId: ID!, $VOId: ID!) {
    updateDeliveryTimeline(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

function DeliveryTimelineModal({ open, handleClose, vo, refetchVO }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const classesPaper = useStylesPaper();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(vo.deliveryStatus);
  const [orderDate, setOrderDate] = useState(vo.orderDate ? new Date(vo.orderDate + "T00:00:00") : null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(
    vo.expectedDeliveryDate ? new Date(vo.expectedDeliveryDate + "T00:00:00") : null
  );
  const [equipmentAcceptanceDate, setEquipmentAcceptanceDate] = useState(
    vo.equipmentAcceptanceDate ? new Date(vo.equipmentAcceptanceDate + "T00:00:00") : null
  );
  const [deliveryDate, setDeliveryDate] = useState(vo.deliveryDate ? new Date(vo.deliveryDate + "T00:00:00") : null);
  const [invoiceStatus, setInvoiceStatus] = useState(vo.invoiceStatus);

  const [updateDeliveryTimeline] = useMutation(UPDATE_DELIVERY_TIMELINE_MUTATION, {
    context: { authRequired: true },
  });

  const handleSubmitDeliveryTimeline = async () => {
    try {
      await updateDeliveryTimeline({
        variables: {
          fieldsObj: {
            deliveryStatus: deliveryStatus,
            orderDate: orderDate,
            expectedDeliveryDate: expectedDeliveryDate,
            equipmentAcceptanceDate: equipmentAcceptanceDate,
            deliveryDate: deliveryDate,
            invoiceStatus: invoiceStatus,
          },
          VOId: vo.vendorOpportunityId,
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await refetchVO();
      await setResponseSuccess(true);
      await handleOpenMessageModal();

      setTimeout(() => {
        handleClose();
        handleCloseMessageModal();
      }, 1500);
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };

  const handleOpenMessageModal = () => {
    setOpenMessageModal(true);
  };

  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(null);
  };

  const handleChangeDeliveryStatus = event => {
    setDeliveryStatus(event.target.value);
  };

  const handleChangeOrderDate = date => {
    setOrderDate(date);
  };

  const handleChangeExpectedDeliveryDate = date => {
    setExpectedDeliveryDate(date);
  };

  const handleChangeEquipmentAcceptanceDate = date => {
    setEquipmentAcceptanceDate(date);
  };

  const handleChangeDeliveryDate = date => {
    setDeliveryDate(date);
  };

  const handleChangeInvoiceStatus = event => {
    setInvoiceStatus(event.target.value);
  };
  const years = range(new Date().getFullYear(), getYear(new Date()) + 2, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div style={{ width: "800px" }}>
      <Dialog onClose={handleClose} open={open} scroll={"body"} fullScreen={!matches}>
        <Paper className={classesPaper.paper}>
          <CardContent>
            <Tile>
              <div className={classesPaper.mainContainer}>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Delivery Status</h6>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={deliveryStatus}
                        onChange={handleChangeDeliveryStatus}
                        classes={{ select: classesPaper.select }}>
                        <MenuItem value={"order_pending"}>Order Pending</MenuItem>
                        <MenuItem value={"back_ordered"}>Back Ordered</MenuItem>
                        <MenuItem value={"shipped"}>Shipped</MenuItem>
                        <MenuItem value={"delivered_pending_da"}>Delivered (Pending Acceptance)</MenuItem>
                        <MenuItem value={"da_complete"}>Customer Accepted</MenuItem>
                        <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Order Date</h6>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "7px" }}>
                    <DatePicker
                      selected={orderDate}
                      onChange={date => handleChangeOrderDate(date)}
                      withPortal
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <>
                          <div>Please Enter Your Order Date</div>
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <br />
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Expected Delivery Date</h6>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "7px" }}>
                    <DatePicker
                      selected={expectedDeliveryDate}
                      onChange={date => handleChangeExpectedDeliveryDate(date)}
                      withPortal
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <>
                          <div>Please Enter Your Expected Delivery Date</div>
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Equipment Acceptance Date</h6>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "7px" }}>
                    <DatePicker
                      selected={equipmentAcceptanceDate}
                      onChange={date => handleChangeEquipmentAcceptanceDate(date)}
                      withPortal
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <>
                          <div>Please Enter Your Equipment Acceptance Date</div>
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Invoice Status</h6>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={invoiceStatus}
                        onChange={handleChangeInvoiceStatus}
                        classes={{ select: classesPaper.select }}
                        disabled>
                        <MenuItem value={"Requested"}>Requested</MenuItem>
                        <MenuItem value={"Received (Pending Approval)"}>Received (Pending Approval)</MenuItem>
                        <MenuItem value={"Approved"}>Approved</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h6>Delivery Date</h6>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "7px" }}>
                    <DatePicker
                      selected={deliveryDate}
                      onChange={date => handleChangeDeliveryDate(date)}
                      withPortal
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <>
                          <div>Please Enter The Delivery Date</div>
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <hr style={{ borderTop: "8px solid #bbb", borderRadius: "2px" }} />
                <Grid className={classesPaper.margin}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" onClick={handleClose} style={{ marginRight: 16 }}>
                      Close
                    </Button>
                    <Button color="primary" onClick={handleSubmitDeliveryTimeline}>
                      Confirm
                    </Button>
                  </div>
                </Grid>
                <UpdateMessageModal
                  open={openMessageModal}
                  handleCloseMessageModal={handleCloseMessageModal}
                  responseSuccess={responseSuccess}
                />
              </div>
            </Tile>
          </CardContent>
        </Paper>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
});

export default connect(mapStateToProps, null)(DeliveryTimelineModal);
