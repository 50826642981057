import React, { Fragment, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import arrayMutators from "final-form-arrays";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import analytics from "../../analytics";
import { tibOptions } from "../../utils";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Tile from "../../components/Tile/Tile";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import formatString from "format-string-by-pattern";
import { Select } from "@mui/material";
import { usStates } from "./constants";

const CREATE_PREQUAL_CREDIT_APP = gql`
  mutation ($creditApplication: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplication) {
      id
    }
  }
`;

const Fields = ({ names, subscription, fieldsState = {}, children, originalRender }) => {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <Field name={name} subscription={subscription}>
      {fieldState => (
        <Fields
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </Field>
  );
};

const useStyles = makeStyles(styles);

export default function PrequalApp() {
  const [tibValue, setTibValue] = useState(tibOptions[0].value);
  const [pocValue, setPocValue] = useState("0");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [amountRequestedValue, setAmountRequestedValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [businessNameValue, setBusinessNameValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [owners, setOwners] = useState([{ firstName: "", lastName: "" }]);
  const [formValid, setFormValid] = useState(false);

  const [createPrequalCreditApp] = useMutation(CREATE_PREQUAL_CREDIT_APP, {
    authRequired: true,
  });

  function validateForm() {
    const errors = {};
    if (!amountRequestedValue) {
      errors.amountRequested = "Required";
    } else if (amountRequestedValue === "$0") {
      errors.amountRequested = "Amount Requested cannot be $0";
    }
    if (!firstNameValue) {
      errors.firstName = "Required";
    }
    if (!lastNameValue) {
      errors.lastName = "Required";
    }
    if (!emailValue) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
      errors.email = "Invalid email address";
    }
    if (!phoneValue) {
      errors.phone = "Required";
    } else if (phoneValue.replace(/[^\d]/g, "").length < 10) {
      errors.phone = "Invalid phone number";
    }
    if (!businessNameValue) {
      errors.businessName = "Required";
    }
    if (!addressValue) {
      errors.address = "Required";
    }
    if (!cityValue) {
      errors.city = "Required";
    }
    if (!stateValue) {
      errors.state = "Required";
    }
    if (!postalCodeValue) {
      errors.postalCode = "Required";
    } else if (postalCodeValue.length !== 5) {
      errors.postalCode = "Invalid postal code";
    }
    if (!tibValue) {
      errors.tib = "Required";
    }
    if (!owners || !owners.length) {
      errors.owners = "At least one owner must be entered";
    } else {
      const ownersArrayErrors = [];
      owners.forEach((owner, ownerIndex) => {
        const ownerErrors = {};
        if (!owner || !owner.firstName) {
          ownerErrors.firstName = "Required";
        }
        if (!owner || !owner.lastName) {
          ownerErrors.lastName = "Required";
        }
        if (Object.keys(ownerErrors).length) {
          ownersArrayErrors[ownerIndex] = ownerErrors;
        }
      });
      if (!!ownersArrayErrors && ownersArrayErrors.length) {
        errors.owners = ownersArrayErrors;
      }
    }
    setFormValid(!Object.keys(errors).length);
    return errors;
  }

  const isValidPhoneNumber = field => field && field.replace(/[^\d]/g, "").length < 10;

  const upperCaseFirstLetters = value => {
    let formattedValue = value.split("");

    return formattedValue
      .map((letter, i) => {
        if (i === 0 || formattedValue[i - 1] === " " || formattedValue[i - 1] === ".") {
          return letter.toUpperCase();
        }

        return letter.toLowerCase();
      })
      .join("");
  };

  function setValue(setValFunc, val) {
    setValFunc(val);
  }

  const classes = useStyles();
  return (
    <Tile title="PreQual Request">
      <Form
        onSubmit={values => {
          analytics.track("credit_application-submit_attempted");
          // Split the Equipment Description into a multiline string
          const equipmentDescription = values.equipmentDescription
            .map(({ description, quantity }) => `${quantity} - ${description}`)
            .join(", \n");
          const poc = pocValue;
          delete values.equipmentDescription;
          if (typeof poc === "number") {
            values.firstName = values.owners[poc].firstName;
            values.lastName = values.owners[poc].lastName;
            setFirstNameValue(values.owners[poc].firstName);
            setLastNameValue(values.owners[poc].lastName);
          }
          values.ccg_transaction_equipmentdescription = equipmentDescription;
          const vals = {
            amountRequested: parseFloat(amountRequestedValue),
            businessName: businessNameValue,
            address: addressValue,
            city: cityValue,
            state: stateValue,
            postalCode: postalCodeValue,
            tib: tibValue,
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            owners,
          };
          const updatedValues = { ...vals, dcrType: "Credit App - PreQual" };
          return new Promise(async (resolve, reject) => {
            const result = await createPrequalCreditApp({ variables: { creditApplication: updatedValues } });
            if (result.data.createCreditApplication.id) {
              resolve((window.location.href = "/prequal-receipt"));
            } else {
              reject((window.location.href = "/app-error"));
            }
          });
        }}
        validate={validateForm}
        initialValues={{ poc: 0 }}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          form: {
            change,
            getState,
            mutators: { push, pop },
          },
          pristine,
          invalid,
          submitting,
        }) => {
          const formState = getState();
          const { poc, legalStructure } = formState.values;
          // Setting Point of Contact to be the same as owner
          if (typeof poc === "number") {
            try {
              const { firstName, lastName } = formState.values.owners[poc];
              change("firstName", firstName);
              change("lastName", lastName);
            } catch (error) {
              console.error(error);
            }
          }
          // Set Percent Ownership to 100% if Legal Structure is Sole Proprietorship
          if (legalStructure === "803370000") {
            change("owners[0].percOwner", 100);
          }
          return (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <div>
                  <h4>Equipment</h4>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <CustomInput
                        labelText="Quote Amount"
                        id="amountRequested"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "amountRequested",
                          type: "text",
                          value: amountRequestedValue,
                          onChange: e => {
                            const value = e.target.value.replace(/[^\d.]/, "");
                            setValue(setAmountRequestedValue, value);
                          },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div style={{ padding: "20px 0" }}>
                  <FieldArray name="equipmentDescription">
                    {({ fields }) => {
                      if (fields.length === 0) {
                        pop("equipmentDescription", "");
                      }
                      if (fields.length === 0) {
                        push("equipmentDescription", "");
                      }
                      return fields.map((name, index) => (
                        <Fragment key={index}>
                          <Grid container spacing={4}>
                            <Grid item xs={2} sm={1} style={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  pop("equipmentDescription", "");
                                }}
                                disabled={index === 0}
                                size="large">
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={4} sm={3} md={2} lg={1} style={{ display: "flex", alignItems: "center" }}>
                              <CustomInput
                                labelText="Quantity"
                                id={`${name}.quantity`}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  name: `${name}.quantity`,
                                  type: "number",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={8} md={9} lg={10} style={{ display: "flex", alignItems: "center" }}>
                              <CustomInput
                                labelText="Description"
                                id={`${name}.description`}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  name: `${name}.description`,
                                  type: "text",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      ));
                    }}
                  </FieldArray>
                  <Button color="primary" variant="outlined" onClick={() => push("equipmentDescription", "")}>
                    ADD ITEM
                  </Button>
                </div>
                <div style={{ padding: "20px 0" }}>
                  <h4>Business</h4>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8} md={6} lg={6}>
                      <CustomInput
                        labelText="Legal Business Name"
                        id="businessName"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "businessName",
                          type: "text",
                          value: businessNameValue,
                          onChange: e => setValue(setBusinessNameValue, e.target.value),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <CustomInput
                        labelText="Business Address"
                        id="address"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "address",
                          type: "text",
                          value: addressValue,
                          onChange: e => {
                            const value = upperCaseFirstLetters(e.target.value);
                            setValue(setAddressValue, value);
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <CustomInput
                        labelText="City"
                        id="city"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "city",
                          type: "text",
                          value: cityValue,
                          onChange: e => {
                            const value = upperCaseFirstLetters(e.target.value);
                            setValue(setCityValue, value);
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl fullWidth className={classes.stateSelector}>
                        <InputLabel id="State">State</InputLabel>
                        <Select
                          onChange={e => setValue(setStateValue, e.target.value)}
                          labelId="State"
                          value={stateValue}>
                          {usStates.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <CustomInput
                        labelText="ZIP"
                        id="postalCode"
                        formControlProps={{ fullWidth: true }}
                        error={postalCodeValue && postalCodeValue.length !== 5}
                        errorMessage={
                          postalCodeValue && postalCodeValue.length !== 5
                            ? "Please ensure postal code has been entered correctly."
                            : null
                        }
                        inputProps={{
                          name: "postalCode",
                          type: "text",
                          value: postalCodeValue,
                          onChange: e => {
                            let value = e.target.value.replace(/[^\d]/, "");
                            if (value.length > 5) {
                              value = value.slice(0, -1);
                            }
                            setValue(setPostalCodeValue, value);
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8} md={6} lg={6}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="tib" className={classes.selectLabel}>
                          Years in Business (Approximate)
                        </InputLabel>
                        <MuiSelect
                          classes={{ select: classes.select }}
                          inputProps={{
                            name: "tib",
                            id: "tib",
                            value: tibValue,
                            onChange: e => setValue(setTibValue, e.target.value),
                          }}>
                          {tibOptions.map(({ value, label }, i) => (
                            <MenuItem value={value} key={i} classes={{ root: classes.selectMenuItem }}>
                              {label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ paddingTop: "20px 0" }}>
                  <h4>Ownership</h4>
                  <FieldArray name="owners">
                    {({ fields }) => {
                      if (fields.length === 0) {
                        push("owners", "");
                      }
                      return fields.map((name, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 ? <Divider variant="inset" /> : null}
                          <Grid container key={index}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText="First Name"
                                  id={`${name}.firstName`}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    name: `${name}.firstName`,
                                    type: "text",
                                    value: owners[index].firstName,
                                    onChange: e => {
                                      const newOwners = [...owners];
                                      const value = upperCaseFirstLetters(e.target.value);
                                      newOwners[index].firstName = value;
                                      setOwners(newOwners);
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText="Last Name"
                                  id={`${name}.lastName`}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    name: `${name}.lastName`,
                                    type: "text",
                                    value: owners[index].lastName,
                                    onChange: e => {
                                      const newOwners = [...owners];
                                      const value = upperCaseFirstLetters(e.target.value);
                                      newOwners[index].lastName = value;
                                      setOwners(newOwners);
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <div style={{ padding: "20px 0 30px 0" }}>
                            {index === 0 ? (
                              <Button
                                className="formBtn"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  owners.push({ firstName: "", lastName: "" });
                                  push("owners", "");
                                }}
                                disabled={
                                  fields.length >= 2 || (!!fields.value[0] && fields.value[0].percOwner === 100)
                                }>
                                ADDITIONAL OWNERS
                              </Button>
                            ) : (
                              <Button
                                className="formBtn"
                                variant="outlined"
                                onClick={() => {
                                  pop("owners", "");
                                  owners.splice(1, 1);
                                }}>
                                REMOVE OWNER
                              </Button>
                            )}
                          </div>
                        </React.Fragment>
                      ));
                    }}
                  </FieldArray>
                </div>
                <div style={{ padding: "20px 0" }}>
                  <h4>Point of Contact</h4>
                  <Fields names={["owners"]}>
                    {fieldState => {
                      const numberOfOwners = fieldState.owners.input.value.length;
                      return (
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={8} md={6} lg={4}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel htmlFor="poc" className={classes.selectLabel}>
                                Point of Contact
                              </InputLabel>
                              <MuiSelect
                                classes={{ select: classes.select }}
                                inputProps={{
                                  name: "poc",
                                  id: "poc",
                                  value: pocValue,
                                  onChange: e => setValue(setPocValue, e.target.value),
                                }}>
                                <MenuItem value="0" classes={{ root: classes.selectMenuItem }}>
                                  Owner 1
                                </MenuItem>
                                {numberOfOwners > 1 ? (
                                  <MenuItem value="1" classes={{ root: classes.selectMenuItem }}>
                                    Owner 2
                                  </MenuItem>
                                ) : null}
                                <MenuItem value="other" classes={{ root: classes.selectMenuItem }}>
                                  Other - Enter Below
                                </MenuItem>
                              </MuiSelect>
                            </FormControl>
                          </Grid>
                          <OnChange name="poc">
                            {(value, previous) => {
                              if (value === "other") {
                                change("firstName", "");
                                change("lastName", "");
                              }
                            }}
                          </OnChange>
                          <Grid item xs={8} />
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="First Name"
                              id="firstName"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                name: "firstName",
                                type: "text",
                                value: firstNameValue,
                                onChange: e => {
                                  const value = upperCaseFirstLetters(e.target.value);
                                  setValue(setFirstNameValue, value);
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Last Name"
                              id="lastName"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                name: "lastName",
                                type: "text",
                                value: lastNameValue,
                                onChange: e => {
                                  const value = upperCaseFirstLetters(e.target.value);
                                  setValue(setLastNameValue, value);
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Fields>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "email",
                          type: "email",
                          value: emailValue,
                          onChange: e => setValue(setEmailValue, e.target.value),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Phone Number"
                        id="phone"
                        formControlProps={{ fullWidth: true }}
                        error={isValidPhoneNumber(phoneValue)}
                        errorMessage={
                          isValidPhoneNumber(phoneValue)
                            ? "Please ensure phone number has been entered correctly."
                            : null
                        }
                        inputProps={{
                          name: "phone",
                          type: "text",
                          value: phoneValue,
                          onChange: e => {
                            const value = formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, ""));
                            setValue(setPhoneValue, value);
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div style={{ padding: "30px 0 0 0" }}>
                  <Grid container>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting || !formValid}>
                      {submitting ? "Submitting" : "Submit"}
                    </Button>
                  </Grid>
                </div>
              </CardContent>
            </form>
          );
        }}
      />
    </Tile>
  );
}
