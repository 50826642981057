import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import Button from "../../components/CustomButtons/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import UpdateMessageModal from "../../components/UpdateMessageModal";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { blue } from "@mui/material/colors";

const useStylesPaper = makeStyles(theme => ({
  clear: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  formControl: {
    minWidth: 500,
    marginTop: "20px",
    marginBottom: "20px",
  },
  margin: {
    marginLeft: "15px",
    padding: "5px 10px",
  },
  ...extendedFormsStyle,
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(styles);

const UPDATE_TEMPLATE = gql`
  mutation ($input: UpdateTemplateInput!, $id: ID) {
    updateTemplate(input: $input, id: $id) {
      id
    }
  }
`;

export default function TemplateDetailModal({
  open,
  handleClose,
  template,
  templatesRefetch,
  // updated,
  // setUpdated
}) {
  const classesPaper = useStylesPaper();
  const [creditSubStage, setCreditSubStage] = useState(!!template && template.creditSubStage);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [templateStatus, setTemplateStatus] = useState((!!template && template.status) || "");
  const [docusignTemplateId, setDocusignTemplateId] = useState((!!template && template.docusignTemplateId) || "");

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    context: { authRequired: true },
  });
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (template) {
      setTemplateStatus(template.status);
      setDocusignTemplateId(template.docusignTemplateId);
    }
  }, [template]);

  const handleChangeDocusignTemplateId = event => {
    setDocusignTemplateId(event.target.value);
  };
  const handleChangeStatus = event => {
    setTemplateStatus(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await updateTemplate({
        variables: {
          input: {
            docusignTemplateId: docusignTemplateId,
            status: templateStatus,
          },
          id: template.key,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        await templatesRefetch();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleClose();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        await templatesRefetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCancel = () => {
    setDocusignTemplateId("");
    setTemplateStatus("");
    handleClose();
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };

  const handleSetOpenConfirmOpen = () => setOpenConfirm(true);
  const handleSetOpenConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <>
      {!!template && (
        <div style={{ width: "400px" }} key={template.templateId}>
          <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"md"} fullWidth={true}>
            <MuiDialogTitle disableTypography className={classes.root}>
              <Typography variant="h6">Template Id Detail</Typography>
              {open ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
            <Paper className={classesPaper.paper}>
              <CardContent>
                <div style={{ padding: "20px 0" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <div style={{ fontSize: "15px" }}>
                        <b>Template Name: </b>
                        {template.name || ""}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div style={{ fontSize: "15px" }}>
                        <b>Created Date: </b>
                        {template.createdDateTime || ""}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <>
                      <Grid item xs={12} md={4} lg={4}>
                        <CustomInput
                          labelText="DocuSign Template Id"
                          id="docusign-template-id"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            name: "docusignTemplateId",
                            type: "text",
                            value: docusignTemplateId,
                            onChange: handleChangeDocusignTemplateId,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <CustomInput
                          labelText="Status"
                          id="templateStatus"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            name: "templateStatus",
                            type: "text",
                            value: templateStatus,
                            onChange: handleChangeStatus,
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button onClick={handleCancel} color="primary">
                        Cancel and Close
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleSubmit} color="primary">
                        Confirm Changes
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <UpdateMessageModal
                    open={openMessageModal}
                    handleCloseMessageModal={handleCloseMessageModal}
                    responseSuccess={responseSuccess}
                  />
                </div>
                <br />
              </CardContent>
            </Paper>
          </Dialog>
        </div>
      )}
    </>
  );
}
