import React from "react";
import { FormInput } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const NumericField = ({ label, fact, value, onChange, helperText, error }: EquipmentDynamicFieldsProps) => (
  <FormInput
    error={error}
    value={value}
    variant="standard"
    helperText={error ? helperText : ""}
    onChange={value => onChange(value)}
    label={label ?? fact.factKey.replace(/_/g, " ")}
  />
);
