import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ContactFormValues, ContactType } from "../../../types";
import { ContactTypeRadio } from "./ContactTypeRadio";

interface Props {
  contactFormValues: ContactFormValues;
  contactType: ContactType;
  setContactFormValues: any;
  setContactType: any;
  hideContactType?: boolean;
}

export const MainInformationForm = ({
  contactFormValues,
  contactType,
  setContactFormValues,
  setContactType,
  hideContactType,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.informationContainerHeader}>
          Main Information
        </Typography>
      </Grid>
      {!hideContactType && <ContactTypeRadio contactType={contactType} setContactType={setContactType} />}
      <Grid item container xs={12} spacing={2} style={{ paddingTop: 0 }}>
        <Grid item xs={4}>
          <TextField
            label="First Name"
            required
            fullWidth
            className={classes.requiredInput}
            value={contactFormValues.firstName}
            onChange={e => setContactFormValues({ ...contactFormValues, firstName: e.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Last Name"
            required
            fullWidth
            className={classes.requiredInput}
            value={contactFormValues.lastName}
            onChange={e => setContactFormValues({ ...contactFormValues, lastName: e.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Email"
            required
            fullWidth
            className={classes.requiredInput}
            value={contactFormValues.email}
            onChange={e => setContactFormValues({ ...contactFormValues, email: e.target.value })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  informationContainerHeader: {
    textTransform: "none",
    fontSize: 15,
  },
  requiredInput: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
});
