import React, { useRef, useState } from "react";
import ReactTable from "react-table";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import makeStyles from "@mui/styles/makeStyles";
import { Query } from "@apollo/client/react/components";
import Tile from "../../components/Tile/Tile";
import Button from "components/CustomButtons/Button";
import { convertToDate, formatCurrency } from "../../utils";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import { Add } from "@mui/icons-material";
import AddCreditProgram from "./AddCreditProgram";
import { useSelector } from "react-redux";
import _ from "lodash";
import TableSearch from "components/Table/TableSearch";
import DragIndicator from "assets/img/drag-indicator.svg";

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
});

const useStyles = makeStyles(styles);

const GET_CREDIT_PRODUCTS_ACCOUNT = gql`
  query {
    accountCreditProducts {
      id
      productId
      productName
      productHeader
      term
      rate
      payment
      rateFactor
      riskTier
      amountMin
      amountMax
      docFee
      advancePayment
      markupDealer
      markupInternal
      lender
      accountId
      residual
      lenderName
      manufacturers
      makes
      modelYears
      validFrom
      validTo
      additionalTerms
      pdfFlyer
      source
      createdDateTime
      contractType
    }
  }
`;

function PageCreditPrograms(props) {
  const userProfileRole = useSelector(state => state.userProfile.vendorContactRole);
  const classes = useStyles();
  const [creditProgramsList, setCreditProgramsList] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(20);

  const statusesSel = [];
  const rolesSel = [];

  function handleCreditProgramRowClick(creditProductId) {
    props.history.push(`/creditProgramDetail/${creditProductId}`);
  }

  const filterRepListData = RepListData => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return RepListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = RepListData.accountCreditProducts.filter(el => {
      if (
        typeof el.createdDateTime === "string" ||
        typeof el.productHeader === "string" ||
        typeof el.contractType === "string" ||
        typeof el.amountMin === "string" ||
        typeof el.lenderName === "string" ||
        typeof el.amountMax === "string" ||
        typeof el.validFrom === "string" ||
        typeof el.validTo === "string"
      ) {
        return (
          (el.createdDateTime || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.productHeader || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.contractType || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.amountMin || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lenderName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.amountMax || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.validFrom || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.validTo || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return { ...RepListData, accountCreditProducts: searchData };
  };

  function processRepListData(creditProgramList) {
    if (creditProgramList && _.isArray(creditProgramList.accountCreditProducts)) {
      const statuses = [];
      const roles = [];
      creditProgramList.accountCreditProducts.forEach(item => {
        try {
          item.amountRequested = formatCurrency(item.amountRequested);
          item.contractType = item.contractType.charAt(0).toUpperCase() + item.contractType.slice(1);
          item.createdDateTime = convertToDate(item.createdDateTime);
          item.validFrom = convertToDate(item.validFrom);
          item.validTo = convertToDate(item.validTo);
          if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
            statuses.push(item.status);
          }
        } catch (error) {
          console.log(error);
        }
      });
      statuses.forEach(item => {
        const statusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        statusesSel.push(statusesSelItem);
      });
      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });

      return creditProgramList.accountCreditProducts;
    }
  }
  const [openAddNewCreditProgramModal, setOpenAddNewCreditProgramModal] = useState(false);

  const handleOpenAddNewCreditProgramModal = () => {
    setOpenAddNewCreditProgramModal(true);
  };
  const handleCloseAddNewCreditProgramModal = () => {
    setOpenAddNewCreditProgramModal(false);
  };

  return (
    <>
      <Query context={{ authRequired: true }} query={GET_CREDIT_PRODUCTS_ACCOUNT} fetchPolicy="no-cache">
        {({ loading, error, data, refetch: refetchCreditPrograms }) => {
          if (loading) return "Loading...";
          if (error) return <h3>{String(error)}</h3>;
          const repList = processRepListData(filterRepListData(data));

          return (
            <div className={classes.root}>
              <Grid>
                <Grid item xs={12}>
                  <Tile>
                    <div className={classes.fixReactTable}>
                      <Grid container>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          <TableSearch
                            setPageNumber={setPageNumber}
                            setSavedListSettings={setSavedListSettings}
                            searchRef={searchRef}
                            savedListSettings={savedListSettings}
                          />
                        </Grid>
                      </Grid>
                      {userProfileRole !== "sales_rep" && (
                        <Grid container>
                          <Grid item xs={12} sm={3} md={3} lg={2}>
                            <Button color="primary" size="sm" onClick={() => handleOpenAddNewCreditProgramModal()}>
                              {<Add />}Create New
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      <ReactTable
                        color="primary"
                        data={repList}
                        getTrProps={(state, rowInfo, column, instance) => ({
                          style: { cursor: "pointer" },
                          onClick: e => handleCreditProgramRowClick(rowInfo.original.id),
                        })}
                        columns={[
                          {
                            Header: "Created On",
                            accessor: "createdDateTime",
                            resizable: false,
                          },
                          {
                            Header: "Product Name",
                            accessor: "productHeader",
                          },
                          {
                            Header: "Contract Type",
                            accessor: "contractType",
                          },
                          {
                            Header: "Amount Min",
                            accessor: "amountMin",
                          },
                          {
                            Header: "Lender Name",
                            accessor: "lenderName",
                          },
                          {
                            Header: "Amount Max",
                            accessor: "amountMax",
                          },
                          {
                            Header: "Valid From",
                            accessor: "validFrom",
                          },
                          {
                            Header: "Valid To",
                            accessor: "validTo",
                          },
                        ]}
                        onPageChange={changePage => {
                          setPageNumber(changePage);
                        }}
                        onPageSizeChange={changePageSize => {
                          setPageSizeValue(changePageSize);
                          setPageNumber(0);
                        }}
                        pageSize={pageSizeValue}
                        defaultPageSize={savedListSettings.pageSize}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        customCellClasses={[classes.right]}
                        customClassesForCells={[5]}
                        className="-striped -highlight"
                      />
                    </div>
                  </Tile>
                </Grid>
              </Grid>
              <AddCreditProgram
                open={openAddNewCreditProgramModal}
                handleClose={() => handleCloseAddNewCreditProgramModal()}
                handleOpenAddNewRepModal={handleOpenAddNewCreditProgramModal}
                refetchCreditPrograms={refetchCreditPrograms}
              />
            </div>
          );
        }}
      </Query>
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCreditPrograms);
