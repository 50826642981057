import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Table from "./Table";

const styles = {
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  wrapperStyles: {
    "& .red_flag_pid_comp_wrap": {
      margin: "0 0 8px 0",
    },
    "& .red_flag_main_wrap": {
      backgroundColor: "#fff",
    },
    "& .red_flag_btn": {
      display: "none",
    },
  },
};

const useStyles = makeStyles(styles);

const TRIGGER_WEBHOOK = gql`
  mutation TriggerWebhook($entityId: String!, $eventType: WebhookEventType!, $accountId: String!) {
    triggerWebhook(entityId: $entityId, eventType: $eventType, accountId: $accountId)
  }
`;

const UPSERT_CREDIT_REPORT_LOG = gql`
  mutation UpsertCreditReportConsumerAuditLog($creditReportConsumerId: String!, $auditLog: JSON!) {
    upsertCreditReportConsumerAuditLog(creditReportConsumerId: $creditReportConsumerId, auditLog: $auditLog)
  }
`;

const ConsumerCreditReport = ({
  report,
  ownerId,
  accountId,
  userName,
  index,
  creditReportConsumerId,
  auditLog,
  isIframe = false,
  putAuditLog = null,
  dateReportWasPulled, // JavaScript Date Object
}) => {
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [auditLogs, setAuditLogs] = useState(auditLog || []);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [changes, setChanges] = useState({
    ADDRESS: { value: "", comment: "" },
    SSN: { value: "", comment: "" },
    OFAC: { value: "", comment: "" },
    FRAUD: { value: "", comment: "" },
    DOB: { value: "", comment: "" },
  });

  const [triggerWebhook] = useMutation(TRIGGER_WEBHOOK, {
    context: { authRequired: false },
  });

  const [upsertCreditReportConsumerAuditLog] = useMutation(UPSERT_CREDIT_REPORT_LOG, {
    context: { authRequired: false },
  });

  const handleUpdate = () => {
    if (ownerId && accountId) {
      setUpdated(true);
    }
  };

  const setEventListeners = () => {
    if (report) {
      const report = document.getElementsByClassName("red_flag_pid_comp_wrap")[index];
      const reportVerdict = report.getElementsByClassName("red_flag_verdict_txt")[0]?.innerText;
      const reportFields = report ? new Array(...report.getElementsByClassName("red_flag_item_row")) : [];
      const outOfWalletBtn = report ? report.getElementsByClassName("pid_link")[0] : undefined;

      if (reportFields.length > 0) {
        reportFields.forEach((div, i) => {
          let fieldName = div.getElementsByClassName("red_flag_item_label")[0]?.innerText;
          let value = null;
          let selectField = div.getElementsByClassName("red_flag_select")[0];
          let commentField = div.getElementsByClassName("red_flag_input")[0];

          if (selectField) {
            selectField.addEventListener("change", e => {
              const index = e?.target?.value;
              const options = e?.target?.options ? new Array(...e.target.options) : [];
              value = options[index]?.innerText?.trim() || "BLANK";
              let newChanges = { ...changes };
              newChanges[fieldName].value = value;
              setChanges({ ...newChanges });
            });
          }
          if (commentField) {
            commentField.addEventListener("change", e => {
              let newChanges = { ...changes };
              newChanges[fieldName].comment = e.target.value;
              setChanges({ ...newChanges });
            });
          }
        });
      }

      if (outOfWalletBtn) {
        outOfWalletBtn.addEventListener("click", () => {
          const entry = {
            change: { OUT_OF_WALLET: { value: "generated", comment: "generated" } },
            createdBy: userName,
            createdOn: new Date(),
          };
          triggerWebhook({ variables: { entityId: ownerId, eventType: "CREDIT_REPORT_CONSUMER_UPDATED", accountId } });
          updateLogs([entry]);
          handleUpdate();
        });
      }

      report.addEventListener("DOMSubtreeModified", () => {
        setUpdated(true);
      });
    }
  };

  const updateReport = () => {
    if (report) {
      const report = document.getElementsByClassName("red_flag_pid_comp_wrap")[index];
      const reportFields = report ? new Array(...report.getElementsByClassName("red_flag_item_row")) : [];

      if (reportFields.length > 0) {
        reportFields.forEach((div, i) => {
          let selectField = div.getElementsByClassName("red_flag_select")[0];
          let uploadBtn = div.getElementsByClassName("red_flag_upload")[0];
          let commentField = div.getElementsByClassName("red_flag_input")[0];

          if (selectField) {
            selectField.setAttribute("onchange", "null");
          }
          if (commentField) {
            commentField.setAttribute("onblur", `$(this).attr('data-val',$(this).val());`);
          }
          if (uploadBtn) {
            uploadBtn.style = "display:none;";
          }
        });
      }
    }
  };

  const updateLogs = newChanges => {
    let auditLog = [
      ...auditLogs,
      ...newChanges.map(change => ({ ...change, createdBy: userName, createdOn: new Date() })),
    ];

    setAuditLogs(auditLog);
    if (isIframe) {
      putAuditLog(auditLog);
    } else {
      upsertCreditReportConsumerAuditLog({
        variables: {
          creditReportConsumerId,
          auditLog,
        },
      });
    }
  };

  const handleClick = () => {
    const report = document.getElementsByClassName("red_flag_pid_comp_wrap")[index];
    const reportVerdict = report.getElementsByClassName("red_flag_verdict_txt")[0]?.innerText;
    const reportFields = report ? new Array(...report.getElementsByClassName("red_flag_item_row")) : [];

    if (reportFields.length > 0) {
      reportFields.forEach((div, i) => {
        let fieldName = div.getElementsByClassName("red_flag_item_label")[0]?.innerText;
        let uploadBtn = div.getElementsByClassName("red_flag_upload")[0];

        if (!_.isEmpty(changes[fieldName]?.value) && uploadBtn) {
          uploadBtn.setAttribute("onclick", `rf_override(1,${i + 1},0);rf_override(2,${i + 1},0);`);
          uploadBtn.onclick();
        }
      });
    }

    setChanges({
      ADDRESS: { value: "", comment: "" },
      SSN: { value: "", comment: "" },
      OFAC: { value: "", comment: "" },
      FRAUD: { value: "", comment: "" },
      DOB: { value: "", comment: "" },
    });

    console.log("CLICK BUTTON");
    triggerWebhook({ variables: { entityId: ownerId, eventType: "CREDIT_REPORT_CONSUMER_UPDATED", accountId } });
    const newChanges = Object.entries(changes)
      .map(ent => {
        if (ent[1].value) return { ...ent[1], field: ent[0] };
      })
      .filter(i => i);

    updateLogs(newChanges);

    setTimeout(() => {
      setEventListeners();
    }, 2000);
  };

  useEffect(() => {
    updateReport();
    setEventListeners();
  }, [report]);

  useEffect(() => {
    if (updated) {
      updateReport();
      setUpdated(false);
    }
  }, [updated]);

  return (
    <>
      {report && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 688,
            border: "1px solid #333333",
            borderRadius: 8,
            padding: 16,
            backgroundColor: "#eee",
          }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "left" }}>
            <p style={{ margin: 0, fontWeight: 500, fontSize: 12 }}>
              Report Pulled:{" "}
              {dateReportWasPulled.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>

          <div
            className={classes.wrapperStyles}
            dangerouslySetInnerHTML={{
              __html: report,
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8, alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 2 }}>
              <div style={{ display: "flex", gap: 8 }}>
                {showInfo && (
                  <p style={{ margin: 0, fontWeight: 500, fontSize: 11 }}>
                    By clicking this button, you are confirming you have reviewed and uploaded the required
                    documentation.
                  </p>
                )}
                <InfoIcon
                  style={{ fontSize: 20, color: "#3E83E6", cursor: "pointer" }}
                  onClick={() => setShowInfo(!showInfo)}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", gap: 8, alignItems: "center" }}>
                <Button
                  onClick={() => setOpen(true)}
                  color="primary"
                  style={{ fontSize: 12, textTransform: "none", textDecoration: "underline" }}>
                  Audit Log
                </Button>
                <Button
                  id={`confirm-button-${index}`}
                  color="primary"
                  style={{ color: "#fff", background: "#3E83E6", marginRight: 16 }}
                  onClick={() => handleClick()}>
                  Confirm Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-new-version"
        aria-describedby="alert-dialog-description-new-version"
        fullWidth={true}
        maxWidth="md">
        <DialogTitle>
          <h4>Audit Log</h4>
        </DialogTitle>
        <DialogContent>
          <Table
            color="primary"
            data={auditLogs}
            columns={[
              {
                Header: "Field",
                accessor: "field",
              },
              {
                Header: "Value",
                accessor: "value",
              },
              {
                Header: "Comment",
                accessor: "comment",
              },
              {
                Header: "Updated By",
                accessor: "createdBy",
              },
              {
                Header: "Updated On",
                accessor: "createdOn",
                Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
              },
            ]}
            pageSize={pageSizeValue}
            pageNumber={pageNumber}
            defaultPageSize={savedListSettings.pageSize}
            showPaginationTop={false}
            showPaginationBottom={true}
            onPageChange={changePage => {
              setPageNumber(changePage);
            }}
            onPageSizeChange={changePageSize => {
              setPageSizeValue(changePageSize);
              setPageNumber(0);
            }}
            className={classes.root + " -highlight"}
            NoDataComponent={() => null}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConsumerCreditReport;
