import { ActionTypes } from "global";
import { Notification } from "./types";
import { notificationsActions } from "./actions";

type NotificationActions = ActionTypes<typeof notificationsActions>;

const initialState: Notification[] = [];

export const notificationsReducer = (state = initialState, action: NotificationActions): Notification[] => {
  switch (action.type) {
    case "NOTIFICATION_ADD":
      return [...state, action.payload.notification];
    case "REMOVE_ADD":
      return state.filter(notification => notification.id !== action.payload.id);
    default:
      return state;
  }
};
