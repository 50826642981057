import React from "react";
import { Table } from "components/shared/Table";
import { VODocument } from "../../types";
import { createTableColumns } from "../constants";

interface Props {
  isLoading: boolean;
  tableData: VODocument[];
}

export const DocumentsTable = ({ isLoading, tableData }: Props) => {
  const columns = createTableColumns();

  return <Table<VODocument> tableData={tableData} loading={isLoading} columns={columns} />;
};
