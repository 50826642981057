import React, { useContext, useState } from "react";
import gql from "graphql-tag";
import { DropzoneDialog } from "mui-file-dropzone";
import { useMutation } from "@apollo/client";
import { DocumentContext } from "../contexts/DocumentContext";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { InvoiceQuoteDescriptionInputModal } from "./InvoiceQuoteDescriptionInputModal_V2";
import { logError } from "../utils/logger";
import makeStyles from "@mui/styles/makeStyles";

const UPLOAD_FILE = gql`
  mutation ($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;

interface Props {
  refetchDoc: any;
  handleChangeDoc: any;
  handleOpenInvoiceModal: any;
  handleCloseUploadInvoiceModal: any;
}

export const InvoiceQuoteUploadModal = ({
  refetchDoc,
  handleChangeDoc,
  handleOpenInvoiceModal,
  handleCloseUploadInvoiceModal,
}: Props) => {
  const classes = useStyles();

  const vo = useSelector((state: any) => state.vo);

  const [openUploadZone, setOpenUploadZone] = useState<boolean>(false);

  const [isInvoiceQuoteModalOpen, setIsInvoiceQuoteModalOpen] = useState<boolean>(false);
  const [fileData, setFileData] = useState<File[] | null>(null);

  const [fileInput, { loading }] = useMutation(UPLOAD_FILE);

  const documentContext = useContext(DocumentContext);

  const handleOpenUploadZone = () => {
    setOpenUploadZone(true);
  };

  const handleCloseUploadZone = () => {
    handleCloseUploadInvoiceModal();
    setOpenUploadZone(false);
  };

  const handleDropFile = (files: File[]) => {
    setFileData(files);
    handleCloseUploadZone();
    setIsInvoiceQuoteModalOpen(true);
  };

  const handleCloseDropzoneDialog = () => {
    handleCloseUploadZone();
    setIsInvoiceQuoteModalOpen(false);
  };

  const handleSaveFile = async (formData: { type: string; description: string }, file: File) => {
    try {
      const { data } = await fileInput({
        variables: {
          input: {
            vendorOpportunityId: vo.vendorOpportunityId,
            dynamicsVendorOpportunityId: vo.dynamicsVendorOpportunityId,
            source: "will_be_changed_by_server",
            docType: formData.type,
            invoiceQuoteNumber: formData.description,
            createdBy: "will_be_changed_by_server",
            docName: "will_be_changed_by_server",
            invoiceTotal: 0,
            taxAmount: 0,
            docDescription:
              formData.type === "invoice" ? `invoice#${formData.description}` : `quote#${formData.description}`,
          },
          file,
        },
      });

      documentContext.setLastDocument(data.upsertDocument);

      await refetchDoc();
      await handleChangeDoc();

      await handleOpenInvoiceModal();
    } catch (err) {
      logError(err);
    }
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        disabled={loading}
        variant="contained"
        onClick={handleOpenUploadZone}
        startIcon={<CloudUploadIcon />}>
        Upload Invoice/Quote
      </Button>

      <DropzoneDialog
        fileObjects={[]}
        filesLimit={1}
        showPreviews={false}
        open={openUploadZone}
        maxFileSize={25000000}
        onDrop={handleDropFile}
        onSave={handleDropFile}
        showFileNamesInPreview={false}
        dialogTitle="Upload Invoice/Quote"
        onClose={handleCloseDropzoneDialog}
        classes={{ root: classes.hideSubmitButton }}
        dropzoneText="Drag or Click Here To Upload File"
      />

      <InvoiceQuoteDescriptionInputModal
        isUploaded={loading}
        fileData={fileData?.[0]}
        onUpload={handleSaveFile}
        isOpen={isInvoiceQuoteModalOpen}
        onClose={() => {
          setFileData(null);
          setIsInvoiceQuoteModalOpen(false);
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  hideSubmitButton: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
  },
});
