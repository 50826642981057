import React, { useContext, useMemo } from "react";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CommonDataContext } from "contexts/CommonDataContext";
import { ProposalProductsFiltersAftermarketPartner } from "./ProposalProductsFiltersAftermarketPartner";
import { ProposalProductsFiltersDealer } from "./ProposalProductsFiltersDealer";

interface Props {
  products: ProposalProduct[];
  onFiltered: (filteredProducts: ProposalProduct[]) => void;
}

export const ProposalProductsFilters = (props: Props) => {
  const { isAftermarketPartner } = useContext(CommonDataContext);

  const ProposalProductsFilters = useMemo(() => {
    if (isAftermarketPartner) return ProposalProductsFiltersAftermarketPartner;
    return ProposalProductsFiltersDealer;
  }, [isAftermarketPartner]);

  return <ProposalProductsFilters {...props} />;
};
