import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableSelect from "../../../../components/Table/TableSelect";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import makeStyles from "@mui/styles/makeStyles";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { AddOutlined, ArrowBackIosOutlined, EditOutlined, VpnKey } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import DragIndicator from "assets/img/drag-indicator.svg";
import PersonalLinkAndQRCode from "../../../../components/PersonalLinkAndQRCode";
import CustomButton from "../../../../components/CustomButtons/Button";
import DealerCredentialsModal from "./DealerCredentialsModal/DealerCredentialsModal";
import { GET_CS_LIST, OPTIONS, STATUS_TYPES, getStatus } from "../../services/CreditSubmissions";
import { lenderTypeMap } from "../../../../pages/VendorOpportunity/LenderSubmissionMappingModals/constants";
import { REGISTRATION_STATUSES, formatCurrency } from "utils";
import DealerContactModal from "./DealerContactModal";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import moment from "moment";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { defaultColorSchema, getHeadersFromAuth } from "../../../../utils";
import ColorSchemaPicker from "../../../../components/ColorSchemaPicker";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MailIcon from "@mui/icons-material/Mail";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { theme } from "../../../../theme";

const config = require("../../../../config.js");

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  formControl: {
    minWidth: 250,
  },
  contactsTableButtons: {
    margin: "0 8px",
  },
  userStatusActive: {
    margin: "auto",
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  userStatusBlocked: {
    margin: "auto",
    padding: "4px 8px",
    color: "#C62828",
    backgroundColor: "#FFCDD2",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  userStatusStaged: {
    margin: "auto",
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  userStatusInvited: {
    margin: "auto",
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
});

const useStyles = makeStyles(styles);

const GET_LENDER_DEALER_PROFILE = gql`
  query getLenderDealerProfileByLenderDealerDynamicsAccountIds(
    $lenderDynamicsAccountId: ID!
    $dealerDynamicsAccountId: ID!
  ) {
    lenderDealerProfileByLenderDealerDynamicsAccountIds(
      lenderDynamicsAccountId: $lenderDynamicsAccountId
      dealerDynamicsAccountId: $dealerDynamicsAccountId
    ) {
      lenderProfileId
      lenderProfileDynamicsId
      ocaTemplate
      ocaLenderUserProfileId
      ocaDealerUserProfileId
      ocaBranding
      ocaAutoSubmission
      ocaPrescreenRules
    }
  }
`;

const GET_ACCOUNT = gql`
  query getDealerAccount($dynamicsId: ID) {
    account(dynamicsId: $dynamicsId) {
      name
      dynamicsAccountId
      contacts {
        id
        fullName
        firstName
        lastName
        email
        phoneNumber
        vendorContactRole
        title
        status
        dynamicsContactId
      }
      id
      vendorProfileId
      locations {
        locationName
        locationId
      }
    }
  }
`;

const GET_VENDOR_PROFILE = gql`
  query getVendorProfile($id: ID) {
    vendorProfile(id: $id) {
      styles
      logo
    }
  }
`;

const GET_LENDER_PROFILE_BY_DYNAMICS_ID = gql`
  query getLenderProfileByDynamicsId($lenderProfileDynamicsId: ID) {
    getLenderProfileByDynamicsId(lenderProfileDynamicsId: $lenderProfileDynamicsId) {
      channel
      lenderProfileUsers {
        userProfile {
          firstName
          lastName
          email
          id
        }
      }
      id
    }
  }
`;

const UPDATE_LENDER_PROFILE_GQL = gql`
  mutation ($entityId: String!, $fieldsObj: UpdateLenderProfileInput!) {
    updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      ocaTemplate
      ocaLenderUserProfileId
      ocaDealerUserProfileId
      ocaBranding
      ocaAutoSubmission
      ocaPrescreenRules
    }
  }
`;

const M_UPDATE_VP = gql`
  mutation ($entityId: String!, $file: Upload, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, file: $file, fieldsObj: $fieldsObj) {
      id
      logo
      styles
    }
  }
`;

const INVITE_DEALER_FROM_LENDER = gql`
  mutation ($input: InviteDealerFromLenderInput!) {
    inviteDealerFromLender(input: $input)
  }
`;

const UPDATE_COMPANY_PROFILE = `
    mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput!, $file: Upload) {
      updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj, file: $file) {
        id
        logo
      }
    }
  `;

const vendorContactRoleHumanReadable = {
  sales_rep: "Sales Representative",
  sales_mgr: "Sales Manager",
  admin: "Administrator",
  executive: "Executive",
  credit_mgr: "Credit Manager",
  support_person: "Support Person",
};

const statusTitles = {
  Active: "User has registered successfully and is connected to the account.",
  Blocked: "User has been removed from the account.",
  Staged: "User has been added to the account but has yet to receive a registration invite.",
  Invited: "User has been sent a registration invite but has yet to connect to the account.",
};

const DealerDetails = ({ match }) => {
  const classes = useStyles();
  const dealerDynamicsAccountId = match.params.dealerId;
  const relatedUsers = useSelector(state => state.relatedUsers);
  const account = useSelector(state => state.account);
  const lenderProfile = useSelector(state => state.lenderProfile);
  const vendorProfile = useSelector(state => state.vp);
  const decisionRef = useRef({ prevdecision: undefined, currentSelect: "" });
  const stageRef = useRef({ prevStage: undefined, currentSelect: "" });
  const [dealer, setDealer] = useState(null);
  const [dealerCredentialsModalIsOpen, setDealerCredentialsModalIsOpen] = useState(false);
  const [openInviteAllContactsConfirmationModal, setOpenInviteAllContactsConfirmationModal] = useState(false);
  const [columnsFilters, setColumnsFilters] = useState([
    {
      header: "Status",
      value: [""],
    },
    {
      header: "Display",
      value: [""],
    },
  ]);
  const [dealerAccount, setDealerAccount] = useState();
  const [dealerSalesReps, setDealerSalesReps] = useState();
  const [lenderDealerProfile, setLenderDealerProfile] = useState();
  const [ocaLink, setOcaLink] = useState();
  const [lenderProfileUpdateInProgress, setLenderProfileUpdateInProgress] = useState(false);
  const [savedListSettings, setSavedListSettings] = useState({
    filter: [],
    sorting: [],
    page: 0,
    pageSize: 10,
  });
  const [contact, setContact] = useState();
  const [editContact, setEditContact] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [vpStyles, setVPStyles] = useState({});
  const [logo, setLogo] = useState("");
  const [logoFile, setLogoFile] = useState("");
  const [onLoad, setOnLoad] = useState(false);
  const [submittingLogo, setSubmittingLogo] = useState(false);
  const [dealerVP, setDealerVP] = useState(null);
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false);
  const [showSnackbarError, setShowSnackbarError] = useState(false);
  const [isSendInviteConfirmationModalOpen, setIsSendInviteConfirmationModalOpen] = useState(false);

  const hasLenderOcaAccess = _.get(vendorProfile, "hasLenderOcaAccess");

  const { loading: isLoadingCSList, data: csListData, refetchCSList } = useQuery(GET_CS_LIST);
  const [getDealerVP, { loading: vpLoading }] = useLazyQuery(GET_VENDOR_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.vendorProfile) setDealerVP(data.vendorProfile);
    },
  });
  const [updateVP, { data: updateVPData, error, loading }] = useMutation(M_UPDATE_VP, {
    context: { authRequired: true },
  });
  const {
    data: dealerAccountData,
    loading: isDealerAccountDataLoading,
    refetch: refetchAccount,
  } = useQuery(GET_ACCOUNT, {
    variables: {
      dynamicsId: dealerDynamicsAccountId,
    },
    onCompleted: data => {
      if (data?.account?.vendorProfileId) {
        getDealerVP({ variables: { id: data.account.vendorProfileId } });
      }
    },
  });
  const { data: lenderDealerData, loading: isLenderDealerDataLoading } = useQuery(GET_LENDER_DEALER_PROFILE, {
    variables: {
      lenderDynamicsAccountId: account.dynamicsAccountId,
      dealerDynamicsAccountId: dealerDynamicsAccountId,
    },
    fetchPolicy: "no-cache",
  });

  const [getLenderProfileUsersData, { loading: isLenderProfileUsersDataLoading, data: lenderProfileUsersData }] =
    useLazyQuery(GET_LENDER_PROFILE_BY_DYNAMICS_ID, {
      fetchPolicy: "no-cache",
    });

  const [updateLenderProfile] = useMutation(UPDATE_LENDER_PROFILE_GQL, {
    context: { authRequired: true },
    onCompleted() {
      setLenderProfileUpdateInProgress(false);
    },
  });

  const [inviteDealerFromLender, { loading: isInviteDealerFromLenderLoading }] = useMutation(INVITE_DEALER_FROM_LENDER);

  useEffect(() => {
    if (dealerVP) {
      const mergedStyles = _.merge({}, defaultColorSchema, dealerVP.styles);
      setVPStyles(mergedStyles);
      setLogo(dealerVP.logo);
    }
  }, [dealerVP]);

  function createFiltersColumns(sel, header) {
    const filter = ({ filter, onChange }) => {
      return sel.length > 0 ? (
        <Select
          multiple
          className={classes.filterSelect}
          renderValue={() => {
            return columnsFilters.find(col => col.header === header).value[0] !== ""
              ? columnsFilters
                  .find(col => col.header === header)
                  .value.join(", ")
                  .replace(
                    STATUS_TYPES.ARCHIVED_DUPLICATE_201,
                    getStatus(STATUS_TYPES.ARCHIVED_DUPLICATE_201).menuLabel
                  )
                  .replace(STATUS_TYPES.ACTIVE_100, getStatus(STATUS_TYPES.ACTIVE_100).menuLabel)
                  .replace(STATUS_TYPES.ARCHIVED_TESTING_200, getStatus(STATUS_TYPES.ARCHIVED_TESTING_200).menuLabel)
                  .replace(STATUS_TYPES.ARCHIVED_OTHER_202, getStatus(STATUS_TYPES.ARCHIVED_OTHER_202).menuLabel)
              : "Show All";
          }}
          onChange={e => {
            let value =
              e.target.value.length > 1 && e.target.value[0] === ""
                ? e.target.value.filter(val => val !== "")
                : e.target.value;
            if (value.includes("")) value = [""];
            onChange(value);
            setColumnsFilters(prevArray =>
              prevArray.map(col =>
                col.header === header
                  ? {
                      ...col,
                      value: value,
                    }
                  : col
              )
            );
          }}
          value={_.get(
            columnsFilters.find(col => col.header === header),
            "value",
            []
          )}
          style={{ width: "100%" }}
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}>
          <MenuItem value="">
            <Checkbox
              checked={
                _.get(
                  columnsFilters.find(col => col.header === header),
                  "value",
                  null
                ) &&
                _.get(
                  columnsFilters.find(col => col.header === header),
                  "value"
                ).includes("")
              }
              style={{ color: theme.palette.primary.main }}
            />
            <ListItemText primary="Show All" />
          </MenuItem>
          {sel.map((item, index) => {
            return (
              <MenuItem key={"stage-" + index} value={item.value} name={item.title}>
                <Checkbox
                  checked={
                    _.get(
                      columnsFilters.find(col => col.header === header),
                      "value",
                      null
                    ) &&
                    _.get(
                      columnsFilters.find(col => col.header === header),
                      "value"
                    ).includes(item.value)
                  }
                  style={{ color: theme.palette.primary.main }}
                />
                <ListItemText primary={item.title} />
              </MenuItem>
            );
          })}
        </Select>
      ) : null;
    };
    return filter;
  }

  useEffect(() => {
    if (_.isEmpty(lenderProfile)) {
      return;
    }
    getLenderProfileUsersData({ variables: { lenderProfileDynamicsId: lenderProfile.id } });
  }, [lenderProfile]);

  useEffect(() => {
    if (_.isEmpty(csListData) || _.isEmpty(dealerAccount)) {
      return;
    }

    const csList = csListData.creditSubmissions;
    let dealers = Object.entries(_.groupBy(csList, cs => cs.dealerName)).map(e => ({
      dealerName: e[0],
      dealerDynamicsAccountId: e[1][0].dealer,
      submissions: e[1],
    }));
    dealers = [
      ...dealers.map(dealer => {
        let contacts = _.groupBy(dealer.submissions, sub => sub.contactName);
        contacts = Object.entries(contacts)
          .map(contact => {
            const relatedUser = relatedUsers.find(user => user.dynamicsContactId === contact[1][0].contact);
            let decisions = _.groupBy(contact[1], sub => sub.decision);
            decisions = Object.entries(decisions).map(e => ({
              decision: e[0],
              count: e[1].length,
            }));
            return {
              fullName: contact[0],
              decisions,
              ...relatedUser,
            };
          })
          .filter(contact => contact.name !== "null");
        const decisions = _.groupBy(dealer.submissions, sub => sub.decision);
        let decisionsCount = Object.entries(decisions).map(e => ({
          decision: e[0],
          count: e[1].length,
        }));

        return {
          dealerName: dealer.dealerName,
          dealerDynamicsAccountId: dealer.dealerDynamicsAccountId,
          contactsCount: Object.keys(contacts).filter(e => e !== "null").length,
          contacts,
          decisions,
          decisionsCount,
          submissions: dealer.submissions,
        };
      }),
    ];

    let curDealer = dealers.find(dealer => dealer.dealerDynamicsAccountId === dealerDynamicsAccountId);
    curDealer = dealerAccountData
      ? {
          ...curDealer,
          contacts: dealerAccountData.account.contacts
            .toSorted((a, b) => a.fullName.localeCompare(b.fullName))
            .filter(c => c.status !== "blocked"),
        }
      : curDealer;
    setDealer(
      filterBySelectFields(curDealer) || {
        dealerName: dealerAccount.name,
        dealerDynamicsAccountId: dealerAccount.dynamicsAccountId,
      }
    );
  }, [csListData, relatedUsers, dealerAccount, savedListSettings]);

  useEffect(() => {
    if (_.isEmpty(lenderDealerData)) {
      return;
    }
    const lenderDealerProfileToSet = lenderDealerData.lenderDealerProfileByLenderDealerDynamicsAccountIds;
    setLenderDealerProfile(lenderDealerProfileToSet);
    setOcaLink(
      `${config.REACT_APP_OCA_BASE_URL}/credit-app?lenderProfileId=${lenderDealerProfileToSet.lenderProfileId}`
    );
  }, [lenderDealerData]);

  useEffect(() => {
    if (_.isEmpty(dealerAccountData)) {
      return;
    }
    setDealerAccount(dealerAccountData.account);
    const dealerSalesReps = _.reject(dealerAccountData.account.contacts, {
      status: REGISTRATION_STATUSES.blocked,
    });
    if (dealer)
      setDealer({
        ...dealer,
        contacts: dealerAccountData.account.contacts
          .sort((a, b) => a.fullName.localeCompare(b.fullName))
          .filter(c => c.status !== "blocked"),
      });
    setDealerSalesReps(dealerSalesReps);
  }, [dealerAccountData]);

  useEffect(() => {
    if (logoFile && typeof logoFile !== "string") {
      const reader = new FileReader();
      reader.onload = e => {
        const avatar = document.getElementById("avatar");
        avatar.src = e.target.result;
        setLogoFile(e.target.result);
      };
      reader.readAsDataURL(logoFile);
    }
  }, [logoFile]);

  const submitLogo = () => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: UPDATE_COMPANY_PROFILE,
        variables: {
          entityId: dealerAccount.vendorProfileId,
          fieldsObj: {
            logo: dealerVP.logo,
          },
        },
      })
    );
    if (logo) {
      formData.append(
        "map",
        JSON.stringify({
          0: ["variables.file"],
        })
      );
      formData.append("0", logo);
    }

    return new Promise(async (resolve, reject) => {
      const authHeaders = await getHeadersFromAuth(Auth);
      fetch(config.DCR_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: {
          ...authHeaders,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(response => {
          response = response || {};
          if (response.errors && response.errors.length) {
            const errors = response.errors.map(e => e.message).join("; ");
            console.error("Error saving profile:", errors);
            resolve();
          } else {
            if (response?.data?.updateVendorProfile) {
              setLogo(response.data.updateVendorProfile.logo);
            }
            resolve();
          }
        })
        .catch(err => {
          console.error("Error saving profile:", err);
          resolve();
        });
    });
  };

  const handleSaveLenderProfile = () => {
    try {
      setLenderProfileUpdateInProgress(true);

      if (vpStyles) {
        updateVP({
          variables: {
            entityId: dealerAccount.vendorProfileId,
            fieldsObj: {
              styles: vpStyles,
            },
          },
        });
      }

      updateLenderProfile({
        variables: {
          fieldsObj: {
            ocaTemplate: lenderDealerProfile.ocaTemplate,
            ocaLenderUserProfileId: lenderDealerProfile.ocaLenderUserProfileId,
            ocaDealerUserProfileId: lenderDealerProfile.ocaDealerUserProfileId,
            ocaBranding: lenderDealerProfile.ocaBranding,
            ocaAutoSubmission: lenderDealerProfile.ocaAutoSubmission,
            ocaPrescreenRules: lenderDealerProfile.ocaPrescreenRules,
          },
          entityId: lenderDealerProfile.lenderProfileDynamicsId,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditClick = (e, contact) => {
    e.stopPropagation(); // to no redirect to another route
    setContact(contact);
    setEditContact(true);
    setOpenModal(true);
  };

  function uploadFileChanged(event) {
    setLogoFile(event.target.files[0], "file");
    setOnLoad(true);
    setLogo(event.target.files[0]);
  }

  function uploadButtonHandler() {
    const file = document.getElementById("uploadLogo");
    file.click();
  }

  const filterBySelectFields = originalData => {
    const { currentSelect: currentDecisionSelect } = decisionRef.current;
    const { currentSelect: currentStageSelect } = stageRef.current;
    let newData = { ...originalData };

    if (currentDecisionSelect?.length !== 0) {
      newData.submissions = dealer.submissions.filter(el => el.decision === currentDecisionSelect);
    }

    if (currentStageSelect?.length !== 0) {
      newData.submissions = dealer.submissions.filter(el => el.stage === currentStageSelect);
    }

    return newData;
  };

  const onSendInviteConfiramtionModalClose = async (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    if (event.result === "ok") {
      try {
        const result = await inviteDealerFromLender({
          variables: {
            input: {
              invitationEmail: contact.email,
              lenderProfileId: lenderDealerProfile.lenderProfileId,
            },
          },
        });
        result?.data?.inviteDealerFromLender ? setShowSnackbarSuccess(true) : setShowSnackbarError(true);
      } catch (e) {
        setShowSnackbarError(true);
      }
    }
    setIsSendInviteConfirmationModalOpen(false);
    setContact(null);
  };

  const onInviteAllContactsConfiramtionModalClose = async (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    if (event.result === "ok") {
      // we need to send invitation for all statuses, even for active
      const dealersToSendInvite = _.filter(dealer?.contacts, c => !!c.email);
      const sendInvitesPromises = _.map(dealersToSendInvite, contact => {
        return inviteDealerFromLender({
          variables: {
            input: {
              invitationEmail: contact.email,
              lenderProfileId: lenderDealerProfile.lenderProfileId,
            },
          },
        });
      });

      return Promise.all(sendInvitesPromises)
        .then(refetchAccount)
        .then(() => {
          setShowSnackbarSuccess(true);
        })
        .catch(() => {
          setShowSnackbarError(true);
        })
        .finally(() => {
          setOpenInviteAllContactsConfirmationModal(false);
        });
    }
    setOpenInviteAllContactsConfirmationModal(false);
  };

  const handleSendInvitation = async (e, contact) => {
    e.stopPropagation(); // to no redirect to another route
    setContact(contact);
    setIsSendInviteConfirmationModalOpen(true);
  };

  let contactsColumns = [
    {
      Header: "Name",
      accessor: "fullName",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Status",
      resizable: false,
      accessor: "status",
      width: 136,
      Cell: props => {
        let status = props.value.charAt(0).toUpperCase() + props.value.slice(1);
        if (status === "Approved") status = "Invited";
        if (status === "Pending_approval") status = "Staged";
        return (
          <div title={statusTitles[status]} className={classes[`userStatus${status}`]}>
            {status}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
    },
  ];

  if (hasLenderOcaAccess)
    contactsColumns.push({
      Header: "Actions",
      id: "actions",
      width: 100,
      Cell: props => (
        <Grid container spacing={3}>
          <Grid item>
            <Tooltip title="Edit" aria-label="Edit" placement="top">
              <EditOutlined onClick={e => handleEditClick(e, props?.original)} color="primary" />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Send Invitation" aria-label="Send Invitation" placement="top">
              <MailIcon onClick={e => handleSendInvitation(e, props?.original)} color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
      ),
    });

  if (isLoadingCSList || isLenderDealerDataLoading) {
    return <LinearProgress size={16} />;
  }

  return (
    <>
      <Grid container>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "16px 0 0" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <Button className="formBtn" color="primary" onClick={() => (window.location.href = "/dealers")}>
                <ArrowBackIosOutlined style={{ fontSize: 16, marginRight: 4 }} />
                Back
              </Button>
              <h3 style={{ margin: "16px 0" }}>
                <b>{dealer?.dealerName}</b>
              </h3>
            </div>
          </div>
        </GridItem>

        {account.isLenderIntegrationSettingsAdmin && (
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "16px 0 0" }}>
              <div style={{ display: "flex", alignItems: "flex-end", gap: 16 }}>
                <CustomButton
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setDealerCredentialsModalIsOpen(true);
                  }}>
                  <VpnKey />
                  Update Dealer Credentials
                </CustomButton>
              </div>
            </div>
            {lenderProfileUsersData?.getLenderProfileByDynamicsId?.channel && dealerAccountData?.account?.contacts && (
              <DealerCredentialsModal
                integrationName={lenderTypeMap.get(lenderProfileUsersData?.getLenderProfileByDynamicsId?.channel)}
                isOpen={dealerCredentialsModalIsOpen}
                handleClose={() => setDealerCredentialsModalIsOpen(false)}
                dealerLocations={dealerAccountData?.account?.locations ? dealerAccountData.account.locations : []}
                dealerContacts={dealerAccountData?.account?.contacts ? dealerAccountData.account.contacts : []}
                dealerAccountId={dealerAccountData.account.id}
              />
            )}
          </GridItem>
        )}
      </Grid>
      <Grid container>
        {lenderDealerProfile && hasLenderOcaAccess && (
          <GridItem xs={12}>
            <Tile className={classes.cardStyles} classBody={classes.cardBody}>
              <Grid container spacing={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid container alignContent="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <h4 style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                        Dealer Logo:
                        {vpLoading && <CircularProgress size={20} style={{ marginLeft: 8 }} />}
                      </h4>
                      <input
                        accept="image/*"
                        id="uploadLogo"
                        type="file"
                        style={{ display: "none" }}
                        onChange={uploadFileChanged}
                      />
                      <Grid container alignItems="flex-end">
                        <Grid item xs={8}>
                          <img
                            id="avatar"
                            alt="Company Logo"
                            style={
                              logo
                                ? {
                                    maxHeight: "200px",
                                    maxWidth: "100%",
                                  }
                                : {
                                    height: "250px",
                                    width: "450px",
                                  }
                            }
                            src={
                              !logo
                                ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                : typeof logo === "string"
                                  ? logo.indexOf("data:image") === -1
                                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}`
                                    : logo
                                  : logo
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>
                            {!onLoad ? (
                              <Button
                                className={classes.companyProfileButton}
                                color="primary"
                                variant="outlined"
                                onClick={uploadButtonHandler}>
                                Upload&nbsp;
                                <PhotoCameraIcon />
                              </Button>
                            ) : (
                              <Grid container justifyContent="flex-end">
                                <Button
                                  className={classes.companyProfileButton}
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                  onClick={() => {
                                    setOnLoad(false);
                                    submitLogo();
                                  }}>
                                  {submittingLogo ? "Submitting" : "Save"}
                                </Button>
                                <Button
                                  className={classes.companyProfileCancelButton}
                                  variant="contained"
                                  onClick={() => {
                                    setOnLoad(false);
                                    setLogo(dealerVP.logo);
                                    setLogoFile("");
                                  }}>
                                  Cancel
                                </Button>
                              </Grid>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <h4 style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                        OCA Color Schema:
                        {vpLoading && <CircularProgress size={20} style={{ marginLeft: 8 }} />}
                      </h4>
                      <ColorSchemaPicker colorSchema={vpStyles} setColorSchema={setVPStyles} />
                    </Grid>
                    <Grid item xs={12}>
                      <h4 style={{ fontWeight: "bold", marginBottom: 0 }}>OCA Settings:</h4>
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="oca-template-select-label">OCA Template</InputLabel>
                          <Select
                            labelId="oca-template-select-label"
                            id="oca-template-select"
                            value={lenderDealerProfile.ocaTemplate || ""}
                            onChange={e =>
                              setLenderDealerProfile({ ...lenderDealerProfile, ocaTemplate: e.target.value })
                            }>
                            <MenuItem value={"LENDER"}>Lender</MenuItem>
                            <MenuItem value={"DEALER"}>Dealer</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="branding-select-label">Branding</InputLabel>
                          <Select
                            labelId="branding-select-label"
                            id="branding-select"
                            value={lenderDealerProfile.ocaBranding || ""}
                            onChange={e =>
                              setLenderDealerProfile({ ...lenderDealerProfile, ocaBranding: e.target.value })
                            }>
                            <MenuItem value={"LENDER"}>Lender</MenuItem>
                            <MenuItem value={"DEALER"}>Dealer</MenuItem>
                            <MenuItem value={"CO_BRANDED"}>Co-Branded</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="lender-contact-select-label">Lender Contact</InputLabel>
                          <Select
                            labelId="lender-contact-select-label"
                            id="lender-contact-select"
                            value={lenderDealerProfile.ocaLenderUserProfileId || ""}
                            onChange={e =>
                              setLenderDealerProfile({ ...lenderDealerProfile, ocaLenderUserProfileId: e.target.value })
                            }>
                            {account.contacts.map(contact => (
                              <MenuItem key={contact.id} value={contact.id}>
                                {contact.fullName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="dealer-contact-select-label">Dealer Contact</InputLabel>
                          <Select
                            labelId="dealer-contact-select-label"
                            id="dealer-contact-select"
                            value={lenderDealerProfile.ocaDealerUserProfileId || ""}
                            onChange={e => {
                              setLenderDealerProfile({
                                ...lenderDealerProfile,
                                ocaDealerUserProfileId: e.target.value,
                              });
                            }}>
                            {dealerSalesReps?.map(contact => (
                              <MenuItem key={contact.id} value={contact.id}>
                                {contact.fullName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="auto-submission-select-label">Auto-Submission to Lender</InputLabel>
                          <Select
                            labelId="auto-submission-select-label"
                            id="auto-submission-select"
                            value={lenderDealerProfile.ocaAutoSubmission || ""}
                            onChange={e =>
                              setLenderDealerProfile({ ...lenderDealerProfile, ocaAutoSubmission: e.target.value })
                            }>
                            <MenuItem value={"YES"}>Yes</MenuItem>
                            <MenuItem value={"NO"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            label="Prescreen rules"
                            value={lenderDealerProfile.ocaPrescreenRules || ""}
                            onChange={e =>
                              setLenderDealerProfile({ ...lenderDealerProfile, ocaPrescreenRules: e.target.value })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                      <FormControl className={classes.formControl}>
                        <CustomButton
                          color="primary"
                          size="sm"
                          onClick={handleSaveLenderProfile}
                          disabled={lenderProfileUpdateInProgress}>
                          Save
                        </CustomButton>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <PersonalLinkAndQRCode personalLink={ocaLink} linkHeader="Lender Dealer Credit Application Link" />
              </Grid>
            </Tile>
          </GridItem>
        )}

        <GridItem xs={12}>
          <Tile>
            <Grid container>
              {_.some(dealer?.decisionsCount) ? (
                dealer.decisionsCount.map(decision => (
                  <GridItem xs={4} sm={4} md={4} lg={4}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <h3>
                        <b>{decision.count}</b>
                      </h3>
                      <p>
                        <b>{decision.decision}</b>
                      </p>
                    </div>
                  </GridItem>
                ))
              ) : (
                <h2>No Submissions yet</h2>
              )}
            </Grid>
          </Tile>
        </GridItem>
        <GridItem xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", margin: "16px 0" }}>
            <h3>
              <b>Contacts</b>
            </h3>
          </div>
        </GridItem>

        <GridItem xs={12}>
          <Tile>
            {hasLenderOcaAccess && (
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 24 }}>
                <CustomButton
                  className={classes.contactsTableButtons}
                  color="primary"
                  onClick={() => {
                    setOpenInviteAllContactsConfirmationModal(true);
                  }}
                  disabled={isInviteDealerFromLenderLoading}>
                  <MailOutlineIcon style={{ marginRight: 6 }} />
                  Invite All Contacts
                </CustomButton>
                <CustomButton
                  className={classes.contactsTableButtons}
                  color="primary"
                  onClick={() => setOpenModal(true)}>
                  <AddOutlined style={{ fontSize: 16, marginRight: 4 }} />
                  Add Contact
                </CustomButton>
              </div>
            )}
            <Table
              color="primary"
              data={dealer?.contacts || []}
              columns={contactsColumns}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              customCellClasses={[classes.right]}
              customClassesForCells={[5]}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    cursor: hasLenderOcaAccess && "pointer",
                  },
                  onClick: e => {
                    e.preventDefault();

                    if (hasLenderOcaAccess)
                      window.location.href = `/profile/${rowInfo.original.dynamicsContactId}?lpId=${lenderDealerProfile.lenderProfileId}`;
                  },
                };
              }}
            />
          </Tile>
        </GridItem>
        <GridItem xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", margin: "16px 0" }}>
            <h3>
              <b>Submissions</b>
            </h3>
          </div>
        </GridItem>
        <Grid item xs={12}>
          <Tile>
            <TableHeader
              filterComps={
                <>
                  <TableSelect
                    setPageNumber={changePage => {
                      setSavedListSettings({ ...savedListSettings, page: changePage });
                    }}
                    setSavedListSettings={setSavedListSettings}
                    selectRef={decisionRef}
                    savedListSettings={savedListSettings}
                    id="decision"
                    label="Decision"
                    options={OPTIONS.decision.map(item => ({ value: item, text: item }))}
                    width={200}
                  />
                  <TableSelect
                    setPageNumber={changePage => {
                      setSavedListSettings({ ...savedListSettings, page: changePage });
                    }}
                    setSavedListSettings={setSavedListSettings}
                    selectRef={stageRef}
                    savedListSettings={savedListSettings}
                    id="stage"
                    label="Stage"
                    options={OPTIONS.stage.map(item => ({ value: item, text: item }))}
                    width={200}
                  />
                </>
              }
            />
            <Table
              color="primary"
              data={dealer?.submissions}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: { cursor: "pointer" },
                onClick: e => {
                  e.preventDefault();
                  window.location.href = `/cs/${rowInfo.original.creditSubId}`;
                },
              })}
              columns={[
                {
                  Header: "Date",
                  accessor: "submissionDate",
                  Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
                  filterable: false,
                  sortMethod: (a, b) => {
                    const dateA = new Date(a).getTime();
                    const dateB = new Date(b).getTime();

                    return dateA - dateB;
                  },
                },
                { Header: "Source", accessor: "dealerName" },
                { Header: "Source Contact", accessor: "contactName" },
                { Header: "Applicant", accessor: "applicantName" },
                {
                  Header: "Requested Amount",
                  accessor: "amountRequested",
                  Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
                },
                {
                  Header: "Approval Amount",
                  accessor: "approvedAmount",
                  Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
                },
                {
                  Header: "Decision",
                  accessor: "decision",
                },
                {
                  Header: "Stage",
                  accessor: "stage",
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              customCellClasses={[classes.right]}
              customClassesForCells={[5]}
              className="-striped -highlight"
            />
          </Tile>
        </Grid>
      </Grid>
      <DealerContactModal
        contact={contact}
        open={openModal}
        create={!editContact}
        disableEdit={dealer?.contacts?.filter(c => c.status === "active")?.length > 0}
        accountId={dealerAccount?.id}
        dynamicsAccountId={dealerAccount?.dynamicsAccountId}
        lenderProfileId={lenderDealerProfile?.lenderProfileId}
        handleClose={() => {
          setOpenModal(false);
          setContact(null);
          setEditContact(false);
        }}
        refetchContacts={() => refetchAccount()}
      />
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to send invite to ${contact?.fullName}?`}
        loading={isInviteDealerFromLenderLoading}
        open={isSendInviteConfirmationModalOpen}
        okButtonText="Yes"
        cancelButtonText="No"
        onClose={onSendInviteConfiramtionModalClose}
      />
      <ConfirmationDialog
        title="Send Invite to All Contacts?"
        message="Are you sure you want to invite all contacts?"
        loading={isInviteDealerFromLenderLoading}
        open={openInviteAllContactsConfirmationModal}
        okButtonText="Yes"
        cancelButtonText="No"
        onClose={onInviteAllContactsConfiramtionModalClose}
      />
      <Snackbar
        open={showSnackbarSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSnackbarSuccess(state => !state)}>
        <Alert severity="success">
          <AlertTitle>Success!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar open={showSnackbarError} autoHideDuration={3000} onClose={() => setShowSnackbarError(state => !state)}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
};

export default DealerDetails;
