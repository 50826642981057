import { cardTitle, container, partnerContainer, whiteColor } from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "100px",
    },
  },
  partnerContainer: {
    ...partnerContainer,
    zIndex: "4",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    fontSize: "26px",
    color: "#000",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#000",
    opacity: "0.7",
  },
  loginPassword: {
    fontSize: "26px !important",
    color: "rgba(0, 0, 0, 0.3)",
    "& ~ a": {
      top: "80px",
    },
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginTop: "30px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  loginOptionsSeparator: {
    background: "#fff",
    color: "#818181",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    padding: "0 10px",
  },
  loginOptionsSeparatorText: {
    width: "460px",
    textAlign: "center",
    borderBottom: "2px solid rgba(62,131,230, 0.12)",
    lineHeight: "0.1em",
    fontSize: "12px",
    marginTop: "17px",
    margin: "20px 0 10px",
  },
  cardContainer: {
    width: "500px",
    height: "408px",
    borderRadius: "7px",
  },
  loginButton: {
    width: "220px !important",
    height: "40px !important",
    color: whiteColor,
    fontWeight: "700",
    fontSize: "16px",
    borderRadius: "7px",
    backgroundColor: "#3E83E6",
    "&:hover,&:focus": {
      backgroundColor: "#3E83E6",
    },
  },
  loginInput: {
    position: "relative",
    zIndex: "5",
    backgroundColor: "rgba(62,131,230, 0.12)",
    borderRadius: "7px",
    padding: "0 10px",
    border: "none",
    height: "40px",
    color: "#818181 !important",
    "&,&:after,&:before,&:hover,&:focus": {
      border: "none",
    },
    "&:hover": {
      "&,&:after,&:before": {
        border: "none !important",
      },
    },
    "& input ,input::placeholder": {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px",
      fontWeight: "500",
      color: "#818181 !important",
    },
  },
  loginLabel: {
    color: "#000 !important",
    transform: "translate(0, 24px)",
    fontSize: "12px",
    fontFamily: "Montserrat, sans-serif",
  },
  oAuthButtons: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "25px !important",
  },
  vendorLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "35px 0 30px 0",
  },
  popupStyles: {
    height: "285px",
    width: "500px",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "7px",
  },
  "@keyframes scaleAnimation": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  popupTitle: {
    "& h2": {
      fontSize: "26px",
    },
    padding: "0px",
    marginTop: "13px",
  },
  animatedRender: {
    animation: "$scaleAnimation .2s ease-in-out forwards",
  },
  popupContentStyles: {
    flex: "none",
    display: "flex",
    marginTop: "20px",
    padding: "0px 24px",
    flexDirection: "column",
    color: "#555555",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    textAlign: "center",
    fontSize: "14px",
  },
  popupContentWithIconStyles: {
    flex: "none",
    display: "flex",
    marginTop: "20px",
    padding: "0px 24px",
    flexDirection: "column",
    color: "#555555",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    textAlign: "center",
    fontSize: "14px",
    transform: "scale(0)",
  },
  popupSpiner: {
    marginTop: "30px",
  },
  popupIcon: {
    fontSize: "96px",
    transform: "scale(0)",
    marginTop: "5px",
  },
  popupIconSuccess: {
    color: "#0B7422",
  },
  popupIconError: {
    color: "#AE0000",
  },
  popupButton: {
    width: "130px",
    height: "50px",
  },
  dcrPoints: {
    borderRadius: "100px",
    color: "white",
    padding: "1px 6px",
    border: "2px solid white",
  },
  signUpPoint: {
    fontSize: "18px",
  },
  modalCard: {
    padding: "20px",
    width: "800px",
    backgroundColor: "white",
    borderRadius: "8px",
    textAlign: "left",
    position: "absolute",
    height: "95vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
  poweredByTrnsactLogo: {
    width: "auto",
    height: "auto",
    maxWidth: "250px",
    minHeight: "40px",
    maxHeight: "80px",
  },
  alertMessage: {
    marginTop: "40px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  resetLink: {
    color: "#3E83E6",
    "&:hover": {
      color: "#3E83E6",
    },
  },
  labelText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    color: "#132941",
  },
  signUpButton: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "18px",
    backgroundColor: "#3E83E6",
    padding: "14px 79px",
    borderRadius: "7px",
    marginBottom: "15px",
    fontFamily: "Montserrat, sans-serif",
  },
  terms: {
    color: "#3e83e6",
    textDecoration: "underline",
    cursor: "pointer",
  },
  customInputContainer: {
    "& > div": {
      paddingTop: "5px",
      marginBottom: "15px",
      "& > div > input": {
        borderRadius: "7px",
        backgroundColor: "#e8f0fc",
        padding: "11px 10px",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
        color: "#818181",
      },
    },
  },
  acceptTerms: {
    display: "inline-block",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  },
  signUpHeader: {
    fontSize: "30px",
    fontWeight: "600",
    fontFamily: "Rajdhani, sans-serif",
    lineHeight: "32px",
    color: "#000",
  },
  checkbox: {
    display: "inline-block",
    paddingLeft: "0",
    "& > .MuiIconButton-label > input ": {
      color: "#aeb5bd",
    },
  },
  phoneNumberCheckbox: {
    position: "absolute",
    right: 0,
    marginTop: "18px",
    marginRight: "10px",
  },
  CheckboxContainer: {
    padding: "0px !important",
  },
  checkboxPhone: {
    color: "#818181",
    padding: 0,
    margin: "9px 0 9px 9px",
    backgroundColor: "white",
    [theme.breakpoints.down("lg")]: {
      paddingRight: 0,
    },
    "& > span": {
      width: "19px",
      height: "18px",
    },
  },
  checkboxLabel: {
    color: "#818181",
    fontSize: "12px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    paddingLeft: 10,
  },
  loginPageHeader: {
    fontSize: "30px",
    fontWeight: "600",
    fontFamily: "Rajdhani, sans-serif",
    lineHeight: "37px",
    color: "#132941",
  },
  signInInputContainer: {
    padding: "0 !important",
    "& .MuiFormControl-fullWidth": {
      paddingTop: 5,
      "& span": {
        top: "-20px",
        fontFamily: "Montserrat, sans-serif",
        color: "#860D20 !important",
      },
    },
  },
});

export default loginPageStyle;
