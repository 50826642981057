import React from "react";
import { FormInputSelect } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const BooleanField = ({ label, fact, value, onChange, error, helperText }: EquipmentDynamicFieldsProps) => (
  <FormInputSelect
    value={value}
    error={error}
    variant="standard"
    helperText={error ? helperText : ""}
    label={label ?? fact.factKey.replace(/_/g, " ")}
    onChange={nextValue => onChange(nextValue)}
    options={[
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ]}
  />
);
