export const countriesFieldNames = ["country", "countryOfResidence"];
export const stateFieldNames = [
  "state",
  "prevState",
  "stateOfIncorp",
  "billingState",
  "dlStateIssued",
  "homeState",
  "employerState",
];
export const canadianStates = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
