import { SelectOption } from "global";
import { termsOptions } from "../constants";

export function calcTermsSelectOptions(terms: string[]): SelectOption[] {
  return [
    ...termsOptions,
    ...terms
      .filter(term => !termsOptions.map(({ value }) => value).includes(term))
      .map(term => ({ label: term, value: term })),
  ].toSorted((a, b) => +a.value - +b.value);
}
