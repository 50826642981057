import React from "react";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { indigo } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  type: string;
  name: string;
  size: number;
  uploadedBy: string;
  uploadedAt: string;
  onDelete: VoidFunction;
  onDownload: VoidFunction;
}

export const UploadedFileView = ({
  size,
  type,
  name,
  onDelete,
  onDownload,
  uploadedBy,
  uploadedAt,
}: Partial<Props>) => {
  const classes = useStyles();

  return (
    <Box className={classes.docViewContainer}>
      {type && (
        <Typography variant="body2" color="textSecondary">
          Type: {type}
        </Typography>
      )}

      <Box className={classes.docDetails}>
        <Box className={classes.nameBlock}>
          {size && <Typography>{(size / (1024 * 1024)).toFixed(2)} MB</Typography>}

          {onDownload && (
            <Tooltip title="Download" placement="top">
              <IconButton size="small" color="primary" onClick={onDownload}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          )}

          <InsertDriveFileIcon style={{ color: indigo["200"] }} />

          {name && (
            <Typography variant="body2" color="textPrimary">
              {name}
            </Typography>
          )}
        </Box>

        {onDelete && (
          <Tooltip title="Remove" placement="top">
            <IconButton size="small" color="secondary" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box className={classes.docAdditionalDetails}>
        {uploadedBy && (
          <Typography variant="body2" color="textSecondary">
            Uploaded By: {uploadedBy}
          </Typography>
        )}

        {uploadedAt && (
          <Typography variant="body2" color="textSecondary">
            {uploadedAt}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  docViewContainer: {
    gap: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  docDetails: {
    display: "flex",
    padding: "8px 16px",
    borderRadius: "8px",
    alignItems: "center",
    backgroundColor: "#EEF0FA",
    justifyContent: "space-between",
  },
  nameBlock: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  docAdditionalDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
