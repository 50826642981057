import { gql } from "@apollo/client";

export const GET_DETAILS_FOR_PRODUCTS_TABLE = gql`
  query DetailsForProductsTable($id: ID) {
    proposal(id: $id) {
      createdSnapshot
      lenderPaymentOptions {
        paymentCriteriaSelected {
          name
          proposalMenuId
        }
      }
    }
  }
`;
