import React, { SyntheticEvent, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { CreditSubmission, Proposal } from "@trnsact/trnsact-shared-types";
import { connect } from "react-redux";
import Tile from "../../Tile/Tile";
import ReSendProposalModal from "../../../pages/Proposals/ReSendProposalModal";
import { ProposalTable } from "./table/ProposalTable";
import { TableFiltersPanel } from "../../Table/TableFiltersPanel";
import { proposalFiltersInitialValue } from "../constants";
import { PageTypes } from "../../../global";
import { ProposalListQueryData, ProposalListResponse, ProposalListVariables, ProposalTableFilters } from "../types";
import { GET_CREDIT_SUBMISSION_PROPOSALS, GET_VENDOR_OPPORTUNITY_PROPOSALS } from "../queries";
import {
  filterTableDataByFiltersValues,
  getStatusUniqueOptions,
  mapProposalToCreditSubmissionTableData,
  mapProposalToVendorOpportunitiesTableData,
} from "../lib";

interface Props {
  voId?: any;
  applicant?: any;
  cs: CreditSubmission;
  pageType: PageTypes;
  showOnlyLenderPrograms?: boolean;
}

const ProposalList = ({ cs, pageType, voId }: Props) => {
  const [proposalTableData, setProposalTableData] = useState<any[]>([]);
  const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(null);

  const [tableFilters, setTableFilters] = useState<ProposalTableFilters>(proposalFiltersInitialValue);

  const [isReProposalModalOpen, setIsReSendProposalModalOpen] = useState<boolean>(false);

  const handleResend = (event: SyntheticEvent, proposal: Proposal) => {
    event.stopPropagation();

    setSelectedProposal(proposal);
    setIsReSendProposalModalOpen(true);
  };

  const queryDataByPageType: ProposalListQueryData = {
    [PageTypes.CreditSubmission]: {
      query: GET_CREDIT_SUBMISSION_PROPOSALS,
      mapper: mapProposalToCreditSubmissionTableData,
      variables: { creditSubmissionDynamicsId: cs.creditSubId },
    },
    [PageTypes.VendorOpportunities]: {
      variables: { voId },
      query: GET_VENDOR_OPPORTUNITY_PROPOSALS,
      mapper: mapProposalToVendorOpportunitiesTableData,
    },
  };

  const { loading, error } = useQuery<ProposalListResponse, ProposalListVariables>(
    queryDataByPageType[pageType].query,
    {
      fetchPolicy: "no-cache",
      context: { authRequired: true },
      notifyOnNetworkStatusChange: true,
      variables: queryDataByPageType[pageType].variables,
      onCompleted(data) {
        const mappedData = queryDataByPageType[pageType].mapper(data?.proposals ?? []);
        setProposalTableData(mappedData);
      },
    }
  );

  if (error) {
    return (
      <Typography component="span" variant="h3">
        {String(error)}
      </Typography>
    );
  }

  const filteredTableData = useMemo(
    () => filterTableDataByFiltersValues(proposalTableData, tableFilters),
    [proposalTableData, tableFilters]
  );

  return (
    <>
      <Tile title="Proposals">
        <Box mb="1rem">
          <TableFiltersPanel
            options={{
              status: { label: "Status", values: getStatusUniqueOptions(proposalTableData ?? []) },
            }}
            onSearchChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
            onOptionsChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
          />
        </Box>

        <ProposalTable
          pageType={pageType}
          handleResend={handleResend}
          isTableDataLoading={loading}
          processedData={filteredTableData ?? []}
        />
      </Tile>

      {pageType === PageTypes.CreditSubmission && (
        <ReSendProposalModal
          open={isReProposalModalOpen}
          proposal={selectedProposal}
          proposalId={selectedProposal?.id}
          proposalEmail={selectedProposal?.email}
          proposalApplicant={selectedProposal?.fullName}
          handleClose={() => {
            setIsReSendProposalModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  vp: state.vp,
  account: state.account,
  refetch: state.refetch,
  userProfile: state.userProfile,
  creditSubmissions: state.creditSubmissions,
});

export default connect(mapStateToProps, null)(ProposalList);
