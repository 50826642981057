import { DocumentNode } from "graphql";
import { ProductDetailsMappedData, ProposalsViewTypes } from "../types";
import { GET_PROPOSALS_FOR_HUB_TABLE, GET_PROPOSALS_FOR_HUB_TABLE_BY_ACCOUNT } from "../api";
import { ProposalSaleType } from "@trnsact/trnsact-shared-types";

export const SelectAllValue = "Select All";

export const hubTableApiQueryByType: Record<ProposalsViewTypes, DocumentNode> = {
  [ProposalsViewTypes.All]: GET_PROPOSALS_FOR_HUB_TABLE_BY_ACCOUNT,
  [ProposalsViewTypes.My]: GET_PROPOSALS_FOR_HUB_TABLE,
};

export const proposalSaleTypeToViewText: Record<ProposalSaleType, string> = {
  [ProposalSaleType.FinanceOnly]: "Finance",
  [ProposalSaleType.CashWithAftermarketMenu]: "Cash",
  [ProposalSaleType.FinanceWithAftermarketMenu]: "Finance",
} as const;

export const productsDetailsInitialValue: ProductDetailsMappedData = {
  tableData: [],
  totals: { cost: 0, markup: 0, retailCost: 0 },
};
