import React, { useState } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: "row",
  },
  radioButton: {
    color: "#818181",
    "& .MuiTypography-root": {
      fontSize: 12,
    },
    maxWidth: "40%",
    float: "left",
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  text: {
    margin: "10px 0",
    color: "#606060",
  },
  note: {
    fontSize: 12,
    color: "#3870ef",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
  },
}));

const DocumentQuestion = ({ fieldConfig }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const classes = useStyles();
  const fieldId = uuidv4();
  return (
    <Grid container spacing={1}>
      <FormControl component="fieldset" fullWidth={true} style={{ padding: 10 }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h5" component="h5" className={classes.header}>
            Document Upload
          </Typography>
          <p className={classes.text}>
            Would you like to upload the driver's license(s) now or later?{fieldConfig.required && " *"}
          </p>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <RadioGroup className={classes.radioGroup} name={fieldConfig.fieldName} value="">
              {fieldConfig.valuesList.map((variant, index) => (
                <FormControlLabel
                  key={`formControl${fieldId}${index}`}
                  className={classes.radioButton}
                  value={variant.value}
                  control={<Radio key={`${fieldId}${index}`} color="primary" />}
                  label={_.get(variant, "displayLabel.en", "")}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            Please expect an email with an link to upload at your convenience.{" "}
            <span className={classes.note}>(only shown when "Later" is selected)</span>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};
export { DocumentQuestion };
