import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions, deskingSelectors } from "../model";

export function useMenuOptionTerms() {
  const dispatch = useDispatch();

  const currentMenuTerm = useSelector(deskingSelectors.term);
  const menuTerms = useSelector(deskingSelectors.menuTerms);

  const currentTermIndex = menuTerms.indexOf(currentMenuTerm.term);
  const lastTermIndex = menuTerms.length - 1;

  useMemo(() => {
    if (menuTerms[currentMenuTerm.index] !== currentMenuTerm.term && menuTerms.length) {
      dispatch(
        deskingActions.setMenuTerm({
          index: currentTermIndex,
          term: currentMenuTerm.term,
        })
      );
    }

    if (currentTermIndex === -1 && menuTerms.length) {
      dispatch(
        deskingActions.setMenuTerm({
          index: lastTermIndex,
          term: menuTerms[lastTermIndex],
        })
      );
    }
  }, [dispatch, menuTerms, currentMenuTerm, currentTermIndex, lastTermIndex]);

  const termsOptions = useMemo(
    () => ({
      isBackwardDisabled: currentTermIndex <= 0,
      isForwardDisabled: currentTermIndex >= lastTermIndex,
      forwardTooltip: menuTerms[currentTermIndex + 1] ? `Go to ${menuTerms[currentTermIndex + 1]}` : "",
      backwardTooltip: menuTerms[currentTermIndex - 1] ? `Back to ${menuTerms[currentTermIndex - 1]}` : "",
    }),
    [currentTermIndex, lastTermIndex, menuTerms]
  );

  const handleUpdateTerm = useCallback(
    (action: "add" | "sub") => {
      const newIndex = action === "add" ? currentTermIndex + 1 : currentTermIndex - 1;

      if (newIndex >= 0 && newIndex <= lastTermIndex) {
        dispatch(
          deskingActions.setMenuTerm({
            index: newIndex,
            term: menuTerms[newIndex],
          })
        );
      }
    },
    [dispatch, menuTerms, currentTermIndex, lastTermIndex]
  );

  return {
    currentTerm: currentMenuTerm.term,
    ...termsOptions,
    handleUpdateTerm,
  };
}
