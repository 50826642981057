import React from "react";
import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

interface Props {
  isCollapsed: boolean;
  onChangeSectionLayout: VoidFunction;
}

export const MenuOptionsCardTitle = ({ isCollapsed, onChangeSectionLayout }: Props) => {
  return (
    <Box style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <IconButton size="small" onClick={onChangeSectionLayout}>
        {isCollapsed ? <Add /> : <Remove />}
      </IconButton>

      <Typography component="span" variant="h6">
        Menu Options
      </Typography>
    </Box>
  );
};
