import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { FormRadioGroup } from "components/form";
import { Controller, useFormContext } from "react-hook-form";
import { deskingSelectors } from "../../../../model";
import { sendViaOptions } from "../../../../constants";
import { FormSectionHeader } from "../../../components";
import { DeskingTestIds } from "../../../../constants/tests";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";

export const SendVia = ({ sectionName }: FormSectionProps) => {
  const { control } = useFormContext<FinanceProgramFormValues>();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const isSectionCollapsed = useSelector((state: any) => deskingSelectors.isSectionCollapsed(state, sectionName));

  return (
    <Box className="section" data-testid={DeskingTestIds.SendVia}>
      <FormSectionHeader
        name="Send Via"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        isSectionCollapsed={isSectionCollapsed}
      />

      <Box className="sectionFields">
        <Controller
          control={control}
          name="sendVia.type"
          render={({ field }) => <FormRadioGroup row options={sendViaOptions} {...field} />}
        />
      </Box>
    </Box>
  );
};
