import React, { useState } from "react";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { grey } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { DnDTypes } from "../../../types";
import { FormInputSelect, FormSwitch } from "../../../../../components/form";

const config = require("../../../../../config");

interface Props {
  financePrograms: any[];

  lender: LenderProfiles;
  isAutoSubmitted?: boolean;
  showProgramsAmount?: boolean;
  onSelectProgram?: () => void;
  onCreateProgram?: VoidFunction;
  onDelete?: (lenderId: string) => void;
  onChangeAutoSubmit?: (lenderId: string) => void;
  onUpdateProgram?: (programToUpdate: any) => void;
}

export const LenderCard = ({
  lender,
  onDelete,
  onSelectProgram,
  onUpdateProgram,
  onCreateProgram,
  financePrograms,
  onChangeAutoSubmit,
  isAutoSubmitted = false,
  showProgramsAmount = false,
}: Props) => {
  const classes = useStyles();

  // Start - This code only for testing Create\Update Finance program flow
  const [selected, setSelected] = useState<any>(null);
  // End - This code only for testing Create\Update Finance program flow

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.LenderCard,
      item: lender,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [lender]
  );

  return (
    <Box
      className={clsx(classes.card, {
        [classes.draggingCard]: isDragging,
      })}
      {...({ ref: drag } as any)}>
      <Box className={classes.title}>
        <Avatar
          alt="img"
          style={{ display: "inline-flex", padding: "5px" }}
          src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lender.lenderLogo}`}
        />

        <Typography component="span" variant="body1">
          {lender?.lenderName}
        </Typography>

        {onDelete && (
          <IconButton
            size="small"
            onClick={() => {
              onDelete(lender.lenderAccountId!);
            }}>
            <DeleteOutlineIcon color="error" />
          </IconButton>
        )}
      </Box>

      {showProgramsAmount && (
        <Box>
          <Typography component="span" variant="caption">
            Created programs: 0
          </Typography>
        </Box>
      )}

      {onChangeAutoSubmit && (
        <FormSwitch
          value={isAutoSubmitted}
          onChange={() => {
            onChangeAutoSubmit(lender.lenderAccountId!);
          }}
          label="Auto submit"
        />
      )}

      {onCreateProgram && (
        <Box>
          <Button variant="outlined" color="primary" onClick={onCreateProgram}>
            Create program
          </Button>
        </Box>
      )}

      {onUpdateProgram && (
        <Box>
          <FormInputSelect
            value={selected}
            onChange={value => {
              setSelected(value);
              onUpdateProgram(financePrograms.find(program => program?.financeProgramId === value));
            }}
            options={financePrograms.map(program => ({
              label: program?.nameInternal,
              value: program?.financeProgramId,
            }))}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    padding: "8px",
    cursor: "grab",
    display: "flex",
    borderRadius: "8px",
    background: grey["200"],
    flexDirection: "column",
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  title: {
    gap: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
