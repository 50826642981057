import React, { useContext } from "react";
import clsx from "clsx";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import ReactTable from "react-table";
import { createTableColumns } from "./tableConfig";
import { usePagination } from "hooks/usePagination";
import { CustomPagination } from "components/Table/Pagination";
import { CommonDataContext } from "contexts/CommonDataContext";

interface Props {
  loading: boolean;
  pageSize?: number;
  tableData: ProposalMenu[];
  onEditProposalMenu: (proposalMenuToDelete: ProposalMenu) => void;
  onDeleteProposalMenu: (proposalMenuToDelete: ProposalMenu) => void;
}

export const MenuTemplatesTable = ({
  loading,
  pageSize = 10,
  tableData,
  onEditProposalMenu,
  onDeleteProposalMenu,
}: Props) => {
  const classes = useStyles();

  const { isAftermarketPartner } = useContext(CommonDataContext);

  const { hasWriteAccess } = useAftermarketPermissions();

  const paginationProps = usePagination(tableData.length, { pageSize });

  const columns = createTableColumns({
    tableData,
    hasWriteAccess,
    isAftermarketPartner,
    onEditProposalMenu,
    onDeleteProposalMenu,
  });

  return (
    <ReactTable
      minRows={pageSize}
      loading={loading}
      columns={columns}
      data={loading ? [] : tableData}
      className={clsx("-highlight", classes.table)}
      PaginationComponent={CustomPagination}
      loadingText={
        <Typography component="span" variant="h6">
          Loading...
        </Typography>
      }
      noDataText={
        !loading && (
          <Typography component="span" variant="h6">
            No data yet
          </Typography>
        )
      }
      {...paginationProps}
    />
  );
};

const useStyles = makeStyles(({ palette }) => ({
  table: {
    "& .rt-thead .rt-th, .rt-td": {
      padding: "12px 8px",
    },
    "& .customHeader": {
      backgroundColor: "#fafafa",
    },
    "& .customHeader > div": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      fontFamily: "Roboto",
    },

    "& .customHeader > div::after": {
      color: "black",
      fontWeight: 500,
    },
  },
}));
