import React from "react";
import { Button, Grid } from "@mui/material";

interface Props {
  dominantColor: string;
  disableUpdate: boolean;
  secondaryColorHex: string;
  onCalculateColors: VoidFunction;
}

export const OcaColorSchema = ({ dominantColor, disableUpdate, secondaryColorHex, onCalculateColors }: Props) => {
  return (
    <Grid container style={{ border: "1px solid #959595", borderRadius: "5px", padding: "10px" }}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <h6>Logo Detected Colors</h6>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <p style={{ fontSize: "12px" }}>Primary color</p>
          </Grid>
          <Grid item xs={6}>
            <div style={{ padding: "10px", backgroundColor: dominantColor, border: "1px solid #959595" }}></div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <p style={{ fontSize: "12px" }}>Secondary color</p>
          </Grid>
          <Grid item xs={6}>
            <div style={{ padding: "10px", backgroundColor: secondaryColorHex, border: "1px solid #959595" }}></div>
          </Grid>
        </Grid>

        <Grid container>
          <Button
            size="small"
            variant="contained"
            disabled={disableUpdate}
            onClick={event => {
              event.preventDefault();
              onCalculateColors();
            }}>
            Set OCA Color Schema
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
