import { gql } from "@apollo/client";

export const GET_DYNAMIC_AFTERMARKET_PRODUCT_PRICING = gql`
  query AftermarketProductDynamicPricing($input: AftermarketProductDynamicPricingInput!) {
    aftermarketProductDynamicPricing(input: $input) {
      success
      errorMessage
      data
    }
  }
`;
