import React from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Paper, Typography } from "@mui/material";
import { PageTypes } from "../../../../global";
import { Breadcrumbs } from "../../../../components/Breadcrumbs/Breadcrumbs";

interface Props {
  applicant: string;
  pageType: PageTypes;
}

export const CreditApplicationDetailsHeader = ({ applicant, pageType }: Props) => {
  const classes = useStyles();

  const { push } = useHistory();

  const listUrl: Record<PageTypes, string> = {
    [PageTypes.VendorOpportunities]: "/vo",
    [PageTypes.CreditSubmission]: "/cs",
  };

  return (
    <Paper className={classes.head} elevation={0}>
      <Box className={classes.headContent}>
        <Typography component="span" variant="h5">
          Submission Details: {applicant}
        </Typography>

        <Breadcrumbs
          currentValue="Detail"
          config={[
            {
              label: "List View",
              value: "list",
              onClick: () => push(listUrl?.[pageType] ?? listUrl[PageTypes.VendorOpportunities]),
            },
            { label: "Detail", value: "Detail", onClick: () => {} },
          ]}
        />
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles({
  head: {
    padding: "1.5rem 1.5rem 0.5rem 1.5rem",
  },
  headContent: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
  applicantInfo: {
    display: "flex",
    alignItems: "center",
  },
});
