import { useQuery } from "@apollo/client";
import { GET_VENDOR_OPPORTUNITY_TASKS } from "../api";
import { VOTasksResponse, VOTasksVariables } from "../api/types";

interface Options {
  VOId: string;
}

export function useVOTasks({ VOId }: Options) {
  const { data, refetch: fetchVOTasks, loading } = useQuery<VOTasksResponse, VOTasksVariables>(
    GET_VENDOR_OPPORTUNITY_TASKS,
    {
      variables: { VOId },
    }
  );

  return {
    fetchVOTasks,
    isVOTasksLoading: loading,
    tasks: data?.vendorOpportunityTask ?? [],
  };
}
