import gql from "graphql-tag";

export const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!, $lenderProfileIds: [ID]) {
    financePrograms(accountId: $accountId, lenderProfileIds: $lenderProfileIds) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      insuranceCoverage {
        coverageRequirements
        certificateRequirements
        otherInstructions
      }
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
      submissionSettings {
        excludedDocuments
      }
    }
  }
`;

export const Q_PROCESS = gql`
  query GetProcess($filters: ProcessesFiltersInput) {
    processes(filters: $filters) {
      accountId
      processDescription
      processId
      processKey
      processName
      processConfigValues
      active
    }
  }
`;

export const UPDATE_WORKFLOW = gql`
  mutation ($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      success
      message
    }
  }
`;

export const M_SAVE_PROCESS = gql`
  mutation ($input: CreateProcessInput!) {
    createProcess(input: $input) {
      accountId
      processDescription
      processId
      processKey
      processName
      processConfigValues
    }
  }
`;

export const CREATE_FINANCE_PROGRAM = gql`
  mutation CreateFinanceProgram($input: CreateFinanceProgramInput!) {
    createFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      insuranceCoverage {
        coverageRequirements
        certificateRequirements
        otherInstructions
      }
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

export const UPDATE_FINANCE_PROGRAM = gql`
  mutation UpdateFinanceProgram($input: UpdateFinanceProgramInput!) {
    updateFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      insuranceCoverage {
        coverageRequirements
        certificateRequirements
        otherInstructions
      }
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

export const Q_Prescreen_Criteria = gql`
  query PreScreenCriteria($accountId: ID!) {
    prescreenCriteria(accountId: $accountId) {
      prescreenCriteriaId
      name
      accountId
      userProfileIdCreatedBy
      jsonCriteria
      active
      notes
      guidelines
      userProfileIdCreatedBy
      createdDateTime
    }
  }
`;

export const M_Create_Prescreen_Criteria = gql`
  mutation CreatePrescreenCriteria($input: CreatePrescreenCriteriaInput!) {
    createPrescreenCriteria(input: $input) {
      prescreenCriteriaId
      name
      accountId
      userProfileIdCreatedBy
      jsonCriteria
      active
      notes
      guidelines
    }
  }
`;

export const M_Update_Prescreen_Criteria = gql`
  mutation UpdatePrescreenCriteria($input: UpdatePrescreenCriteriaInput!) {
    updatePrescreenCriteria(input: $input) {
      prescreenCriteriaId
      name
      accountId
      jsonCriteria
      notes
      guidelines
      userProfileIdCreatedBy
      active
    }
  }
`;
