import React from "react";
import { ModalsKeys } from "global";
import { formatTaxID } from "formatters";
import { useModal } from "hooks/useModal";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { ReferenceType } from "@trnsact/trnsact-shared-types";
import { InputField, NumberFormatField } from "components/form";
import { getSectionByReferenceType } from "utils/ocaTemplateUtils";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box, LinearProgress } from "@mui/material";
import { CorporateGuarantorForm } from "../../types";
import { validateTaxID, validateYear } from "../../lib";
import { UPDATE_CREDIT_APPLICATION_V2_QUERY } from "../../queries";

export const CorporateGuarantorModal = () => {
  const { isOpen, data, handleClose } = useModal(ModalsKeys.CorporateGuarantorDialog);

  const [updateCreditAppRequest, { loading }] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const { control, handleSubmit } = useForm<CorporateGuarantorForm>({
    defaultValues: {
      companyName: "",
      address: "",
      taxId: "",
      yearBusinessEstablished: undefined,
    },
  });

  const handleSave = async (formData: CorporateGuarantorForm) => {
    const corporateGuarantorSectionInOca = getSectionByReferenceType(
      data.ocaTemplate,
      ReferenceType.CorporateGuarantor
    );

    const corporateGuarantorReference = {
      ...formData,
      taxId: parseInt(formData.taxId.replace(/[^0-9]/g, "")),
      referenceType: ReferenceType.CorporateGuarantor,
      referenceIdx: corporateGuarantorSectionInOca.referenceIdx,
    };

    const payload = {
      creditAppId: data.creditApplication.id,
      input: {
        references: [corporateGuarantorReference],
      },
    };

    await updateCreditAppRequest({ variables: payload });
    handleClose();
    await data.refetchCreditApp();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Add Corporate Guarantor
        <Divider />
      </DialogTitle>

      {!loading && (
        <DialogContent>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
            <InputField
              control={control}
              name="companyName"
              label="Legal Business Name"
              rules={{ required: true }}
              inputProps={{ required: true }}
            />

            <InputField
              name="address"
              control={control}
              label="Business Address"
              rules={{ required: true }}
              inputProps={{ required: true }}
            />

            <InputField
              name="taxId"
              control={control}
              label="Tax ID (EIN)"
              formatter={formatTaxID}
              rules={{ validate: validateTaxID }}
            />

            <NumberFormatField
              control={control}
              name="yearBusinessEstablished"
              label="Year Business Established"
              rules={{ validate: validateYear }}
              textFieldProps={{ size: "small", fullWidth: true }}
            />
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        {!loading ? (
          <>
            <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
              Cancel
            </Button>

            <Button variant="contained" color="primary" size="small" onClick={handleSubmit(handleSave)}>
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};
