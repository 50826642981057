import React, { useContext, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../components/CustomButtons/Button";
import { DocumentContext } from "../../contexts/DocumentContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
// import mixpanel from '../../mixpanel';

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const CREATE_DOC_SET = gql`
  mutation ($input: UpsertDocSetInput!) {
    upsertDocSet(input: $input)
  }
`;

export default function DocSetCreateModal(props) {
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(0, 2),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
    },
    primaryBorderColor: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiInputLabel-shrink": {
        color: theme.palette.primary.main,
      },
    },
  }));

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openErrorDialogue, setOpenErrorDialogue] = useState(false);
  // const [openDescriptionDialogue, setOpenDescriptionDialogue] = useState(false);
  const [mutationError, setMutationError] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [docSetName, setDocSetName] = useState("");
  const [docSetDescription, setDocSetDescription] = useState("");
  // const [docSetApprovalTerms, setDocSetApprovalTerms] = useState('');
  const [docSetTemplates, setDocSetTemplates] = useState([]);
  const [docSetVault, setDocSetVault] = useState("");
  const [docSetKnowledge, setDocSetKnowledge] = useState("");
  const [createDocSet] = useMutation(CREATE_DOC_SET, {
    context: { authRequired: true },
  });
  const documentContext = useContext(DocumentContext);
  const graphqlErrorMessage = {
    duplicateMessage:
      'GraphQL error: duplicate key value violates unique constraint "document_vendor_opportunity_id_doc_name_key"',
  };
  const [open, setOpen] = useState(false);
  const errorDialogue = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {!!mutationError && mutationError.message === graphqlErrorMessage.duplicateMessage
          ? "This file is already uploaded"
          : ""}
      </p>
    </div>
  );

  const handleOpenErrorDialogue = () => setOpenErrorDialogue(true);
  const handleCloseErrorDialogue = () => setOpenErrorDialogue(false);

  const handleChangeDocSetName = event => {
    setDocSetName(event.target.value || "");
  };
  const handleChangeDocSetDescription = event => {
    setDocSetDescription(event.target.value || "");
  };
  const handleChangeDocSetVault = event => {
    setDocSetVault(event.target.value || "");
  };
  const handleChangeDocSetKnowledge = event => {
    setDocSetKnowledge(event.target.value || "");
  };
  const handleCreateDocSet = async () => {
    try {
      const reduceDocSetTemplates = docSetTemplates.reduce((acc, curr) => (acc.push(curr.docusignTemplateId), acc), []);
      const docuSignTemplateIds = reduceDocSetTemplates.join(",");
      const response = await createDocSet({
        variables: {
          input: {
            name: docSetName,
            description: docSetDescription,
            createdBy: "testing",
            docusignTemplateIds: docuSignTemplateIds,
          },
        },
      });
      await setDocSetName("");
      await setDocSetDescription("");
      await setDocSetTemplates([]);
      // await setDocSetApprovalTerms('');
      await setDocSetVault("");
      await setDocSetKnowledge("");
      await props.docSetsRefetch();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setMutationError(err);
      handleOpenErrorDialogue();
      await setDocSetName("");
      await setDocSetDescription("");
      await setDocSetTemplates("");
      // await setDocSetApprovalTerms('');
      await setDocSetVault("");
      await setDocSetKnowledge("");
      setOpen(false);
    }
  };

  const approvalTermsOptions = [
    { value: "trac", text: "Trac" },
    { value: "finance", text: "Finance" },
    { value: "lease", text: "Lease" },
  ];

  const vaultOptions = [
    { value: "eOriginal", text: "eOriginal" },
    { value: "none", text: "None" },
  ];

  const knowledgeBasedAuthOptions = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
  ];

  const handleClose = () => {
    setOpen(false);
    setDocSetName("");
    setDocSetDescription("");
    // setDocSetApprovalTerms('');
    setDocSetTemplates([]);
    setDocSetVault("");
    setDocSetKnowledge("");
    props.handleCloseDocSetModal();
  };

  return (
    <div>
      <Dialog disableEscapeKeyDown open={props.open} onClose={handleClose}>
        <DialogTitle>Create Doc Set</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Name"
                multiline
                rows={1}
                value={docSetName}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeDocSetName}
                required
                helperText={"Please enter a name for the document"}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Description"
                multiline
                rows={4}
                value={docSetDescription}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeDocSetDescription}
                required
              />
            </FormControl>
            <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
              <Autocomplete
                multiple
                value={docSetTemplates}
                size="small"
                options={props.templates}
                onChange={(event, newValue) => {
                  setDocSetTemplates(newValue);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField {...params} variant="standard" label="Templates (Add In Order)" placeholder="" />
                )}
              />
            </FormControl>
            {/* <FormControl className={[classes.selectFormControl, classes.formControl].join(' ')}>
              <InputLabel htmlFor="demo-dialog-native">Doc Set Approval Terms</InputLabel>
              <Select
                color="primary"
                classes={{select: classes.select}}
                value={docSetApprovalTerms}
                onChange={handleChangeDocSetApprovalTerms}
                inputProps={{name: "lenderSelect", id: "lender-select"}}>
                {
                  approvalTermsOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value} classes={{root: classes.selectMenuItem}}>
                      <Checkbox
                        checked={docSetApprovalTerms === item.value}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked,}}/>
                      {item.text}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl> */}
            <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
              <InputLabel htmlFor="demo-dialog-native">Vault?</InputLabel>
              <Select
                color="primary"
                classes={{ select: classes.select }}
                value={docSetVault}
                onChange={handleChangeDocSetVault}
                inputProps={{ name: "vaultSelect", id: "vault-select" }}>
                {vaultOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value} classes={{ root: classes.selectMenuItem }}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
              <InputLabel htmlFor="demo-dialog-native">Knowledge Based Auth?</InputLabel>
              <Select
                color="primary"
                classes={{ select: classes.select }}
                value={docSetKnowledge}
                onChange={handleChangeDocSetKnowledge}
                inputProps={{ name: "knowledgeSelect", id: "knowledge-select" }}>
                {knowledgeBasedAuthOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value} classes={{ root: classes.selectMenuItem }}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" simple>
            Cancel
          </Button>
          <Button onClick={handleCreateDocSet} color="primary" simple>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
