import React, { useContext, useState } from "react";
import Button from "../../components/CustomButtons/Button";
import makeStyles from "@mui/styles/makeStyles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Auth } from "aws-amplify";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useHistory } from "react-router";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import { Grid } from "@mui/material";
import { MobileContext } from "../../contexts/MobileContext";

const useStyles = makeStyles(styles);

export default function ForgotPasswordForm(props) {
  const mobileContext = useContext(MobileContext);
  const classes = useStyles();
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const [values, setValues] = useState({ userName: "", passwordResetCode: "", newPassword: "" });
  const [resetCodeSent, setResetCodeSent] = useState(false);

  // success snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = React.useState("");
  const history = useHistory();
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  // error snackbar
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = React.useState("");
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleSendCode = event => {
    event.preventDefault();
    const userName = values["userName"].toLowerCase();
    Auth.forgotPassword(userName)
      .then(data => {
        console.log(data);
        setResetCodeSent(true);
      })
      .catch(err => {
        console.log(err);
        setErrorSnackbarMessage("Looks like you have not registered");
        setOpenErrorSnackbar(true);
        history.push("/signup");
      });
  };
  const handleNewPasswordSubmit = event => {
    event.preventDefault();

    const pwd = values.newPassword;
    const isPwdValid = pwd.length >= 6;

    if (!isPwdValid) {
      setIsNewPasswordValid(isPwdValid);
      return;
    } else {
      setIsNewPasswordValid(isPwdValid);
    }
    const userName = values["userName"].toLowerCase();
    Auth.forgotPasswordSubmit(userName, values["passwordResetCode"], values["newPassword"])
      .then(data => {
        setSuccessSnackbarMessage("Successfully submitted new password");
        setOpenSuccessSnackbar(true);
        if (props.onPasswordResetSuccess) {
          props.onPasswordResetSuccess(data);
        }
      })
      .catch(err => {
        console.log(err);
        setErrorSnackbarMessage(err.message);
        setOpenErrorSnackbar(true);
        if (props.onPasswordResetError) {
          props.onPasswordResetError(err);
        }
      });
  };

  let { isMobile } = mobileContext;

  return (
    <div className={classes.container} style={isMobile ? { height: "100vh" } : {}}>
      <Grid container sx={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={6} md={6}>
          <Card login className={classes[cardAnimation]}>
            <GridItem xs={12} style={{ margin: "40px 0 5px" }}>
              <Typography variant="h5" align="center" className={classes.signUpHeader}>
                Reset Your Password
              </Typography>
            </GridItem>
            <CardBody>
              {!resetCodeSent && (
                <form onSubmit={handleSendCode}>
                  <GridContainer>
                    <GridItem lg={12} xs={12} className={classes.customInputContainer}>
                      <InputLabel className={classes.labelText}>Email Address</InputLabel>
                      <CustomInput
                        id="email"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "userName",
                          onChange: handleInputChange,
                          autoComplete: "email",
                          required: true,
                          autoFocus: true,
                          disableUnderline: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={12} xs={12}>
                      <Grid container justifyContent="center" style={{ marginTop: 20, marginBottom: 10 }}>
                        <Button type="submit" className={classes.signUpButton} variant="contained" color="primary">
                          Send Code
                        </Button>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </form>
              )}
              {!!resetCodeSent && (
                <form onSubmit={handleNewPasswordSubmit}>
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        labelText="Reset Password Code"
                        fullWidth
                        label="Code"
                        type="text"
                        id="passwordResetCode"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "passwordResetCode",
                          onChange: handleInputChange,
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        fullWidth
                        labelText="New Password"
                        type="text"
                        id="newPassword"
                        formControlProps={{ fullWidth: true, required: true }}
                        error={!isNewPasswordValid}
                        helperText={!isNewPasswordValid && "Password must be a minimum of 6 characters."}
                        inputProps={{
                          name: "newPassword",
                          onChange: handleInputChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                classes={{ root: classes.root }}
                                size="large">
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          type: showPassword ? "text" : "password",
                        }}
                      />
                    </GridItem>
                    <GridItem lg={12} xs={12}>
                      <Grid container justifyContent="center" style={{ marginTop: 20, marginBottom: 10 }}>
                        <Button
                          className={classes.signUpButton}
                          variant="contained"
                          type="submit"
                          fullWidth
                          simple
                          size="lg"
                          block
                          color="primary">
                          Submit
                        </Button>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </form>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}>
        <Alert
          onClose={handleSuccessSnackbarClose}
          severity="success"
          className={classes.alertMessage}
          variant="filled">
          {successSnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} severity="error" className={classes.alertMessage} variant="filled">
          {errorSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
