import {
  AftermarketPartnerIntegrationSettingsDialogData,
  DealerManagementPartnerLink,
} from "modules/aftermarketPartner/types";
import { createTableColumns } from "./tableConfig";
import { Table } from "components/shared/Table";

interface Props {
  loading?: boolean;
  tableData?: DealerManagementPartnerLink[];
  handleOpenIntegrationSettingsDialog: (data?: AftermarketPartnerIntegrationSettingsDialogData) => void;
}

export const DealerManagementTable = ({ tableData, loading, handleOpenIntegrationSettingsDialog }: Props) => {
  const columns = createTableColumns(handleOpenIntegrationSettingsDialog);

  return <Table<DealerManagementPartnerLink> tableData={tableData || []} columns={columns} loading={loading} />;
};
