import React, { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

export const ProposalProductsCardsContainer = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    maxHeight: "800px",
    overflowY: "auto",
    flexDirection: "column",
  },
});
