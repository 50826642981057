import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import makeStyles from "@mui/styles/makeStyles";
import { Query } from "@apollo/client/react/components";
import Tile from "../../components/Tile/Tile";
import Button from "components/CustomButtons/Button";
import { formatCurrency } from "../../utils";
import Grid from "@mui/material/Grid";
import CreateNewLeadModal from "./AddNewLead";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import { Add, CloseOutlined } from "@mui/icons-material";
import TableSearch from "components/Table/TableSearch";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSelect from "components/Table/TableSelect";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Dialog, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { theme } from "../../theme";

const _ = require("lodash");

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      // backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  switch: {
    "& .MuiTypography-body1": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
});

const useStyles = makeStyles(styles);

const GET_LEAD_LIST = gql`
  query UserLeads {
    leads {
      id
      amountRequested
      fullName
      email
      businessName
      phone
      createdDateTime
      modifiedDateTime
      vendorSalespersonId
      status
      accountId: creditApplicationId
      title
      description
      salesPersonName
      source
      leadStatus
      emailRemindersEnabled
      equipmentDescription
      locationName
    }
  }
`;

const GET_LEAD_LIST_ACCOUNT = gql`
  query AccountLeads {
    accountLeads {
      id
      amountRequested
      fullName
      email
      businessName
      phone
      createdDateTime
      modifiedDateTime
      vendorSalespersonId
      status
      accountId: creditApplicationId
      creditApplicationId
      title
      description
      salesPersonName
      source
      leadStatus
      emailRemindersEnabled
      equipmentDescription
      locationName
    }
  }
`;

const GET_LEAD_LIST_PARTNER = gql`
  query AccountLeads {
    accountLeads {
      id
      amountRequested
      fullName
      email
      businessName
      phone
      createdDateTime
      modifiedDateTime
      vendorSalespersonId
      status
      accountId: creditApplicationId
      creditApplicationId
      title
      description
      salesPersonName
      source
      leadStatus
      dealerName
      emailRemindersEnabled
      equipmentDescription
      locationName
    }
  }
`;

function PageLeadsList(props) {
  const userProfileDynamicsAccountId = props.account.dynamicsAccountId;
  const userProfileAccountId = props.account.id;
  const classes = useStyles();
  let statusesSel = [];
  let locationsSel = [];
  const rolesSel = [];
  const leadStatusSel = [];
  const [leads, setLeads] = useState("account");
  const [partnerView, setPartnerView] = useState(false);
  const [savedListSettings, setSavedListSettings] = useState(
    JSON.parse(window.localStorage.getItem("leadListSettings")) === null
      ? {
          filter: [{ id: "leadStatus", value: "MASTER" }],
          sorting: [],
          page: 0,
          pageSize: 10,
        }
      : JSON.parse(window.localStorage.getItem("leadListSettings"))
  );
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const locationRef = useRef({ prevLocation: undefined, currentLocation: "" });
  const statusRef = useRef({ prevStatus: undefined, currentSelect: "" });
  const [hideCompletedApps, setHideCompletedApps] = useState(true);
  const [pageSizeValue, setPageSizeValue] = useState(20);
  const [statusModal, setStatusModal] = useState(false);

  useEffect(() => {
    setPartnerView(props.account.accountType === "Partner");
  }, [props.account]);

  function handleVORowClick(leadId) {
    props.history.push(`/leadDetail/${leadId}`);
  }

  const filterLeadListData = LeadListData => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return LeadListData;
    }
    const lowerCaseSearch = currentSearch.toLowerCase().trim();

    if (!_.isEmpty(LeadListData.accountLeads)) {
      const searchData = LeadListData.accountLeads.filter(el => {
        if (
          typeof el.businessName === "string" ||
          typeof el.fullName === "string" ||
          typeof el.amountRequested === "string" ||
          typeof el.equipmentDescription === "string" ||
          typeof el.source === "string" ||
          typeof el.salesPersonName === "string"
        ) {
          return (
            (el.businessName || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.amountRequested || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.equipmentDescription || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.source || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.salesPersonName || "").toLowerCase().includes(lowerCaseSearch)
          );
        }

        return false;
      });

      return { ...LeadListData, accountLeads: [...searchData] };
    } else {
      const searchData = LeadListData.accountLeads.filter(el => {
        if (
          typeof el.dealerName === "string" ||
          typeof el.businessName === "string" ||
          typeof el.fullName === "string" ||
          typeof el.equipmentDescription === "string" ||
          typeof el.amountRequested === "string" ||
          typeof el.salesPersonName === "string"
        ) {
          return (
            (el.dealerName || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.businessName || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.equipmentDescription || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.amountRequested || "").toLowerCase().includes(lowerCaseSearch) ||
            (el.salesPersonName || "").toLowerCase().includes(lowerCaseSearch)
          );
        }

        return false;
      });

      return { ...LeadListData, leads: [...searchData] };
    }
  };

  function renderStatusExpl(status) {
    let exp = "";

    switch (status) {
      case "Credit Application completed":
        exp = "Credit application is completed and opportunity created.";
        break;
      case "Credit Application has been continued":
        exp = "Credit application was started by customer, stopped, and then continued application.";
        break;
      case "Stale":
        exp = "Two reminder emails to complete the credit application sent and application still not completed.";
        break;
      case "New Lead Created":
        exp = "Lead created with no reminders sent, no credit application, and no progress made.";
        break;
      case "Credit Application Sent":
        exp = "Customer has been sent the credit application.";
        break;
      case "New Credit Application Has Been Started":
        exp = "Customer has received and started the credit application.";
        break;
      case "Quote sent":
        exp = "Finance amount quote has been sent to customer.";
        break;
      case "Credit Application Started":
        exp = "Customer has received and started the credit application.";
        break;
      case "OCA completed":
        exp = "Credit application is completed.";
        break;
      case "oca sent":
        exp = "Customer has been sent the credit application.";
        break;
      case "OCA has been started":
        exp = "Customer has received and started the credit application.";
        break;
      case "quote sent":
        exp = "Finance amount quote has been sent to customer.";
        break;
      default:
        exp = "";
        break;
    }

    return exp;
  }

  function processRepListData(LeadListData) {
    if (LeadListData && _.isArray(LeadListData.leads)) {
      const statuses = [];
      const roles = [];
      const leadStatus = [];
      const locations = [];

      LeadListData.leads.forEach(item => {
        try {
          item.amountRequested = formatCurrency(item.amountRequested);
          if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
            statuses.push(item.status);
          }
          if (locations.find(listItem => listItem === item.locationName) === undefined && !!item.locationName) {
            locations.push(item.locationName);
          }
          if (leadStatus.find(listItem => listItem === item.leadStatus) === undefined && !!item.leadStatus) {
            leadStatus.push(item.leadStatus);
          }
          item.leadType = item.creditApplicationId ? "Incomplete Application" : "Marketing Lead";
        } catch (error) {
          console.log(error);
        }
      });
      statuses.forEach(item => {
        const statusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        statusesSel.push(statusesSelItem);
      });
      statusesSel = _.uniqBy(statusesSel, "value");

      locations.forEach(item => {
        const locationsSelItem = {
          value: item,
          text: item ? item : "None",
        };
        locationsSel.push(locationsSelItem);
      });
      locationsSel = _.uniqBy(locationsSel, "value");

      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });
      leadStatus.forEach(item => {
        const leadStatusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        leadStatusSel.push(leadStatusesSelItem);
      });

      return LeadListData.leads;
    }
    if (LeadListData && _.isArray(LeadListData.accountLeads)) {
      const statuses = [];
      const locations = [];
      const roles = [];

      LeadListData.accountLeads.forEach(item => {
        try {
          item.amountRequested = formatCurrency(item.amountRequested);
          if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
            statuses.push(item.status);
          }
          if (locations.find(listItem => listItem === item.locationName) === undefined && !!item.locationName) {
            locations.push(item.locationName);
          }
          item.leadType = item.creditApplicationId ? "Incomplete Application" : "Marketing Lead";
        } catch (error) {
          console.log(error);
        }
      });
      statuses.forEach(item => {
        const statusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        statusesSel.push(statusesSelItem);
      });
      statusesSel = _.uniqBy(statusesSel, "value");

      locations.forEach(item => {
        const locationsSelItem = {
          value: item,
          text: item ? item : "None",
        };
        locationsSel.push(locationsSelItem);
      });
      locationsSel = _.uniqBy(locationsSel, "value");

      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });

      return LeadListData.accountLeads;
    }
    return [];
  }
  const [openAddNewLeadModal, setOpenAddNewLeadModal] = useState(false);

  const handleOpenAddNewLeadModal = () => {
    setOpenAddNewLeadModal(true);
  };
  const handleCloseAddNewLeadModal = () => {
    setOpenAddNewLeadModal(false);
  };
  const handleSetLeadsToLoad = () => {
    if (leads == "self") {
      setLeads("account");
    } else {
      setLeads("self");
    }
  };
  const setDealerWebsiteSource = value => {
    let source = "";
    if (!value) return "";

    switch (value) {
      case "PP":
        source = "Dealer Website Product Page";
        break;
      case "ILP":
        source = "Dealer Website Inventory List Page";
        break;
      case "FP":
        source = "Dealer Website Finance Page";
        break;
      default:
        source = "";
        break;
    }
    return source;
  };

  useEffect(() => {
    window.localStorage.setItem("leadListSettings", JSON.stringify(savedListSettings));
  }, [savedListSettings]);

  const handleSaveSettings = (aspect, e) => {
    let saved = {};
    saved[aspect] = e;
    setSavedListSettings({ ...savedListSettings, ...saved });
  };
  const handleResetFilters = () => {
    setSavedListSettings({ ...savedListSettings, filter: [] });
  };
  let query = leads === "self" ? GET_LEAD_LIST : GET_LEAD_LIST_ACCOUNT;

  if (partnerView) {
    query = GET_LEAD_LIST_PARTNER;
  }

  return (
    <>
      <Query context={{ authRequired: true }} query={query} fetchPolicy="no-cache">
        {({ loading, error, data, refetch: refetchLeads }) => {
          if (loading) return "Loading...";
          if (error) return <h3>{String(error)}</h3>;
          let repListData = processRepListData(filterLeadListData(data));
          if (leads !== "self") {
            repListData = processRepListData(filterLeadListData(data));
          }

          if (hideCompletedApps) {
            repListData = repListData.filter(lead => lead.status !== "Credit Application completed");
          }

          const { currentSelect: currentStatusSelect } = statusRef.current;
          const { currentSelect: currentLocationSelect } = locationRef.current;

          if (currentStatusSelect) {
            repListData = repListData.filter(el => currentStatusSelect.includes(el.status));
          }

          if (currentLocationSelect) {
            repListData = repListData.filter(el => currentLocationSelect.includes(el.locationName));
          }

          let defaultColumns = [
            {
              Header: "Created On",
              resizable: false,
              accessor: "createdDateTime",
              sortMethod: (a, b) => {
                const dateA = new Date(a).getTime();
                const dateB = new Date(b).getTime();

                return dateA - dateB;
              },
              Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
            },

            {
              Header: "Business Name",
              accessor: "businessName",
            },
            {
              Header: "Contact",
              accessor: "fullName",
            },
            {
              Header: "Amount",
              accessor: "amountRequested",
            },
            {
              Header: "Equipment",
              accessor: "equipmentDescription",
            },
            {
              Header: "Location",
              accessor: "locationName",
            },
            {
              Header: "Source",
              accessor: "source",
              Cell: props => {
                let source = props?.value ? props.value.replace("Page", "") : props.value;
                if (source && setDealerWebsiteSource(source)) {
                  source = setDealerWebsiteSource(source);
                }
                return source;
              },
            },
            {
              Header: "Type",
              accessor: "leadType",
            },
            {
              Header: "Status",
              accessor: "status",
            },
            {
              Header: "Owner",
              accessor: "salesPersonName",
            },
          ];
          if (partnerView) {
            defaultColumns = [
              {
                Header: "Created On",
                resizable: false,
                accessor: "createdDateTime",
                Cell: props => <div>{props.value ? moment(props.value).format("ll hh:mma") : ""}</div>,
                Filter: () => <></>,
              },
              {
                Header: "Dealer Account Name",
                accessor: "dealerName",
              },
              {
                Header: "Company Name",
                accessor: "businessName",
              },
              {
                Header: "Company Contact",
                accessor: "fullName",
              },
              {
                Header: "Amount",
                accessor: "amountRequested",
              },
              {
                Header: "Equipment",
                accessor: "equipmentDescription",
              },
              {
                Header: "Dealer Contact",
                accessor: "salesPersonName",
              },
              {
                Header: "Status",
                accessor: "status",
              },
            ];
          }

          return (
            <div className={classes.root}>
              <Grid>
                <Grid item xs={12}>
                  <Tile>
                    <div className={classes.fixReactTable}>
                      <TableHeader
                        filterComps={
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ display: "flex", columnGap: 16 }}>
                              <TableSearch
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                searchRef={searchRef}
                                savedListSettings={savedListSettings}
                              />
                              <TableSelect
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                selectRef={locationRef}
                                savedListSettings={savedListSettings}
                                id="location"
                                label="Location"
                                options={
                                  hideCompletedApps
                                    ? locationsSel.filter(location => location.value !== "Credit Application completed")
                                    : locationsSel
                                }
                                width={200}
                                multiSelect={true}
                              />
                              <TableSelect
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                selectRef={statusRef}
                                savedListSettings={savedListSettings}
                                id="status"
                                label="Status"
                                options={
                                  hideCompletedApps
                                    ? statusesSel.filter(status => status.value !== "Credit Application completed")
                                    : statusesSel
                                }
                                width={200}
                                multiSelect={true}
                              />
                              <InfoIcon
                                style={{
                                  fontSize: 20,
                                  color: theme.palette.primary.main,
                                  cursor: "pointer",
                                  marginLeft: -12,
                                }}
                                onClick={() => setStatusModal(true)}
                              />
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={hideCompletedApps}
                                    onChange={() => setHideCompletedApps(!hideCompletedApps)}
                                    color="primary"
                                  />
                                }
                                label="Hide Completed Applications"
                                className={classes.switch}
                              />
                              {!partnerView && props.userProfile.vendorContactRole !== "sales_rep" && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={leads === "self"}
                                      onChange={() => handleSetLeadsToLoad()}
                                      color="primary"
                                    />
                                  }
                                  label="Show Only My Leads"
                                  className={classes.switch}
                                />
                              )}
                            </div>
                            <div style={{ display: "flex" }}>
                              {/* <Button size="sm" color="primary" onClick={handleResetFilters} style={{ marginRight: 16 }}>
                              Clear Filters
                            </Button> */}
                              <Button color="primary" size="sm" onClick={() => handleOpenAddNewLeadModal()}>
                                <Add /> Create New
                              </Button>
                            </div>
                          </div>
                        }></TableHeader>
                      <Table
                        color="primary"
                        data={repListData}
                        noDataText={<h3>No results with current settings</h3>}
                        filtered={savedListSettings.filter}
                        defaultSorted={savedListSettings.sorting}
                        defaultPageSize={savedListSettings.pageSize}
                        pageSize={pageSizeValue}
                        page={pageNumber}
                        defaultFiltered={savedListSettings.filter}
                        onFilteredChange={e => {
                          handleSaveSettings("filter", e);
                        }}
                        onSortedChange={e => {
                          handleSaveSettings("sorting", e);
                        }}
                        onPageChange={e => {
                          setPageNumber(e);
                          handleSaveSettings("page", e);
                        }}
                        onPageSizeChange={e => {
                          handleSaveSettings("pageSize", e);
                          setPageSizeValue(e);
                          setPageNumber(0);
                        }}
                        getTrProps={(state, rowInfo, column, instance) => ({
                          style: { cursor: "pointer" },
                          onClick: e => handleVORowClick(rowInfo.original.id),
                        })}
                        columns={defaultColumns}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        customCellClasses={[classes.right]}
                        customClassesForCells={[5]}
                        className="-striped -highlight"
                      />
                    </div>
                  </Tile>
                </Grid>
              </Grid>
              <CreateNewLeadModal
                open={openAddNewLeadModal}
                handleClose={() => handleCloseAddNewLeadModal()}
                handleOpenAddNewRepModal={handleOpenAddNewLeadModal}
                dynamicsAccountId={userProfileDynamicsAccountId}
                accountId={userProfileAccountId}
                refetchLeads={refetchLeads}
              />
              <Dialog open={statusModal} onClose={() => setStatusModal(!statusModal)} fullWidth={true} maxWidth="sm">
                <div style={{ display: "flex", justifyContent: "space-between", padding: "8px 8px 0px 40px" }}>
                  <h3 style={{ marginBottom: 16 }}>Statuses Overview</h3>
                  <IconButton style={{ height: 40 }} onClick={() => setStatusModal(false)} size="large">
                    <CloseOutlined />
                  </IconButton>
                </div>
                <div style={{ padding: "0px 40px 20px" }}>
                  {statusesSel.map((status, i) => {
                    let explanation = renderStatusExpl(status.value);
                    if (explanation)
                      return (
                        <div style={{ margin: "16px 0" }} key={i}>
                          <p>
                            <b>
                              {i + 1}. {status.value}
                            </b>
                            : {explanation}
                          </p>
                        </div>
                      );
                  })}
                </div>
              </Dialog>
            </div>
          );
        }}
      </Query>
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLeadsList);
