import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "components/CustomButtons/Button.js";
import { configurableItemsSettings } from "../../../services/pcwService";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  configurationPanelTitle: {
    margin: "0 0 20px",
    fontWeight: "bold",
  },
  settingContainer: {
    marginBottom: "30px",
  },
  settingTitle: {
    fontWeight: "500",
  },
}));

export function ItemConfigurationPanel({ configurableItemSettings, onSaveFn, onCancelFn, childComponent, account }) {
  const classes = useStyles();
  const [itemConfiguration, setItemConfiguration] = useState(_.cloneDeep(configurableItemSettings.item.config));
  const itemAvailableSettings = configurableItemsSettings[configurableItemSettings.type];
  const { configurationKeysToDisplay } = configurableItemSettings;
  const settingsToDisplay = configurationKeysToDisplay // configurationKeysToDisplay can be an empty array
    ? _.filter(itemAvailableSettings.settings, s => _.includes(configurationKeysToDisplay, s.configurationKey))
    : itemAvailableSettings.settings;
  return (
    <Box>
      <h3 className={classes.configurationPanelTitle}>{itemAvailableSettings.title}</h3>
      {settingsToDisplay.map(settings => (
        <Box key={settings.configurationKey} className={classes.settingContainer}>
          <h4 className={classes.settingTitle}>{settings.label}</h4>
          {React.createElement(settings.component, {
            settings: settings,
            itemConfiguration: itemConfiguration,
            setItemConfiguration: setItemConfiguration,
            itemType: configurableItemSettings.type,
            account,
            extraData: _.get(
              configurableItemSettings,
              `extraData.dataByConfigurationKey[${settings.configurationKey}]`
            ),
          })}
        </Box>
      ))}
      {childComponent ?? null}
      <Grid container spacing={2}>
        <Grid item>
          <Button
            onClick={() =>
              onSaveFn({
                ...configurableItemSettings,
                item: {
                  ...configurableItemSettings.item,
                  config: itemConfiguration,
                },
              })
            }
            color="primary">
            Save
          </Button>
        </Grid>
        {onCancelFn && (
          <Grid item>
            <Button onClick={() => onCancelFn(configurableItemSettings)}>Cancel</Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
