import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Link, Typography } from "@mui/material";
import { DetailsCellDefaultProps } from "../../../../types";

export const ProductsCell = ({ details }: DetailsCellDefaultProps) => {
  const classes = useStyles();

  const products = useMemo(() => {
    const selectedMenuOption = details?.paymentCriteriaSelected?.menuOption?.products;
    const menuOption = details?.paymentCriteria?.menu?.menuOption?.products ?? [];

    return selectedMenuOption ?? menuOption;
  }, [details]);

  if (!products.length) {
    return (
      <Typography align="center" component="span" variant="body2">
        -
      </Typography>
    );
  }

  return (
    <Box className={classes.container}>
      {products.map((product: any) =>
        product?.aftermarketProduct?.aftermarketProductId ? (
          <Link
            target="_blank"
            variant="body2"
            key={product.proposalProductId}
            href={`/aftermarket/edit/${product?.aftermarketProduct?.aftermarketProductId}`}>
            {product.title}
          </Link>
        ) : (
          <Typography component="span" variant="body2">
            {product.title}
          </Typography>
        )
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",

    "& > a.MuiTypography-root": {
      maxWidth: "fit-content",
    },
  },
});
