import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { formatCurrency } from "utils";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    height: "100svh",
    overflowY: "scroll",
    paddingBottom: 144,
  },
  searchContainer: {
    padding: "16px 32px",
    width: "100%",
    backgroundColor: "rgb(45,45,45)",
    height: "fit-content",
    position: "absolute",
    bottom: 0,
  },
}));

export default props => {
  const classes = useStyles();
  const {
    items,
    primaryKey = "",
    fallbackPrimaryKey = "",
    secondaryKey = "",
    subPrimaryKey1 = "",
    subPrimaryKey2 = "",
    onListItemClick = function () {},
  } = props;

  const [searchVal, setSearchVal] = useState("");

  const handleSearchChange = e => {
    e.preventDefault();

    setSearchVal(e.target.value);
  };

  const matchesFilters = item => {
    return item[primaryKey]?.includes(searchVal) || item[fallbackPrimaryKey]?.includes(searchVal);
  };

  return (
    <div style={{ height: "100%" }}>
      <List className={classes.root}>
        {items?.map(item => {
          if (matchesFilters(item)) {
            return (
              <>
                <ListItem button onClick={() => onListItemClick(item)}>
                  <ListItemText
                    primary={item[primaryKey] || item[fallbackPrimaryKey] || "Unknown"}
                    secondary={moment(item[subPrimaryKey1]).format("MM/DD/yyyy") + " - " + item[subPrimaryKey2]}
                  />
                  <ListItemText primary={formatCurrency(item[secondaryKey]?.toFixed(2))} style={{ textAlign: "end" }} />
                </ListItem>
                <Divider />
              </>
            );
          }
        })}
      </List>
      <div className={classes.searchContainer}>
        <FormControl style={{ margin: 0, width: "100%" }}>
          <TextField
            size="small"
            variant="outlined"
            onChange={handleSearchChange}
            value={searchVal}
            style={{ backgroundColor: "#fff", borderRadius: 4, width: "100%" }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ opacity: searchVal.length > 0 ? 1 : 0 }}
                    disablePointerEvents={searchVal.length === 0}
                    onClick={() => setSearchVal("")}>
                    <ClearIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};
