import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import Button from "../../../../components/CustomButtons/Button";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import DragIndicator from "assets/img/drag-indicator.svg";
import TableSearch from "../../../../components/Table/TableSearch";
import { GET_CS_LIST } from "../../services/CreditSubmissions";
import { StoreOutlined } from "@mui/icons-material";
import AddDealer from "./AddDealer";

const _ = require("lodash");

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  vpStatusActive: {
    margin: "auto",
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  vpStatusBlocked: {
    margin: "auto",
    padding: "4px 8px",
    color: "#C62828",
    backgroundColor: "#FFCDD2",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  vpStatusStaged: {
    margin: "auto",
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
  vpStatusInvited: {
    margin: "auto",
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
    margin: 0,
  },
});

const useStyles = makeStyles(styles);

const GET_DEALER_LENDER_PROFILES = gql`
  query dealerLenderProfile($dynamicsUserId: ID) {
    dealerLenderProfile(dynamicsUserId: $dynamicsUserId)
  }
`;

const QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER = gql`
  query vendorProfilesByDynamicsLenderAccountId($dynamicsAccountId: ID!) {
    vendorProfilesByDynamicsLenderAccountId(dynamicsAccountId: $dynamicsAccountId) {
      id
      dynamicsId
      name
      accountId
      dynamicsAccountId
      vpStatus
    }
  }
`;

const statusTitles = {
  Active: "Dealer has successfully registered contact.",
  Invited: "Dealer contact has been invited to register to Trnsact.",
  Staged: "Dealer is connected but there are no contacts invited.",
};

const DealerManagementList = () => {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const vendorProfile = useSelector(state => state.vp);
  const searchRef = React.useRef({ prevSearch: undefined, currentSearch: "" });
  const [creditSubmissionsDealers, setCreditSubmissionsDealers] = useState([]);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [filteredDealerList, setFilteredDealerList] = useState([]);
  const [vendorProfilesAssociatedToLender, setVendorProfilesAssociatedToLender] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(20);
  const [openAddModal, setOpenAddModal] = useState(false);

  const { loading: isLoadingCSList, data: csListData, refetchCSList } = useQuery(GET_CS_LIST);

  const {
    loading: isLoadingVendorProfilesAssociatedToLender,
    data: vendorProfilesAssociatedToLenderData,
    refetch: refetchVPs,
  } = useQuery(QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER, {
    variables: {
      dynamicsAccountId: account?.dynamicsAccountId,
    },
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const filterDealerProfileList = dealerProfileList => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return dealerProfileList;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = dealerProfileList.filter(el => {
      if (typeof el.dealerName === "string") {
        return (el.dealerName || "").toLowerCase().includes(lowerCaseSearch);
      }

      return false;
    });

    return [...searchData];
  };

  useEffect(() => {
    if (csListData?.creditSubmissions) {
      const csList = csListData?.creditSubmissions;
      if (csList.length > 0) {
        let dealers = Object.entries(_.groupBy(csList, cs => cs.dealerName)).map(e => ({
          dealerName: e[0],
          dealerId: e[1][0].dealer,
          submissions: e[1],
        }));
        dealers = [
          ...dealers.map(dealer => {
            const contacts = _.groupBy(dealer.submissions, sub => sub.contactName);
            const decisions = _.groupBy(dealer.submissions, sub => sub.decision);
            let decisionsCount = Object.entries(decisions).map(e => ({
              decision: e[0],
              count: e[1].length,
            }));

            return {
              dealerName: dealer.dealerName,
              dealerId: dealer.dealerId, // dynamicsAccountId
              contactsCount: Object.keys(contacts).filter(e => e !== "null").length,
              contacts,
              decisions,
              decisionsCount,
              dealerStatus: "",
            };
          }),
        ];
        setCreditSubmissionsDealers(dealers.filter(d => d.dealerName !== "null"));
      }
    }
  }, [csListData]);

  useEffect(() => {
    let allDealers = _(vendorProfilesAssociatedToLender).concat(creditSubmissionsDealers).compact().value();
    allDealers = allDealers
      .map(vp => {
        if (creditSubmissionsDealers.find(v => v.dealerId === vp.dealerId)) {
          if (vp.dealerStatus === "") return undefined;
          let credVp = creditSubmissionsDealers.find(v => v.dealerId === vp.dealerId) || {};
          return { ...vp, ...credVp, dealerStatus: vp.dealerStatus };
        }
        return vp;
      })
      .filter(vp => vp);
    setFilteredDealerList(filterDealerProfileList(allDealers));
  }, [creditSubmissionsDealers, vendorProfilesAssociatedToLender, searchRef.current.currentSearch]);

  useEffect(() => {
    if (_.isEmpty(vendorProfilesAssociatedToLenderData)) {
      return;
    }
    const vendorProfilesAssociatedToLenderToSet = _.map(
      vendorProfilesAssociatedToLenderData.vendorProfilesByDynamicsLenderAccountId,
      vp => {
        return {
          dealerName: vp.name,
          dealerId: vp.dynamicsAccountId,
          dealerStatus: vp.vpStatus || "",
          decisions: [],
          decisionsCount: [], // will be overwritten if creditsubmissions exist
        };
      }
    );
    setVendorProfilesAssociatedToLender(vendorProfilesAssociatedToLenderToSet);
  }, [vendorProfilesAssociatedToLenderData]);

  if (isLoadingCSList || isLoadingVendorProfilesAssociatedToLender) {
    return <LinearProgress size={16} />;
  }
  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <Tile>
            <TableHeader
              filterComps={
                <>
                  <TableSearch
                    setPageNumber={setPageNumber}
                    setSavedListSettings={setSavedListSettings}
                    searchRef={searchRef}
                    savedListSettings={savedListSettings}
                  />
                </>
              }>
              {!_.isEmpty(vendorProfile) && (
                <Button color="primary" size="sm" onClick={() => setOpenAddModal(true)}>
                  <StoreOutlined />
                  Add Dealer
                </Button>
              )}
            </TableHeader>
            <Table
              color="primary"
              page={pageNumber}
              data={filteredDealerList}
              getTrProps={(state, rowInfo, column, instance) => ({
                style: { cursor: "pointer" },
                onClick: () => {
                  console.log(rowInfo.original);
                  if (rowInfo.original?.dealerId) {
                    window.location.href = `/dealers/${rowInfo.original.dealerId}`;
                  }
                },
              })}
              columns={[
                {
                  Header: "Dealer Name",
                  accessor: "dealerName",
                  Cell: props => props.value.replace("VP - ", ""),
                },
                {
                  Header: "Status",
                  accessor: "dealerStatus",
                  id: "dealerStatus",
                  Cell: props => {
                    console.log(props.value);
                    let status = props.value?.charAt(0).toUpperCase() + props.value?.slice(1) || "";
                    return (
                      <div title={statusTitles[status]} className={classes[`vpStatus${status}`]}>
                        {status}
                      </div>
                    );
                  },
                },
                {
                  Header: "Transactions Stats.",
                  accessor: "decisionsCount",
                  Cell: props => (
                    <div>
                      {props.value.map((el, key) => {
                        return (
                          <p key={key}>
                            {el?.decision}
                            {": "}
                            <strong>{el?.count}</strong>
                          </p>
                        );
                      })}
                    </div>
                  ),
                  filterable: false,
                  sortable: false,
                },
              ]}
              onPageChange={changePage => {
                setPageNumber(changePage);
              }}
              onPageSizeChange={changePageSize => {
                setPageSizeValue(changePageSize);
                setPageNumber(0);
              }}
              pageSize={pageSizeValue}
              defaultPageSize={savedListSettings.pageSize}
              showPaginationTop={false}
              showPaginationBottom={true}
              customCellClasses={[classes.right]}
              customClassesForCells={[5]}
              className=" -highlight"
            />
          </Tile>
        </Grid>
      </Grid>
      <AddDealer open={openAddModal} handleClose={() => setOpenAddModal(false)} refetchVPs={refetchVPs} />
    </div>
  );
};

export default DealerManagementList;
