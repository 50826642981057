import React from "react";
import { Box, Modal } from "@mui/material";
import { ModalsKeys } from "../../../../../global";
import { useModal } from "../../../../../hooks/useModal";
import { ProductCatalogDetails } from "./ProductCatalogDetails";

export const ProductCatalogDialog = () => {
  const { isOpen, handleClose, data } = useModal(ModalsKeys.ProductCatalogDialog);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%", // Customize the width
          height: "80%", // Customize the height
          backgroundColor: "#fff", // Ensure the background is not transparent
          border: "none", // Remove the solid border
          borderRadius: "12px", // Add rounded corners
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Modern shadow for depth
          padding: "24px", // Increase padding for a cleaner look
          overflowY: "auto", // To handle large content
        }}>
        <ProductCatalogDetails
          proposalProduct={data?.proposalProduct}
          productConfiguration={data?.productConfiguration}
        />
      </Box>
    </Modal>
  );
};
