import { gql } from "@apollo/client";

export const UPSERT_TASK = gql`
  mutation ($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

export const UPDATE_CREDIT_APPLICATION_V2_QUERY = gql`
  mutation ($creditAppId: ID!, $input: UpdateCreditApplicationV2Input!) {
    updateCreditApplicationV2(creditAppId: $creditAppId, input: $input)
  }
`;

export const UPDATE_ACCOUNT_QUERY = gql`
  mutation ($dynamicsId: ID, $fieldsObj: UpdateAccountFields) {
    updateAccount(dynamicsId: $dynamicsId, fieldsObj: $fieldsObj)
  }
`;

export const UPDATE_ACCOUNT_CRM_QUERY = gql`
  mutation ($entityId: ID!, $fieldsObj: UpdateAccountCRMFields) {
    updateAccountCRM(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;

export const ASSIGN_SALES_REP = gql`
  mutation ($fieldsObj: AssignSalesRepInput!, $entityId: ID!, $VOId: ID!) {
    assignSalesRep(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

export const UPDATE_DYNAMICS_VO_LOCATION = gql`
  mutation ($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

export const UPDATE_VO_LOCATION = gql`
  mutation ($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

export const DELETE_REFERENCE = gql`
  mutation ($referenceId: String!) {
    deleteReference(referenceId: $referenceId)
  }
`;

export const GET_LOCATIONS = gql`
  query locations($accountId: ID) {
    locations(accountId: $accountId) {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
    }
  }
`;

export const GET_SALES_REPS_FOR_ADMIN = gql`
  query ($accountId: ID) {
    salesRepsForAdmin(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
    }
  }
`;
