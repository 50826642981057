import { ReactNode } from "react";
import PageOpportunityDetails from "./PageOpportunityDetails";
import { ModalsKeys } from "../../../global";
import { FinanceProgramModal, PreviewFinanceProgramModal } from "../../../modules/financeProgram";
import { ModalsContainer } from "../../../components/shared/Modals";

export const PageOpportunityDetailsContainer = ({ match }: any) => {
  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.FinanceForm]: <FinanceProgramModal />,
    [ModalsKeys.PreviewFinanceForm]: <PreviewFinanceProgramModal />,
  };

  return (
    <>
      <PageOpportunityDetails match={match} />
      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </>
  );
};
