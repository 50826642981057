import { gql } from "@apollo/client";

export const TRIGGER_CREDIT_REPORT_CONSUMER_PULL = gql`
  mutation PullConsumerCreditReport($input: TriggerCreditReportConsumerPullInput!) {
    triggerCreditReportConsumerPull(input: $input) {
      creditReportConsumerId
      externalReportId
      vendorOpportunityId
      ownerPgDynamicsId
      ownerPgId
      cbrDynamicsId
      reportType
      score
      machineReadableReport {
        bureauType
        scoreType
        creditBureauType
        humanReadableReportDocumentId
        ownerPgId
        fields {
          key
          value
        }
        ficoScore
        publicRecordCount
        pastAmountDue
        totalInquiryCount
        satisfactoryAccountsCount
        installmentBalance
        monthlyPayment
        inquiryCountLastSixMonths
        derogAccountsCount
        realEstateBalance
        realEstatePayment
        tradelineCount
        pastDerogAccountsCount
        collectionRecordsCount
        revolvingAvailPercent
        paidAccountsCount
        oldestTradeline
        bankruptcyRecordCount
        revolvingBalance
        revolvingTotalCredit
        errorDescription
      }
      humanReadableReportDocumentId
      errorDescription
      createdDateTime
    }
  }
`;
