export function sortEntitiesByModifiedDate<Entity extends any = any>(arrayOfEntities: Entity[] | undefined): Entity[] {
  if (!arrayOfEntities || !Array.isArray(arrayOfEntities)) return [];

  return arrayOfEntities.toSorted((optionA, optionB) => {
    if (
      typeof optionA === "object" &&
      optionA &&
      "modifiedDateTime" in optionA &&
      typeof optionA.modifiedDateTime === "string" &&
      typeof optionB === "object" &&
      optionB &&
      "modifiedDateTime" in optionB &&
      typeof optionB.modifiedDateTime === "string"
    ) {
      const dateB = new Date(optionA.modifiedDateTime).getTime();
      const dateA = new Date(optionB.modifiedDateTime).getTime();

      return dateB - dateA;
    }

    return 1;
  });
}
