import { SET_CREDIT_APP } from "./types";

const initialState = {};

export const creditAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDIT_APP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const contacts = state =>
  [...state.creditApp.creditApplicationOwner, state.creditApp?.primaryContact].filter(Boolean).map(owner => ({
    ...owner,
    phone: owner?.phone || owner?.mobilePhone || owner?.homePhone || owner?.workPhone,
    firstAndLastName: `${owner.firstName} ${owner.lastName}`,
  }));

const contact = (state, ownerId) => state.creditApp.creditApplicationOwner?.find(owner => owner.ownerPgId === ownerId);

const contactSelectOptions = state => {
  const allContacts = [
    state?.creditApp?.primaryContact,
    ...(state?.creditApp?.creditApplicationOwner ?? []),
    ...(state?.creditApp?.creditApplicationContacts ?? []),
  ];

  return allContacts.reduce((options, owner) => {
    if (!owner || options.map(option => option.value).includes(owner.ownerPgId)) return options;

    options.push({
      label: owner.title
        ? `${owner.firstName} ${owner.lastName}, ${owner.title}`
        : `${owner.firstName} ${owner.lastName}`,
      value: owner.ownerPgId,
    });

    return options;
  }, []);
};

const creditAppSelectors = {
  contact,
  contacts,
  contactSelectOptions,
};

export { creditAppSelectors };
