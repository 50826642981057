import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SaveIcon from "@mui/icons-material/Save";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AddEditMenuTemplateUrlParams, MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useSavingMenu } from "modules/aftermarketMenuConstructor/hooks/useSavingMenu";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { useNotifications } from "modules/notification";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { CommonDataContext } from "contexts/CommonDataContext";

export const HeaderCard = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isAftermarketPartner } = useContext(CommonDataContext);
  const { handleSubmit } = useFormContext<MenuTemplateGeneralFormInputs>();

  const isLoading = useSelector(menuConstructorSelectors.isLoading);
  const initialProposalMenu = useSelector(menuConstructorSelectors.initialProposalMenu);
  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu);

  const [isSaveMenuConfirmationModalOpen, setIsSaveMenuConfirmationModalOpen] = useState<boolean>(false);

  const { hasWriteAccess } = useAftermarketPermissions();
  const { showNotification } = useNotifications();
  const { createMenu, updateMenu, isLoading: isSavingMenuLoading } = useSavingMenu();

  const { handleOpen: handleSaveAsModalOpen } = useModal(ModalsKeys.AftermarketSaveAsDialog);
  const { handleOpen: handleShareMenuConfirmationModalOpen } = useModal(
    ModalsKeys.AftermarketShareMenuConfirmationDialog
  );

  const { proposalMenuId } = useParams<AddEditMenuTemplateUrlParams>();
  const isEditing = !!proposalMenuId;

  useEffect(() => {
    dispatch(menuConstructorActions.setIsLoading(isSavingMenuLoading));
  }, [isSavingMenuLoading]);

  const handleSaving = async (savingFn: any, proposalMenu: Partial<ProposalMenu>) => {
    try {
      const result = await savingFn(proposalMenu);
      if (!result.success) {
        throw "Error";
      }
      showNotification("Menu Saved!");
      history.push(`/aftermarket/menu`);
    } catch (err) {
      console.error(err);
      showNotification("Error on Menu Saving", { type: "error" });
    }
  };

  const handleSaveClick: SubmitHandler<MenuTemplateGeneralFormInputs> = async formData => {
    const savingFn = isEditing ? updateMenu : createMenu;
    if (isAftermarketPartner && formData.linkedToCriterias.length) {
      handleShareMenuConfirmationModalOpen({ onSave: () => handleSaving(savingFn, proposalMenu), formData });
    } else {
      await handleSaving(savingFn, proposalMenu);
    }
  };

  const handleSaveAsClick: SubmitHandler<MenuTemplateGeneralFormInputs> = formData => {
    handleSaveAsModalOpen({ onSave: (proposalMenu: ProposalMenu) => handleSaving(createMenu, proposalMenu) });
  };

  const onSaveMenuConfiramtionModalClose = (event: any, reason: string) => {
    if (["backdropClick", "escapeKeyDown"].includes(reason)) {
      return;
    }
    setIsSaveMenuConfirmationModalOpen(false);
    if (event.result === "cancel") {
      // means "Discard Changes" in our context
      history.push(`/aftermarket/menu`);
    }
  };

  const handleBackClick = () => {
    const hasUnsavedChanges = JSON.stringify(initialProposalMenu) !== JSON.stringify(proposalMenu);
    if (hasUnsavedChanges) {
      setIsSaveMenuConfirmationModalOpen(true);
      return;
    }
    history.push(`/aftermarket/menu`);
  };

  return (
    <>
      <CardContainer
        title={
          <Box display="flex" alignItems="center">
            <ChevronLeftIcon className={classes.chevronLeftIcon} fontSize="large" onClick={handleBackClick} />
            <Typography component="span" variant="h6">
              {!isEditing ? "Add" : "Edit"} Menu Template
            </Typography>
          </Box>
        }
        containerClassName={classes.addEditContainerClassName}
        actionBtn={
          hasWriteAccess &&
          !isLoading && (
            <Box>
              {isEditing && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleSubmit(handleSaveAsClick)}
                  style={{ marginRight: 8 }}>
                  Save As
                </Button>
              )}
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSubmit(handleSaveClick)}>
                Save
              </Button>
            </Box>
          )
        }
      />

      <ConfirmationDialog
        title="Confirmation"
        messageLayout={() => (
          <>
            <Typography>Are you sure you want to leave the Menu Constuctor?</Typography>
            <Typography>Any unsaved changes will be lost.</Typography>
          </>
        )}
        okButtonText="Cancel"
        cancelButtonText="Discard Changes"
        open={isSaveMenuConfirmationModalOpen}
        onClose={onSaveMenuConfiramtionModalClose}
      />
    </>
  );
};

const useStyles = makeStyles({
  chevronLeftIcon: {
    color: "black",
    marginRight: 6,
    cursor: "pointer",
  },
  addEditContainerClassName: {
    gap: 0,
  },
});
