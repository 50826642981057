import React from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { ProposalsTableDataResponse, ProposalsTableDataVariables } from "../../../api/types";
import { AftermarketPartnerProposalsTable } from "modules/proposals/ui/tables/aftermarketPartnerProposals/AftermarketPartnerProposalsTable";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { GET_PARTNERS_PROPOSALS } from "modules/aftermarketPartner/api";

const config = require("../../../../../config");

export const ProposalsPage = () => {
  const account = useSelector((state: any) => state.account);

  const { data, loading } = useQuery<ProposalsTableDataResponse, ProposalsTableDataVariables>(GET_PARTNERS_PROPOSALS, {
    skip: !account.id,
    variables: { accountId: account.id },
  });

  const getProposalLink = (proposal: Proposal) => {
    window.open(
      `https://app${config.environmentName === "dev" ? ".dev" : ""}.trnsact.com/ps/?vendorGUID=${
        proposal.account?.dynamicsAccountId
      }&proposalId=${proposal.id}&viewOnly=true`,
      "_blank"
    );
  };

  const handleGoToProposal = (proposal: Proposal) => {
    window.open(`/proposalDetail/${proposal.id}?from=/proposals`, "_blank");
  };

  return (
    <CardContainer title="Proposals">
      <AftermarketPartnerProposalsTable
        loading={loading}
        onGoToProposal={handleGoToProposal}
        onGoToProposalDetail={getProposalLink}
        tableData={(data?.partnersProposals as any) ?? []}
      />
    </CardContainer>
  );
};
