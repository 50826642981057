import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SyntaxHighlighter from "react-syntax-highlighter";
import financeButtonImg from "assets/img/wizards/finance_button_example.png";
import financeButtonImg2 from "assets/img/wizards/finance_button_example_2.png";

const config = require("../../../config");

const ProductWidgetInstruction = () => {
  let useStyles = makeStyles({
    mainContainer: {
      minHeight: "800px",
    },
    imgBig: {
      maxWidth: "30vw",
      borderRadius: "10px",
      margin: "20px auto",
    },
    imgButton: {
      maxWidth: "30vw",
      borderRadius: "10px",
      margin: "20px auto",
    },
    linkExample: {
      padding: "5px",
      display: "block",
      margin: "5px 0",
      overflowWrap: "break-word",
    },
    linkHighlighted: {
      fontWeight: "bolder",
    },
    instructions: {
      "& > li": {
        padding: "5px",
        fontWeight: 400,
      },
    },
  });
  let classes = useStyles();

  const account = useSelector(state => state.account);

  return (
    <Grid container spacing={4}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography>
          Show your customers that financing is available on your product pages. Dynamically calculate a monthly payment
          based on the product price and representative interest rate. Clicking on the product widget takes visitors to
          your Secure Credit Application with the item’s price passed through.
        </Typography>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justifyContent="space-evenly">
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <p>Financing button example:</p>
            <img src={financeButtonImg} className={classes.imgButton} />
            <img src={financeButtonImg2} className={classes.imgBig} />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Typography variant="h5">Implementation Guide</Typography>
            <ol className={classes.instructions}>
              <li>First you need to identify the place where the product is rendered</li>
              <li>
                Once you have identified the source code that generates the content of the product offered, you need to
                identify the exact place for the Price element, for example:
                <SyntaxHighlighter language="xml">
                  {`<html>
  <body>
    <!-- Product Description Section -->
    <div class="equipment-desc">
       Welder 180AMPS
    </div>
    <div class="price-section">
       <span class="price regular-price">$4,950.00</span>   
       <span class="price price-withoutTax">$3,610.00</span>         
    </div>
    ...
  </body>
</html>`}
                </SyntaxHighlighter>
              </li>
              <li>
                Now close to that element you can place a new button with a link to your Secure Credit Application:
                <SyntaxHighlighter language="xml">
                  {`<html>
  <body>
    <!-- Product Description Section -->
    <div class="equipment-desc">
       Welder 180AMPS
    </div>
    <div class="price-section">
       <span class="price regular-price">$4,950.00</span>   
       <span class="price price-withoutTax">$3,610.00</span>         
    </div>
    <div class="dcr-finance-button">
        <button type="button" 
        onClick="javascript:window.open('${config.REACT_APP_OCA_BASE_URL}/?vendorGUID=${account.dynamicsAccountId}
        &price=3610.00&equipmentDescription=${encodeURI("Welder 180AMPS")}');">
            Finance as low as 61.90/mo
        </button>
    </div>
    ...
  </body>
</html>`}
                </SyntaxHighlighter>
                The amount calculated for financing can obtained with the following formula, example written in
                JavaScript:
                <SyntaxHighlighter language="javascript">
                  {`
const calculatePrice = (price) => {
   return (parseFloat(price) * 0.02339).toFixed(2);
};`}
                </SyntaxHighlighter>
              </li>
              <li>
                What's most important here is the URLs parameters: <b>VendorGUID, Price & Equipment Description</b>.
                Your VendorGUID is unique for your company and the price needs to be provisioned on the link in order
                for us to fill the amount requested on the form for you:
                <span className={classes.linkExample}>
                  {config.REACT_APP_OCA_BASE_URL}/?vendorGUID=
                  <span className={classes.linkHighlighted}>{account.dynamicsAccountId}</span>&
                  <span className={classes.linkHighlighted}>price=3610.00</span>&
                  <span className={classes.linkHighlighted}>equipmentDescription=Welder%20180AMPS</span>
                </span>
              </li>

              <li>
                <p>
                  <b>Equipment Data</b>: We also enable the following URL paremeters to capture precise information
                  around the equipment offered, here's a list of accepted keys:
                </p>
                <ul>
                  <li>
                    <b>equipmentPictureUrl</b> (URL string with a picture of the Equipment)
                  </li>
                  <li>
                    <b>equipmentYear</b> (Full year: YYYY format)
                  </li>
                  <li>
                    <b>equipmentSN</b> (Equipment Serial Number / VIN e.g: YN2321JSXS)
                  </li>
                  <li>
                    <b>equipmentHours</b> (Integer number)
                  </li>
                  <li>
                    <b>equipmentCondition</b> (Values accepted are 'used' or 'new')
                  </li>
                  <li>
                    <b>equipmentMake</b> (Manufacturer's name only)
                  </li>
                  <li>
                    <b>equipmentModel</b> (Equipment's Model e.x Raptor 150)
                  </li>
                  <li>
                    <b>equipmentCity</b> (City name)
                  </li>
                  <li>
                    <b>equipmentPostalCode</b> (Postal Code)
                  </li>
                  <li>
                    <b>equipmentState</b> (State Code, e.g: CA, FL, etc)
                  </li>
                </ul>
                <p>
                  - Full URL example:
                  <span className={classes.linkExample}>
                    {config.REACT_APP_OCA_BASE_URL}/
                    <span className={classes.linkHighlighted}>?vendorGUID={account.dynamicsAccountId}</span>&
                    <span className={classes.linkHighlighted}>price=3610.00</span>&
                    <span className={classes.linkHighlighted}>equipmentDescription=Chevy%20S10</span>&
                    <span className={classes.linkHighlighted}>
                      equipmentPictureUrl=https://www.youcompany.com/content/uploads/equipment/truck-318x212.jpg
                    </span>
                    &<span className={classes.linkHighlighted}>equipmentYear=2020</span>&
                    <span className={classes.linkHighlighted}>equipmentSN=YN2321JSXS</span>&
                    <span className={classes.linkHighlighted}>equipmentSN=YN2321JSXS</span>&
                    <span className={classes.linkHighlighted}>equipmentHours=10000</span>&
                    <span className={classes.linkHighlighted}>equipmentCondition=used</span>&
                    <span className={classes.linkHighlighted}>equipmentMake=Chevrolet</span>&
                    <span className={classes.linkHighlighted}>equipmentModel=S104x4DOHC</span>&
                    <span className={classes.linkHighlighted}>equipmentCity=San%20Diego</span>&
                    <span className={classes.linkHighlighted}>equipmentPostalCode=2882</span>&
                    <span className={classes.linkHighlighted}>equipmentState=CA</span>
                  </span>
                </p>
              </li>
            </ol>
            <Typography>
              <ContactSupportIcon color="primary" fontSize="small" /> If your company has no webmaster/developer we can
              assist you in the process by creating a one that best match your site. Send an email to{" "}
              <a href="mailto:support@trnsact.com">Support</a> with the 'Subject Website Integration Widget' and request
              the feature.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductWidgetInstruction;
