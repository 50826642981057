import { Markup, ProposalSaleType } from "@trnsact/trnsact-shared-types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";
import { CreateProposalVariables } from "../api/types";
import {
  AftermarketMenu,
  FinanceProgramFormValues,
  MarkupMethods,
  OptionName,
  ProductConfig,
  ProductId,
  SaleType,
  SendVia,
  Structures,
  Term,
} from "../types";
import { Nullable } from "global";

interface Options {
  voId: string;
  isDraft: boolean;
  formData: FinanceProgramFormValues;
  menuByTerms: Record<Term, AftermarketMenu>;
  productsConfigInMenu: Record<OptionName, Record<ProductId, ProductConfig>>;
}

export function collectVariablesForCreateProposal({
  voId,
  isDraft,
  formData,
  menuByTerms,
  productsConfigInMenu,
}: Options): CreateProposalVariables {
  const {
    financeQuote: {
      fee,
      terms,
      rateType,
      commonRate,
      programName,
      daysToFirstPayment,
      specifyRate,
      specifyRates,
      advancePayment,
      markupRate,
      residual,
      paymentCalculationMethod,
      programType,
      structure,
      markupMethod,
    },
    saleDetails: { financeAmount, saleType, downPayment },
    financeProgramDescription,
    submission: { maxApprovalAmount },
  } = formData;

  let dealerMarkup: Nullable<Markup> = null;

  if (formData.financeQuote.structure !== Structures.None) {
    if (formData.financeQuote.markupMethod === MarkupMethods.MarkupPercentage) {
      dealerMarkup = { identifier: "Dealer Markup", markupPercentage: Number(formData.financeQuote.markupRate) };
    } else if (formData.financeQuote.markupMethod === MarkupMethods.MarkupDollar) {
      dealerMarkup = { identifier: "Dealer Markup", markupFlat: Number(formData.financeQuote.markupRate) };
    }
  }

  const collectedData = {
    input: {
      sendProposalData: !isDraft
        ? {
            sendViaEmail: [SendVia.Email, SendVia.TextAndEmail].includes(formData.sendVia.type),
            sendViaText: [SendVia.Text, SendVia.TextAndEmail].includes(formData.sendVia.type),
            phoneNumber: formData.customer.phone,
          }
        : null,
      vendorOpportunityId: voId,
      proposalMenuId: formData.financeQuote?.menu,
      financeProgramId: formData.financeQuote?.program,
      saleType: determineCreateType({ saleType, isMenuExist: !!Object.keys(menuByTerms).length }),
      settings: {
        view: {
          isShowProductDetails: formData.displaySettings.productDetails,
          isShowProductPrice: formData.displaySettings.productPrice,
        },
      },
      paymentOptions:
        saleType === SaleType.Financing
          ? {
              finance: {
                saleType: determineCreateType({ saleType, isMenuExist: !!Object.keys(menuByTerms).length }),
                salesTaxPercentage: 0,
                numberOfAdvancePayments: Number(advancePayment) ?? 0,
                financeProgramType: programType.toUpperCase(),
                rateType,
                financeProgramName: programName,
                amountFinanced: financeAmount,
                downPaymentAmount: Number(downPayment) ?? 0,
                residualPercentage: Number(residual) ?? 0,
                daysToFirstPayment: Number(daysToFirstPayment),
                startDate: new Date(formData.financeQuote.startDate).toISOString().split("T")[0],
                menu: prepareMenu(
                  menuByTerms,
                  financeProgramDescription,
                  formData.financeQuote.menu,
                  productsConfigInMenu
                ),
                customerRate:
                  structure !== Structures.None && markupMethod === MarkupMethods.CustomerRate
                    ? Number(markupRate)
                    : null,
                dealerMarkup,
                paymentPeriodTiming: paymentCalculationMethod,
                markups: [],
                terms: prepareRates({ specifyRate, commonRate, specifyRates, terms }),
                flatFees: fee.map(feeValue => ({
                  identifier: feeValue.identifier,
                  financed: feeValue.financed,
                  fee: Number(feeValue.fee),
                })),
                maxApprovalAmount,
              },
            }
          : {
              cash: {
                amountFinanced: financeAmount,
                flatFees: fee.map(feeValue => ({
                  identifier: feeValue.identifier,
                  financed: feeValue.financed,
                  fee: Number(feeValue.fee),
                })),
                menu: prepareMenu(
                  menuByTerms,
                  financeProgramDescription,
                  formData.financeQuote.menu,
                  productsConfigInMenu
                ),
                saleType: ProposalSaleType.CashWithAftermarketMenu,
              },
            },
    },
  };
  return collectedData;
}

function determineCreateType(options: { saleType: SaleType; isMenuExist: boolean }): ProposalSaleType {
  if (!options.isMenuExist) return ProposalSaleType.FinanceOnly;

  return options.saleType === SaleType.Cash
    ? ProposalSaleType.CashWithAftermarketMenu
    : ProposalSaleType.FinanceWithAftermarketMenu;
}

function prepareMenu(
  menuByTerms: Record<Term, AftermarketMenu> = {},
  financeProgramDescription: string,
  menuId: string = "",
  productsConfigInMenu: Record<OptionName, Record<ProductId, ProductConfig>>
) {
  const menuArr = Object.values(menuByTerms);

  if (!menuArr.length) return null;

  return {
    name: menuArr[0].name,
    proposalMenuId: menuId,
    menuOptions: prepareMenuOptionsForAPI(menuArr[0]?.menuOptions, financeProgramDescription, productsConfigInMenu),
  };
}

function prepareRates({
  terms,
  specifyRate,
  commonRate,
  specifyRates,
}: Pick<FinanceProgramFormValues["financeQuote"], "specifyRates" | "commonRate" | "specifyRate" | "terms">) {
  if (!specifyRate) return terms.map(term => ({ term: Number(term), rate: Number(commonRate) }));
  return specifyRates.map(({ rate, term }) => ({ term: Number(term), rate: Number(rate) }));
}
