import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Pricing, SaleType } from "../../../types";
import { ProfitDetailsContainer } from "./ProfitDetailsContainer";
import { ProfitSummaryContainer } from "./ProfitSummaryContainer";

interface Props {
  payment: Pricing;
  saleType: SaleType;
}

export const PaymentOptions = ({ payment, saleType }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.summary}>
      <ProfitDetailsContainer payment={payment} saleType={saleType} />
      <ProfitSummaryContainer payment={payment} saleType={saleType} />
    </Box>
  );
};

const useStyles = makeStyles({
  summary: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    "& .profitSummary": {
      display: "flex",
      flexDirection: "column",
    },

    "& .profitSummaryContent": {
      "& .summaryList": {
        "& .summaryListItem": {
          gap: "0.75rem",
          display: "flex",
          padding: "0.5rem 0",
          alignItems: "center",
          justifyContent: "flex-start",

          "& > *": {
            flex: 1,
          },
        },
      },

      "& .totalProfit": {
        gap: "0.75rem",
        display: "flex",
        padding: "0.75rem 0",
        alignItems: "center",
        borderTop: "2px solid black",
        justifyContent: "flex-start",

        "& > *": {
          flex: 1,
        },
      },
    },

    "& .summaryListItem:not(:last-child)": {
      borderBottom: "1px solid #ccc",
    },
  },
});
