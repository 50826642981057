export const usStates = [
  { value: "AK", label: "AK" },
  { value: "AL", label: "AL" },
  { value: "AR", label: "AR" },
  { value: "AZ", label: "AZ" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DC", label: "DC" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "IA", label: "IA" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "MA", label: "MA" },
  { value: "MD", label: "MD" },
  { value: "ME", label: "ME" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MO", label: "MO" },
  { value: "MS", label: "MS" },
  { value: "MT", label: "MT" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "NE", label: "NE" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NV", label: "NV" },
  { value: "NY", label: "NY" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VA", label: "VA" },
  { value: "VT", label: "VT" },
  { value: "WA", label: "WA" },
  { value: "WI", label: "WI" },
  { value: "WV", label: "WV" },
  { value: "WY", label: "WY" },
];

export const configurableItemsTypes = {
  // CADM
  section: "section",
  block: "block",
  field: "field",
  modal: "modal",
  // Access Control
  scorecard: "scorecard",
  leads: "leads",
  opportunities: "opportunities",
  userManagement: "userManagement",
  securityManagement: "securityManagement",
  aftermarket: "aftermarket",
  healthCheck: "healthCheck",
  creditReports: "creditReports",
  documents: "documents",
  documentsByTypeSettings: "documentsByTypeSettings",
  portalSetup: "portalSetup",
  voManagement: "voManagement",
  prescreen: "prescreen",
  submissionsHubManagement: "submissionsHubManagement",
};

export const portalConfigurationTypes = {
  CADMCommercial: "CREDIT_APPLICATION_DETAIL_MODAL_COMMERCIAL",
  CADMIndividual: "CREDIT_APPLICATION_DETAIL_MODAL_INDIVIDUAL",
  scorecard: "SCORECARD",
  leads: "LEADS",
  opportunities: "OPPORTUNITIES",
  userManagement: "USER_MANAGEMENT",
  securityManagement: "SECURITY_MANAGEMENT_MFA",
  aftermarket: "AFTERMARKET",
  healthCheck: "HEALTH_CHECK",
  creditReports: "CREDIT_REPORTS",
  documents: "DOCUMENTS",
  portalSetup: "PORTAL_SETUP",
  voChecklistTemplate: "VO_CHECKLIST_TEMPLATE",
  voManagement: "VO_MANAGEMENT",
  prescreen: "PRESCREEN_MANAGEMENT",
  submissionsHubManagement: "SUBMISSIONS_HUB_MANAGEMENT",
};

export const pcwMovingElementActionTypes = {
  move: "move",
  merge: "merge",
};

export const lenderTypesSettings = [
  { code: 100, title: "Bank", letter: "A", color: "#458e46" },
  { code: 200, title: "Captive", letter: "A+", color: "#458e46" },
  { code: 300, title: "Non-Bank Lender", letter: "B", color: "#eeba45" },
  { code: 400, title: "Broker", letter: "C", color: "#8f1b14" },
];

export const ocaLegacyReferenceFields = [
  "primaryBankName",
  "primaryBankContactName",
  "primaryBankContactEmail",
  "primaryBankPhone",
  "primaryBankAccountNum",
  "primaryBankAverageBalance",
  "loanReferenceBankName",
  "loanReferenceName",
  "loanReferenceContactName",
  "loanReferenceMonthlyPayment",
  "loanReferenceContactEmail",
  "primaryCreditName",
  "primaryCreditContactName",
  "primaryCreditContactEmail",
  "primaryCreditPhone",
  "primaryCreditAccountNum",
  "locReferenceCreditLimit",
  "locReferenceCurrentBalance",
  "locReferenceMonthlyPayment",
  "locReferenceRenewalDate",
  "locReferenceLineOfCredit",
  "tradeReferenceName",
  "tradeReferenceContactName",
  "tradeReferenceContactEmail",
  "tradeReferencePhone",
  "tradeReferenceFax",
  "tradeReferenceAccountNum",
  "percRevenue",
  "tradeReferenceYears",
  "tradeReferenceMonths",
  "tradeReferenceName2",
  "tradeReferenceContactName2",
  "tradeReferenceContactEmail2",
  "tradeReferencePhone2",
  "tradeReferenceFax2",
  "tradeReferenceAccountNum2",
  "percRevenue2",
  "tradeReferenceYears2",
  "tradeReferenceMonths2",
  "tradeReferenceName3",
  "tradeReferenceContactName3",
  "tradeReferenceContactEmail3",
  "tradeReferencePhone3",
  "tradeReferenceFax3",
  "tradeReferenceAccountNum3",
  "percRevenue3",
  "tradeReferenceYears3",
  "tradeReferenceMonths3",
];

export const pocFields = [
  "firstName",
  "lastName",
  "middleName",
  "nameSuffix",
  "relationship",
  "dlStateIssued",
  "driverLicenseExp",
  "driverLicenseNum",
  "homeAddress",
  "homeCity",
  "address2",
  "homeState",
  "homePostalCode",
  "homePhone",
  "addressCounty",
  "homeType",
  "timeAtAddressYears",
  "timeAtAddressMonths",
  "employerName",
  "timeAtJobYears",
  "timeAtJobMonths",
  "employmentStatus",
  "employerAddress",
  "employerAddress2",
  "employerCity",
  "employerState",
  "employerZip",
  "grossMonthlyIncome",
  "employerPhone",
  "address",
  "phone",
  "city",
  "state",
  "postalCode",
  "email",
  "percOwner",
  "yearsWithCdl",
  "yearsAsOwnerOp",
  "mobilePhone",
  "ssn",
  "netWorth",
  "monthlyHousingPayment",
  "uploadDLQuestion",
  "dateOfBirth",
  "signature",
  "signatureDate",
  "employerEmail",
  "title",
  "countryOfResidence",
  "ownershipType",
  "usCitizen",
  "commercialDriverMonths",
  "commercialDriverYears",
  "residentStatus",
  "providingGuaranty",
  "maritalStatus",
  "companyDriverYears",
  "ownerOperatorYears",
  "firstTimeFinancing",
  "nearestRelativeFirstName",
  "nearestRelativeLastName",
  "nearestRelativePhone",
  "nearestRelativeCity",
  "nearestRelativeZip",
  "prevAddress",
  "prevAddress2",
  "prevCity",
  "prevState",
  "prevCounty",
  "prevPostalCode",
  "reasonForDeclining",
  "personalGuaranteeSignature",
  "personalGuaranteeSignatureDate",
  "addendum",
];

export const fieldsToNotPopulateFromPocForBusinessApp = [
  "address",
  "address2",
  "city",
  "state",
  "postalCode",
  "prevAddress",
  "prevAddress2",
  "prevCity",
  "prevState",
  "prevPostalCode",
  "usCitizen", //do not consider as poc field for Business app because it should map on credit_application record (there are different column types in owners and credit_application tables)
];

//export const signatureDateKeys = ["signatureDate", "personalGuaranteeSignatureDate"];
export const signatureDateKeys = [
  "signatureDate",
  "personalGuaranteeSignatureDate",
  "primaryContact.signatureDate",
  "primaryContact.personalGuaranteeSignatureDate",
];

export const creditAppTypes = {
  commercial: "COMMERCIAL",
  commercialWithGuarantor: "COMMERCIAL_WITH_GUARANTOR",
  individual: "INDIVIDUAL",
  individualWithSecondGuarantor: "INDIVIDUAL_WITH_SECOND_GUARANTOR",
};

export const ocaSectionTypes = {
  legacyReference: "references",
  dynamicReference: "dynamic-reference",
  equipment: "equipment",
  coApplicant: "co-applicant",
  dynamicSignatures: "dynamic-signatures",
  documentSign: "document-sign",
  personalGuaranteeSignatures: "personal-guarantee-signatures",
  common: "common", // used for non typed sections
  marketingCommunication: "marketing-communication",
};

export const referenceTypes = {
  customer: "CUSTOMER",
  trade: "TRADE",
  bank: "BANK",
  loan: "LOAN",
  loc: "LOC",
  insurance: "INSURANCE",
  employment: "EMPLOYMENT",
  employmentPast: "EMPLOYMENT_PAST",
  employmentFuture: "EMPLOYMENT_FUTURE",
  corporateGuarantor: "CORPORATE_GUARANTOR",
};

export const equipmentTypes = {
  purchase: "PURCHASE",
  tradeIn: "TRADE_IN",
  viewOnly: "VIEW_ONLY",
};

export const offersTypes = {
  workingCapital: "WORKING_CAPITAL",
};

export const applicantBlockTypes = {
  contactInformation: "contact-information",
  currentAddress: "current-address",
  previousAddress: "previous-address",
  employmentInformation: "employment-information",
  nearestRelative: "nearest-relative",
  identityVerification: "identity-verification",
};

export const coApplicantBlockTypes = {
  contactInformation: "contact-information",
  currentAddress: "current-address",
  previousAddress: "previous-address",
  employmentInformation: "employment-information",
  nearestRelative: "nearest-relative",
  identityVerification: "identity-verification",
};

export const adminRoles = {
  super: "super",
  singleAccountOnly: "single_account_only",
};

export const ocaUploadFileTypes = [
  {
    value: "Financial",
    label: "Financial Doc",
  },
  {
    value: "tax returns",
    label: "Tax Returns",
  },
  {
    value: "bank statements",
    label: "Bank Statements",
  },
  {
    value: "other",
    label: "Other",
  },
];

// doc_type in DB is value for some reason. So added a key to be able to manipulate with docs data
export const { documentsByKey } = require("../../constants");

export const floatNumberRegexp = /^\$?([\d,.]*)?$/;

export const vendorProfileDemoLogoPath = "vendor-profiles/DemoCompanyLogo.jpg";

export const taskRegardingValues = {
  signatures: "Signatures",
  newSignorAndGuarantorSignature: "New Signor & Guarantor - Signature",
  catFiAddedndum: "CAT FI Addendum",
  dlUpload: "Driver's license upload",
  insuranceQuote: "Provide Insurance Quote",
  insuranceVerification: "Insurance Verification",
  documentESign: "Document e-sign",
  recertificationSignatures: "Recertification - Signatures",
};

export const proposalMenuDraggableTypes = {
  proposalProduct: "proposal-product",
  proposalMenuOption: "proposal-menu-option",
};
