import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSaleMode } from "../../../context";
import { PaymentOptions } from "./PaymentOptions";
import { useFinanceProgramTerms } from "../../../hooks";
import { PaymentOptionCard } from "./PaymentOptionCard";
import { MenuCardsProps, SaleType } from "../../../types";
import { TermsStepperManager } from "../TermsStepperManager";

export const FinancePaymentOption = ({ isCalculating, financePricing }: MenuCardsProps) => {
  const { saleType } = useSaleMode();

  const classes = useStyles();

  const { currentTerm, ...termsManagementProps } = useFinanceProgramTerms();

  const renderBySaleType: Record<SaleType, ReactNode> = {
    [SaleType.Cash]: null,
    [SaleType.Financing]: (
      <>
        <TermsStepperManager label={`${currentTerm} Months`} {...termsManagementProps} />

        {!!financePricing[currentTerm] && (
          <PaymentOptionCard paymentOption={financePricing[currentTerm]} isCalculating={isCalculating} />
        )}

        <PaymentOptions saleType={saleType} payment={financePricing[currentTerm]} />
      </>
    ),
  };

  return <Box className={classes.container}>{renderBySaleType[saleType]}</Box>;
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
});
