import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";
export function CustomTextField({ fieldConfig }) {
  const [value, setValue] = useState(undefined);

  const upperCaseFirstLetter = value => value.slice(0, 1).toUpperCase() + value.slice(1, value.length);
  return (
    <TextField
      style={{ pointerEvents: "none" }}
      disabled={fieldConfig.disabled}
      fullWidth={fieldConfig.fullWidth}
      variant="standard"
      label={_.get(fieldConfig, "displayLabel.en", "")}
      helperText={_.get(fieldConfig, "helperText.en", "")}
      required={fieldConfig.required}
    />
  );
}
