import React, { useContext, useState } from "react";
import { DCR_API_BASE_SERVER_URL } from "config";
import axios from "axios";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import { DocumentContext } from "contexts/DocumentContext";
import MessageModal from "components/MessageModal";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Tile from "../../components/Tile/Tile";
import { setValue } from "../../redux/actions";
import { connect } from "react-redux";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(() => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DOWNLOAD_AGREEMENT = gql`
  mutation ($id: ID!) {
    downloadAgreement(id: $id)
  }
`;

function Agreements({ vo, account, userProfile, agreements, handleOpen, agreementsRefetch }) {
  const classes = useStyles();
  let agreementsTableData = [[]];
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [downloadedEnvelope, setDownloadedEnvelope] = useState("");

  const documentContext = useContext(DocumentContext);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);

  const [downloadAgreement] = useMutation(DOWNLOAD_AGREEMENT, {
    context: { authRequired: true },
  });

  const handleDownload = docusignEnvelopeId => {
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/download-envelope?envelopeId=${docusignEnvelopeId}`,
        {},
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(response => {
        handleCloseMessageModal();
        const fileURL = window.URL.createObjectURL(response.data, { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "envelope.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  };

  const [lastDocument, setLastDocument] = useState();

  const mapData = (key, agreementStatus, sent, owner, docusignEnvelopeId) => {
    return { key, agreementStatus, sent, owner, docusignEnvelopeId };
  };

  const agreementsTableHead = ["Status", "Sent", "Owner", "Download"];

  function addAgreementsToTableData(agreements) {
    const agreementsRows = [];
    if (!!agreements && agreements.length) {
      agreements.forEach(row => {
        row.agreementStatus = row.agreementStatus[0].toUpperCase() + row.agreementStatus.substring(1);
        const tableRow = [
          row.agreementStatus,
          row.signedOn,
          row.owner,
          <IconButton aria-label="delete" size="sm" onClick={() => handleDownload(row.docusignEnvelopeId)}>
            <CloudDownloadIcon />
          </IconButton>,
        ];
        agreementsRows.push(tableRow);
      });
    }
    agreementsTableData = [...agreementsRows];
  }

  let allAgreements;
  if (agreements) {
    allAgreements = agreements.map(row =>
      mapData(row.agreementId, row.agreementStatus, row.sent, row.owner, row.docusignEnvelopeId)
    );
  }
  addAgreementsToTableData(allAgreements);

  return (
    <DocumentContext.Provider value={{ lastDoc: lastDocument, setLastDocument }}>
      <Tile
        title="Agreements"
        titleButtonComponent={
          <>
            <Button size="sm" color="primary" onClick={handleOpen}>
              Send Agreement
            </Button>
          </>
        }
        useCollapsing={true}>
        <Table
          tableHead={agreementsTableHead}
          tableData={agreementsTableData}
          customCellClasses={[classes.flexJustifyEnd]}
          customClassesForCells={[5]}
          customHeadCellClasses={[classes.flexJustifyEnd]}
          customHeadClassesForCells={[5]}
        />
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseMessageModal}
          title={"Loading"}
          message={"Please wait while we prepare the agreement."}
        />
      </Tile>
      <br />
    </DocumentContext.Provider>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account,
    userProfile: state.userProfile,
    vendorProfile: state.vp,
  };
};

const mapDispatchToProps = {
  reduxSetValue: setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
