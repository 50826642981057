import React from "react";
import { Column } from "react-table";
import { Proposal, ProposalMenu } from "@trnsact/trnsact-shared-types";
import { SimpleTextCell } from "components/Table/Cells/SimpleTextCell";
import { DateCell } from "components/Table/Cells/DateCell";
import { ActionsCell } from "./cells/ActionsCell";
import { DealersCell } from "./cells/DealersCell";

interface Props {
  tableData: ProposalMenu[];
  hasWriteAccess: boolean;
  isAftermarketPartner: boolean;
  onEditProposalMenu: (proposalMenuToDelete: ProposalMenu) => void;
  onDeleteProposalMenu: (proposalMenuToDelete: ProposalMenu) => void;
}

export function createTableColumns({
  tableData,
  hasWriteAccess,
  isAftermarketPartner,
  onEditProposalMenu,
  onDeleteProposalMenu,
}: Props): Column[] {
  const hasLinkedFromMenus = tableData.some(pm => !!pm.linkedFromCriteria);

  return [
    {
      sortable: true,
      Header: "Name",
      accessor: "name",
      Cell: ({ original }: any) => <SimpleTextCell value={original.name} />,
    },
    {
      sortable: false,
      Header: "Description",
      accessor: "description",
      Cell: ({ original }: any) => <SimpleTextCell value={original.description} />,
    },
    ...(isAftermarketPartner
      ? [
          {
            sortable: false,
            Header: "Dealers",
            Cell: ({ original }: any) => <DealersCell linkedToCriterias={original.linkedToCriterias} />,
          },
        ]
      : []),
    ...(hasLinkedFromMenus
      ? [
          {
            sortable: true,
            Header: "Shared From",
            Cell: ({ original }: any) => <SimpleTextCell value={original.linkedFromCriteria?.account?.name} />,
          },
        ]
      : []),
    {
      sortable: true,
      Header: "Created",
      accessor: "createdDateTime",
      Cell: ({ original }: any) => <DateCell value={original.createdDateTime} />,
    },
    {
      sortable: true,
      Header: "Modified",
      accessor: "modifiedDateTime",
      Cell: ({ original }: any) => <DateCell value={original.modifiedDateTime} />,
    },
    {
      sortable: true,
      Header: "Action",
      Cell: ({ original }: any) => (
        <ActionsCell
          proposalMenu={original}
          onEditProposalMenu={onEditProposalMenu}
          onDeleteProposalMenu={hasWriteAccess && !original.linkedFromCriteria ? onDeleteProposalMenu : undefined}
        />
      ),
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
