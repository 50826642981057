import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { ConditionsFieldsTypes } from "../types";

export function criteriaViewer(type: ConditionsFieldsTypes, data: any) {
  const formatterMapper: Record<ConditionsFieldsTypes, () => ReactNode> = {
    [ConditionsFieldsTypes.String]: () =>
      data?.string?.value
        ?.map((option: any) => {
          if (option?.label) return option?.label;
          return option;
        })
        ?.join(", ") || "-",
    [ConditionsFieldsTypes.Number]: () => (
      <Box style={{ display: "flex", justifyContent: "flex-start" }}>
        <Typography style={{ flex: 1 }}>Min: {data?.number?.min || "-"}</Typography>
        <Typography style={{ flex: 1 }}>Max: {data?.number?.max || "-"}</Typography>
      </Box>
    ),
    [ConditionsFieldsTypes.Boolean]: () => (data?.boolean ? "Yes" : "No"),
  };

  return formatterMapper[type]();
}
