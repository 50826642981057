import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { Box } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Auth } from "aws-amplify";
import { SESSION_TIMEOUT_COOKIE_NAME } from "../../constants";
//@ts-ignore
import Cookies from "js-cookie";

//@ts-ignore
const useStyles = makeStyles(sidebarStyle);

export const LogoutNavItem = () => {
  const classes = useStyles();

  const handleLogout = async () => {
    await Auth.signOut();
    Cookies.remove(SESSION_TIMEOUT_COOKIE_NAME);
    localStorage.clear();
    sessionStorage.clear();
    document.location.pathname = "";
  };

  return (
    <ListItem className={classes.item} onClick={handleLogout}>
      <Box className={classes.itemLink}>
        <ExitToAppIcon className={classes.itemIcon} />
        <ListItemText primary="Logout" disableTypography={true} className={classes.itemText} />
      </Box>
    </ListItem>
  );
};
