import React from "react";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

export const NotifyAboutRemovedProductsDialog = () => {
  const classes = useStyles();

  const { isOpen, data, handleClose } = useModal(ModalsKeys.DeskingNotifyAboutRemovedProductsDialog);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            One or more of the products on this menu are ineligible for desking this opportunity
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box className={classes.dialogContent}>
          <Box className="listOfProducts">
            {data.removedProducts.map((product, index) => (
              <Typography key={product.proposalProductId} component="span" variant="body1">
                {index + 1}. {product.title}
              </Typography>
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    gap: "1rem",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  dialogContent: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",

    "& .listOfProducts": {
      display: "flex",
      flexDirection: "column",
    },
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
