import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "../../components/CustomButtons/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import UpdateMessageModal from "../../components/UpdateMessageModal";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { blue } from "@mui/material/colors";

const useStylesPaper = makeStyles(theme => ({
  clear: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  formControl: {
    minWidth: 500,
    marginTop: "20px",
    marginBottom: "20px",
  },
  margin: {
    marginLeft: "15px",
    padding: "5px 10px",
  },
  ...extendedFormsStyle,
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  selectFixed: {
    // marginBottom: '0 !important',
    // '& label + .MuiInput-formControl': {
    //   marginTop: 0
    // },
    // '& .MuiInputLabel-formControl': {
    //   transform: 'translate(0, 6px) scale(1)'
    // },
    // '& .MuiInputLabel-shrink': {
    //   transform: 'translate(0, 1.5px) scale(0.75)'
    // }
  },
  colTopic: {
    width: "20%",
    minWidth: 100,
  },
  colRecipient: {
    width: "15%",
    minWidth: 75,
  },
  colMessage: {
    width: "65%",
    minWidth: 325,
  },
});

const useStyles = makeStyles(styles);
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const SEND_ENVELOPE = gql`
  mutation ($input: DocuSignTemplateInputs!, $VOId: ID) {
    sendVOEnvelope(input: $input, VOId: $VOId)
  }
`;

export default function SendDocsModal({
  open,
  handleClose,
  docSets,
  vo,
  creditApp,
  userProfile,
  account,
  agreementsRefetch,
}) {
  const classesPaper = useStylesPaper();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [selectedDocSet, setSelectedDocSet] = useState(null);

  const [sendEnvelope] = useMutation(SEND_ENVELOPE, {
    context: { authRequired: true },
  });
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await sendEnvelope({
        variables: {
          input: {
            // signerEmail will need to come from API
            // signerName
            ccEmail: userProfile.email,
            ccName: userProfile.fullName,
            ccTitle: userProfile.title,
            templateIds: selectedDocSet.docusignTemplateIds,
            accountId: account.id,
            agreementNumber: "100",
            lesseeName: creditApp.businessName,
            dba: creditApp.dba,
            lesseePhone: creditApp.phone,
            billingAddress: creditApp.address,
            billingCity: creditApp.city,
            billingState: creditApp.state,
            billingPostalCode: creditApp.postalCode,
            billingCounty: creditApp.businessAddressCounty,
            creditAppId: creditApp.id,
            // signorLegalName: String
            // signorTitle: String
            // numberOfAdvancePayments: can come from proposal
            // salesRepDocFees: can come from proposal
            // taxRate: not sure
            // payment: proposal
            // term: proposal
            // deposit: maybe user entered
            // bankAccountHolderName: user entered
            totalEquipmentCost: vo.equipmentCost.toString(),
            vendorNameAddressEquipmentDesc: vo.equipmentDescription,
            docusignTemplateIds: selectedDocSet.docSetId,
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        agreementsRefetch();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleClose();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        agreementsRefetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCancel = () => {
    setSelectedDocSet(null);
    handleClose();
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };

  const handleSetOpenConfirmOpen = () => setOpenConfirm(true);
  const handleSetOpenConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <>
      {!!docSets && (
        <div style={{ width: "400px" }}>
          <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"sm"} fullWidth={true}>
            <MuiDialogTitle disableTypography className={classes.root}>
              <Typography variant="h6">Create And Send Agreement</Typography>
              {open ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
            <Paper className={classesPaper.paper}>
              <CardContent>
                <div style={{ padding: "20px 0" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl fullWidth className={classes.selectFormControl + " " + classes.selectFixed}>
                        <Autocomplete
                          value={selectedDocSet}
                          size="small"
                          options={docSets}
                          onChange={(event, newValue) => {
                            setSelectedDocSet(newValue);
                          }}
                          getOptionLabel={option => `${option.name}`}
                          renderInput={params => (
                            <TextField {...params} variant="standard" label="Available Doc Sets" placeholder="" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button onClick={handleCancel} color="primary">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleSubmit} color="primary">
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <UpdateMessageModal
                    open={openMessageModal}
                    handleCloseMessageModal={handleCloseMessageModal}
                    responseSuccess={responseSuccess}
                  />
                </div>
                <br />
              </CardContent>
            </Paper>
          </Dialog>
        </div>
      )}
    </>
  );
}
