import React from "react";
import { Typography } from "@mui/material";
import { AmountField } from "../Fields/AmountField";
import { AutocompleteField } from "../Fields/AutocompleteField";
import { SelectField } from "../Fields/SelectField";
import { MultiselectField } from "../Fields/MultiselectField";
import { CustomTextField } from "../Fields/TextField";
import { EmailField } from "../Fields/EmailField";
import { PhoneField } from "../Fields/PhoneField";
import { PostalCodeField } from "../Fields/PostalCodeField";
import { TaxIdField } from "../Fields/TaxIdField";
import { SocialSecurityField } from "../Fields/SocialSecurityField";
import { DateField } from "../Fields/DateField";
import { PercentField } from "../Fields/PercentField";
import { SignField } from "../Fields/SignField";
import { CustomCheckbox } from "../Fields/CustomCheckbox";
import { ImageInput } from "../Fields/ImageInput";
import { RadioQuestion } from "../Fields/RadioQuestion";
import { TypedField } from "../Fields/TypedField";
import { SignorAvailabilityQuestion } from "../Fields/SignorAvailabilityQuestion";
import { DocumentQuestion } from "../Fields/DocumentQuestion";
import { RepresentativeSelect } from "../Fields/RepresentativeSelect";
import { LocationSelect } from "../Fields/LocationSelect";
import { SignatureselectField } from "../Fields/SignatureselectField";
import { MarketingDisclosuresCheckbox } from "../Fields/MarketingDisclosuresCheckbox";

export function Element({ item }) {
  switch (item.type) {
    case "autocomplete-field":
      return <AutocompleteField fieldConfig={item.config} />;
    case "title":
      return <Typography variant="h6">Title</Typography>;
    case "location-select":
      return <LocationSelect fieldConfig={item.config} />;
    case "representative-select":
      return <RepresentativeSelect fieldConfig={item.config} />;
    case "amount-field":
      return <AmountField fieldConfig={item.config} />;
    case "multiselect-field":
    case "who-is-present-field":
      return <MultiselectField fieldConfig={item.config} />;
    case "select-field":
    case "state-field":
    case "truck-type-field":
    case "eqpt-use-field":
    case "haz-mat-field":
    case "longhaul-regional-field":
      return <SelectField fieldConfig={item.config} />;
    case "text-field":
    case "purchase-reason-field":
    case "types-of-goods-field":
    case "primary-bank-name-field":
    case "html-output-field":
    case "spacer-field":
    case "dynamic-signature-and-title":
    case "hidden-field":
    case "is-owner-or-officer-field":
      return <CustomTextField fieldConfig={item.config} />;
    case "text":
      return null;
    case "email-field":
      return <EmailField fieldConfig={item.config} />;
    case "phone-field":
      return <PhoneField fieldConfig={item.config} />;
    case "postalcode-field":
      return <PostalCodeField fieldConfig={item.config} />;
    case "taxid-field":
      return <TaxIdField fieldConfig={item.config} />;
    case "socialsecurity-field":
      return <SocialSecurityField fieldConfig={item.config} />;
    case "date-field":
      return <DateField fieldConfig={item.config} />;
    case "percent-field":
      return <PercentField fieldConfig={item.config} />;
    case "sign-field":
      return <SignField fieldConfig={item.config} />;
    case "checkbox":
      return <CustomCheckbox fieldConfig={item.config} />;
    case "marketing-disclosures-field":
      return <MarketingDisclosuresCheckbox fieldConfig={item.config} />;
    case "image-input":
    case "typed-file-input":
      return <ImageInput fieldConfig={item.config} />;
    case "radio-field":
      return <RadioQuestion fieldConfig={item.config} />;
    case "typed-field":
      return <TypedField fieldConfig={item.config} />;
    case "signor-availability-question":
      return <SignorAvailabilityQuestion fieldConfig={item.config} />;
    case "document-question":
      return <DocumentQuestion fieldConfig={item.config} />;
    case "radio-question":
      return <RadioQuestion fieldConfig={item.config} />;
    case "signatureselect-field":
      return <SignatureselectField fieldConfig={item.config} />;
    case "owners-array":
      return <Typography variant="h6">Owners Section</Typography>;
    case "signatures-simple":
      return <Typography variant="h6">Signatures Section</Typography>;

    default:
      return null;
  }
}
