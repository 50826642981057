import ocaFieldsConfig from "../DynamicOCA/config/fields_sectionized.json";
import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Grid, Snackbar, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { referenceTypes } from "../../pages/Prequal/constants";
import { getSectionByReferenceType } from "../../utils/ocaTemplateUtils";
import {
  getNewPageConfiguration,
  getReferenceBlockConfiguration,
  getSimpleBlockConfiguration,
  isOwnershipSection,
} from "../../services/dynamicOCAService";
import _ from "lodash";

const ocaFields = _.flatMap(ocaFieldsConfig, "fieldList");

const QUERY_ACTIVE_OCA_TEMPLATE = gql`
  query ($vendorProfileId: ID!, $isActive: Boolean) {
    getOcaTemplatesByVendorProfile(vendorProfileId: $vendorProfileId, isActive: $isActive) {
      ocaTemplateId
      name
      vendorProfileName
      jsonDefinition
      active
    }
  }
`;

const EDIT_OCA_TEMPLATE = gql`
  mutation ($input: UpsertOCATemplateInput!) {
    updateOCATemplate(input: $input)
  }
`;

const useStyles = makeStyles(theme => ({
  ocaInsuranceModule: {
    marginTop: "25px",
  },
}));

export default function OCAInsuranceModuleToggle({ vendorProfile }) {
  const classes = useStyles();

  const { data: activeOCATemplateData } = useQuery(QUERY_ACTIVE_OCA_TEMPLATE, {
    variables: {
      vendorProfileId: vendorProfile.id,
      isActive: true,
    },
    fetchPolicy: "no-cache",
  });

  const [editOcaTemplate] = useMutation(EDIT_OCA_TEMPLATE, {
    context: { authRequired: true },
    onCompleted() {
      setUpdateOcaTemplateSuccess(true);
    },
  });

  const [activeOcaTemplate, setActiveOcaTemplate] = useState();
  const [isInsuranceModuleSetInOca, setIsInsuranceModuleSetInOca] = useState(false);
  const [updateOcaTemplateSuccess, setUpdateOcaTemplateSuccess] = useState(false);

  useEffect(() => {
    if (!activeOCATemplateData) {
      return;
    }
    const activeOcaTemplate = _.first(activeOCATemplateData.getOcaTemplatesByVendorProfile);
    const insuranceSectionInOca = getSectionByReferenceType(activeOcaTemplate, referenceTypes.insurance);
    setActiveOcaTemplate(activeOcaTemplate);
    setIsInsuranceModuleSetInOca(!!insuranceSectionInOca);
  }, [activeOCATemplateData]);

  const handleAddOrRemoveOcaInsuranceModule = async isInsuranceModuleSetInOca => {
    const addInsuranceModuleInOca = () => {
      const ownersPage = _.find(activeOcaTemplate.jsonDefinition, page => _.some(page.sections, isOwnershipSection));
      if (_.isEmpty(ownersPage)) {
        console.error("There is no owners page in active OCA template");
        return;
      }
      const ownersPageIdx = _.indexOf(activeOcaTemplate.jsonDefinition, ownersPage);

      const defaultPageConfiguration = getNewPageConfiguration();
      const defaultSimpleBlockConfiguration = getSimpleBlockConfiguration();
      const providingInsuranceQuestionBlockConfiguration = _.merge({}, defaultSimpleBlockConfiguration, {
        title: {
          config: {
            displayLabel: {
              en: "",
              es: "",
            },
          },
        },
        fields: [_.find(ocaFields, field => _.get(field, "config.fieldName") === "providingInsurance")],
      });
      const insurancePageConfiguration = _.merge({}, defaultPageConfiguration, {
        sections: [
          providingInsuranceQuestionBlockConfiguration,
          getReferenceBlockConfiguration(referenceTypes.insurance, activeOcaTemplate.jsonDefinition),
        ],
      });
      activeOcaTemplate.jsonDefinition.splice(ownersPageIdx, 0, insurancePageConfiguration);
      return activeOcaTemplate.jsonDefinition;
    };

    const removeInsuranceModuleFromOca = () => {
      return _(activeOcaTemplate.jsonDefinition)
        .map(page => {
          page.sections = _.reject(page.sections, section => {
            return (
              section.referenceType === referenceTypes.insurance ||
              _.some(section.fields, field => _.get(field, "config.fieldName") === "providingInsurance")
            );
          });
          if (_.isEmpty(page.sections)) {
            return null;
          }
          return page;
        })
        .compact()
        .value();
    };

    /*     if (initialIsInsuranceModuleSetInOca === isInsuranceModuleSetInOca) {
      return;
    } */

    const jsonDefinitionToSave = isInsuranceModuleSetInOca ? addInsuranceModuleInOca() : removeInsuranceModuleFromOca();
    await editOcaTemplate({
      variables: {
        input: {
          ocaTemplateId: activeOcaTemplate.ocaTemplateId,
          jsonDefinition: jsonDefinitionToSave,
          active: true,
        },
      },
    });
    setActiveOcaTemplate({
      ...activeOcaTemplate,
      jsonDefinition: jsonDefinitionToSave,
    });
    //setInitialIsInsuranceModuleSetInOca(isInsuranceModuleSetInOca);
  };

  return (
    <div>
      {activeOcaTemplate && (
        <Grid container spacing={2} alignItems="flex-start" className={classes.ocaInsuranceModule}>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isInsuranceModuleSetInOca}
                    onChange={() => {
                      setIsInsuranceModuleSetInOca(!isInsuranceModuleSetInOca);
                      handleAddOrRemoveOcaInsuranceModule(!isInsuranceModuleSetInOca);
                    }}
                    color="primary"
                  />
                }
                label="Include Insurance Module in OCA"
              />
            </FormGroup>
          </Grid>
          {/*           <Grid item xs={6}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleAddOrRemoveOcaInsuranceModule}
            >
              Save
            </Button>
          </Grid> */}
        </Grid>
      )}
      <Snackbar
        open={updateOcaTemplateSuccess}
        autoHideDuration={3000}
        onClose={() => setUpdateOcaTemplateSuccess(state => !state)}>
        <Alert severity="success">The OCA template has been successfully updated</Alert>
      </Snackbar>
    </div>
  );
}
