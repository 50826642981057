import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";

const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
  },
}));

export default function UpdateMessageModal({ open, handleCloseMessageModal, responseSuccess }) {
  const classesPaper = useStylesPaper();

  return (
    <div style={{ width: "400px" }}>
      {responseSuccess === true ? (
        <Dialog onClose={handleCloseMessageModal} open={open} scroll={"body"}>
          <Paper className={classesPaper.paper}>
            <h2>Success</h2>
            <p>Updated Successfully!</p>
          </Paper>
        </Dialog>
      ) : responseSuccess === false ? (
        <Dialog onClose={handleCloseMessageModal} open={open} scroll={"body"}>
          <Paper className={classesPaper.paper}>
            <h2>Error</h2>
            <p>Something went wrong!</p>
          </Paper>
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
}
