import React, { useState } from "react";
import _ from "lodash";
import "date-fns";
import formatString from "format-string-by-pattern";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import { formatMoney, isEmailValid } from "../../utils";
import {
  isPostalCodeValid,
  checkIfIsStateField,
  checkIfIsCountryField,
  restrictFieldOptionsBySelectedCountry,
} from "../../modules/creditApplicationDetails/lib/";

export function ReadonlyField({ field }) {
  return <span>{field.value}</span>;
}

export function PhoneField({ field, onChangeFn }) {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const handleChange = e => {
    const formattedValue = formatString("(999) 999-9999", e.target.value.replace(/[^0-9]/g, ""));
    //const isValid = isPhoneValid(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, formattedValue);
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      onChange={handleChange}
      label={field.config.title}
      defaultValue={field.value ? field.value : ""}
    />
  );
}

export function PostalCodeField({ field, onChangeFn }) {
  const [isValid, setIsValid] = useState(field.value ? isPostalCodeValid(field.value) : true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const handleChange = e => {
    setIsValid(isPostalCodeValid(e.target.value));
    setValue(e.target.value);
    onChangeFn(field, e.target.value);
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      error={!isValid}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function TaxIdField({ field, onChangeFn }) {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const isTaxIdValueValid = value => {
    if (!value) {
      return false;
    }
    return value.replace(/[\D]+/g, "")?.length === 9;
  };

  const handleChange = e => {
    const formattedValue = formatString("99-9999999", e.target.value.replace(/[^0-9]/g, ""));
    //const isValid = isTaxIdValueValid(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, _.parseInt(formattedValue.replace(/[\D]+/g, "")));
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function AmountField({ field, onChangeFn, skipFormattingForPayload }) {
  const getFormattedValue = value => {
    return value || value === 0 ? `$${formatMoney(_.toString(value).replace(/[^0-9]/g, ""))}` : "";
  };

  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(getFormattedValue(field.value));

  const isValueValid = value => {
    if (!value) {
      return false;
    }
    const valueToCheck = parseInt(value.replace(/[\D]+/g, ""));
    return valueToCheck <= field.config.max && valueToCheck >= field.config.min;
  };

  const handleChange = e => {
    //const isValid = isValueValid(e.target.value);
    const formattedValue = getFormattedValue(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, skipFormattingForPayload ? formattedValue : parseFloat(formattedValue.replace(/[\D]+/g, "")));
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function SocialSecurityField({ field, onChangeFn }) {
  const getFormattedValue = value => {
    return value ? formatString("999-99-9999", value.replace(/[^0-9]/g, "")) : "";
  };

  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const isSocialSecurityValueValid = value => {
    if (!value) {
      return false;
    }
    return value.replace(/[\D]+/g, "")?.length === 9;
  };

  const handleChange = e => {
    const formattedValue = getFormattedValue(e.target.value);
    //const isValid = isSocialSecurityValueValid(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, formattedValue);
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function DateField({ field, onChangeFn }) {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const isDateValid = value => {
    if (!value) {
      return false;
    }
    return value.replace(/[\D]+/g, "")?.length === 8;
  };

  const handleChange = e => {
    const formattedValue = formatString("99/99/9999", e.target.value.replace(/[^0-9]/g, ""));
    //const isValid = isDateValid(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, formattedValue);
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function DateTimePickerField({ field, onChangeFn }) {
  const dateToSet = field.value ? new Date(_.parseInt(field.value)) : null;
  const [selectedDate, setSelectedDate] = useState(dateToSet);

  const handleDateChange = date => {
    setSelectedDate(date);
    const dateToSave = date ? date.toISOString() : null;
    onChangeFn(field, dateToSave);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        sx={{ width: "100% " }}
        label={field.config.title}
        value={selectedDate ? moment(selectedDate) : null}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
}

export function TypedField({ field, onChangeFn, skipFormattingForPayload }) {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value || field.value === 0 ? field.value : "");

  const isValueValid = value => {
    if (!value) {
      return false;
    }
    let valueToCheck = value;
    if (field.config.type === "integer") {
      valueToCheck = parseInt(value);
    } else if (field.config.type === "float") {
      valueToCheck = parseInt(value);
    } else {
      console.warn(`field.config.type (typed-input) of ${field.config.type} is unhandled! Defaulting to int`);
      valueToCheck = parseInt(value);
    }
    return valueToCheck <= field.config.max && valueToCheck >= field.config.min;
  };

  const handleChange = e => {
    if (field.config.type === "integer") {
      const formattedValue = e.target.value.replace(/[^0-9]/g, "");
      //const isValid = isValueValid(e.target.value);
      setValue(formattedValue);
      onChangeFn(field, skipFormattingForPayload ? formattedValue : _.parseInt(formattedValue));
    } else if (field.config.type === "float") {
      const formattedValue = e.target.value.replace(/[^0-9.]/g, "").replace(/\.+/, ".");
      //const isValid = isValueValid(e.target.value);
      setValue(formattedValue);
      onChangeFn(field, skipFormattingForPayload ? formattedValue : parseFloat(formattedValue));
    } else {
      console.warn(`field.config.type (typed-input) of ${field.config.type} is unhandled! Defaulting to int`);
      const formattedValue = e.target.value.replace(/[^0-9]/g, "");
      //const isValid = isValueValid(e.target.value);
      setValue(formattedValue);
      onChangeFn(field, skipFormattingForPayload ? formattedValue : _.parseInt(formattedValue));
    }
  };

  return (
    <TextField
      fullWidth
      value={value}
      id={field.key}
      key={field.key}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function EmailField({ field, onChangeFn }) {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState(field.value ? field.value : "");

  const handleChange = e => {
    const isValid = isEmailValid(e.target.value);
    setIsValid(isValid);
    setValue(e.target.value);
    onChangeFn(field, e.target.value);
  };

  return (
    <TextField
      fullWidth
      type="email"
      value={value}
      id={field.key}
      key={field.key}
      error={!isValid}
      name={field.key}
      defaultValue={value}
      onChange={handleChange}
      label={field.config.title}
    />
  );
}

export function SelectField({ field, block, onChangeFn, skipFormattingForPayload }) {
  const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);
  const fieldName = _.get(field, "ocaConfig.config.fieldName");
  const nonBoolFields = ["priorBankruptcy", "purchaseOrder"]; // no need to transform to boolean

  let defaultValue = field.value;
  const trulyValues = [true, "true", "yes", "Yes"];
  const falsyValues = [false, "false", "no", "No"];
  if (field.value === true) {
    const defaultOption = _.find(fieldOptions, o => _.includes(trulyValues, o.optionValue));
    defaultValue = defaultOption.optionValue;
  } else if (field.value === false) {
    const defaultOption = _.find(fieldOptions, o => _.includes(falsyValues, o.optionValue));
    defaultValue = defaultOption.optionValue;
  }

  const isStateField = checkIfIsStateField(fieldName);
  //const fieldOptionsToShow = isStateField ? restrictFieldOptionsBySelectedCountry(fieldOptions, block) : fieldOptions;

  const handleChange = e => {
    const formatValue = value => {
      let result = value;
      if (_.includes(trulyValues, value) && !_.includes(nonBoolFields, fieldName)) {
        result = true;
      } else if (_.includes(falsyValues, value) && !_.includes(nonBoolFields, fieldName)) {
        result = false;
      }
      return result;
    };

    const valueToSend = skipFormattingForPayload ? e.target.value : formatValue(e.target.value);
    onChangeFn(field, valueToSend);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{field.config.title}</InputLabel>
      <Select
        onChange={handleChange}
        inputProps={{
          name: field.key,
          defaultValue: defaultValue,
        }}>
        {fieldOptions.map(item => (
          <MenuItem value={item.optionValue} key={item.optionValue}>
            {_.get(item, "displayLabel.en", "") ? _.get(item, "displayLabel.en", "") : item.optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function MultiSelectField({ field, onChangeFn }) {
  const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink id={field.key}>
        {field.config.title}
      </InputLabel>

      <Select
        labelId={field.key}
        onChange={e => onChangeFn(field, e.target.value)}
        multiple={true}
        inputProps={{
          name: field.key,
          defaultValue: Array.isArray(field.value) ? field.value : [field.value],
        }}>
        {fieldOptions.map(item => (
          <MenuItem value={item.optionValue} key={item.optionValue}>
            {_.get(item, "displayLabel.en", "") ? _.get(item, "displayLabel.en", "") : item.optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function CreditAppModalEditableInput({
  field,
  block,
  classes,
  onChangeFn,
  locationsTree,
  locationsTreeOptions,
  skipFormattingForPayload = null, // we want to skip formatting value which will by used in OCA
}) {
  if (field.config.isReadonly) {
    return <ReadonlyField field={field} />;
  }
  if (!field.ocaConfig) {
    return null;
  }

  switch (field.ocaConfig.type) {
    case "phone-field":
      return <PhoneField field={field} onChangeFn={onChangeFn} />;
    case "postalcode-field":
      return <PostalCodeField field={field} onChangeFn={onChangeFn} />;
    case "socialsecurity-field":
      return <SocialSecurityField field={field} onChangeFn={onChangeFn} />;
    case "taxid-field":
      return <TaxIdField field={field} onChangeFn={onChangeFn} />;
    case "email-field":
      return <EmailField field={field} onChangeFn={onChangeFn} />;
    case "autocomplete-field":
    case "select-field":
      return (
        <SelectField
          field={field}
          block={block}
          onChangeFn={onChangeFn}
          skipFormattingForPayload={skipFormattingForPayload}
        />
      );
    case "multiselect-field":
      return <MultiSelectField field={field} onChangeFn={onChangeFn} />;
    case "amount-field":
      return <AmountField field={field} onChangeFn={onChangeFn} skipFormattingForPayload={skipFormattingForPayload} />;
    case "typed-field":
    case "percent-field":
      return <TypedField field={field} onChangeFn={onChangeFn} skipFormattingForPayload={skipFormattingForPayload} />;
    case "date-field":
      return <DateField field={field} classes={classes} onChangeFn={onChangeFn} />;
    case "hidden-field":
      return (
        // we decided to allow edit signatureDate fields
        _.includes(["signatureDate", "personalGuaranteeSignatureDate"], _.get(field, "ocaConfig.config.fieldName")) && (
          <DateTimePickerField field={field} onChangeFn={onChangeFn} />
        )
      );
    case "location-select":
      return <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={locationsTreeOptions} />;
    case "representative-select":
      return <SelectField field={field} onChangeFn={onChangeFn} skipFormattingForPayload={skipFormattingForPayload} />;
    default:
      return (
        <TextField
          fullWidth
          id={field.key}
          key={field.key}
          name={field.key}
          label={field.config.title}
          defaultValue={field.value ? field.value : ""}
          onChange={e => onChangeFn(field, e.target.value)}
        />
      );
  }
}
