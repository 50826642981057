import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import CustomInput from "./CustomInput/CustomInput";
import CardContent from "@mui/material/CardContent";
import CardHeader from "./Card/CardHeader";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    minWidth: 180,
  },
  selectBlock: {
    marginTop: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  primaryColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));

export default function InvoiceQuoteDescriptionInputModal({
  openInvoiceQuoteUploadModal,
  handleCloseInvoiceQuoteUploadModal,
  handleOkInvoiceQuoteUploadModal,
  setFileType,
  fileType,
  fileDescription,
  setInvoiceQuoteNumber,
  // setInvoiceTotal,
  // setTaxAmount
}) {
  const classes = useStyles();

  const [selected, setSelected] = useState(false);

  const handleChange = event => setFileType(event.target.value || "");

  const handleCloseSelect = () => setSelected(true);

  const handleClose = () => {
    handleCloseInvoiceQuoteUploadModal();
    setSelected(false);
  };
  const handleOk = () => {
    if (!!fileType && !!fileDescription) {
      handleOkInvoiceQuoteUploadModal();
      handleCloseInvoiceQuoteUploadModal();
      setSelected(false);
    } else {
      setSelected(true);
    }
  };

  const handleChangeInvoiceQuoteNumber = event => setInvoiceQuoteNumber(event.target.value || "");
  // const handleChangeInvoiceTotal = (event) => setInvoiceTotal(event.target.value || '');
  // const handleChangeSaleTax = (event) => setTaxAmount(event.target.value || '');

  return (
    <div className={classes.primaryColor}>
      <Dialog disableEscapeKeyDown open={openInvoiceQuoteUploadModal} onClose={handleClose}>
        <CardHeader>
          <h3>Please Describe The Uploading File</h3>
        </CardHeader>
        <CardContent>
          <form className={classes.container + " " + classes.primaryColor}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={6}>
                <div style={{ position: "relative" }}>
                  <FormControl
                    required
                    fullWidth
                    className={`${classes.formControl} ${classes.selectFormControl} ${classes.selectBlock}`}
                    error={!!fileType}>
                    <InputLabel htmlFor="documentType" className={classes.selectLabel}>
                      Document Type
                    </InputLabel>
                    <Select
                      classes={{ select: classes.select }}
                      value={fileType}
                      onClose={handleCloseSelect}
                      onChange={e => handleChange(e)}
                      inputProps={{ name: "documentType", id: "documentType" }}>
                      <MenuItem value={"quote"} classes={{ root: classes.selectMenuItem }}>
                        Quote
                      </MenuItem>
                      <MenuItem value={"invoice"} classes={{ root: classes.selectMenuItem }}>
                        Invoice
                      </MenuItem>
                    </Select>
                    {!fileType && !!selected ? <FormHelperText error>Please Select A Type!</FormHelperText> : <></>}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <CustomInput
                  labelText="Invoice/Quote Number"
                  id="invoiceQuoteNumber"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{ type: "text", value: fileDescription, onChange: handleChangeInvoiceQuoteNumber }}
                />
                {!fileDescription && !!selected ? (
                  <FormHelperText error>Please Provide An Invoice Or Quote Number</FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
              {/*<Card style={{margin: '0 20px'}}>*/}
              {/*  <CardContent style={{paddingBottom: '0'}}>*/}
              {/*    <Grid item xs={12} sm={12} lg={12}>*/}
              {/*      <h4 style={{marginBottom: '-17px'}}>Invoice Total:</h4>*/}
              {/*      <CustomInput*/}
              {/*        labelText="Invoice Amount"*/}
              {/*        id="standard-basic1"*/}
              {/*        formControlProps={{fullWidth: true}}*/}
              {/*        inputProps={{type: 'text', onChange: handleChangeInvoiceTotal}}/>*/}
              {/*      <br/>*/}
              {/*      <h4 style={{marginBottom: '-17px'}}>Have you included sales tax? If yes, how much? $</h4>*/}
              {/*      <CustomInput*/}
              {/*        labelText="Sale Tax"*/}
              {/*        id="standard-basic2"*/}
              {/*        formControlProps={{fullWidth: true}}*/}
              {/*        inputProps={{type: 'text', onChange: handleChangeSaleTax}}/>*/}
              {/*    </Grid>*/}
              {/*  </CardContent>*/}
              {/*</Card>*/}
            </Grid>
          </form>
        </CardContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
