import React from "react";
import { Column } from "react-table";
import { formatCurrency } from "../../../../../utils";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { CellArgs } from "../../../types";
import { getPersonaName } from "../../../lib/getPersonaName";
import { hubSearchFilterByColumn } from "../../../lib/hubSearchFilters";
import { MultiSelectFilter } from "../../components/ColumnMultiSelectFilter";
import { ActionCell, DateCell, SimpleTextCell, StatusCell } from "../cells";
import { proposalSaleTypeToViewText } from "../../../constants";

interface Options {
  tableData: Proposal[];
  onGoToProposal: (proposal: Proposal) => void;
  onGoToProposalDetail: (proposal: Proposal) => void;
}

type ProposalCellArgs = CellArgs<Proposal>;

export function createTableColumns({ onGoToProposal, onGoToProposalDetail, tableData }: Options): Column<Proposal>[] {
  return [
    {
      sortable: false,
      Header: "Created On",
      accessor: "createdDateTime",
      Cell: ({ original }: ProposalCellArgs) => <DateCell proposal={original} />,
    },
    {
      sortable: false,
      Header: "Company",
      accessor: "businessName",
      Cell: ({ original }: ProposalCellArgs) => <SimpleTextCell value={original.businessName!} />,
    },
    {
      sortable: false,
      Header: "Contact",
      accessor: "firstName",
      Cell: ({ original }: ProposalCellArgs) => <SimpleTextCell value={getPersonaName(original)} />,
    },
    {
      sortable: false,
      Header: "Sale Type",
      accessor: "saleType",
      Cell: ({ original }: ProposalCellArgs) => (
        <SimpleTextCell value={original?.saleType ? proposalSaleTypeToViewText[original.saleType] : null} />
      ),
    },
    {
      sortable: false,
      Header: "Amount",
      accessor: "amountRequested",
      Cell: ({ original }: ProposalCellArgs) => (
        <SimpleTextCell formatter={formatCurrency} value={original.amountRequested!} />
      ),
    },
    {
      sortable: false,
      filterable: true,
      accessor: "status",
      Header: "Proposal Status",
      style: { minWidth: "11rem" },
      headerStyle: { minWidth: "11rem" },
      filterMethod: hubSearchFilterByColumn,
      Cell: ({ original }: ProposalCellArgs) => <StatusCell proposal={original} />,
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter filter={filter} onChange={onChange} column={column} rows={tableData} />
      ),
    },
    {
      sortable: false,
      Header: "Owner",
      Cell: ({ original }: ProposalCellArgs) => <SimpleTextCell value={original.salesPersonName!} />,
    },
    {
      sortable: false,
      Header: "Actions",
      Cell: ({ original }: ProposalCellArgs) => (
        <ActionCell proposal={original} onGoToProposal={onGoToProposal} onGoToProposalDetail={onGoToProposalDetail} />
      ),
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
    style: { textAlign: "center", ...(header?.style ?? {}) },
    headerStyle: { textAlign: "center", ...(header?.headerStyle ?? {}) },
  }));
}
