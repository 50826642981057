import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { BaseModalProps } from "../../../../global";

interface Props extends BaseModalProps {
  emailAddressReminder: any;
}

export const SendReminderDialog = ({ isOpen, onClose, emailAddressReminder }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ backgroundColor: "#ffffff" }}>
      <DialogTitle id="alert-dialog-title">
        <u>{"Success:"}</u>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A reminder has been sent to <a href={`mailto:${emailAddressReminder}`}>{emailAddressReminder}</a>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
