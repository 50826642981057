import React, { useState } from "react";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { theme } from "../../theme";

export default ({
  id,
  label,
  options,
  selectRef,
  setSavedListSettings,
  savedListSettings,
  setPageNumber,
  width = "100%",
  multiSelect = false,
}) => {
  const [selectFilter, setSelectFilter] = useState(selectRef?.current?.currentSelect || "");

  const throttleSelectRef = _.throttle(option => {
    selectRef.current = { ...selectRef.current, currentSelect: option };
  }, 500);

  const handleSelectChange = (newOption, curOption) => {
    const getNewValue = newOption => {
      if (newOption === "Show All") return "";
      if (!multiSelect) {
        return newOption;
      }

      if (curOption === "") return newOption;

      const storedValue = `, ${newOption}`;
      return curOption.includes(newOption) ? curOption.replace(storedValue, "") : curOption + storedValue;
    };

    const newValue = getNewValue(newOption);
    setSelectFilter(newValue);
    throttleSelectRef(newValue);
    setSavedListSettings({ ...savedListSettings, page: 0 });
    setPageNumber(0);
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth style={{ width }}>
      <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-select-label`}
        id={`${id}-select`}
        value={selectFilter}
        variant="outlined"
        onChange={e => handleSelectChange(e.target.value, selectFilter)}
        label={label}
        renderValue={multiSelect ? value => value : undefined}>
        <MenuItem value="Show All">Show All</MenuItem>
        {options.map((item, index) => (
          <MenuItem key={id + "-" + index} value={item.value}>
            {multiSelect && (
              <Checkbox checked={selectFilter.includes(item.value)} style={{ color: theme.palette.primary.main }} />
            )}
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
