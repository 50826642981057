import { Box, Button } from "@mui/material";
import { useRoutes } from "hooks/useRoutes";
import { portalConfigurationTypes } from "pages/Prequal/constants";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";

interface Props {
  handleClick: () => {};
}

export const CreateNewOpportunityButton = (props: Props) => {
  const { currentRouteConfig } = useRoutes();

  const userProfile = useSelector((state: any) => state.userProfile);

  const opportunitiesPortalConfigurations = useSelector(state =>
    portalConfigurationsSelectors.configDataByType(state, portalConfigurationTypes.opportunities)
  );
  const voManagementPortalConfigurations = useSelector(state =>
    portalConfigurationsSelectors.configDataByType(state, portalConfigurationTypes.voManagement)
  );

  const isVisible = useMemo((): boolean => {
    const allowManualVoCreation = opportunitiesPortalConfigurations?.jsonDefinition.config.allowManualVoCreation;
    const disallowVoСreationRoles =
      voManagementPortalConfigurations?.jsonDefinition.config.rolesSettings.disallowVoСreation;

    return (
      currentRouteConfig?.id === "vo" &&
      allowManualVoCreation &&
      !disallowVoСreationRoles?.includes(userProfile?.vendorContactRole)
    );
  }, [userProfile, currentRouteConfig, voManagementPortalConfigurations, opportunitiesPortalConfigurations]);

  if (!isVisible) {
    return null;
  }

  return (
    <Box mr={2} ml={2}>
      <Button color="primary" variant="contained" size="small" onClick={props.handleClick}>
        Create new opportunity
      </Button>
    </Box>
  );
};
