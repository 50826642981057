import { useCallback } from "react";
import { logError } from "utils/logger";
import { useMutation } from "@apollo/client";
import { UPDATE_VENDOR_OPPORTUNITY } from "../api";
import { UpdateVOResponse, UpdateVOVariables } from "../api/types";

export function useVO() {
  const [updateVO, { loading }] = useMutation<UpdateVOResponse, UpdateVOVariables>(UPDATE_VENDOR_OPPORTUNITY);

  const handleUpdateVO = useCallback(
    async (variables: UpdateVOVariables) => {
      try {
        await updateVO({ variables });
      } catch (error) {
        logError(error);
      }
    },
    [updateVO]
  );

  return {
    handleUpdateVO,
    isVOUpdating: loading,
  };
}
