import React from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ModalsKeys } from "../../../../../global";
import { useModal } from "../../../../../hooks/useModal";
import { FactToCheck } from "modules/desking/types";
import CloseIcon from "@mui/icons-material/Close";

export const ConstructorPricingInfoDialog = () => {
  const classes = useStyles();

  const { isOpen, data, handleClose } = useModal(ModalsKeys.ConstructorPricingInfoDialog);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            Why can't I set pricing?
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box className={classes.container}>
        <Typography component="span" variant="body1">
          <ul>
            <li>
              This product requires the following information to automatically determine pricing:{" "}
              {data.skippedFacts
                .map((f: FactToCheck) => {
                  return f.factKey.replace(/_/g, " ");
                })
                .join(", ")}
            </li>
            <li>
              Because this information depends on the specific deal / equipment, it is impossible to get accurate
              pricing for this menu template.
            </li>
            <li>Don't worry! When you Desk the deal, pricing will be set based on the equipment.</li>
          </ul>
          <div>
            <span className={classes.attentionGrabber}>NOTE: </span>
            <span>You can still set a markup for this product on this menu.</span>
          </div>
        </Typography>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  attentionGrabber: {
    fontWeight: "bold",
  },
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
});
