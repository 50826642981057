import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import CreditSubmissionsDecisionForm from "./CreditSubmissionsDecisionForm";
import MessageModal from "../../components/MessageModal";
import Tile from "../../components/Tile/Tile";
import { Query } from "@apollo/client/react/components";
import { formatCurrency } from "utils";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 800,
  },
  right: {
    textAlign: "right",
    fontSize: "12px",
  },
}));

const queryContext = { authRequired: true };
const GET_CREDIT_SUBMISSIONS = gql`
  query ($csId: ID!) {
    creditSubmission(dynamicsId: $csId) {
      voId
      submissionDate
      decision
      amountRequested
      approvedAmount
      approvedRate
      transactionSummary
    }
  }
`;

const CreditSubmissionsCard = props => {
  const classes = useStyles();
  const [getCreditSubmissions, { loading, error, data, refetch: refetchCreditSubmission }] =
    useLazyQuery(GET_CREDIT_SUBMISSIONS);

  // if (loading) console.log("Loading CreditSubmissions...");
  if (error) console.log(`Error! ${error.message}`);

  // Fetch CreditSubmissions for the very first time:
  useEffect(() => {
    if (props.cs) {
      getCreditSubmissions({
        variables: {
          csId: props.cs.creditSubId,
        },
      });
    }
  }, []);

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };

  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };

  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };

  return (
    <Query
      fetchPolicy="no-cache"
      query={GET_CREDIT_SUBMISSIONS}
      context={queryContext}
      variables={{ csId: props.cs.creditSubId }}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return <h3>{String(error)}</h3>;
        const tableHead = ["Submission Date", "Decision", "Requested Amount", "Approved Amount"];
        let tableData = [["", "", "", ""]];
        if (!!data && !!data.creditSubmission) {
          const cs = data.creditSubmission;
          const formattedAmountReq = cs.amountRequested ? formatCurrency(cs.amountRequested.toFixed(2)) : null;
          const formattedApprovedAmt = cs.approvedAmount ? formatCurrency(cs.approvedAmount.toFixed(2)) : null;
          tableData = [[cs.submissionDate, cs.decision, formattedAmountReq, formattedApprovedAmt]];
        }
        return (
          <Tile title="Decision">
            {/* <Table
              tableHead={tableHead}
              tableData={tableData}
              customHeadClassesForCells={[2, 3]}
              customHeadCellClasses={[classes.right, classes.right]}
              customClassesForCells={[2, 3]}
              customCellClasses={[classes.right, classes.right]}
            /> */}
            <CreditSubmissionsDecisionForm
              refetch={refetchCreditSubmission}
              cs={props.cs}
              handleOpenModal={handleOpenModal}
              handleSubmissionMessage={handleSubmissionMessage}
            />
            <MessageModal
              isOpen={openMessageModal}
              handleCloseModal={handleCloseModal}
              title={submissionMessage.title}
              message={submissionMessage.message}
            />
          </Tile>
        );
      }}
    </Query>
  );
};

export default CreditSubmissionsCard;
