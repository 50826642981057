import React from "react";
import { formatCurrency } from "utils";
import { ProfitDetails } from "./ProfitDetails";
import { PaymentInfoRenderConfig, Pricing, SaleType } from "../../../types";

interface Props {
  payment: Pricing;
  saleType: SaleType;
}

export const ProfitDetailsContainer = ({ payment, saleType }: Props) => {
  const renderConfig: Record<SaleType, PaymentInfoRenderConfig[]> = {
    [SaleType.Financing]: [
      {
        label: "Finance Markup",
        value: payment?.dealerMarkup,
        formatter: value => (value ? `${value}%` : "0.00%"),
      },
      {
        label: "Customer Rate",
        value: payment?.customerRate,
        formatter: value => (value ? `${value}%` : "0.00%"),
      },
      {
        label: "Due in Advance",
        value: payment?.amountDueToday ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
      {
        label: "Cost of Deferred Payments",
        value: payment?.costOfDeferredPayments,
        formatter: value => formatCurrency(value ?? "0", true),
      },
    ],
    [SaleType.Cash]: [
      {
        label: "Due in Advance",
        value: payment?.amountDueToday ?? null,
        formatter: value => formatCurrency(value ?? "0", true),
      },
    ],
  };

  return <ProfitDetails renderConfig={renderConfig[saleType]} />;
};
