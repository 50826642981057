import React, { useContext, useState } from "react";
import { DropzoneDialog } from "mui-file-dropzone";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "@apollo/client";
import { DocumentContext } from "../contexts/DocumentContext";
import InvoiceQuoteDescriptionInputModal from "./InvoiceQuoteDescriptionInputModal";
import { Button, Modal } from "@mui/material";
import gql from "graphql-tag";
import { connect } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const getModalStyle = () => {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
};

const UPLOAD_FILE = gql`
  mutation ($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;

function InvoiceQuoteUploadModal({
  uploadZoneOpened,
  handleCloseUploadInvoiceModal,
  handleChangeDoc,
  refetchDoc,
  buttonClassName = null,
  buttonDescription,
  equipmentUpload,
  vo,
  openUploadZoneStatus = null,
  hideButton = null,
  startProcess,
  setStartProcess,
  handleOpenInvoiceModal,
}) {
  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openErrorDialogue, setOpenErrorDialogue] = useState(false);
  // const [openDescriptionDialogue, setOpenDescriptionDialogue] = useState(false);
  const [openUploadZone, setOpenUploadZone] = useState(false);
  const [mutationError, setMutationError] = useState("");
  const [fileType, setFileType] = useState("");
  const [invoiceQuoteNumber, setInvoiceQuoteNumber] = useState("");
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  // const [openFileDescriptionInputModal, setOpenFileDescriptionInputModal] = useState(false);
  const [openInvoiceQuoteUploadModal, setOpenInvoiceQuoteUploadModal] = useState(false);

  const [fileInput] = useMutation(UPLOAD_FILE, {
    context: { authRequired: true },
  });

  const documentContext = useContext(DocumentContext);

  const graphqlErrorMessage = {
    duplicateMessage:
      'GraphQL error: duplicate key value violates unique constraint "document_vendor_opportunity_id_doc_name_key"',
  };

  const errorDialogue = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {!!mutationError && mutationError.message === graphqlErrorMessage.duplicateMessage
          ? "This file is already uploaded"
          : ""}
      </p>
    </div>
  );

  const handleOpenErrorDialogue = () => setOpenErrorDialogue(true);
  const handleCloseErrorDialogue = () => setOpenErrorDialogue(false);
  const handleOpenUploadZone = () => {
    if (startProcess === null || startProcess === undefined) {
      setStartProcess("upload");
    }
    setOpenUploadZone(true);
  };
  const handleCloseUploadZone = () => {
    handleCloseUploadInvoiceModal();
    setOpenUploadZone(false);
  };
  const handleOpenInvoiceQuoteUploadModal = () => {
    setOpenInvoiceQuoteUploadModal(true);
  };
  const handleCloseInvoiceQuoteUploadModal = () => setOpenInvoiceQuoteUploadModal(false);
  const handleOkInvoiceQuoteUploadModal = () => {};

  const handleSaveFile = async uploadFile => {
    if (uploadFile.length === 0) setMutationError("No File Selected");
    try {
      const response = await fileInput({
        variables: {
          input: {
            vendorOpportunityId: vo.vendorOpportunityId,
            dynamicsVendorOpportunityId: vo.dynamicsVendorOpportunityId,
            source: "will_be_changed_by_server",
            docType: fileType,
            invoiceQuoteNumber: invoiceQuoteNumber,
            createdBy: "will_be_changed_by_server",
            docName: "will_be_changed_by_server",
            invoiceTotal: parseInt(invoiceTotal),
            taxAmount: parseInt(taxAmount),
            docDescription: fileType === "invoice" ? `invoice#${invoiceQuoteNumber}` : `quote#${invoiceQuoteNumber}`,
          },
          file: uploadFile[0],
        },
      });
      await documentContext.setLastDocument(response.data.upsertDocument);
      await setFileType("");
      await setInvoiceQuoteNumber("");
      await refetchDoc();
      await handleChangeDoc();
      if (startProcess === "upload") {
        await handleOpenInvoiceModal();
      }
    } catch (err) {
      console.log(err);
      setMutationError(err);
      handleOpenErrorDialogue();
      await setFileType("");
      await setInvoiceQuoteNumber("");
    }
  };

  if (openUploadZoneStatus) {
    if (startProcess === null || startProcess === undefined) {
      setStartProcess("upload");
    }
    handleOpenUploadZone();
  }

  return (
    <div>
      {!hideButton && (
        <Button
          size="small"
          color="primary"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={handleOpenUploadZone}>
          {buttonDescription}
        </Button>
      )}
      <DropzoneDialog
        open={openUploadZone || uploadZoneOpened}
        onDrop={handleOpenInvoiceQuoteUploadModal}
        onSave={file => {
          handleSaveFile(file);
          handleCloseUploadZone();
        }}
        onClose={() => {
          handleCloseUploadZone();
          handleCloseInvoiceQuoteUploadModal();
          setFileType("");
          setInvoiceQuoteNumber("");
          if (startProcess === "upload") {
            setStartProcess(null);
          }
        }}
        filesLimit={1}
        dropzoneText={"Drag or Click Here To Upload File"}
        dialogTitle="Upload Invoice/Quote"
        showPreviews={true}
        maxFileSize={25000000}
      />
      <Modal
        open={openErrorDialogue}
        onClose={handleCloseErrorDialogue}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {errorDialogue}
      </Modal>
      <InvoiceQuoteDescriptionInputModal
        equipmentUpload={equipmentUpload}
        setInvoiceQuoteNumber={setInvoiceQuoteNumber}
        fileDescription={invoiceQuoteNumber}
        setFileType={setFileType}
        fileType={fileType}
        setInvoiceTotal={setInvoiceTotal}
        setTaxAmount={setTaxAmount}
        openInvoiceQuoteUploadModal={openInvoiceQuoteUploadModal}
        handleCloseInvoiceQuoteUploadModal={handleCloseInvoiceQuoteUploadModal}
        handleOkInvoiceQuoteUploadModal={handleOkInvoiceQuoteUploadModal}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
});

export default connect(mapStateToProps, null)(InvoiceQuoteUploadModal);
