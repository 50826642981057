import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useHistory, useParams } from "react-router-dom";
import {
  BusinessRule,
  ConditionSettingsBoolean,
  ConditionSettingsNumeric,
  ConditionSettingsString,
  FACT_NAMES_BOOLEAN,
  FACT_NAMES_NUMERIC,
  FACT_NAMES_STRING,
  NUMERIC_OPERATORS,
} from "@trnsact/business-criteria";
import _ from "lodash";
import {
  AftermarketProduct,
  AftermarketProductCategory,
  AftermarketProductType,
  AftermarketProductVendorApiChannel,
  CANADA_REGION_CODES,
  EQUIPMENT_CONDITION,
  FINANCING_TYPE,
  LEGAL_STRUCTURE,
  UpdateAftermarketProductInput,
  US_REGION_CODES,
} from "@trnsact/trnsact-shared-types";
import ChipInput from "components/Generic/ChipInput/ChipInput";
import { AFTERMARKET_PRODUCT_EVENTS } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/aftermarket-product-events";
import { MARKUP_TYPE } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/markup-type";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { adminRoles, portalConfigurationTypes } from "pages/Prequal/constants";
import { AftermarketProductManagementHelpers } from "./helpers";
import ReactQuill from "react-quill";
import { Configuration, ConfigurationRow, FactBooleanType, FactNumericType, FactStringType } from "./types";
import { M_UPDATE_AFTERMARKET_PRODUCTS, Q_AFTERMARKET_PRODUCTS, Q_PARTNER_LINKS } from "./api";
import { SelectOption } from "global";
import { PricingSection } from "./PricingSection";

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 3) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

const formatConditionToSave = (addedCondition: any, factTypes: string[]) => {
  if (
    factTypes.includes(_.get(addedCondition, "conditionSettings.fact")) &&
    !_.get(addedCondition, "conditionSettings.exactMatch").every((item: any) => typeof item === "string")
  ) {
    return addedCondition.conditionSettings.exactMatch.map((item: any) => item.code);
  }
  return addedCondition.conditionSettings.exactMatch;
};
const formatNames = (name: string) => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const getStringFactInitialValues = (configRow: any): string[] => {
  const value = _.get(configRow, `conditionSettings.exactMatch`, [] as string[]);
  if (_.isArray(value)) {
    return value;
  }
  if (_.isString(value) && value !== "") {
    return [value];
  }
  return [];
};

const aftermarketVendorList = Object.keys(AftermarketProductVendorApiChannel).map(key => ({
  label: AftermarketProductVendorApiChannel[key as keyof typeof AftermarketProductVendorApiChannel],
}));
const aftermarketProductCategoryList = Object.keys(AftermarketProductCategory)
  .map(key => ({
    value: AftermarketProductCategory[key as keyof typeof AftermarketProductCategory],
    label: capitalize(AftermarketProductCategory[key as keyof typeof AftermarketProductCategory]),
  }))
  .sort((a, b) => {
    if (a.value === AftermarketProductCategory.None) {
      return 1;
    }
    if (b.value === AftermarketProductCategory.None) {
      return -1;
    }
    return 0;
  });
const aftermarketProductTypeList = Object.keys(AftermarketProductType).map(key => ({
  value: AftermarketProductType[key as keyof typeof AftermarketProductType],
  label: capitalize(AftermarketProductType[key as keyof typeof AftermarketProductType]),
}));

function getRegionName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? formatNames(entry[0]) : undefined;
}

function getLegalStructureName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? capitalize(entry[0]) : undefined;
}
function getFinancingTypeName(list: any, code: string): string | undefined {
  return FINANCING_TYPE[code as keyof typeof FINANCING_TYPE];
}

const factNamesNumeric: FactNumericType[] = Object.keys(FACT_NAMES_NUMERIC).map(key => ({
  value: FACT_NAMES_NUMERIC[key as keyof typeof FACT_NAMES_NUMERIC],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesBoolean: FactBooleanType[] = Object.keys(FACT_NAMES_BOOLEAN).map(key => ({
  value: FACT_NAMES_BOOLEAN[key as keyof typeof FACT_NAMES_BOOLEAN],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesString: FactStringType[] = Object.keys(FACT_NAMES_STRING).map(key => ({
  value: FACT_NAMES_STRING[key as keyof typeof FACT_NAMES_STRING],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const numericOperatorNames = Object.keys(NUMERIC_OPERATORS).map(key => ({
  value: NUMERIC_OPERATORS[key as keyof typeof NUMERIC_OPERATORS],
  label: key
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .replace(/^\w/, char => char.toUpperCase()),
}));

const booleanOperatorNames = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const productCatalogQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"], // ,'strike', 'blockquote'
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    // ['link', 'image'],
    // ['clean']
  ],
};

export const UpdateAftermarketProductPage = () => {
  const account = useSelector((state: any) => state.account);
  const userProfile = useSelector((state: any) => state.userProfile);
  const portalConfiguration = useSelector((state: any) => state.portalConfiguration);

  const classes = useStyles();
  const { aftermarketProductId } = useParams<{ aftermarketProductId: string }>();

  const { data: { aftermarketProducts } = { aftermarketProducts: [] } } = useQuery<{
    aftermarketProducts: AftermarketProduct[];
  }>(Q_AFTERMARKET_PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: { accountId: account.id },
  });

  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  const [updateAftermarketProduct] = useMutation(M_UPDATE_AFTERMARKET_PRODUCTS, {
    context: { authRequired: true },
  });

  const [productLogo, setProductLogo] = useState<File | null>(null);
  const [productLogoPreview, setProductLogoPreview] = useState<string | null>(null);

  const [availableFacts] = useState<(FactBooleanType | FactNumericType | FactStringType)[]>([
    ...factNamesBoolean,
    ...factNamesNumeric,
    ...factNamesString,
  ]);

  const [activeTab, setActiveTab] = useState<number>(0);

  const [selectedVendor, setSelectedVendor] = useState<SelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AftermarketProduct[]>([]);
  const [availableVendors, setAvailableVendors] = useState<SelectOption[]>([]);
  const [vendorOption, setVendorOption] = useState<"select" | "create">("select");
  const [productName, setProductName] = useState<string>("");
  const [externalId, setExternalId] = useState<string | null>(null);
  const [productType, setProductType] = useState<string>(AftermarketProductCategory.Gap);
  const [baseOrOption, setBaseOrOption] = useState<AftermarketProductType>(AftermarketProductType.Product);
  const [productCategory, setProductCategory] = useState<string>("");
  const [productDescription, setProductDescription] = useState<string>("");
  const [accountCreatedThisProduct, setAccountCreatedThisProduct] = useState<boolean>(false);
  const [productManagedByApi, setProductManagedByApi] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isPartnerAccount = account.accountType === "Partner";

  const [productSummary, setProductSummary] = useState<any>(null);
  const [limitationsOfLiability, setLimitationsOfLiability] = useState<any>(null);
  const [eligibilityAndInclusions, setEligibilityAndInclusions] = useState<any>(null);
  const [legalDisclaimer, setLegalDisclaimer] = useState<any>(null);
  const [paragraphDescription, setParagraphDescription] = useState<any>(null);
  const [coverageDetails, setCoverageDetails] = useState<any>(null);

  const [productLogoUrl, setProductLogoUrl] = useState<string | null>(null);

  const handleProductLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductLogoPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setProductLogoPreview(null);
    }

    setProductLogo(selectedFile);
  };

  const [getPartnerLinks, { data: partnerLinks }] = useLazyQuery(Q_PARTNER_LINKS, {
    variables: {
      accountId: account.id,
    },
  });

  const history = useHistory();

  const [canWrite, setCanWrite] = useState(true);
  const [canRead, setCanRead] = useState(
    _.get(
      portalConfiguration,
      `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.readOnly`,
      []
    ).includes(userProfile.vendorContactRole)
  );

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (portalConfiguration && userProfile) {
      setIsSuperAdmin([adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole));
      setCanRead(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.readOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
    }
  }, [portalConfiguration, userProfile, account]);

  useEffect(() => {
    if (!_.isEmpty(partnerLinks)) {
      const dataReceived = _.get(partnerLinks, "aftermarketDealerPartnerLink", []);
      if (dataReceived.length > 0) {
        setAvailableVendors(
          dataReceived.map((pl: any) => ({
            label: AftermarketProductManagementHelpers.getPartnerLinkLabel(pl),
            value: pl.partnerLinkId,
          }))
        );
      }
    } else {
      getPartnerLinks();
    }
  }, [partnerLinks, getPartnerLinks]);

  const handleSaveAftermarketProduct = async (e: any) => {
    e.preventDefault();

    let rules = [];
    rules = configurations.map(config => {
      const rule = new BusinessRule({
        name: config.name,
        eventParams: {
          type: AFTERMARKET_PRODUCT_EVENTS.AFTERMARKET_PRODUCT_QUALIFIED,
          params: {
            DEALER_COST: config.dealerCost,
            MARKUP: {
              AMOUNT: config.dealerMarkup,
              TYPE: config.dealerMarkupType,
            },
            RATE_FACTOR: config.rateFactor,
            PRICING_MAX: config.maxPricing,
            PRICING_MIN: config.minPricing,
            SUGGESTED_RETAIL_PRICE: config.suggestedRetailPricing,
          },
        },
      });
      config.configRows.map((cond: any) => {
        let addedCondition = _.cloneDeep(cond);

        addedCondition.conditionSettings.exactMatch = formatConditionToSave(addedCondition, [
          "CANADA_REGION",
          "USA_STATE",
          "LEGAL_STRUCTURE",
          "FINANCING_TYPE",
          "EQUIPMENT_CONDITION",
        ]);

        addedCondition.conditionSettings.requiredForQuote = true;

        rule.Conditions.addCondition(addedCondition.conditionSettings);
      });

      return rule;
    });
    try {
      const updateAftermarketProductInput: UpdateAftermarketProductInput = {
        aftermarketProductId,
        criteria: rules.map(rule => rule.getRule()),
        partnerLinkIds: selectedVendor.map(vendor => vendor.value),
        productCategory: productType as any, // Yes, productType is the category
        productName,
        productType: baseOrOption,
        addonIds: selectedOptions.map(option => option.aftermarketProductId),
        productLogo,
        aftermarketProductCatalogDetails: {
          productSummary: productSummary,
          limitationsOfLiability: limitationsOfLiability,
          eligibilityAndInclusions: eligibilityAndInclusions,
          legalDisclaimer: legalDisclaimer,
          paragraphDescription: paragraphDescription,
          coverageDetails: coverageDetails,
        },
      };

      if (externalId) {
        updateAftermarketProductInput.externalId = externalId;
      }

      await updateAftermarketProduct({
        variables: {
          input: updateAftermarketProductInput,
        },
      });
    } catch (error) {
      console.log(error);
    }
    history.push("/aftermarket");
  };
  const getNextRowElements = (
    configurationIndex: number
  ): ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString | null => {
    const chosenConfiguration = configurations[configurationIndex];
    //Pick configRows from the configuration that are not already selected
    const selectedFacts = new Set(chosenConfiguration.configRows.map(configRow => configRow.conditionSettings.fact));
    const remainingNumericFacts = factNamesNumeric.filter(fact => !selectedFacts.has(fact.value));
    const remainingBooleanFacts = factNamesBoolean.filter(fact => !selectedFacts.has(fact.value));
    const remainingStringFacts = factNamesString.filter(fact => !selectedFacts.has(fact.value));

    if (remainingNumericFacts.length > 0) {
      return { fact: remainingNumericFacts[0].value, minValue: null, maxValue: null };
    } else if (remainingBooleanFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    } else if (remainingStringFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    }

    return null;
  };
  const handleChangeValue = (event: any, configurationIndex: number, rowIndex: number | null, fieldName: string) => {
    if (rowIndex === null) {
      setConfigurations(prev =>
        prev.map((config, index) => {
          if (index !== configurationIndex) return config;
          return { ...config, [fieldName]: event.target.value };
        })
      );
    } else {
      const updateConfigurations = _.cloneDeep(configurations);

      const updatedRows = [...configurations[configurationIndex].configRows];
      // String inputs
      if (_.has(updatedRows, `[${rowIndex}].conditionSettings.exactMatch`)) {
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, event.target.value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
      } else {
        let value = _.isEmpty(event.target.value) ? null : event.target.value;
        if (isNaN(value)) {
          switch (value) {
            case "true":
              value = true;
              break;
            case "false":
              value = false;
              break;
            default:
              return;
          }
        }
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

        setConfigurations(updateConfigurations);
      }
    }
  };

  useEffect(() => {
    const product = aftermarketProducts.find((p: any) => p.aftermarketProductId === aftermarketProductId);
    if (product) {
      setProductName(product.productName);
      setProductCategory(product.productCategory);
      let productCreatedByAccount = true;
      if (product.accountIdCreatedBy !== null && product.accountIdCreatedBy !== account.id) {
        productCreatedByAccount = false;
      }

      setCanWrite(
        isSuperAdmin ||
          isPartnerAccount ||
          (_.get(
            portalConfiguration,
            `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.assignedOnly`,
            []
          ).includes(userProfile.vendorContactRole) &&
            productCreatedByAccount)
      );

      if (product.accountIdCreatedBy === account.id) {
        setAccountCreatedThisProduct(true);
      }

      if (product.aftermarketVendorApiChannel) {
        setProductManagedByApi(true);
        setActiveTab(2);
      }

      if (product.externalId) {
        setExternalId(product.externalId);
      }

      setBaseOrOption(product.productType);

      setVendorOption("select");

      setProductSummary(product.aftermarketProductCatalogDetails?.productSummary);
      setLimitationsOfLiability(product.aftermarketProductCatalogDetails?.limitationsOfLiability);
      setEligibilityAndInclusions(product.aftermarketProductCatalogDetails?.eligibilityAndInclusions);
      setLegalDisclaimer(product.aftermarketProductCatalogDetails?.legalDisclaimer);
      setParagraphDescription(product.aftermarketProductCatalogDetails?.paragraphDescription);
      setCoverageDetails(product.aftermarketProductCatalogDetails?.coverageDetails);

      setProductLogoUrl(product.productLogoUrl);

      const chosenVendors = product.partnerLinks.map<SelectOption>((pl: any) => {
        const v = availableVendors.find(v => v.value === pl.partnerLinkId);
        return {
          label: _.get(v, "partnerProfile.name", "Unknown Vendor"),
          value: pl.partnerLinkId,
        };
      });
      if (chosenVendors) {
        setSelectedVendor(chosenVendors);
      }
      setProductType(product.productCategory);

      // @ts-expect-error product.addons is valid AftermarketProduct[]
      setSelectedOptions(product.addons ?? []);

      if (!_.isEmpty(product.criteria)) {
        setConfigurations(
          product.criteria.map((criteria: any) => {
            const configRows: Configuration["configRows"] = _.get(criteria, "conditions.formRules", [])
              .map((fr: ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString) => ({
                conditionSettings: fr,
              }))
              .map((row: any) => {
                //Clone variable to avoid modifying the original object and corrupting the data
                let prev = _.cloneDeep(row);
                if (_.get(prev, "conditionSettings.fact") == "CANADA_REGION") {
                  prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((region: string) => ({
                    label: getRegionName(CANADA_REGION_CODES, region),
                    code: region,
                  }));
                }
                if (_.get(prev, "conditionSettings.fact") == "USA_STATE") {
                  prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((region: string) => ({
                    label: getRegionName(US_REGION_CODES, region),
                    code: region,
                  }));
                }

                if (_.get(prev, "conditionSettings.fact") == "LEGAL_STRUCTURE") {
                  prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ls: string) => ({
                    label: getLegalStructureName(LEGAL_STRUCTURE, ls),
                    code: ls,
                  }));
                }

                if (_.get(prev, "conditionSettings.fact") == "FINANCING_TYPE") {
                  prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ft: string) => ({
                    label: getFinancingTypeName(FINANCING_TYPE, ft),
                    code: ft,
                  }));
                }
                if (_.get(prev, "conditionSettings.fact") == "EQUIPMENT_CONDITION") {
                  prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ec: string) => ({
                    label: capitalize(EQUIPMENT_CONDITION[ec as keyof typeof EQUIPMENT_CONDITION]),
                    code: ec,
                  }));
                }
                return prev;
              });

            return {
              type: product.productType,
              dealerCostSource: _.get(criteria, "event.params.DEALERCOST", 0),
              dealerCost: _.get(criteria, "event.params.DEALERCOST", 0),
              dealerMarkup: _.get(criteria, "event.params.MARKUP.AMOUNT", 0),
              rateFactor: _.get(criteria, "event.params.RATEFACTOR", 0),
              dealerMarkupType: _.get(
                criteria,
                "event.params.MARKUP.TYPE",
                MARKUP_TYPE.PERCENTAGE
              ) as typeof MARKUP_TYPE,
              minPricing: _.get(criteria, "event.params.PRICINGMIN", 0),
              maxPricing: _.get(criteria, "event.params.PRICINGMAX", 0),
              suggestedRetailPricing: _.get(criteria, "event.params.SUGGESTEDRETAILPRICE", 0),
              name: _.get(criteria, "name", ""),
              configRows,
            };
          })
        );
      }

      // @ts-expect-error product.productDescriptionExternal is a string
      setProductDescription(_.get(product, "productDescriptionExternal", ""));
      setIsLoading(false);
    }
  }, [aftermarketProducts]);

  const handleAddProductionConfiguration = () => {
    //Default initial values per config
    setConfigurations([
      ...configurations,
      {
        name: configurations.length === 0 ? "Base Configuration" : `Configuration ${configurations.length + 1}`,
        configRows: [],
        dealerCostSource: "api",
        dealerCost: 0,
        rateFactor: 0,
        dealerMarkup: 0,
        dealerMarkupType: MARKUP_TYPE.PERCENTAGE,
        minPricing: 0,
        maxPricing: 0,
        suggestedRetailPricing: 0,
        type: AftermarketProductType.Product,
      },
    ]);
  };
  const handleChangeFact = (
    event: SelectChangeEvent<FACT_NAMES_BOOLEAN | FACT_NAMES_NUMERIC | FACT_NAMES_STRING>,
    configurationIndex: number,
    rowIndex: number
  ) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const updatedRows = [...newCriteriaRows];
    const isBoolean = Object.keys(FACT_NAMES_BOOLEAN).includes(String(event.target.value));
    const isNumeric = Object.keys(FACT_NAMES_NUMERIC).includes(String(event.target.value));

    if (isBoolean) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_BOOLEAN,
        value: false,
      };
    } else if (isNumeric) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_NUMERIC,
        minValue: null,
        maxValue: null,
      };
    } else {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_STRING,
        exactMatch: "",
      };
    }
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
    setConfigurations(updateConfigurations);
  };

  const updateBaseOrOption = (type: AftermarketProductType) => {
    if (type === AftermarketProductType.Addon) {
      setActiveTab(0);
    }
    setBaseOrOption(type);
  };

  const renderNumericInputByFactType = (fact: string) => {
    switch (fact) {
      case FACT_NAMES_NUMERIC.DEAL_SIZE:
        return CustomCurrencyInput;
      default:
        return undefined;
    }
  };

  const renderStringInputByFactType = (configurationIndex: number, rowIndex: number, configRow: any) => {
    switch (configRow.conditionSettings.fact) {
      case FACT_NAMES_STRING.EQUIPMENT_CONDITION:
        const equipmentConditionOptions = Object.keys(EQUIPMENT_CONDITION).map(key => ({
          code: key,
          label: EQUIPMENT_CONDITION[key as keyof typeof EQUIPMENT_CONDITION],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, equipmentConditionOption) => {
              return handleChangeValue(
                {
                  target: {
                    value: equipmentConditionOption.map(equipCond => equipCond.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={equipmentConditionOptions}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select the Equipment Condition" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.FINANCING_TYPE:
        const listToRenderFinancingType = Object.keys(FINANCING_TYPE).map(key => ({
          code: key,
          label: FINANCING_TYPE[key as keyof typeof FINANCING_TYPE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, financingTypes) => {
              return handleChangeValue(
                {
                  target: {
                    value: financingTypes.map(financingType => financingType.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderFinancingType}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Financing Type" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.LEGAL_STRUCTURE:
        const listToRenderLegalStructure = Object.keys(LEGAL_STRUCTURE).map(key => ({
          label: key,
          code: LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, legalStructures) => {
              return handleChangeValue(
                {
                  target: {
                    value: legalStructures.map(legalStructure => legalStructure.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderLegalStructure}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Legal Structure" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.CANADA_REGION:
        const canadaSelectedValues = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderCanadaStates = _.cloneDeep(
          Object.keys(CANADA_REGION_CODES).map(key => ({
            label: formatNames(key),
            code: CANADA_REGION_CODES[key as keyof typeof CANADA_REGION_CODES],
          }))
        );

        return (
          <Autocomplete
            id="canada-state-autocomplete"
            key={"canada-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderCanadaStates}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={canadaSelectedValues}
            renderInput={params => <TextField {...params} label="Select a Region" variant="outlined" />}
          />
        );

      case FACT_NAMES_STRING.USA_STATE:
        const contained = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderStates = Object.keys(US_REGION_CODES).map(key => ({
          label: formatNames(key),
          code: US_REGION_CODES[key as keyof typeof US_REGION_CODES],
        }));

        return (
          <Autocomplete
            id="usa-state-autocomplete"
            key={"usa-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderStates}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={contained}
            renderInput={params => <TextField {...params} label="Select a State" variant="outlined" />}
          />
        );
      default:
        const initialValues = getStringFactInitialValues(configRow);
        const stringFactInput = (
          <ChipInput
            id={`exact-match-${rowIndex}`}
            onChange={(id: string, value: string[]) =>
              handleChangeValue({ target: { value } }, configurationIndex, rowIndex, "exactMatch")
            }
            value={_.clone(initialValues)}
          />
        );
        return stringFactInput;
    }
  };

  const currentRowIsBooleanFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_BOOLEAN).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsNumericFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_NUMERIC).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsStringFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_STRING).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const getAvailableFactsForRow = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const selectedFacts = new Set(newCriteriaRows.map(configRow => configRow.conditionSettings.fact));
    const currentFact = newCriteriaRows[rowIndex].conditionSettings.fact;
    selectedFacts.delete(currentFact);

    return availableFacts.filter(fact => !selectedFacts.has(fact.value));
  };

  const handleAddMoreCondition = (configurationIndex: number) => {
    const baseCondition = getNextRowElements(configurationIndex);
    if (!baseCondition) {
      alert("No more available facts to add.");
      return;
    }
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = [
      ...previousRows,
      {
        conditionSettings: baseCondition,
      },
    ];
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };

  const handleRemoveConfigurationRow = (configurationIndex: number) => {
    const previousConfigurations = _.cloneDeep(configurations);
    const updatedConfigurations = previousConfigurations.filter((_, i) => i !== configurationIndex);
    setConfigurations(updatedConfigurations);
  };

  useEffect(() => {
    console.log(configurations);
  }, [configurations]);

  const handleRemoveConditionRow = (configurationIndex: number, rowIndex: number) => {
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = previousRows.filter((_, i) => i !== rowIndex);
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={3}>
      {/* Header */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <ArrowBackIosIcon
              fontSize="small"
              cursor={1}
              onClick={() => {
                history.push("/aftermarket");
              }}
            />
            Edit Aftermarket Product
          </Typography>
          {canWrite === true && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={
                selectedVendor === null || productName === "" || (configurations.length === 0 && !isPartnerAccount)
              }
              onClick={handleSaveAftermarketProduct}
              style={{ float: "right", marginBottom: "10px" }}>
              Save Changes
            </Button>
          )}
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>
      {!accountCreatedThisProduct && (
        <>
          <Paper style={{ padding: "20px", width: "100%" }}>
            <div style={{ fontWeight: "bold", color: "orange", fontSize: "18px" }}>
              This product has been shared with you. You cannot edit it.
            </div>
          </Paper>

          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </>
      )}
      {/* Step 1: Aftermarket Vendor Section */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary">
            <LooksOneIcon /> {isPartnerAccount ? "Dealer" : "Aftermarket Vendor"}
          </Typography>

          <Autocomplete
            size="small"
            multiple
            value={availableVendors.filter(vendor => selectedVendor.find(sv => sv.value === vendor.value))}
            onChange={(event, newValues) => {
              setSelectedVendor(newValues);
            }}
            options={availableVendors}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.label}
            disabled={!canWrite}
            renderInput={params => (
              <TextField
                {...params}
                label={`Select the ${isPartnerAccount ? "Dealers that have access to this product" : "Vendor"}`}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      {/* Step 2: Product Configuration Section */}
      {selectedVendor && (
        <>
          <Paper style={{ padding: "20px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">
                <LooksTwoIcon /> Product Configuration
              </Typography>

              <TextField
                fullWidth
                label="Product Name - The applicant will be presented with this name"
                variant="outlined"
                margin="normal"
                size="small"
                value={productName}
                onChange={e => setProductName(e.target.value)}
                required
                disabled={!canWrite}
              />

              {productManagedByApi && (
                <TextField
                  fullWidth
                  id={`externalId`}
                  label="Vendor's Product ID - Used for API Integration"
                  variant="outlined"
                  size="small"
                  value={externalId || ""}
                  onChange={e => setExternalId(e.target.value)}
                  disabled={true}
                  slotProps={{
                    inputLabel: {
                      shrink: !!externalId,
                    },
                  }}
                />
              )}

              <Grid container spacing={0} style={{ paddingTop: "8px" }}>
                <Grid item xs={1}>
                  <Button variant="contained" component="label" disabled={!canWrite}>
                    Set Logo
                    <input type="file" accept="image/*" hidden onChange={handleProductLogoChange} />
                  </Button>
                </Grid>
                {productLogoUrl && (
                  <Grid item xs={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Typography>Current Icon</Typography>
                      <img
                        src={productLogoUrl}
                        alt="Current Product Logo"
                        style={{
                          maxWidth: "100%",
                          maxHeight: 64,
                          marginTop: 8,
                          border: "1px solid #ccc",
                          borderRadius: 4,
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {productLogoPreview && (
                  <Grid item xs={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Typography>New Icon</Typography>
                      <img
                        src={productLogoPreview}
                        alt="Selected product logo"
                        style={{
                          maxWidth: "100%",
                          maxHeight: 64,
                          marginTop: 8,
                          border: "1px solid #ccc",
                          borderRadius: 4,
                        }}
                      />
                      <IconButton color="primary" size="large">
                        <DeleteOutlineIcon
                          color="error"
                          onClick={() => {
                            setProductLogo(null);
                            setProductLogoPreview(null);
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Typography style={{ paddingTop: "8px" }}>Product Type</Typography>
              <RadioGroup row value={productType} onChange={e => setProductType(e.target.value)}>
                {aftermarketProductCategoryList.map(({ value, label }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio color="primary" />}
                    label={label}
                    disabled={!canWrite}
                  />
                ))}
              </RadioGroup>

              <Typography>Product or Addon?</Typography>
              <RadioGroup
                row
                value={baseOrOption}
                onChange={e => updateBaseOrOption(e.target.value as AftermarketProductType)}>
                <FormControlLabel
                  key={AftermarketProductType.Product}
                  value={AftermarketProductType.Product}
                  control={<Radio color="primary" />}
                  label={"Product"}
                  disabled={!canWrite}
                />
                <FormControlLabel
                  key={AftermarketProductType.Addon}
                  value={AftermarketProductType.Addon}
                  control={<Radio color="primary" />}
                  label={"Addon"}
                  disabled={!canWrite}
                />
              </RadioGroup>
            </Grid>
            <Divider variant="fullWidth" />
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} variant="scrollable">
              <Tab label={`Base ${baseOrOption === AftermarketProductType.Product ? "Product" : "Option"}`} />
              <Tab label="Addons" disabled={baseOrOption === AftermarketProductType.Addon} />
              <Tab label="Product Catalog" />
            </Tabs>

            {/* Product Configuration Details - What goes under "Base Products" tab */}
            {activeTab === 0 &&
              (productManagedByApi ? (
                <div>Managed by API</div>
              ) : (
                <Grid item xs={12}>
                  {configurations.length === 0 && (
                    <Typography variant="body1">
                      Click on "Add Product Configuration" to start adding configurations.
                    </Typography>
                  )}
                  {configurations.map((configuration: Configuration, configurationIndex: number) => {
                    return (
                      <Box
                        key={`configuration_${configurationIndex}`}
                        border={1}
                        borderColor="grey.400"
                        borderRadius="4px"
                        padding={2}>
                        <Grid container alignItems="center">
                          <IconButton color="primary" size="large">
                            <DeleteOutlineIcon
                              color="error"
                              onClick={() => {
                                handleRemoveConfigurationRow(configurationIndex);
                              }}
                            />
                          </IconButton>
                          <Typography variant="body1">
                            [Configuration #{configurationIndex + 1}] - Product <b>{productName}</b>
                          </Typography>
                        </Grid>
                        {configuration.configRows.map((configRow: ConfigurationRow, rowIndex: number) => {
                          return (
                            <Grid
                              container
                              spacing={1}
                              key={rowIndex}
                              justifyContent="flex-start"
                              alignContent="flex-start"
                              alignItems="center">
                              <span style={{ border: "1px dashed #DEDEDE", borderRadius: "50%" }}>
                                <IconButton
                                  aria-label="close"
                                  size="small"
                                  onClick={() => {
                                    handleRemoveConditionRow(configurationIndex, rowIndex);
                                  }}>
                                  <RemoveCircleIcon fontSize="small" color="error" />
                                </IconButton>
                              </span>
                              <Grid item xs={5}>
                                <FormControl fullWidth variant="standard">
                                  <InputLabel id={`new-fact-label-${rowIndex}`}>Rule</InputLabel>
                                  <Select
                                    labelId={`new-fact-label-${rowIndex}`}
                                    id={`new-fact-${rowIndex}`}
                                    value={configRow.conditionSettings.fact}
                                    onChange={e => handleChangeFact(e, configurationIndex, rowIndex)}
                                    label="Rule">
                                    {getAvailableFactsForRow(configurationIndex, rowIndex).map((fact, index) => (
                                      <MenuItem key={index} value={fact.value}>
                                        {fact.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {currentRowIsBooleanFact(configurationIndex, rowIndex) && (
                                <>
                                  <Grid item xs={4}>
                                    <FormControl fullWidth variant="standard" size="small">
                                      <InputLabel id={`new-operator-label-${rowIndex}`}></InputLabel>
                                      <Select
                                        labelId={`new-operator-label-${rowIndex}`}
                                        id={`new-operator-${rowIndex}`}
                                        value={_.get(configRow, "conditionSettings.value")}
                                        onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "value")}
                                        label="Option">
                                        {booleanOperatorNames.map(element => (
                                          <MenuItem key={element.label} value={String(element.value)}>
                                            {element.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </>
                              )}
                              {currentRowIsNumericFact(configurationIndex, rowIndex) && (
                                <>
                                  <Grid item xs={2}>
                                    <InputLabel id={`min-value-label-${rowIndex}`}>Min Value</InputLabel>
                                    <TextField
                                      id={`min-value-${rowIndex}`}
                                      variant="standard"
                                      type="text"
                                      size="small"
                                      value={_.get(configRow, `conditionSettings.minValue`)}
                                      onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "minValue")}
                                      fullWidth
                                      slotProps={{
                                        input: {
                                          inputComponent: renderNumericInputByFactType(
                                            configRow.conditionSettings.fact
                                          ),
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <InputLabel id={`max-value-label-${rowIndex}`}>Max Value</InputLabel>
                                    <TextField
                                      id={`max-value-${rowIndex}`}
                                      variant="standard"
                                      type="text"
                                      size="small"
                                      value={_.get(configRow, `conditionSettings.maxValue`)}
                                      onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "maxValue")}
                                      fullWidth
                                      slotProps={{
                                        input: {
                                          inputComponent: renderNumericInputByFactType(
                                            configRow.conditionSettings.fact
                                          ),
                                        },
                                      }}
                                    />
                                  </Grid>
                                </>
                              )}
                              {currentRowIsStringFact(configurationIndex, rowIndex) && (
                                <>
                                  <Grid item xs={4}>
                                    {renderStringInputByFactType(configurationIndex, rowIndex, configRow)}
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          );
                        })}
                        <Grid container justifyContent="center" spacing={6}>
                          <Grid item xs={12}>
                            {_.isEmpty(configuration.configRows) ? "No conditions added yet." : ""}
                          </Grid>
                          <Grid item>
                            <Button
                              startIcon={<AddIcon />}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleAddMoreCondition(configurationIndex);
                              }}
                              size="small">
                              ADD MORE
                            </Button>
                          </Grid>
                        </Grid>

                        <Divider />

                        <PricingSection
                          handleChangeValue={handleChangeValue}
                          configurationIndex={configurationIndex}
                          configuration={configurations[configurationIndex]}
                        />
                      </Box>
                    );
                  })}
                </Grid>
              ))}

            {/* Options Details */}
            {activeTab === 1 &&
              (productManagedByApi ? (
                <div>Managed by API</div>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="primary">
                    <LooksOneIcon /> Select Options on this Product
                  </Typography>

                  <Autocomplete
                    size="small"
                    multiple={true}
                    defaultValue={selectedOptions}
                    onChange={(event, newValues) => {
                      setSelectedOptions(newValues as any);
                    }}
                    options={aftermarketProducts.filter(
                      (product: AftermarketProduct) => product.productType === AftermarketProductType.Addon
                    )}
                    isOptionEqualToValue={(option, value) => option.productName === value.productName}
                    getOptionLabel={(option: AftermarketProduct) => `${capitalize(option.productName)}`}
                    renderInput={params => (
                      <TextField {...params} label="Select Options on this Product" variant="outlined" />
                    )}
                  />
                </Grid>
              ))}

            {/* Product Catalog Information*/}
            {activeTab === 2 && (
              <Box>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="primary">
                    <LooksOneIcon /> Product Catalog Information
                  </Typography>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Product Summary
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      style={{ minHeight: "300px" }}
                      theme="snow"
                      value={productSummary}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setProductSummary(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Limitations of Liability
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={limitationsOfLiability}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setLimitationsOfLiability(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Eligibility & Inclusions
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={eligibilityAndInclusions}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setEligibilityAndInclusions(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Legal Disclaimer
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={legalDisclaimer}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setLegalDisclaimer(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Paragraph Description
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={paragraphDescription}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setParagraphDescription(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Coverage Details
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      style={{ minHeight: "300px" }}
                      theme="snow"
                      value={coverageDetails}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setCoverageDetails(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                      readOnly={!canWrite}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
        </>
      )}
      {selectedVendor && !productManagedByApi && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddProductionConfiguration}
            disabled={!canWrite}>
            ADD PRODUCT CONFIGURATION
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  catalogInformationGridContainer: {
    paddingTop: "24px",
  },
  catalogInformationGridItem: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  catalogInformationLabel: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  catalogInformationQuillInput: {
    "& .ql-editor": {
      minHeight: "300px", // Set your desired minimum height for the content area
    },
  },
});
