import React from "react";
import { ValueOf } from "global";
import { InputField } from "components/form";
import { useFormContext } from "react-hook-form";
import { Box, Button, Typography } from "@mui/material";
import { CreditLineFormValues } from "../model";
import { UpdateVOVariables } from "../../api/types";

interface Props {
  isLoading: boolean;
  onCreditLineUpdate: <Key extends keyof ValueOf<UpdateVOVariables, "fieldsObj">>(
    data: Record<Key, ValueOf<UpdateVOVariables, "fieldsObj">[Key]>
  ) => Promise<void>;
}

export const CreditLineNotes = ({ isLoading, onCreditLineUpdate }: Props) => {
  const { resetField, control, getValues, setValue } = useFormContext<CreditLineFormValues>();

  const handleAddNote = async () => {
    const noteValue = getValues("allNotes") + "\n\n" + getValues("notes");
    setValue("allNotes", noteValue);
    await onCreditLineUpdate({ creditLineNotes: noteValue });
    resetField("notes");
  };

  return (
    <Box className="notesSection">
      <InputField
        label="Notes"
        name="allNotes"
        control={control}
        inputProps={{ minRows: 4, maxRows: 6, disabled: true, multiline: true, variant: "outlined" }}
      />

      <Box className="customNote">
        <InputField
          name="notes"
          label="Note"
          control={control}
          inputProps={{
            placeholder: "Enter your note...",
          }}
        />

        <Box className="addNotesBtn">
          <Button variant="contained" disabled={isLoading} onClick={handleAddNote}>
            Add note
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
