import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation, useQuery } from "@apollo/client";
import { VendorProfile } from "@trnsact/trnsact-shared-types";
import { Button, FormControlLabel, Grid, InputLabel, Snackbar, Switch } from "@mui/material";
import { DisclosuresCardData } from "types";
import Tile from "../../components/Tile/Tile";

const QUERY_VP_DISCLOSURES = gql`
  query vpDisclosuresData($dynamicsId: ID) {
    vendorProfile(dynamicsId: $dynamicsId) {
      id
      dcrDisclosureLanguage
      dcrDisclosureLanguageSpanish
      dcrDisclosureTerms
      dcrMarketingDisclosure
      dcrDisclosureTermsSpanish
      dcrDisclosureUseDefaultTerms
      ocaPersonalGuaranteeAgreementDisclosure
      proposalsDeclinationDisclosure
    }
  }
`;

const M_UPDATE_VP = gql`
  mutation ($entityId: String!, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

interface Props {
  vendorProfile: VendorProfile;
}

export default function DisclosuresCard({ vendorProfile }: Props) {
  const classes = useStyles();

  const { data: vpDisclosuresData } = useQuery(QUERY_VP_DISCLOSURES, {
    variables: {
      dynamicsId: vendorProfile.dynamicsId,
    },
    fetchPolicy: "no-cache",
  });

  const [updateVP, { loading: updateDisclosuresLoading }] = useMutation(M_UPDATE_VP, {
    context: { authRequired: true },
    onCompleted() {
      setUpdateDisclosuresSuccess(true);
    },
  });

  const initialDisclosures: DisclosuresCardData = {
    dcrDisclosureLanguage: "",
    dcrDisclosureLanguageSpanish: "",
    dcrDisclosureTerms: "",
    dcrDisclosureTermsSpanish: "",
    ocaPersonalGuaranteeAgreementDisclosure: "",
    proposalsDeclinationDisclosure: "",
    dcrMarketingDisclosure: "",
    dcrDisclosureUseDefaultTerms: false,
  };

  const [disclosures, setDisclosures] = useState<DisclosuresCardData>(initialDisclosures);
  const [updateDisclosuresSuccess, setUpdateDisclosuresSuccess] = useState(false);

  const handleDisclosureUpdate = (newValue: string | boolean, key: string) => {
    setDisclosures(prevValue => ({ ...prevValue, [key]: newValue }));
  };

  const saveDisclosures = async () => {
    await updateVP({
      variables: {
        entityId: vendorProfile.id,
        fieldsObj: {
          ...disclosures,
        },
      },
    });
  };

  useEffect(() => {
    if (!vpDisclosuresData) return;

    const { vendorProfile } = vpDisclosuresData;

    const disclosuresDataToSet: DisclosuresCardData = {
      dcrDisclosureLanguage: vendorProfile.dcrDisclosureLanguage,
      dcrDisclosureLanguageSpanish: vendorProfile.dcrDisclosureLanguageSpanish,
      dcrDisclosureTerms: vendorProfile.dcrDisclosureTerms,
      dcrDisclosureTermsSpanish: vendorProfile.dcrDisclosureTermsSpanish,
      ocaPersonalGuaranteeAgreementDisclosure: vendorProfile.ocaPersonalGuaranteeAgreementDisclosure,
      proposalsDeclinationDisclosure: vendorProfile.proposalsDeclinationDisclosure,
      dcrDisclosureUseDefaultTerms: vendorProfile.dcrDisclosureUseDefaultTerms,
      dcrMarketingDisclosure: vendorProfile.dcrMarketingDisclosure,
    };

    setDisclosures(disclosuresDataToSet);
  }, [vpDisclosuresData]);

  return (
    <div>
      <Tile title="Disclosures" useCollapsing initCollapsed>
        {disclosures && (
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={6}>
              <InputLabel style={{ margin: "11px 0" }}>Authorization Page Language:</InputLabel>
              <ReactQuill
                value={disclosures.dcrDisclosureLanguage}
                onChange={value => handleDisclosureUpdate(value, "dcrDisclosureLanguage")}
              />
            </Grid>
            <Grid item container xs={6}>
              <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                <InputLabel>Terms & Conditions:</InputLabel>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Switch
                      checked={disclosures.dcrDisclosureUseDefaultTerms}
                      onChange={() =>
                        handleDisclosureUpdate(
                          !disclosures.dcrDisclosureUseDefaultTerms,
                          "dcrDisclosureUseDefaultTerms"
                        )
                      }
                      name="DefaultLanguage"
                      color="primary"
                    />
                  }
                  label="Default Language"
                />
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  value={disclosures.dcrDisclosureTerms}
                  onChange={value => handleDisclosureUpdate(value, "dcrDisclosureTerms")}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>Authorization Page Language in Spanish:</InputLabel>
              <ReactQuill
                value={disclosures.dcrDisclosureLanguageSpanish}
                onChange={value => handleDisclosureUpdate(value, "dcrDisclosureLanguageSpanish")}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>Spanish Terms & Conditions:</InputLabel>
              <ReactQuill
                value={disclosures.dcrDisclosureTermsSpanish}
                onChange={value => handleDisclosureUpdate(value, "dcrDisclosureTermsSpanish")}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>OCA Personal Guarantee agreement disclosure:</InputLabel>
              <ReactQuill
                value={disclosures.ocaPersonalGuaranteeAgreementDisclosure}
                onChange={value => handleDisclosureUpdate(value, "ocaPersonalGuaranteeAgreementDisclosure")}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Declination Language (Proposals):</InputLabel>
              <ReactQuill
                value={disclosures.proposalsDeclinationDisclosure}
                onChange={value => handleDisclosureUpdate(value, "proposalsDeclinationDisclosure")}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Marketing Disclosure:</InputLabel>
              <ReactQuill
                value={disclosures.dcrMarketingDisclosure}
                onChange={value => handleDisclosureUpdate(value, "dcrMarketingDisclosure")}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={updateDisclosuresLoading}
                onClick={saveDisclosures}>
                Save Disclosures
              </Button>
            </Grid>
          </Grid>
        )}
      </Tile>
      <Snackbar
        open={updateDisclosuresSuccess}
        autoHideDuration={3000}
        onClose={() => setUpdateDisclosuresSuccess(state => !state)}>
        <Alert severity="success">The disclosures have been successfully updated</Alert>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles({
  inputLabel: {
    marginBottom: "8px",
  },
});
