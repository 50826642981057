import React from "react";
import { useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useModal } from "../../../../hooks/useModal";
import { menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { ModalsKeys } from "../../../../global";

export const ShareMenuConfirmationDialog = () => {
  const partnerLinksById = useSelector(menuConstructorSelectors.partnerLinksById);

  const { isOpen, data, handleClose } = useModal(ModalsKeys.AftermarketShareMenuConfirmationDialog);

  const handleSave = () => {
    if (data?.onSave) data?.onSave();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Save and Share the Template?</DialogTitle>
      <DialogContent>
        <Typography>This action will share your menu with the chosen Dealer(s).</Typography>
        <ul>
          {data.formData?.partnerLinksIdsToShareMenu?.map(partnerLinkId => (
            <li>
              <Typography>{partnerLinksById[partnerLinkId].accountName}</Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
