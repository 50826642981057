import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "@apollo/client";
import { CommonDataContext } from "../../../contexts/CommonDataContext";
import _ from "lodash";
import { CardContainer } from "../../../components/shared/CardContainer/CardContainer";
import { ContractTab, DealerTab, EquipmentTab, InvoiceTab } from "./tabs";
import {
  DEACTIVATE_DOC,
  UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION,
  UPDATE_DYNAMICS_VO,
  UPDATE_INVOICE_STATUS_MUTATION,
  UPDATE_VO,
} from "./queries";
import EquipmentInputModal from "components/EquipmentInputModal";
import Modal from "@mui/material/Modal";
import { TabsContainer } from "components/shared/TabsContainer/TabsContainer";
import { TabsOption } from "global";
import { SaleAndEquipmentDetailsTabs } from "./types";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { collectInvoiceFromVO } from "modules/invoice";
import { useNotifications } from "modules/notification";
import { RowDataList } from "components/shared/RowDataList/RowDataList";
import { Typography } from "@mui/material";
import { formatCurrency } from "utils";

interface Props extends Record<string, any> {
  vo: any;
  isInvoiceUpdating?: boolean;
  updateInvoice: VoidFunction;
}

export function InvoiceEquipmentDetailsCard({
  invoiceDoc,
  refetchDocs,
  equipmentData,
  refetchEquipment,
  vo,
  creditApp,
  refetchVO,
  invoiceStatus,
  account,
  vendorProfile,
  refetchCreditApp,
  openEquipmentInputModal,
  setOpenEquipmentInputModal,
  vendorProfilesAssociatedToLender,
  loadingVendorProfilesAssociatedToLender,
  updateInvoice,
  isInvoiceUpdating,
  inventoryActions,
}: Props) {
  const { contentViewType } = useViewTypeContext();
  const { showNotification } = useNotifications();

  const classes = useStyles();

  const [equipmentId, setEquipmentId] = useState<any>(null);

  const [equipments, setEquipments] = useState<any>([]);

  const [greenBorder, setGreenBorder] = useState<any>(false);
  const [startProcess, setStartProcess] = useState<any>();
  const isVpAssignedToVoByLender = vo.vendorProfileId !== vendorProfile.id;
  const [vpAssignedByLender, setVpAssignedByLender] = useState<any>();
  const { isLenderUser } = useContext(CommonDataContext);

  const [updateDynamicsInvoiceStatus] = useMutation(UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION);
  const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS_MUTATION);
  const [deactivateDocRequest] = useMutation(DEACTIVATE_DOC);
  const [updateDynamicsVO, { loading: isUpdatingDynamicsVO }] = useMutation(UPDATE_DYNAMICS_VO);
  const [updateVO, { loading: isUpdatingVO }] = useMutation(UPDATE_VO);

  const [uploadInvoiceModal, setUploadInvoiceModal] = useState<any>(false);

  const handleOpenInvoiceDetailModal = () => {
    if (startProcess === null || startProcess === undefined) {
      setStartProcess("invoice");
    }

    updateInvoice();
  };

  const handleOpenEquipmentInputModal = () => {
    setOpenEquipmentInputModal(true);
  };
  const handleCloseEquipmentInputModal = (responseResult: any) => {
    if (responseResult && responseResult.success) {
      showNotification(equipmentId ? "Equipment details successfully updated" : "Equipment details successfully added");
    }

    if (responseResult && responseResult.error) {
      showNotification("An error has occured", { type: "error" });
    }

    setOpenEquipmentInputModal(false);
  };
  const handleInvoiceDownload = () => {
    const link = document.createElement("a");
    link.href = invoiceDoc.source;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const handleCloseUploadInvoiceModal = () => {
    setUploadInvoiceModal(false);
  };

  const setVODealer = async () => {
    try {
      //TODO: nullify also sales reps and location
      await updateDynamicsVO({
        variables: {
          fieldsObj: {
            vendorProfileId: vpAssignedByLender.dynamicsId,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateVO({
        variables: {
          fieldsObj: {
            vendorProfileId: vpAssignedByLender.id,
            sourceVendorProfileId: vo.vendorProfileId,
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (equipmentData !== null && equipmentData !== undefined && equipmentData.equipments !== null) {
      setEquipments(equipmentData.equipments);
    }
  }, [equipmentData]);

  useEffect(() => {
    if (invoiceStatus === "Requested") {
      setGreenBorder(true);
    } else if (invoiceStatus !== "Requested") {
      setGreenBorder(false);
    }
  }, [invoiceStatus]);

  useEffect(() => {
    if (isVpAssignedToVoByLender && _.some(vendorProfilesAssociatedToLender)) {
      const vpAssignedByLenderToSet = isVpAssignedToVoByLender
        ? _.find(vendorProfilesAssociatedToLender, { id: vo.vendorProfileId })
        : null;
      setVpAssignedByLender(vpAssignedByLenderToSet);
    }
  }, [vendorProfilesAssociatedToLender, isVpAssignedToVoByLender]);

  const handleChangeDoc = async () => {
    try {
      await updateDynamicsInvoiceStatus({
        variables: {
          fieldsObj: {
            dynamicInvoiceStatus: 200,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateInvoiceStatus({
        variables: {
          fieldsObj: {
            invoiceStatus: "Received (Pending Approval)",
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeactivateDoc = async (value: any) => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      await refetchVO();
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };

  if (!!invoiceDoc && !!invoiceDoc.docDescription) {
    invoiceDoc.name = `${invoiceDoc.docDescription.split("#")[1]}`;
    const invType = invoiceDoc.docDescription.split("#")[0];
    invoiceDoc.type = invType.substr(0, 1).toUpperCase() + invType.substr(1);
  }

  const tabs: TabsOption<SaleAndEquipmentDetailsTabs>[] = [
    {
      label: "Invoice",
      value: SaleAndEquipmentDetailsTabs.Invoice,
      isVisible: true,
      component: (
        <InvoiceTab
          invoiceDoc={invoiceDoc}
          refetchDocs={refetchDocs}
          isLoading={isInvoiceUpdating}
          contentViewType={contentViewType}
          handleChangeDoc={handleChangeDoc}
          invoice={collectInvoiceFromVO(vo)}
          handleDeactivateDoc={handleDeactivateDoc}
          handleInvoiceDownload={handleInvoiceDownload}
          handleOpenInvoiceDetailModal={handleOpenInvoiceDetailModal}
          handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
          equipmentDetails={
            Array.isArray(equipmentData?.equipments) && equipmentData?.equipments.length ? (
              <RowDataList
                isLoading={isInvoiceUpdating}
                contentViewType={contentViewType}
                data={equipmentData.equipments?.map((equipment: any) => [
                  <Typography key={equipment.equipmentId} component="span" variant="body2">
                    {equipment.year} {equipment.make} {equipment.model}
                  </Typography>,
                  formatCurrency(equipment.retailPrice),
                ])}
              />
            ) : null
          }
        />
      ),
    },
    {
      label: "Equipment",
      value: SaleAndEquipmentDetailsTabs.Equipment,
      isVisible: true,
      component: (
        <EquipmentTab
          equipmentData={equipmentData}
          vendorProfile={vendorProfile}
          contentViewType={contentViewType}
          onCreateEquipment={() => {
            setEquipmentId(null);
            handleOpenEquipmentInputModal();
          }}
          onUpdateEquipment={equipmentId => {
            setEquipmentId(equipmentId);
            handleOpenEquipmentInputModal();
          }}
        />
      ),
    },
    {
      label: "Contract",
      value: SaleAndEquipmentDetailsTabs.Contract,
      isVisible: vendorProfile.showContract === "show",
      component: <ContractTab vo={vo} refetchVO={refetchVO} contentViewType={contentViewType} />,
    },
    {
      label: "Dealer",
      value: SaleAndEquipmentDetailsTabs.Dealer,
      isVisible: isLenderUser,
      component: (
        <DealerTab
          setVODealer={setVODealer}
          contentViewType={contentViewType}
          vpAssignedByLender={vpAssignedByLender}
          setVpAssignedByLender={setVpAssignedByLender}
          isVpAssignedToVoByLender={isVpAssignedToVoByLender}
          isUpdatingVO={isUpdatingDynamicsVO || isUpdatingVO}
          vendorProfilesAssociatedToLender={vendorProfilesAssociatedToLender}
        />
      ),
    },
  ];

  return (
    <CardContainer
      title="Sale & Equipment Details"
      contentViewType={contentViewType}
      containerClassName={greenBorder ? classes.greenBorder : ""}>
      <TabsContainer<SaleAndEquipmentDetailsTabs> tabs={tabs} />

      <Modal
        open={openEquipmentInputModal}
        onClose={handleCloseEquipmentInputModal}
        aria-labelledby="Equipment Input Modal"
        aria-describedby="Input">
        <EquipmentInputModal
          vo={vo}
          creditApp={creditApp}
          equipmentData={equipmentData}
          handleClose={handleCloseEquipmentInputModal}
          refetchVO={refetchVO}
          equipmentId={equipmentId}
          refetch={refetchEquipment}
          vendorProfile={vendorProfile}
          refetchCreditApp={refetchCreditApp}
          account={account}
          refetchDocs={refetchDocs}
          inventoryActions={inventoryActions}
        />
      </Modal>
    </CardContainer>
  );
}

const useStyles = makeStyles({
  greenBorder: {
    border: "2px solid #3ec179",
  },
  tabsWrapper: {
    borderBottom: "1px solid lightgrey",
  },
});
