import { MarkupType, ProposalProduct, Markup } from "@trnsact/trnsact-shared-types/dist/generated";

class ProductsCalculationManager {
  public calculateCostByRateFactor(rateFactor: number, equipmentCost: number | undefined): number {
    if (typeof equipmentCost !== "number") {
      return 0;
    }

    return Math.max(0, (rateFactor / 100) * equipmentCost);
  }

  public calculateRateFactor(productCost: number, equipmentCost: number | undefined): number {
    if (typeof equipmentCost !== "number") {
      return 0;
    }

    if (equipmentCost < 0) {
      return 0;
    }

    return (productCost / equipmentCost) * 100;
  }

  public getMarkupValueByType(value: number, type: MarkupType): number {
    return type === MarkupType.Percentage ? value / 100 : value;
  }

  public calculateMarkupFlat(retailCost: number, cost: number): number {
    return retailCost - cost;
  }

  public calculateRetailCostByFlatMarkup(cost: number, markup: number): number {
    return Math.max(0, cost + markup);
  }

  public calculateMarkupPercentage(retailCost: number, cost: number): number {
    return (retailCost - cost) / 100;
  }

  public getPrisingFromProduct(product: ProposalProduct, equipmentCost: number | undefined) {
    const rateFactor: number | undefined = product?.aftermarketProduct?.criteria[0]?.event?.params?.RATE_FACTOR;

    if (rateFactor !== undefined) {
      const cost = this.calculateCostByRateFactor(rateFactor, equipmentCost);
      const retailCost = this.calculateRetailCostByFlatMarkup(cost, product?.markup?.markup ?? 0);

      return {
        cost,
        rateFactor,
        retailCost,
        markup: product?.markup ?? { markup: 0, type: "FLAT" },
      };
    }

    return {
      cost: product?.cost ?? 0,
      retailCost: product?.retailCost ?? 0,
      markup: product?.markup ?? { markup: 0, type: "FLAT" },
      rateFactor: this.calculateRateFactor(product?.cost ?? 0, equipmentCost),
    };
  }
}

export const productsCalculationManager = new ProductsCalculationManager();
