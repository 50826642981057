import React from "react";
import { Tooltip } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { green } from "@mui/material/colors";

interface Props {
  isValid: boolean;
}

export const ProposalProductPricingValidationStatus = ({ isValid }: Props) => {
  return (
    <>
      {isValid ? (
        <Tooltip arrow title="All products in this package have passed configuration validation.">
          <CheckCircle style={{ color: green["500"] }} />
        </Tooltip>
      ) : (
        <Tooltip arrow title="Not all products in this package have passed configuration validation.">
          <Cancel color="error" />
        </Tooltip>
      )}
    </>
  );
};
