import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { TableView } from "../TableView";
import { createTableColumns } from "./tableConfig";
import { ProductDetailsTableData } from "../../../types";
import { createAddonsTableColumns } from "./addonsTableConfig";
import { GET_AFTERMARKET_PRODUCTS, GET_DETAILS_FOR_PRODUCTS_TABLE } from "../../../api";
import { prepareProductsDetailsForRender } from "../../../lib/prepareProductsDetailsForRender";
import { ProposalProductsDetailsResponse, ProposalProductsDetailsVariables } from "../../../api/types";

interface Props {
  accountId: string;
  proposalId: string;
}

export const ProductsDetails = ({ proposalId, accountId }: Props) => {
  const { data: details, loading } = useQuery<ProposalProductsDetailsResponse, ProposalProductsDetailsVariables>(
    GET_DETAILS_FOR_PRODUCTS_TABLE,
    { skip: !proposalId, variables: { id: proposalId } }
  );

  const { data: aftermarketProducts } = useQuery(GET_AFTERMARKET_PRODUCTS, {
    skip: !accountId,
    variables: { accountId },
  });

  const { tableData, totals } = useMemo(
    () => prepareProductsDetailsForRender({ response: details, aftermarketProducts }),
    [details, aftermarketProducts]
  );

  const columns = createTableColumns({ totals });
  const addonsColumns = createAddonsTableColumns();

  return (
    <TableView<ProductDetailsTableData>
      loading={loading}
      columns={columns}
      tableData={tableData}
      withPagination={false}
      SubComponent={row => (
        <>
          {!!row.original.addons.length && (
            <TableView minRows={1} withPagination={false} columns={addonsColumns} tableData={row.original.addons} />
          )}
        </>
      )}
    />
  );
};
