import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { ModalsKeys } from "../../../global";
import { useModal } from "../../../hooks/useModal";
import { FinanceProgramFormSteps } from "../types";
import { dialogTitleByFormStep } from "../constants";
import { FinanceProgram } from "./coreForm/FinanceProgram";

export const FinanceProgramModal = () => {
  const classes = useStyles();
  const isNecessaryShowConfirmation = useRef<boolean>(false);
  const [formStep, setFormStep] = useState<FinanceProgramFormSteps>(FinanceProgramFormSteps.First);

  const { isOpen, handleClose, data } = useModal(ModalsKeys.FinanceForm);
  const { handleOpen: handleOpenConfirmDialog } = useModal(ModalsKeys.Confirmation);

  const handleCloseWithConfirmation = () => {
    if (isNecessaryShowConfirmation.current) {
      handleOpenConfirmDialog({
        title: "Are you sure you want to close the Program window?",
        message: "Any unsaved changes will be lost.",
        onConfirm: handleClose,
      });
    } else {
      handleClose();
    }

    isNecessaryShowConfirmation.current = false;
  };

  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth onClose={handleCloseWithConfirmation}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            {data?.program ? data?.program?.first?.displayName : dialogTitleByFormStep[formStep]}
          </Typography>

          <IconButton size="small" onClick={handleCloseWithConfirmation}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers classes={{ dividers: classes.dialogDividers }}>
        <FinanceProgram
          onStepChanged={setFormStep}
          onFormChanged={() => {
            isNecessaryShowConfirmation.current = true;
          }}
          onCancel={handleCloseWithConfirmation}
          {...data}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogDividers: {
    padding: "16px 24px 24px 24px",
  },
});
