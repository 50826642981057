import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Search from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { InputField } from "../../../../../components/form";

interface Props {
  lenders: LenderProfiles[];
  onFiltered: (filteredLenders: LenderProfiles[]) => void;
}

export const LendersFilter = ({ lenders = [], onFiltered }: Props) => {
  const { control, watch } = useForm({
    defaultValues: {
      search: "",
    },
  });

  useEffect(() => {
    const subscription = watch(value => {
      const filteredLenders = lenders.filter(({ lenderName }) =>
        lenderName?.toLocaleLowerCase().includes(value?.search?.toLocaleLowerCase() ?? "")
      );

      onFiltered(filteredLenders);
    });

    return subscription.unsubscribe;
  }, [watch]);

  return (
    <InputField
      name="search"
      label="Search"
      control={control}
      inputProps={{
        variant: "outlined",
        placeholder: "Enter...",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
