import { Invoice } from "../types";
import { InvoiceFormValues } from "../model";
import { invoiceCalculations } from "./invoiceCalculations";

export function getInitialValuesFromExistingInvoice(invoice: Invoice): InvoiceFormValues {
  const { invoiceTotal, netFinanceAmount, netTrade } = invoiceCalculations.byInvoice(invoice);

  return {
    ...invoice,
    netTrade,
    invoiceTotal,
    netFinanceAmount,
  };
}
