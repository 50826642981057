import React from "react";
import { Button, Chip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  isEditingDisabled: boolean;
  isIndividualCreditApp: boolean;
  onConvertToBusiness: VoidFunction;
}

export const ApplicationTypeLayout = ({ onConvertToBusiness, isIndividualCreditApp, isEditingDisabled }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.applicationTypeText}>Application type:</Typography>

      {isIndividualCreditApp ? (
        <>
          <Chip
            size="small"
            label="Individual"
            style={{ backgroundColor: "#88b253", color: "white", marginRight: 8 }}
          />

          {!isEditingDisabled && (
            <Button color="primary" onClick={onConvertToBusiness}>
              Convert to Business
            </Button>
          )}
        </>
      ) : (
        <Chip size="small" label="Business" style={{ backgroundColor: "#374ef4", color: "white" }} />
      )}
    </>
  );
};

const useStyles = makeStyles({
  applicationTypeText: {
    margin: "20px 0 5px",
    textTransform: "none",
    fontSize: "12px",
  },
});
