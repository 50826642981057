import React from "react";
import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import Tile from "../../components/Tile/Tile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import makeStyles from "@mui/styles/makeStyles";
import { format, parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "@apollo/client";
import _, { isEmpty } from "lodash";

/* Statuses:
    - reviewRequired - orange - #ffa233
    - preApproved - green - #36b37e
    - declined - red - #ff5630
*/

const SUBMISSION_DOCS = gql`
  query ($creditSubId: ID, $VOId: ID) {
    creditSubmissionDocuments(creditSubId: $creditSubId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;

const styles = {
  right: {
    textAlign: "right",
  },
  greenButton: {
    margin: 0,
    float: "right",
  },

  fullWidth: {
    width: "100%",
  },
  preApproved: {
    color: "#36b37e",
  },
  reviewRequired: {
    color: "#ffa233",
  },
  declined: {
    color: "#ff5630",
  },
  defaultStatus: {
    color: "#36b37e",
  },
};

const useStyles = makeStyles(styles);

const getDocName = (document, cs, vo) => {
  if (!document) {
    return null;
  }
  const { docName, createdDateTime } = document;
  const createdDateFormatted = format(parseISO(createdDateTime), "yyyy-MM-dd");
  switch (true) {
    case docName?.toLowerCase()?.includes("paynet"):
      return _(["Paynet-Report", cs ? cs.businessName : vo.potentialCustomer.name, createdDateFormatted])
        .filter()
        .join("-");
    case !docName?.toLowerCase()?.includes("paynet"):
      return _([docName, createdDateFormatted]).filter().join("-");
    default:
      return docName;
  }
};

const Report = ({ reportDoc, cs, vo }) => {
  const openDoc = url => window.open(url, "_blank");
  const downloadDoc = url => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "scoreCard_doc.pdf");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="score-card__reports-item">
      <div className="score-card__reports-item-left">
        <PictureAsPdfIcon style={{ color: "#cf2c1e" }} />
        <div className="score-card__reports-item-name">{getDocName(reportDoc, cs, vo)}</div>
      </div>
      <div className="score-card__reports-item-right">
        <div className="score-card__reports-item-right__wrapper">
          <IconButton edge="end" onClick={() => openDoc(reportDoc?.source)} size="large">
            <div className="score-card__reports-item-right__img">
              <LaunchRoundedIcon />
            </div>
          </IconButton>
          <IconButton edge="end" onClick={() => downloadDoc(reportDoc?.source)} size="large">
            <div className="score-card__reports-item-right__img">
              <GetAppRoundedIcon />
            </div>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const getStatusClass = status => {
  switch (status) {
    case "Pre-Approved":
      return "preApproved";
    case "Review Required":
      return "reviewRequired";
    case "Declined":
      return "declined";
    default:
      return "defaultStatus";
  }
};

const ScoreCard = ({ cs, vo, scorecardConfig, userProfile }) => {
  const classes = useStyles();

  let inputs = [];
  let outputs = [];

  if (cs) {
    inputs = _.get(cs, "fastTrackResults.inputs", []);
    outputs = _.get(cs, "fastTrackResults.outputs", []);
  } else {
    inputs = _.get(vo, `fastTrackResults[${vo.fastTrackResults.length - 1}].inputs`, []);
    outputs = _.get(vo, `fastTrackResults[${vo.fastTrackResults.length - 1}].outputs`, []);
  }

  const scorecardReportsUserRolesHidden = _.get(scorecardConfig, "rolesSettings.reportsHidden");
  const isReportsHiddenByUserRole = _.includes(scorecardReportsUserRolesHidden, userProfile.vendorContactRole);

  const { loading: isDocsLoading, data: csDocsData } = useQuery(SUBMISSION_DOCS, {
    variables: {
      creditSubId: cs ? cs.creditSubId : undefined,
      VOId: vo.vendorOpportunityId,
    },
  });

  let scoreCardStatus = "Awaiting Review"; // Default ScoreCard status

  if (outputs && outputs?.find(output => output?.key === "Decision")?.value) {
    console.log("Before scoreCardStatus");
    scoreCardStatus = outputs.find(output => output.key === "Decision").value;
    console.log("After scoreCardStatus");
  }

  if (_.some([inputs, outputs, csDocsData?.creditSubmissionDocuments], isEmpty)) {
    if (cs) {
      return null;
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tile title="Scorecard" useCollapsing={true} className="score-card__main-title">
            <Grid>
              <Grid container className="score-card__decision-wrapper">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="score-card__decision-text">Decision:</span>
                  <span className={`score-card__decision-status ${classes[getStatusClass(scoreCardStatus)]}`}>
                    {scoreCardStatus}
                  </span>
                </Grid>
              </Grid>
              {!isReportsHiddenByUserRole && (
                <Grid container className="score-card__reports-container">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className="score-card__reports-title">Reports</p>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div>
                      {csDocsData?.creditSubmissionDocuments?.length &&
                      csDocsData?.creditSubmissionDocuments?.filter(doc => doc?.docType === "Credit Report")?.length &&
                      !isDocsLoading ? (
                        csDocsData?.creditSubmissionDocuments
                          .filter(doc => doc?.docType === "Credit Report")
                          .map(document => <Report key={document.documentId} reportDoc={document} cs={cs} vo={vo} />)
                      ) : (
                        <p>{isDocsLoading ? "Loading..." : `No reports yet`}</p>
                      )}
                    </div>
                  </Grid>
                </Grid>
              )}
              <Grid container className="score-card__decisioning-variables">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="score-card__decisioning-title">Decisioning Variables</div>
                </Grid>
              </Grid>
              <>
                <Grid item container class="score-card__decisioning-content">
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} container className="score-card__decisioning-left">
                    <div className="score-card__decisioning-input-text">Inputs:</div>
                    <div className="score-card__decisioning-input-value">
                      {inputs &&
                        inputs.map(item => {
                          return (
                            <div key={item.key} className="score-card__decisioning-input-item">
                              <div className="score-card__input-item-left">{item.key}:</div>
                              <div className="score-card__input-item-right">{item.value}</div>
                            </div>
                          );
                        })}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} container className="score-card__decisioning-right">
                    <div className="score-card__decisioning-output-text">Outputs:</div>
                    <div className="score-card__decisioning-output-value">
                      {outputs &&
                        outputs.map(item => {
                          return (
                            <div key={item.key} className="score-card__decisioning-input-item">
                              <div className="score-card__input-item-left">{item.key}:</div>
                              <div className="score-card__input-item-right">{item.value}</div>
                            </div>
                          );
                        })}
                    </div>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Tile>
        </Grid>
      </Grid>
    </div>
  );
};

export default ScoreCard;
