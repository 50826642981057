import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import noImage from "assets/img/icons/noimage.png";
import Tile from "../../components/Tile/Tile";
import { useLazyQuery } from "@apollo/client";
import { connect } from "react-redux";
import { setRefetch } from "../../redux/actions";
import MessageModal from "../../components/MessageModal";
import gears from "assets/img/gears.gif";
import clsx from "clsx";
const config = require("../../config");

const Q_LENDER_MATCHING_PROFILES = gql`
  query ($dynamicsId: ID!, $dynamicsCreditApplicationId: ID!) {
    getLenderMatchingProfiles(dynamicsId: $dynamicsId, dynamicsCreditApplicationId: $dynamicsCreditApplicationId)
  }
`;

const M_SUBMIT_TO_LENDER = gql`
  mutation ($input: CreditSubmissionInput!) {
    createCreditSubmission(input: $input)
  }
`;

const M_CREATE_PRESCREEN = gql`
  mutation ($VOId: ID!) {
    triggerVOPrescreen(VOId: $VOId)
  }
`;

const SEND_FEATURE_REQUEST = gql`
  mutation ($input: sendFeatureRequestInput!) {
    sendFeatureRequest(input: $input) {
      success
    }
  }
`;

const useStyles = makeStyles(theme => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },

  slContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  slRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderRadius: 6,
    padding: 5,
    margin: "10px 0",
    boxShadow: "0 0 6px rgba(0,0,0,.2)",
    overflow: "hidden",
    position: "relative",
    "&:before": {
      display: "block",
      content: '" "',
      position: "absolute",
      left: 0,
      top: 0,
      width: 3,
      height: "100%",
      backgroundColor: "#ddd",
    },
  },
  slRowPass: {
    "&:before": {
      backgroundColor: "#3bc173",
    },
  },
  slRowFail: {
    "&:before": {
      backgroundColor: "#a71818",
    },
  },
  semaphore: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
    backgroundColor: "#969393",
  },
  redSemaphore: {
    backgroundColor: "#bb3030",
  },
  yellowSemaphore: {
    backgroundColor: "#fbd66e",
  },
  greenSemaphore: {
    backgroundColor: "#5ac183",
  },

  slHeadRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSize: 16,
    fontWeight: 500,
  },
  slCell: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colLogo: {
    width: 105,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  colInfo: {
    flexGrow: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  colOutcome: {
    width: 200,
    justifyContent: "center",
  },
  colSubmit: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 200,
  },
  logoRect: {
    width: 100,
    height: 50,
    borderRadius: 6,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lenderLogo: {
    borderRadius: 6,
    height: "75px",
    maxWidth: "100%",
  },
  lmeButtonGreen: {
    backgroundColor: "#3bc173",
  },
  lmeButtonLightRed: {
    backgroundColor: "#f67679",
  },
  gearsRunningText: {
    color: theme.palette.primary.main,
    textShadow: "#a3b6ca 5px 5px 5px",
    fontSize: "calc(2em + 1vw)",
    width: "50%",
    margin: "0",
    position: "absolute",
    top: "40%",
    left: "50%",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  gears: {
    marginLeft: "20%",
    width: "30%",
    height: 300,
    backgroundImage: `url(${gears})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "@media (max-width: 768px)": {
      width: "100%",
      marginLeft: "0",
    },
  },
}));

function LenderMatchingEngine({ creditApp, vo, account, vp, userProfile, setRefetch }) {
  const classes = useStyles();
  const [lenders, setLenders] = useState([]);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: "", message: "" });
  const [lmeInProgress, setLmeInProgress] = useState(false);
  const [runEngineBtnDisabled, setRunEngineBtnDisabled] = useState(false);
  const [getLenders, { loading: lendersLoading, error: lendersError, data: lendersData, refetch: lendersDataRefetch }] =
    useLazyQuery(Q_LENDER_MATCHING_PROFILES, {
      context: { authRequired: true },
    });
  const [submitToLenderRequest] = useMutation(M_SUBMIT_TO_LENDER, {
    context: { authRequired: true },
  });

  const [submitPrescreen] = useMutation(M_CREATE_PRESCREEN);

  const [sendFeatureRequest] = useMutation(SEND_FEATURE_REQUEST, {
    context: { authRequired: true },
  });

  if (lendersLoading) console.log("Loading prescreen...");
  if (lendersError) console.log(`Error! ${lendersError.message}`);

  useEffect(() => {
    if (!!creditApp && !!creditApp.dynamicsId && !!vp && !!vp.dynamicsId) {
      getLenders({
        variables: {
          dynamicsId: vp.dynamicsId,
          dynamicsCreditApplicationId: creditApp.dynamicsId,
        },
      });
    }
    if (
      !!lendersData &&
      !!lendersData.getLenderMatchingProfiles &&
      !!lendersData.getLenderMatchingProfiles.lenderProfiles
    ) {
      const lmpLenders = lendersData.getLenderMatchingProfiles.lenderProfiles;
      const lmpValue = lendersData.getLenderMatchingProfiles.value;

      setRunEngineBtnDisabled(!!lmpValue.length);

      const processedLenders = lmpLenders.map(lender => {
        const lenderValue = lmpValue.find(val => val.lenderProfile === "LP - " + lender.lenderName);
        return { ...lender, ...lenderValue };
      });
      setLenders(processedLenders);
    }
  }, [getLenders, creditApp, lendersData, vp]);

  const submitPrescreens = async () => {
    try {
      setLmeInProgress(true);
      await submitPrescreen({
        variables: {
          VOId: vo.dynamicsVendorOpportunityId,
        },
      });

      for (let pollingTimes = 0; pollingTimes <= 25; pollingTimes++) {
        await lendersDataRefetch();
        await new Promise(resolve => setTimeout(resolve, 2000));
        if (lendersData.getLenderMatchingProfiles.value.length > 0) {
          break;
        }
      }

      setLmeInProgress(false);
    } catch (err) {
      setLmeInProgress(false);
      console.log(err);
    }
  };

  const creditSubmissionsTileData = {
    title: "Lender Matching Engine",
    titleButtonProps: {
      disabled: runEngineBtnDisabled,
      color: "primary",
      style: { width: "10%" },
    },
    titleButtonText:
      vp.dcrPrescreenStatus === "804790002"
        ? "Run Engine"
        : vp.dcrPrescreenStatus === "804790003"
          ? "Request Access"
          : null,
    titleButtonFunc:
      vp.dcrPrescreenStatus === "804790002"
        ? submitPrescreens
        : vp.dcrPrescreenStatus === "804790003"
          ? () => requestFeature()
          : null,
  };

  const handleOpenMessageModal = (timeout = false) => {
    setOpenMessageModal(true);
    if (timeout) {
      setTimeout(() => {
        handleCloseMessageModal();
      }, 2000);
    }
  };

  const handleCloseMessageModal = () => setOpenMessageModal(false);

  const handleSubmissionMessage = ({ title = "", message = "" }) => {
    setModalMessage({ title, message });
    handleOpenMessageModal();
  };

  const submitCreditApp = async lender => {
    try {
      handleSubmissionMessage({
        title: "Submitting",
        message: "Please wait. Submitting your request to lenders...",
      });
      handleOpenMessageModal();
      await submitToLenderRequest({
        variables: {
          input: {
            accountId: account.id,
            amountRequested: vo.invoiceTotal,
            dynamicsUserId: "to_be_change_by_server",
            dynamicsVOId: vo.dynamicsVendorOpportunityId,
            lenderContactId: lender.lenderContactId,
            lenderProfileId: lender.lenderProfileId,
            submittedLender: lender.lenderAccountId,
          },
        },
      });
      setRefetch("pastSubmissions", true);
      handleCloseMessageModal();
      handleSubmissionMessage({
        title: "Success",
        message: "Your request successfully submitted to lender.",
      });
      handleOpenMessageModal(true);
    } catch (err) {
      handleCloseMessageModal();
      console.log(err);
      handleSubmissionMessage({
        title: "Error",
        message: "Something went wrong!",
      });
      handleOpenMessageModal(true);
    }
  };

  const requestFeature = async () => {
    try {
      handleOpenMessageModal(true);
      handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Sending your feature request...",
      });
      await sendFeatureRequest({
        variables: {
          input: {
            feature: "LME",
            dynamicsContactId: userProfile.dynamicsContactId,
            accountName: account.name,
          },
        },
      });
      handleSubmissionMessage({
        title: "Success",
        message: "Sent Successfully",
      });
    } catch (err) {
      console.log(err);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  return (
    <Tile {...creditSubmissionsTileData} useCollapsing={true}>
      {lendersLoading ? (
        <>Loading lenders...</>
      ) : (
        <>
          {lmeInProgress ? (
            <div className={classes.gears}>
              <h4 className={classes.gearsRunningText}>
                Engine <b>running</b> ...
              </h4>
            </div>
          ) : (
            <>
              {!!lenders && !!lenders.length && (
                <div className={classes.slContainer}>
                  <div className={classes.slHeadRow}>
                    <div className={classes.slCell + " " + classes.colInfo}>Available Lenders</div>
                    <div className={classes.slCell + " " + classes.colOutcome}>LME Outcome</div>
                    <div className={classes.slCell + " " + classes.colSubmit}>Action</div>
                  </div>
                  {lenders.map((lender, index) => (
                    <div className={classes.slRow} key={"lender-row-" + index}>
                      <div className={classes.slCell + " " + classes.colLogo}>
                        <div className={classes.logoRect}>
                          <img
                            onError={e => {
                              if (e.target.src !== noImage) {
                                e.target.src = noImage;
                              }
                            }}
                            src={
                              lender.lenderLogo
                                ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lender.lenderLogo}`
                                : noImage
                            }
                            className={classes.lenderLogo}
                            alt={lender.lenderName}
                          />
                        </div>
                      </div>

                      <div className={classes.slCell + " " + classes.colInfo}>
                        <b>{lender.lenderName}</b>
                        <br />
                      </div>
                      <div className={classes.slCell + " " + classes.colOutcome}>
                        <span
                          className={clsx(classes.semaphore, {
                            [classes.greenSemaphore]: lender.prescreenOutCome === "Pass",
                            [classes.redSemaphore]:
                              !!lender.prescreenOutCome && lender.prescreenOutCome.includes("not"),
                          })}></span>
                        {lender.prescreenOutCome}
                      </div>
                      <div className={classes.slCell + " " + classes.colSubmit}>
                        {!lender.ccg_decision && (
                          <Button
                            onClick={() => submitCreditApp(lender)}
                            disabled={vp.dcrPrescreenStatus === "804790003"}>
                            Submit to lender
                          </Button>
                        )}
                        {lender.ccg_decision === 803370000 && <>Submitted & Awaiting Decision</>}
                        {lender.ccg_decision === 803370001 && <>Requested Additional Information</>}
                        {lender.ccg_decision === 803370002 && (
                          <Button className={classes.lmeButtonLightRed}>Declined</Button>
                        )}
                        {lender.ccg_decision === 803370003 && (
                          <Button className={classes.lmeButtonGreen}>Approved</Button>
                        )}
                        {lender.ccg_decision === 803370004 && (
                          <Button className={classes.lmeButtonGreen}>Pre-Approved</Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <MessageModal
                isOpen={openMessageModal}
                handleCloseModal={handleCloseMessageModal}
                title={modalMessage.title}
                message={modalMessage.message}
              />
            </>
          )}
        </>
      )}
    </Tile>
  );
}

const mapStateToProps = state => {
  return {
    creditApp: state.creditApp,
    vo: state.vo,
    vp: state.vp,
    account: state.account,
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(LenderMatchingEngine);
