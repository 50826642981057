import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  RadioProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

interface RadioGroupOptions {
  value: string;
  label: string;
}

interface Props extends RadioGroupProps {
  label?: string;
  radioProps?: RadioProps;
  options: RadioGroupOptions[];
  formControlProps?: FormControlProps;
}

export const FormRadioGroup = ({
  label,
  options,
  radioProps = {},
  formControlProps = {},
  ...radioGroupProps
}: Props) => {
  const classes = useStyles();

  return (
    <FormControl {...formControlProps}>
      {!!label && (
        <FormLabel classes={{ root: classes.formLabelRoot }}>
          <Typography component="span" variant="subtitle2">
            {label}
          </Typography>
        </FormLabel>
      )}

      <RadioGroup {...radioGroupProps}>
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            label={<Typography variant="body2">{label}</Typography>}
            control={<Radio color="primary" {...radioProps} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  formLabelRoot: {
    color: palette.common.black,
  },
}));
