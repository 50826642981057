import React, { useState, FC, PropsWithChildren, ReactNode, useEffect } from "react";
import { Box, Tabs, Tab, TabsProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ContentViewType, SelectOption, TabsOption } from "../../../global";
import { FormInputSelect } from "../../form";

interface Props<TabsValue> {
  tabProps?: TabsProps;
  initialTab?: TabsValue;
  forceTabValue?: TabsValue;
  tabs: TabsOption<TabsValue>[];
  contentViewType?: ContentViewType;
  TabsLabelWrapper?: FC<PropsWithChildren>;
  onTabChange?: (nextTabValue: TabsValue) => void;
}

export const TabsContainer = <TabsValue extends string = string>({
  tabs,
  tabProps,
  initialTab,
  onTabChange,
  forceTabValue,
  TabsLabelWrapper,
  contentViewType = ContentViewType.Desktop,
}: Props<TabsValue>) => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState<TabsValue>(initialTab ?? tabs?.[0]?.value);

  const handleChangeTab = (nextTabValue: TabsValue) => {
    setCurrentTab(nextTabValue);

    if (onTabChange) onTabChange(nextTabValue);
  };

  const tabsOptions = tabs
    .filter(({ isVisible }) => isVisible)
    .map(({ label, value }) => ({ label: label.toUpperCase(), value })) as SelectOption<TabsValue>[];

  useEffect(() => {
    if (forceTabValue) handleChangeTab(forceTabValue);
  }, [forceTabValue]);

  const tabsComponentByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Desktop]: (
      <Tabs
        variant="scrollable"
        value={currentTab}
        indicatorColor="primary"
        onChange={(_, nextTabValue) => handleChangeTab(nextTabValue)}
        {...tabProps}>
        {tabs
          .filter(({ isVisible }) => isVisible)
          .map(({ label, value, disabled = false }) => (
            <Tab key={label} label={label} value={value} disabled={disabled} />
          ))}
      </Tabs>
    ),
    [ContentViewType.Mobile]: (
      <FormInputSelect<TabsValue> value={currentTab} options={tabsOptions} onChange={handleChangeTab} />
    ),
  };

  return (
    <>
      <Box className={classes.tabsWrapper}>
        {TabsLabelWrapper ? (
          <TabsLabelWrapper>{tabsComponentByViewType[contentViewType]}</TabsLabelWrapper>
        ) : (
          tabsComponentByViewType[contentViewType]
        )}
      </Box>

      <Box>
        {tabs
          .filter(({ isVisible }) => isVisible)
          .map(({ value, component }) => (
            <Box key={value}>{value === currentTab && <>{component}</>}</Box>
          ))}
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  tabsWrapper: {
    borderBottom: "1px solid lightgrey",
  },
});
