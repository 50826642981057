import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useNotifications } from "modules/notification";
import { UPDATE_PROPOSAL } from "../api";
import { logError } from "../../../utils/logger";

export function useProposal() {
  const { showNotification } = useNotifications();

  const [updateProposal, { loading: isProposalUpdating }] = useMutation(UPDATE_PROPOSAL, {
    context: { authRequired: true },
    refetchQueries: ["Proposal"],
  });

  const onUpdateProposal = useCallback(async (id: string, fieldsObj: Record<string, any>) => {
    try {
      await updateProposal({ variables: { id, fieldsObj } });

      showNotification("Status updated!");
    } catch (error) {
      logError(error);
      showNotification("Status not updated!", { type: "error" });
    }
  }, []);

  return {
    onUpdateProposal,
    isProposalUpdating,
  };
}
