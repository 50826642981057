import React, { useState } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
const useStyles = makeStyles(() => ({
  signFieldStyles: {
    "& .MuiInput-root": {
      fontFamily: "'Homemade Apple', cursive",
    },
  },
}));

export function SignField({ fieldConfig }) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  return (
    <TextField
      style={{ pointerEvents: "none" }}
      disabled={fieldConfig.disabled}
      className={classes.signFieldStyles}
      fullWidth={fieldConfig.fullWidth}
      variant="standard"
      label={_.get(fieldConfig, "displayLabel.en", "")}
      helperText={_.get(fieldConfig, "helperText.en", "")}
      error={false}
      required={fieldConfig.required}
    />
  );
}
