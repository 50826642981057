import React from "react";
import { Column } from "react-table";
import { CellArgs } from "../../../types";
import { HubProposal } from "../../../api/types";
import { formatCurrency } from "../../../../../utils";
import { getPersonaName } from "../../../lib/getPersonaName";
import { hubSearchFilterByColumn } from "../../../lib/hubSearchFilters";
import { MultiSelectFilter } from "../../components/ColumnMultiSelectFilter";
import { SimpleTextCell, StageCell, StatusCell, DateCell, CustomerCell, ActionCell } from "../cells";
import { proposalSaleTypeToViewText } from "../../../constants";

interface Options {
  tableData: HubProposal[];
  onGoToProposal: (proposal: HubProposal) => void;
  onGoToProposalDetail: (proposal: HubProposal) => void;
}

type HubProposalCellArgs = CellArgs<HubProposal>;

export function createTableColumns({
  onGoToProposal,
  onGoToProposalDetail,
  tableData,
}: Options): Column<HubProposal>[] {
  return [
    {
      sortable: true,
      Header: "Created",
      accessor: "createdDateTime",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => <DateCell proposal={original} />,
    },
    {
      sortable: true,
      Header: "Customer",
      accessor: "businessName",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => <CustomerCell proposal={original} />,
    },
    {
      sortable: true,
      Header: "Contact",
      accessor: "firstName",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => <SimpleTextCell value={getPersonaName(original)} />,
    },
    {
      sortable: false,
      Header: "Sale Type",
      accessor: "saleType",
      Cell: ({ original }: HubProposalCellArgs) => (
        <SimpleTextCell value={original.saleType ? proposalSaleTypeToViewText[original.saleType] : null} />
      ),
    },
    {
      sortable: true,
      filterable: true,
      Header: "Sales Rep",
      style: { textAlign: "center" },
      filterMethod: hubSearchFilterByColumn,
      accessor: "vendorOpportunity.salesRepresentative.fullName",
      Cell: ({ original }: HubProposalCellArgs) => (
        <SimpleTextCell value={getPersonaName(original?.vendorOpportunity?.salesRepresentative)} />
      ),
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter filter={filter} onChange={onChange} column={column} rows={tableData} />
      ),
    },
    {
      sortable: true,
      filterable: true,
      Header: "Finance Manager",
      headerStyle: { minWidth: "10rem" },
      filterMethod: hubSearchFilterByColumn,
      style: { minWidth: "10rem", textAlign: "center" },
      accessor: "vendorOpportunity.financeManager.fullName",
      Cell: ({ original }: HubProposalCellArgs) => (
        <SimpleTextCell value={getPersonaName(original?.vendorOpportunity?.financeManager)} />
      ),
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter filter={filter} onChange={onChange} column={column} rows={tableData} />
      ),
    },
    {
      sortable: true,
      filterable: true,
      Header: "Location",
      style: { textAlign: "center" },
      filterMethod: hubSearchFilterByColumn,
      accessor: "vendorOpportunity.location.locationName",
      Cell: ({ original }: HubProposalCellArgs) => (
        <SimpleTextCell value={original?.vendorOpportunity?.location?.locationName} />
      ),
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter filter={filter} column={column} rows={tableData} onChange={onChange} />
      ),
    },
    {
      sortable: true,
      Header: "Amount",
      accessor: "amountRequested",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => (
        <SimpleTextCell formatter={formatCurrency} value={original.amountRequested} />
      ),
    },
    {
      sortable: true,
      filterable: true,
      Header: "Opportunity Stage",
      headerStyle: { minWidth: "11rem" },
      filterMethod: hubSearchFilterByColumn,
      style: { minWidth: "11rem", textAlign: "center" },
      accessor: "vendorOpportunity.applicationStage",
      Cell: ({ original }: HubProposalCellArgs) => <StageCell proposal={original} />,
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter
          filter={filter}
          column={column}
          rows={tableData}
          onChange={onChange}
          labelFormatter={label => label.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
        />
      ),
    },
    {
      sortable: true,
      filterable: true,
      accessor: "status",
      Header: "Proposal Status",
      headerStyle: { minWidth: "11rem" },
      filterMethod: hubSearchFilterByColumn,
      style: { minWidth: "11rem", textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => <StatusCell proposal={original} />,
      Filter: ({ filter, onChange, column }: any) => (
        <MultiSelectFilter filter={filter} onChange={onChange} column={column} rows={tableData} />
      ),
    },
    {
      sortable: true,
      Header: "Created By",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => <SimpleTextCell value={original.salesPersonName} />,
    },
    {
      sortable: false,
      filterable: false,
      Header: "Actions",
      style: { textAlign: "center" },
      Cell: ({ original }: HubProposalCellArgs) => (
        <ActionCell proposal={original} onGoToProposal={onGoToProposal} onGoToProposalDetail={onGoToProposalDetail} />
      ),
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
