import React, { ReactNode } from "react";
import { FinanceProgramRateType, FinanceProgramType, PaymentPeriodTiming } from "@trnsact/trnsact-shared-types";
import { FormSummaryInfo } from "../FormSummaryInfo";
import { getLabelFromEnum } from "../../../../../lib";
import { FinanceProgramForm } from "../../../../../schema";
import { MarkupMethods, Structures } from "../../../../../types";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const ConfigureStructureSummaryPart = ({ financeProgramFormValues: program }: Props) => {
  const renderConfiguration: Record<string, ReactNode> = {
    "Rate Type": getLabelFromEnum(FinanceProgramRateType, program.second.rateType),
    "Buy Rate (%)": program.second.rate,
    Method: getLabelFromEnum(MarkupMethods, program.second.method),
    "Program Type": getLabelFromEnum(FinanceProgramType, program.second.programType),
    "Payment Calculation Method": getLabelFromEnum(PaymentPeriodTiming, program.second.paymentCalculationMethod),
    "Terms in Month": program.second.terms?.length ? program.second.terms?.map(term => term)?.join(", ") : null,
    Structure: getLabelFromEnum(Structures, program.second.structure),
    "Dealer Markup (%)": program.second.dealerMarkup,
    "Advance Payment (# of)": program.second.advancePayment,
    "Days To First Payment": program.second.daysToFirstPayment,
  };

  return <FormSummaryInfo data={renderConfiguration} />;
};
