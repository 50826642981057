import React from "react";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Tile from "../../components/Tile/LenderSubmissionsTile";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    width: "calc(100% - 60px)",
    margin: "0px 30px 30px",
    boxShadow: "none",
    borderRadius: "7px",
  },
  titleStyles: {
    fontSize: "20px",
    fontWeight: "300",
    textTransform: "none",
    color: "#000000",
  },
  cardHeader: {
    marginLeft: "5px !important",
    marginRight: "5px !important",
  },
  row: {
    border: "none",
  },
}));

function LenderSubmissionSubmittedValues({ initCollapsed, creditSubmission }) {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={12} style={{ width: "100%" }}>
        <Paper className={classes.container} style={{ marginBottom: 0 }}>
          <Tile
            title={
              <Typography variant="h6" className={classes.titleStyles}>
                Data Submitted to Lender
              </Typography>
            }
            useCollapsing={true}
            initCollapsed={initCollapsed}
            classNameHeader={classes.cardHeader}>
            <CardContent style={{ padding: 0 }}>
              <Grid item xs={12} className={classes.detailInput}>
                {creditSubmission.mappedValues.map(mv => {
                  return (
                    <Grid key={mv.details.displayName} container spacing={4}>
                      <Grid item xs={4}>
                        {mv.details.displayName}
                      </Grid>
                      <Grid item xs={4}>
                        {mv.details.displayName.toLowerCase().includes("password") ? "*********" : mv.mappedValue}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Tile>
        </Paper>
      </Grid>
    </>
  );
}

export default LenderSubmissionSubmittedValues;
