import React, { useEffect, useState } from "react";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddOutlined } from "@mui/icons-material";

const styles = {
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
};

const useStyles = makeStyles(styles);

const NumberInputList = ({ label, onNumbersChange, onBlur, required, value, disabled, selectType = null }) => {
  const classes = useStyles();
  const [numbers, setNumbers] = useState([]);
  const [isOtherNumber, setIsOtherNumber] = useState(false);
  const [otherNumber, setOtherNumber] = useState("");
  const [terms, setTerms] = useState(["12", "24", "36", "48", "60", "72", "84", "Other"]);

  const addNumber = e => {
    const value = e.target.value;

    if (value.includes("Other")) {
      setIsOtherNumber(true);
    } else {
      setNumbers(value);
      onNumbersChange(value); // Notify the parent component about the updated numbers
    }
  };

  const addOtherNumber = value => {
    let newTerms = [...terms];

    _.remove(newTerms, n => n === "Other");

    newTerms = newTerms.map(n => parseInt(n));
    newTerms.push(value);
    newTerms.sort((a, b) => a - b);

    newTerms = newTerms.map(n => n.toString());
    newTerms.push("Other");
    console.log("NewTerms", newTerms);

    setTerms(newTerms);

    let newNumbers = [...numbers, value.toString()];
    console.log("NewNunbers", newNumbers);
    setNumbers(newNumbers);
    onNumbersChange(newNumbers);

    setIsOtherNumber(false);
    setOtherNumber("");
    onBlur();
  };

  useEffect(() => {
    if (value && numbers.length === 0) {
      let initValue = value ? (typeof value[0] === "number" ? value.map(v => v.toString()) : value) : [];
      setNumbers(initValue);
    } else {
      setNumbers(_.map(value, _.toString));
    }
  }, [value]);

  return (
    <div>
      {!isOtherNumber ? (
        <FormControl fullWidth size="small" variant={selectType ?? "outlined"}>
          <InputLabel required={required} id="numbers-input-list-label">
            {label}
          </InputLabel>
          <Select
            value={numbers}
            onBlur={onBlur}
            onChange={addNumber}
            id="numbers-input-list"
            labelId="numbers-input-list-label"
            variant={selectType ?? "outlined"}
            renderValue={selected =>
              selected
                .sort((a, b) => {
                  return a < b;
                })
                .join(", ")
            }
            label={label}
            multiple
            required={required}
            disabled={disabled}>
            {terms.map(number => (
              <MenuItem key={number} value={number}>
                {number !== "Other" && <Checkbox checked={numbers.indexOf(number) > -1} />}
                <ListItemText primary={number} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          className={classes.textField}
          label="Please provide term"
          variant="outlined"
          type="number"
          value={otherNumber}
          onChange={e => setOtherNumber(e.target.value)}
          fullWidth
          margin="normal"
          onBlur={() => addOtherNumber(otherNumber)}
          inputRef={input => input && input.focus()}
          slotProps={{
            input: {
              endAdornment: <AddOutlined onClick={() => addOtherNumber(otherNumber)} style={{ cursor: "pointer" }} />,
            },

            inputLabel: {
              shrink: true,
              style: { width: "100%" },
            },
          }}
        />
      )}
    </div>
  );
};

export default NumberInputList;
