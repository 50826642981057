import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  TableContainer,
  TableHead,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import { ModalsKeys } from "global";
import { connect } from "react-redux";
import { Form } from "react-final-form";
import { useModal } from "hooks/useModal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { convertToDate, formatCurrency, formatMoney, setFieldValue, validateValue } from "../../utils";
import { calculateRatesAndPayments } from "../../paymentSelectorMath";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { ModalsContainer } from "components/shared/Modals";
import { FormInput } from "components/form";

import {
  FinanceProgramModal,
  mapCriteriaFormValuesToCreateInput,
  mapFinanceProgramEntityToFormValues,
  mapFormValuesToUpdateFinanceProgramInput,
  PreviewFinanceProgramModal,
} from "modules/financeProgram";
import {
  PaymentOptionsDetails,
  ProposalMenuOptionDetailsDialog,
  proposalSaleTypeToViewText,
  ProductsDetails,
  ProposalContractDetailsDialog,
  useProposal,
} from "modules/proposals";
import { useNotifications } from "modules/notification";
import { logError } from "utils/logger";
import {
  GET_FINANCE_PROGRAMS,
  UPDATE_FINANCE_PROGRAM,
  UPDATE_FASTTRACK_STATUS,
  M_Create_Prescreen_Criteria,
  Q_Prescreen_Criteria,
  Q_PROPOSAL,
  M_SEND_PROPOSAL,
  DEACTIVATE_PROPOSAL,
} from "./api";
import { NetworkStatus, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import moment from "moment/moment";
import { isNotNullOrUndefined } from "../../utils/typeGuargs";

const config = require("../../config.js");

function ProposalDetails({ account, vo, vp }: any) {
  const { propId } = useParams<{ propId: string }>();

  const location = useLocation();
  const history = useHistory();
  const prevPage = new URLSearchParams(location.search).get("from") || "/";

  const { showNotification } = useNotifications();
  const { isProposalUpdating, onUpdateProposal } = useProposal();

  const client = useApolloClient();

  const numberWithCommas = (x: any) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const [criteriaOptions, setCriteriaOptions] = useState([]);

  const { handleOpen: handleOpenFinanceFormModal, handleClose: handleCloseFinanceFormModal } = useModal(
    ModalsKeys.FinanceForm
  );
  const { handleOpen: handleOpenFinanceProgramPreviewModal } = useModal(ModalsKeys.PreviewFinanceForm);
  const { handleOpen: handleOpenProposalMenuOptionDetailsDialog } = useModal(
    ModalsKeys.ProposalMenuOptionDetailsDialog
  );
  const { handleOpen: handleOpenContractDetailsDialog } = useModal(ModalsKeys.ProposalContractDetailsDialog);

  const modalsWithKeys = {
    [ModalsKeys.FinanceForm]: <FinanceProgramModal />,
    [ModalsKeys.PreviewFinanceForm]: <PreviewFinanceProgramModal />,
    [ModalsKeys.ProposalContractDetailsDialog]: <ProposalContractDetailsDialog />,
    [ModalsKeys.ProposalMenuOptionDetailsDialog]: <ProposalMenuOptionDetailsDialog />,
  };

  const [updateLenderProgram] = useMutation(UPDATE_FINANCE_PROGRAM, { context: { authRequired: true } });
  const [createPrescreenCriteria] = useMutation(M_Create_Prescreen_Criteria, { context: { authRequired: true } });
  const [sendProposal] = useMutation(M_SEND_PROPOSAL, { context: { authRequired: true } });
  const [deactivateProposal] = useMutation(DEACTIVATE_PROPOSAL, { context: { authRequired: true } });
  const [updateFastTrackStatus] = useMutation(UPDATE_FASTTRACK_STATUS, { context: { authRequired: true } });

  useQuery(Q_Prescreen_Criteria, {
    variables: { accountId: account.id },
    onCompleted(data) {
      const setOfCriteria = data?.prescreenCriteria ?? [];

      const options = setOfCriteria.map((criteria: any) => ({
        label: criteria.name,
        value: criteria.prescreenCriteriaId,
        createdDateTime: criteria?.createdDateTime,
        formRules: criteria?.jsonCriteria?.formRules ?? [],
      }));

      setCriteriaOptions(options);
    },
  });

  const {
    networkStatus,
    data: proposalDetails,
    loading: isProposalDetailsLoading,
  } = useQuery(Q_PROPOSAL, {
    variables: { id: propId },
    fetchPolicy: "no-cache",
  });

  const formatPhone = (val: any) => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const [renderOptions, setRenderOptions] = useState<any>(false);
  const [fields, setFields] = useState<any>({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
    },
    lastName: {
      value: "",
    },
    contactName: {
      value: "",
    },
    applicantName: {
      value: "",
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    businessName: {
      value: "",
    },
    phone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
    },
    description: {
      value: "",
    },
    status: {
      value: "",
    },
    amountRequested: {
      value: "",
      format: formatMoney,
    },
    createdDateTime: {
      value: "",
    },
    vendorSalespersonId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    proposalLink: {
      value: "",
    },
    saleType: {
      value: "",
    },
    contractDate: {
      value: "",
    },
    firstPaymentDate: {
      value: "",
    },
  });

  const [formValid, setFormValid] = useState(false);

  const checkFormValidation = (): any => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value: any, fieldName: any) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (proposalDetails) {
      const createdFinanceSnapshot = proposalDetails?.proposal?.createdSnapshot?.paymentOptions[0]?.finance;

      const startDate = createdFinanceSnapshot?.startDate;
      const daysToFirstPayment = createdFinanceSnapshot?.daysToFirstPayment;

      setFields({
        id: {
          value: proposalDetails.proposal.id ? proposalDetails.proposal.id : "",
          validationStatus: "",
        },
        firstName: {
          value: proposalDetails.proposal.firstName ? proposalDetails.proposal.firstName : "",
          validationStatus: "",
        },
        lastName: {
          value: proposalDetails.proposal.lastName ? proposalDetails.proposal.lastName : "",
          validationStatus: "",
        },
        contactName: {
          value:
            proposalDetails.proposal.firstName && proposalDetails.proposal.lastName
              ? proposalDetails.proposal.firstName + " " + proposalDetails.proposal.lastName
              : "",
          validationStatus: "",
        },
        applicantName: {
          value: proposalDetails.proposal.fullName ? proposalDetails.proposal.fullName : "",
          validationStatus: "",
        },
        email: {
          value: proposalDetails.proposal.email ? proposalDetails.proposal.email : "",
          validationStatus: "",
        },
        amountRequested: {
          value: proposalDetails.proposal.amountRequested ? formatMoney(proposalDetails.proposal.amountRequested) : "",
          validationStatus: "",
          format: formatMoney,
        },
        businessName: {
          value: proposalDetails.proposal.businessName ? proposalDetails.proposal.businessName : "",
          validationStatus: "",
        },
        status: {
          value: proposalDetails.proposal.status ? proposalDetails.proposal.status : "",
          validationStatus: "",
        },
        phone: {
          value: proposalDetails.proposal.phone ? formatPhone(proposalDetails.proposal.phone) : "",
          validationStatus: "",
          format: formatPhone,
          // validations: [{ type: "required" }],
        },
        createdDateTime: {
          value: proposalDetails.proposal.createdDateTime
            ? convertToDate(proposalDetails.proposal.createdDateTime)
            : "",
        },
        vendorSalespersonId: {
          value: proposalDetails.proposal.vendorSalespersonId ? proposalDetails.proposal.vendorSalespersonId : "",
        },
        proposalLink: {
          value: proposalDetails.proposal.id
            ? `${config.REACT_APP_OCA_BASE_URL}/ps/?vendorGUID=${account.dynamicsAccountId}&proposalId=${proposalDetails.proposal.id}&viewOnly=true`
            : null,
        },
        saleType: {
          value: proposalDetails.proposal.saleType
            ? (proposalSaleTypeToViewText as any)?.[proposalDetails?.proposal?.saleType]
            : "-",
        },
        contractDate: {
          value: startDate ? moment(startDate).format("MM-DD-YYYY") : "",
        },
        firstPaymentDate: {
          value:
            startDate && isNotNullOrUndefined(daysToFirstPayment)
              ? moment(startDate).add(daysToFirstPayment, "days").format("MM-DD-YYYY")
              : "",
        },
      });
    }
  }, [proposalDetails]);

  useEffect(() => {
    if (proposalDetails) {
      calculateMonthlyOptions(proposalDetails.proposal.paymentCards).catch(logError);
    }
  }, [proposalDetails]);

  const sendLink = async () => {
    try {
      await sendProposal({
        variables: {
          input: {
            id: proposalDetails.proposal.id,
            amountRequested: proposalDetails.proposal.amountRequested,
            creditProductIds: proposalDetails.proposal.creditProductIds,
            riskTier: proposalDetails.proposal.tier,
            vendorOpportunityId: vo.vendorOpportunityId,
            accountId: account.id,
            vendorSalespersonId: proposalDetails.proposal.vendorSalespersonId,
            firstName: proposalDetails.proposal.firstName,
            lastName: proposalDetails.proposal.lastName,
            email: fields.email.value,
            businessName: proposalDetails.proposal.businessName,
            status: "Proposal Sent",
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenContractDetails = () => {
    handleOpenContractDetailsDialog({ proposalId: propId });
  };

  const handleSetStatusToContract = async () => {
    await onUpdateProposal(propId, { status: "Contract" });
  };

  const handleSubmit = async () => {
    try {
      await sendLink();
      showNotification("Successfully sent your proposal!");
    } catch (e) {
      console.log(e);
      showNotification("Error sending proposal!", { type: "error" });
    }
  };

  const handleDeleteProposal = async (id: any, VOId: any) => {
    try {
      await deactivateProposal({
        variables: {
          id: id,
        },
      }).then(async () => {
        await updateFastTrackStatus({
          variables: {
            VOId: VOId,
          },
        });
      });
      showNotification("Successfully deleted your proposal!");
      setTimeout(() => {
        window.location.href = `/vo/${VOId}`;
      }, 3000);
    } catch (err) {
      console.log(err);
      showNotification("Error deleting proposal!", { type: "error" });
    }
  };

  const calculateMonthlyOptions = async (obj: any) => {
    if (!!obj && obj.length) {
      obj.forEach((item: any, i: any) => {
        item.amountRequested = parseFloat(proposalDetails.proposal.amountRequested);
        item.monthlyPayments = item.amountRequested * item.rateFactor;
        if (typeof item.docFee !== "number") {
          item.docFee = parseFloat(item.docFee.replace(/[^0-9.-]+/g, ""));
        }
        item.mpWithPoints = item.monthlyPayments * (1 + (item.markupInternal / 100 + item.markupDealer / 100));
        item.monthlyPayments = item.mpWithPoints;
        item.monthlyPayments = item.monthlyPayments.toFixed(2);
        const rateSandPay = calculateRatesAndPayments(
          item.amountRequested,
          item.term,
          item.rate,
          item.markupInternal / 100 + item.markupDealer / 100
        );
        if (!item.rateFactor) {
          item.monthlyPayments = rateSandPay.sellPayment;
        }
        item.interestRate = (rateSandPay.simpleInterest * 100).toFixed(2);
        if (!!item.advancePayment && typeof item.advancePayment !== "number") {
          item.advancePayment = parseFloat(item.advancePayment.replace(/[^0-9.-]+/g, ""));
        }
        if (item.advancePayment > 0) {
          item.amountDue = item.advancePayment * parseFloat(item.monthlyPayments) + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        } else {
          item.amountDue = item.monthlyPayments + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        }
        item.monthlyPayments = numberWithCommas(item.monthlyPayments);
        item.emailHeader = "Option " + parseInt(i + 1);
        delete item.interestRate;
        delete item.credittProduct;
        delete item.mpWithPoints;
        delete item.__typename;
      });
      setTimeout(() => setRenderOptions(true), 500);
    }
  };

  const handleOpenFinanceProgramDialog = async (financeProgramId: any) => {
    try {
      const { data: financeProgramResponse } = await client.query({
        query: GET_FINANCE_PROGRAMS,
        variables: { accountId: account.id },
      });

      const targetProgram = financeProgramResponse?.financePrograms.find(
        (program: any) => program.financeProgramId === financeProgramId
      );

      const programFormValues = mapFinanceProgramEntityToFormValues(targetProgram, vp.lenderProfiles);

      handleOpenFinanceProgramPreviewModal({
        program: programFormValues,
        onEdit: () => {
          handleOpenFinanceFormModal({
            criteriaOptions,
            lendersOptions: vp.lenderProfiles,
            program: programFormValues,
            onSubmit: async formValue => {
              try {
                const preparedData = mapFormValuesToUpdateFinanceProgramInput(targetProgram, formValue);

                await updateLenderProgram({
                  variables: {
                    input: preparedData,
                  },
                });

                const isCriteriaDidntUpdate =
                  targetProgram?.prescreenCriteria?.prescreenCriteriaId === formValue?.program?.third?.criteria?.value;

                if (!isCriteriaDidntUpdate) {
                  const { data: createdCriteria } = await createPrescreenCriteria({
                    variables: {
                      input: mapCriteriaFormValuesToCreateInput(formValue, account.id),
                    },
                  });

                  await updateLenderProgram({
                    variables: {
                      input: {
                        financeProgramId: formValue.program.id,
                        prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
                      },
                    },
                  });
                }

                showNotification(`Finance program updated!`);

                handleCloseFinanceFormModal();
              } catch (error) {
                showNotification(`Finance program was not updated!`, {
                  type: "error",
                });
              }
            },
          });
        },
      });
    } catch (error) {
      logError(error);
      showNotification("Finance program is not available, please try later!", {
        type: "error",
      });
    }
  };

  const { isShowContractBtn, isProposalStatusContract, hasContracts } = useMemo(() => {
    if (!proposalDetails?.proposal) {
      return {
        isShowContractBtn: false,
        isProposalStatusContract: false,
        hasContracts: false,
      };
    }

    const { proposal } = proposalDetails;

    const statusNotContract = proposal?.status !== "Contract";
    const isProposalOptionAccepted = !!proposal?.lenderPaymentOptions?.some(
      (payment: any) => !!payment?.paymentCriteriaSelected
    );

    return {
      isShowContractBtn: statusNotContract && isProposalOptionAccepted,
      isProposalStatusContract: !statusNotContract,
      hasContracts: proposalDetails.proposal.contracts.length > 0,
    };
  }, [proposalDetails]);

  const { isShowProductsCard, isShowPaymentsCard } = useMemo(() => {
    if (!proposalDetails?.proposal) {
      return {
        isShowProductsCard: false,
        isShowPaymentsCard: false,
      };
    }

    const { proposal } = proposalDetails;

    return {
      isShowProductsCard:
        proposal?.saleType === "FINANCE_WITH_AFTERMARKET_MENU" && ["Contract", "Accepted"].includes(proposal?.status),
      isShowPaymentsCard: proposal?.type === "payments" && proposal?.category !== "QUOTE",
    };
  }, [proposalDetails]);

  if (!proposalDetails) {
    return <CircularProgress />;
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Form
        onSubmit={() => {
          handleSubmit();
        }}
        validate={checkFormValidation}
        initialValues={!!proposalDetails.proposal}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit()?.then(res => console.log(res));
            }}>
            <Grid container>
              <Tile>
                <Stack gap="1rem">
                  <Stack direction="row" justifyContent="space-between">
                    <Button startIcon={<ArrowBackIosOutlined />} onClick={() => history.push(prevPage)}>
                      Back
                    </Button>

                    <Stack direction="row" gap="0.5rem">
                      {(hasContracts || isProposalStatusContract) && (
                        <Button variant="contained" onClick={handleOpenContractDetails}>
                          Contract Details
                        </Button>
                      )}

                      {isShowContractBtn && (
                        <Button
                          variant="contained"
                          onClick={handleSetStatusToContract}
                          style={{ backgroundColor: green["500"] }}
                          disabled={isProposalUpdating || networkStatus === NetworkStatus.refetch}>
                          {hasContracts && !isProposalStatusContract ? "Contract remaining products" : "Begin Contract"}
                        </Button>
                      )}

                      {fields.proposalLink.value ? (
                        <Button href={fields.proposalLink.value} target="_blank">
                          {proposalDetails.proposal.category === "QUOTE" ? "View Quote" : "View Proposal"}
                        </Button>
                      ) : (
                        <span>No Link Available</span>
                      )}

                      <Button
                        color="error"
                        onClick={() => handleDeleteProposal(proposalDetails.proposal.id, vo.vendorOpportunityId)}>
                        Delete
                      </Button>

                      {(proposalDetails.proposal.status !== "Accepted" ||
                        proposalDetails.proposal.status !== "accepted - pending bank") && (
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={submitting || !formValid}>
                          {submitting ? "Sending" : "Send"}
                        </Button>
                      )}
                    </Stack>
                  </Stack>

                  <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                    <FormInput disabled label="Created On" value={fields.createdDateTime.value} />

                    <FormInput disabled label="Status" value={fields.status.value} />

                    <FormInput
                      label="Business Name"
                      value={fields.businessName.value}
                      onChange={value => {
                        setValue(value, "businessName");
                      }}
                    />

                    <FormInput
                      label="Amount Requested"
                      value={fields.amountRequested.value}
                      onChange={value => {
                        setValue(value, "amountRequested");
                      }}
                    />

                    <FormInput
                      disabled
                      label="Contact Name"
                      value={fields.contactName.value ? fields.contactName.value : fields.applicantName.value}
                    />

                    <FormInput
                      type="email"
                      label="Email"
                      value={fields.email.value}
                      onChange={value => {
                        setValue(value, "email");
                      }}
                    />

                    <FormInput label="Type" disabled name="type" value={fields.saleType.value} />

                    <FormInput label="Contract Date" disabled name="contractDate" value={fields.contractDate.value} />

                    <FormInput
                      disabled
                      name="firstPaymentDate"
                      label="First Payment Date"
                      value={fields.firstPaymentDate.value}
                    />
                  </Box>
                </Stack>
              </Tile>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                {renderOptions &&
                  proposalDetails.proposal.status !== "Accepted" &&
                  proposalDetails.proposal.type === "base" && (
                    <Paper>
                      <CardHeader title="Generated Proposal" />
                      <Divider />
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Credit Product</TableCell>
                                <TableCell>Term </TableCell>
                                <TableCell>Monthly Payment</TableCell>
                                <TableCell>Amount Due</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {proposalDetails.proposal.paymentCards.map(
                                ({ term, monthlyPayments, productHeader, amountDue }: any, i: number) => (
                                  <>
                                    {term === 24 && (
                                      <TableRow key={i}>
                                        <TableCell colSpan={3} align="left">
                                          Credit Product {productHeader}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    <TableRow key={i}>
                                      <TableCell></TableCell>
                                      <TableCell>{term} Months</TableCell>
                                      <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                      <TableCell>{formatCurrency(amountDue)}</TableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Paper>
                  )}
                {!!proposalDetails &&
                  proposalDetails.proposal.type === "gbb" &&
                  proposalDetails.proposal.status !== "Accepted" && (
                    <Paper>
                      <CardHeader title="Generated Proposal" />
                      <Divider />
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Credit Product</TableCell>
                                <TableCell>Term </TableCell>
                                <TableCell>Monthly Payment</TableCell>
                                <TableCell>Rate</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Trac Option</TableCell>
                              </TableRow>
                              {proposalDetails.proposal.gbbCalculatedOptions[0].trac.map(
                                ({ monthlyPayments, rate, emailHeader }: any, i: number) => (
                                  <>
                                    <TableRow key={i}>
                                      <TableCell>{emailHeader}</TableCell>
                                      <TableCell>60 Months</TableCell>
                                      <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                      <TableCell>{rate}%</TableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                              <TableRow>
                                <TableCell>Finance Option</TableCell>
                              </TableRow>
                              {proposalDetails.proposal.gbbCalculatedOptions[0].finance.map(
                                ({ monthlyPayments, rate, emailHeader }: any, i: number) => (
                                  <>
                                    <TableRow key={i}>
                                      <TableCell>{emailHeader}</TableCell>
                                      <TableCell>60 Months</TableCell>
                                      <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                      <TableCell>{rate}%</TableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Paper>
                  )}
                {!!proposalDetails.proposal &&
                  proposalDetails.proposal.status == "Accepted" &&
                  proposalDetails.proposal.type === "base" && (
                    <Paper>
                      <CardHeader title="Accepted Proposal" />
                      <Divider />
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Credit Product</TableCell>
                                <TableCell>Term </TableCell>
                                <TableCell>Monthly Payment</TableCell>
                                <TableCell>Interest</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>{proposalDetails.proposal.productSelected}</TableCell>
                                <TableCell>{proposalDetails.proposal.termLength} Months</TableCell>
                                <TableCell>{formatCurrency(proposalDetails.proposal.monthlyPayment)}</TableCell>
                                <TableCell>{proposalDetails.proposal.interestRate.toFixed(2)}%</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Paper>
                  )}
                {!!proposalDetails.proposal &&
                  proposalDetails.proposal.status == "Accepted" &&
                  proposalDetails.proposal.type === "gbb" && (
                    <Paper>
                      <CardHeader title="Accepted Proposal" />
                      <Divider />
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Accepted Option</TableCell>
                                <TableCell>Term </TableCell>
                                <TableCell>Monthly Payment</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {proposalDetails.proposal.gbbAcceptedCalculatedOption.type} -{" "}
                                  {proposalDetails.proposal.gbbAcceptedCalculatedOption.emailHeader} -{" "}
                                  {proposalDetails.proposal.acceptedAftermarketOptions}
                                </TableCell>
                                <TableCell>{proposalDetails.proposal.gbbAcceptedTerms} Months</TableCell>
                                <TableCell>{formatCurrency(proposalDetails.proposal.monthlyPayment)}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Paper>
                  )}
              </Grid>
            </Grid>
          </form>
        )}
      />

      {isShowProductsCard && (
        <CardContainer title="Details">
          <ProductsDetails proposalId={propId} accountId={account.id} />
        </CardContainer>
      )}

      {isShowPaymentsCard && (
        <CardContainer title="Product Details">
          <PaymentOptionsDetails
            withDetermineStatus
            loading={isProposalDetailsLoading}
            proposal={proposalDetails?.proposal}
            onOpenFinanceProgramDialog={handleOpenFinanceProgramDialog}
            onOpenDetailsDialog={menuOption => {
              handleOpenProposalMenuOptionDetailsDialog({ menuOption });
            }}
          />
        </CardContainer>
      )}

      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </Box>
  );
}

const mapStateToProps = (state: any) => ({
  account: state.account,
  vo: state.vo,
  vp: state.vp,
});

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDetails);
