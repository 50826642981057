import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Box,
  Stack,
  Typography,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddOutlined, CloseOutlined } from "@mui/icons-material";
import NumberInputList from "./NumberInputList";
import { getDefaultFinanceProgram } from "../services/proposalsService";
import { areAllRatesSame, formatCurrency, formatNumberWithDelimiter, parseCurrency } from "../utils";
import { floatNumberRegexp } from "../pages/Prequal/constants";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "../theme";
import _ from "lodash";
import DOMPurify from "dompurify";
import { Engine } from "json-rules-engine";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import {
  CustomOperators,
  FACT_NAMES_BOOLEAN,
  FACT_NAMES_DATE,
  FACT_NAMES_NUMERIC,
  FACT_NAMES_STRING,
} from "@trnsact/business-criteria";
import LoadingModal from "./LoadingModal/LoadingModal";
import NTP_LOGO from "assets/img/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "assets/img/aftermarket-vendor-logos/gaig-logo.png";

const GET_AFTERMARKET_PRODUCTS_BY_VENDOR_API_CHANNEL = gql`
  query GetAftermarketProductsByVendorApiChannel($input: AftermarketProductsByVendorApiChannelInput!) {
    aftermarketProductsByVendorApiChannel(input: $input) {
      aftermarketProductId
      aftermarketVendorApiChannel
      productName
      productType
      productCategory
      productDescriptionExternal
      criteria
    }
  }
`;

let requiredToQuoteEngine = new Engine();

const FeeInput = ({ index, onRemove, onFeeChange, fee, disabled }) => {
  const [financedChecked, setFinancedChecked] = useState(fee.financed ? fee.financed : false);

  const handleDescriptionChange = event => {
    onFeeChange("identifier", event.target.value);
  };

  const handleFinancedChange = event => {
    setFinancedChecked(event.target.checked);
    onFeeChange("financed", event.target.checked);
  };

  return (
    <Stack gap="0.5rem" key={index}>
      <Typography>Fee: {index + 1}</Typography>

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 2rem", gap: "0.5rem" }}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          disabled={disabled}
          label="Description"
          value={fee.identifier}
          onChange={handleDescriptionChange}
        />

        <TextField
          fullWidth
          size="small"
          label="Fee Amount"
          variant="outlined"
          disabled={disabled}
          value={formatCurrency(fee.fee, true) || ""}
          onChange={e => floatNumberRegexp.test(e.target.value) && onFeeChange("fee", e.target.value)}
        />

        <IconButton onClick={() => onRemove(index)} size="small">
          <CloseOutlined />
        </IconButton>
      </Box>

      <FormControlLabel
        label="Financed?"
        style={{ marginTop: "-16px", marginBottom: "24px" }}
        control={
          <Switch checked={financedChecked} onChange={handleFinancedChange} color="primary" disabled={disabled} />
        }
      />
    </Stack>
  );
};

function RequiredToQuoteInputs({
  financeProgram,
  amountFinanced,
  updateFinanceProgram,
  setShowProgramInputs,
  requiredToQuoteEngineResults,
  setRequiredToQuoteEngineResults, // { qualifies: boolean, overridden: boolean, engineNeedsToRun: boolean, engineHasRun: boolean }
  setDraggableAftermarketProducts,
}) {
  const [
    getAftermarketProductsByVendorApiChannel,
    { data: aftermarketProductsByVendorApiChannelData, loading: aftermarketProductsByVendorApiChannelDataLoading },
  ] = useLazyQuery(GET_AFTERMARKET_PRODUCTS_BY_VENDOR_API_CHANNEL, {
    context: { authRequired: true },
    fetchPolicy: "network-only",
  });

  const classes = useStyles();

  const [facts, setFacts] = useState({});
  const [factsToCheck, setFactsToCheck] = useState([]);
  const [suggestedPrograms, setSuggestedPrograms] = useState([]);
  const [engineHasRun, setEngineHasRun] = useState(false);
  const [aftermarketVendorApiChannelsAndData, setAftermarketVendorApiChannelsAndData] = useState({
    channels: {},
    data: {},
  });

  useEffect(() => {
    if (_.isString(amountFinanced)) {
      const dealSizeParsed = parseFloat(amountFinanced.replace(/[^0-9.]/g, ""));
      requiredToQuoteEngine.addFact("DEAL_SIZE", dealSizeParsed);
      setFacts({ ...facts, DEAL_SIZE: dealSizeParsed });
    } else if (_.isNumber(amountFinanced)) {
      requiredToQuoteEngine.addFact("DEAL_SIZE", amountFinanced);
      setFacts({ ...facts, DEAL_SIZE: amountFinanced });
    }
  }, [amountFinanced]);

  useEffect(() => {
    setRequiredToQuoteEngineResults({
      engineNeedsToRun: true,
      engineHasRun: false,
      qualifies: false,
      overridden: false,
    });
    setSuggestedPrograms([]);
    if (facts) {
      if (
        Object.keys(facts).length === 0 ||
        _.some(facts, (value, key) => {
          return (
            (facts[key] === null || facts[key] === undefined) &&
            _.find(factsToCheck, f => f.factKey === key).rule.runEngineOnNull !== true
          );
        })
      ) {
        console.log("No facts to check");
        return;
      }
      // const factDealSize = requiredToQuoteEngine.getFact("DEAL_SIZE");
      // console.log(`factDealSize: ${JSON.stringify(factDealSize)}`);

      // const factEquipmentHours = requiredToQuoteEngine.getFact("EQUIPMENT_HOURS");
      // console.log(`factEquipmentHours: ${JSON.stringify(factEquipmentHours)}`);

      // const factEquipmentAgeYears = requiredToQuoteEngine.getFact("EQUIPMENT_AGE_YEARS");
      // console.log(`factEquipmentAge: ${JSON.stringify(factEquipmentAgeYears)}`);

      // const factEquipmentModel = requiredToQuoteEngine.getFact("EQUIPMENT_MODEL");
      // console.log(`factEquipmentModel: ${JSON.stringify(factEquipmentModel)}`);

      requiredToQuoteEngine
        .run()
        .then(engineResults => {
          // console.log(`engineResults: ${JSON.stringify(engineResults)}`);
          let financeProgramHasBeenUpdated = false;
          const updatedFinanceProgramIds = [];
          let newFinanceProgram = {};

          const newRequiredToQuoteEngineResults = {
            qualifies: false,
            overridden: false,
            engineNeedsToRun: true,
            engineHasRun: true,
          };

          if (engineResults.events.length === 0) {
            setShowProgramInputs(false);
            setRequiredToQuoteEngineResults(newRequiredToQuoteEngineResults);
          }

          const draggableAftermarketProducts = [];

          const newAftermarketVendorApiChannelsAndData = {
            channels: {},
            data: {},
          };

          engineResults.events.map(event => {
            if (event.type === "SUGGESTED_PROGRAM_FOUND") {
              if (
                !_.has(financeProgram, "prescreenCriteria.jsonCriteria.programOverrides") ||
                !_.get(financeProgram, "prescreenCriteria.jsonCriteria.programOverrides", []).length === 0
              ) {
                setShowProgramInputs(true);
                newRequiredToQuoteEngineResults.qualifies = true;
              }
            } else if (event.type === "OVERRIDE_PROGRAM") {
              financeProgramHasBeenUpdated = true;
              setShowProgramInputs(true);

              newRequiredToQuoteEngineResults.qualifies = true;
              newRequiredToQuoteEngineResults.overridden = true;

              updatedFinanceProgramIds.push(event.params.financeProgramId);
              if (financeProgram.financeProgramId === event.params.financeProgramId) {
                console.log(
                  `TTT: Overriding program: ${JSON.stringify(financeProgram)} with ${JSON.stringify(event.params)}`
                );
                newFinanceProgram = {
                  ...financeProgram,
                  ...event.params.paymentOptionsConfiguration,
                  amountFinanced: amountFinanced,
                };
              }
            } else if (event.type === "AFTERMARKET_PRODUCT_GET_DYNAMIC_PRODUCTS") {
              /*
              Event Structure
              {
                "type": "AFTERMARKET_PRODUCT_GET_DYNAMIC_PRODUCTS",
                "params": {
                  "AFTERMARKET_VENDOR_API_CHANNEL": "NTP",
                  "AFTERMARKET_PRODUCT_IDS_EXTERNAL_QUALIFIED": {
                    "fact": "AFTERMARKET_PRODUCT_IDS_EXTERNAL_QUALIFIED"
                  }
                }
              }
              */
              newAftermarketVendorApiChannelsAndData.channels[event.params.AFTERMARKET_VENDOR_API_CHANNEL] =
                event.params.AFTERMARKET_VENDOR_API_CHANNEL;
              setShowProgramInputs(false);
              // Test VIN: 3AKJHHDR4LSLL3208
              if (!financeProgram.financeProgramId) {
                newRequiredToQuoteEngineResults.qualifies = true;
              }
              if (event.params.AFTERMARKET_VENDOR_API_CHANNEL === "NTP") {
                newAftermarketVendorApiChannelsAndData.data.odometer = requiredToQuoteEngine.getFact(
                  FACT_NAMES_NUMERIC.ODOMETER_MILEAGE
                ).value;
                newAftermarketVendorApiChannelsAndData.data.vin = requiredToQuoteEngine.getFact(
                  FACT_NAMES_STRING.VIN
                ).value;
                requiredToQuoteEngine.removeRule("NTP Product Dynamic Coverage");
              }
              if (event.params.AFTERMARKET_VENDOR_API_CHANNEL === "GAIG") {
                requiredToQuoteEngine.removeRule("GAIG Product Dynamic Coverage");
              }
              // getAftermarketProductsByVendorApiChannel({
              //   variables: {
              //     input: {
              //       aftermarketVendorApiChannel: "NTP",
              //       data: {
              //         odometer: requiredToQuoteEngine.getFact(FACT_NAMES_NUMERIC.ODOMETER_MILEAGE).value,
              //         vin: requiredToQuoteEngine.getFact(FACT_NAMES_STRING.VIN).value,
              //       },
              //     },
              //   },
              // });
            } else if (event.type === "OVERRIDE_STRING_FACT_OPTIONS") {
              try {
                const overrides = event.params.overrides;
                if (overrides) {
                  overrides.forEach(override => {
                    const factName = override.fact;
                    const factToCheckIndex = factsToCheck.findIndex(fact => fact.factKey === factName);
                    const factToCheck = factsToCheck[factToCheckIndex];
                    if (factToCheck?.type === "string") {
                      factsToCheck[factToCheckIndex].options = override.values;
                      setFactsToCheck([...factsToCheck]);
                    }
                  });
                }
              } catch (e) {
                console.log(`Error during OVERRIDE_STRING_FACT_OPTIONS: ${e.message}`);
              }
            } else if (event.type === "AFTERMARKET_PRODUCT_QUALIFIED") {
              /*
              {
                "MARKUP": {
                  "TYPE": "PERCENTAGE",
                  "AMOUNT": "0"
                },
                "DEALER_COST": "380",
                "PRICING_MAX": "599",
                "PRICING_MIN": "399",
                "SUGGESTED_RETAIL_PRICE": "699"
              }
              */
              setShowProgramInputs(true);
              newRequiredToQuoteEngineResults.qualifies = true;
              if (event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA) {
                draggableAftermarketProducts.push(event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA);
              } else {
                draggableAftermarketProducts.push(event.params);
              }
            }
          });
          if (draggableAftermarketProducts.length > 0) {
            setDraggableAftermarketProducts(draggableAftermarketProducts);
          }
          if (financeProgramHasBeenUpdated) {
            updateFinanceProgram(newFinanceProgram, updatedFinanceProgramIds);
          }
          setAftermarketVendorApiChannelsAndData(newAftermarketVendorApiChannelsAndData);
          setRequiredToQuoteEngineResults(newRequiredToQuoteEngineResults);
          setEngineHasRun(true);
          if (Object.keys(newAftermarketVendorApiChannelsAndData.channels).length > 0) {
            getAftermarketProductsByVendorApiChannel({
              variables: {
                input: {
                  aftermarketVendorApiChannels: Object.keys(newAftermarketVendorApiChannelsAndData.channels),
                  data: newAftermarketVendorApiChannelsAndData.data,
                },
              },
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [facts]);

  useEffect(() => {
    console.log(`Finance Program CHANGED -+-+-+-+-+-+`);
    requiredToQuoteEngine = new Engine();
    requiredToQuoteEngine.addOperator("length", CustomOperators.length);
    setFacts([]);
    setFactsToCheck([]);
    if (financeProgram) {
      const newFactsToCheck = [];

      if (!_.get(financeProgram, "prescreenCriteria.jsonCriteria.formRules") && !_.get(financeProgram, "menu")) {
        return;
      }
      try {
        let ruleConditions = [];

        // Finance Program Conditions and Events
        financeProgram?.prescreenCriteria?.jsonCriteria?.formRules?.forEach(rule => {
          if (rule.requiredForQuote) {
            setShowProgramInputs(false);
            setRequiredToQuoteEngineResults({
              ...requiredToQuoteEngineResults,
              engineNeedsToRun: true,
              qualifies: false,
            });
            //  || rule.requiredToQuote requiredForQuote is the correct name, requiredToQuote is here for backwards compatibility
            if (
              Object.keys(FACT_NAMES_BOOLEAN).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "boolean", rule });
            } else if (
              Object.keys(FACT_NAMES_NUMERIC).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
            } else if (Object.keys(FACT_NAMES_STRING).includes(rule.fact)) {
              if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                newFactsToCheck[factToCheckIndex].options = _.uniq(
                  newFactsToCheck[factToCheckIndex].options.concat(
                    _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                  )
                );
              } else {
                newFactsToCheck.push({
                  factKey: rule.fact,
                  type: "string",
                  options: Array.isArray(rule.exactMatch) ? rule.exactMatch : [],
                  rule,
                });
              }
            }
            ruleConditions.push(
              financeProgram.prescreenCriteria.jsonCriteria.jsonRules.conditions.all.find(
                condition => condition.fact === rule.fact
              )
            );
          }
        });

        financeProgram?.menu?.menuOptions?.forEach(menuOption => {
          menuOption?.products.forEach(product => {
            const productRules = _.isArray(product?.aftermarketProduct?.criteria)
              ? product.aftermarketProduct.criteria
              : null;
            if (productRules) {
              productRules.forEach(aftermarketProductCriteria => {
                let requiredToQuote = false;
                aftermarketProductCriteria?.conditions?.formRules?.forEach(rule => {
                  if (rule.requiredForQuote) {
                    requiredToQuote = true;
                    setShowProgramInputs(false);
                    setRequiredToQuoteEngineResults({
                      ...requiredToQuoteEngineResults,
                      engineNeedsToRun: true,
                      qualifies: false,
                    });
                    if (
                      Object.keys(FACT_NAMES_BOOLEAN).includes(rule.fact) &&
                      !_.find(newFactsToCheck, { factKey: rule.fact })
                    ) {
                      newFactsToCheck.push({ factKey: rule.fact, type: "boolean", rule });
                    } else if (
                      Object.keys(FACT_NAMES_NUMERIC).includes(rule.fact) &&
                      !_.find(newFactsToCheck, { factKey: rule.fact })
                    ) {
                      newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
                    } else if (
                      Object.keys(FACT_NAMES_DATE).includes(rule.factName) &&
                      !_.find(newFactsToCheck, { factKey: rule.fact })
                    ) {
                      newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
                    } else if (Object.keys(FACT_NAMES_STRING).includes(rule.fact)) {
                      if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                        if (rule.exactMatch) {
                          const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                          newFactsToCheck[factToCheckIndex].options = _.uniq(
                            newFactsToCheck[factToCheckIndex].options.concat(
                              _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                            )
                          );
                        }
                      } else {
                        newFactsToCheck.push({
                          factKey: rule.fact,
                          type: "string",
                          options: rule.exactMatch ? (Array.isArray(rule.exactMatch) ? rule.exactMatch : []) : null,
                          rule,
                        });
                      }
                    }
                  }
                });
                if (requiredToQuote) {
                  requiredToQuoteEngine.addRule({
                    ...aftermarketProductCriteria,
                    conditions: { all: aftermarketProductCriteria.conditions.jsonRules.conditions.all },
                  });
                }
              });
            }
          });
        });

        console.log(`financeProgram: ${JSON.stringify(financeProgram)}`);
        if (ruleConditions.length > 0) {
          requiredToQuoteEngine.addRule({
            conditions: { all: ruleConditions },
            name: `Suggested Program Found: ${financeProgram.name}`,
            event: {
              type: "SUGGESTED_PROGRAM_FOUND",
              params: {
                financeProgram: financeProgram,
              },
            },
          });
        }

        if (
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides &&
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides.length > 0
        ) {
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides.forEach(override => {
            // console.log("IN OVERRIDE");
            // console.log(`IN OVERRIDE: ${JSON.stringify(override)}`);
            if (_.get(override, "conditions.jsonRules.conditions", null)) {
              console.log(
                `Adding override rule: ${JSON.stringify({
                  conditions: { all: override.conditions.jsonRules.conditions.all },
                  name: `${financeProgram.name}: Override Triggered`,
                  event: {
                    type: "OVERRIDE_PROGRAM",
                    params: {
                      financeProgramId: financeProgram.financeProgramId,
                      financeProgram: override.program,
                    },
                  },
                })}`
              );
              requiredToQuoteEngine.addRule({
                conditions: { all: override.conditions.jsonRules.conditions.all },
                name: `${financeProgram.name}: Override Triggered`,
                event: {
                  type: "OVERRIDE_PROGRAM",
                  params: {
                    financeProgramId: financeProgram.financeProgramId,
                    paymentOptionsConfiguration: override.paymentOptionsConfiguration,
                  },
                },
              });
            }
          });
        }
      } catch (e) {
        console.log(e.message);
      }

      setFactsToCheck(newFactsToCheck);
    }
  }, [financeProgram]);

  useEffect(() => {
    if (aftermarketProductsByVendorApiChannelData) {
      const newFactsToCheck = factsToCheck;

      aftermarketProductsByVendorApiChannelData.aftermarketProductsByVendorApiChannel.forEach(aftermarketProduct => {
        // aftermarketProductsByVendorApiChannelData.aftermarketProductsByVendorApiChannel[0]
        const productRules = _.isArray(aftermarketProduct?.criteria)
          ? aftermarketProduct?.criteria
          : [aftermarketProduct?.criteria];
        if (productRules) {
          productRules.forEach(aftermarketProductCriteria => {
            aftermarketProductCriteria?.conditions?.formRules?.forEach(rule => {
              if (rule.requiredForQuote) {
                setShowProgramInputs(false);
                setRequiredToQuoteEngineResults({
                  ...requiredToQuoteEngineResults,
                  engineNeedsToRun: true,
                  qualifies: false,
                });
                if (
                  Object.keys(FACT_NAMES_BOOLEAN).includes(rule.fact) &&
                  !_.find(newFactsToCheck, { factKey: rule.fact })
                ) {
                  newFactsToCheck.push({ factKey: rule.fact, type: "boolean", rule });
                  requiredToQuoteEngine.addFact(rule.fact, null);
                } else if (
                  Object.keys(FACT_NAMES_NUMERIC).includes(rule.fact) &&
                  !_.find(newFactsToCheck, { factKey: rule.fact })
                ) {
                  newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
                  requiredToQuoteEngine.addFact(rule.fact, null);
                } else if (
                  Object.keys(FACT_NAMES_DATE).includes(rule.factName) &&
                  !_.find(newFactsToCheck, { factKey: rule.fact })
                ) {
                  newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
                  requiredToQuoteEngine.addFact(rule.fact, null);
                } else if (Object.keys(FACT_NAMES_STRING).includes(rule.fact)) {
                  if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                    if (rule.exactMatch) {
                      const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                      newFactsToCheck[factToCheckIndex].options = _.uniq(
                        newFactsToCheck[factToCheckIndex].options.concat(
                          _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                        )
                      );
                    }
                  } else {
                    newFactsToCheck.push({
                      factKey: rule.fact,
                      type: "string",
                      options: rule.exactMatch ? (Array.isArray(rule.exactMatch) ? rule.exactMatch : []) : null,
                      rule,
                    });
                    requiredToQuoteEngine.addFact(rule.fact, null);
                  }
                }
              }
            });
            requiredToQuoteEngine.addRule({
              ...aftermarketProductCriteria,
              conditions: { all: aftermarketProductCriteria.conditions.jsonRules.conditions.all },
            });
          });
        }
      });

      setFactsToCheck(newFactsToCheck);
      // mapValues is intentionally setting undefined as the value for brand new facts
      setFacts({ ..._.mapValues(_.keyBy(newFactsToCheck, "factKey"), "value"), ...facts });
    }
  }, [aftermarketProductsByVendorApiChannelData]);

  if (factsToCheck.length === 0) {
    console.log("No facts to check");
    return <> </>;
  }

  return (
    <>
      {aftermarketProductsByVendorApiChannelDataLoading && (
        <LoadingModal
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "100%",
              }}>
              Getting available products!
              <br />
              {aftermarketVendorApiChannelsAndData.channels.GAIG && (
                <img width="150px" height="75px" src={GAIG_LOGO} alt="GAIG" />
              )}
              {aftermarketVendorApiChannelsAndData.channels.NTP && (
                <img width="75px" height="75px" src={NTP_LOGO} alt="NTP" />
              )}
            </div>
          }
        />
      )}
      {factsToCheck
        .filter(fact => fact.factKey !== "DEAL_SIZE")
        .map(fact => {
          let factInput;

          switch (fact.type) {
            case "numeric":
              factInput = (
                <Grid item xs={6} key={fact.factKey}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
                    value={formatNumberWithDelimiter(facts[fact.factKey], true)}
                    onChange={e => {
                      if (floatNumberRegexp.test(e.target.value)) {
                        const parsedValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
                        console.log(`adding fact: ${parsedValue}`);
                        requiredToQuoteEngine.addFact(fact.factKey, parsedValue);
                        setFacts({ ...facts, [fact.factKey]: parsedValue });
                      }
                    }}
                  />
                </Grid>
              );
              break;

            case "string":
              factInput = (
                <Grid item xs={12} key={fact.factKey}>
                  <FormControl fullWidth size="small">
                    {fact.options && (
                      <>
                        <InputLabel required id={fact.factKey}>
                          {fact.factKey.replace(/_/g, " ")}
                        </InputLabel>
                        <Select
                          id={fact.factKey}
                          variant="outlined"
                          labelId={`${fact.factKey}-label`}
                          value={facts[fact.factKey] ?? ""}
                          label={fact.factKey.replace(/_/g, " ")}
                          onChange={e => {
                            requiredToQuoteEngine.addFact(fact.factKey, e.target.value);
                            setFacts({ ...facts, [fact.factKey]: e.target.value });
                          }}>
                          <MenuItem value="None of These">None of these / I don't Know</MenuItem>
                          {_.orderBy(fact.options, null, "asc").map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                    {!fact.options && (
                      <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={facts[fact.factKey]}
                        className={classes.textField}
                        label={fact.factKey.replace(/_/g, " ")}
                        onChange={e => {
                          requiredToQuoteEngine.addFact(fact.factKey, e.target.value);
                          setFacts({ ...facts, [fact.factKey]: e.target.value });
                        }}
                      />
                    )}
                  </FormControl>
                </Grid>
              );
              break;

            default:
              break;
          }

          return factInput;
        })}
    </>
  );
}

const FinanceProgramForm = props => {
  const {
    isVOView,
    isCreditSubmissionView,
    onValuesChange,
    initFinanceProgram,
    isProgramOwner = true,
    financeProgram,
    quoteToolModeEnabled,
    requiredToQuoteEngineResults,
    setRequiredToQuoteEngineResults,
    setDraggableAftermarketProducts,
  } = props;

  const classes = useStyles();
  const [isRatePerTerm, setRatePerTerm] = useState(false);
  const [ratePerTermIsDisabled, setRatePerTermIsDisabled] = useState(false);
  const [selectedRatePerTermMethod, setSelectedRatePerTermMethod] = useState("markup");
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [showProgramInputs, setShowProgramInputs] = useState(true);

  const defaultProgram = getDefaultFinanceProgram(isVOView, isCreditSubmissionView);

  const [program, setProgram] = useState(initFinanceProgram || defaultProgram);

  const [selectedRateType, setSelectedRateType] = useState("INTEREST_RATE");
  const [selectedFinanceType, setSelectedFinanceType] = useState("none");

  const updatePropsProgram = updatedProgram => {
    const parseFloatInput = input => {
      return parseFloat(parseCurrency(_.toString(input)));
    };

    let parsedProgram = {
      ...updatedProgram,
      // markups: [
      //   {
      //     identifier: "Dealer Markup",
      //     markupPercentage: updatedProgram.markups[0].markupPercentage,
      //   },
      //   {
      //     identifier: "OEM Subsidy",
      //     markupPercentage: updatedProgram.markups[1] ? updatedProgram.markups[1].markupPercentage : 0,
      //   },
      // ],
      flatFees: [..._.get(updatedProgram, "flatFees", [])],
    };

    parsedProgram.numberOfAdvancePayments = parsedProgram.numberOfAdvancePayments
      ? parseInt(parsedProgram.numberOfAdvancePayments)
      : 0;
    parsedProgram.residualPercentage = parsedProgram.residualPercentage
      ? parseInt(parsedProgram.residualPercentage)
      : 0;

    parsedProgram.terms = parsedProgram.terms
      ? parsedProgram.terms.map((term, i) => {
          return {
            term: parseInt(term),
            rate: parsedProgram.rates[i] ? parseFloat(parsedProgram.rates[i]) : 0,
          };
        })
      : [{ term: 0, rate: 0 }];

    if (parsedProgram.customerRate) {
      parsedProgram.customerRate = parseFloatInput(parsedProgram.customerRate);
    }

    if (parsedProgram.dealerMarkup) {
      parsedProgram.dealerMarkup = parseFloatInput(parsedProgram.dealerMarkup);
    }

    parsedProgram.flatFees =
      parsedProgram.flatFees.length > 0
        ? parsedProgram.flatFees.map(fee => ({
            ...fee,
            fee: parseFloatInput(fee.fee),
          }))
        : [];

    if (isVOView || isCreditSubmissionView) {
      parsedProgram.amountFinanced = parseFloatInput(parsedProgram.amountFinanced);
      parsedProgram.downPaymentAmount = parseFloatInput(parsedProgram.downPaymentAmount);
    }

    delete parsedProgram.rates; // important
    delete parsedProgram.buyRate; // important
    onValuesChange(parsedProgram);
  };

  const handleInputChange = (field, event) => {
    const updatedProgram = { ...program };
    if (field === "terms") {
      _.times(event.length - updatedProgram.rates.length, () => updatedProgram.rates.push(0));
    }
    if (event && event.target && (event.target.value || event.target.value === "")) {
      if (field === "buyRate") {
        updatedProgram.rates = [];
        const buyRate = event.target.value;
        _.times(_.get(updatedProgram, "terms.length", 1), () => updatedProgram.rates.push(buyRate));
        updatedProgram.buyRate = buyRate;
      } else if (field === "dealerMarkup") {
        updatedProgram.customerRate = undefined;
        updatedProgram[field] = parseFloat(event.target.value);
      } else if (field === "customerRate") {
        updatedProgram.dealerMarkup = undefined;
        updatedProgram[field] = parseFloat(event.target.value);
      } else if (field === "oemSubsidy") {
        if (!updatedProgram["markups"][1]) {
          updatedProgram["markups"][1] = {
            identifier: "OEM Subsidy",
            markupPercentage: "",
          };
        }
        updatedProgram["markups"][1].markupPercentage = event.target.value;
      } else {
        updatedProgram[field] = event.target.value;
      }
    } else {
      updatedProgram[field] = event;
    }

    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  const handleRatePerTermMethodChange = event => {
    setSelectedRatePerTermMethod(event.target.value);
    const newProgram = { ...program };
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRateTypeChange = event => {
    console.log(`RATE TYPE CHANGED: ${event.target.value}`);
    setSelectedRateType(event.target.value);
    if (event.target.value === "RATE_FACTOR") {
      setRatePerTerm(true);
      setRatePerTermIsDisabled(true);
    } else {
      setRatePerTermIsDisabled(false);
    }
    const newProgram = { ...program };
    newProgram.rateType = event.target.value;
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleFinanceTypeChange = event => {
    setSelectedFinanceType(event.target.value);
    const newProgram = { ...program };
    if (event.target.value !== "financeProfit") {
      newProgram.dealerMarkup = undefined;
    }
    if (event.target.value !== "subsidy") {
      newProgram.customerRate = undefined;
    }
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  useEffect(() => {
    if (initFinanceProgram) {
      initFinanceProgram.rates = initFinanceProgram.terms.map(term => {
        if (term && term.rate) {
          return term.rate;
        }
        return 0;
      });
      initFinanceProgram.terms = initFinanceProgram.terms.map(term => {
        if (term && term.term) {
          return term.term;
        }
        return term;
      });
      initFinanceProgram.buyRate = initFinanceProgram.rates[0];
      delete initFinanceProgram.rate; // Important

      try {
        if (initFinanceProgram.rateType) {
          setSelectedRateType(initFinanceProgram.rateType);
        }
      } catch (e) {
        console.log(e);
      }

      try {
        if (program.dealerMarkup) {
          setSelectedFinanceType("financeProfit");
        }
        if (program.customerRate && selectedFinanceType !== "financeProfit") {
          setSelectedFinanceType("subsidy");
        }
      } catch (e) {
        console.log(e);
      }

      // try {
      //   if (initFinanceProgram.type) {
      //     handleInputChange("type", { target: { value: initFinanceProgram.type } });
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
    }
    if (
      // use program to check rates since initFinanceProgram always contain initial values. program has the actual ones
      (program &&
        !areAllRatesSame(program.rates)) /* initFinanceProgram && !areAllRatesSame(initFinanceProgram.rates) */ ||
      (financeProgram && !areAllRatesSame(financeProgram.rates))
    ) {
      setRatePerTerm(true);
    }
  }, [initFinanceProgram]);

  useEffect(() => {
    if (financeProgram) {
      console.log(`FP CHANGED: financeProgram: ${JSON.stringify(financeProgram)}`);
      if (financeProgram.rateType) {
        setSelectedRateType(financeProgram.rateType);
        if (financeProgram.rateType === "RATE_FACTOR") {
          setRatePerTermIsDisabled(true);
        } else {
          setRatePerTermIsDisabled(false);
        }
      }
      if (financeProgram.dealerMarkup) {
        setSelectedFinanceType("financeProfit");
      }
      if (financeProgram.customerRate) {
        setSelectedFinanceType("subsidy");
      }
      const showCustomInputs = doesFinanceProgramHasCustomRequiredToQuote(financeProgram);
      setShowCustomInputs(showCustomInputs);
      setShowProgramInputs(showCustomInputs ? false : true);
      updatePropsProgram(financeProgram);
    }
  }, [financeProgram]);

  const handleRatePerTermToggle = () => {
    let newProgram = { ...program };
    if (isRatePerTerm) {
      // When this is TRUE, it means we are shutting OFF the toggle
      newProgram.rates = newProgram.terms.map(() => newProgram.buyRate);
    }
    setProgram(newProgram);
    updatePropsProgram(newProgram);
    setRatePerTerm(!isRatePerTerm);
  };

  const updateFinanceProgram = (newFinanceProgram, updatedFinanceProgramIds) => {
    newFinanceProgram.rates = newFinanceProgram.terms.map(term => {
      return term.rate;
    });
    newFinanceProgram.terms = newFinanceProgram.terms.map(term => {
      return term.term;
    });
    setProgram({ ...newFinanceProgram });
    updatePropsProgram({ ...newFinanceProgram });
  };

  const doesFinanceProgramHasCustomRequiredToQuote = fp => {
    try {
      const financeProgramHasRequiredForQuoteRule = _.find(
        fp?.prescreenCriteria?.jsonCriteria?.formRules,
        rule => rule.requiredForQuote
      );

      let menuHasRequiredForQuoteRule = false;

      fp.menu?.menuOptions?.forEach(menuOption => {
        menuOption?.products.forEach(product => {
          if (product?.aftermarketProduct && product.aftermarketProduct.criteria) {
            menuHasRequiredForQuoteRule = true;
          }
        });
      });

      if (financeProgramHasRequiredForQuoteRule || menuHasRequiredForQuoteRule) {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  const handleAddFee = () => {
    let newProgram = { ...program };
    newProgram.flatFees.push({ identifier: "", fee: "", financed: false });
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRemoveFee = feeIndex => {
    let newProgram = { ...program };
    newProgram.flatFees.splice(feeIndex, 1);
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleFeeChange = (feeIndex, field, value) => {
    let newProgram = { ...program };
    newProgram.flatFees[feeIndex][field] = field === "fee" ? formatCurrency(value) : value;
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRateInputChange = (rateIndex, event) => {
    const updatedProgram = { ...program };
    if (event && event.target && (event.target.value || event.target.value === "")) {
      updatedProgram.rates[rateIndex] = event.target.value;
    }
    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  const handleValidityDateToggle = () => {
    const update = !program.validDateStart && !program.validDateEnd;

    const updatedProgram = {
      ...program,
      validDateStart: update ? new Date() : "",
      validDateEnd: update ? new Date(new Date().setMonth(new Date().getMonth() + 3)) : "",
    };

    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  useEffect(() => {
    if (initFinanceProgram) {
      setProgram({
        ...initFinanceProgram,
        ...program,
      });
    }
  }, [initFinanceProgram]);

  useEffect(() => {
    setProgram({ ...program, ...props.financeProgram });
  }, [props.financeProgram]);

  return (
    <Grid container style={{ marginTop: 8 }}>
      <div>
        {program && program.prescreenCriteria && program.prescreenCriteria.guidelines && (
          <div>
            <h4>Program Guidelines</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(program.prescreenCriteria.guidelines, {
                  ALLOWED_TAGS: ["b", "i", "em", "u", "strong", "ol", "ul", "li", "p", "br"],
                }),
              }}
            />
          </div>
        )}
      </div>

      <Grid container spacing={1}>
        {(isVOView || isCreditSubmissionView) && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              size="small"
              variant="outlined"
              label="Amount Financed"
              value={formatCurrency(program.amountFinanced, true) || ""}
              onChange={e => floatNumberRegexp.test(e.target.value) && handleInputChange("amountFinanced", e)}
            />
          </Grid>
        )}

        {(isVOView || isCreditSubmissionView) && showCustomInputs && (
          <RequiredToQuoteInputs
            financeProgram={financeProgram}
            amountFinanced={program.amountFinanced}
            updateFinanceProgram={updateFinanceProgram}
            setShowProgramInputs={setShowProgramInputs}
            requiredToQuoteEngineResults={requiredToQuoteEngineResults}
            setRequiredToQuoteEngineResults={setRequiredToQuoteEngineResults}
            setDraggableAftermarketProducts={setDraggableAftermarketProducts}
          />
        )}
      </Grid>

      {showProgramInputs && (
        <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              size="small"
              variant="outlined"
              disabled={!isProgramOwner}
              label="Display Finance Program Name"
              value={program.financeProgramName ? program.financeProgramName : ""}
              onChange={e => handleInputChange("financeProgramName", e)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Rate Type</FormLabel>
              <RadioGroup
                aria-label="rate-type"
                name="rate-type"
                value={program.rateType}
                onChange={handleRateTypeChange}
                row>
                <FormControlLabel
                  value="INTEREST_RATE"
                  control={<Radio color="primary" disabled={!isProgramOwner} />}
                  label="Interest Rate"
                />
                <FormControlLabel
                  value="RATE_FACTOR"
                  control={<Radio color="primary" disabled={!isProgramOwner} />}
                  label="Rate Factor"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={isVOView || isCreditSubmissionView ? 6 : 12}>
            <NumberInputList
              required
              value={program.terms}
              label="Terms in Months"
              disabled={!isProgramOwner}
              onNumbersChange={value => {
                value.sort((a, b) => Number(a) - Number(b));
                handleInputChange("terms", value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Specify rate per term"
              control={
                <Switch
                  color="primary"
                  checked={isRatePerTerm}
                  onChange={handleRatePerTermToggle}
                  disabled={ratePerTermIsDisabled || !isProgramOwner}
                />
              }
            />
          </Grid>

          {isRatePerTerm ? (
            program.terms.map((term, i) => (
              <Grid item xs={6} key={i}>
                <TextField
                  label={
                    selectedRateType === "INTEREST_RATE"
                      ? `Buy Rate at ${term} months (%)`
                      : `Rate Factor at ${term} months`
                  }
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={program.rates[i]}
                  disabled={!isProgramOwner}
                  onChange={e => handleRateInputChange(i, e)}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                label="Buy Rate (%)"
                value={program.buyRate}
                disabled={!isProgramOwner}
                onChange={e => handleInputChange("buyRate", e)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Structure</FormLabel>
              <RadioGroup
                aria-label="finance-type"
                name="finance-type"
                value={selectedFinanceType}
                onChange={handleFinanceTypeChange}
                row>
                <FormControlLabel
                  value="financeProfit"
                  control={<Radio color="primary" disabled={!isProgramOwner} />}
                  label="Markup"
                />
                <FormControlLabel
                  value="subsidy"
                  control={<Radio color="primary" disabled={!isProgramOwner} />}
                  label="Subsidy"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio color="primary" disabled={!isProgramOwner} />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {selectedFinanceType === "financeProfit" && (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Method</FormLabel>
                <RadioGroup
                  aria-label="rate-per-term-method"
                  name="rate-per-term-method"
                  value={selectedRatePerTermMethod}
                  onChange={handleRatePerTermMethodChange}
                  row>
                  <FormControlLabel
                    value="markup"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Markup %"
                  />
                  <FormControlLabel
                    value="customerRate"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Customer Rate"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {(selectedFinanceType === "subsidy" ||
            (selectedRatePerTermMethod === "customerRate" && selectedFinanceType === "financeProfit")) && (
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                label="Customer Rate (%)"
                value={program.customerRate}
                onChange={e => handleInputChange("customerRate", e)}
              />
            </Grid>
          )}
          {selectedFinanceType === "financeProfit" && selectedRatePerTermMethod === "markup" && (
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                type="number"
                variant="outlined"
                label="Dealer Markup (%)"
                value={program.dealerMarkup}
                onChange={e => handleInputChange("dealerMarkup", e)}
                fullWidth
                disabled={
                  !isProgramOwner && _.get(program, "financeProgramModificationSettings.markup", false) !== true
                }
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="program-type-label" required>
                Program Type
              </InputLabel>
              <Select
                variant="outlined"
                label="Program Type"
                value={program.type}
                labelId="program-type-label"
                onChange={e => handleInputChange("type", e)}
                disabled={!isProgramOwner}>
                {[
                  { label: "Loan", value: "LOAN" },
                  { label: "Lease", value: "LEASE" },
                  { label: "EFA", value: "EFA" },
                ].map(({ label, value }) => (
                  <MenuItem key={label} value={value} children={label} />
                ))}
              </Select>
            </FormControl>
          </Grid>

          {program.type === "LEASE" && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                size="small"
                type="number"
                variant="outlined"
                label="Residual (%)"
                disabled={!isProgramOwner}
                value={program.residualPercentage}
                onChange={e => handleInputChange("residualPercentage", e)}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              size="small"
              type="number"
              variant="outlined"
              disabled={!isProgramOwner}
              label="Advance Payment (# of)"
              value={program.numberOfAdvancePayments}
              onChange={e => handleInputChange("numberOfAdvancePayments", e)}
            />
          </Grid>

          {(isVOView || isCreditSubmissionView) && (
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                variant="outlined"
                label="Down Payment"
                disabled={!isProgramOwner}
                value={formatCurrency(program.downPaymentAmount, true) || ""}
                onChange={e => floatNumberRegexp.test(e.target.value) && handleInputChange("downPaymentAmount", e)}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel required id="payment-calculation-label">
                Payment Calculation Method
              </InputLabel>
              <Select
                variant="outlined"
                label="Payment Calculation Method"
                labelId="payment-calculation-label"
                value={program.paymentPeriodTiming}
                onChange={e => handleInputChange("paymentPeriodTiming", e)}
                disabled={!isProgramOwner}>
                {[
                  { label: "Advance", value: "BEGINNING_OF_PERIOD" },
                  { label: "Arrears", value: "END_OF_PERIOD" },
                ].map(({ label, value }) => (
                  <MenuItem key={label} value={value} children={label} />
                ))}
              </Select>
            </FormControl>
          </Grid>

          {!(isVOView || isCreditSubmissionView) && (
            <>
              <Grid container style={{ margin: "16px 0" }}>
                <FormControlLabel
                  style={{ color: "rgba(0,0,0,0.5)", fontWeight: "normal", marginLeft: 0 }}
                  value={program.validDateStart || program.validDateEnd}
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      style={{
                        color: program.validDateStart || program.validDateEnd ? theme.palette.primary.main : undefined,
                      }}
                      checked={program.validDateStart || program.validDateEnd}
                      onChange={e => handleValidityDateToggle()}
                    />
                  }
                  label="Validity Dates"
                  labelPlacement="start"
                />
              </Grid>
              {(program.validDateStart || program.validDateEnd) && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        sx={{ width: "100% " }}
                        label="Start Date"
                        value={
                          program.validDateStart
                            ? moment(
                                /^\d+$/.test(program.validDateStart)
                                  ? parseInt(program.validDateStart)
                                  : program.validDateStart
                              )
                            : null
                        }
                        onChange={e => handleInputChange("validDateStart", e.toISOString())}
                        disabled={!isProgramOwner}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        sx={{ width: "100% " }}
                        label="End Date"
                        value={
                          program.validDateEnd
                            ? moment(
                                /^\d+$/.test(program.validDateEnd)
                                  ? parseInt(program.validDateEnd)
                                  : program.validDateEnd
                              )
                            : null
                        }
                        onChange={e => handleInputChange("validDateEnd", e.toISOString())}
                        disabled={!isProgramOwner}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            {program.flatFees.map((fee, feeIndex) => (
              <FeeInput
                fee={fee}
                key={feeIndex}
                index={feeIndex}
                onRemove={() => handleRemoveFee(feeIndex)}
                onFeeChange={(field, value) => handleFeeChange(feeIndex, field, value)}
                disabled={!isProgramOwner}
              />
            ))}

            {isProgramOwner && (
              <Button onClick={() => handleAddFee()} style={{ marginTop: "4px", color: theme.palette.primary.main }}>
                <AddOutlined />
                Add Fee
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
});

export default FinanceProgramForm;
