import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { AssignmentInd } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import Autocomplete from "@mui/material/Autocomplete";
import documentIcon from "assets/img/icons/document.png";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { format } from "date-fns";
import _, { isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { Button as MUIButton } from "@mui/material";
import { salesGroups, vendorContactRoleHumanReadable } from "../utils";
import CustomInput from "./CustomInput/CustomInput";
import Tile from "./Tile/Tile";
import Fab from "@mui/material/Fab";
import GetAppIcon from "@mui/icons-material/GetApp";
import { asyncForEach } from "../utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ListIcon from "@mui/icons-material/List";
import Alert from "@mui/material/Alert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { SET_DOCS_REFETCH } from "../redux/types";
import gql from "graphql-tag";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import Box from "@mui/material/Box";

const config = require("config");

const useStyles = makeStyles(theme => ({
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "4px 18px",
    },
  },
  regardingSelect: {
    paddingTop: "11px",
  },
  titleChatText: {
    fontSize: "16px",
    fontWeight: "500",
  },
  notifyContainer: {
    display: "flex",
    gap: 8,
    padding: "16px",
    alignItems: "center",
  },
  notifyCheckboxesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  paper: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    width: "80%",
    height: 839,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  selectStyles: {
    height: 40,
    marginBottom: 8,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskTitle: {
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "20px",
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  taskAssigned: {
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: "300",
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
  selectLabel: {
    background: "#fff",
    padding: "0 4px",
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  dateField: {
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
}));
const useUiStyles = makeStyles(styles);
const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const UPLOAD_FILE = gql`
  mutation ($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;

const DEACTIVATE_DOC = gql`
  mutation ($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

export default function LenderTaskForm(props) {
  const classes = { ...useStyles(), ...useUiStyles() };
  const dispatch = useDispatch();
  const drawerContained = props.drawerContained ?? false;
  const handleAddTask = props.handleAddTask;
  const handleSaveTask = props.handleSaveTask;
  const creditSubId = props.creditSubId;

  const refetchDocs = useSelector(state => state.docs.refetch);
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  let relatedUsers = useSelector(state => state.relatedUsers);

  const lenderProfiles = useSelector(state => state.lp);
  const creditApp = useSelector(state => state.creditApp);
  const vo = useSelector(state => state.vo);

  const regexp = /^\$?([\d,.]*)?$/;
  const uiClasses = useUiStyles();
  const taskId = props.taskId; //NULL FOR NEW TASKS
  const tasksData = props.tasksData;
  const renderInWorkflowConstructor = props.renderInWorkflowConstructor ?? false;
  const [modalStyle] = useState(getModalStyle);
  const [enabledForSMS, setEnabledForSMS] = useState(null);
  const [assignedByAccount, setAssignedByAccount] = useState("");
  const [assignedByUser, setAssignedByUser] = useState("");
  const [assignedByUserProfileId, setAssignedByUserProfileId] = useState("");
  const [assignee, setAssignee] = useState("");
  const [assignedToAccount, setAssignedToAccount] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [priority, setPriority] = useState("medium");
  const [type, setType] = useState("info");
  const [regarding, setRegarding] = useState("");
  const [status, setStatus] = useState("open");
  const [assigneeList, setAssigneeList] = useState([]);
  const [createdDateTime, setCreatedDateTime] = useState("");
  const [vendorOpportunityId, setVendorOpportunityId] = useState(null);
  const [notifyVia, setNotifyVia] = useState("email");
  const [notifyByEmail, setNotifyByEmail] = useState(true);
  const [notifyByText, setNotifyByText] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [lastReminderSent, setLastReminderSent] = useState("");
  const [nextReminder, setNextReminder] = useState("");
  const [taskItems, setTaskItems] = useState([]);
  const [assignedToUserProfileId, setAssignedToUserProfileId] = useState(null);
  const [openUploadZone, setOpenUploadZone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [taskAssignedToMe, setTaskAssignedToMe] = useState(false);
  const [taskItemIndex, setTaskItemIndex] = useState(0);
  const [taskDueDateTime, setTaskDueDateTime] = useState(moment().add(7, "d").toISOString());

  const extendedNotificationOptions = [
    { value: "sms", text: "SMS" },
    { value: "both", text: "Email & SMS" },
  ];

  const fileOptionsForDealerAssignment = [
    { value: "quote", text: "Quote" },
    { value: "invoice", text: "Invoice" },
    { value: "buyers order", text: "Buyer's Order" },
    { value: "insurance certificate", text: "Insurance Certificate" },
    { value: "driver license", text: "Driver License" },
    { value: "bank statements", text: "Bank Statements" },
    { value: "tax returns", text: "Tax Returns" },
    { value: "other", text: "Other" },
  ];
  const fileOptionsForLenderAssignment = [
    { value: "quote", text: "Quote" },
    { value: "invoice", text: "Invoice" },
    { value: "buyers order", text: "Buyer's Order" },
    { value: "decision letter", text: "Decision Letter" },
    { value: "insurance certificate", text: "Insurance Certificate" },
    { value: "driver license", text: "Driver License" },
    { value: "bank statements", text: "Bank Statements" },
    { value: "tax returns", text: "Tax Returns" },
    { value: "other", text: "Other" },
  ];
  const [fileInput] = useMutation(UPLOAD_FILE, {
    context: { authRequired: true },
  });

  //Validations before submit
  useEffect(() => {
    setSubmitEnabled(true);

    if (_.size(taskItems) === 0) {
      setSubmitEnabled(false);
    }
    if (_.isEmpty(assignee)) {
      setSubmitEnabled(false);
    }
  }, [assignee, taskItems]);
  const [deactivateDocRequest, { data: deactivateDocResponseData, loading: mutationDeactivateDoc }] = useMutation(
    DEACTIVATE_DOC,
    {
      context: { authRequired: true },
    }
  );

  useEffect(() => {
    if (_.get(vo, "potentialCustomer.name", false)) {
      setRegarding(vo.potentialCustomer.name);
    }
    if (_.get(vo, "vendorOpportunityId", false)) {
      setVendorOpportunityId(vo.vendorOpportunityId);
    }
    let allRecipients = [
      ...relatedUsers,
      { ...userProfile, accountId: account.id, account }, //add logged user to recipient list
    ];
    const accounts = _.uniq(
      allRecipients.map(up => {
        return up.account;
      }),
      "name"
    );
    if (renderInWorkflowConstructor && props.dynamicRecipients) {
      allRecipients = _.concat(allRecipients, props.dynamicRecipients);
    }

    let mergedRecipientList = allRecipients.map(userProfile => {
      const account = _.find(accounts, {
        id: userProfile.accountId,
      });

      if (account) {
        if (userProfile.vendorContactRole) {
          return {
            ...userProfile,
            company: account.name,
            role: vendorContactRoleHumanReadable[userProfile.vendorContactRole],
            category: `Internal (${vendorContactRoleHumanReadable[userProfile.vendorContactRole]})`,
          };
        } else {
          return {
            ...userProfile,
            company: account.name,
            role: "Lender",
            category: "Lender",
          };
        }
      }
      return userProfile;
    });

    setAssigneeList(_.sortBy(_.uniqBy(mergedRecipientList, "id"), "category"));
  }, [relatedUsers, vo, userProfile]);

  useEffect(() => {
    if (taskId && !_.isEmpty(tasksData)) {
      const {
        assignedByAccount,
        assignedByUser,
        assignedToAccount,
        createdBy,
        status,
        regarding,
        priority,
        createdDateTime,
        assignedToUserProfileId,
        assignedByUserProfileId,
        notifyByEmail,
        notifyByText,
        lastReminderSent,
        nextReminder,
        taskItems,
        vendorOpportunityId,
      } = _.find(tasksData, {
        taskId,
      });

      //Load task info into Form
      setAssignedByAccount(assignedByAccount);
      setAssignedByUser(assignedByUser);
      setAssignedToAccount(assignedToAccount);
      setAssignedByUserProfileId(assignedByUserProfileId);
      setAssignedToUserProfileId(assignedToUserProfileId);
      setCreatedBy(createdBy);
      setStatus(status);
      setType(type);
      setPriority(priority);
      setCreatedDateTime(createdDateTime);
      setNotifyByEmail(notifyByEmail);
      setNotifyByText(notifyByText);
      setLastReminderSent(lastReminderSent);
      setNextReminder(nextReminder);
      setNotificationsEnabled(notifyByEmail || notifyByText);
      setRegarding(regarding);
      setVendorOpportunityId(vendorOpportunityId);
      if (taskItems) {
        setTaskItems(
          taskItems.map(taskItem => {
            taskItem.invoiceQuoteNumber = null;
            if (taskItem.doc) {
              let doc = taskItem.doc;
              doc.file = null;
              doc.reader = null;
              taskItem.doc = doc;
            } else {
              taskItem.doc = { file: null, reader: null };
            }

            return taskItem;
          })
        );
      } else {
        setTaskItems([]);
      }

      setTaskAssignedToMe(assignedToUserProfileId === userProfile.id);
      //Fill task Info data
    }
  }, [taskId, tasksData]);

  useEffect(() => {
    if (!_.isEmpty(assigneeList)) {
      let assignee;
      let assignor;
      if (!taskId) {
        assignor = _.find(assigneeList, { id: userProfile.id });
        setAssignedByUser(userProfile.fullName);
        setAssignedByUserProfileId(userProfile.id);
        setAssignedByAccount(account.name);

        setAssignee(null);
        if (_.get(assignor, "mugshot", false)) {
          setAvatar(`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${assignor.mugshot}`);
        }
      } else {
        assignee = _.find(assigneeList, { id: assignedToUserProfileId });
        assignor = _.find(assigneeList, { id: assignedByUserProfileId });
        setAssignee(assignee);

        if (_.get(assignee, "mobilePhoneNumber", false)) {
          setEnabledForSMS(true);
        } else {
          setEnabledForSMS(false);
        }
        if (_.get(assignor, "mugshot", false)) {
          setAvatar(`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${assignor.mugshot}`);
        }
      }
    }
  }, [assignedByUserProfileId, assignedToUserProfileId, assigneeList, userProfile, account]);

  useEffect(() => {
    if (notifyByEmail) {
      setNotifyVia("email");
    }
    if (notifyByText) {
      setNotifyVia("sms");
    }
    if (notifyByEmail && notifyByText) {
      setNotifyVia("both");
    }
  }, [notifyByText, notifyByEmail]);

  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      dispatch({
        type: SET_DOCS_REFETCH,
        payload: { refetch: !refetchDocs },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownload = doc => {
    try {
      const link = document.createElement("a");
      link.href = doc.source;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", doc.docName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatus = event => setStatus(event.target.value);
  const handlePriority = event => setPriority(event.target.value);
  const handleCreatedDateTime = event => setCreatedDateTime(event.target.value);

  const handleCloseUploadZone = () => setOpenUploadZone(false);

  const [mutationError, setMutationError] = useState("");

  const handleAddDoc = index => {
    setTaskItemIndex(index);
    setOpenUploadZone(true);
  };
  const handleAddItems = () => {
    setTaskItems([
      ...taskItems,
      {
        type: "docs",
        taskAssigneeNotes: "",
        taskAssignorNotes: "",
        docType: "invoice",
        doc: {
          file: null,
          reader: null,
        },
      },
    ]);
  };

  const handleRemoveItems = () => {
    let previous = _.cloneDeep(taskItems);

    const removedItem = previous.pop();
    // if (_.get(removedItem, 'documentId', false)) {
    //   handleDeactivateDoc(removedDoc.documentId);
    // }

    setTaskItems(previous);
  };

  const handleSaveTaskItems = (index, key, item) => {
    let previous = _.cloneDeep(taskItems);

    if (key === "file") {
      index = taskItemIndex;
      previous[index]["doc"]["file"] = item;
      previous[index]["doc"]["reader"] = null;
      if (item.type.includes("image")) {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          previous[index]["doc"]["reader"] = reader.result;
          setTaskItems(previous);
        };
      } else {
        previous[index]["doc"]["reader"] = documentIcon;
        setTaskItems(previous);
      }
    } else {
      previous[index][key] = item;
      setTaskItems(previous);
    }
  };

  const handleSubmit = async () => {
    let payloadInput = {
      vendorOpportunityId: vendorOpportunityId,
      regarding: regarding,
      status: status,
      assignedByUserProfileId: userProfile.id,
      assignedByAccountId: account.id,
      assignedToUserProfileId: assignee.id,
      assignedToAccountId: account.id,
      priority: priority,
      createdBy: userProfile.fullName,
      assignedByUser: userProfile.fullName,
      assignedByAccount: account.name,
      assignedToUser: assignee.fullName,
      assignedToAccount: "will_be_changed_by_backend",
      creditSubId: creditSubId ? creditSubId : null,
      notifyByEmail,
      notifyByText,
      dueDateTime: taskDueDateTime,
    };

    if (taskItems) {
      payloadInput.taskItems = taskItems.map(taskItem => {
        return {
          type: taskItem.type,
          docType: taskItem.docType,
          taskAssigneeNotes: taskItem.taskAssigneeNotes,
          taskAssignorNotes: taskItem.taskAssignorNotes,
        };
      });
    }

    setLoading(true);
    let uploadedFiles = [];
    //Uploading documents
    if (taskId) {
      await asyncForEach(taskItems, async doc => {
        try {
          if (doc.type === "docs" && doc.doc.file) {
            const input = {
              vendorOpportunityId: vendorOpportunityId,
              source: "will_be_changed_by_server",
              createdBy: userProfile.fullName || "will_be_changed_by_server",
              docName: "will_be_changed_by_server",
              docDescription: "Decision Letter",
              docType: doc.docType,
            };
            const file = doc.doc.file;
            const responseFileUpload = await fileInput({
              variables: {
                input: input,
                file,
              },
            });

            if (_.get(responseFileUpload, "data.upsertDocument.document", false)) {
              uploadedFiles.push({
                taskItemId: doc.taskItemId,
                documentId: responseFileUpload.data.upsertDocument.document,
              });
            }
          }
        } catch (err) {
          console.log("------------------------------------");
          console.log(err);
          console.log("------------------------------------");
        }
      });
    }

    try {
      //Task ID equals to "-1" used to determine if saved state has draft info
      if (taskId && taskId !== "-1") {
        await handleSaveTask({
          taskId,
          input: {
            taskId,
            status,
            priority,
            assignedToUserProfileId: assignee.id,
            assignedByAccount: account.name,
            assignedToUser: assignee.fullName,
            notifyByEmail,
            notifyByText,
            dueDateTime: taskDueDateTime,
            taskItems: taskItems.map(taskItem => {
              const foundUploadedDocumentId = _.get(
                _.find(uploadedFiles, {
                  taskItemId: taskItem.taskItemId,
                }),
                "documentId",
                false
              );
              let documentId = _.get(taskItem, "doc.documentId", null);
              console.log("------------------------------------");
              console.log(documentId);
              console.log("------------------------------------");
              if (foundUploadedDocumentId) {
                documentId = foundUploadedDocumentId;
              }
              return {
                type: taskItem.type,
                docType: taskItem.docType,
                taskAssigneeNotes: taskItem.taskAssigneeNotes,
                taskAssignorNotes: taskItem.taskAssignorNotes,
                documentId,
              };
            }),
          },
        });
      } else {
        await handleAddTask(payloadInput);
      }
    } catch (err) {
      console.log(err);
    }

    dispatch({
      type: SET_DOCS_REFETCH,
      payload: { refetch: !refetchDocs },
    });

    setLoading(false);
    props.handleClose();
  };

  if (loading) {
    return (
      <div style={modalStyle} className={classes.surface}>
        <Tile
          title={taskId ? (taskAssignedToMe ? "Work Task" : "Edit Task") : "Add Task"}
          maxHeightLimitByScreen={true}
          titleIcon={<AssignmentInd />}>
          <div className={classes.formContainer}>
            <LinearProgress />
          </div>
        </Tile>
      </div>
    );
  }

  const FormComponent = (
    <form noValidate autoComplete="off">
      <Grid container spacing={4} className={classes.formContainerGridContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.subHeaderContainer}>
          <span className={classes.taskTitle}>Task Details</span>
          {avatar ? (
            <div>
              <Avatar alt="profilePicture" src={avatar} />
            </div>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={10}>
          {assignedByAccount ? (
            <h5 className={classes.taskAssigned}>
              Assigned by: {assignedByUser} ({assignedByAccount})
            </h5>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
          <TextField
            fullWidth
            size="small"
            id="regarding"
            label="Regarding"
            variant="outlined"
            value={regarding}
            onChange={e => {
              setRegarding(e.target.value);
            }}
          />
        </Grid>

        {taskId && nextReminder && (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              variant="outlined"
              size="small"
              id="createdOn"
              label="Next reminder"
              fullWidth
              disabled
              value={format(new Date(nextReminder), "MM/dd/yyyy hh:mm a")}
              slotProps={{
                htmlInput: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ width: "100% " }}
              label="DUE DATE:"
              value={taskDueDateTime ? moment(taskDueDateTime) : null}
              onChange={val => setTaskDueDateTime(val.toISOString())}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id={`priority-select-label`}>Priority</InputLabel>
            <Select
              label="Priority"
              value={priority}
              id="priority-select"
              onChange={handlePriority}
              labelId="priority-select-label">
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id={`status-select-label`}>Status</InputLabel>
            <Select
              value={status}
              label="Status"
              id="status-select"
              labelId="status-select-label"
              onChange={handleStatus}>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="requested">Requested</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
          {assigneeList && (
            <Box display="flex" width="100%" alignItems="center">
              <Autocomplete
                fullWidth
                id="listOfAssignees"
                value={assignee}
                options={assigneeList}
                groupBy={option => {
                  if (renderInWorkflowConstructor) {
                    return option.category;
                  } else {
                    return option.company;
                  }
                }}
                disabled={assignedToUserProfileId === userProfile.id}
                onChange={(event, contact) => {
                  if (_.get(contact, "mobilePhoneNumber", false)) {
                    setEnabledForSMS(true);
                  } else {
                    setEnabledForSMS(false);
                  }
                  setNotifyVia("email"); //needs to reset
                  setAssignee(contact);
                }}
                getOptionLabel={option =>
                  `${option.fullName}${renderInWorkflowConstructor ? "" : " (" + option.role + ")"}`
                }
                renderInput={params => (
                  <TextField {...params} variant="outlined" size="small" label="Assigned To" placeholder="Search" />
                )}
              />

              {enabledForSMS === false && (
                <Tooltip id="tooltip-top-start" title="Mobile Phone not available" placement="top">
                  <MobileOffIcon color="primary" className={classes.smallIcon} size="small" />
                </Tooltip>
              )}
            </Box>
          )}
        </Grid>

        {assignee && (
          <Grid xs={12} sm={6} md={6} lg={6} className={classes.notifyContainer}>
            <h5 className={classes.titleChatText}>Notify Via</h5>
            <Grid className={classes.notifyCheckboxesContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="medium"
                    name="notifyViaEmail"
                    className={classes.chatCheckboxStyles}
                    checked={notifyVia === "email"}
                    onChange={() => setNotifyVia(notifyVia === "email" ? false : "email")}
                  />
                }
                label="Email"
                size="small"
              />
              {taskId && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="medium"
                        name="notifyViaEmail"
                        className={classes.chatCheckboxStyles}
                        checked={notifyVia === "sms"}
                        onChange={() => setNotifyVia(notifyVia === "sms" ? false : "sms")}
                      />
                    }
                    label="SMS (if available)"
                    size="small"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="medium"
                        name="notifyViaEmail"
                        className={classes.chatCheckboxStyles}
                        checked={notifyVia === "both"}
                        onChange={() => setNotifyVia(notifyVia === "both" ? false : "both")}
                      />
                    }
                    label="Both"
                    size="small"
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}

        {taskId && (
          <>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{ width: "100% " }}
                  label="Created on"
                  value={createdDateTime ? moment(createdDateTime) : moment()}
                  onChange={val => setCreatedDateTime(val.toISOString())}
                />
              </LocalizationProvider>
            </Grid>

            {lastReminderSent && (
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100% " }}
                    label="Last reminder sent"
                    value={lastReminderSent ? moment(lastReminderSent) : null}
                    onChange={val => setLastReminderSent(val.toISOString())}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <br />
          <h4>
            <ListIcon color="action" size="small" />
            Task Items
          </h4>
          {_.size(taskItems) ? (
            <hr />
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Alert severity="info">Click on 'Add' to starting adding Task Items</Alert>
            </Grid>
          )}
          {taskItems.map((taskItem, index) => {
            return (
              <div key={`taskItem.${index}.general`}>
                <Grid container spacing={2}>
                  <DropzoneDialog
                    id={`dropZone.${index}`}
                    key={`dropZone[${index}]`}
                    open={openUploadZone}
                    onDrop={() => {}}
                    onSave={file => {
                      handleSaveTaskItems(taskItemIndex, "file", file[0]);
                      handleCloseUploadZone();
                    }}
                    onClose={() => {
                      handleCloseUploadZone();
                    }}
                    filesLimit={1}
                    dropzoneText={"Drag or Click Here To Upload File"}
                    // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    showPreviews={false}
                    maxFileSize={5000000}
                  />
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.attachmentSubtitle}>Task Item #{index + 1}</h5>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel htmlFor="simple-select">Type</InputLabel>
                      <Select
                        label="Type"
                        key={`taskItems[${index}].type`}
                        value={taskItem.type}
                        onChange={e => {
                          handleSaveTaskItems(index, "type", e.target.value);
                        }}
                        inputProps={{
                          name: `taskItems[${index}].type`,
                          id: `taskItems[${index}].type`,
                          disabled: taskAssignedToMe && taskId,
                        }}>
                        <MenuItem value={"docs"}>Document Upload</MenuItem>
                        <MenuItem value={"info"}>Information Request</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {taskItem.type === "docs" && (
                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "1rem" }}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel htmlFor="simple-select">Document Type</InputLabel>
                        <Select
                          label="Document Type"
                          value={taskItem.docType || ""}
                          key={`taskItem[${index}].docType`}
                          onChange={e => {
                            handleSaveTaskItems(index, "docType", e.target.value);
                          }}
                          inputProps={{
                            name: `taskItems${index}].docType`,
                            id: `taskItems${index}].docType`,
                            disabled: taskAssignedToMe,
                          }}>
                          {taskAssignedToMe
                            ? fileOptionsForLenderAssignment.map((item, key) => {
                                return (
                                  <MenuItem key={`taskItems${index}].docType[${item.value}]`} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })
                            : fileOptionsForDealerAssignment.map((item, key) => {
                                return (
                                  <MenuItem key={`taskItems${index}].docType[${item.value}]`} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      label={taskAssignedToMe ? "Notes from Assignor" : "Notes to Assignee"}
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled={taskAssignedToMe}
                      value={taskItem.taskAssignorNotes || ""}
                      onChange={e => {
                        handleSaveTaskItems(index, "taskAssignorNotes", e.target.value);
                      }}
                    />
                  </Grid>
                  {taskAssignedToMe ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInput
                        labelText="Response to Assignor"
                        id={`taskItem.${index}.taskAssigneeNotes`}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "text",
                          disabled: !taskAssignedToMe,
                          onChange: e => {
                            handleSaveTaskItems(index, "taskAssigneeNotes", e.target.value);
                          },
                          value: taskItem.taskAssigneeNotes || "",
                        }}
                      />
                    </Grid>
                  ) : null}

                  {taskItem.type === "docs" ? (
                    <>
                      {/* SHOWN IN EDIT MODE */}
                      {_.get(taskItem, "doc.documentId", false) ? (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {_.get(taskItem.doc, "docName", false) ? (
                              <CustomInput
                                labelText="File Name"
                                key={`docs[${index}].file`}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  type: "text",
                                  id: `docs[${index}].file`,
                                  value: taskItem.doc.docName || "",
                                }}
                              />
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {_.get(taskItem.doc, "createdBy", false) ? (
                              <CustomInput
                                labelText="Uploaded by"
                                key={`docs[${index}].createdBy`}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  type: "text",
                                  id: `docs[${index}].createdBy`,
                                  value: taskItem.doc.createdBy || "",
                                }}
                              />
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            {_.get(taskItem.doc, "source", false) ? (
                              <Tooltip id="tooltip-top-start" title="Click to Download Document" placement="top">
                                <Fab
                                  color="primary"
                                  size="small"
                                  onClick={() => handleDownload(taskItem.doc)}
                                  aria-label="download">
                                  <GetAppIcon />
                                </Fab>
                              </Tooltip>
                            ) : null}
                          </Grid>
                        </>
                      ) : (
                        <>
                          {/* SHOWN IN EDIT MODE */}
                          {taskAssignedToMe ? (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Button
                                onClick={() => {
                                  handleAddDoc(index);
                                }}
                                color="dribbble"
                                size={"sm"}>
                                <CloudUploadIcon size="sm" />
                                Upload Document
                              </Button>
                            </Grid>
                          ) : null}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {_.get(taskItem.doc, "file.name", false) ? (
                              <CustomInput
                                labelText="File Name"
                                key={`taskItem.doc[${index}].file`}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  type: "text",
                                  id: `taskItem.doc[${index}].file`,
                                  value: taskItem.doc.file.name || "",
                                }}
                              />
                            ) : null}
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {_.get(taskItem.doc, "reader", false) ? (
                              <img src={taskItem.doc.reader} className={classes.previewDoc} />
                            ) : null}
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
              </div>
            );
          })}
        </Grid>

        {/* Add // Remove Buttons */}
        {!taskAssignedToMe ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <br />
              <br />
              <hr />
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button onClick={handleAddItems} color="primary" size={"sm"}>
                    <AddCircleIcon />
                    Add
                  </Button>
                  {_.size(taskItems) ? (
                    <Button onClick={handleRemoveItems} color="primary" size={"sm"}>
                      <RemoveCircleIcon /> Remove
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}

        <br />
        <br />
      </Grid>
    </form>
  );

  if (drawerContained) {
    return (
      <>
        {FormComponent}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "40px",
          }}>
          <MUIButton
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
            disabled={!submitEnabled}
            startIcon={<SaveIcon />}>
            Save Task
          </MUIButton>
        </div>
      </>
    );
  }

  return (
    <div style={modalStyle} className={classes.surface}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose} size="large">
        <CloseIcon />
      </IconButton>
      <Tile title={taskId ? "Edit Task" : "Add Task"} maxHeightLimitByScreen titleIcon={<AssignmentInd />}>
        <div className={classes.formContainer}>
          {FormComponent}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "40px",
            }}>
            <Button onClick={handleSubmit} color="success" disabled={!submitEnabled} startIcon={<PublishIcon />}>
              Save
            </Button>
          </div>
        </div>
      </Tile>
    </div>
  );
}
