import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Auth } from "aws-amplify";
import { FORM_ERROR } from "final-form";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import { setRefetch } from "../../redux/actions";
import { getHeadersFromAuth, setFieldValue, validateValue } from "../../utils";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { theme } from "../../theme";

const config = require("../../config.js");

const useStyles = makeStyles({
  companyProfile: {
    paddingLeft: "10px!important",
    marginTop: "50px",
  },
  defaultIcon: {
    position: "relative",
    right: "17px",
    maxWidth: "15px",
  },
  defaultContactSelector: {
    paddingBottom: "16px",
  },
  companyInfo: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "25px 10px 25px 30px !important",
    marginBottom: "50px",
    fontSize: "12px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  companyProfilePhotoContainer: {
    boxShadow: "none",
  },
  companyProfilePhoto: {
    padding: 0,
  },
  companyProfileButton: {
    backgroundColor: "#3E83E6",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
  },
  companyProfileHeader: {
    fontSize: "26px",
    fontWeight: "300",
    marginBottom: "15px",
  },
  companyProfileContact: {
    paddingRight: "22px",
    display: "flex",
    alignItems: "center",
  },
  companyProfileCancelButton: {
    backgroundColor: "#999",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
    marginLeft: "20px",
  },
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Q_PARTNER_LINK = gql`
  query ($ID: ID, $dynamicsId: ID) {
    partnerLink(id: $ID, dynamicsId: $dynamicsId) {
      dynamicsPartnerId
      name
      partnerAccountId
      dynamicsPartnerAccountId
      partnerProfileId
      dynamicsPartnerProfileId
      partnerContactId
      dynamicsPartnerContactId
      dynamicsPartnerLinkId
      address
      phone
      contact {
        firstName
        lastName
        email
        phoneNumber
      }
      dealerContact {
        firstName
        lastName
        email
        phoneNumber
        dynamicsContactId
        accountId
      }
      dealerSalesReps {
        fullName
        title
        email
        phoneNumber
        dynamicsContactId
      }
    }
  }
`;

const M_PARTNER_LINK = `mutation($fieldsObj: UpdatePartnerLinkInput) {
    updatePartnerLinkContact(fieldsObj: $fieldsObj) {
      id
    }
  }
`;

function PartnerDealerAccountDetails({ userProfile, match, setRefetch }) {
  const partnerLinkId = match.params.dynamicsId;
  const history = useHistory();
  const [dealerReps, setDealerReps] = useState("");

  const { data: partnerDealerDetail, refetch: partnerDealerRefetch } = useQuery(Q_PARTNER_LINK, {
    variables: {
      dynamicsId: partnerLinkId,
    },
  });

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [createNewContact, setCreateNewContact] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
    },
    businessName: {
      value: "",
      validationStatus: "",
    },
    address: {
      value: "",
      validationStatus: "",
    },
    phone: {
      value: "",
      validationStatus: "",
    },
    primaryContactFirstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactLastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactPhone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
      validations: [{ type: "required" }],
    },
    primaryContactTitle: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactEmail: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactDynamicsId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    newPrimaryContactFirstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    newPrimaryContactLastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    newPrimaryContactPhone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
    },
    newPrimaryContactTitle: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    newPrimaryContactEmail: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  const handleContactClick = id => {
    history.push(`/profile/${id}`);
  };

  const handleAccountClick = id => {
    history.push(`/accountDetails/${id}`);
  };

  useEffect(() => {
    if (partnerDealerDetail) {
      var reps;
      if (partnerDealerDetail.partnerLink.dealerSalesReps) {
        reps = partnerDealerDetail.partnerLink.dealerSalesReps.map(item => {
          try {
            item.value = item.dynamicsContactId;
            if (item.title == null) {
              item.label = item.fullName;
            } else {
              item.label = item.fullName + ", " + item.title;
            }
            return item;
          } catch (err) {
            console.error(err);
            return null;
          }
        });
        setDealerReps(reps);
      } else {
        setDealerReps([]);
      }
      setFields({
        id: {
          value: partnerDealerDetail.partnerLink.id ? partnerDealerDetail.partnerLink.id : "",
          validationStatus: "",
        },
        businessName: {
          value: partnerDealerDetail.partnerLink.name ? partnerDealerDetail.partnerLink.name : "",
          validationStatus: "",
        },
        address: {
          value: partnerDealerDetail.partnerLink.address ? partnerDealerDetail.partnerLink.address : "",
          validationStatus: "",
        },
        phone: {
          value: partnerDealerDetail.partnerLink.phone ? formatPhone(partnerDealerDetail.partnerLink.phone) : "",
          validationStatus: "",
          format: formatPhone,
        },
        primaryContactTitle: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact && partnerDealerDetail.partnerLink.dealerContact.title
              ? partnerDealerDetail.partnerLink.dealerContact.title
              : "",
          validationStatus: "",
        },
        primaryContactFirstName: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact && partnerDealerDetail.partnerLink.dealerContact.firstName
              ? partnerDealerDetail.partnerLink.dealerContact.firstName
              : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactLastName: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact && partnerDealerDetail.partnerLink.dealerContact.lastName
              ? partnerDealerDetail.partnerLink.dealerContact.lastName
              : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactPhone: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact && partnerDealerDetail.partnerLink.dealerContact.phoneNumber
              ? partnerDealerDetail.partnerLink.dealerContact.phoneNumber
              : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactEmail: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact && partnerDealerDetail.partnerLink.dealerContact.email
              ? partnerDealerDetail.partnerLink.dealerContact.email
              : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactDynamicsId: {
          value:
            !!partnerDealerDetail.partnerLink.dealerContact &&
            partnerDealerDetail.partnerLink.dealerContact.dynamicsContactId
              ? partnerDealerDetail.partnerLink.dealerContact.dynamicsContactId
              : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        newPrimaryContactFirstName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        newPrimaryContactLastName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        newPrimaryContactPhone: {
          value: "",
          validationStatus: "",
          format: formatPhone,
        },
        newPrimaryContactTitle: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        newPrimaryContactEmail: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
      });
    }
  }, [partnerDealerDetail]);

  const classes = useStyles();

  const handleContactButton = () => {
    if (createNewContact) {
      setCreateNewContact(false);
    } else {
      setCreateNewContact(true);
    }
  };

  return (
    <>
      {partnerDealerDetail ? (
        <Form
          onSubmit={values => {
            const formData = new FormData();
            formData.append(
              "operations",
              JSON.stringify({
                query: M_PARTNER_LINK,
                variables: {
                  fieldsObj: {
                    partnerLinkId: partnerDealerDetail.partnerLink.dynamicsPartnerLinkId,
                    email: fields.newPrimaryContactEmail.value,
                    firstName: fields.newPrimaryContactFirstName.value,
                    lastName: fields.newPrimaryContactLastName.value,
                    title: fields.newPrimaryContactTitle.value,
                    phoneNumber: fields.newPrimaryContactPhone.value ? fields.newPrimaryContactPhone.value : null,
                    dynamicsAccountId: partnerDealerDetail.partnerLink.dynamicsPartnerAccountId,
                    dynamicsContactId: createNewContact ? null : fields.primaryContactDynamicsId.value,
                    vendorContactRole: 100000004, //credit manager
                  },
                },
              })
            );
            formData.append("map", JSON.stringify({ 0: ["variables.file"] }));
            return new Promise(async (resolve, reject) => {
              const authHeaders = await getHeadersFromAuth(Auth);
              fetch(config.DCR_GRAPHQL_ENDPOINT, {
                method: "POST",
                headers: {
                  ...authHeaders,
                },
                body: formData,
              })
                .then(response => response.json())
                .then(response => {
                  response = response || {};
                  if (response.errors && response.errors.length) {
                    const errors = response.errors.map(e => e.message).join("; ");
                    console.error("Error updating contact:", errors);
                    setErrorMessage("Error updating contact!");
                    setErrorOpen(true);
                    resolve();
                  } else {
                    setSnackbarOpen(true);
                    resolve();
                    setRefetch("account", true);
                    setCreateNewContact(false);
                  }
                  setTimeout(() => partnerDealerRefetch(), 4000);
                })
                .catch(err => {
                  console.error("Error updating contact:", err);
                  const ret = {};
                  ret[FORM_ERROR] = "Error updating contact!";
                  resolve(ret);
                });
            });
          }}
          validate={checkFormValidation}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => {
                    console.log(res);
                    partnerDealerRefetch();
                  });
                }}>
                <Grid container spacing={1} className={classes.companyProfile}>
                  <Grid container>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <Button className="formBtn" color="primary" variant="outlined" onClick={() => history.goBack()}>
                        Back
                      </Button>
                    </GridItem>
                  </Grid>
                  <Grid container justifyContent="space-between" className={classes.companyInfo}>
                    <Grid item md={6}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <Typography variant={"h5"} className={classes.companyProfileHeader}>
                            Company Info
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Business Name: </b>
                          <span
                            onClick={() => handleAccountClick(partnerDealerDetail.partnerLink.dealerContact.accountId)}
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}>
                            {fields.businessName.value}
                          </span>
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Business Address: </b>
                          {fields.address.value}
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Dealer Phone: </b>
                          {fields.phone.value}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12} className={classes.companyInfo}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
                          <Typography variant={"h5"} className={classes.companyProfileHeader}>
                            Primary Contact
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Name: </b>
                          <span
                            onClick={() => handleContactClick(fields.primaryContactDynamicsId.value)}
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}>
                            {fields.primaryContactFirstName.value} {fields.primaryContactLastName.value}
                          </span>
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Phone:</b> {formatPhone(fields.primaryContactPhone.value)}
                        </Grid>
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <b>Email:</b>{" "}
                          {!fields.primaryContactFirstName.value ? (
                            <span
                              onClick={() => handleContactClick(fields.primaryContactDynamicsId.value)}
                              style={{ color: theme.palette.primary.main, cursor: "pointer" }}>
                              {" "}
                              {fields.primaryContactEmail.value}{" "}
                            </span>
                          ) : (
                            fields.primaryContactEmail.value
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <br />
                        <Grid container justifyContent="space-between" alignItems="flex-end">
                          <Grid item md={6}>
                            {dealerReps.length > 1 && (
                              <FormControl style={{ paddingTop: "16px" }} fullWidth>
                                <Grid container alignItems="center">
                                  <InputLabel
                                    style={{
                                      color: "#AAAAAA !important",
                                      fontWeight: "400",
                                      //lineHeight: '1.42857',
                                      position: "unset",
                                      width: "max-content",
                                      letterSpacing: "unset",
                                      "& + $underline": {
                                        marginTop: "0px",
                                      },
                                    }}
                                    id="vendor-salesperson-id-label">
                                    Primary Dealer Contact
                                  </InputLabel>
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">Default Contact</Typography>
                                        {
                                          "Select your Team Member who you'd like to have displayed on all default communications where a specific Sales Representative has yet to be assigned."
                                        }{" "}
                                      </React.Fragment>
                                    }>
                                    <InfoIcon className={classes.defaultIcon} color="primary"></InfoIcon>
                                  </HtmlTooltip>
                                </Grid>
                                <Select
                                  id="primaryContact"
                                  label="Primary Contact"
                                  name="primaryContactDynamicsId"
                                  variant="standard"
                                  autoWidth
                                  required
                                  inputProps={{
                                    type: "text",
                                    name: "primaryContactDynamicsId",
                                    value: fields.primaryContactDynamicsId.value,
                                    onChange: e => {
                                      partnerDealerRefetch();
                                      setValue(e.target.value, "primaryContactDynamicsId");
                                    },
                                  }}>
                                  {!!dealerReps && dealerReps.length > 1
                                    ? dealerReps.map(({ value, label }, i) => (
                                        <MenuItem value={value} key={i} label={label}>
                                          {label}
                                        </MenuItem>
                                      ))
                                    : "No Reps Available"}
                                </Select>
                              </FormControl>
                            )}
                            <br />
                            <br />
                            {!!dealerReps && dealerReps.length > 1 && (
                              <Grid item md={6} className={classes.companyProfileContact}>
                                <Grid container justifyContent="flex-end" alignItems="flex-end">
                                  <Button
                                    className={classes.companyProfileButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}>
                                    {submitting ? "Updating" : "Update"}
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      {createNewContact ? (
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                              <Typography variant={"h5"} className={classes.companyProfileHeader}>
                                Please Enter New Contact Details
                              </Typography>
                            </Grid>
                            <Grid item lg={5} xl={5} md={5} sm={5} xs={5}>
                              <CustomInput
                                labelText="Contact First Name"
                                id="newPrimaryContactFirstName"
                                formControlProps={{ fullWidth: true }}
                                error={fields.newPrimaryContactFirstName.validationStatus === "error"}
                                inputProps={{
                                  type: "text",
                                  name: "newPrimaryContactFirstName",
                                  value: fields.newPrimaryContactFirstName.value,
                                  onChange: e => setValue(e.target.value, "newPrimaryContactFirstName"),
                                }}
                              />
                            </Grid>
                            <Grid item lg={5} xl={5} md={5} sm={5} xs={5}>
                              <CustomInput
                                labelText="Contact Last Name"
                                id="newPrimaryContactLastName"
                                formControlProps={{ fullWidth: true }}
                                error={fields.newPrimaryContactLastName.validationStatus === "error"}
                                inputProps={{
                                  type: "text",
                                  name: "newPrimaryContactLastName",
                                  value: fields.newPrimaryContactLastName.value,
                                  onChange: e => setValue(e.target.value, "newPrimaryContactLastName"),
                                }}
                              />
                            </Grid>
                            <Grid item lg={2} xl={2} md={2} sm={2} xs={2}>
                              <CustomInput
                                labelText="Contact Title"
                                id="newPrimaryContactTitle"
                                formControlProps={{ fullWidth: true }}
                                error={fields.newPrimaryContactTitle.validationStatus === "error"}
                                inputProps={{
                                  type: "text",
                                  name: "newPrimaryContactTitle",
                                  value: fields.newPrimaryContactTitle.value,
                                  onChange: e => setValue(e.target.value, "newPrimaryContactTitle"),
                                }}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
                              <CustomInput
                                labelText="Contact Email"
                                id="newPrimaryContactEmail"
                                formControlProps={{ fullWidth: true }}
                                error={fields.newPrimaryContactEmail.validationStatus === "error"}
                                inputProps={{
                                  type: "text",
                                  name: "newPrimaryContactEmail",
                                  value: fields.newPrimaryContactEmail.value,
                                  onChange: e => setValue(e.target.value, "newPrimaryContactEmail"),
                                }}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
                              <CustomInput
                                labelText="Contact Phone"
                                id="newPrimaryContactPhone"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  type: "text",
                                  name: "newPrimaryContactPhone",
                                  value: fields.newPrimaryContactPhone.value,
                                  onChange: e => setValue(e.target.value, "newPrimaryContactPhone"),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <br />
                      <Grid item md={6} className={classes.companyProfileContact}>
                        <Grid container justifyContent="flex-end" alignItems="flex-end">
                          <Button
                            className={classes.companyProfileButton}
                            color="primary"
                            variant="contained"
                            onClick={() => handleContactButton()}>
                            {!createNewContact ? "Create New Contact" : "Cancel"}
                          </Button>
                          {createNewContact && (
                            <Button
                              className={classes.companyProfileButton}
                              color="primary"
                              variant="contained"
                              onClick={handleSubmit}>
                              Submit
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" variant="filled">
                    Successfully updated the account!
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error" variant="filled">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Account Details"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDealerAccountDetails);
