import React, { ReactNode, useEffect, useState } from "react";
import { parseCurrency } from "utils";
import makeStyles from "@mui/styles/makeStyles";
import { HelpOutlined, Info } from "@mui/icons-material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Box, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { CurrencyInputField, FormRadioGroup, InterestInputField } from "components/form";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { proposalProductUpdateDependentFields } from "../../../../../lib";
import { dealerCostInfoText, markupTypesOptions } from "../../../../../constants";
import { CommonMenuPriceValues, PrisingConfig, ProductConfig, ProductPrisingFields } from "../../../../../types";

interface Props {
  equipmentCost: number;
  isPricingEditable?: boolean;
  prisingConfig: PrisingConfig;
  proposalProduct: ProposalProduct;
  productConfiguration: ProductConfig;
  isConstructorWithSkippedProducts: boolean;
  onOpenConstructorPricingInfoDialog?: VoidFunction;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export const CardPricingSection = ({
  prisingConfig,
  equipmentCost,
  proposalProduct,
  isPricingEditable,
  productConfiguration,
  updateProductConfiguration,
  isConstructorWithSkippedProducts,
  onOpenConstructorPricingInfoDialog,
}: Props) => {
  const classes = useStyles();

  const [isShowCostCalcInfo, setShowCostCalcInfo] = useState<boolean>(!!productConfiguration?.rateFactor);

  const { control, setValue } = useForm<CommonMenuPriceValues>({
    defaultValues: {
      cost: productConfiguration.cost,
      markup: productConfiguration.markup,
      rateFactor: productConfiguration.rateFactor,
      retailCost: productConfiguration.retailCost,
    },
  });

  useEffect(() => {
    setValue("cost", productConfiguration.cost);
    setValue("retailCost", productConfiguration.retailCost);
    setValue("rateFactor", productConfiguration.rateFactor);
    setValue("markup.type", productConfiguration.markup?.type);
    setValue("markup.markup", productConfiguration.markup?.markup ?? 0);
  }, [productConfiguration, setValue]);

  const [cost, retailCost, markupValue, markupType, rateFactor] = useWatch({
    control,
    name: ["cost", "retailCost", "markup.markup", "markup.type", "rateFactor"],
  });

  const syncFieldsValues = (field: ProductPrisingFields, nextValue: number) => {
    const values = { cost, rateFactor, retailCost, markupType, markupValue };

    const results = proposalProductUpdateDependentFields({ values, field, nextValue, equipmentCost, proposalProduct });

    setValue("cost", results.costNextValue);
    setValue("retailCost", results.retailCostNextValue);
    setValue("markup.markup", results.markupNextValue);
    setValue("rateFactor", results.rateFactorNextValue);

    return results;
  };

  const handleChangeCost = (nextValue: unknown) => {
    const cost = Number(nextValue ?? 0);

    setShowCostCalcInfo(false);

    const { retailCostNextValue, rateFactorNextValue } = syncFieldsValues("cost", cost);

    updateProductConfiguration({
      cost,
      customPrice: true,
      retailCost: retailCostNextValue,
      rateFactor: rateFactorNextValue,
    });
  };

  const handleChangeMarkup = (nextValue: number) => {
    const { retailCostNextValue } = syncFieldsValues("markup.markup", nextValue);

    updateProductConfiguration({
      customPrice: true,
      retailCost: retailCostNextValue,
      markup: { markup: +nextValue, type: markupType },
    });
  };

  const handleChangeRetailCost = (nextValue: unknown) => {
    const retailCost = Number(nextValue ?? 0);

    const { markupNextValue } = syncFieldsValues("retailCost", retailCost);

    updateProductConfiguration({
      retailCost,
      customPrice: true,
      markup: { markup: +markupNextValue, type: markupType },
    });
  };

  const handleChangeMarkupType = (nextValue: MarkupType) => {
    let markupNextValue: number = 0;

    if (nextValue === MarkupType.Flat) {
      markupNextValue = retailCost - cost;
    }

    if (nextValue === MarkupType.Percentage) {
      markupNextValue = (markupValue / cost) * 100;
    }

    setValue("markup.type", nextValue);
    setValue("markup.markup", markupNextValue);

    updateProductConfiguration({
      markup: {
        markup: +markupNextValue,
        type: nextValue,
      },
    });
  };

  const handleChangeRateFactor = (nextValue: unknown) => {
    const rateFactor = Number(nextValue ?? 0);

    setShowCostCalcInfo(true);

    const { costNextValue, retailCostNextValue } = syncFieldsValues("rateFactor", rateFactor);

    updateProductConfiguration({
      rateFactor,
      customPrice: true,
      cost: costNextValue,
      retailCost: retailCostNextValue,
    });
  };

  const markupField: Record<MarkupType, ReactNode> = {
    [MarkupType.Flat]: (
      <CurrencyInputField
        label="Markup ($)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(Number(parseCurrency(event.target.value))),
        }}
      />
    ),
    [MarkupType.Percentage]: (
      <InterestInputField
        label="Markup (%)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(parseFloat(event.target.value)),
        }}
      />
    ),
  };

  return (
    <Box className={classes.price}>
      {prisingConfig.isShowRate && (
        <InterestInputField
          decimalScale={6}
          name="rateFactor"
          control={control}
          label="Rate Factor (%)"
          textFieldProps={{
            onChange: event => handleChangeRateFactor(parseCurrency(event.target.value)),
          }}
        />
      )}

      {prisingConfig.isShowCost && isConstructorWithSkippedProducts && (
        <CurrencyInputField
          name="cost"
          label="Cost"
          control={control}
          textFieldProps={{
            onChange: event => handleChangeCost(parseCurrency(event.target.value)),
            disabled: prisingConfig.isCostDisabled || !isPricingEditable,
            slotProps: isShowCostCalcInfo
              ? {
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={dealerCostInfoText}>
                          <Info fontSize="small" color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  },
                }
              : undefined,
          }}
        />
      )}

      {prisingConfig.isShowMarkup && isPricingEditable && (
        <Box>
          {markupField[markupType]}

          <Controller
            control={control}
            name="markup.type"
            render={({ field }) => (
              <FormRadioGroup
                row
                options={markupTypesOptions}
                {...field}
                onChange={(event, value) => handleChangeMarkupType(value as MarkupType)}
              />
            )}
          />
        </Box>
      )}

      {prisingConfig.isShowRetail && isPricingEditable && isConstructorWithSkippedProducts && (
        <CurrencyInputField
          control={control}
          name="retailCost"
          label="Retail Price"
          textFieldProps={{
            onChange: event => handleChangeRetailCost(parseCurrency(event.target.value)),
          }}
        />
      )}

      {!isConstructorWithSkippedProducts && (
        <Box display="flex" alignItems="center">
          <IconButton onClick={onOpenConstructorPricingInfoDialog} size="large">
            <HelpOutlined color="primary" style={{ cursor: "pointer" }} />
          </IconButton>

          <Typography variant="body2" style={{ paddingLeft: "10px" }}>
            <div>This product requires equipment details to determine pricing.</div>
            <div>You can still set a markup.</div>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  price: {
    gap: "0.5rem",
    display: "flex",

    "& > *": {
      flex: 1,
    },
  },
});
