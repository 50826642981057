import React, { useContext, useEffect, useState } from "react";
import { DCR_API_BASE_SERVER_URL } from "config";
import axios from "axios";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "@mui/material/Modal";
import makeStyles from "@mui/styles/makeStyles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DocumentContext } from "contexts/DocumentContext";
import CardHeader from "components/Card/CardHeader";
import MessageModal from "components/MessageModal";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import NewCreditAppModal from "./NewCreditAppModal";
import FileUploadModal from "./FileUploadModal";
import { theme } from "../../theme";

const QUERY_USER_DOCS = gql`
  query {
    adminDocuments {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      docName
      accountName
      customerName
    }
  }
`;

const GET_USER_PROFILE = gql`
  query {
    userProfile {
      fullName
      id
      vendorContactRole
    }
  }
`;

const DEACTIVATE_DOC = gql`
  mutation ($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

const useStyles = makeStyles(() => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function Documents({ vo, account, vendorProfile, refetchCreditApp }) {
  const classes = useStyles();
  let [tableData, setTableData] = useState([[]]);
  const [userProfile, setUserProfile] = useState();
  const [showCreditApp, setShowCreditApp] = useState(1);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [deactivateDocRequest, { data: deactivateDocResponseData, loading: mutationDeactivateDoc }] = useMutation(
    DEACTIVATE_DOC,
    {
      context: { authRequired: true },
    }
  );
  const documentContext = useContext(DocumentContext);
  const handleClose = () => setOpenVoCreditAppModal(false);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);

  //fetch showCreditApp attribute to show/hide creditApp
  useEffect(() => {
    if (vendorProfile) setShowCreditApp(vendorProfile.vendorProfile.showCreditApp);
  }, [vendorProfile]);

  const { loading, error, data, refetch } = useQuery(QUERY_USER_DOCS);

  if (loading) console.log("Loading documents...");
  if (error) console.log(`Error! ${error.message}`);

  const {
    loading: loadingUserProfile,
    error: errorUserProfile,
    data: dataUserProfile,
    refetch: refetchUserProfile,
  } = useQuery(GET_USER_PROFILE);

  if (loadingUserProfile) console.log("Loading userProfile...");
  if (errorUserProfile) console.log(`Error! ${errorUserProfile.message}`);

  //Fetch UserProfile for the very first time:
  useEffect(() => {
    if (dataUserProfile !== null && dataUserProfile !== undefined && dataUserProfile.userProfile.fullName !== null) {
      setUserProfile(dataUserProfile);
    }
  }, [dataUserProfile]);

  const mapData = (key, account, customer, name, uploaded_by, type, description, link) => {
    return { key, account, customer, name, uploaded_by, type, description, link };
  };

  //Fetch document for the very first time:
  useEffect(() => {
    let documents;
    if (data !== null && data !== undefined && data.adminDocuments !== null) {
      documents = data.adminDocuments.map(row =>
        mapData(
          row.documentId,
          row.accountName,
          row.customerName,
          row.docName,
          row.createdBy,
          row.docType,
          row.docDescription,
          row.source
        )
      );
    }
    addDocumentsToTableData(documents);
  }, [data]);

  useEffect(() => {
    //refetch data after lastDoc context changed
    refetch();
  }, [documentContext.lastDoc]);

  useEffect(() => {
    //refetch data after lastDoc context changed
    refetch();
  }, [deactivateDocResponseData]);

  const [creditApp, setCreditApp] = useState();
  const [openVoCreditAppModal, setOpenVoCreditAppModal] = useState(false);
  const handleCreditAppRequest = () => {
    setOpenVoCreditAppModal(true);
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile,
          vendorProfile: vendorProfile,
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };
  const handleRefetchCreditAppRequest = () => {
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          vendorProfile: vendorProfile,
          userProfile: userProfile,
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };
  const handleCreditAppRequestDownload = () => {
    handleOpenMessageModal();
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile,
          vendorProfile: vendorProfile,
          download: true,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(async response => {
        handleCloseMessageModal();
        const fileURL = window.URL.createObjectURL(response.data, { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "credit_app.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  };
  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };
  const tableHead = [
    "Account Name",
    "Customer Name",
    "File Name",
    "",
    "Uploaded By",
    "Type",
    "Description",
    "Download Link",
  ];

  function addDocumentsToTableData(documents) {
    const documentsRows = [];
    if (!!documents && documents.length) {
      documents.map(row => {
        const tableRow = [
          row.account,
          row.customer,
          row.name,
          <button className={classes.clearButton} onClick={() => handleDeactivateDoc(row.key)}>
            <DeleteForeverIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
          </button>,
          row.uploaded_by,
          row.type,
          row.description,
          <Button color="primary" size="sm" href={row.link} target="_blank">
            Download
          </Button>,
        ];
        documentsRows.push(tableRow);
      });
    }
    tableData = [...documentsRows];
    setTableData([...documentsRows]);
  }

  return (
    <Card>
      <CardHeader>
        <div className={classes.tileCardHeader}>
          <h3 className={classes.cardIconTitle}>Documents</h3>
          <FileUploadModal
            vo={vo}
            equipmentUpload={false}
            buttonDescription={() => {
              return (
                <>
                  <CloudUploadIcon style={{ marginRight: "8px" }} /> Upload Document
                </>
              );
            }}
          />
        </div>
      </CardHeader>
      <CardBody>
        <Table
          tableHead={tableHead}
          tableData={tableData}
          customCellClasses={[classes.flexJustifyEnd]}
          customClassesForCells={[5]}
          customHeadCellClasses={[classes.flexJustifyEnd]}
          customHeadClassesForCells={[5]}
        />
        <Modal
          open={openVoCreditAppModal}
          onClose={handleClose}
          aria-labelledby="Credit Application"
          aria-describedby="Summary">
          {creditApp ? (
            <React.Fragment>
              <NewCreditAppModal
                {...creditApp}
                showCreditApp={showCreditApp}
                userProfile={userProfile.userProfile}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                refetchCreditApp={refetchCreditApp}
                handleRefetchCreditAppRequest={handleRefetchCreditAppRequest}
              />
            </React.Fragment>
          ) : (
            <div />
          )}
        </Modal>
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseMessageModal}
          title={"Loading"}
          message={"Please wait while we prepare your document."}
        />
      </CardBody>
    </Card>
  );
}
