import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AddOutlined, Check, RemoveOutlined } from "@mui/icons-material";
import { formatCurrency, parseCurrency } from "utils";
import { setRefetch } from "../../../../../redux/actions";

const UPDATE_VO_CONTRACT_MUTATION = gql`
  mutation ($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

function AddNewContractModal({ open, handleClose, VOId, vo, refetchVO }) {
  const theme = useTheme();

  const [showLoanOptions, setShowLoanOptions] = useState(false);
  const [showLeaseOptions, setShowLeaseOptions] = useState(false);

  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openLoadingSnackbar, setOpenLoadingSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);

  const handleInputChange = (e, name) => {
    if (name === "leaseRateMoneyFactor" || name === "annualPercentageRate" || name === "lengthOfContractInMonths") {
      setValues({ ...values, [name]: e.target.value });
    }
    setValues({ ...values, [name]: parseCurrency(e.target.value) });
  };

  const [values, setValues] = useState({
    amountRequested: vo.amountRequested || 0.0,
    equipmentCost: vo.equipmentCost || 0.0,
    salesTax: vo.salesTax || 0.0,
    additionalFees: vo.additionalFees || 0.0,
    lengthOfContractInMonths: vo.lengthOfContractInMonths || 0.0,
    annualPercentageRate: vo.annualPercentageRate || 0.0,
    totalMonthlyPaymentEstimated: vo.totalMonthlyPaymentEstimated || 0.0,
    vehiclePriceWholesaleInvoice: vo.vehiclePriceWholesaleInvoice || 0.0,
    vehiclePriceMSRP: vo.vehiclePriceMSRP || 0.0,
    downPaymentAmount: vo.downPaymentAmount || 0.0,
    manufacturerRebateAmount: vo.manufacturerRebateAmount || 0.0,
    tradeAllowanceGrossTradeIn: vo.tradeAllowanceGrossTradeIn || 0.0,
    tradeOwedBalanceAmount: vo.tradeOwedBalanceAmount || 0.0,
    creditLifePremiumAmount: vo.creditLifePremiumAmount || 0.0,
    disabilityPremiumAmount: vo.disabilityPremiumAmount || 0.0,
    msrpGuidePercentage: vo.msrpGuidePercentage || 0.0,
    residualAmount: vo.residualAmount || 0.0,
    leaseRatePercentage: vo.leaseRatePercentage || 0.0,
    leaseRateMoneyFactor: vo.leaseRateMoneyFactor || 0.0,
    totalExtendedWarrantyAmount: vo.totalExtendedWarrantyAmount || 0.0,
    vehicleSellingPrice: vo.vehicleSellingPrice || 0.0,
    baseMonthlyPayment: vo.baseMonthlyPayment || 0.0,
    insuranceTotalExtendedWarrantyAmount: vo.insuranceTotalExtendedWarrantyAmount || 0.0,
    extendedWarrantyAmount: vo.extendedWarrantyAmount || 0.0,
    gapInsuranceAmount: vo.gapInsuranceAmount || 0.0,
    roadsideCoverageAmount: vo.roadsideCoverageAmount || 0.0,
    roadHazardAmount: vo.roadHazardAmount || 0.0,
    docFeeAmount: vo.docFeeAmount || 0.0,
    physicalDamageCoverageAmount: vo.physicalDamageCoverageAmount || 0.0,
    federalExciseTaxAmount: vo.federalExciseTaxAmount || 0.0,
  });

  const [updateVOContract, { data, error, loading }] = useMutation(UPDATE_VO_CONTRACT_MUTATION, {
    context: { authRequired: true },
  });

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoadingSnackbar(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setOpenLoadingSnackbar(true);
    setOpenSuccessSnackbar(true);
    setOpenErrorSnackbar(true);

    updateVOContract({
      variables: {
        fieldsObj: {
          amountRequested: parseFloat(values["amountRequested"]),
          salesTax: parseFloat(values["salesTax"]),
          additionalFees: parseFloat(values["additionalFees"]),
          lengthOfContractInMonths: parseInt(values["lengthOfContractInMonths"]),
          annualPercentageRate: parseFloat(values["annualPercentageRate"]),
          totalMonthlyPaymentEstimated: parseFloat(values["totalMonthlyPaymentEstimated"]),
          vehiclePriceWholesaleInvoice: parseFloat(values["vehiclePriceWholesaleInvoice"]),
          vehiclePriceMSRP: parseFloat(values["vehiclePriceMSRP"]),
          downPaymentAmount: parseFloat(values["downPaymentAmount"]),
          manufacturerRebateAmount: parseFloat(values["manufacturerRebateAmount"]),
          tradeAllowanceGrossTradeIn: parseFloat(values["tradeAllowanceGrossTradeIn"]),
          tradeOwedBalanceAmount: parseFloat(values["tradeOwedBalanceAmount"]),
          creditLifePremiumAmount: parseFloat(values["creditLifePremiumAmount"]),
          disabilityPremiumAmount: parseFloat(values["disabilityPremiumAmount"]),
          msrpGuidePercentage: isNaN(parseFloat(values["residualAmount"]) / parseFloat(values["vehiclePriceMSRP"]))
            ? 0
            : parseFloat(values["residualAmount"]) / parseFloat(values["vehiclePriceMSRP"]),
          residualAmount: parseFloat(values["residualAmount"]),
          leaseRatePercentage: parseFloat(values["leaseRatePercentage"]),
          leaseRateMoneyFactor: parseFloat(values["leaseRateMoneyFactor"]),
          totalExtendedWarrantyAmount: parseFloat(values["totalExtendedWarrantyAmount"]),
          vehicleSellingPrice: parseFloat(values["vehicleSellingPrice"]),
          baseMonthlyPayment: values["baseMonthlyPayment"] ? parseFloat(values["baseMonthlyPayment"]) : null,
          extendedWarrantyAmount: parseFloat(values["extendedWarrantyAmount"]),
          gapInsuranceAmount: parseFloat(values["gapInsuranceAmount"]),
          roadsideCoverageAmount: parseFloat(values["roadsideCoverageAmount"]),
          roadHazardAmount: parseFloat(values["roadHazardAmount"]),
          docFeeAmount: parseFloat(values["docFeeAmount"]),
          physicalDamageCoverageAmount: parseFloat(values["physicalDamageCoverageAmount"]),
          federalExciseTaxAmount: parseFloat(values["federalExciseTaxAmount"]),
        },
        VOId: VOId,
      },
    }).then(() => {
      setTimeout(() => refetchVO(), 3000);
    });
  };

  return (
    <Dialog onClose={handleClose} open={open} scroll="body" maxWidth="sm" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "space-between",
          paddingBottom: 0,
        }}>
        <Typography component="span" variant="h6">
          Contract Details
        </Typography>

        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {data ? (
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "20rem",
            }}>
            <Check style={{ color: "#4caf50", fontSize: 64 }} />
            <h4>Contract Updated!</h4>
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <div>
            <form
              style={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
              onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["gapInsuranceAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["gapInsuranceAmount"])
                          }
                          label="Gap Insurance Amount"
                          id="gap-insurance-amount"
                          onChange={e => handleInputChange(e, "gapInsuranceAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["equipmentCost"]) === ""
                              ? "$0"
                              : formatCurrency(values["equipmentCost"])
                          }
                          label="Equipment Subtotal"
                          id="equipment-amount"
                          onChange={e => handleInputChange(e, "equipmentCost")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["roadsideCoverageAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["roadsideCoverageAmount"])
                          }
                          label="Roadside Coverage Amount"
                          id="roadside-coverage-amount"
                          onChange={e => handleInputChange(e, "roadsideCoverageAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["roadHazardAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["roadHazardAmount"])
                          }
                          label="Road Hazard Amount"
                          id="road-hazard-amount"
                          onChange={e => handleInputChange(e, "roadHazardAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["docFeeAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["docFeeAmount"])
                          }
                          label="Doc Fee Amount"
                          id="doc-fee-amount"
                          onChange={e => handleInputChange(e, "docFeeAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["physicalDamageCoverageAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["physicalDamageCoverageAmount"])
                          }
                          label="Physical Damage Coverage Amount"
                          id="physical-damage-coverage-amount"
                          onChange={e => handleInputChange(e, "physicalDamageCoverageAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["federalExciseTaxAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["federalExciseTaxAmount"])
                          }
                          label="Federal Excise Tax Amount"
                          id="federal-excise-tax-amount"
                          onChange={e => handleInputChange(e, "federalExciseTaxAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          value={
                            formatCurrency(values["extendedWarrantyAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["extendedWarrantyAmount"])
                          }
                          label="Extended Warranty Amount"
                          id="extended-warranty-amount"
                          onChange={e => handleInputChange(e, "extendedWarrantyAmount")}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: "flex",
                      gap: "8px",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => setShowLoanOptions(!showLoanOptions)}>
                    {showLoanOptions ? <RemoveOutlined /> : <AddOutlined />}

                    <Typography component="span" variant="subtitle2">
                      Loan Details
                    </Typography>
                  </Box>
                </Grid>
                {showLoanOptions && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash Price"
                            variant="standard"
                            value={
                              formatCurrency(values["amountRequested"]) === ""
                                ? "$0"
                                : formatCurrency(values["amountRequested"])
                            }
                            onChange={e => handleInputChange(e, "amountRequested")}
                            id="cashPrice"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Term"
                            variant="standard"
                            value={values["lengthOfContractInMonths"]}
                            onChange={e => handleInputChange(e, "lengthOfContractInMonths")}
                            id="term"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "lengthOfContractInMonths",
                                onChange: handleInputChange,
                                autoComplete: "lengthOfContractInMonths",
                                required: true,
                                autoFocus: true,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Taxes"
                            variant="standard"
                            value={
                              formatCurrency(values["salesTax"]) === "" ? "$0" : formatCurrency(values["salesTax"])
                            }
                            onChange={e => handleInputChange(e, "salesTax")}
                            id="taxes"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "salesTax",
                                onChange: handleInputChange,
                                autoComplete: "salesTax",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Customer Rate %"
                            variant="standard"
                            value={values["annualPercentageRate"]}
                            onChange={e => handleInputChange(e, "annualPercentageRate")}
                            id="customerRate"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "annualPercentageRate",
                                onChange: handleInputChange,
                                autoComplete: "annualPercentageRate",
                                required: true,
                                autoFocus: true,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Title/Lic/Other Fees"
                            variant="standard"
                            value={
                              formatCurrency(values["additionalFees"]) === ""
                                ? "$0"
                                : formatCurrency(values["additionalFees"])
                            }
                            onChange={e => handleInputChange(e, "additionalFees")}
                            id="additionalFees"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "additionalFees",
                                onChange: handleInputChange,
                                autoComplete: "additionalFees",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Monthly Payment"
                            variant="standard"
                            value={
                              formatCurrency(values["totalMonthlyPaymentEstimated"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalMonthlyPaymentEstimated"])
                            }
                            onChange={e => handleInputChange(e, "totalMonthlyPaymentEstimated")}
                            id="totalMonthlyPaymentEstimated"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "totalMonthlyPaymentEstimated",
                                onChange: handleInputChange,
                                autoComplete: "totalMonthlyPaymentEstimated",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash Down"
                            variant="standard"
                            value={
                              formatCurrency(values["downPaymentAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["downPaymentAmount"])
                            }
                            onChange={e => handleInputChange(e, "downPaymentAmount")}
                            id="cashDown"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "downPaymentAmount",
                                onChange: handleInputChange,
                                autoComplete: "downPaymentAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Wholesale/Invoice"
                            variant="standard"
                            value={
                              formatCurrency(values["vehiclePriceWholesaleInvoice"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceWholesaleInvoice"])
                            }
                            onChange={e => handleInputChange(e, "vehiclePriceWholesaleInvoice")}
                            id="invoice"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "vehiclePriceWholesaleInvoice",
                                onChange: handleInputChange,
                                autoComplete: "vehiclePriceWholesaleInvoice",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Rebate"
                            variant="standard"
                            value={
                              formatCurrency(values["manufacturerRebateAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["manufacturerRebateAmount"])
                            }
                            onChange={e => handleInputChange(e, "manufacturerRebateAmount")}
                            id="rebate"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "manufacturerRebateAmount",
                                onChange: handleInputChange,
                                autoComplete: "manufacturerRebateAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="MSRP/Retail"
                            variant="standard"
                            value={
                              formatCurrency(values["vehiclePriceMSRP"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceMSRP"])
                            }
                            onChange={e => handleInputChange(e, "vehiclePriceMSRP")}
                            id="msrp"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "vehiclePriceMSRP",
                                onChange: handleInputChange,
                                autoComplete: "vehiclePriceMSRP",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Trade Allowance"
                          variant="standard"
                          value={
                            formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                              ? "$0"
                              : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                          }
                          onChange={e => handleInputChange(e, "tradeAllowanceGrossTradeIn")}
                          id="tradeAllowance"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "tradeAllowanceGrossTradeIn",
                              onChange: handleInputChange,
                              autoComplete: "tradeAllowanceGrossTradeIn",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Trade Owed"
                          variant="standard"
                          value={
                            formatCurrency(values["tradeOwedBalanceAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["tradeOwedBalanceAmount"])
                          }
                          onChange={e => handleInputChange(e, "tradeOwedBalanceAmount")}
                          id="tradeOwed"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "tradeOwedBalanceAmount",
                              onChange: handleInputChange,
                              autoComplete: "tradeOwedBalanceAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Credit Life"
                            variant="standard"
                            value={
                              formatCurrency(values["creditLifePremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["creditLifePremiumAmount"])
                            }
                            onChange={e => handleInputChange(e, "creditLifePremiumAmount")}
                            id="creditLife"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "creditLifePremiumAmount",
                                onChange: handleInputChange,
                                autoComplete: "creditLifePremiumAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Disability"
                          variant="standard"
                          value={
                            formatCurrency(values["disabilityPremiumAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["disabilityPremiumAmount"])
                          }
                          onChange={e => handleInputChange(e, "disabilityPremiumAmount")}
                          id="disability"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "disabilityPremiumAmount",
                              onChange: handleInputChange,
                              autoComplete: "disabilityPremiumAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: "flex",
                      gap: "8px",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => setShowLeaseOptions(!showLeaseOptions)}>
                    {showLeaseOptions ? <RemoveOutlined /> : <AddOutlined />}

                    <Typography component="span" variant="subtitle2">
                      Lease Details
                    </Typography>
                  </Box>
                </Grid>
                {showLeaseOptions && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cap Cost"
                            variant="standard"
                            value={
                              formatCurrency(values["amountRequested"]) === ""
                                ? "$0"
                                : formatCurrency(values["amountRequested"])
                            }
                            onChange={e => handleInputChange(e, "amountRequested")}
                            id="capCost"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "amountRequested",
                                onChange: handleInputChange,
                                autoComplete: "amountRequested",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Term"
                            variant="standard"
                            value={values["lengthOfContractInMonths"]}
                            onChange={e => handleInputChange(e, "lengthOfContractInMonths")}
                            id="term"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "lengthOfContractInMonths",
                                onChange: handleInputChange,
                                autoComplete: "lengthOfContractInMonths",
                                required: true,
                                autoFocus: true,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Credit Life"
                            variant="standard"
                            value={
                              formatCurrency(values["creditLifePremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["creditLifePremiumAmount"])
                            }
                            onChange={e => handleInputChange(e, "creditLifePremiumAmount")}
                            id="creditLife"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "creditLifePremiumAmount",
                                onChange: handleInputChange,
                                autoComplete: "creditLifePremiumAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Base Monthly Payment"
                            variant="standard"
                            value={
                              formatCurrency(values["baseMonthlyPayment"]) === ""
                                ? "$0"
                                : formatCurrency(values["baseMonthlyPayment"])
                            }
                            onChange={e => handleInputChange(e, "baseMonthlyPayment")}
                            id="baseMonthlyPayment"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "baseMonthlyPayment",
                                onChange: handleInputChange,
                                autoComplete: "baseMonthlyPayment",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Disability"
                            variant="standard"
                            value={
                              formatCurrency(values["disabilityPremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["disabilityPremiumAmount"])
                            }
                            onChange={e => handleInputChange(e, "disabilityPremiumAmount")}
                            id="disability"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "disabilityPremiumAmount",
                                onChange: handleInputChange,
                                autoComplete: "disabilityPremiumAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Total Monthly Payment"
                            variant="standard"
                            value={
                              formatCurrency(values["totalMonthlyPaymentEstimated"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalMonthlyPaymentEstimated"])
                            }
                            onChange={e => handleInputChange(e, "totalMonthlyPaymentEstimated")}
                            id="totalPayment"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "totalMonthlyPaymentEstimated",
                                onChange: handleInputChange,
                                autoComplete: "totalMonthlyPaymentEstimated",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Ins/SVC/Other"
                            variant="standard"
                            value={
                              formatCurrency(values["totalExtendedWarrantyAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalExtendedWarrantyAmount"])
                            }
                            onChange={e => handleInputChange(e, "totalExtendedWarrantyAmount")}
                            id="insOther"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "totalExtendedWarrantyAmount",
                                onChange: handleInputChange,
                                autoComplete: "totalExtendedWarrantyAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Wholesale/Invoice"
                            variant="standard"
                            value={
                              formatCurrency(values["vehiclePriceWholesaleInvoice"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceWholesaleInvoice"])
                            }
                            onChange={e => handleInputChange(e, "vehiclePriceWholesaleInvoice")}
                            id="invoice"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "vehiclePriceWholesaleInvoice",
                                onChange: handleInputChange,
                                autoComplete: "vehiclePriceWholesaleInvoice",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash"
                            variant="standard"
                            value={
                              formatCurrency(values["downPaymentAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["downPaymentAmount"])
                            }
                            onChange={e => handleInputChange(e, "downPaymentAmount")}
                            id="cash"
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "downPaymentAmount",
                                onChange: handleInputChange,
                                autoComplete: "downPaymentAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="MSRP/Retail"
                            variant="standard"
                            value={
                              formatCurrency(values["vehiclePriceMSRP"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceMSRP"])
                            }
                            onChange={e => handleInputChange(e, "vehiclePriceMSRP")}
                            id="msrp"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "vehiclePriceMSRP",
                                onChange: handleInputChange,
                                autoComplete: "vehiclePriceMSRP",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Rebate"
                          variant="standard"
                          value={
                            formatCurrency(values["manufacturerRebateAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["manufacturerRebateAmount"])
                          }
                          onChange={e => handleInputChange(e, "manufacturerRebateAmount")}
                          id="rebate"
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "manufacturerRebateAmount",
                              onChange: handleInputChange,
                              autoComplete: "manufacturerRebateAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Residual %"
                          variant="standard"
                          value={values["msrpGuidePercentage"]}
                          onChange={e => handleInputChange(e, "msrpGuidePercentage")}
                          id="residual"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Trade Allowance"
                          variant="standard"
                          value={
                            formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                              ? "$0"
                              : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                          }
                          onChange={e => handleInputChange(e, "tradeAllowanceGrossTradeIn")}
                          id="tradeAllowance"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "tradeAllowanceGrossTradeIn",
                              onChange: handleInputChange,
                              autoComplete: "tradeAllowanceGrossTradeIn",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Residual Value"
                            variant="standard"
                            value={
                              formatCurrency(values["residualAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["residualAmount"])
                            }
                            onChange={e => handleInputChange(e, "residualAmount")}
                            id="residualValue"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            slotProps={{
                              htmlInput: {
                                name: "residualAmount",
                                onChange: handleInputChange,
                                autoComplete: "residualAmount",
                                required: true,
                                autoFocus: true,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              },

                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Trade Owed"
                          variant="standard"
                          value={
                            formatCurrency(values["tradeOwedBalanceAmount"]) === ""
                              ? "$0"
                              : formatCurrency(values["tradeOwedBalanceAmount"])
                          }
                          onChange={e => handleInputChange(e, "tradeOwedBalanceAmount")}
                          id="tradeOwed"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "tradeOwedBalanceAmount",
                              onChange: handleInputChange,
                              autoComplete: "tradeOwedBalanceAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Cust. Rate/Money Factor"
                          variant="standard"
                          value={values["leaseRateMoneyFactor"]}
                          onChange={e => handleInputChange(e, "leaseRateMoneyFactor")}
                          id="leaseRateMoneyFactor"
                          lowerCase={true}
                          formControlProps={{ fullWidth: true, required: true }}
                          slotProps={{
                            htmlInput: {
                              name: "leaseRateMoneyFactor",
                              onChange: handleInputChange,
                              autoComplete: "leaseRateMoneyFactor",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },

                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Net Trade"
                            variant="standard"
                            onChange={e => handleInputChange(e, "netTrade")}
                            id="netTrade"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            value={
                              formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                            }
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{
                      color: "#fff",
                      backgroundColor: theme.palette.primary.main,
                      fontSize: 14,
                      padding: "4px 12px",
                    }}>
                    Update
                  </Button>
                </div>
              </Grid>
            </form>

            {loading && (
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openLoadingSnackbar}
                autoHideDuration={5000}
                onClose={handleLoadingSnackbarClose}>
                <Alert onClose={handleLoadingSnackbarClose} severity="info">
                  Loading...
                </Alert>
              </Snackbar>
            )}
            {error && (
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openErrorSnackbar}
                autoHideDuration={5000}
                onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} severity="alert">
                  Create New Contract error!
                </Alert>
              </Snackbar>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}

const mapDispatchToProps = {
  setRefetch,
};

export default connect(null, mapDispatchToProps)(AddNewContractModal);
