import { FinanceProgramForm } from "../schema";
import { mapCriteriaFormValuesToCreateInput } from "./mapCriteriaFormValuesToCreateInput";
import { mapConfigurationFormValuesToCreateOrUpdate } from "./mapConfigurationFormValuesToCreateOrUpdate";
import { CreateFinanceProgramInput } from "@trnsact/trnsact-shared-types/dist/generated";

export function mapFormValuesToCreateFinanceProgramInput(
  formValue: FinanceProgramForm,
  accountId: string
): CreateFinanceProgramInput {
  const {
    program: { first, second, third, fourth, fifth },
  } = formValue;

  const programAvailableForLenders = [...first.lenders, ...first.dealers].map(({ value }) => value);

  return {
    accountId,
    nameInternal: first.name,
    financeProgramModificationSettings: {
      markup: true,
    },
    lenderProfileIds: programAvailableForLenders,
    prescreenCriteriaIdToLink: third?.criteria?.value,
    validDateEnd: second.isValidityDates ? second.endDate : null,
    validDateStart: second.isValidityDates ? second.startDate : null,
    prescreenCriteriaInput: mapCriteriaFormValuesToCreateInput(formValue, accountId),
    paymentOptionsConfiguration: mapConfigurationFormValuesToCreateOrUpdate(formValue),
    submissionSettings: { excludedDocuments: fourth.submissionSettings.excludedDocuments },
    insuranceCoverage: fifth.insuranceCoverage,
  };
}
