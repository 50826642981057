import React, { useState } from "react";
import { FormControlLabel, FormGroup, Grid, Snackbar, Switch } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import _ from "lodash";

const M_UPDATE_VP = gql`
  mutation ($entityId: String!, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  inputLabel: {
    marginBottom: "8px",
  },
  disabledRow: {
    backgroundColor: "#f2f2f2",
    cursor: "not-allowed",
  },
}));

export default function LenderDocumentAccess({ vendorProfile, userProfile, vendorContactRoles }) {
  const classes = useStyles();
  const [updateVpSettingMutation] = useMutation(M_UPDATE_VP);
  const [updateVpSettingSuccess, setUpdateVpSettingSuccess] = useState(false);
  const [showLenderDocsAccessMgmt, setShowLenderDocsAccessMgmt] = useState(vendorProfile.showLenderDocsAccessMgmt);

  if (userProfile) {
    if (!_.includes([vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr], userProfile.vendorContactRole)) {
      return (
        <div>
          {vendorProfile && (
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item lg={5} xl={5} xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showLenderDocsAccessMgmt}
                            onChange={() => {
                              const newState = !showLenderDocsAccessMgmt;
                              updateVpSettingMutation({
                                variables: {
                                  entityId: vendorProfile.id,
                                  fieldsObj: { showLenderDocsAccessMgmt: newState },
                                },
                              }).then(() => {
                                setUpdateVpSettingSuccess(true);
                              });
                              setShowLenderDocsAccessMgmt(newState);
                            }}
                            color="primary"
                          />
                        }
                        label="Enable Enhanced Document Access Control"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Snackbar
            open={updateVpSettingSuccess}
            autoHideDuration={3000}
            onClose={() => setUpdateVpSettingSuccess(state => !state)}>
            <Alert severity="success">Dealer Document Access Control Setting successfully updated</Alert>
          </Snackbar>
        </div>
      );
    }
  }

  return null;
}
