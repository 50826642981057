import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FinanceCommonFields } from "./FinanceCommonFields";
import { FinanceExistingProgramFields } from "./FinanceExistingProgramFields";
import { CreateProposalFinanceExistingOptions, FinanceProgramFormValues } from "../../../../../../types";

export const FinanceDetails = () => {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const [createProposalWithExistingProgram, program] = useWatch({
    control,
    name: ["financeQuote.createProposalWithExistingProgram", "financeQuote.program"],
  });

  const isShowExistingProgramFields = createProposalWithExistingProgram === CreateProposalFinanceExistingOptions.Yes;
  const isShowCommonProgramFields = Boolean(program) || !isShowExistingProgramFields;

  return (
    <>
      {isShowExistingProgramFields && <FinanceExistingProgramFields />}
      {isShowCommonProgramFields && <FinanceCommonFields />}
    </>
  );
};
