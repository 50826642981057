import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CreditApplicationRouterState } from "../types";
import { CreditApplicationDetailsHeader } from "./header/CreditApplicationDetailsPage";
import { CreditApplicationDetailsLayout } from "./layout/CreditApplicationDetailsLayout";

interface Props extends CreditApplicationRouterState {
  backToCommonView: VoidFunction;
}

export const CreditApplicationDetailsFullView = ({ backToCommonView, creditApplicationState }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CreditApplicationDetailsHeader
        pageType={creditApplicationState.pageType!}
        applicant={creditApplicationState.applicant!}
      />
      <CreditApplicationDetailsLayout backToCommonView={backToCommonView} {...creditApplicationState} />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
