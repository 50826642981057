import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "components/CustomButtons/Button";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CardIcon from "../Card/CardIcon";

import iconPlusSvg from "assets/img/icons/plus.svg";
import iconMinusSvg from "assets/img/icons/minus.svg";

const styles = {
  customCardContentClass: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardIconTitle: {
    ...cardTitle,
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 10,
    position: "relative",
  },
  perfectScrollBarContainerLimited: {
    maxHeight: "80vh",
    marginRight: -10,
    paddingRight: 10,
    overflowX: "hidden",
    "& .MuiTable-root": {
      margin: 0,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  collapseButton: {
    position: "absolute",
    top: 0,
    right: -15,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
  titleButtons: {},
};

const useStyles = makeStyles(styles);

export default function Tile(props) {
  let {
    title,
    titleButtonText,
    titleButtonFunc,
    titleButtonIcon,
    titleButtonComponent,
    titleButtonProps,
    titleButtons,
    children,
    maxHeightLimitByScreen,
    titleIcon,
    className,
    useCollapsing,
    initCollapsed = false,
    isAllCollapsed = false,
  } = props;
  const defaultTitleButtonProps = { color: "primary", size: "sm" };
  if (!titleButtonProps) titleButtonProps = defaultTitleButtonProps;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(initCollapsed);

  useEffect(() => {
    setCollapsed(isAllCollapsed);
  }, [isAllCollapsed]);

  return (
    <Card className={className}>
      {!!title || !!titleButtonText || !!titleIcon || !!titleButtons ? (
        <CardHeader icon>
          {titleIcon ? <CardIcon color="primary">{titleIcon}</CardIcon> : ""}
          <div className={classes.tileCardHeader} style={{ marginRight: useCollapsing ? 50 : 15 }}>
            <h3 className={classes.cardIconTitle}>{title}</h3>
            {!collapsed && (!!titleButtonText || !!titleButtonIcon) && (
              <Button {...titleButtonProps} onClick={titleButtonFunc}>
                <>
                  {titleButtonIcon ? titleButtonIcon : ""}
                  {titleButtonText ? titleButtonText : ""}
                </>
              </Button>
            )}
            {!collapsed && !!titleButtons && (
              <div className={classes.titleButtons}>
                {titleButtons.map((button, index) => {
                  const buttonProps = button.props ? button.props : defaultTitleButtonProps;
                  return (
                    <Button {...buttonProps} onClick={button.func} key={index}>
                      <>
                        {button.text ? button.text : ""}
                        {button.icon ? button.icon : ""}
                      </>
                    </Button>
                  );
                })}
              </div>
            )}
            {!collapsed && !!titleButtonComponent && titleButtonComponent}
          </div>
          {!!useCollapsing && (
            <div className={classes.collapseButton} onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <div className={classes.icon + " " + classes.iconPlus} />
              ) : (
                <div className={classes.icon + " " + classes.iconMinus} />
              )}
            </div>
          )}
        </CardHeader>
      ) : (
        ""
      )}
      {!collapsed && (
        <CardBody style={{ overflowX: "auto" }}>
          {maxHeightLimitByScreen ? (
            <PerfectScrollbar className={classes.perfectScrollBarContainerLimited}>{children}</PerfectScrollbar>
          ) : (
            <div>{children}</div>
          )}
        </CardBody>
      )}
    </Card>
  );
}
