import React, { FC } from "react";
import { MenuOptionCardBodyProps } from "modules/desking/types";
import { ProposalMenuOptionType } from "@trnsact/trnsact-shared-types";
import { MenuOptionCardCommon } from "./MenuOptionCardCommon";
import { MenuOptionCardDeclination } from "./MenuOptionCardDeclination";

export const MenuOptionCardBody = (props: MenuOptionCardBodyProps) => {
  const cardBody: Record<ProposalMenuOptionType, FC<MenuOptionCardBodyProps>> = {
    [ProposalMenuOptionType.Common]: MenuOptionCardCommon,
    [ProposalMenuOptionType.Declination]: MenuOptionCardDeclination,
  };

  const CardBody = cardBody[props.menuOption?.type ?? ProposalMenuOptionType.Common];

  return <CardBody {...props} />;
};
