import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, Button } from "@mui/material";

const styles = {
  actionButton: {
    minWidth: "100px",
  },
};

const useStyles = makeStyles(styles);

interface Props {
  title?: string;
  message?: string;
  messageLayout?: any;
  onClose: any;
  open: boolean;
  loading?: boolean;
  okButtonText?: string;
  cancelButtonText?: string;
  titleCentered?: boolean;
  buttonsCentered?: boolean;
}

export default function ConfirmationDialog({
  title,
  message,
  messageLayout,
  onClose,
  open,
  loading,
  okButtonText = "Ok",
  cancelButtonText = "Cancel",
  titleCentered = false,
  buttonsCentered = false,
}: Props) {
  const classes = useStyles();

  const handleCancel = () => {
    onClose({ result: "cancel" });
  };

  const handleOk = () => {
    onClose({ result: "ok" });
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="sm">
      {title && <DialogTitle style={titleCentered ? { textAlign: "center" } : {}}>{title}</DialogTitle>}

      <DialogContent>
        {message && <Typography>{message}</Typography>}
        {messageLayout && React.createElement(messageLayout, {})}
      </DialogContent>

      <DialogActions style={buttonsCentered ? { justifyContent: "center" } : {}}>
        {!loading && (
          <>
            <Button className={classes.actionButton} onClick={handleCancel}>
              {cancelButtonText}
            </Button>
            <Button className={classes.actionButton} onClick={handleOk}>
              {okButtonText}
            </Button>
          </>
        )}
        {loading && <LinearProgress style={{ width: "100%" }} />}
      </DialogActions>
    </Dialog>
  );
}
