import React, { ReactNode } from "react";
import { Box, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  FormInputSelect,
  FormRadioGroup,
  FormSwitch,
  InputField,
  InterestInputField,
  MultiSelectWithCustomOptions,
  NumberFormatField,
} from "components/form";
import moment from "moment";
import { FeeArrayFields } from "./FeeArrayFields";
import { calcTermsSelectOptions } from "../../../../../../lib";
import { StructureConfiguration } from "./StructureConfiguration";
import { deskingActions, deskingSelectors } from "../../../../../../model";
import { FinanceProgramFormValues, ProgramTypes, RateTypes } from "../../../../../../types";
import {
  paymentCalculationMethodOptions,
  programTypeOptions,
  rateTypeOptions,
  styleFormRadioGroupProps,
} from "../../../../../../constants";

export const FinanceCommonFields = () => {
  const dispatch = useDispatch();

  const menuTerms = useSelector(deskingSelectors.menuTerms);

  const { control, setValue, getValues } = useFormContext<FinanceProgramFormValues>();
  const { fields: specifyRatesFields } = useFieldArray({ control, name: "financeQuote.specifyRates" });
  const financeQuote = useWatch({ control, name: "financeQuote" });

  const { terms, specifyRate, commonRate, programType, rateType, startDate, daysToFirstPayment } = financeQuote;

  const handleChangeTerms = (selected: (string | number)[]) => {
    if (selected.length === terms.length) return;

    const termsToString = selected.toSorted((a, b) => Number(b) - Number(a)).map(String);

    setValue("financeQuote.terms", termsToString);

    const termsToAdd = termsToString.find(term => !menuTerms.includes(term));

    if (termsToAdd && getValues("financeQuote.menu")) {
      dispatch(deskingActions.addMenuTerm({ term: termsToAdd, menuId: getValues("financeQuote.menu") }));
    }

    if (specifyRate) {
      const nextSpecifyRates = termsToString.map(term => ({ term, rate: "" }));
      setValue("financeQuote.specifyRates", nextSpecifyRates);
    } else {
      setValue(
        "financeQuote.specifyRates",
        termsToString.map(term => ({ term, rate: commonRate }))
      );
    }

    const termForRemove = menuTerms.find(term => !termsToString.includes(term));

    if (menuTerms.length && termForRemove) {
      dispatch(deskingActions.removeMenuTerm({ term: termForRemove }));
    }
  };

  const handleStartDateChange = (value: string) => {
    try {
      const firstPaymentDateMoment = moment(value);
      setValue(
        "financeQuote.firstPaymentDate",
        firstPaymentDateMoment.add(daysToFirstPayment, "days").format("YYYY-MM-DD")
      );
    } catch (e: any) {
      console.error(`Error in handleStartDateChange: ${e.message}`);
    }
  };

  const handleFirstPaymentDateChange = (value: string) => {
    const firstPaymentDateMoment = moment(value);
    const startDateMoment = moment(startDate);

    if (firstPaymentDateMoment.isBefore(startDateMoment)) {
      setValue("financeQuote.startDate", firstPaymentDateMoment.format("YYYY-MM-DD"));
      setValue("financeQuote.daysToFirstPayment", "0");
    } else {
      setValue("financeQuote.daysToFirstPayment", firstPaymentDateMoment.diff(startDateMoment, "days").toString());
    }
  };

  const handleDaysToFirstPaymentChange = (value: string) => {
    const firstPaymentDateMoment = moment(startDate);
    firstPaymentDateMoment.add(value, "days");
    setValue("financeQuote.firstPaymentDate", firstPaymentDateMoment.format("YYYY-MM-DD"));
  };

  return (
    <>
      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.rateType"
          render={({ field }) => (
            <FormRadioGroup
              row
              label="Rate type"
              options={rateTypeOptions}
              {...styleFormRadioGroupProps}
              {...field}
              onChange={(event, value) => {
                if (value === RateTypes.RateFactor) setValue("financeQuote.specifyRate", true);
                setValue(
                  "financeQuote.specifyRates",
                  terms.toSorted().map(term => ({ term, rate: "" }))
                );
                field.onChange(event, value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="financeQuote.specifyRate"
          render={({ field }) => (
            <FormSwitch
              label="Specify rate per term"
              disabled={rateType === RateTypes.RateFactor}
              {...field}
              checked={field.value}
            />
          )}
        />
      </Box>

      <Box className="row">
        <MultiSelectWithCustomOptions
          isOptionsSorted
          control={control}
          name="financeQuote.terms"
          onChange={handleChangeTerms}
          options={calcTermsSelectOptions(terms)}
          selectProps={{ label: "Terms in Months" }}
          onCustomOptionAdded={() => {
            setValue("financeQuote.customTermTemporaryValue", null);
          }}
          selectFormControlProps={{ required: true, variant: "standard" }}
          inputProps={{
            type: "number",
            label: "Add custom term",
            placeholder: "Enter your own term",
            onInputChanged: value => {
              setValue("financeQuote.customTermTemporaryValue", value);
            },
          }}
        />

        {!specifyRate && (
          <InterestInputField
            control={control}
            label="Buy Rate (%)"
            name="financeQuote.commonRate"
            textFieldProps={{ required: true, fullWidth: true, size: "small" }}
          />
        )}
      </Box>

      {!!specifyRate && !!specifyRatesFields?.length && (
        <>
          {specifyRatesFields
            .toSorted((a, b) => +a.term - +b.term)
            .map((field, index) => {
              const rateFields: Record<RateTypes, ReactNode> = {
                [RateTypes.InterestRate]: (
                  <InterestInputField
                    key={field.id}
                    control={control}
                    name={`financeQuote.specifyRates.${index}.rate`}
                    textFieldProps={{ required: true, fullWidth: true }}
                    label={`Buy Rate ${field?.term ?? ""} months (%)`}
                  />
                ),
                [RateTypes.RateFactor]: (
                  <NumberFormatField
                    key={field.id}
                    range={[0, 1]}
                    decimalScale={6}
                    control={control}
                    fixedDecimalScale
                    thousandSeparator
                    name={`financeQuote.specifyRates.${index}.rate`}
                    textFieldProps={{ required: true, fullWidth: true }}
                    label={`Buy Rate ${field?.term ?? ""} months (Rate Factor)`}
                  />
                ),
              };

              return <>{rateFields[rateType]}</>;
            })}
        </>
      )}

      <StructureConfiguration />

      <Box className="row">
        <Controller
          control={control}
          name="financeQuote.programType"
          render={({ field }) => (
            <FormInputSelect variant="standard" label="Program Type" options={programTypeOptions} {...field} />
          )}
        />

        {programType === ProgramTypes.Lease && (
          <InterestInputField
            control={control}
            label="Residual (%)"
            name="financeQuote.residual"
            textFieldProps={{ fullWidth: true }}
          />
        )}
      </Box>

      <Box className="row">
        <InputField
          control={control}
          label="Advance Payment (# of)"
          name="financeQuote.advancePayment"
          inputProps={{ type: "number" }}
        />

        <InputField
          control={control}
          label="Contract Date"
          name="financeQuote.startDate"
          inputProps={{ type: "date", onKeyDown: e => e.preventDefault() }}
          extraAction={{
            debounceTime: 50,
            cb: nextValue => {
              handleStartDateChange(nextValue);
            },
          }}
        />

        <InputField
          control={control}
          label="First Payment Date"
          name="financeQuote.firstPaymentDate"
          inputProps={{ type: "date", onKeyDown: e => e.preventDefault() }}
          extraAction={{
            debounceTime: 50,
            cb: nextValue => {
              handleFirstPaymentDateChange(nextValue);
            },
          }}
        />

        <InputField
          control={control}
          label="Days to First Payment"
          name="financeQuote.daysToFirstPayment"
          inputProps={{ type: "number" }}
          extraAction={{
            debounceTime: 50,
            cb: nextValue => {
              if (+nextValue >= 0) {
                handleDaysToFirstPaymentChange(nextValue);
                // setValue("financeQuote.paymentCalculationMethod", PaymentCalculationMethods.Advance);
              } else {
                setValue("financeQuote.daysToFirstPayment", "0");
              }
            },
          }}
        />
      </Box>

      <Controller
        control={control}
        name="financeQuote.paymentCalculationMethod"
        render={({ field }) => (
          <Tooltip
            arrow
            title={
              "Advance - Payment is due at the beginning of the period\nArrears - Payment is due at the end of the period"
            }>
            <span>
              <FormInputSelect
                variant="standard"
                label="Payment Calculation Method"
                // disabled={isDaysToFirstPaymentGTZero}
                options={paymentCalculationMethodOptions}
                {...field}
              />
            </span>
          </Tooltip>
        )}
      />

      <FeeArrayFields />
    </>
  );
};
