import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";

export function AutocomleteFieldSetting({ settings, itemConfiguration, setItemConfiguration, extraData }) {
  const getValue = () => {
    const savedValue = _.get(itemConfiguration, settings.configurationKey);
    const { optionKeyToSave } = extraData;
    if (extraData.multiple) {
      return extraData.isInversed
        ? _.reject(extraData.options, o => _.includes(savedValue, o[optionKeyToSave]))
        : _.filter(extraData.options, o => _.includes(savedValue, o[optionKeyToSave]));
    }
    return extraData.isInversed
      ? _.find(extraData.options, o => o[optionKeyToSave] !== savedValue)
      : _.find(extraData.options, { [optionKeyToSave]: savedValue });
  };

  const updateValue = (e, value) => {
    setItemConfiguration(prevItemConfiguration => {
      const getValueToSave = () => {
        const { optionKeyToSave } = extraData;
        if (extraData.multiple) {
          return extraData.isInversed
            ? _.map(_.difference(extraData.options, value), optionKeyToSave)
            : _.map(value, optionKeyToSave);
        }
        return extraData.isInversed
          ? _.find(extraData.options, o => o[optionKeyToSave] !== value[optionKeyToSave])
          : _.get(value, optionKeyToSave, "");
      };

      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: getValueToSave(),
      };
    });
  };

  return (
    <Autocomplete
      multiple={extraData.multiple}
      limitTags={extraData.multiple ? 5 : -1}
      options={extraData.options}
      getOptionLabel={extraData.getOptionLabelFn}
      onChange={updateValue}
      value={getValue() || ""}
      renderInput={params => <TextField {...params} placeholder={extraData.placeholder} />}
    />
  );
}
