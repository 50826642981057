import { ProductDetailsMappedData } from "../types";
import { productsDetailsInitialValue } from "../constants";
import { ProposalProductsDetailsResponse } from "../api/types";

interface Options {
  aftermarketProducts: any;
  response: ProposalProductsDetailsResponse | undefined;
}

export function prepareProductsDetailsForRender({ response, aftermarketProducts }: Options): ProductDetailsMappedData {
  if (!response) return structuredClone(productsDetailsInitialValue);

  const {
    proposal: { createdSnapshot, lenderPaymentOptions },
  } = response;

  return (createdSnapshot?.paymentOptions ?? []).reduce((details: ProductDetailsMappedData, paymentOption: any) => {
    paymentOption?.finance?.menu?.menuOptions?.forEach((menuOption: any) => {
      const isAccepted = lenderPaymentOptions?.some(
        (option: any) => option?.paymentCriteriaSelected?.name === menuOption?.name
      );

      menuOption.products.forEach((product: any) => {
        const targetProduct = aftermarketProducts?.aftermarketProducts?.find(
          (aftermarketProduct: any) => aftermarketProduct.id === product?.aftermarketProduct?.aftermarketProductId
        );

        if (!isAccepted) return details;

        details.totals = {
          cost: (details.totals.cost +=
            product.cost +
            (product?.addons?.reduce((acc: number, addon: any) => (acc += Number(addon?.cost ?? 0)), 0) ?? 0)),
          markup: (details.totals.markup += Math.max(0, product.retailCost - product.cost)),
          retailCost: (details.totals.retailCost +=
            product.retailCost +
            (product?.addons?.reduce((acc: number, addon: any) => (acc += Number(addon?.retailCost ?? 0)), 0) ?? 0)),
        };

        details.tableData.push({
          cost: product.cost,
          name: product.title,
          addons: product.addons,
          vendor:
            targetProduct.partnerLinks?.[0]?.name?.split("-")?.[0] ??
            product.aftermarketProduct?.aftermarketVendorApiChannel,
          id: product.proposalProductId,
          markup: product.markup.markup,
          retailCost: product.retailCost,
          markupType: product.markup?.type,
          productCategory: product?.aftermarketProduct?.productCategory?.replace(/_/g, " "),
        });
      });
    });

    return details;
  }, structuredClone(productsDetailsInitialValue));
}
