import React from "react";
import { Column } from "react-table";
import { formatCurrency } from "utils";
import { CellArgs } from "../../../types";
import { SimpleTextCell } from "../cells";

type DetailsProposalCellArgs = CellArgs;

export function createAddonsTableColumns(): Column<any>[] {
  return [
    {
      Header: "Addon Name",
      accessor: "name",
      Cell: ({ original }: DetailsProposalCellArgs) => <SimpleTextCell value={original.title} />,
    },
    {
      Header: "Type",
      accessor: "category",
      Cell: ({ original }: DetailsProposalCellArgs) => <SimpleTextCell value={original.category} />,
    },
    {
      Header: "Cost",
      accessor: "cost",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell formatter={formatCurrency} value={original.cost} />
      ),
    },
    {
      Header: "Retail",
      accessor: "retailCost",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell formatter={formatCurrency} value={original.retailCost} />
      ),
    },
  ].map(header => ({
    ...header,
    sortable: false,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
