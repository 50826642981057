import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FinanceDetails } from "./FinanceDetails";
import { InitializeWorkflow } from "./InitializeWorkflow";
import { FinanceProgramFormValues } from "../../../../../../types";

export const Finance = () => {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const createProposalWithExistingProgram = useWatch({
    control,
    name: "financeQuote.createProposalWithExistingProgram",
  });

  const isShowDetailsFields = createProposalWithExistingProgram !== null;

  return (
    <>
      <InitializeWorkflow />
      {isShowDetailsFields && <FinanceDetails />}
    </>
  );
};
