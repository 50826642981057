import React from "react";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import DropdownTreeSelect, { DropdownTreeSelectProps, TreeData } from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

interface Props {
  tree: TreeData;
  disabled: boolean;
  onBlur: VoidFunction;
  onFocus: VoidFunction;
  treeSelectOptions: DropdownTreeSelectProps;
}

export default function LocationsTreeViewDropdown({
  tree,
  disabled = false,
  treeSelectOptions,
  onBlur = () => {},
  onFocus = () => {},
}: Props) {
  const classes = useStyles();

  if (_.isEmpty(tree)) return null;

  return (
    <div className={`locations-tree-view-dropdown ${classes.locationsTreeViewDropdown}`}>
      <DropdownTreeSelect
        data={tree}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        mode={treeSelectOptions.mode}
        texts={treeSelectOptions.texts}
        onChange={treeSelectOptions.onChange}
        showDropdown={treeSelectOptions.showDropdown}
        inlineSearchInput={treeSelectOptions.inlineSearchInput}
        showPartiallySelected={treeSelectOptions.showPartiallySelected}
      />
    </div>
  );
}

const useStyles = makeStyles({
  locationsTreeViewDropdown: {
    height: "100%",

    "& .tag-list": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .tag-list .tag-item:first-child span:not(.placeholder)": {
      color: "#000000de",
    },
    "& .tag-list .tag-item:first-child span": {
      fontSize: "16px",
    },
    "& .tag-list .tag-item span": {
      color: "#0000008a",
      fontWeight: "400",
      whiteSpace: "nowrap",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "12px",
    },
    "& .tag-remove": {
      display: "none",
    },
    "& .react-dropdown-tree-select .dropdown": {
      display: "block",
    },
    "& .react-dropdown-tree-select": {
      height: "100%",
    },
    "& .dropdown": {
      height: "100%",
    },
    "& .react-dropdown-tree-select .dropdown .dropdown-trigger": {
      border: "none",
      borderBottom: "1px solid #0000006b",
      paddingLeft: "0px",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .react-dropdown-tree-select .dropdown .dropdown-content": {
      width: "100%",
      zIndex: 5,
    },
    "& .node": {
      display: "flex",
      padding: "0.5rem",
      alignItems: "center",
    },
    "& .node.checked": {
      backgroundColor: "#a13e3e14",
    },
    "& .node>label": {
      width: "100%",
      padding: "5px 0",
      marginLeft: "5px",
      color: "black",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    "& .node:hover": {
      backgroundColor: "#0000000a",
    },
    "& .node .node-label:after": {
      marginLeft: "10px",
      border: "1px solid lightgray",
      padding: "1px 4px",
      borderRadius: "8px",
    },
    "& .node.master .node-label:after": {
      content: `"Master"`,
    },
    "& .node.region .node-label:after": {
      content: `"Region"`,
    },
    "& .node.branch .node-label:after": {
      content: `"Branch"`,
    },
    "& .node.division .node-label:after": {
      content: `"Division"`,
    },
    "& .node.department .node-label:after": {
      content: `"Department"`,
    },
    "& .node.state .node-label:after": {
      content: `"State"`,
    },
    "& .toggle": {
      display: "flex",
      margin: "0 5px",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "22px",
    },
    "& .radio-item": {
      display: "none",
    },
    "& .tag": {
      padding: "0",
      backgroundColor: "transparent",
      border: "none",
    },
    "& .tag-item:nth-child(2)": {
      position: "absolute",
      top: "-18px",
      left: "0",
      fontSize: "12px",
    },
    "& .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after, .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after":
      {
        color: "#0000008a",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
      },
    "& .react-dropdown-tree-select .dropdown .dropdown-content .search": {
      padding: "12px",
      width: "97%",
    },
    // The following styles are require to support this logic: To select a parent, must click twice. To select a child only need to select once.
    "& .toggle.collapsed": {
      position: "absolute",
      width: "100%",
    },
    '& .toggle.collapsed+label:not(.toggle.collapsed[style="visibility: hidden;"]+label)': {
      pointerEvents: "none",
      marginLeft: "27px",
    },
  },
});
