import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { CheckCircleOutline, CloseOutlined, ErrorOutline } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import formatString from "format-string-by-pattern";
import { useSelector } from "react-redux";
import { formatCurrency, getStatesArray, parseCurrency } from "../utils";

const config = require("../config.js");

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const CREATE_CREDIT_APP = gql`
  mutation CreateCreditApplication($input: CreateCreditApplicationInput!) {
    createCreditApplication(input: $input) {
      dynamicsId
      errorMessage
      id
      success
    }
  }
`;

const GET_VENDOR_OPPORTUNITY = gql`
  query getVendorOpportunityByDunamicsCreditApplicationId($dynamicsCreditApplicationId: ID!) {
    vendorOpportunityByDynamicsCreditApplicationId(dynamicsCreditApplicationId: $dynamicsCreditApplicationId) {
      vendorOpportunityId
    }
  }
`;

const styles = {
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "0.5rem",
  },
  stateWrapping: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 500,
    height: 500,
    "& h4": {
      marginTop: 16,
    },
  },
};

const useStyle = makeStyles(styles);

export default ({ open, onClose }) => {
  const classes = useStyle();
  const [values, setValues] = useState({});
  const [locations, setLocations] = useState(null);
  const [creditAppAlert, setCreditAppAlert] = useState({ success: false, open: false, loading: false, message: "" });
  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);
  const vp = useSelector(state => state.vp);

  const [createCreditApp] = useMutation(CREATE_CREDIT_APP, {
    context: { authRequired: true },
  });
  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });
  const [getVendorOpportunity, { data: vendorOpportunityResponse, error: vendorOpportunityError }] = useLazyQuery(
    GET_VENDOR_OPPORTUNITY,
    {
      context: { authRequired: true },
      skip: true,
    }
  );
  // const { data: vpTemplate } = useQuery(GET_OCA_TEMPLATE, {
  //   context: { authRequired: true },
  //   variables: { dynamicsId: vp.dynamicsId },
  // });

  useEffect(() => {
    console.log("LOCATIONS", locationsDataResponse);
    if (!locationsDataResponse || _.isEmpty(locationsDataResponse.locations)) {
      return;
    }
    setLocations([...locationsDataResponse.locations].sort((a, b) => a.locationName.localeCompare(b.locationName)));
  }, [locationsDataResponse]);

  useEffect(() => {
    if (vendorOpportunityResponse?.vendorOpportunityByDynamicsCreditApplicationId?.vendorOpportunityId) {
      setCreditAppAlert({ success: true, open: true, loading: false });
      setTimeout(() => {
        window.location.href = `/vo/${vendorOpportunityResponse.vendorOpportunityByDynamicsCreditApplicationId.vendorOpportunityId}`;
      }, 2500);
    } else if (
      vendorOpportunityResponse?.vendorOpportunityByDynamicsCreditApplicationId?.vendorOpportunityId === null
    ) {
      setCreditAppAlert({ success: true, open: true, loading: false });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else if (vendorOpportunityError) {
      console.error("ERROR :: ", vendorOpportunityError);
      setCreditAppAlert({ success: false, open: true, loading: false });
      setTimeout(() => {
        setCreditAppAlert({ success: false, open: false, loading: false });
      }, 3000);
    }
  }, [vendorOpportunityResponse, vendorOpportunityError]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "phone" || name === "bizPhone") {
      let phoneValue = value?.replace(/[^\d-() ]/, "");
      setValues({
        ...values,
        [name]: formatString("(999) 999-9999", phoneValue),
      });
    } else if (name === "amountRequested") {
      setValues({
        ...values,
        [name]: value?.replace(/[^\d\.]/g, ""),
      });
    } else if (name === "ssn") {
      let ssn = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: formatString("999-99-9999", ssn),
      });
    } else if (name === "taxId") {
      let taxId = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: formatString("99-9999999", taxId),
      });
    } else if (name === "primary_postalCode" || name === "business_postalCode") {
      let postalCode = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: postalCode.substring(0, 5),
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setCreditAppAlert({ success: false, open: false, loading: false });
      setValues({});
    }, 1500);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setCreditAppAlert({ success: false, open: false, loading: true, message: "Creating Opportunity..." });

    const contact = {
      firstName: values.firstName,
      lastName: values.lastName,
      ssn: values.ssn,
      email: values.email,
      phone: values.phone,
      address: values.primary_address,
      city: values.primary_city,
      state: values.primary_state,
      postalCode: values.primary_postalCode,
      dateOfBirth: values.dateOfBirth,
    };

    const pocInfo =
      values.bizOrIndividual === "Individual"
        ? {
            ...contact,
          }
        : {
            ...contact,
            businessName: values.businessName,
            address: values.business_address,
            city: values.business_city,
            state: values.business_state,
            postalCode: values.business_postalCode,
            taxId: parseInt(values.taxId?.replace("-", "")),
            dateEstablished: values.dateEstablished,
            bizPhone: values.bizPhone,
          };

    const input = {
      ...pocInfo,
      owners: [
        {
          ...contact,
          isOwnerPc: true,
          isOwnerPg: false,
          isOwnerPcOnly: false,
        },
      ],
      formId: uuidv4(),
      applicationType: values.bizOrIndividual === "Individual" ? "INDIVIDUAL" : "COMMERCIAL",
      vendorGUID: account.dynamicsAccountId,
      signature: values.firstName + " " + values.lastName,
      locationId: values.locationId,
      dynamicsContactId: userProfile.dynamicsContactId,
      entityType: values.entityType,
      amountRequested: parseFloat(parseCurrency(values.amountRequested)),
      ccg_transaction_equipmentdescription: values.equipmentDescription,
      ocaEnv: config.environmentName === "dev" ? 100 : 200,
      attemptToSubmit: true,
    };

    await createCreditApp({ variables: { input } })
      .then(async response => {
        setTimeout(() => {
          setCreditAppAlert({ success: false, open: true, loading: true, message: "Syncing Information..." });
        }, 10000);
        setTimeout(async () => {
          await getVendorOpportunity({
            variables: { dynamicsCreditApplicationId: response.data.createCreditApplication?.dynamicsId },
          });
        }, 20000);
      })
      .catch(error => {
        console.error("ERROR :: ", error);
        setCreditAppAlert({ success: false, open: true, loading: false });
        setTimeout(() => {
          setCreditAppAlert({ success: false, open: false, loading: false });
        }, 3000);
      });
  };

  const renderAddressComps = key => (
    <>
      <TextField
        fullWidth
        label={`Address`}
        variant="outlined"
        value={values[`${key}_address`]}
        required
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            name: `${key}_address`,
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
      <TextField
        fullWidth
        label={`City`}
        variant="outlined"
        value={values[`${key}_city`]}
        required
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            name: `${key}_city`,
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <FormControl required variant="outlined" fullWidth>
          <InputLabel>State</InputLabel>
          <Select
            label="State"
            sx={{ mb: 2 }}
            variant="outlined"
            inputProps={{
              name: `${key}_state`,
              onChange: handleInputChange,
              required: true,
            }}
            value={values[`${key}_state`]}>
            {getStatesArray().map(state => (
              <MenuItem key={key + state.name} value={state.abbr}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={`Postal Code`}
          value={values[`${key}_postalCode`]}
          variant="outlined"
          required
          sx={{ mb: 2 }}
          slotProps={{
            htmlInput: {
              name: `${key}_postalCode`,
              onChange: handleInputChange,
              required: true,
            },
          }}
        />
      </div>
    </>
  );

  const renderContact = label => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>{label}</h5>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <TextField
          fullWidth
          label={"First Name"}
          variant="outlined"
          value={values[`firstName`]}
          required
          slotProps={{
            htmlInput: {
              name: "firstName",
              onChange: handleInputChange,
              required: true,
            },
          }}
        />
        <TextField
          fullWidth
          label={"Last Name"}
          variant="outlined"
          value={values[`lastName`]}
          required
          slotProps={{
            htmlInput: {
              name: "lastName",
              onChange: handleInputChange,
              required: true,
            },
          }}
        />
      </div>
      <TextField
        fullWidth
        label={"Email"}
        variant="outlined"
        value={values[`email`]}
        required
        slotProps={{
          htmlInput: {
            name: "email",
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
      <TextField
        fullWidth
        label={"Phone"}
        value={values.phone}
        variant="outlined"
        required
        slotProps={{
          htmlInput: {
            name: "phone",
            onChange: handleInputChange,
            requied: true,
          },
        }}
      />
      {renderAddressComps("primary")}
      <TextField
        fullWidth
        sx={{ mb: 2 }}
        label={"Social Security Number"}
        value={values.ssn}
        variant="outlined"
        slotProps={{
          htmlInput: {
            name: "ssn",
            onChange: handleInputChange,
          },
        }}
      />
      <TextField
        fullWidth
        label={"Date of Birth"}
        type="date"
        value={values.dateOfBirth}
        variant="outlined"
        required
        slotProps={{
          htmlInput: {
            name: "dateOfBirth",
            onChange: handleInputChange,
            required: true,
          },
          inputLabel: { shrink: true },
        }}
      />
    </div>
  );

  const renderEquipmentComps = cashSale => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>Equipment Information</h5>
      {cashSale && (
        <TextField
          fullWidth
          label={`Invoice Total`}
          value={formatCurrency(values.amountRequested)}
          variant="outlined"
          required
          slotProps={{
            htmlInput: {
              name: "amountRequested",
              onChange: handleInputChange,
              required: true,
            },
          }}
        />
      )}
      <TextField
        fullWidth
        label={"Equipment Description"}
        variant="outlined"
        value={values[`equipmentDescription`]}
        required
        slotProps={{
          htmlInput: {
            name: "equipmentDescription",
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
    </div>
  );

  const renderBusinessComps = () => (
    <div>
      <h5 style={{ fontWeight: "bold" }}>Business Information</h5>
      <TextField
        fullWidth
        label={"Business Name"}
        variant="outlined"
        value={values[`businessName`]}
        sx={{ mb: 2 }}
        required
        slotProps={{
          htmlInput: {
            name: "businessName",
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
      <TextField
        fullWidth
        label={"Phone"}
        value={values.bizPhone}
        variant="outlined"
        sx={{ mb: 2 }}
        required
        slotProps={{
          htmlInput: {
            name: "bizPhone",
            onChange: handleInputChange,
            required: true,
          },
        }}
      />
      {renderAddressComps("business")}
      <TextField
        fullWidth
        label={"Tax Id"}
        value={values.taxId}
        variant="outlined"
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            name: "taxId",
            onChange: handleInputChange,
          },
        }}
      />
      <TextField
        fullWidth
        label={"Date Established"}
        type="date"
        value={values.dateEstablished}
        variant="outlined"
        required
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            name: "dateEstablished",
            onChange: handleInputChange,
            required: true,
          },
          inputLabel: { shrink: true },
        }}
      />
    </div>
  );

  const renderOpportunityDetailsComps = account => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>Opportunity Details</h5>
      <FormControl variant="standard" fullWidth>
        <InputLabel>Location</InputLabel>
        <Select
          label="Location"
          inputProps={{
            name: "locationId",
            onChange: handleInputChange,
          }}
          value={values[`locationId`]}>
          {locations?.map((location, i) => (
            <MenuItem key={`${i}-location`} value={location.locationId}>
              {location.locationName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  const renderModalByState = () => {
    if (creditAppAlert.loading)
      return (
        <DialogContent className={classes.stateWrapping}>
          <CircularProgress />
          <h4>{creditAppAlert.message ? creditAppAlert.message : "Creating Opportunity..."}</h4>
          <p>This can take about 30 seconds.</p>
        </DialogContent>
      );
    if (creditAppAlert.open && creditAppAlert.success)
      return (
        <DialogContent className={classes.stateWrapping}>
          <CheckCircleOutline style={{ fontSize: 64, color: "#4caf50" }} />
          <h4>Opportunity Created Successfully!</h4>
        </DialogContent>
      );
    if (creditAppAlert.open && !creditAppAlert.success)
      return (
        <DialogContent className={classes.stateWrapping}>
          <ErrorOutline style={{ fontSize: 64, color: "#f44336" }} />
          <h4>Something went wrong.</h4>
        </DialogContent>
      );

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <DialogTitle>Create New Opportunity</DialogTitle>
          <IconButton onClick={() => handleClose()} size="large">
            <CloseOutlined />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <p style={{ marginBottom: "2rem" }}>
              Please provide the following information to create a new opportunity.
            </p>
            <FormControl required variant="standard" fullWidth>
              <InputLabel>Opportunity Type</InputLabel>
              <Select
                label="Opportunity Type"
                inputProps={{
                  name: "entityType",
                  onChange: handleInputChange,
                  required: true,
                }}>
                <MenuItem key={"cashSale"} value={"CASH_SALE"}>
                  Cash Sale
                </MenuItem>
              </Select>
            </FormControl>
            {values.entityType && (
              <>
                {values.entityType !== "CASH_SALE" && (
                  <TextField
                    fullWidth
                    label={"Requested Amount"}
                    variant="outlined"
                    value={values[`entityType`]}
                    required
                    slotProps={{
                      htmlInput: {
                        name: "requestedAmount",
                        onChange: handleInputChange,
                        required: true,
                      },
                    }}
                  />
                )}
                <div className={classes.formSection}>
                  <FormControl required variant="standard" fullWidth>
                    <InputLabel>Application Type</InputLabel>
                    <Select
                      label="Application Type"
                      inputProps={{
                        name: "bizOrIndividual",
                        onChange: handleInputChange,
                        required: true,
                      }}
                      value={values[`bizOrIndividual`]}>
                      <MenuItem key={"individual"} value={"Individual"}>
                        Individual
                      </MenuItem>
                      <MenuItem key={"commercial"} value={"Commercial"}>
                        Commercial
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {values.bizOrIndividual && (
                  <>
                    {values.bizOrIndividual === "Individual" && <>{renderContact("Applicant")}</>}
                    {values.bizOrIndividual === "Commercial" && (
                      <>
                        {renderBusinessComps()}
                        {renderContact("Contact")}
                      </>
                    )}
                    {renderEquipmentComps(values.entityType === "CASH_SALE")}
                    {renderOpportunityDetailsComps(account)}
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      {renderModalByState()}
    </Dialog>
  );
};
