import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DetailsCellDefaultProps } from "../../../../types";

interface Props extends DetailsCellDefaultProps {
  onOpenFinanceProgramDialog: VoidFunction;
}

export const FinanceProgramCell = ({ details, onOpenFinanceProgramDialog }: Props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.programName} variant="body2" color="primary" onClick={onOpenFinanceProgramDialog}>
      {details.paymentCriteria.financeProgramName}
    </Typography>
  );
};

const useStyles = makeStyles({
  programName: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.7,
    },
  },
});
