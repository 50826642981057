import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  isOpen: boolean;
  taskCreated: any;
  authLinkOwner: any;
  handleAuthConfirm: any;
  onClose: VoidFunction;
  creatingAuthLinkTask: any;
  isCreatingAuthLinkTaskExist: boolean;
}

export const AuthLinkDialog = ({
  isOpen,
  onClose,
  taskCreated,
  authLinkOwner,
  handleAuthConfirm,
  creatingAuthLinkTask,
  isCreatingAuthLinkTaskExist,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        id="classic-modal-slide-title"
        style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <h4>
          <b>Send Authorization Link</b>
        </h4>

        <Button key="close" aria-label="Close" onClick={onClose}>
          <Close />
        </Button>
      </DialogTitle>
      {!isCreatingAuthLinkTaskExist ? (
        <>
          {taskCreated ? (
            <DialogContent className={classes.dialogContent}>
              <Check style={{ color: "#2e7d32", fontSize: 82 }} />
              <h5>Authorization Link Sent.</h5>
            </DialogContent>
          ) : (
            <>
              {!authLinkOwner.firstName || !authLinkOwner.lastName || !authLinkOwner.email ? (
                <DialogContent style={{ display: "flex", flexDirection: "column" }}>
                  <h5>Not all information has been provided to send authorization link.</h5>
                  <ul>
                    {!authLinkOwner.firstName && <li>No first name.</li>}
                    {!authLinkOwner.lastName && <li>No last name.</li>}
                    {!authLinkOwner.email && <li>No email.</li>}
                  </ul>
                </DialogContent>
              ) : (
                <DialogContent style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <p>
                    Are you sure you would like to send an authorization link to {authLinkOwner.firstName}{" "}
                    {authLinkOwner.lastName}?
                  </p>
                </DialogContent>
              )}
            </>
          )}
        </>
      ) : (
        <DialogContent className={classes.dialogContent}>
          <CircularProgress style={{ width: 82, height: 82 }} />
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        {!taskCreated && !creatingAuthLinkTask && (
          <>
            <Button onClick={onClose}>No</Button>
            <Button color="primary" onClick={() => handleAuthConfirm(authLinkOwner)}>
              Yes
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AuthLinkDialog;

const useStyles = makeStyles({
  dialogContent: {
    padding: 24,
    display: "flex",
    minHeight: "300px",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogActions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
});
