import React from "react";
import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const PageSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className="header">
        <Skeleton className="skeleton" variant="rectangular" height="6rem" />

        <Skeleton className="skeleton" variant="rectangular" height="3.5rem" />
      </Box>
      <Box className="cardsRow">
        <Skeleton className="skeleton" variant="rectangular" width="50%" height="33rem" />

        <Skeleton className="skeleton" variant="rectangular" width="50%" height="33rem" />
      </Box>
      <Skeleton className="skeleton" variant="rectangular" height="9rem" />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "0.3rem",
    display: "flex",
    flexDirection: "column",

    "& .header": {
      gap: "0.3rem",
      display: "flex",
      flexDirection: "column",
    },

    "& .cardsRow": {
      gap: "0.3rem",
      display: "flex",
    },

    "& .skeleton": {
      borderRadius: "0.5rem",
    },
  },
});
