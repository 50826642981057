import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { OpportunitiesNotifications } from "./opportunitiesNotifications/OpportunitiesNotifications";

export const NotificationsManagement = () => {
  const classes = useStyles();

  return (
    <CardContainer
      title={
        <Typography component="span" variant="h5" className={classes.title}>
          Notification Management
        </Typography>
      }
      subTitle="Configure Email Alerts by Event Type"
      containerClassName={classes.containerClassName}>
      <OpportunitiesNotifications />
    </CardContainer>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  containerClassName: {
    gap: 6,
  },
  title: {
    fontWeight: 500,
  },
}));
