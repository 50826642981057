import React from "react";
import { formatCurrency } from "utils";
import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FinanceProgramFormValues } from "../../../types";

interface Props {
  priceOfProductsInMenu: number;
}

export const CashOptionSummary = ({ priceOfProductsInMenu }: Props) => {
  const { getValues } = useFormContext<FinanceProgramFormValues>();

  const amount = priceOfProductsInMenu + (getValues("saleDetails.equipmentSubtotal") + getValues("saleDetails.fees"));

  return (
    <Typography component="span" variant="subtitle2">
      Total Price: {formatCurrency(amount)}
    </Typography>
  );
};
