import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import formatString from "format-string-by-pattern";
import Button from "../../../../components/CustomButtons/Button";
import Alert from "@mui/material/Alert";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "25px",
  },
  requiredHint: {
    fontSize: "10px",
    fontWeight: "300",
    textAlign: "right",
    width: "100%",
    margin: 0,
  },
  modalHeader: {
    fontWeight: 300,
    fontSize: 25,
    lineHeight: "29px",
    color: "#000000",
  },
  requiredItems: {
    fontWeight: 300,
    fontSize: 12,
    color: "#818181",
    marginRight: 30,
  },
  modalInputContainer: {
    marginBottom: 16,
    "& > div > label": {
      fontWeight: 400,
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiFormLabel-filled": {
      color: "#3C4858",
      textTransform: "uppercase",
    },
    "& .Mui-focused": {
      color: "#3C4858",
      textTransform: "uppercase",
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#818181 !important",
    },
  },
  outlineInput: {
    marginTop: 20,
    "& .MuiInputBase-input": {
      fontWeight: 400,
      lineHeight: "14px",
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#818181 !important",
    },
  },
  subMethod: {
    fontWeight: 400,
    fontSize: 12,
    color: "#3C4858",
    textTransform: "uppercase",
    marginTop: 4,
  },
  submitButton: {
    backgroundColor: "#3E83E6",
    fontSize: "12px",
    fontWeight: 300,
    color: "#FFF",
    marginTop: "30px",
    padding: "10px 25px",
  },
  textError: {
    color: "#98260d",
    textTransform: "uppercase",
  },
  selectInput: {
    color: "#818181",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "-0.01px",
  },
  modalItem: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 20,
    },
  },
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
});
const useStyles = makeStyles(styles);

const initialFormValues = {
  dealerName: "",
  dealerContactEmail: "",
  dealerContactPhone: null,
  dealerContactFirstName: "",
  dealerContactLastName: "",
  dealerContactTitle: null,
  monthlyApplications: null,
  forcastedApplications: null,
  interestRates: "",
  description: "",
};

const initialErrorStates = {
  dealerName: false,
  dealerContactEmail: false,
  dealerContactFirstName: false,
  dealerContactLastName: false,
  monthlyApplications: false,
  forcastedApplications: false,
  interestRates: false,
  description: false,
};

const CREATE_NEW_LENDER_PROFILE = gql`
  mutation ($input: LenderProfileWithAccountInput!) {
    createLenderProfileWithAccount(input: $input) {
      channel
      contactEmail
      contactName
      dealerId
      dynamicsContactSubmission
      id
      insuranceRequirements
      lenderAccountId
      lenderAccountIdPg
      lenderProfileId
      name
      logo
      vendorProfileId
    }
  }
`;

const AddDealerModal = props => {
  const { handleClose, open, refetchVPs } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialErrorStates);
  const [submitted, setSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const vp = useSelector(state => state.vp);
  const [createLenderProfileWithAccount, { loading: mutationLoading }] = useMutation(CREATE_NEW_LENDER_PROFILE, {
    context: { authRequired: true },
  });

  let checker = arr => arr.every(v => v === false);

  const runValidations = () => {
    const emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errorCollect = {
      ...errors,
      dealerName: !values.dealerName,
      dealerContactFirstName: !values.dealerContactFirstName,
      dealerContactLastName: !values.dealerContactLastName,
      dealerContactEmail: !emailRegex.test(String(values.dealerContactEmail).toLowerCase()),
      monthlyApplications: !values.monthlyApplications,
      forcastedApplications: !values.forcastedApplications,
      description: !values.description,
    };
    setErrors(errorCollect);

    const result = checker(Object.values(errorCollect));
    return result;
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const validationsPassed = runValidations();

    setSubmitted(true);
    setSubmitSuccess(false);

    if (validationsPassed) {
      try {
        await createLenderProfileWithAccount({
          variables: {
            input: {
              name: values.dealerName,
              dealerContactEmail: values.dealerContactEmail,
              dealerContactFirstName: values.dealerContactFirstName,
              dealerContactLastName: values.dealerContactLastName,
              dealerContactTitle: values.dealerContactTitle,
              dealerContactPhone: values.dealerContactPhone,
              lenderContactEmail: userProfile.email,
              lenderContactFirstName: userProfile.firstName,
              lenderContactLastName: userProfile.lastName,
              channel: 100,
              accountId: account.dynamicsAccountId, // lender's dynamics accountId
              portalUrl: null,
              portalUsername: null,
              portalPassword: null,
              averageApprovalRate: null,
              monthlyApplications: parseInt(values.monthlyApplications),
              description: values.description,
              vendorProfileId: vp.dynamicsId, // lender's dynamics VP
              requestDocumentsMethod: "",
              referredBy: 100,
              interestRates: "",
              lenderTypeCode: 300,
              accountType: "DEALER",
              lpName: account.name,
              sendInvite: true,
            },
          },
        });

        refetchVPs();
        setLoading(false);
        setSubmitSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = prop => event => {
    if (prop === "dealerContactPhone") {
      let phoneValue = event.target.value.replace(/[^\d-() ]/, "");
      setValues({
        ...values,
        [prop]: formatString("(999) 999-9999", phoneValue),
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }

    if (submitted) {
      runValidations();
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingBottom: 0 }}>
          <h4>
            <b>Add Dealer</b>
            {loading && <CircularProgress style={{ marginLeft: 16, height: 24, width: 24 }} />}
          </h4>
          {handleClose ? (
            <IconButton size="medium" aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={errors.dealerName}
                  label="Name"
                  name="dealerName"
                  value={values.dealerName}
                  onChange={handleChange("dealerName")}
                  required={true}
                  type="text"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                  error={errors.monthlyApplications}
                  label="Current Applications Monthly"
                  value={values.monthlyApplications}
                  onChange={handleChange("monthlyApplications")}
                  type="number"
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  required={true}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextField
                  error={errors.forcastedApplications}
                  label="Forcasted Applications Monthly"
                  value={values.forcastedApplications}
                  onChange={handleChange("forcastedApplications")}
                  type="number"
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  required={true}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                error={errors.description}
                label="Description"
                value={values.description}
                onChange={handleChange("description")}
                // multiline
                placeholder="What's needed for you to increase your market share with this dealer? *"
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                type="text"
                // helperText="Required"
                required={true}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            <h4 style={{ margin: "24px 0 8px" }}>
              <b>Primary Contact</b>
            </h4>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  error={errors.dealerContactFirstName}
                  label="First Name"
                  name="dealerContactFirstName"
                  value={values.dealerContactFirstName}
                  onChange={handleChange("dealerContactFirstName")}
                  type="text"
                  required={true}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  error={errors.dealerContactFirstName}
                  label="Last Name"
                  name="dealerContactLastName"
                  value={values.dealerContactLastName}
                  onChange={handleChange("dealerContactLastName")}
                  type="text"
                  required={true}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                label={"Title"}
                value={values.dealerContactTitle}
                onChange={handleChange("dealerContactTitle")}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                error={errors.dealerContactEmail}
                label={
                  errors.dealerContactEmail ? (
                    <span className={classes.textError}>Valid email is required</span>
                  ) : (
                    "Email"
                  )
                }
                value={values.dealerContactEmail}
                onChange={handleChange("dealerContactEmail")}
                type="text"
                required={true}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                label="Phone"
                value={values.dealerContactPhone}
                onChange={handleChange("dealerContactPhone")}
                type="text"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid item>
                <Button className={classes.submitButton} onClick={handleSubmit} type="submit" disabled={false}>
                  ADD Dealer
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={submitSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}>
        <Alert severity="success" variant="filled">
          Dealer Added Successfully.
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddDealerModal;
