import React from "react";
import { Box, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProductConfig } from "modules/desking/types";

interface Props {
  proposalProduct: ProposalProduct;
  productConfiguration?: ProductConfig;
}

export const ProductCatalogDetails = ({ proposalProduct, productConfiguration }: Props) => {
  const coverageDetails =
    productConfiguration?.aftermarketProduct?.aftermarketProductCatalogDetails ??
    proposalProduct?.aftermarketProduct?.aftermarketProductCatalogDetails;
  return (
    <Box className="infoItem">
      <Typography style={{ fontWeight: "bold", marginBottom: 15 }} variant="body1">
        {proposalProduct.title}
      </Typography>

      {coverageDetails.productSummary && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Product Summary
          </Typography>
          <ReactQuill value={coverageDetails.productSummary} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}

      {coverageDetails.paragraphDescription && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Paragraph Description
          </Typography>
          <ReactQuill value={coverageDetails.paragraphDescription} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}

      {coverageDetails.coverageDetails && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Coverage Details
          </Typography>
          <ReactQuill value={coverageDetails.coverageDetails} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}

      {coverageDetails.limitationsOfLiability && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Limitations of Liability
          </Typography>
          <ReactQuill value={coverageDetails.limitationsOfLiability} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}

      {coverageDetails.eligibilityAndInclusions && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Eligibility and Inclusions
          </Typography>
          <ReactQuill value={coverageDetails.eligibilityAndInclusions} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}

      {coverageDetails.legalDisclaimer && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Legal Disclaimer
          </Typography>
          <ReactQuill value={coverageDetails.legalDisclaimer} readOnly={true} theme="bubble" />
          <hr />
        </div>
      )}
    </Box>
  );
};
