import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Snackbar,
  Select,
  Tab,
  TextField,
  Tooltip,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import Alert from "@mui/material/Alert";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Close from "@mui/icons-material/Close";
import FinanceProgramForm from "../../../components/FinanceProgramForm";
import CriteriaForm from "./CriteriaForm";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import { CREATE_FINANCE_PROGRAM, UPDATE_FINANCE_PROGRAM } from "../queries";

interface Account {
  id: string;
}

interface AppState {
  account: Account;
}

const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },

  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
}));

const Q_Prescreen_Criteria = gql`
  query PreScreenCriteria($accountId: ID!) {
    prescreenCriteria(accountId: $accountId) {
      prescreenCriteriaId
      name
      accountId
      userProfileIdCreatedBy
      jsonCriteria
      active
      notes
      guidelines
    }
  }
`;

// Define the types for each part of the state

interface PaymentOptionsConfiguration {
  financeProgramName: string;
  flatFees: number[];
  markups: number[];
  dealerMarkup: number | null;
  customerRate: number | null;
  numberOfAdvancePayments: number;
  paymentPeriodTiming: string;
  rate: number;
  terms: string[];
  type: "LOAN" | "LEASE";
  rateType: "INTEREST_RATE" | "RATE_FACTOR";
  rates: any[];
  residualPercentage: number;
}

interface ProgramModificationSettings {
  markup: boolean;
}

interface Program {
  accountId: string;
  lenderProfileIds: string[];
  nameInternal: string;
  validDateStart: string;
  validDateEnd: string;
  prescreenCriteriaIdToLink?: string;
  paymentOptionsConfiguration: PaymentOptionsConfiguration;
  financeProgramId: undefined | string;
  financeProgramModificationSettings: ProgramModificationSettings;
}

const ProgramsPreScreen = ({
  open,
  onClose,
  lenderProfiles,
  create,
  refetchPrograms,
  financeProgram,
  isLenderUser,
  dealerVendorProfiles,
  targetLenderProfileId,
  targetLenderProfileName,
}: {
  open: boolean;
  onClose: any;
  lenderProfiles: any;
  create: any;
  refetchPrograms: any;
  financeProgram: any;
  isLenderUser: any;
  dealerVendorProfiles: any;
  targetLenderProfileId: any;
  targetLenderProfileName?: any;
}) => {
  const classes = styles();
  const account = useTypedSelector(state => state.account);

  const {
    data: psCriteriaData,
    loading: psCriteriaLoading,
    refetch: refetchPsCriteria,
  } = useQuery(Q_Prescreen_Criteria, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    variables: { accountId: account.id },
  });

  const [tabSelected, setTabSelected] = useState("1");

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createMode, setCreateMode] = useState(create);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [initFinanceProgram, setInitFinanceProgram] = useState<Program | null>(null);
  const [initCriteriaForm, setCriteriaForm] = useState<any | null>(null);
  const [prescreenCriteriaId, setPrescreenCriteriaId] = useState<string>(
    _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", "")
  );
  const [removingFP, setRemovingFP] = useState(false);

  const [selectedLenderProfilesForLender, setSelectedLenderProfilesForLender] = useState<string[]>([]);
  const [selectedLenderProfilesForDealer, setSelectedLenderProfilesForDealer] = useState<string[]>([]);
  const [criteriaFormSelectedCriteriaIndex, setCriteriaFormSelectedCriteriaIndex] = useState<number | null>(null);
  const [hasCriteriaFormUnsavedChanges, setHasCriteriaFormUnsavedChanges] = useState<boolean>(false);
  const [hasProgramUnsavedChanges, setHasFinanceProgramUnsavedChanges] = useState<boolean>(false);

  // Workaround: set value in timeout to avoid value overwriting in side useEffect
  const setHasFinanceProgramUnsavedChangesTimedOut = (value: boolean) => {
    setTimeout(() => {
      setHasFinanceProgramUnsavedChanges(value);
    }, 500);
  };

  const [program, setProgram] = useState<Program>({
    accountId: account.id,
    lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
    nameInternal: _.get(financeProgram, "nameInternal", ""),
    validDateStart: "",
    validDateEnd: "",
    prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
    financeProgramId: undefined,
    paymentOptionsConfiguration: {
      financeProgramName: _.get(financeProgram, "paymentOptionsConfiguration.financeProgramName", ""),
      flatFees: [],
      markups: [],
      dealerMarkup: null,
      customerRate: null,
      numberOfAdvancePayments: 0,
      paymentPeriodTiming: "",
      rate: 0,
      terms: [],
      type: "LOAN",
      rateType: "INTEREST_RATE",
      rates: [],
      residualPercentage: 0,
    },
    financeProgramModificationSettings: {
      markup: _.get(financeProgram, "financeProgramModificationSettings.markup", false),
    },
  });

  useEffect(() => {
    if (open) {
      setHasFinanceProgramUnsavedChangesTimedOut(false);
    }
  }, [open]);

  useEffect(() => {
    setHasFinanceProgramUnsavedChanges(true);
  }, [program]);

  useEffect(() => {
    setCreateMode(create);
    if (create) {
      //reset prescreen criteria id
      console.log(`setProgram paymentConfiguration ${JSON.stringify(program.paymentOptionsConfiguration)}`);
      setPrescreenCriteriaId("");
      setInitFinanceProgram(null);
      setProgram({
        accountId: account.id,
        lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
        nameInternal: _.get(financeProgram, "nameInternal", ""),
        validDateStart: "",
        validDateEnd: "",
        prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
        financeProgramId: undefined,
        paymentOptionsConfiguration: {
          financeProgramName: _.get(financeProgram, "paymentOptionsConfiguration.financeProgramName", ""),
          flatFees: [],
          markups: [],
          dealerMarkup: null,
          customerRate: null,
          numberOfAdvancePayments: 0,
          paymentPeriodTiming: "",
          rate: 0,
          terms: [],
          type: "LOAN",
          rateType: "INTEREST_RATE",
          rates: [],
          residualPercentage: 0,
        },
        financeProgramModificationSettings: {
          markup: _.get(financeProgram, "financeProgramModificationSettings.markup", false),
        },
      });
    }
  }, [create]);

  const handleTabChange = (event: any, newValue: string) => {
    setTabSelected(newValue);
    console.log(`handleTabChange: ${newValue}`);
    setInitFinanceProgram(program);
  };

  const [createLenderProgram] = useMutation(CREATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
    onCompleted: () => {
      refetchPrograms();
    },
  });
  const [updateLenderProgram] = useMutation(UPDATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const handleCreateOrUpdateFinanceProgram = async () => {
    setLoading(true);

    if (createMode) {
      //Clean residual state data
      const parsedProgram = {
        ...program,
        prescreenCriteriaIdToLink: _.get(program, "prescreenCriteriaIdToLink") || prescreenCriteriaId,
        prescreenCriteria: undefined,
        financeProgramId: undefined,
        financeProgramToLenderProfiles: undefined,
        modifiedDateTime: undefined,
        createdDateTime: undefined,
        __typename: undefined,
      };

      const result = await createLenderProgram({ variables: { input: { ...parsedProgram } } });

      const programSaved = _.get(result, "data.createFinanceProgram", null);
      if (programSaved) {
        setProgram(programSaved);
        setCreateMode(false);
      }
    } else {
      try {
        // @ts-expect-error __typename exists
        delete program.financeProgramModificationSettings.__typename;
      } catch (e) {}

      await updateLenderProgram({
        variables: {
          input: {
            ...program,
            accountId: undefined,
            lenderProfileIds: undefined,
            lenderProfileIdsToDisassociate: _.isEqual(
              _.get(initFinanceProgram, "lenderProfileIds", []),
              program.lenderProfileIds
            )
              ? undefined
              : _.get(initFinanceProgram, "lenderProfileIds", []).filter(
                  id => !program.lenderProfileIds.includes(id)
                ) || [],
            lenderProfileIdsToAssociate: program.lenderProfileIds,
            prescreenCriteriaIdToLink: _.get(program, "prescreenCriteriaIdToLink"),
            //@todo: cleanup payload
            financeProgramToLenderProfiles: undefined,
            rate: undefined,
            prescreenCriteria: undefined,
            createdDateTime: undefined,
            modifiedDateTime: undefined,
            __typename: undefined,
          },
        },
      });
    }
    setSnackbarOpen(true);
    setLoading(false);
    setHasFinanceProgramUnsavedChangesTimedOut(false);
    if (createMode) {
      setHasCriteriaFormUnsavedChanges(false);
    }
  };

  const handleClose = () => {
    console.log("Program PreScreen action: handleClose");

    if (hasProgramUnsavedChanges || hasCriteriaFormUnsavedChanges) {
      setOpenConfirmationDialog(true);
    } else {
      handleConfirmationClose(true);
    }
  };

  const handleConfirmationClose = (agree: boolean) => {
    console.log("Program PreScreen action: handleConfirmationClose");
    setOpenConfirmationDialog(false);
    if (agree) {
      onClose();
      //Reset the program modal
      setTabSelected("1");
      //Reset old criteria selected
      setCriteriaForm(null);
      setHasCriteriaFormUnsavedChanges(false);
      setInitFinanceProgram(null);
      refetchPrograms();
    }
  };

  useEffect(() => {
    if (_.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", "")) {
      console.log("Program PreScreen action: setPrescreenCriteriaId");
      setPrescreenCriteriaId(_.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""));
    }

    let programToSet = {
      ...program,
      ...financeProgram,
      prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
      nameInternal: _.get(financeProgram, "nameInternal", ""),
    };

    if (targetLenderProfileId) {
      programToSet = { ...programToSet, lenderProfileIds: [targetLenderProfileId] };
    }
    console.log("Program PreScreen action: setSelectedLenderProfilesForLender");
    setSelectedLenderProfilesForLender(
      _.get(financeProgram, "lenderProfileIds", []).filter((lpId: string) =>
        (lenderProfiles ?? []).find((lp: any) => lp.id === lpId)
      )
    );
    console.log("Program PreScreen action: setSelectedLenderProfilesForDealer");
    setSelectedLenderProfilesForDealer(
      _.get(financeProgram, "lenderProfileIds", []).filter((lpId: string) =>
        (dealerVendorProfiles ?? []).find((vp: any) => vp.lenderProfiles[0]?.lenderProfileId === lpId)
      )
    );

    if (financeProgram && financeProgram.paymentOptionsConfiguration) {
      console.log("Program PreScreen action: setInitFinanceProgram");
      setInitFinanceProgram(programToSet);
    }
    console.log("Program PreScreen action: setProgram");
    setProgram(programToSet);
  }, [financeProgram, targetLenderProfileId]);

  useEffect(() => {
    console.log("component re mount");
  }, []);

  const getLenderProfileLenderOptions = () => {
    let lenderProfileOptions: any[] = [];
    if (_.get(lenderProfiles, "length", 0) > 0) {
      lenderProfileOptions.push(
        <MenuItem value={"selectAll"}>
          <ListItemText style={{ fontWeight: "bold" }} primary="Select All" />
        </MenuItem>
      );
      lenderProfileOptions = lenderProfileOptions.concat(
        lenderProfiles
          .sort((a: any, b: any) => _.get(a, "lenderName", "").localeCompare(b.lenderName))
          .map((lp: any, i: any) => (
            <MenuItem key={`lender-profile-${i}`} value={lp.id}>
              <Checkbox checked={selectedLenderProfilesForLender.includes(lp.id)} />
              <ListItemText primary={lp.lenderName} />
            </MenuItem>
          ))
      );
    }

    return [...lenderProfileOptions];
  };

  const getLenderProfileDealerOptions = () => {
    let dealerVendorProfileOptions: any[] = [];
    if (dealerVendorProfiles && _.get(dealerVendorProfiles, "length", 0) > 0) {
      dealerVendorProfileOptions.push(
        <MenuItem value={"selectAll"}>
          <ListItemText style={{ fontWeight: "bold" }} primary="Select All" />
        </MenuItem>
      );
      dealerVendorProfileOptions = dealerVendorProfileOptions.concat(
        dealerVendorProfiles
          .map((vp: any) => ({ ...vp, name: vp.name.replace("VP - ", "") }))
          .sort((a: any, b: any) => _.get(a, "name", "").localeCompare(b.name))
          .map((vp: any, i: any) => (
            <MenuItem key={`dealer-vendor-profile-${i}`} value={vp.lenderProfiles[0]?.lenderProfileId}>
              <Checkbox checked={selectedLenderProfilesForDealer.includes(vp.lenderProfiles[0]?.lenderProfileId)} />
              <ListItemText primary={vp.name} />
            </MenuItem>
          ))
      );
    }

    return [...dealerVendorProfileOptions];
  };

  const updatePrescreenCriteriaId = (newPrescreenCriteriaId: string) => {
    setPrescreenCriteriaId(newPrescreenCriteriaId);
    if (newPrescreenCriteriaId && _.get(program, "financeProgramId")) {
      console.log(
        `Program PreScreen action: updatePrescreenCriteriaId: connect FP with Criteria with ID ${newPrescreenCriteriaId}`
      );
      setProgram({ ...program, prescreenCriteriaIdToLink: newPrescreenCriteriaId });
      updateLenderProgram({
        variables: {
          input: {
            financeProgramId: program.financeProgramId,
            prescreenCriteriaIdToLink: newPrescreenCriteriaId,
          },
        },
      }).then(() => {
        setSnackbarOpen(true);
        setHasCriteriaFormUnsavedChanges(false);
      });
    }
  };

  //TODO: add more proper validation
  const requiredFields = [
    program.nameInternal,
    program.paymentOptionsConfiguration.financeProgramName,
    program.paymentOptionsConfiguration.terms,
    program.paymentOptionsConfiguration.paymentPeriodTiming,
    prescreenCriteriaId,
  ];
  const isSubmitButtonDisabled = loading || requiredFields.some(_.isEmpty);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="fp-pc-title"
        aria-describedby="fp-pc-description"
        fullWidth
        maxWidth="lg"
        transitionDuration={0}
        TransitionProps={{
          unmountOnExit: false,
          style: { backgroundColor: "rgb(206 204 206 / 33%)" },
        }}>
        <DialogTitle id="fp-pc-title" style={{ paddingBottom: 0 }}>
          <h4>
            <b>{targetLenderProfileName} Program</b>
          </h4>
          <Divider />
          <IconButton className={classes.closeBtn} onClick={handleClose} size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <TabContext value={tabSelected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="Program"
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#2094ef",
                },
              }}>
              <Tab label="Pricing" value="1" style={{ fontSize: "12px" }} />
              <Tab label="Criteria" value="2" style={{ fontSize: "12px" }} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
              {loading ?? <LinearProgress />}
              {lenderProfiles && _.get(lenderProfiles, "length", 0) > 0 && _.isEmpty(targetLenderProfileId) && (
                <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                  <InputLabel shrink={true} id="lender-profile-label">
                    Lenders this program is available to
                  </InputLabel>
                  <Select
                    labelId="lender-profile-label"
                    id="lender-profile"
                    defaultValue={[]}
                    value={selectedLenderProfilesForLender}
                    onChange={(e: any) => {
                      if (e.target.value.includes("selectAll")) {
                        setSelectedLenderProfilesForLender(lenderProfiles.map((lp: any) => lp.id));
                        return setProgram({
                          ...program,
                          lenderProfileIds: lenderProfiles
                            .map((lp: any) => lp.id)
                            .concat(selectedLenderProfilesForDealer),
                        });
                      }
                      setSelectedLenderProfilesForLender([...Object.keys(e.target.value).map(k => e.target.value[k])]);
                      return setProgram({
                        ...program,
                        lenderProfileIds: [...Object.keys(e.target.value).map(k => e.target.value[k])].concat(
                          selectedLenderProfilesForDealer
                        ),
                      });
                    }}
                    label={"Lenders this program is available to"}
                    style={{ height: 40 }}
                    multiple={true}
                    renderValue={(selected: any) => {
                      // console.log(`WIP: lenderProfiles: ${JSON.stringify(lenderProfiles)}`);

                      const lenderProfilesSelected = (lenderProfiles ? lenderProfiles : [])
                        .filter((lp: any) => selected.includes(lp.id))
                        .map((lp: any) => lp.lenderName);

                      return [...lenderProfilesSelected].join(", ");
                    }}
                    disabled={program.accountId !== account.id}>
                    {getLenderProfileLenderOptions()}
                  </Select>
                </FormControl>
              )}

              {dealerVendorProfiles &&
                _.get(dealerVendorProfiles, "length", 0) > 0 &&
                _.isEmpty(targetLenderProfileId) && (
                  <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                    <InputLabel shrink={true} id="lender-profile-label">
                      Dealers this program is available to
                    </InputLabel>
                    <Select
                      labelId="lender-profile-label"
                      id="lender-profile"
                      defaultValue={[]}
                      value={selectedLenderProfilesForDealer}
                      onChange={(e: any) => {
                        console.log(
                          `WIP: selected: ${JSON.stringify([
                            ...Object.keys(e.target.value).map(k => e.target.value[k]),
                          ])}`
                        );
                        if (e.target.value.includes("selectAll")) {
                          setSelectedLenderProfilesForDealer(
                            dealerVendorProfiles.map((vp: any) => vp.lenderProfiles[0]?.lenderProfileId)
                          );
                          return setProgram({
                            ...program,
                            lenderProfileIds: dealerVendorProfiles.map(
                              (vp: any) => vp.lenderProfiles[0]?.lenderProfileId
                            ),
                          });
                        }
                        setSelectedLenderProfilesForDealer([
                          ...Object.keys(e.target.value).map(k => e.target.value[k]),
                        ]);
                        return setProgram({
                          ...program,
                          lenderProfileIds: [...Object.keys(e.target.value).map(k => e.target.value[k])].concat(
                            selectedLenderProfilesForLender
                          ),
                        });
                      }}
                      label={"Dealers this program is available to"}
                      style={{ height: 40 }}
                      multiple={true}
                      renderValue={(selected: any) => {
                        // console.log(`WIP: lenderProfiles: ${JSON.stringify(lenderProfiles)}`);

                        const dealerVendorProfilesSelected = (dealerVendorProfiles ? dealerVendorProfiles : [])
                          .filter((vp: any) => selected.includes(vp.lenderProfiles[0]?.lenderProfileId))
                          .map((vp: any) => vp.name.replace("VP - ", ""));

                        return [...dealerVendorProfilesSelected].join(", ");
                      }}
                      disabled={program.accountId !== account.id}>
                      {getLenderProfileDealerOptions()}
                    </Select>
                  </FormControl>
                )}
              <TextField
                className={classes.textField}
                label="Finance Program Name"
                variant="outlined"
                value={_.get(program, "nameInternal", "")}
                onChange={e => {
                  return setProgram({ ...program, nameInternal: e.target.value });
                }}
                fullWidth
                margin="normal"
                disabled={isLenderUser ? false : program.accountId !== account.id}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    style: { width: "100%" },
                    required: true,
                  },
                }}
              />

              <InputLabel shrink={true} style={{ marginTop: 10, fontSize: "22px" }}>
                Allow partners to set markup?
                <Checkbox
                  checked={_.get(program, "financeProgramModificationSettings.markup", false)}
                  onChange={e =>
                    setProgram({
                      ...program,
                      financeProgramModificationSettings: {
                        ...program.financeProgramModificationSettings,
                        markup: e.target.checked,
                      },
                    })
                  }
                />
                <Tooltip
                  title="When this is checked your partners will be able to set and adjust markup, otherwise they will have to use the exact settings you set below."
                  arrow>
                  <IconButton size="large">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputLabel>

              <FinanceProgramForm
                initFinanceProgram={
                  !_.isEmpty(initFinanceProgram)
                    ? {
                        ...initFinanceProgram?.paymentOptionsConfiguration,
                        validDateEnd: initFinanceProgram?.validDateEnd,
                        validDateStart: initFinanceProgram?.validDateStart,
                      }
                    : null
                }
                onValuesChange={(values: any) =>
                  setProgram({
                    ...program,
                    paymentOptionsConfiguration: {
                      ...values,
                      validDateStart: undefined,
                      validDateEnd: undefined,
                    },
                    validDateEnd: values.validDateEnd,
                    validDateStart: values.validDateStart,
                  })
                }
                isProgramOwner={program.accountId === account.id}
              />
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="flex-start">
                {(isLenderUser || program.accountId === account.id) && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={_.isEmpty(prescreenCriteriaId) ? 3 : 6}>
                        <Grid container>
                          <Grid item xs={_.isEmpty(prescreenCriteriaId) ? 6 : 4}>
                            <Button
                              disabled={isSubmitButtonDisabled}
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleCreateOrUpdateFinanceProgram()}>
                              {createMode ? "Create Program" : "Update Program"}
                            </Button>
                          </Grid>

                          {!createMode && !removingFP && (
                            <Grid item xs={4}>
                              <Button
                                variant="outlined"
                                disabled={loading}
                                size="small"
                                style={{ color: "#f44336" }}
                                onClick={() => setRemovingFP(true)}>
                                Remove Program
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {_.isEmpty(prescreenCriteriaId) && (
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={1}>
                              <InfoIcon color="primary" />
                            </Grid>
                            <Grid item xs={11}>
                              <Typography variant="caption">
                                To create a program, please fill in the criteria
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogActions>
          </TabPanel>
          <TabPanel value="2">
            <DialogContent style={{ overflowY: "hidden" }}>
              <CriteriaForm
                account={account}
                updatePrescreenCriteriaId={updatePrescreenCriteriaId}
                selectedPrescreenCriteriaId={prescreenCriteriaId}
                targetLenderProfileId={targetLenderProfileId}
                triggerSetSnackbarOpen={setSnackbarOpen}
                updateInitCriteria={setCriteriaForm}
                initCriteria={initCriteriaForm}
                psCriteriaData={psCriteriaData}
                psCriteriaLoading={psCriteriaLoading}
                refetchPsCriteria={refetchPsCriteria}
                setHasCriteriaFormUnsavedChanges={setHasCriteriaFormUnsavedChanges}
                criteriaFormSelectedCriteriaIndex={criteriaFormSelectedCriteriaIndex}
                setCriteriaFormSelectedCriteriaIndex={setCriteriaFormSelectedCriteriaIndex}
              />
            </DialogContent>
          </TabPanel>
        </TabContext>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2500}
          onClose={() => {
            setSnackbarOpen(false);
          }}>
          <Alert severity="success">Saved</Alert>
        </Snackbar>
        {/* <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="error">
      {errorMessage}
    </Alert>
  </Snackbar> */}
      </Dialog>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => {
          handleConfirmationClose(false);
        }}
        aria-labelledby="confirm-close-title"
        aria-describedby="confirm-close-description">
        <DialogContent>
          <DialogContentText id="confirm-close-description">
            Are you sure you want to close the {targetLenderProfileName} Program window?
          </DialogContentText>
          <DialogContentText id="unsaved-warn">Any unsaved changes will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleConfirmationClose(true);
            }}
            color="primary"
            autoFocus
            size="small"
            variant="outlined">
            Discard Changes
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleConfirmationClose(false);
            }}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProgramsPreScreen;
