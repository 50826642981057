import React from "react";
import { Switch, SwitchProps, FormControlLabel } from "@mui/material";

interface Props extends SwitchProps {
  label?: React.ReactNode;
}

export const FormSwitch = ({ label, ...switchProps }: Props) => {
  return (
    <FormControlLabel
      style={{ whiteSpace: "nowrap", marginLeft: "0px" }}
      control={<Switch color="primary" {...switchProps} />}
      label={label}
    />
  );
};
