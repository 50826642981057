import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { AWS_CONFIG, USER_PILOT_TOKEN } from "./config";

import Amplify, { Analytics, Auth } from "aws-amplify";
import App from "./App";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { compose, createStore } from "redux";
import { rootReducer } from "./redux/rootReducer";
import { Provider } from "react-redux";
import axios from "axios";
import { getHeadersFromAuth } from "./utils";
import { Userpilot } from "userpilot";
const config = require("./config");

console.log("%c ".concat("", " "), "color: grey; font-size:12px");

const store = createStore(
  rootReducer,
  compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
);

Userpilot.initialize(USER_PILOT_TOKEN);
Analytics.disable();

Amplify.configure(AWS_CONFIG);

// Add a request interceptor
axios.interceptors.request.use(
  async request => {
    try {
      const authHeaders = await getHeadersFromAuth(Auth);
      request.headers = { ...request.headers, ...authHeaders };
    } catch (err) {}

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

const authLink = setContext(async (_, { headers, ...context }) => {
  try {
    const authHeaders = await getHeadersFromAuth(Auth);

    const settings = {
      headers: {
        ...headers,
        accept: "application/json",
        ...authHeaders,
      },
      ...context,
    };

    return settings;
  } catch (error) {}

  if (context.authRequired === false) {
    return;
  }

  //If no user token could be capture, throw error for ErrorLink to handle
  throw new Error("No current user");
});

const httpLink = createUploadLink({
  uri: config.DCR_GRAPHQL_ENDPOINT,
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(function({ message, locations, path, extensions }) {
      if (window.NREUM) {
        window.NREUM.noticeError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }

      if (!extensions) {
        return null;
      }

      if (extensions.code === "UNAUTHENTICATED") {
        console.log("The session was ended.");
      }

      return null;
    });

  if (networkError) {
    if (!!networkError.message && networkError.message != "No current user") {
      console.log(networkError.message);
    }
  }
});

const cache = new InMemoryCache({
  AccountRep: {
    keyFields: ["email"],
  },
  Location: { keyFields: ["locationId"] },
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${config.DCR_GRAPHQL_ENDPOINT}/${operation?.operationName || "graphql"}`,
  }));

  return forward ? forward(operation) : null;
});

const client = new ApolloClient({
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
  connectToDevTools: true,
  link: errorLink.concat(ApolloLink.from([namedLink, authLink, retryLink, httpLink])),
});

const ApolloApp = () => {
  return (
    <Provider store={store}>
      <Helmet>
        <script
          type="text/javascript"
          defer="true"
          src="//cdn.mouseflow.com/projects/c13bcfe7-bdc3-4737-b9a3-992157baf90f.js"
        />
      </Helmet>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<ApolloApp />, document.getElementById("root"));
