import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { ModalsKeys } from "../../../../global";
import { useModal } from "../../../../hooks/useModal";

export const ConfirmSaveDraftDialog = () => {
  const classes = useStyles();

  const { isOpen, handleClose, data } = useModal(ModalsKeys.DeskingConfirmSaveDraftDialog);

  const handleSaveAsDraft = () => {
    if (data?.onSaveAsDraft) data?.onSaveAsDraft();
    handleClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth className={classes.dialog} onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            Do you want to save the previous version as a draft?
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box className={classes.dialogContent}>
          <Typography component="span" variant="body1">
            This version will be in the Proposals table
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>

        <Button size="small" color="primary" variant="contained" onClick={handleSaveAsDraft}>
          Save as draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: "10px 4px",
  },
  dialogTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
