import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import { connect } from "react-redux";
import LenderSubmissionFileUpLoadModal from "./LenderSubmissionFileUploadModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SaveIcon from "@mui/icons-material/Save";
import { DocumentContext } from "contexts/DocumentContext";
import Tile from "../../components/Tile/LenderSubmissionsTile";
import Typography from "@mui/material/Typography";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Tooltip from "@mui/material/Tooltip";
import { Button, LinearProgress, Switch } from "@mui/material";
import _ from "lodash";
import moment from "moment";

const QUERY_DECISION_LETTER = gql`
  query GetLenderSubmissionDocuments($creditSubId: ID, $VOId: ID) {
    creditSubmissionDocuments(creditSubId: $creditSubId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      createdBy
      docName
      docType
      createdByAccount
      docDescription
      createdDateTime
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
        dealerAccess
      }
    }
    lenderDecisionLetters(creditSubId: $creditSubId) {
      documentId
      vendorOpportunityId
      source
      createdBy
      docName
      docType
      docDescription
      createdDateTime
      createdByAccount
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
        dealerAccess
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    width: "calc(100% - 60px)",
    margin: "0px 30px 30px",
    boxShadow: "none",
    borderRadius: "7px",
  },
  header: {
    width: "100%",
    display: "flex",
    padding: "0px 20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lenderButton: {
    display: "flex",
    alignItems: "flex-start",
  },
  lenderButtonSubText: {
    fontSize: "10px",
  },
  titleStyles: {
    fontSize: "20px",
    fontWeight: "300",
    textTransform: "none",
    color: "#000000",
  },
  cardHeader: {
    marginLeft: "5px !important",
    marginRight: "5px !important",
  },
  row: {
    border: "none",
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Roboto",
    color: "#000",
    border: "none",
  },
  tableCell: {
    border: "none",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#000",
  },
  tableCellWider: {
    border: "none",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#000",
    minWidth: "80px",
  },
  downloadButton: {
    backgroundColor: "transparent",
    paddingRight: 0,
    border: "none",
    color: "#0A84FC",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: 300,
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
}));

const M_BULK_UPDATE_DOCUMENTS_TOGGLE_LENDER_ACCESS = gql`
  mutation ($batchUpdateLenderDocumentAccessInput: BatchUpdateLenderDocumentAccessInput!) {
    updateLenderDocumentsAccess(input: $batchUpdateLenderDocumentAccessInput) {
      success
      errorMessage
    }
  }
`;

function DecisionLetters({ vo, creditSubId, vp, initCollapsed, creditSubmissionId, account }) {
  console.log({ account });
  const classes = useStyles();
  const [updateLenderDocumentsAccess] = useMutation(M_BULK_UPDATE_DOCUMENTS_TOGGLE_LENDER_ACCESS);
  const [documents, setDocuments] = useState([]);
  const [decisionLetters, setDecisionLetters] = useState([]);
  const [enableSaveSettings, setEnableSaveSettings] = useState(false);

  const documentContext = useContext(DocumentContext);

  const {
    loading,
    error,
    data,
    refetch: creditSubmissionDocsRefetch,
  } = useQuery(QUERY_DECISION_LETTER, {
    variables: {
      creditSubId: creditSubId,
      VOId: vo.vendorOpportunityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) console.log("Loading documents...");
  if (error) console.log(`Error! ${error.message}`);

  const mapData = (
    key,
    name,
    uploaded_by,
    type,
    description,
    link,
    documentCreditSubmission,
    date,
    created_by_account
  ) => {
    let lender_access = undefined;
    let dealer_access = undefined;
    if (vp.showLenderDocsAccessMgmt) {
      if (documentCreditSubmission) {
        const thisSubmision = _.find(documentCreditSubmission, { creditSubmissionId }) || {};
        lender_access = thisSubmision.lenderAccess;
        dealer_access = thisSubmision.dealerAccess;
      }
    }
    if (vp.allowSyndication) {
      return {
        key,
        name,
        uploaded_by,
        type,
        description,
        link,
        lender_access,
        date,
        dealer_access,
        created_by_account,
      };
    }

    return { key, name, uploaded_by, type, description, link, lender_access, date, created_by_account };
  };

  //Fetch document for the very first time:
  useEffect(() => {
    if (data !== null && data !== undefined && data.documents !== null) {
      setDocuments(
        data.creditSubmissionDocuments.map(row => {
          return mapData(
            row.documentId,
            row.docName,
            row.createdBy,
            row.docType,
            row.docDescription,
            row.source,
            row.documentCreditSubmission,
            row.createdDateTime,
            row.createdByAccount
          );
        })
      );
    }
    if (data !== null && data !== undefined && data.lenderDecisionLetters !== null) {
      setDecisionLetters(
        data.lenderDecisionLetters.map(row =>
          mapData(
            row.documentId,
            row.docName,
            row.createdBy,
            row.docType,
            row.docDescription,
            row.source,
            row.createdDateTime,
            row.createdByAccount
          )
        )
      );
    }
  }, [data]);

  useEffect(() => {
    //refetch data after lastDoc context changed
    if (creditSubmissionDocsRefetch) creditSubmissionDocsRefetch();
  }, [documentContext.lastDoc]);

  const handleAllowDocument = (documentId, type = "lender_access") => {
    setEnableSaveSettings(true);
    setDocuments(
      documents.map(doc => {
        if (doc.key === documentId) {
          if (type === "lender_access") {
            doc.lender_access = !doc.lender_access;
          }
          if (type === "dealer_access") {
            doc.dealer_access = !doc.dealer_access;
          }
        }
        return doc;
      })
    );
  };

  const bulkUpdateDocumentAccessSettings = async () => {
    const documentsToUpdate = documents.map(doc => {
      return {
        documentId: doc.key,
        lenderAccess: doc.lender_access,
        dealerAccess: doc.dealer_access,
      };
    });

    if (documentsToUpdate) {
      await updateLenderDocumentsAccess({
        variables: {
          batchUpdateLenderDocumentAccessInput: {
            creditSubmissionId: creditSubId,
            documents: documentsToUpdate,
          },
        },
      });
    }
    setEnableSaveSettings(false);
  };

  const tableCell = tableData => {
    const rows = tableData.map(row => {
      return (
        <TableRow key={row.key} className={classes.row}>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            <Grid item justifyContent="space-between" container spacing={0}>
              <Grid>
                <div style={{ padding: "6px 0px" }}>{moment(row.date).format("MM.DD.YY")}</div>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="left" className={classes.tableCell}>
            {row.uploaded_by}
          </TableCell>
          <TableCell align="left" className={classes.tableCell}>
            {_.capitalize(row.type)}
          </TableCell>
          <TableCell align="left" className={classes.tableCell}>
            {row.description}
          </TableCell>
          {row.lender_access !== undefined && (
            <TableCell align="left" className={classes.tableCellWider}>
              <label style={{ fontSize: "1em", color: "#000" }}>Allow</label>
              <Switch
                value={row.lender_access ? "true" : "false"}
                checked={row.lender_access}
                onChange={e => handleAllowDocument(row.key, "lender_access")}
                defaultChecked={true}
                color="primary"
                size="small"
              />
            </TableCell>
          )}
          {row.dealer_access !== undefined && (
            <TableCell align="left" className={classes.tableCellWider}>
              <label style={{ fontSize: "1em", color: "#000" }}>Allow</label>
              <Switch
                disabled={row.created_by_account !== account.id || row.uploaded_by === "Trnsact"}
                value={row.dealer_access ? "true" : "false"}
                checked={row.dealer_access}
                onChange={e => handleAllowDocument(row.key, "dealer_access")}
                defaultChecked={true}
                color="primary"
                size="small"
              />
            </TableCell>
          )}
          {row.link ? (
            <TableCell align="left" className={classes.tableCell}>
              <a className={classes.downloadButton} href={row.link} target="_blank" rel="noreferrer">
                Download
              </a>
            </TableCell>
          ) : (
            <TableCell align="left" className={classes.tableCell}>
              <Tooltip title="Access Restricted" aria-label="Access Restricted" placement="top">
                <ReportProblemOutlinedIcon className={`${classes.reportProblemIcon}`} />
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      );
    });

    tableData.length === 0 &&
      rows.push(
        <TableRow key="no-docs" className={classes.row}>
          <TableCell align="center" colSpan={6} className={classes.tableCell}>
            No documents found
          </TableCell>
        </TableRow>
      );

    return rows;
  };

  return (
    <>
      <Grid item lg={12} style={{ width: "100%" }}>
        <Paper className={classes.container} style={{ marginBottom: 0 }}>
          <Tile
            title={
              <Typography variant="h6" className={classes.titleStyles}>
                Decision Letter
              </Typography>
            }
            useCollapsing={true}
            initCollapsed={initCollapsed}
            classNameHeader={classes.cardHeader}
            titleButtonComponent={
              <LenderSubmissionFileUpLoadModal
                refetchDocs={creditSubmissionDocsRefetch}
                voId={vo.vendorOpportunityId}
                creditSubmissionId={creditSubId}
                helperText="Upload decision letter"
                buttonText="Upload Letter"
                buttonDescription={() => {
                  return (
                    <>
                      <CloudUploadIcon style={{ marginRight: "8px" }} /> Upload Document
                    </>
                  );
                }}
              />
            }>
            <CardContent style={{ padding: 0 }}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Table size="small">
                  <TableHead>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.tableHeader}>Submission Date</TableCell>
                      <TableCell align="left" className={classes.tableHeader}>
                        Uploaded By
                      </TableCell>
                      <TableCell align="left" className={classes.tableHeader}>
                        Type
                      </TableCell>
                      <TableCell align="left" className={classes.tableHeader}>
                        Description
                      </TableCell>
                      <TableCell align="right" className={classes.tableHeader}>
                        Download Link
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{tableCell(decisionLetters)}</TableBody>
                </Table>
              )}
            </CardContent>
          </Tile>
        </Paper>
      </Grid>
      <Grid item lg={12} style={{ width: "100%" }}>
        <Paper className={classes.container} style={{ margin: "0px 30px" }}>
          <Tile
            title={
              <Typography variant="h6" className={classes.titleStyles}>
                Credit Submission Documents
              </Typography>
            }
            classNameHeader={classes.cardHeader}
            useCollapsing={true}
            initCollapsed={initCollapsed}
            titleButtonComponent={
              <LenderSubmissionFileUpLoadModal
                refetchDocs={creditSubmissionDocsRefetch}
                voId={vo.vendorOpportunityId}
                creditSubmissionId={creditSubId}
                helperText={
                  <span className={classes.lenderButton}>
                    Upload documents Pertaining{" "}
                    <span style={{ textDecoration: "underline", padding: "0 5px" }}> only </span> to this submission
                  </span>
                }
                buttonText="Upload Docs"
                buttonDescription={() => {
                  return (
                    <>
                      <CloudUploadIcon style={{ marginRight: "8px" }} /> Upload Document
                    </>
                  );
                }}
              />
            }>
            <CardContent style={{ padding: 0 }}>
              {loading ? (
                <LinearProgress />
              ) : (
                <>
                  <Table size="small">
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.tableHeader}>Submission Date</TableCell>
                        <TableCell align="left" className={classes.tableHeader}>
                          Uploaded By
                        </TableCell>
                        <TableCell align="left" className={classes.tableHeader}>
                          Type
                        </TableCell>
                        <TableCell align="left" className={classes.tableHeader}>
                          Description
                        </TableCell>
                        {vp.showLenderDocsAccessMgmt && (
                          <>
                            <TableCell align="left" className={classes.tableHeader}>
                              Lender Access
                            </TableCell>
                            {vp?.allowSyndication ? (
                              <TableCell align="left" className={classes.tableHeader}>
                                Dealer Access
                              </TableCell>
                            ) : null}
                          </>
                        )}
                        <TableCell align="right" className={classes.tableHeader}>
                          Download Link
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{tableCell(documents)}</TableBody>
                  </Table>
                  {vp.showLenderDocsAccessMgmt && (
                    <Grid item lg={12} style={{ width: "100%" }} alignContent="flex-end" alignItems="flex-end">
                      {enableSaveSettings && (
                        <Button
                          style={{ margin: "20px 5px 5px auto", display: "flex" }}
                          fullWidth={false}
                          variant="contained"
                          color="primary"
                          endIcon={<SaveIcon />}
                          autoFocus
                          size="small"
                          onClick={() => {
                            bulkUpdateDocumentAccessSettings();
                          }}>
                          Save Settings
                        </Button>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </CardContent>
          </Tile>
        </Paper>
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  return {
    creditApp: state.creditApp,
    vo: state.vo,
    account: state.account,
  };
};

export default connect(mapStateToProps, null)(DecisionLetters);
