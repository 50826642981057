import React, { PropsWithChildren, SyntheticEvent } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  onCellClick?: (event: SyntheticEvent) => void;
}

export const TableCell = ({ children, onCellClick = () => {} }: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Box onClick={onCellClick} className={classes.cell}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles({
  cell: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
