import Grid from "@mui/material/Grid";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import makeStyles from "@mui/styles/makeStyles";

import Button from "../components/CustomButtons/Button";

const styles = {
  linkButton: {
    padding: "10px 15px",
    marginRight: 20,
    width: "200px",
  },
  ocaLinkContainer: {
    backgroundColor: "#edf3fd",
    padding: "30px",
  },
  ocaLinkHeader: {
    margin: 0,
    textAlign: "center",
  },
  qrCodeContainer: {
    textAlign: "center",
  },
  downloadQRContainer: {
    textAlign: "center",
  },
  downloadQRLink: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#3b82f4",
    textTransform: "uppercase",
    "&:hover, &:focus": {
      color: "#3b82f4",
    },
  },
  ocaUnreadyMessage: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(styles);

function PersonalLinkAndQRCode({ personalLink, linkHeader, isOCAUnready = false, isOCAUnreadyMessage = false }) {
  const classes = useStyles();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(personalLink);
  };
  const generateQRCodeImageURL = personalLink => {
    const baseUrl = "https://api.qrserver.com/v1/create-qr-code/";
    const encodedLink = encodeURIComponent(personalLink);
    const queryParams = `?data=${encodedLink}&amp;size=350x350&bgcolor=edf3fd`;
    return baseUrl + queryParams;
  };
  const QRCodeImageURL = generateQRCodeImageURL(personalLink);

  if (!personalLink) {
    return null;
  }

  return (
    <Grid item container xs={12} sm={6} className={"personalLinkAndQRCodeRoot" + " " + classes.ocaLinkContainer}>
      <Grid item xs={12} justifyContent="center" container>
        <h2 className={classes.ocaLinkHeader}>{linkHeader}</h2>
      </Grid>
      <Grid item container xs={12}>
        {!isOCAUnready && (
          <>
            <Grid item xs={12} className={classes.qrCodeContainer}>
              <img src={QRCodeImageURL} alt="OCA QR code link" />
            </Grid>
            <Grid item xs={12} className={classes.downloadQRContainer}>
              <a className={classes.downloadQRLink} href={QRCodeImageURL + "&download=1"} download="qr.png">
                Download QR
              </a>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="flex-end" container>
              <Button
                size="small"
                color="primary"
                onClick={copyToClipboard}
                startIcon={<FileCopyTwoToneIcon />}
                className={classes.linkButton}>
                Copy to Clipboard
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  window.open(personalLink);
                }}
                startIcon={<OpenInNewIcon />}
                className={classes.linkButton}>
                Open in New Tab
              </Button>
            </Grid>
          </>
        )}
        {isOCAUnready && isOCAUnreadyMessage && (
          <Grid item xs={12}>
            <h4 className={classes.ocaUnreadyMessage}>{isOCAUnreadyMessage}</h4>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default PersonalLinkAndQRCode;
