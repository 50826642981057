import gql from "graphql-tag";

export const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

export const DEACTIVATE_TASK = gql`
  mutation($taskId: ID!) {
    deactivateTask(taskId: $taskId)
  }
`;

export const UPDATE_TASK = gql`
  mutation($taskId: ID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input)
  }
`;

export const SEND_REMINDER_TASK = gql`
  mutation($input: SendTaskReminderInput!) {
    sendTaskReminder(input: $input)
  }
`;

export const QUERY_TASKS = gql`
  query VendorOpportunityTask($VOId: ID!, $limit: Int) {
    vendorOpportunityTask(VOId: $VOId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      completedDateTime
      dueDateTime
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      notifyAssignorOnReminder
      notificationContactIds
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      ownerPgId
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
      reference {
        referenceId
        tasksCompletionData
      }
    }
  }
`;

export const QUERY_TASKS_BY_CS = gql`
  query VendorOpportunityTask($csId: ID!, $limit: Int) {
    vendorOpportunityTask(csId: $csId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      createdDateTime
      completedDateTime
      dueDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notificationContactIds
      notifyAssignorOnReminder
      notifyByEmail
      notifyByText
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
      reference {
        referenceId
        tasksCompletionData
      }
    }
  }
`;

export const QUERY_RELATED_USERS = gql`
  query($accountId: ID) {
    relatedUsers(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mobilePhoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
    }
  }
`;
