import gql from "graphql-tag";

export const GET_PARTNER_LINKS = gql`
  query GetPartnerLinks($dynamicsId: ID!) {
    getPartnerLinks(dynamicsId: $dynamicsId) {
      partnerLinkId
      dynamicsPartnerAccountId
      dynamicsPartnerLinkId
      accountId
      accountName
      integrationSettingsValues {
        integrationSettingsValues
      }
      partnerLocations {
        locationId
        locationName
        category
      }
    }
  }
`;
