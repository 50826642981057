import { CreditApplication, OcaTemplate } from "@trnsact/trnsact-shared-types";

export type ContactType = "contact-only" | "signor-only" | "signor-and-guarantor";
//export type ContactType = ContactTypeEnum.ContactOnly | ContactTypeEnum.SignorOnly | ContactTypeEnum.SignorAndGuarantor;

export type ContactFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string | null;
  title?: string;
  ssn?: string;
  percOwner?: string;
  workPhone?: string;
  mobilePhone?: string;
  homePhone?: string;
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export type ContactPayloadInput = ContactFormValues & {
  ownerPgId: string;
  percOwner: number;
  creditApplicationId: string;
  isOwnerPc: boolean;
  isOwnerPg: boolean;
  isOwnerPcOnly: boolean;
  ownerNumber: number;
};

export type RecertificationOption = "document" | "verbal-authorization" | "send-task";

export type RecertificationFormValues = {
  recertificationOption: RecertificationOption;
  recertificationDate: string;
  recertificationContactIds: string[];
  file: File | null;
};

/* export enum ContactTypeEnum {
  ContactOnly = "contact-only",
  SignorOnly = "signor-only",
  SignorAndGuarantor = "signor-and-guarantor",
}; */

export enum AddContactConfirmationModalTitle {
  ContactOnly = "Add New Contact",
  SignorOnly = "Add New Signor",
  SignorAndGuarantor = "Add New Signor Guarantor",
}

export interface CorporateGuarantorForm {
  companyName: string;
  address: string;
  taxId: string;
  yearBusinessEstablished: number;
}

export interface CorporateGuarantorDialogData {
  creditApplication: CreditApplication;
  ocaTemplate: OcaTemplate;
  refetchCreditApp: () => Promise<void>;
}
