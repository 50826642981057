import React from "react";
import { connect } from "react-redux";
import { setRefetch } from "../../redux/actions";

import { gql } from "@apollo/client";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import makeStyles from "@mui/styles/makeStyles";
import RouteOneBizApp from "./RouteOneBizApp";
import IndividualApp from "./RouteOneIndApp";
import CreditApp from "./PageCreditApp";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import DescriptionIcon from "@mui/icons-material/Description";

const config = require("../../config.js");

const useRadioStyles = makeStyles(customCheckboxRadioSwitch);

const CREATE_CREDIT_APPLICATION = gql`
  mutation ($creditApplication: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplication) {
      id
      success
      errorMessage
    }
  }
`;

function CreditAppv2({ userProfile, match, setRefetch, account, vp }) {
  return (
    <>
      {vp.showRouteOneApp === "show" ? (
        <div>
          <br />
          <CustomTabs
            headerColor="primary"
            tabs={[
              {
                tabName: "RouteOne Business App",
                tabIcon: DescriptionIcon,
                tabContent: <RouteOneBizApp />,
              },
              {
                tabName: "RouteOne Consumer App",
                tabIcon: DescriptionIcon,
                tabContent: <IndividualApp />,
              },
            ]}
          />
        </div>
      ) : (
        <CreditApp />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
    vp: state.vp,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditAppv2);
