import React, { PropsWithChildren, useCallback, useState } from "react";
import { SaleType, SaleStructureType } from "../../types";
import { saleModeContext as SaleModeContext, SaleModeValues } from "./saleModeContext";

export const SaleModeProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<SaleModeValues>({
    isCash: false,
    isFinancing: true,
    saleType: SaleType.Financing,
    saleStructureType: SaleStructureType.Finance,
  });

  const handleChangeMode = useCallback((saleType: SaleType) => {
    setState(prev => ({
      ...prev,
      saleType,
      isCash: saleType === SaleType.Cash,
      isFinancing: saleType === SaleType.Financing,
      saleStructureType: saleType === SaleType.Cash ? SaleStructureType.AftermarketMenu : SaleStructureType.Finance,
    }));
  }, []);

  const handleChangeStructureType = useCallback((saleStructureType: SaleStructureType) => {
    setState(prev => ({ ...prev, saleStructureType }));
  }, []);

  return (
    <SaleModeContext.Provider
      value={{
        ...state,
        onChangeSaleType: handleChangeMode,
        onChangeStructureType: handleChangeStructureType,
      }}
    >
      {children}
    </SaleModeContext.Provider>
  );
};
