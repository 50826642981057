import React, { useEffect, useState } from "react";
import { AppBar, CircularProgress, Grid, Modal, Tab, Tabs, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import GridItem from "components/Grid/GridItem";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import ReactTable from "react-table";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import * as _ from "lodash";
import Tile from "../../../components/Tile/Tile";
import StringList from "../../../../../components/Generic/StringList/StringList";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: "75%",
    maxWidth: 900,
    minWidth: "75%",
    maxHeight: "85%", // Adjusted to a fixed height
    minHeight: "75%",
    overflowY: "auto", // Enable vertical scrolling
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formContainer: {
    paddingTop: "16px",
  },
  fixReactTable: {
    width: "100%",
    overflowX: "auto", // Enable horizontal scrolling
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "200px",
    height: "200px",
  },
}));

const INTEGRATION_SETTINGS = gql`
  query ($integrationName: String!) {
    integrationSettings(integrationName: $integrationName) {
      integrationName
      integrationSettings
    }
  }
`;

const INSERT_INTEGRATION_SETTINGS_VALUES = gql`
  mutation ($input: InsertIntegrationSettingsValues!) {
    insertIntegrationSettingsValues(input: $input) {
      accountId
      integrationSettingsValuesId
      integrationName
      locationId
      userProfileId
      integrationSettingsValues
    }
  }
`;

const GET_INTEGRATION_SETTINGS_VALUES_LIST = gql`
  query ($input: IntegrationSettingsValuesListInput) {
    integrationSettingsValuesList(input: $input) {
      integrationSettingsValuesId
      integrationName
      integrationSettingsValues
      accountId
      userProfileId
      locationId
      createdDateTime
      modifiedDateTime
    }
  }
`;

const DealerCredentialsModal = ({
  integrationName,
  dealerContacts,
  dealerLocations,
  dealerAccountId,
  isOpen,
  handleClose,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({ user: {}, location: {} });
  const [activeTab, setActiveTab] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [openCredentialSaveSnackbar, setOpenCredentialSaveSnackbar] = useState(false);
  const [credentialSaveMessage, setCredentialSaveMessage] = useState("Saved Successfully!");
  const [severity, setSeverity] = useState("success");
  const [insertIntegrationSettingsLoading, setInsertIntegrationSettingsLoading] = useState(false);

  const {
    loading: loadingIntegrationSettingsValuesList,
    error: errorIntegrationSettingsValuesList,
    data: integrationSettingsValuesListData,
  } = useQuery(GET_INTEGRATION_SETTINGS_VALUES_LIST, {
    skip: !integrationName,
    variables: { input: { integrationName, accountId: dealerAccountId } },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  const { loading, data } = useQuery(INTEGRATION_SETTINGS, {
    skip: !integrationName,
    variables: { integrationName },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  const [insertIntegrationSettingsValues] = useMutation(INSERT_INTEGRATION_SETTINGS_VALUES, {
    onCompleted: () => {
      setInsertIntegrationSettingsLoading(false);
    },
  });

  const handleCredentialSaveSnackbarClose = () => {
    setOpenCredentialSaveSnackbar(false);
  };

  const handleInputChange = (name, value, rowKey, level) => {
    setFormValues(prevValues => {
      const newValues = {
        ...prevValues,
        [level]: {
          ...prevValues[level],
          [rowKey]: { ...(prevValues[level] && prevValues[level][rowKey]), [name]: value },
        },
      };

      // console.log(`NEW VALUES: ${JSON.stringify(newValues, null, 2)}`);
      return newValues;
    });
  };

  const handleSave = (value, level, rowKey, name) => {
    setInsertIntegrationSettingsLoading(true);
    setFormValues(currentFormValues => {
      // This function receives the previous state as an argument

      // console.log(`value: ${value}, level: ${level}, rowKey: ${rowKey}, name: ${name}`);

      if (!currentFormValues[level][rowKey]) {
        currentFormValues[level][rowKey] = {};
      }

      currentFormValues[level][rowKey][name] = value;

      const input = {
        integrationName: integrationName,
        integrationSettingsValues: {
          ...currentFormValues[level][rowKey],
        },
      };

      if (level === "user") {
        input.userProfileId = rowKey;
      } else if (level === "location") {
        input.locationId = rowKey;
      } else if (level === "account") {
        input.accountId = rowKey;
      } else {
        console.warn(`Unhandled credentials level: ${level}`);
      }

      // console.log(`save input: ${JSON.stringify(input, null, 2)}`);

      insertIntegrationSettingsValues({
        variables: { input },
      })
        .then(() => {
          setSeverity("success");
          setCredentialSaveMessage("Saved Successfully!");
          setOpenCredentialSaveSnackbar(true);
        })
        .catch(() => {
          setSeverity("error");
          setCredentialSaveMessage("Problem saving credentials, please try again.");
          setOpenCredentialSaveSnackbar(true);
        });

      return currentFormValues; // Return the updated state
    });
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const integrationSettings = data?.integrationSettings?.integrationSettings
    ? data.integrationSettings.integrationSettings
    : null;

  // Group integration settings by level
  const groupedSettings = {};

  if (integrationSettings && Array.isArray(integrationSettings)) {
    integrationSettings.forEach(setting => {
      if (!groupedSettings[setting.level]) {
        groupedSettings[setting.level] = [];
      }
      groupedSettings[setting.level].push(setting);
    });
  }

  const integrationSettingsValuesList = integrationSettingsValuesListData?.integrationSettingsValuesList
    ? integrationSettingsValuesListData.integrationSettingsValuesList
    : null;

  useEffect(() => {
    let initialFormValues = null;
    if (integrationSettingsValuesList) {
      initialFormValues = { user: {}, location: {}, account: {} };
      for (let i = 0; i < integrationSettingsValuesList.length; i++) {
        const isvRow = integrationSettingsValuesList[i];
        // console.log(`both isvRow: ${JSON.stringify(isvRow, null, 2)}`);
        if (isvRow.userProfileId) {
          initialFormValues.user[isvRow.userProfileId] = isvRow.integrationSettingsValues;
        } else if (isvRow.locationId) {
          initialFormValues.location[isvRow.locationId] = isvRow.integrationSettingsValues;
        }
      }
      console.log(`both initialFormValues: ${JSON.stringify(initialFormValues, null, 2)}`);
      setFormValues(initialFormValues);
      setActivePage(0);
    }
  }, [integrationSettingsValuesListData]);

  const userColumns = [
    {
      Header: "Name",
      accessor: "fullName",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Email",
      accessor: "email",
    },
  ].concat(
    groupedSettings?.user?.map(setting => {
      return {
        Header: setting.displayName,
        Cell: props => {
          return renderFormInput(setting, props.original.id);
        },
        filterable: false,
        sortable: false,
      };
    })
  );

  const locationColumns = [
    {
      Header: "Location",
      accessor: "locationName",
    },
  ].concat(
    groupedSettings?.location?.map(setting => {
      return {
        Header: setting.displayName,
        Cell: props => {
          return renderFormInput(setting, props.original.locationId);
        },
        filterable: false,
        sortable: false,
      };
    })
  );

  const accountColumns = groupedSettings?.account?.map(setting => {
    return {
      Header: setting.displayName,
      Cell: props => {
        return renderFormInput(setting, props.original.locationId);
      },
      filterable: false,
      sortable: false,
    };
  });

  const renderFormInput = (setting, rowKey) => {
    console.log(`in renderFormInput: ${setting.level} ${setting.name} ${JSON.stringify(setting)} ${rowKey}`);
    console.log(`formValues: ${JSON.stringify(formValues, null, 2)}`);

    let optionSetEntries;
    if (setting.type === "OPTION_SET") {
      if (
        formValues[setting.level] &&
        formValues[setting.level][rowKey] &&
        _.isArray(formValues[setting.level][rowKey][setting.name])
      ) {
        optionSetEntries = formValues[setting.level][rowKey][setting.name].map(e => {
          if (_.isObject(e)) {
            return e;
          }
          return {
            displayName: e,
            value: e,
          };
        });
      } else if (
        formValues[setting.level] &&
        formValues[setting.level][rowKey] &&
        formValues[setting.level][rowKey][setting.name]
      ) {
        optionSetEntries = [
          {
            displayName: formValues[setting.level][rowKey][setting.name],
            value: formValues[setting.level][rowKey][setting.name],
          },
        ];
      } else {
        optionSetEntries = [];
      }
    }

    return (
      <GridItem xs={12}>
        {setting.type === "STRING" && (
          <TextField
            label={setting.displayName}
            type={setting.name.toLowerCase().includes("password") ? "password" : "text"}
            key={`${setting.level}-${setting.name}-${rowKey}`}
            fullWidth
            defaultValue={
              formValues[setting.level] &&
              formValues[setting.level][rowKey] &&
              formValues[setting.level][rowKey][setting.name]
            }
            onBlur={e => handleSave(e.target.value, setting.level, rowKey, setting.name)}
            required={setting.requiredStatus === "REQUIRED"}
          />
        )}
        {setting.type === "INTEGER" && (
          <TextField
            label={setting.displayName}
            type="number"
            key={`${setting.level}-${setting.name}-${rowKey}`}
            fullWidth
            defaultValue={
              formValues[setting.level] &&
              formValues[setting.level][rowKey] &&
              formValues[setting.level][rowKey][setting.name]
            }
            onBlur={e => handleSave(e.target.value, setting.level, rowKey, setting.name)}
            required={setting.requiredStatus === "REQUIRED"}
          />
        )}
        {setting.type === "OPTION_SET" && (
          <StringList
            label={setting.displayName}
            entries={optionSetEntries}
            onChange={optionSetList => handleInputChange(setting.name, optionSetList, rowKey, setting.level)}
            onBlur={optionSetList => handleSave(optionSetList, setting.level, rowKey, setting.name)}
            withDisplayName={true}
          />
        )}
      </GridItem>
    );
  };

  return (
    <Modal open={isOpen} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper}>
        <div className={classes.closeButton} onClick={handleClose}>
          X
        </div>
        {integrationSettings && !loading && !loadingIntegrationSettingsValuesList && (
          <div className={classes.tabContainer}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleChangeTab}>
                {Object.keys(groupedSettings).map((level, index) => (
                  <Tab key={index} label={level} disabled={!groupedSettings[level]} />
                ))}
              </Tabs>
            </AppBar>
            {insertIntegrationSettingsLoading && (
              <div>
                <CircularProgress className={classes.circularProgress} />
              </div>
            )}
            {!insertIntegrationSettingsLoading &&
              Object.keys(groupedSettings).map((level, index) => (
                <div key={index} role="tabpanel" id={`tabpanel-${index}`}>
                  {activeTab === index && (
                    <>
                      <Grid item xs={12}>
                        <Tile>
                          <div className={classes.fixReactTable}>
                            {level === "account" && (
                              <ReactTable
                                color="primary"
                                data={dealerContacts}
                                columns={accountColumns}
                                defaultPageSize={10}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                customCellClasses={[classes.right]}
                                customClassesForCells={[5]}
                                className="-striped -highlight"
                                onPageChange={setActivePage}
                              />
                            )}
                            {level === "user" && (
                              <ReactTable
                                color="primary"
                                data={dealerContacts}
                                columns={userColumns}
                                defaultPageSize={10}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                customCellClasses={[classes.right]}
                                customClassesForCells={[5]}
                                className="-striped -highlight"
                                onPageChange={setActivePage}
                              />
                            )}
                            {level === "location" && (
                              <ReactTable
                                color="primary"
                                data={dealerLocations}
                                columns={locationColumns}
                                defaultPageSize={10}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                customCellClasses={[classes.right]}
                                customClassesForCells={[5]}
                                className="-striped -highlight"
                                onPageChange={setActivePage}
                              />
                            )}
                          </div>
                        </Tile>
                      </Grid>
                    </>
                  )}
                </div>
              ))}
            <Snackbar
              open={openCredentialSaveSnackbar}
              autoHideDuration={1500}
              onClose={handleCredentialSaveSnackbarClose}>
              <Alert variant="filled" severity={severity} onClose={handleCredentialSaveSnackbarClose}>
                {credentialSaveMessage}
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DealerCredentialsModal;
