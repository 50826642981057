import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FinanceProgramForm } from "../../../../schema";
import { FinanceCriteriaOptions } from "../../../../types";
import { ChooseCriteriaRuleField } from "./ChooseCriteriaRuleField";
import { CriteriaAutocompleteField } from "./CriteriaAutocompleteField";
import { allFacts, getInitialCriteriaConditionFormValue } from "../../../../constants";

interface Props {
  isReadOnly: boolean;
  criteriaOptions: FinanceCriteriaOptions[];
}

export const ChooseCriteria = ({ criteriaOptions, isReadOnly }: Props) => {
  const { control, getValues, setValue, watch } = useFormContext<FinanceProgramForm>();
  const { fields, append, remove } = useFieldArray<FinanceProgramForm>({ control, name: "program.third.conditions" });

  const handleAppendCriteriaRule = () => {
    const criteriaValue = getValues("program.third.criteria");
    const selectedRules = getValues("program.third.conditions").map(({ rule }) => rule);

    if (criteriaValue) setValue("program.third.criteria", null);

    const nextRule = allFacts.filter(fact => !selectedRules.includes(fact.value))[0].value;
    const nextCriteriaInitialValue = getInitialCriteriaConditionFormValue(nextRule);
    append(nextCriteriaInitialValue);
  };

  const handleRemoveCriteriaRule = (fieldIndex: number) => {
    const criteriaValue = getValues("program.third.criteria");
    if (criteriaValue) setValue("program.third.criteria", null);

    remove(fieldIndex);
  };

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (value?.program?.third?.criteria && type === "change") {
        setValue("program.third.criteria", null);
      }
    });

    return subscription.unsubscribe;
  }, []);

  return (
    <>
      <CriteriaAutocompleteField isReadOnly={isReadOnly} criteriaOptions={criteriaOptions} />

      {!!fields?.length &&
        fields.map((field, index) => (
          <ChooseCriteriaRuleField key={field.id} index={index} onRemove={() => handleRemoveCriteriaRule(index)} />
        ))}

      <Box>
        <Button
          startIcon={<AddCircleOutline />}
          onClick={handleAppendCriteriaRule}
          disabled={isReadOnly || fields.length === allFacts.length}>
          Criteria row
        </Button>
      </Box>
    </>
  );
};
