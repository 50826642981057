import React from "react";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_CONTRACT_DETAILS } from "../../api";
import { DetailsResponse, DetailsVariables } from "../../api/types";
import { ContractDetailsVendorData } from "../../types";
import { DynamicJsonViewer } from "components/Table/Cells/DynamicJsonViewerCell";
import { Close } from "@mui/icons-material";

const columns: Array<{ header: string; dataKey: keyof ContractDetailsVendorData }> = [
  { header: "Status", dataKey: "status" },
  { header: "Vendor", dataKey: "aftermarketVendorApiChannel" },
  { header: "Data Sent", dataKey: "dataPayload" },
  { header: "Data Received", dataKey: "dataResponse" },
];

export const ProposalContractDetailsDialog = () => {
  const {
    isOpen,
    handleClose,
    data: { proposalId },
  } = useModal(ModalsKeys.ProposalContractDetailsDialog);

  const { data, loading } = useQuery<DetailsResponse, DetailsVariables>(GET_CONTRACT_DETAILS, {
    skip: !proposalId,
    variables: { id: proposalId },
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h5">Contract Details</Typography>
          <IconButton size="small" onClick={handleClose}>
            <Close style={{ color: "#000000" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ height: "600px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map(({ header }) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.details?.contracts?.[0]?.proposalContractAftermarketVendorData?.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map(({ dataKey }) => {
                        if (typeof row[dataKey] === "string") {
                          return (
                            <TableCell key={dataKey}>
                              <Typography>{row[dataKey]}</Typography>
                            </TableCell>
                          );
                        }

                        return <DynamicJsonViewer json={row[dataKey]} />;
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};
