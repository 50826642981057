import React, { ReactNode } from "react";
import { formatCurrency } from "utils";
import { ProfitSummary } from "./ProfitSummary";
import { PaymentInfoRenderConfig, SaleType, Pricing } from "../../../types";

interface Props {
  payment: Pricing;
  saleType: SaleType;
}

export const ProfitSummaryContainer = ({ payment, saleType }: Props) => {
  const renderConfig: PaymentInfoRenderConfig[] = [
    {
      label: "Finance Profit",
      value: payment?.financeProfit,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
  ];

  const renderBySaleType: Record<SaleType, ReactNode> = {
    [SaleType.Cash]: null,
    [SaleType.Financing]: (
      <ProfitSummary totalProfit={formatCurrency(payment?.totalProfit ?? "0", true)} renderConfig={renderConfig} />
    ),
  };

  return <>{renderBySaleType[saleType]}</>;
};
