import React from "react";
import { TableView } from "../TableView";
import { createTableColumns } from "./tableConfig";
import { Proposal } from "@trnsact/trnsact-shared-types";

interface Props {
  loading?: boolean;
  tableData: Proposal[];
  onGoToProposal: (proposal: Proposal) => void;
  onGoToProposalDetail: (proposal: Proposal) => void;
}

export const BaseTable = ({ tableData, onGoToProposal, onGoToProposalDetail, loading }: Props) => {
  const columns = createTableColumns({
    tableData,
    onGoToProposal,
    onGoToProposalDetail,
  });

  return (
    <TableView<Proposal>
      loading={loading}
      columns={columns}
      minHeight="33.5rem"
      tableData={tableData}
      noData="No data about proposals"
      loadingText="Proposals loading..."
    />
  );
};
