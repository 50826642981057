import React, { ReactNode } from "react";
import moment from "moment";
import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import { FinanceProgramRateType, FinanceProgramType, PaymentPeriodTiming } from "@trnsact/trnsact-shared-types";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FinanceProgramForm } from "../../../../schema";
import { DateFormats } from "../../../../../../constants";
import { MarkupMethods, Structures } from "../../../../types";
import {
  markupOptions,
  paymentCalculationMethodOptions,
  programTypeOptions,
  rateTypeOptions,
  structureOptions,
  termsOptions,
} from "../../../../constants";
import {
  CurrencyInputField,
  DateField,
  InputField,
  InterestInputField,
  MultiSelectWithCustomOptions,
  RadioGroupField,
  SelectField,
  SwitchField,
} from "../../../../../../components/form";

interface Props {
  isReadOnly: boolean;
}

export const ConfigureStructure = ({ isReadOnly }: Props) => {
  const { control, setValue } = useFormContext<FinanceProgramForm>();
  const secondStep = useWatch({ control, name: "program.second" });
  const { fields, append, remove } = useFieldArray<FinanceProgramForm>({ control, name: "program.second.fee" });
  const { fields: specifyRatesFields } = useFieldArray({ control, name: "program.second.specifyRates" });

  const structurePartByType: Record<Structures, ReactNode> = {
    [Structures.Markup]: (
      <>
        <RadioGroupField label="Method" name="program.second.method" control={control} options={markupOptions} row />

        {secondStep.method !== MarkupMethods.Markup ? (
          <InterestInputField
            control={control}
            name="program.second.customerRate"
            label="Customer Rate (%)"
            textFieldProps={{
              required: true,
              fullWidth: true,
              disabled: isReadOnly,
            }}
          />
        ) : (
          <InterestInputField
            control={control}
            name="program.second.dealerMarkup"
            label="Dealer Markup (%)"
            textFieldProps={{
              required: true,
              fullWidth: true,
              disabled: isReadOnly,
            }}
          />
        )}
      </>
    ),
    [Structures.Subsidy]: (
      <InterestInputField
        control={control}
        label="Customer Rate (%)"
        name="program.second.customerRate"
        textFieldProps={{
          required: true,
          fullWidth: true,
          disabled: isReadOnly,
        }}
      />
    ),
    [Structures.None]: null,
  };

  const handleChangeTerms = (selected: (string | number)[]) => {
    const termsToString = selected.map(String);

    setValue("program.second.terms", termsToString, { shouldValidate: true });
    setValue(
      "program.second.specifyRates",
      termsToString.map(term => ({
        term,
        rate: secondStep.rate ?? 0,
      }))
    );
  };

  const isDaysToFirstPaymentGTZero = Boolean(+secondStep.daysToFirstPayment);

  return (
    <>
      <RadioGroupField
        row
        label="Rate Type"
        control={control}
        options={rateTypeOptions}
        name="program.second.rateType"
        onChange={(event, value) => {
          if (value === FinanceProgramRateType.RateFactor) setValue("program.second.isSpecifyRate", true);
          setValue("program.second.rateType", value as FinanceProgramRateType);
        }}
        formControlProps={{
          disabled: isReadOnly,
        }}
      />

      <Box className="formRow">
        <MultiSelectWithCustomOptions
          isOptionsSorted
          control={control}
          options={termsOptions}
          name="program.second.terms"
          onChange={handleChangeTerms}
          selectProps={{
            disabled: isReadOnly,
            label: "Terms in Months",
          }}
          selectFormControlProps={{
            required: true,
            variant: "standard",
          }}
          inputProps={{
            type: "number",
            label: "Abb custom term",
            placeholder: "Enter your own term",
          }}
        />

        {!secondStep.isSpecifyRate && (
          <InterestInputField
            control={control}
            label="Buy Rate (%)"
            name="program.second.rate"
            textFieldProps={{
              required: true,
              fullWidth: true,
              disabled: isReadOnly,
            }}
          />
        )}
      </Box>

      <Box>
        <SwitchField
          control={control}
          label="Specify rate per term"
          name="program.second.isSpecifyRate"
          disabled={isReadOnly || secondStep.rateType === FinanceProgramRateType.RateFactor}
        />
      </Box>

      {!!secondStep.isSpecifyRate && !!specifyRatesFields?.length && (
        <>
          {specifyRatesFields.map((field, index) => (
            <InterestInputField
              key={field.id}
              control={control}
              label={`Buy Rate ${secondStep.terms?.[index] ?? ""} months (%)`}
              name={`program.second.specifyRates.${index}.rate`}
              textFieldProps={{
                required: true,
                fullWidth: true,
                disabled: isReadOnly,
              }}
            />
          ))}
        </>
      )}

      <RadioGroupField
        row
        label="Structure"
        control={control}
        options={structureOptions}
        name="program.second.structure"
        formControlProps={{
          disabled: isReadOnly,
        }}
      />

      {!!secondStep.structure && structurePartByType[secondStep.structure]}

      <Box className="formRow">
        <SelectField
          required
          control={control}
          label="Program Type"
          disabled={isReadOnly}
          options={programTypeOptions}
          name="program.second.programType"
        />

        <InputField
          control={control}
          label="Advance Payment (# of)"
          name="program.second.advancePayment"
          inputProps={{
            type: "number",
            required: true,
            disabled: isReadOnly,
            placeholder: "Enter value",
          }}
        />

        <InputField
          control={control}
          label="Days to First Payment"
          name="program.second.daysToFirstPayment"
          inputProps={{ type: "number", required: false, disabled: isReadOnly }}
          extraAction={{
            cb: nextValue => {
              if (!!+nextValue) {
                setValue("program.second.paymentCalculationMethod", PaymentPeriodTiming.BeginningOfPeriod);
              }
            },
          }}
        />
      </Box>

      <Box className="formRow">
        <Tooltip
          arrow
          title={!isDaysToFirstPaymentGTZero ? "" : "To Calculate in Arrears, set Days to First Payment to 0"}>
          <span style={{ width: "100%" }}>
            <SelectField
              required
              control={control}
              label="Payment Calculation Method"
              options={paymentCalculationMethodOptions}
              name="program.second.paymentCalculationMethod"
              disabled={isReadOnly || isDaysToFirstPaymentGTZero}
            />
          </span>
        </Tooltip>

        {secondStep.programType === FinanceProgramType.Lease && (
          <InterestInputField
            control={control}
            label="Residual %"
            name="program.second.residual"
            textFieldProps={{
              fullWidth: true,
              required: true,
              disabled: isReadOnly,
            }}
          />
        )}
      </Box>

      <SwitchField
        control={control}
        disabled={isReadOnly}
        label="Validity Dates"
        name="program.second.isValidityDates"
      />

      {secondStep.isValidityDates && (
        <Box className="formRow">
          <DateField
            required
            control={control}
            label="Start Date"
            disabled={isReadOnly}
            format={DateFormats.Short}
            name="program.second.startDate"
          />

          <DateField
            required
            label="End Date"
            control={control}
            disabled={isReadOnly}
            format={DateFormats.Short}
            name="program.second.endDate"
            minDate={secondStep.startDate ? moment(secondStep.startDate) : moment()}
          />
        </Box>
      )}

      <Box>
        {!!fields?.length &&
          fields.map((field, index) => (
            <Box key={field.id}>
              <Box className="arrayField">
                <Box className="arrayFieldHeader">
                  <IconButton
                    size="small"
                    disabled={isReadOnly}
                    className="customIconBtn"
                    onClick={() => remove(index)}>
                    <DeleteOutline color="error" />
                  </IconButton>

                  <Typography component="span" variant="subtitle2">
                    Fee: {index + 1}
                  </Typography>
                </Box>

                <Box className="formRow">
                  <InputField
                    control={control}
                    label="Description"
                    name={`program.second.fee.${index}.description`}
                    inputProps={{
                      disabled: isReadOnly,
                    }}
                  />

                  <CurrencyInputField
                    control={control}
                    label="Fee Amount"
                    name={`program.second.fee.${index}.amount`}
                    textFieldProps={{
                      required: true,
                      fullWidth: true,
                      disabled: isReadOnly,
                    }}
                  />
                </Box>
              </Box>

              <SwitchField
                label="Financed?"
                control={control}
                disabled={isReadOnly}
                name={`program.second.fee.${index}.isFinanced`}
              />
            </Box>
          ))}
      </Box>

      <Box>
        <Button
          disabled={isReadOnly}
          startIcon={<AddCircleOutline />}
          onClick={() => append({ amount: 0, description: "", isFinanced: false })}>
          Add Fee
        </Button>
      </Box>
    </>
  );
};
