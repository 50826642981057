import React, { useContext, useMemo, useState } from "react";
import cx from "classnames";
import { Redirect, Route, Switch } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import makeStyles from "@mui/styles/makeStyles";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
/* import Sidebar from "components/Sidebar/Sidebar.js" */
import { SidebarV2 } from "components/Sidebar/SidebarV2";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { ErrorHandler } from "ErrorHandler";
import { portalConfigurationsSelectors } from "../redux/portalConfigurationReducer";
import { useViewTypeContext } from "contexts/contentViewType";
import { useRoutes } from "hooks/useRoutes";
import { CommonDataContext } from "contexts/CommonDataContext";

//@ts-ignore
const useStyles = makeStyles(styles);

export const DashboardV2 = () => {
  const classes = useStyles();

  const { isDealerUser, isLenderUser, isCommonPartner, isAftermarketPartner } = useContext(CommonDataContext);
  const userProfile = useSelector((state: any) => state.userProfile);

  const { currentRouteConfig, availableAppRoutes, availalbeUserRoutes } = useRoutes();
  const { isMobile } = useViewTypeContext();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [mobActive, setMobActive] = useState(false);

  const mainPanelClasses = `${classes.mainPanel} ${cx({
    [classes.mainPanelSidebarMini]: miniActive,
  })}`;

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const sidebarMobActive = () => {
    setMobActive(!mobActive);
  };

  const availableRoutes = [...availableAppRoutes, ...availalbeUserRoutes];

  const routeToRedirect =
    useMemo(() => {
      if (isAftermarketPartner) return "/aftermarket";
      if (isDealerUser) return "/vo";
      if (isLenderUser) return "/cs";
    }, [isDealerUser, isLenderUser, isAftermarketPartner]) ?? "";

  return (
    <div
      className={classes.wrapper}
      style={isMobile ? { paddingBottom: "env(safe-area-inset-bottom)", height: "100svh" } : {}}>
      <SidebarV2 />
      <ErrorHandler>
        <div className={mainPanelClasses}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            sidebarMobActive={sidebarMobActive.bind(this)}
            miniActive={miniActive}
            mobActive={mobActive}
            brandText={currentRouteConfig?.name}
            routeType={currentRouteConfig?.type}
            handleDrawerToggle={handleDrawerToggle}
            fastTrackOption={currentRouteConfig?.fastTrackOption}
            isMobile={isMobile}
            /* portalConfigurations={portalConfigurations} */
            /* {...props} */
          />

          <div className={classes.content} style={isMobile ? { padding: 0 } : {}}>
            <div
              className={classes.container}
              style={isMobile && window ? { padding: 0, paddingTop: 32, height: "calc(100svh-40px)" } : {}}>
              <Switch>
                <Redirect exact from="/" to={routeToRedirect} />
                {/* if authorized user clicks welcome link from email */}
                <Redirect exact from="/welcome" to={routeToRedirect} />

                {availableRoutes.map(route => (
                  <Route key={route.id} path={route.path} component={route.component} exact={route.exact} />
                ))}
              </Switch>
            </div>
          </div>
          <Footer fluid />
        </div>
      </ErrorHandler>
    </div>
  );
};
