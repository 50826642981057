import { SimpleTextCell } from "components/Table/Cells/SimpleTextCell";
import {
  AccountIntegrationSettings,
  IntegrationSettingsDealerNumberByLocationForm,
  IntegrationSettingsFormInputs,
} from "modules/aftermarketPartner/types";
import { Column } from "react-table";
import { InputField } from "components/form";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { aftermarketPartnerSelectors } from "modules/aftermarketPartner/model";

export function createTableColumns(): Column<IntegrationSettingsDealerNumberByLocationForm>[] {
  const integrationSettings = useSelector(
    aftermarketPartnerSelectors.integrationSettings
  ) as AccountIntegrationSettings[];
  const locationIntegrationSettings = integrationSettings?.find(is => is.level === "location");

  const { control } = useFormContext<IntegrationSettingsFormInputs>();

  return [
    {
      Header: "Location",
      accessor: "location.locationName",
      Cell: ({ original }: any) => <SimpleTextCell value={original.location.locationName} />,
    },
    {
      sortable: false,
      Header: locationIntegrationSettings!.displayName,
      Cell: ({ original, index }: any) => (
        <InputField
          key={original.location.locationId}
          control={control}
          label={locationIntegrationSettings!.displayName}
          name={`dealerNumberByLocation.${index}.dealerNumber`}
        />
      ),
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
