import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import ReactTable, { Column, TableProps } from "react-table";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { usePagination } from "../../../../hooks/usePagination";
import { hubSearchFilterFoTable } from "../../lib/hubSearchFilters";
import { CustomPagination } from "../../../../components/Table/Pagination";

interface Props<TableData> extends Partial<Omit<TableProps<TableData>, "data">> {
  minHeight?: string;
  noData?: ReactNode;
  tableData: TableData[];
  withPagination?: boolean;
  columns: Column<TableData>[];
}

export const TableView = <TableData extends any>({
  noData,
  columns,
  loading,
  tableData,
  getTrProps,
  minRows = 5,
  loadingText,
  minHeight = "",
  SubComponent,
  withPagination = true,
}: Props<TableData>) => {
  const classes = useStyles({ minHeight });

  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const paginationProps = usePagination(filteredData.length);

  useEffect(() => {
    setFilteredData(tableData);
    paginationProps.onPageChange(0);

    return () => {
      setFilteredData([]);
    };
  }, [tableData]);

  return (
    <ReactTable<TableData>
      minRows={minRows}
      loading={loading}
      columns={columns}
      getTrProps={getTrProps}
      SubComponent={SubComponent}
      showPaginationBottom={withPagination}
      data={loading ? [] : filteredData}
      className={clsx("-highlight", classes.table)}
      PaginationComponent={withPagination ? CustomPagination : undefined}
      onFilteredChange={newFiltering => {
        setFilteredData(hubSearchFilterFoTable(tableData, newFiltering));
        paginationProps.onPageChange(0);
      }}
      loadingText={
        <Typography component="span" variant="h6">
          {loadingText ?? "Data loading..."}
        </Typography>
      }
      noDataText={
        !loading && (
          <Typography component="span" variant="h6">
            {noData ?? "No data"}
          </Typography>
        )
      }
      {...(withPagination && paginationProps)}
    />
  );
};

const useStyles = makeStyles<Theme, { minHeight: string }>({
  table: {
    minHeight: ({ minHeight }) => minHeight,

    "& .-loading > div": {
      color: "black",
    },

    "& .rt-noData": {
      top: "50%",
      left: "50%",
      color: "black",
      transform: "translate(-50%, -50%)",
    },

    "& .customHeader": {
      margin: 0,
    },

    "& .customHeader > div": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      fontFamily: "Roboto",
    },

    "& .customHeader > div::after": {
      color: "black",
      fontWeight: 500,
    },
  },
});
