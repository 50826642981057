import React, { Dispatch, KeyboardEvent, SetStateAction, useState } from "react";
import { Controller } from "react-hook-form";
import { SelectOption } from "global";
import { SelectProps } from "@mui/material/Select";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FormControlProps, IconButton, InputAdornment, InputProps } from "@mui/material";
import { FormInput } from "../FormInput";
import { MultiSelect } from "../MultiSelect";

enum RenderTypes {
  Select,
  Input,
}

const enterKeyCode = 13;
const otherSelectValue = "other" as const;
const otherSelectOption: SelectOption = { label: "Other", value: otherSelectValue };

interface OnInputChangedOptions {
  currentOptions: SelectOption[];
  onChangeOptions: Dispatch<SetStateAction<SelectOption[]>>;
  onAddCustomOption: (currentSelectValues: string[]) => void;
}

interface Props<FormValues extends FieldValues = FieldValues> {
  options: SelectOption[];
  isOptionsSorted?: boolean;
  control: Control<FormValues>;
  onCustomOptionAdded?: () => void;
  selectFormControlProps?: FormControlProps;
  name: UseControllerProps<FormValues>["name"];
  onChange: (selected: (string | number)[]) => void;
  selectProps?: Omit<SelectProps, "onChange" | "value">;
  inputProps?: Omit<InputProps, "onChange" | "onKeyDown" | "ref" | "InputProps" | "onKeyUp"> & {
    label?: string;
    onInputChanged?: (value: string, options: OnInputChangedOptions) => void;
  };
}

export const MultiSelectWithCustomOptions = <FormValues extends FieldValues = FieldValues>({
  name,
  control,
  options,
  onChange,
  selectProps = {},
  onCustomOptionAdded,
  isOptionsSorted = false,
  selectFormControlProps = {},
  inputProps = {},
}: Props<FormValues>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [renderComponent, setRenderComponent] = useState<RenderTypes>(RenderTypes.Select);

  const [dynamicOptions, setDynamicOptions] = useState<SelectOption[]>([...options, otherSelectOption]);

  const isDynamicInputValueExist = dynamicOptions.map(({ value }) => value).includes(inputValue);
  const isAddActionDisabled = !inputValue;

  const handleChange = (selected: string[]) => {
    if (selected.includes(otherSelectValue)) {
      setRenderComponent(RenderTypes.Input);

      onChange(selected.filter(value => value !== otherSelectValue));
    } else onChange(selected);
  };

  const handleAddCustomOption = (currentSelectValues: string[]) => {
    if (isDynamicInputValueExist) {
      handleChange([...currentSelectValues, inputValue]);
    } else {
      setDynamicOptions(prev => {
        const nextOptionsValues = [...prev, { label: inputValue, value: inputValue }].filter(
          ({ value }) => value !== otherSelectValue
        );

        const nextSelectValues = [...currentSelectValues, inputValue];

        if (isOptionsSorted) {
          nextSelectValues.sort((a, b) => Number(a) - Number(b));
          nextOptionsValues.sort((a, b) => Number(a.value) - Number(b.value));
        }

        handleChange(nextSelectValues);

        return [...nextOptionsValues, otherSelectOption];
      });
    }

    onCustomOptionAdded?.();
    setInputValue("");
    setRenderComponent(RenderTypes.Select);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, currentSelectValues: string[]) => {
    if (isAddActionDisabled) return;

    if (event.keyCode === enterKeyCode && !event.shiftKey) {
      event.preventDefault();

      handleAddCustomOption(currentSelectValues);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentSelectValues: string[]
  ) => {
    if (isAddActionDisabled) {
      setInputValue("");
      setRenderComponent(RenderTypes.Select);
    } else {
      event.preventDefault();
      handleAddCustomOption(currentSelectValues);
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    inputProps?.onInputChanged?.(value, {
      currentOptions: dynamicOptions,
      onChangeOptions: setDynamicOptions,
      onAddCustomOption: handleAddCustomOption,
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          {renderComponent === RenderTypes.Select ? (
            <MultiSelect
              options={dynamicOptions}
              {...field}
              error={!!fieldState.error}
              formControlProps={selectFormControlProps}
              helperText={fieldState.error ? fieldState.error.message : ""}
              onChange={selected => handleChange(selected.map(String))}
              {...selectProps}
            />
          ) : (
            <FormInput
              autoFocus
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={event => {
                handleKeyDown(event, field.value);
              }}
              onBlur={event => {
                handleBlur(event, field.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleAddCustomOption(field.value)}
                      disabled={isAddActionDisabled}
                      size="large">
                      <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...inputProps}
            />
          )}
        </>
      )}
    />
  );
};
