import React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export function PrescreenManagementTypeLabel() {
  return (
    <Grid container>
      <Typography style={{ marginRight: 4 }}>Module Access (View Only Controls)</Typography>
      <Tooltip
        title="These settings are for view access only. To enable or disable the module, visit the Module itself to Save the latest Configuration."
        placement="top">
        <InfoIcon color="primary"></InfoIcon>
      </Tooltip>
    </Grid>
  );
}
