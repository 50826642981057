import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import makeStyles from "@mui/styles/makeStyles";
import { vendorContactRoleHumanReadable } from "../../../utils";
import _ from "lodash";
import { LinearProgress, Modal } from "@mui/material";
import { UPDATE_TASK, UPSERT_TASK } from "../queries";
import { TaskTable } from "./table/TaskTable";
import { SendReminderDialog } from "./modals/SendReminderDialog";
import TableSearch from "../../Table/TableSearch";
import TableSelect from "../../Table/TableSelect";
import TableHeader from "components/Table/TableHeader";
import moment from "moment/moment";
import DealerTaskForm from "../../DealerTaskForm";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { ContentViewType } from "../../../global";
import { EntityMobileCards } from "../../shared/EntityMobileCards";
import { Task } from "@trnsact/trnsact-shared-types";
import { TasksMobileEntity } from "../types";
import { CardContainer } from "../../shared/CardContainer/CardContainer";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { mobileEntityAdapter } from "../lib/mobileEntityAdapter";

const defaultStatusOptions = [
  { value: "open", text: "Open" },
  { value: "complete", text: "Complete" },
  { value: "in_progress", text: "In Progress" },
  { value: "cancel", text: "Cancel" },
  { value: "requested", text: "Requested" },
];

const defaultPriorityOptions = [
  { value: "high", text: "High" },
  { value: "medium", text: "Medium" },
  { value: "low", text: "Low" },
];

const defaultOptions = {
  createdBy: [],
  assignedTo: [],
  status: defaultStatusOptions,
  priority: defaultPriorityOptions,
};

export const TaskList = (props: any) => {
  const {
    vo,
    account,
    queryTasks,
    queryTasksOptions,
    tasksQueryKey,
    addTaskFlag,
    userProfile,
    tileTitle,
    refetchDocs,
    relatedUsers,
    creditSubId,
    onTasksLoaded,
    conversations,
    documentsPortalConfiguration,
    voTasks,
    lenderProfileDynamicsId,
    documents,
  } = props;

  const classes = useStyles();

  const { contentViewType } = useViewTypeContext();

  const [openSendReminderPopup, setOpenSendReminderPopup] = useState<any>(false);
  const [emailAddressReminder, setEmailAddressReminder] = useState<any>("");
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<any>(null);
  const [tasksData, setTasksData] = useState<any>(undefined);

  const [pageNumber, setPageNumber] = useState<any>(0);
  const [savedListSettings, setSavedListSettings] = useState<any>({ page: 0, pageSize: 20 });
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const statusRef = useRef({ prevStatus: undefined, currentSelect: "" });
  const priorityRef = useRef({ prevRole: undefined, currentSelect: "" });

  const { data: tasksQueryData, refetch } = useQuery(queryTasks, queryTasksOptions);

  useEffect(() => {
    if (props.taskId) {
      setOpenTaskModal(true);
      setTaskId(props.taskId);
    }
  }, [props.taskId]);

  useEffect(() => {
    if (voTasks) {
      setTasksData(voTasks);
    } else {
      setTasksData(tasksQueryData);
    }
  }, [voTasks, tasksQueryData]);

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    context: { authRequired: true },
  });

  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
    setTaskId(null);
  };

  const handlSendReminderPopup = () => {
    setOpenSendReminderPopup(false);
  };

  const filterBySelectFields = (taskList: any) => {
    const { currentSelect: currentStatusSelect } = statusRef.current;
    const { currentSelect: currentPrioritySelect } = priorityRef.current;
    let newData = { ...taskList };

    if (currentStatusSelect?.length !== 0) {
      newData.vendorOpportunityTask = newData.vendorOpportunityTask.filter(
        (el: any) => el.status === currentStatusSelect
      );
    }

    if (currentPrioritySelect?.length !== 0) {
      newData.vendorOpportunityTask = newData.vendorOpportunityTask.filter(
        (el: any) => el.priority === currentPrioritySelect
      );
    }

    return newData;
  };

  const filterTasks = (taskListData: any) => {
    const { currentSearch } = searchRef.current;

    taskListData = filterBySelectFields(taskListData);

    if (currentSearch.length === 0) {
      return taskListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    taskListData.vendorOpportunityTask = taskListData.vendorOpportunityTask.filter((el: any) => {
      if (
        typeof el.createdDateTime === "string" ||
        typeof el.createdBy === "string" ||
        typeof el.assignedToUser === "string" ||
        typeof el.regarding === "string"
      ) {
        return (
          moment(el.createdDateTime).format("ll").toLowerCase().includes(lowerCaseSearch) ||
          (el.createdBy || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.assignedToUser || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.regarding || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return { ...taskListData };
  };

  const handleAddTask = async (payload: any, refetch: any) => {
    try {
      await upsertTask({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveTask = async (payload: any, refetch: any) => {
    try {
      await updateTask({
        variables: payload,
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const isAdmin = (userProfile: any) =>
    userProfile.vendorContactRole == vendorContactRoleHumanReadable.credit_mgr ||
    userProfile.vendorContactRole == vendorContactRoleHumanReadable.sales_mgr;

  const processTasks = (tasksData: any) => {
    let tasks: any = [];

    if (!!tasksData && !!tasksData[tasksQueryKey]) {
      let loadedTasks = tasksData[tasksQueryKey];
      onTasksLoaded({ loading: false, tasks: loadedTasks });
      loadedTasks = loadedTasks.map((task: any) => ({
        ...task,
        createdDateMilliseconds: new Date(task.createdDateTime.replace(/\..+/, "")).getTime(),
      }));
      const userLoggedIsAdmin = isAdmin(userProfile);

      if (tasksQueryKey == "consolidatedTask" || tasksQueryKey == "vendorOpportunityTask" || userLoggedIsAdmin) {
        tasks = [...loadedTasks];
      } else {
        const filterTasks = _.filter(loadedTasks, task => {
          return task.assignedToUserProfileId == userProfile.id || task.assignedByUserProfileId == userProfile.id;
        });
        tasks = [...filterTasks];
      }
    }

    return tasks;
  };

  const renderByViewType: Record<ContentViewType, (tasks: any, options: any, loading: boolean) => ReactNode> = {
    [ContentViewType.Mobile]: tasks => (
      <EntityMobileCards<Task, TasksMobileEntity>
        entities={tasks}
        entityAdapter={mobileEntityAdapter}
        actionsPanel={({ id }) => (
          <IconButton
            size="small"
            onClick={() => {
              setTaskId(id);
              setOpenTaskModal(true);
            }}>
            <EditIcon color="primary" />
          </IconButton>
        )}
      />
    ),
    [ContentViewType.Desktop]: (tasks, options, loading) => (
      <>
        {tasks && (
          <TaskTable
            tasks={tasks}
            isTableDataLoading={loading}
            onRowClick={taskId => {
              setTaskId(taskId);
              setOpenTaskModal(true);
            }}
          />
        )}
      </>
    ),
  };

  return (
    <div className={classes.root}>
      {!!account && (
        <Query
          context={queryTasksOptions.context}
          variables={queryTasksOptions.variables}
          query={queryTasks}
          fetchPolicy="no-cache">
          {({ loading, error, data: tasksData, refetch: refetchTasksData }: any) => {
            if (loading) return <LinearProgress />;
            if (error) return <h3>{String(error)}</h3>;

            let options = defaultOptions;

            const tasks = processTasks(filterTasks(tasksData));
            const createdByOptions: any = [];
            const assignedToOptions: any = [];

            if (tasksData && tasksData.consolidatedTask) {
              tasksData.consolidatedTask.map((task: any) => {
                if (!createdByOptions.find((option: any) => task.createdBy === option.value) && task.createdBy) {
                  createdByOptions.push({ value: task?.createdBy, text: task?.createdBy });
                }
                if (
                  !assignedToOptions.find((option: any) => task.assignedToUser === option.value) &&
                  task.assignedToUser
                ) {
                  assignedToOptions.push({ value: task?.assignedToUser, text: task?.assignedToUser });
                }
              });
            }

            options = { ...options, createdBy: createdByOptions, assignedTo: assignedToOptions };

            return (
              <>
                <CardContainer
                  title={tileTitle ?? "Tasks"}
                  contentViewType={contentViewType}
                  actionBtnProps={{
                    label: "ADD TASK",
                    disabled: !addTaskFlag,
                    onClick: () => {
                      setTaskId(null);
                      setOpenTaskModal(true);
                    },
                  }}>
                  <TableHeader
                    filterComps={
                      <>
                        <TableSearch
                          width="100%"
                          setPageNumber={setPageNumber}
                          setSavedListSettings={setSavedListSettings}
                          searchRef={searchRef}
                          savedListSettings={savedListSettings}
                        />
                        <TableSelect
                          width="100%"
                          setPageNumber={setPageNumber}
                          setSavedListSettings={setSavedListSettings}
                          selectRef={statusRef}
                          savedListSettings={savedListSettings}
                          id="status"
                          label="Status"
                          options={options.status}
                        />
                        <TableSelect
                          width="100%"
                          setPageNumber={setPageNumber}
                          setSavedListSettings={setSavedListSettings}
                          selectRef={priorityRef}
                          savedListSettings={savedListSettings}
                          id="priority"
                          label="Priority"
                          options={options.priority}
                        />
                      </>
                    }
                  />

                  {renderByViewType[contentViewType](tasks, options, loading)}
                </CardContainer>

                <SendReminderDialog
                  isOpen={openSendReminderPopup}
                  onClose={handlSendReminderPopup}
                  emailAddressReminder={emailAddressReminder}
                />

                <Modal
                  open={openTaskModal}
                  onClose={handleCloseTaskModal}
                  aria-labelledby="Task Form"
                  aria-describedby="Input"
                  disableEnforceFocus={true}>
                  <div>
                    <DealerTaskForm
                      vo={vo}
                      handleClose={handleCloseTaskModal}
                      handleAddTask={(payload: any) => handleAddTask(payload, refetchTasksData)}
                      handleSaveTask={(payload: any) => handleSaveTask(payload, refetchTasksData)}
                      taskId={taskId}
                      tasksData={tasks}
                      creditSubId={creditSubId}
                      refetchDocs={refetchDocs}
                      relatedUsers={relatedUsers}
                      conversations={(conversations || []).filter((conv: any) => conv.taskId === taskId)}
                      documentsPortalConfiguration={documentsPortalConfiguration}
                      lenderProfileDynamicsId={lenderProfileDynamicsId}
                      documents={documents}
                    />
                  </div>
                </Modal>
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInput-underline:after": {
      borderColor: theme.palette.primary.main,
    },
    marginBottom: "16px",
  },
  right: {
    textAlign: "right",
  },
  addButtonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 300,
    fontSize: "12px",
    boxShadow: "0px 2px 2px rgba(55, 133, 244, 0.25)",
    borderRadius: "3px",
    width: "120px",
  },
  noDataStyles: {
    display: "block",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.8)",
    transition: "all 0.3s ease",
    zIndex: 1,
    pointerEvents: "none",
    padding: "20px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  saveBtn: {
    marginTop: "25px",
  },
  margin: {
    row: {
      height: "20px",
    },
  },
  surface: {
    position: "absolute",
    minWidth: 700,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 700,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
}));
