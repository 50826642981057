import { SelectOption } from "global";
import { FACT_NAMES_BOOLEAN, FACT_NAMES_DATE, FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import NTP_LOGO from "../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "../../../assets/img/aftermarket-vendor-logos/gaig-logo.png";
import {
  AftermarketCatalogDetailsKeys,
  AftermarketProductTypes,
  CatalogDetailsToRenderAsLinkConfig,
  Equipment,
  Fact,
  PrisingConfigByVendor,
  ShowTypes,
} from "../types";

export const catalogDetailsKeysToRenderAsSection = [
  AftermarketCatalogDetailsKeys.ProductSummary,
  AftermarketCatalogDetailsKeys.ParagraphDescription,
  AftermarketCatalogDetailsKeys.CoverageDetails,
];

export const catalogDetailsToRenderAsLinkConfig: CatalogDetailsToRenderAsLinkConfig[] = [
  {
    key: AftermarketCatalogDetailsKeys.EligibilityAndInclusions,
    label: "Eligibility Requirements",
  },
  {
    key: AftermarketCatalogDetailsKeys.LimitationsOfLiability,
    label: "Limitations of Liability",
  },
  {
    key: AftermarketCatalogDetailsKeys.LegalDisclaimer,
    label: "Legal Disclaimer",
  },
];

export const vendorsLogoByType: Partial<Record<AftermarketProductTypes, string>> = {
  [AftermarketProductTypes.Ntp]: NTP_LOGO,
  [AftermarketProductTypes.Gaig]: GAIG_LOGO,
};

export const proposalProductsFiltersOptions: SelectOption<ShowTypes>[] = [
  { label: "All", value: ShowTypes.All },
  { label: "Service Contract", value: ShowTypes.ServiceContract },
  { label: "Insurance", value: ShowTypes.Insurance },
  { label: "GAP", value: ShowTypes.GAP },
  { label: "Roadside", value: ShowTypes.Roadside },
  { label: "None", value: ShowTypes.None },
];

export const dynamicFieldErrorText = "Value not contained in general configuration";
export const dealerCostInfoText = "Dealer Cost will be calculated dynamically when a unit price is provided.";

// All facts object
export const allFacts = {
  ...FACT_NAMES_BOOLEAN,
  ...FACT_NAMES_NUMERIC,
  ...FACT_NAMES_DATE,
  ...FACT_NAMES_STRING,
};

// Arr for exclude facts from common and products dynamic fields. Expand it if necessary remove dynamic field from configuration.
export const factsExcludeForCheck: Fact[] = [FACT_NAMES_NUMERIC.DEAL_SIZE];

export const factsExcludeForMenuConstructorCheck: Fact[] = [
  FACT_NAMES_STRING.EQUIPMENT_MAKE,
  FACT_NAMES_STRING.VIN,
  FACT_NAMES_NUMERIC.EQUIPMENT_YEAR,
  FACT_NAMES_STRING.EQUIPMENT_CLASS,
  FACT_NAMES_STRING.EQUIPMENT_TYPE,
  FACT_NAMES_NUMERIC.ODOMETER_MILEAGE,
  FACT_NAMES_STRING.EQUIPMENT_CONDITION,
];

// Mapping Equipment entity key to facts.
// If need make field in Equipment section to Dynamic field.
// - Add mapping here
// - Add extra action in Equipment.tsx file. Action should dispatch value to common config.
// - If need updated Text field to Selector field. Do it in Equipment.tsx file and create options for field in selectors.tsx.
export const factsEquipmentEntity: Record<keyof Equipment, Fact> = {
  make: FACT_NAMES_STRING.EQUIPMENT_MAKE,
  serialNumberVin: FACT_NAMES_STRING.VIN,
  year: FACT_NAMES_NUMERIC.EQUIPMENT_YEAR,
  classType: FACT_NAMES_STRING.EQUIPMENT_CLASS,
  mileage: FACT_NAMES_NUMERIC.ODOMETER_MILEAGE,
  newUsed: FACT_NAMES_STRING.EQUIPMENT_CONDITION,
};

// Arr for exclude facts from render. Just hide this form UI. All configuration will be workable for it.
export const hideFieldsForRender: Fact[] = Object.values(factsEquipmentEntity);

// Arr for render dynamic fields. It will be filtered by certain (selected or from menu) products.
export const factsExcludeEquipmentEntity = Object.values(allFacts).reduce<Fact[]>((result, fact) => {
  if (!Object.values(factsEquipmentEntity).includes(fact) && !factsExcludeForCheck.includes(fact)) {
    result.push(fact);
  }
  return result;
}, []);

export const equipmentValuesNormalizerToFactValues: Record<Fact, (value: string) => string> = {
  [FACT_NAMES_STRING.EQUIPMENT_CONDITION]: value => value.toUpperCase(),
  [FACT_NAMES_STRING.EQUIPMENT_MAKE]: value =>
    value.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
};

export const prisingConfigByVendor: Partial<PrisingConfigByVendor> = {
  [AftermarketProductTypes.Ntp]: {
    isShowRate: false,
  },
  [AftermarketProductTypes.Gaig]: {
    isShowRate: false,
  },
};
