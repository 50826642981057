import React, { ReactNode } from "react";
import { FieldNotify, SelectOption } from "global";
import makeStyles from "@mui/styles/makeStyles";
import { FormNotificationBlock } from "./FormNotificationBlock";
import { Box, CircularProgress, InputAdornment, MenuItem, TextField, TextFieldProps } from "@mui/material";

interface FormInputSelectProps<
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>,
> extends Omit<TextFieldProps, "select" | "onChange"> {
  label?: ReactNode;
  options?: Options[];
  isLoading?: boolean;
  notify?: FieldNotify;
  onChange: (value: ValueType) => void;
}

export const FormInputSelect = <
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>,
>({
  label,
  value,
  error,
  options,
  onChange,
  isLoading,
  helperText,
  notify,
  ...rest
}: FormInputSelectProps<ValueType, Options>) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    event.stopPropagation();

    onChange(event.target.value as ValueType);
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "100%" }}>
      <TextField
        select
        fullWidth
        size="small"
        label={label}
        value={value}
        error={error}
        variant="outlined"
        onChange={handleChange}
        className={classes.requiredInput}
        helperText={error ? helperText : ""}
        disabled={isLoading || !options?.length || rest?.inputProps?.disabled}
        {...rest}
        slotProps={{
          input: {
            endAdornment: isLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
          },
          select: {
            ref: rest.inputRef,
            MenuProps: {
              style: { maxHeight: "500px" },
            },
            IconComponent: isLoading ? () => null : undefined,
          },
        }}>
        {options?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {notify && <FormNotificationBlock {...notify} />}
    </Box>
  );
};

const useStyles = makeStyles({
  requiredInput: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
});
