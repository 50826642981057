import { gql } from "@apollo/client";

export const UPDATE_COMPANY_PROFILE_VP = `
    mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput!, $file: Upload) {
      updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj, file: $file) {
        id
      }
    }
  `;

export const UPDATE_COMPANY_PROFILE_LP = `
mutation($entityId: String!, $fieldsObj: UpdateLenderProfileInput!, $file: Upload) {
  updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj, file: $file) {
    id
  }
}
`;

export const GET_REP_LIST = gql`
  query {
    salesRepsForAdmin {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
      lastLogin
      invitationDatetime
      status
    }
  }
`;

export const M_UPDATE_VP = gql`
  mutation ($entityId: String!, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

export const M_UPDATE_LP = gql`
  mutation ($entityId: String!, $fieldsObj: UpdateLenderProfileInput!) {
    updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

export const UPDATE_COMPANY_INFO = gql`
  mutation ($dynamicsId: ID, $fieldsObj: UpdateAccountFields) {
    updateAccount(dynamicsId: $dynamicsId, fieldsObj: $fieldsObj)
  }
`;

export const UPDATE_COMPANY_INFO_CRM = gql`
  mutation ($entityId: ID!, $fieldsObj: UpdateAccountCRMFields) {
    updateAccountCRM(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;
