import React, { useCallback, useEffect, useState } from "react";
import { DCR_API_BASE_SERVER_URL } from "../../config";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Tile from "../../components/Tile/Tile";
import NewCreditAppModal from "./NewCreditAppModal";
import CADMv2 from "./CADMv2";
import { formatCurrency } from "../../utils";
import Table from "../../components/Table/Table";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import EquipmentInputModal from "../../components/EquipmentInputModal";
import CreditSafeComp from "components/CreditSafeComp";
import { OpportunityTypes } from "../../utils";
import { FINANCING_TYPE } from "@trnsact/trnsact-shared-types";
import { theme } from "../../theme";

import { GuarantorListWithCreditPull } from "components/GuarantorListWithCreditPull";

const useStyles = makeStyles(theme => ({
  container: {
    "&  .MuiButton-text": {
      marginLeft: "10px",
    },
  },
}));

function CustomerSummaryCard({
  vo,
  refetchVO,
  vp,
  account,
  equipmentData,
  refetchEquipment,
  equipmentLoading,
  invoiceEquipmentTabPage,
  userProfile,
  refetchCreditApp,
  creditApp,
  userDocs,
  userDocsRefetch,
  portalConfiguration,
  portalConfigurationDataLoading,
  isCADMEditingDisabled,
  isCreditReportsHiddenForUserRole,
  refetchTasks,
  tasksData,
  refetchDocs,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [creditAppModal, setCreditAppModal] = useState();
  const [openCreditAppModal, setOpenCreditAppModal] = useState(false);
  const [openCADMv2, setOpenCADMv2] = useState(false);
  const [ownerToScrollTo] = useState(false);
  const [doneRefetchingCreditApp, setDoneRefetchingCreditApp] = useState(false);
  const [doneRefetchingDocuments, setDoneRefetchingDocuments] = useState(false);
  const [documentIdToAutomaticallyOpen, setDocumentIdToAutomaticallyOpen] = useState(null);
  const [openEquipmentInputModal, setOpenEquipmentInputModal] = useState(false);
  const [equipmentId, setEquipmentId] = useState(null);
  const [pdfData, setPDFData] = useState();

  let creditReportAlertComponent = null;

  const getPDFRequestData = useCallback(async () => {
    const url = `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`;
    const data = {
      vendorProfile: vp,
      account: { account, userProfile },
      userProfile: userProfile,
      download: false,
    };
    const config = {
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
      },
    };
    return axios
      .post(url, data, config)
      .then(response => response.data)
      .catch(error => console.log(error));
  }, [vp]);

  useEffect(() => {
    if (!pdfData) {
      return;
    }
    setCreditAppModal({ ...pdfData });
  }, [pdfData]);

  useEffect(() => {
    if (documentIdToAutomaticallyOpen && doneRefetchingDocuments) {
      setDoneRefetchingDocuments(false);
      if (documentIdToAutomaticallyOpen) {
        console.log(JSON.stringify(userDocs));
        const doc = userDocs.documents.find(d => d.documentId === documentIdToAutomaticallyOpen);
        console.log(JSON.stringify(doc));
        if (doc) {
          // TODO: This if statement is reached but window.open() is not working. Popup blocker?
          window.open(doc.source, "_newtab");
        }
        return;
      }
      setDocumentIdToAutomaticallyOpen(null);
    }
  });

  const fetchCreditAppDetails = async () => {
    const PDFRequestData = await getPDFRequestData();
    setPDFData(PDFRequestData);
  };

  const handleCreditAppRequest = () => {
    setOpenCreditAppModal(true);
    return fetchCreditAppDetails();
  };

  const handleOpenCreditReportDocument = (pgDetails, cbr) => {
    const reportDoc = userDocs.documents.find(d => d.documentId === cbr.humanReadableReportDocumentId);
    if (reportDoc) {
      window.open(reportDoc.source, "_newtab");
    }
    // return fetchCreditAppDetails();
  };
  const handleRefetchCreditAppRequest = () => {
    return fetchCreditAppDetails();
  };

  const openEquipmentInputModalFn = equipmentId => {
    setOpenEquipmentInputModal(true);
    setEquipmentId(equipmentId);
  };

  const handleCloseEquipmentInputModal = () => setOpenEquipmentInputModal(false);

  const handleClose = () => setOpenCreditAppModal(false);

  const creditAppDataLoaded = !!creditApp && Object.keys(creditApp).length;

  let customerSummaryTileData = {
    title: "Customer Summary",
    useCollapsing: true,
  };
  let customerSummaryTableData = [];

  const handleClick = (type, id) => {
    if (!type || !id) {
      return;
    }
    if (type === "account") {
      history.push(`/accountDetails/${id}`);
    }
    if (type === "contact") {
      history.push(`/ownerPgDetails/${id}`);
    }
  };

  if (creditAppDataLoaded || !_.isEmpty(vo)) {
    let contactName = "";

    if (!_.isEmpty(creditApp)) {
      if (
        creditApp.applicationType == "Commercial" ||
        creditApp.applicationType == "COMMERCIAL" ||
        creditApp.applicationType == "COMMERCIAL_WITH_GUARANTOR" ||
        creditApp.applicationType == "COMMERCIAL_WITH_MULTIPLE_GUARANTORS"
      ) {
        customerSummaryTableData.push([
          <BoldText text="Applicant" />,
          <div>
            <span
              style={{ color: theme.palette.primary.main, cursor: "pointer" }}
              onClick={() => handleClick("account", vo.potentialCustomer.id)}>
              {creditApp.businessName}
            </span>
          </div>,
        ]);

        if (
          (_.get(vp, "settingBusinessCreditReportPullInDealerPortal") === "PAYNET" ||
            _.get(vp, "settingBusinessCreditReportPullInDealerPortal") === "CREDITSAFE_FULL_REPORT") &&
          !isCreditReportsHiddenForUserRole
        ) {
          console.log("settingBusinessCreditReportPullInDealerPortal is set to PAYNET");
          customerSummaryTableData.push([
            <BoldText text="Paynet Report" />,
            <div>
              <CreditSafeComp
                vo={vo}
                creditApp={creditApp}
                userDocs={userDocs}
                openCreditPullErrorSnackBar={() => {
                  alert("Credit Pull Error");
                }}
                setCreditPullErrorSnackBarText={() => {
                  console.log("Credit Pull Error");
                }}
                userDocsRefetch={userDocsRefetch}
                refetchCreditApp={refetchCreditApp}
                CbrSoftPullTooltip={<div>Contact Support</div>}
                doneRefetchingCreditApp={doneRefetchingCreditApp}
                setDoneRefetchingDocuments={setDoneRefetchingDocuments}
                setDocumentIdToAutomaticallyOpen={setDocumentIdToAutomaticallyOpen}
              />
            </div>,
          ]);
        }
      } else {
        customerSummaryTableData.push([
          <BoldText text="Applicant" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleClick("account", vo.potentialCustomer.id)}>
            {creditApp.businessName ? creditApp.businessName : vo.potentialCustomer ? vo.potentialCustomer.name : ""}
          </span>,
        ]);
      }
    }

    if (!_.isEmpty(creditApp)) {
      if (!vp || portalConfigurationDataLoading) {
        customerSummaryTileData.titleButtons = [{ text: "Loading...", func: _.noop }];
      } else {
        customerSummaryTileData.titleButtons = _.some(portalConfiguration)
          ? [{ text: "Detail v2", func: () => setOpenCADMv2(true) }]
          : [{ text: "Detail", func: handleCreditAppRequest }];
      }
      contactName = creditApp.primaryContact
        ? creditApp.primaryContact.firstName + " " + creditApp.primaryContact.lastName
        : creditApp.firstName + " " + creditApp.lastName;
    } else {
      contactName = vo.accountPrimaryContactName;
    }

    function BoldText({ text }) {
      return <b>{text}</b>;
    }

    if (!_.isEmpty(creditApp)) {
      if (creditApp.applicationType) {
        if (
          creditApp.applicationType == "COMMERCIAL" ||
          creditApp.applicationType == "COMMERCIAL_WITH_GUARANTOR" ||
          creditApp.applicationType == "COMMERCIAL_WITH_MULTIPLE_GUARANTORS"
        ) {
          creditApp.applicationType = "Commercial";
        }
        if (creditApp.applicationType === "INDIVIDUAL") {
          creditApp.applicationType = "Individual";
        }
        if (creditApp.applicationType === "INDIVIDUAL_WITH_SECOND_GUARANTOR") {
          creditApp.applicationType = "Joint";
        }
        customerSummaryTableData.push([<BoldText text="Applicant Type" />, creditApp.applicationType]);
      }
      if (creditApp.applyingFor) {
        customerSummaryTableData.push([
          <BoldText text="Applying For" />,
          _(creditApp.applyingFor)
            .map(item => FINANCING_TYPE[item] || item)
            .join(", "),
        ]);
      }

      /* if (!_.isEmpty(creditApp)) contactName = `${creditApp.firstName} ${creditApp.lastName}`; */

      customerSummaryTableData.push([
        <BoldText text="Contact" />,
        <span
          style={{
            color: creditApp.primaryContact ? theme.palette.primary.main : null,
            cursor: creditApp.primaryContact ? "pointer" : null,
          }}
          onClick={() => handleClick("contact", creditApp.primaryContact ? creditApp.primaryContact.ownerPgId : null)}>
          {contactName}
        </span>,
      ]);

      if (creditApp.title && creditApp.title.trim() !== "") {
        customerSummaryTableData.push([<BoldText text="Title" />, creditApp.title]);
      }

      const phoneNumber =
        creditApp.phone ||
        creditApp.bizPhone ||
        creditApp.primaryContact?.phone ||
        creditApp.primaryContact?.mobilePhone ||
        creditApp.primaryContact?.homePhone;

      if (phoneNumber && phoneNumber.trim() !== "") {
        customerSummaryTableData.push([<BoldText text="Phone" />, phoneNumber]);
      }

      if (creditApp.email && creditApp.email.trim() !== "") {
        customerSummaryTableData.push([<BoldText text="Email" />, creditApp.email]);
      }

      // credit report pull start
      customerSummaryTableData.push([
        <BoldText text="Guarantor(s)" key={"guarantors-section"} />,
        `${creditApp?.creditApplicationOwner?.length} Personal Guarantor${
          creditApp?.creditApplicationOwner?.length === 1 ? "" : "s"
        }`,
      ]);

      const guarantorListWithCreditPull = GuarantorListWithCreditPull({
        vo,
        creditApp,
        vp,
        refetchCreditApp,
        handleOpenCreditReportDocument,
        isCreditReportsHiddenForUserRole,
        key: "guarantor-list-with-credit-pull",
      });

      guarantorListWithCreditPull.forEach(guarantorListItem => {
        if (_.isArray(guarantorListItem)) {
          customerSummaryTableData.push([guarantorListItem[0], guarantorListItem[1]]);
        } else {
          creditReportAlertComponent = guarantorListItem;
        }
      });

      customerSummaryTableData.push([<BoldText text="Type" />, OpportunityTypes[vo.entityType]]);
      customerSummaryTableData.push([<BoldText text="Amount" />, formatCurrency(vo.invoiceTotal)]);
    } else {
      customerSummaryTableData.push([<BoldText text="Contact" />, <span>{contactName}</span>]);
    }

    customerSummaryTableData.push([
      <BoldText text="Equipment Description" />,
      <div>
        {equipmentData && _.some(equipmentData.equipments) ? (
          _.map(equipmentData.equipments, item => {
            return (
              <a
                href="#"
                onClick={() => openEquipmentInputModalFn(item.equipmentId)}
                style={{ display: "inline-block", color: theme.palette.primary.main }}
                key={`${item.stockId}${item.make}${item.model}`}>
                {(item.quantity ? "Qty " + item.quantity : "") +
                  (item.newUsed ? ", " + item.newUsed : "") +
                  (item.make ? ", " + item.make : "") +
                  (item.model ? ", " + item.model : "") +
                  (item.serialNumberVin ? ", SN/VIN#" + item.serialNumberVin : "") +
                  (item.additionalDescription ? ", " + item.additionalDescription : "") +
                  (item.stockId ? ", " + item.stockId : "")}
              </a>
            );
          })
        ) : (
          <span>{_.get(vo, "equipmentDescription")}</span>
        )}
      </div>,
    ]);
  }

  if (!_.isEmpty(vo) && !!vo.contactFormNotes) {
    customerSummaryTableData.push([<BoldText text="Message to Dealer" />, <span>{vo.contactFormNotes}</span>]);

    function BoldText({ text }) {
      return <b>{text}</b>;
    }
  }
  if (!_.isEmpty(vo) && !!vo.partnerName && !vo.sourceUrl) {
    customerSummaryTableData.push([<BoldText text="Source" />, <span>{vo.partnerName}</span>]);

    function BoldText({ text }) {
      return <b>{text}</b>;
    }
  } else if (!_.isEmpty(vo) && !!vo.partnerName && !!vo.sourceUrl) {
    customerSummaryTableData.push([
      <BoldText text="Source" />,
      <span>
        {vo.partnerName}
        <br />
        <a href={vo.sourceUrl} target="_blank" rel="noreferrer">
          Source URL
        </a>
      </span>,
    ]);

    function BoldText({ text }) {
      return <b>{text}</b>;
    }
  }

  if (creditAppDataLoaded || !_.isEmpty(vo)) {
    return (
      <Box className={classes.container}>
        <Tile {...customerSummaryTileData}>
          <Table tableHeaderColor="primary" tableData={customerSummaryTableData} colorsColls={["primary"]} />
          {creditReportAlertComponent}
          <Modal
            open={openCreditAppModal}
            onClose={handleClose}
            aria-labelledby="Credit Application"
            aria-describedby="Summary">
            <>
              {!!creditAppModal && (
                <NewCreditAppModal
                  {...creditAppModal}
                  {...vo}
                  contactEmail={creditAppModal?.businessEmail}
                  ownerToScrollTo={ownerToScrollTo}
                  refetchCreditApp={refetchCreditApp}
                  softPullInDealerPortal={vp.softPullInDealerPortal}
                  handleRefetchCreditAppRequest={handleRefetchCreditAppRequest}
                />
              )}
            </>
          </Modal>

          <Modal
            open={openCADMv2}
            onClose={() => setOpenCADMv2(false)}
            aria-labelledby="Credit Application"
            aria-describedby="Summary">
            <>
              {portalConfiguration && (
                <CADMv2
                  vo={vo}
                  account={account}
                  refetchVO={refetchVO}
                  vendorProfile={vp}
                  equipmentData={equipmentData}
                  refetchEquipment={refetchEquipment}
                  creditAppModalConfig={portalConfiguration.jsonDefinition}
                  refetchTasks={refetchTasks}
                  vendorOpportunityTasks={tasksData?.vendorOpportunityTask}
                  isEditingDisabled={isCADMEditingDisabled}
                  refetchCreditAppOuter={refetchCreditApp}
                />
              )}
            </>
          </Modal>

          <Modal
            open={openEquipmentInputModal}
            onClose={handleCloseEquipmentInputModal}
            aria-labelledby="Equipment Input Modal"
            aria-describedby="Input">
            <div>
              {vp && equipmentId ? (
                <EquipmentInputModal
                  vo={vo}
                  creditApp={creditApp}
                  equipmentLoading={equipmentLoading}
                  equipmentData={equipmentData}
                  handleClose={handleCloseEquipmentInputModal}
                  refetchVO={refetchVO}
                  equipmentId={equipmentId}
                  refetch={refetchEquipment}
                  vendorProfile={vp}
                  refetchCreditApp={refetchCreditApp}
                  invoiceEquipmentTabPage={invoiceEquipmentTabPage}
                  refetchDocs={refetchDocs}
                  account={account}
                />
              ) : null}
            </div>
          </Modal>
        </Tile>
      </Box>
    );
  } else {
    return "";
  }
}

export default CustomerSummaryCard;
