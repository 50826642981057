import { useLazyQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Button from "components/CustomButtons/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "@apollo/client";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_OPEN,
  CHAT_V2_RESET,
  CHAT_V2_RESET_CONVERSATIONS,
  CHAT_V2_SELECTED_CONVERSATION_SID,
} from "../../redux/types";
import ChatWidgetV2 from "../../components/Chatv2";
import { vendorContactRoleHumanReadable } from "../../utils";
import iconPlusSvg from "assets/img/icons/plus.svg";
import iconMinusSvg from "assets/img/icons/minus.svg";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import Pagination from "../../pages/ChatPage/ChatTablePagination";
import mixpanel from "../../mixpanel";

// const Q_ALL_MESSAGES = gql`
//   query allMessagesForUser($userProfileId: ID, $limit: Int) {
//     allMessagesForUser(userProfileId: $userProfileId, limit: $limit) {
//       feedId
//       vendorOpportunityId
//       type
//       sentFromUserProfileId
//       sentToUserProfileId
//       sentFromAccountId
//       sentToAccountId
//       notes
//       relatedTo
//       topic
//       read
//       priority
//       createdBy
//       dynamicCreditSubmissionId
//       creditAppplicationId
//       createdDateTime
//       participants {
//         userProfileId
//         accountId
//         read
//       }
//       messages {
//         message
//       }
//       taskId
//       regarding
//     }
//   }
// `;

const Q_ALL_CONVERSATIONS = gql`
  query getAllConversationsForUser {
    getAllConversationsForUser {
      sid
      vendorOpportunityId
      creditSubmissionId
      participants {
        sid
        identity
        userProfileId
        email
        mobilePhoneNumber
        mugshot
        notifyByEmail
        notifyBySMS
        notifyByWhatsapp
        isDealer
        isLender
        isApplicant
        messagingBinding {
          type
          address
          proxyAddress
        }
      }
      taskId
      topic
      createdBy
      createdAt
      messages {
        sid
        conversationId
        body
        participantSid
        userProfileId
        author
        delivery {
          total
          sent
          delivered
          read
          failed
          undelivered
        }
      }
      leadSheetId
      lead {
        id
        fullName
        email
        communicationDetail
      }
      task {
        regarding
        vendorOpportunityId
        status
        assignedToUserProfileId
        assignedToAccountId
        assignedByUserProfileId
        assignedByAccountId
        notes
        priority
        createdBy
        createdDateTime
        assignedByUser
        assignedByAccount
        assignedToUser
        assignedToAccount
        active
        deleteBy
        deleteDate
        creditSubId
        notifyByEmail
        notifyByText
      }
      vendorOpportunity {
        potentialCustomer {
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: "10px",
    marginBottom: theme.spacing(2),
    borderTopLeftRadius: "0px",
    position: "relative",
    zIndex: "30",
    boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.28)",
  },
  table: {
    minWidth: 750,
  },
  // new
  tableCellStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
  },
  icon: {
    width: 15,
    height: 15,
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    margin: "15px",
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
  chatButton: {
    color: "#0A84FC",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "300",
    "&:hover": {
      fontWeight: "500",
    },
  },
  tableText: {
    fontSize: "12px",
  },
  tab: {
    backgroundColor: "#fff",
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    width: "200px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    boxShadow: "-2px -1px 4px rgba(0, 0, 0, 0.05)",
    margin: "0px 10px 0px 0px",
    color: "#000",
    zIndex: "15",
    fontSize: "20px",
    position: "relative",
  },
  activeTab: {
    zIndex: "50",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.28)",
  },
  tabsContainer: {
    flexDirection: "row",
  },
  tabLabel: {
    fontSize: "20px",
  },
}));

const useRowStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    width: 15,
    height: 15,
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    margin: "15px",
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
  tableCellStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
  },
  chatItemHeader: {
    fontSize: "18px",
    color: "#0A84FC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  chatItemHeaderText: {
    color: "#0A84FC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  chatButton: {
    color: "#0A84FC",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  separatorChar: {
    color: "#767676",
  },
  newMessageText: {
    color: "#0D981B",
  },
  tableText: {
    fontSize: "12px",
  },
  relatedTasksText: {
    color: "#0A84FC",
    fontWeight: "14px",
    cursor: "pointer",
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    color: "#000",
    fontWeight: "700",
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const EnhancedTableRow = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    row,
    messagesGroupedByVendorSubject,
    allMessages,
    collapse,
    accountList,
    isTransations,
    voName,
    leadName,
    allConversations,
  } = props;

  const topicList = messagesGroupedByVendorSubject[row];

  const [open, setOpen] = useState(collapse);

  const classes = useRowStyles();
  const userProfile = useSelector(state => state.userProfile);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const lenderProfiles = useSelector(state => state.lp);

  const [recipientList, setRecipientList] = useState([]);

  let vendorOpportunityId,
    creditSubId,
    messagesSize,
    hasUnreadMessages,
    feedId,
    participantsList,
    targetRecipient,
    accounts,
    createDate,
    messages,
    enhancedParticipantsList,
    leadSheetId;

  const handleOpenChatOnSubject = conversations => {
    dispatch({
      type: CHAT_V2_RESET,
    });
    const conversation = _.get(conversations, "[0]", null);
    if (conversation) {
      dispatch({
        type: CHAT_V2_SELECTED_CONVERSATION_SID,
        payload: conversation.sid,
      });
    }

    dispatch({
      type: CHAT_V2_CONVERSATIONS,
      payload: conversations,
    });

    dispatch({
      type: CHAT_V2_OPEN,
      payload: true,
    });

    console.log("CONVERSATIONS VIEW CONVERSATION CLICKED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: VIEW CONVERSATION CLICKED");
    // console.log("handleOpenChatOnSubject");

    //find related // lender user
    //pass to redux
    // dispatch({
    //   type: SET_CHAT_RESET
    // });

    // //Open chat
    // dispatch({
    //   type: SET_CHAT_OPENED,
    //   payload: { opened: true }
    // });

    // // //Target user
    // dispatch({
    //   type: SET_CHAT_TARGET_RECIPIENT,
    //   payload: { targetRecipient }
    // });
    // //Credit sub if exists
    // dispatch({
    //   type: SET_CHAT_TARGET_CS,
    //   payload: { targetCS: creditSubId }
    // });

    // dispatch({
    //   type: SET_CHAT_TARGET_TOPIC,
    //   payload: { targetTopic: topic }
    // });

    // dispatch({
    //   type: SET_VO,
    //   payload: { vendorOpportunityId, potentialCustomer: { name: relatedTo } }
    // });
  };

  useEffect(() => {
    setOpen(collapse);
  }, [collapse]);

  useEffect(() => {
    const availableLenderUserProfiles = _.filter(lenderProfiles, profile => {
      if (profile.lenderUserProfile) {
        return profile;
      }
    }).map(item => item.lenderUserProfile);

    setRecipientList([...relatedUsers, ...availableLenderUserProfiles]);
  }, [relatedUsers, lenderProfiles]);

  //Determine wheter we show a Lead or a VO
  let labelRelatedTo = _.find(allConversations, {
    vendorOpportunityId: row,
  });

  if (!labelRelatedTo) {
    labelRelatedTo = _.find(allConversations, {
      leadSheetId: row,
    });
  }

  function directToVoDetails(voId) {
    history.push(`vo/${voId}`);
  }

  function directToLeadDetails(leadSheetId) {
    history.push(`leadDetail/${leadSheetId}`);
  }

  return (
    <React.Fragment key={Math.random() + "_fragment"}>
      <TableRow hover role="checkbox" tabIndex={-1} key={Math.random() + "_row"}>
        {/* <TableCell
          component="th"
          align="left"
          scope="row"
          padding="none"
          className={classes.tableCellStyles}
          // onClick={() => setOpen(!open)}
        >
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          Transaction: <strong>{labelRelatedTo.relatedTo}</strong>
        </TableCell> */}
        <TableCell component="th" align="left" scope="row" padding="none" className={classes.tableCellStyles}>
          <div>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <div className={classes.icon + " " + classes.iconMinus} />
              ) : (
                <div className={classes.icon + " " + classes.iconPlus} />
              )}
            </IconButton>
            <span
              className={classes.chatItemHeader}
              onClick={() => {
                if (!vendorOpportunityId) {
                  return directToLeadDetails(leadSheetId);
                } else {
                  return directToVoDetails(vendorOpportunityId);
                }
              }}>
              {isTransations
                ? voName
                : labelRelatedTo.task && labelRelatedTo.task.regarding
                  ? labelRelatedTo.task.regarding
                  : "N/A"}

              {leadName && !voName ? `Lead: ${leadName}` : null}
            </span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow key={Math.random() + "tableRow"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} key={Math.random() + "_collapse"} timeout="auto" unmountOnExit>
            <Box margin={3}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Subject(s)</StyledTableCell>
                    <StyledTableCell align="left">
                      {isTransations ? "Related Task" : "Related Opportunity"}
                    </StyledTableCell>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Participant(s)</StyledTableCell>
                    <StyledTableCell align="left">Last Message:</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topicList.map((conversation, index) => {
                    vendorOpportunityId = _.get(conversation, "vendorOpportunityId", null);
                    leadSheetId = _.get(conversation, "leadSheetId", null);

                    creditSubId = _.get(conversation, "dynamicCreditSubmissionId", null);

                    messagesSize = _.size(_.get(conversation, "messages", []));

                    hasUnreadMessages = _.size(
                      _.filter(_.get(conversation, "participants", []), o => {
                        if (o.userProfileId == userProfile.id && !o.read) return o;
                      })
                    );

                    createDate = _.get(conversation, "createdAt", false);

                    feedId = _.get(conversation, "feedId", false);
                    participantsList = _.get(conversation, "participants", []);

                    messages = _.get(conversation, "messages", false);

                    targetRecipient = participantsList.map(participant => {
                      return _.find(recipientList, {
                        id: participant.userProfileId,
                      });
                    });

                    accounts = _.uniqBy(
                      _.remove(
                        participantsList.map(participant => {
                          const user = _.find(recipientList, {
                            id: participant.userProfileId,
                          });
                          const accountId = _.get(user, "accountId", false);

                          const thisAccount = _.find(accountList, {
                            id: accountId,
                          });
                          return thisAccount;
                        }),
                        undefined
                      ),
                      "name"
                    );

                    enhancedParticipantsList = participantsList.map(participant => {
                      const user = _.find(recipientList, {
                        id: participant.userProfileId,
                      });
                      if (!user) {
                        return participant.identity + " (Applicant)";
                      }
                      let contactRole = _.get(user, "vendorContactRole", false)
                        ? vendorContactRoleHumanReadable[_.get(user, "vendorContactRole", "")]
                        : "Lender";

                      return _.get(user, "fullName", "") + ` (${contactRole})`;
                    });
                    return (
                      <TableRow key={index}>
                        <TableCell align="left" className={classes.tableText}>
                          {conversation.topic || "N/A"}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          <span
                            className={
                              isTransations && labelRelatedTo.task && labelRelatedTo.task.regarding
                                ? classes.relatedTasksText
                                : ""
                            }
                            onClick={() => {
                              if (isTransations && labelRelatedTo.task && labelRelatedTo.task.regarding) {
                                if (!vendorOpportunityId) {
                                  directToLeadDetails(leadSheetId);
                                } else {
                                  directToVoDetails(vendorOpportunityId);
                                }
                              }
                            }}>
                            {isTransations
                              ? labelRelatedTo.task && labelRelatedTo.task.regarding
                                ? labelRelatedTo.task.regarding
                                : "N/A"
                              : voName}
                          </span>
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {format(new Date(createDate), "MM/dd/yyyy")}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {enhancedParticipantsList.map((participant, key) => {
                            return (
                              <div key={key}>
                                <p>{participant}</p>
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {messages.length ? messages[messages.length - 1].body : "N/A"}
                        </TableCell>
                        <TableCell align="center" className={classes.tableText}>
                          <Button
                            onClick={() => {
                              const targetConversation = _.find(messagesGroupedByVendorSubject[row], {
                                sid: conversation.sid,
                              });

                              handleOpenChatOnSubject([targetConversation]);
                            }}
                            color="primary"
                            simple
                            className={classes.chatButton}>
                            View Conversation
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const conversationsRedux = useSelector(state => state.chatV2.conversations);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const lenderProfiles = useSelector(state => state.lp);
  const [recipientList, setRecipientList] = useState([]);
  const [accountList, setAccountsList] = useState([]);
  const [filterRecipient, setFilterRecipient] = useState([]);
  const [filterCompany, setFilterCompany] = useState(" ");
  const [filterUnread, setFilterUnread] = useState(false);
  const [collapse, setCollapse] = useState(true);
  // const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [allMessages, setAllMessages] = useState([]);
  const [allConversations, setAllConversations] = useState([]);
  const [tabsValue, setTabsValue] = useState("opportunities");
  const apiValuesRef = useRef([]);

  const [messagesGroupedByVendorSubject, setMessagesGroupedByVendorSubject] = useState([]);

  useEffect(() => {
    dispatch({
      type: CHAT_V2_RESET_CONVERSATIONS,
    });
    console.log("CONVERSATIONS PAGE OPENED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: PAGE OPENED");
  }, []);

  const [getAllMessages, { data: listConversations, called: fetchChatsCalled, refetch, loading }] =
    useLazyQuery(Q_ALL_CONVERSATIONS);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleFilterRecipient = targetRecipient => {
    if (_.isEmpty(targetRecipient)) {
      setFilterRecipient([]);
    } else {
      setFilterRecipient(targetRecipient);
    }
  };

  useEffect(() => {
    const targetIds = filterRecipient.map(p => p.id);

    const filtered = {};

    // filtering by recipients
    _.each(apiValuesRef.current, (value, key) => {
      const filteredConversations = _.filter(value, conv => {
        return (
          targetIds.length === 0 ||
          _.some(conv.participants, p => {
            return targetIds.includes(p.userProfileId);
          })
        );
        //  &&
        // (!filterCompany.trim() ||
        //   _.some(conv.participants, { accountId: filterCompany }))
      });

      if (filteredConversations.length) {
        filtered[key] = filteredConversations;
      }
    });

    setMessagesGroupedByVendorSubject(filtered);
  }, [filterRecipient]);

  // useEffect(() => {
  //   dispatch({ type: SET_CHAT_RESET });
  //   dispatch({
  //     type: SET_VO,
  //     payload: {
  //       vendorOpportunityId: null
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   if (fetchChatsCalled) {
  //     refetch();
  //   }
  // }, [chatUnread]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterCompany = value => {
    setFilterCompany(value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Object.keys(messagesGroupedByVendorSubject).length - page * rowsPerPage);

  useEffect(() => {
    const availableLenderUserProfiles = _.filter(lenderProfiles, profile => {
      if (profile.lenderUserProfile) {
        return profile;
      }
    }).map(item => item.lenderUserProfile);
    const availableLenderAccounts = _.filter(lenderProfiles, profile => {
      if (profile.lenderAccount) {
        return profile;
      }
    }).map(item => item.lenderAccount);
    const accounts = [account, ...availableLenderAccounts];

    // const usersByAccount = _.groupBy(
    //   [...relatedUsers, ...availableLenderUserProfiles],
    //   "accountId"
    // );

    const listRelatedDealers = _.filter(relatedUsers, o => {
      return o.id !== userProfile.id;
    });
    const mergedRecipientList = [...listRelatedDealers, ...availableLenderUserProfiles].map(up => {
      const account = _.find(accounts, {
        id: up.accountId,
      });
      if (account)
        return {
          ...up,
          company: account.name,
        };
    });

    setRecipientList(mergedRecipientList);
    setAccountsList(accounts);
  }, [relatedUsers, lenderProfiles]);
  useEffect(() => {
    if (!!listConversations && !!listConversations.getAllConversationsForUser) {
      refetch();
    }
  }, [listConversations, conversationsRedux]);

  useEffect(() => {
    if (!!listConversations && !!listConversations.getAllConversationsForUser) {
      const conversations = listConversations.getAllConversationsForUser;

      const conversationsFromLeads = _.filter(conversations, o => {
        return o.vendorOpportunityId === null && o.leadSheetId;
      });

      const conversationsFromVos = _.filter(conversations, o => {
        return o.vendorOpportunityId !== null;
      });
      const groupedVos = _.chain(conversationsFromVos).groupBy("vendorOpportunityId").value();

      const groupedLeads = _.chain(conversationsFromLeads).groupBy("leadSheetId").value();

      let grouped = _.merge(groupedVos, groupedLeads);

      apiValuesRef.current = grouped;

      setAllConversations(conversations);
      setMessagesGroupedByVendorSubject(grouped);

      const key = grouped && Object.keys(grouped)[0];

      if (key) {
        dispatch({
          type: CHAT_V2_CONVERSATIONS,
          payload: grouped[key],
        });
      }
    } else {
      getAllMessages();
      apiValuesRef.current = [];
      setMessagesGroupedByVendorSubject([]);
    }
  }, [loading, listConversations, getAllMessages]);

  // TODO: revisit

  // useEffect(() => {
  //   let filteredMessages = allMessages;

  //   if (filterUnread) {
  //     filteredMessages = _.filter(allMessages, o => {
  //       const myself = _.find(o.participants, {
  //         userProfileId: userProfile.id,
  //         read: false
  //       });

  //       if (!_.isEmpty(myself)) return o;
  //     });
  //   }

  // if (filterCompany !== " ") {
  //   filteredMessages = _.filter(filteredMessages, message => {
  //     if (
  //       _.some(message.participants, {
  //         accountId: filterCompany
  //       })
  //     ) {
  //       return message;
  //     }
  //   });
  // }

  //   if (!_.isEmpty(filterRecipient)) {
  //     filteredMessages = _.filter(filteredMessages, message => {
  //       const found = message.participants.map(participant => {
  //         let contact = _.find(filterRecipient, {
  //           id: participant.userProfileId
  //         });

  //         if (contact) {
  //           return true;
  //         }

  //         return false;
  //       });
  //       if (_.some(found)) return message;
  //     });
  //   }

  //   //Aggregation magic happens here, client side
  //   setMessagesGroupedByVendorSubject(
  //     _.chain(filteredMessages)
  //       .groupBy("vendorOpportunityId")
  //       .mapValues(values =>
  //         _.chain(values)
  //           .groupBy("topic")
  //           .value()
  //       )
  //       .value()
  //   );
  // }, [filterRecipient, filterUnread, filterCompany]);

  // useEffect(() => {
  //   setLoading(loadingMesssages);
  // }, [loadingMesssages]);

  // useEffect(() => {}, [filterUnread]);

  const handleFilterUnread = event => {
    setFilterUnread(!!event.target.checked);
  };

  if (!allConversations.length && loading) {
    return <LinearProgress />;
  }

  return allConversations.length ? (
    <div className={classes.root}>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        className={classes.tabsContainer}
        style={{ flexDirection: "row" }}
        value={tabsValue}
        onChange={e => setTabsValue(e.target.value)}>
        <FormControlLabel
          value="opportunities"
          className={tabsValue === "tasks" ? classes.tab : `${classes.tab} ${classes.activeTab}`}
          control={<Radio style={{ display: "none" }} />}
          label="Opportunities"
          classes={{ label: classes.tabLabel }}
        />
        <FormControlLabel
          value="tasks"
          style={{ margin: "0px 0px 0px 10px" }}
          className={tabsValue === "opportunities" ? classes.tab : `${classes.tab} ${classes.activeTab}`}
          control={<Radio style={{ display: "none" }} />}
          label="Tasks"
          classes={{ label: classes.tabLabel }}
        />
      </RadioGroup>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h5>Filters: </h5>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                value={filterRecipient}
                options={recipientList}
                groupBy={option => option.company}
                onChange={(event, newValue) => {
                  handleFilterRecipient(newValue);
                }}
                getOptionLabel={option => {
                  return (
                    option.fullName +
                    `${
                      vendorContactRoleHumanReadable[option.vendorContactRole]
                        ? ` (${vendorContactRoleHumanReadable[option.vendorContactRole]})`
                        : " (Lender)"
                    }`
                  );
                }}
                renderInput={params => (
                  <TextField {...params} variant="standard" label="Participant(s)" placeholder="" />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="simple-select">
                <b>Company:</b>
              </InputLabel>
              <Select
                size="small"
                value={filterCompany}
                onChange={e => {
                  handleFilterCompany(e.target.value);
                }}
                inputProps={{
                  name: "account",
                  id: "account",
                }}>
                <MenuItem key={"empty"} value={" "} size="small">
                  -- ALL --
                </MenuItem>
                {!!accountList &&
                  accountList.map((account, key) => {
                    return (
                      <MenuItem key={`company_${key}`} value={account.id} size="small">
                        {account.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs={4} sm={4} md={3} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={collapse}
                  onChange={() => {
                    setCollapse(!collapse);
                  }}
                  name="collapse"
                />
              }
              label="Expand Rows"
            />
          </Grid>
        </Grid>
        {/* <ChatWidgetV2 /> */}
        <hr />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
            <TableBody>
              {Object.keys(messagesGroupedByVendorSubject)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${row}`;
                  let voName, relatedTaskId, leadName;

                  if (
                    allConversations[index].vendorOpportunity &&
                    allConversations[index].vendorOpportunity.potentialCustomer &&
                    allConversations[index].vendorOpportunity.potentialCustomer.name
                  ) {
                    voName = allConversations[index].vendorOpportunity.potentialCustomer.name;
                  }

                  if (allConversations[index].leadSheetId) {
                    leadName = allConversations[index].lead.fullName;
                  }

                  if (allConversations[index].taskId) {
                    relatedTaskId = allConversations[index].taskId;
                  }

                  return (
                    <EnhancedTableRow
                      key={labelId}
                      row={row}
                      voName={voName}
                      leadName={leadName}
                      collapse={collapse}
                      allMessages={allMessages}
                      relatedTaskId={relatedTaskId}
                      allConversations={allConversations}
                      isTransations={tabsValue === "opportunities"}
                      accountList={accountList}
                      messagesGroupedByVendorSubject={messagesGroupedByVendorSubject}
                    />
                  );
                })
                .filter(row => {
                  if (tabsValue === "tasks") {
                    return row.props.relatedTaskId;
                  }
                  return row;
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 60 * emptyRows,
                  }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Opportunities per page"
          component="div"
          count={Object.keys(messagesGroupedByVendorSubject).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  ) : (
    <p>You don't have any messages yet</p>
  );
}
