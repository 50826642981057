import React, { useContext, useMemo } from "react";
import { CommonDataContext } from "contexts/CommonDataContext";
import { GeneralInformationFormAftermarketPartner } from "./GeneralInformationFormAftermarketPartner";
import { GeneralInformationFormDealer } from "./GeneralInformationFormDealer";

export const GeneralInformationForm = () => {
  const { isAftermarketPartner } = useContext(CommonDataContext);

  const FormToRender = useMemo(() => {
    if (isAftermarketPartner) return GeneralInformationFormAftermarketPartner;
    return GeneralInformationFormDealer;
  }, [isAftermarketPartner]);

  return <FormToRender />;
};
