import React, { useEffect, useState } from "react";
import { TaskList } from "./TaskList_V2/ui";
import { QueryHookOptions, useLazyQuery } from "@apollo/client";
import { QUERY_RELATED_USERS } from "./TaskList_V2/queries";
import { CreditSubmission, VendorOpportunity, UserProfile, Account, Document } from "@trnsact/trnsact-shared-types";

interface Props {
  account: Account;
  cs: CreditSubmission;
  vo: VendorOpportunity;
  userProfile: UserProfile;
  refetchDocs: VoidFunction;

  documents?: Document[];
  documentsPortalConfiguration?: any;
}

export const SpecificTasks_V2 = ({
  vo,
  cs,
  account,
  documents,
  userProfile,
  refetchDocs,
  documentsPortalConfiguration,
}: Props) => {
  const creditSubId = cs.creditSubId;

  const [relatedUsers, setRelatedUsers] = useState<any[]>([]);

  const [
    getRelatedUsers,
    { loading: relatedUsersLoading, error: relatedUsersError, data: relatedUsersData },
  ] = useLazyQuery(QUERY_RELATED_USERS, {
    variables: {
      accountId: account.id,
    },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  useEffect(() => {
    if (relatedUsersData !== null && relatedUsersData !== undefined && relatedUsersData.relatedUsers !== null) {
      setRelatedUsers(relatedUsersData.relatedUsers);
    } else {
      getRelatedUsers();
    }
  }, [getRelatedUsers, relatedUsersData]);

  if (relatedUsersLoading) console.log("Loading related users profiles...");
  if (relatedUsersError) console.log(`Error! ${relatedUsersError.message}`);

  const queryTasksOptions: QueryHookOptions = !creditSubId
    ? {
        variables: { VOId: vo.vendorOpportunityId, csId: null, limit: 100 },
      }
    : {
        variables: { VOId: null, csId: creditSubId, limit: 100 },
      };

  return (
    <TaskList
      cs={cs}
      vo={vo}
      addTaskFlag
      account={account}
      queryTasksOptions={{
        ...queryTasksOptions,
        context: { authRequired: true },
        fetchPolicy: "no-cache",
      }}
      userProfile={userProfile}
      creditSubId={creditSubId}
      tasksQueryKey="vendorOpportunityTask"
      relatedUsers={relatedUsers}
      taskOnClickAction="editTask"
      refetchDocs={refetchDocs}
      documents={documents}
      documentsPortalConfiguration={documentsPortalConfiguration}
    />
  );
};
