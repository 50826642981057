import { FC, ReactNode } from "react";
import { Invoice } from "modules/invoice";
import { ConnectDropTarget } from "react-dnd";
import { Nullable, SelectOption } from "global";
import { VendorOpportunity } from "modules/vendorOpportunity";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProposalMenuOptionType } from "@trnsact/trnsact-shared-types/src/generated";
import { FACT_NAMES_BOOLEAN, FACT_NAMES_DATE, FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";

export type ProductId = string;
export type OptionName = string;
export type Term = string;
export type MenuName = string;
export type FinanceProgramId = string;
export type ProposalMenuId = string;
export type SubmissionId = string;

export enum AftermarketLicenceType {
  Core = "Core",
  Enterprise = "Enterprise",
}

export enum BuilderViewType {
  Finance = "Finance",
  AftermarketMenu = "AftermarketMenu",
}

export enum SaleStructureType {
  Finance,
  AftermarketMenu,
}

export enum SendVia {
  Email = "Email",
  Text = "Text",
  TextAndEmail = "TextAndEmail",
}

export enum SaleType {
  Cash = "cash",
  Financing = "financing",
}

export enum RateTypes {
  InterestRate = "INTEREST_RATE",
  RateFactor = "RATE_FACTOR",
}

export enum Structures {
  None = "none",
  Markup = "financeProfit",
  Subsidy = "subsidy",
}

export enum MarkupMethods {
  MarkupDollar = "markupDollar",
  MarkupPercentage = "markupPercentage",
  CustomerRate = "customerRate",
}

export enum ProgramTypes {
  Loan = "LOAN",
  Lease = "LEASE",
  EFA = "EFA",
}

export enum PaymentCalculationMethods {
  Advance = "BEGINNING_OF_PERIOD",
  Arrears = "END_OF_PERIOD",
}

export enum EquipmentActionsMode {
  Create = "create",
  Update = "update",
}

export interface FlatFee {
  fee: number;
  financed: boolean;
  identifier: string;
}

export interface Markup {
  identifier: string;
  markupFlat?: number;
  markupPercentage?: number;
}

interface CustomerForm {
  email: string;
  state: string;
  phone: string;
  selectedCustomer: string;
  firstAndLastName: string;
}

export interface Pricing {
  rate: number;
  term: number;
  overages?: number;
  productCost?: number;
  totalProfit?: number;
  dealerMarkup: number;
  customerRate: number;
  financeProfit: number;
  subsidyAmount: number;
  amountDueToday?: number;
  profitPercentage?: number;
  aftermarketProfit?: number;
  subsidyPercentage?: number;
  menu: Nullable<AftermarketMenu>;
  costOfDeferredPayments: number;
  paymentAmountPerPeriod: number;
  paymentAmountPerPeriodWithoutDealerMarkup: number;
}

export interface MenuCardsProps {
  isCalculating: boolean;
  isSectionOpen?: boolean;
  isMAALessThenFinanceAmount?: boolean;
  builder?: Record<Term, AftermarketMenu>;
  financePricing: Record<Term, Pricing>;
  menuPricing: Record<Term, Record<MenuName, Pricing>>;
}

interface SaleDetailsForm {
  fees: number;
  netRate: number;
  salesTax: number;
  saleType: SaleType;
  downPayment: number;
  invoiceTotal: number;
  financeAmount: number;
  equipmentSubtotal: number;
}

interface EquipmentForm {
  selectedEquipment: string;
  quantity: string;
  year: string;
  make: string;
  model: string;
  class: string;
  serialNumberVin: string;
  stockId: string;
  newUsed: string;
  mileage: string;
  description: string;
  totalWholesaleCost: string;
  retailPrice: string;
}

interface SpecifyRates {
  term: string;
  rate: string;
}

interface FinanceQuoteForm {
  commonRate: string;
  amount: string;
  terms: string[];
  daysToFirstPayment: string;
  startDate: string; // Contract Date
  firstPaymentDate: string;
  program: string;
  residual: string;
  rateType: RateTypes;
  structure: Structures;
  programType: ProgramTypes;
  programName: string;
  dealerMarkup: string;
  specifyRate: boolean;
  advancePayment: string;
  specifyRatePerTerm: string;

  createProposalWithExistingProgram: Nullable<CreateProposalFinanceExistingOptions>;

  markupRate: string;
  markupMethod: MarkupMethods;
  paymentCalculationMethod: PaymentCalculationMethods;

  subsidyType: MarkupType;

  customTermTemporaryValue: Nullable<string>;

  specifyRates: SpecifyRates[];
  fee: FlatFee[];

  menu: string;
}

interface SendViaForm {
  type: SendVia;
}

interface ProposalSettings {
  productPrice: boolean;
  productDetails: boolean;
}

interface SubmissionForm {
  selectedSubmissionId: Nullable<string>;
  maxApprovalAmount: Nullable<number>;
}

export interface FinanceProgramFormValues {
  submission: SubmissionForm;
  customer: CustomerForm;
  saleDetails: SaleDetailsForm;
  equipment: EquipmentForm;
  financeQuote: FinanceQuoteForm;
  sendVia: SendViaForm;
  displaySettings: ProposalSettings;
  financeProgramDescription: string;
}

export interface CommonMenuPriceValues {
  cost: number;
  isTotal?: boolean;
  retailCost: number;
  rateFactor: number;
  markup: {
    markup: number;
    type: MarkupType;
  };
}

export type ProductPrisingFields = "cost" | "markup.markup" | "retailCost" | "rateFactor";

export interface SubmitOptions {
  isDraft: boolean;
}

export enum DnDTypes {
  ProposalProduct = "proposalProduct",
  MenuOption = "menuOption",
  ProductInMenu = "productInMenu",
}

export enum AftermarketProductTypes {
  Simple = "Simple",
  Gaig = "GAIG",
  Ntp = "NTP",
}

export type PrisingConfig = {
  isShowRate: boolean;
  isRateDisabled: boolean;
  isShowCost: boolean;
  isCostDisabled: boolean;
  isShowMarkup: boolean;
  isMarkupDisabled: boolean;
  isShowRetail: boolean;
  isRetailDisabled: boolean;
};

export type PrisingConfigByVendor = Record<AftermarketProductTypes, Partial<PrisingConfig>>;

export interface ProposalProductCardProps {
  product: ProposalProduct;
  equipmentCost?: number;
  type?: "simple" | "forEdit";
  factsToCheck?: FactToCheck[];
  factsSkipped?: FactToCheck[];
  isNeedRunJsonEngine?: number;
  hideFieldsForRender?: Fact[];
  mode: ProposalProductCardModes;
  proposalProductConfiguration: ProductConfig;
  prisingConfigByVendor: Partial<PrisingConfigByVendor>;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export interface ProposalProductCardByVendorProps extends Omit<ProposalProductCardProps, "prisingConfigByVendor"> {
  prisingConfig: PrisingConfig;
}

export interface FormSectionProps {
  sectionName: FormSections;
  updateInvoiceAction?: ReactNode;
  onClearStructureAndMenuOptions?: VoidFunction;
  handleUpdateCollapse?: (section: FormSections, nextValue: boolean) => void;
  handleUpdateOpenStatus?: (section: FormSections, nextValue: boolean) => void;
}

export enum ProposalProductCardModes {
  Desking = "desking",
  Constructor = "constructor",
}

export const ProposalProductCoverageTermDefaultToFinanceTermOption = "Default to Finance Term";

export enum FormSections {
  Customer = "customer",
  SaleDetails = "saleDetails",
  Equipment = "equipment",
  Structure = "structure",
  MenuBuilder = "menuBuilder",
  SendVia = "sendVia",
}

export enum MarkupType {
  Flat = "FLAT",
  Percentage = "PERCENTAGE",
}

export enum CreateProposalFinanceExistingOptions {
  Yes = "yes",
  No = "no",
}

export enum EquipmentSourcedRequiredOptionsValue {
  DTRSourced = "DTR-Sourced",
  NonDTRSourced = "Non DTR-Sourced",
}

export interface UserActionsEvents {
  onSyncEquipment?: () => Promise<void>;
  onEquipmentCreated?: (equipmentId: string) => Promise<void>;
  onEquipmentUpdated?: (equipmentId: string) => Promise<void>;
  onEquipmentRemoved?: (equipmentId: string) => Promise<void>;
}

export interface PanelProps {
  vo: VendorOpportunity;
  accountId: string;
  inventoryActions: any;
  creditApplication: any;
  invoice: Nullable<Invoice>;
  updateInvoiceAction: ReactNode;
  actions: UserActionsEvents;
}

export enum ShowTypes {
  All = "ALL",
  ServiceContract = "SERVICE_CONTRACT",
  Insurance = "INSURANCE",
  GAP = "GAP",
  Roadside = "ROADSIDE",
  None = "NONE",
}

export interface ProductsFiltersDealer {
  search: string;
  vendors: string[];
  showOnly: ShowTypes;
}

export interface ProductsFiltersAftermarketPartner {
  search: string;
  showOnly: ShowTypes;
}

export interface EquipmentDynamicFieldsProps {
  value: string;
  error?: boolean;
  label?: ReactNode;
  fact: FactToCheck;
  disabled?: boolean;
  tooltip?: ReactNode;
  helperText?: string;
  options?: SelectOption[];
  onChange: (nextValue: string) => void;
  onClick?: (fact: FactToCheck) => void;
}

export type Fact = FACT_NAMES_BOOLEAN | FACT_NAMES_NUMERIC | FACT_NAMES_DATE | FACT_NAMES_STRING | string;

export interface Condition {
  fact: Fact;
  value: string;
  operator: string;
  factName?: string;
  exactMatch?: string;
  runEngineOnNull?: any;
  requiredForQuote?: boolean;
}

export interface AftermarketEvent {
  type: string;
  params?: Record<string, any>;
}

export interface Criteria {
  name: string;
  event: AftermarketEvent;
  conditions: {
    formRules: Condition[];
    jsonRules: {
      conditions: {
        all: Condition[];
      };
    };
  };
}

export type FactToCheckTypes = "boolean" | "numeric" | "string";

export interface FactToCheck {
  factKey: Fact;
  rule: Condition;
  type: FactToCheckTypes;
  options?: string[] | undefined | null;
}

export type ProductsDynamicFieldsRender = {
  meta: Record<Fact, { options: string[] }>;
  render: Record<Fact, { fact: FactToCheck; fieldComponent: FC<EquipmentDynamicFieldsProps> }>;
};

export enum AftermarketCatalogDetailsKeys {
  ProductSummary = "productSummary",
  ParagraphDescription = "paragraphDescription",
  CoverageDetails = "coverageDetails",
  EligibilityAndInclusions = "eligibilityAndInclusions",
  LimitationsOfLiability = "limitationsOfLiability",
  LegalDisclaimer = "legalDisclaimer",
}

export interface CatalogDetailsToRenderAsLinkConfig {
  key: AftermarketCatalogDetailsKeys;
  label: string;
}

export interface NotifyAboutRemovedProductsDialogData {
  removedProducts: ProposalProduct[];
}

export interface PaymentOptionTerm {
  term: string;
  index: number;
}

export type ProductConfig = Record<Fact | string, any>;

export interface CalculatePaymentMenuVariables {
  name: string;
  description: string;
  menuOptions: any;
}

export interface Equipment extends Record<string, any> {
  make: string;
  year: string;
  model: string;
  mileage: number;
  newUsed: string;
  stockId: string;
  quantity: number;
  equipmentId: string;
  retailPrice: number;
  term: Nullable<any>;
  type: Nullable<any>;
  hours: Nullable<any>;
  wholesaleCost: number;
  cabType: Nullable<any>;
  loanType: Nullable<any>;
  serialNumberVin: string;
  classType: Nullable<any>;
  truckClass: Nullable<any>;
  attachments: Nullable<any>;
  totalWholesaleCost: number;
  equipmentIdx: Nullable<any>;
  assetTypeStr: Nullable<any>;
  tradeInValue: Nullable<any>;
  vendorOpportunityId: string;
  lenderProduct: Nullable<any>;
  additionalDescription: string;
  amountOwedOnTradeIn: Nullable<any>;
}

export interface PaymentInfoRenderConfig {
  label: string;
  infoMessage?: ReactNode;
  value: Nullable<number>;
  hideInfoMessage?: boolean;
  formatter: (value: Nullable<number | string>) => string;
}

export interface AftermarketMenuOption {
  name: string;
  ordinal: number;
  payment: Pricing;
  titleColor: string;
  description: string;
  titleBgColor: string;
  menuOptionDetail: any;
  type?: ProposalMenuOptionType;
  products: ProposalProduct[];
  proposalMenuOptionId: string;
}

export interface AftermarketMenu {
  name: string;
  description: string;
  proposalMenuId: string;
  menuOptions: AftermarketMenuOption[];
}

export interface MenuOptionCardBodyProps {
  mode: ProposalProductCardModes;
  menuOption: AftermarketMenuOption;
  commonPrice: CommonMenuPriceValues;
  productsDropContainer: ConnectDropTarget;
  productsConfiguration?: Record<ProductId, ProductConfig>;
  onOpenProductDetailsDialog?: (product: ProposalProduct) => void;
  onRemoveProduct?: (productId: ProductId, currentMenuOption: AftermarketMenuOption) => void;
  onRemoveAddon?: (product: ProposalProduct, optionName: OptionName, title: string) => void;
  onSwitchProducts: (options: { menuName: OptionName; from: number; to: number }) => void;
}

export interface SendProposalData {
  sendViaEmail: boolean;
  sendViaText: boolean;
  phoneNumber: string;
}

export interface Submission {
  id: string;
  decision: string;
  lenderName: string;
  approvedAmount: Nullable<number>;
}
