import React from "react";
import { grey } from "@mui/material/colors";
import { useFormContext } from "react-hook-form";
import { Box, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FinanceProgramFormValues } from "../../../types";
import { SwitchField } from "../../../../../components/form";

export const ProposalSettings = () => {
  const classes = useStyles();

  const { control } = useFormContext<FinanceProgramFormValues>();

  return (
    <Box className={classes.container}>
      <Box className={classes.panel}>
        <Typography component="span" variant="subtitle2">
          Proposal Settings
        </Typography>

        <Tooltip placement="top" arrow title="Control which elements of the menu appear for the customer">
          <HelpOutlineIcon fontSize="small" />
        </Tooltip>
      </Box>

      <Box className={classes.form}>
        <SwitchField control={control} name="displaySettings.productDetails" label="Show Product Details" />

        <SwitchField control={control} name="displaySettings.productPrice" label="Show Product Price" />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  panel: {
    gap: "0.5rem",
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
});
