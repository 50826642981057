import { createContext } from "react";
import { Nullable } from "global";
import { Engine } from "json-rules-engine";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

interface JsonRulesEngineContext {
  engine: Engine;
  handleRunEngin: VoidFunction;
  handleAddProductsRules: (product: ProposalProduct) => void;
}

export const jsonRulesEngineContext = createContext<Nullable<JsonRulesEngineContext>>(null);
