import React from "react";
import { FieldNotify } from "global";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { Box, TextField, TextFieldProps } from "@mui/material";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import NumberFormat, { NumberFormatValues, SourceInfo } from "react-number-format";
import { FormNotificationBlock } from "../FormNotificationBlock";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  max?: number;
  notify?: FieldNotify;
  control: Control<FormValues>;
  textFieldProps?: TextFieldProps;
  name: UseControllerProps<FormValues>["name"];
  customOnChange?: (nextValue: number, name: UseControllerProps<FormValues>["name"]) => void;
}

export const CurrencyInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  max,
  label,
  notify,
  control,
  customOnChange,
  textFieldProps = {},
}: Props<FormValues>) => {
  const handleChange = (onFieldChange: any, { value }: NumberFormatValues, sourceInfo: SourceInfo) => {
    const parsedValue = parseFloat(value);

    if (customOnChange) {
      customOnChange(isNaN(parsedValue) ? 0 : parsedValue, name);
    } else {
      if (isNaN(parsedValue)) return onFieldChange(null);
      onFieldChange(parsedValue);
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, value, onChange, ...field }, fieldState }) => (
          <NumberFormat
            prefix="$"
            fullWidth
            size="small"
            label={label}
            inputRef={ref}
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            variant="standard"
            allowNegative={false}
            customInput={TextField}
            {...field}
            {...(textFieldProps as any)}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ""}
            value={parseFloat(value)}
            onValueChange={(values, sourceInfo) => handleChange(onChange, values, sourceInfo)}
            isAllowed={values => {
              const { floatValue } = values;
              if (max && floatValue && floatValue > max) {
                return false;
              }
              return true;
            }}
          />
        )}
      />

      {notify && <FormNotificationBlock {...notify} />}
    </Box>
  );
};
