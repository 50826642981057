import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import DeliveryTimelineModal from "./DeliveryTimelineModal";
import { CardContainer } from "../../components/shared/CardContainer/CardContainer";
import { Button } from "@mui/material";
import { RowDataList } from "../../components/shared/RowDataList/RowDataList";
import { ContentViewType } from "../../global";

export default function DeliveryTimelineCard({
  vo,
  refetchVO,
  deliveryTimeLineTableBody,
  contentViewType = ContentViewType.Desktop,
}) {
  const classes = useStyles();

  const [openDeliveryTimelineModal, setOpenDeliveryTimelineModal] = useState(false);

  const handleOpenDeliveryTimelineModal = () => {
    setOpenDeliveryTimelineModal(true);
  };
  const handleCloseDeliveryTimelineModal = () => {
    setOpenDeliveryTimelineModal(false);
  };

  const [redBorder, setRedBorder] = useState(false);

  useEffect(() => {
    setRedBorder(!!vo.expectedDeliveryDate && !!vo.deliveryDate && vo.expectedDeliveryDate < vo.deliveryDate);
  }, []);

  return (
    <>
      <CardContainer
        title="Delivery Timeline"
        contentViewType={contentViewType}
        actionBtnProps={{
          label: "Edit delivery timeline",
          onClick: handleOpenDeliveryTimelineModal,
        }}
        containerClassName={redBorder ? classes.redBorder : classes.transparentBorder}>
        <div className={classes.cardLine}>
          {deliveryTimeLineTableBody?.length ? (
            <RowDataList data={deliveryTimeLineTableBody} contentViewType={contentViewType} />
          ) : (
            <div className={classes.cardLine}>
              <span className={classes.greenText}>Please add delivery timeline details via "Edit" button</span>
              <Button color="primary" onClick={handleOpenDeliveryTimelineModal} className={classes.greenButton}>
                EDIT
              </Button>
            </div>
          )}
        </div>
      </CardContainer>

      <DeliveryTimelineModal
        refetchVO={refetchVO}
        open={openDeliveryTimelineModal}
        deliveryTimeLineTableBody={deliveryTimeLineTableBody}
        handleOpenInvoiceModal={handleOpenDeliveryTimelineModal}
        handleClose={() => handleCloseDeliveryTimelineModal()}
      />
    </>
  );
}

const useStyles = makeStyles({
  cardLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px 0",
    "& h4": {
      width: "100%",
    },
    "& > div": {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  redBorder: {
    border: "2px solid #FF0000",
    height: "100%",
  },
  transparentBorder: {
    border: "2px solid transparent",
    height: "100%",
  },
  greenText: {
    color: "#3ec179",
  },
  greenButton: {
    backgroundColor: "#3ec179",
  },
  right: {
    textAlign: "right",
  },
});
