import gql from "graphql-tag";

export const INTEGRATION_SETTINGS_VALUES_LIST = gql`
  query GetIntegrationSettingsValuesList($input: IntegrationSettingsValuesListInput) {
    integrationSettingsValuesList(input: $input) {
      integrationSettingsValuesId
      integrationName
      integrationSettingsValues
      accountId
      userProfileId
      locationId
      createdDateTime
      modifiedDateTime
    }
  }
`;
