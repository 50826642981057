import React from "react";
import clsx from "clsx";
import ReactTable, { Column } from "react-table";
import { Typography } from "@mui/material";
import { usePagination } from "hooks/usePagination";
import makeStyles from "@mui/styles/makeStyles";
import { CustomPagination } from "./Pagination";

interface Props<Entity> {
  loading?: boolean;
  tableData: Entity[];
  columns: Column<Entity>[];
}

export const Table = <Entity,>({ tableData, columns, loading = false }: Props<Entity>) => {
  const classes = useStyles();

  const paginationProps = usePagination(tableData.length);

  return (
    <ReactTable<Entity>
      loading={loading}
      columns={columns}
      showPaginationBottom
      PaginationComponent={CustomPagination}
      data={loading ? [] : tableData}
      className={clsx("-highlight", classes.table)}
      loadingText={
        <Typography component="span" variant="h6">
          Data loading...
        </Typography>
      }
      noDataText={
        !loading && (
          <Typography component="span" variant="h6">
            No data
          </Typography>
        )
      }
      {...paginationProps}
    />
  );
};

const useStyles = makeStyles({
  table: {
    "& .-loading > div": {
      color: "black",
    },

    "& .rt-noData": {
      top: "50%",
      left: "50%",
      color: "black",
      transform: "translate(-50%, -50%)",
    },

    "& .customHeader": {
      margin: 0,
    },

    "& .customHeader > div": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      fontFamily: "Roboto",
    },

    "& .customHeader > div::after": {
      color: "black",
      fontWeight: 500,
    },
  },
});
