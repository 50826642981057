import React, { useEffect, useState } from "react";
import { formatMoney, getProductVendorName } from "../../utils";
import { format } from "date-fns";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Fuse from "fuse.js";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import { connect } from "react-redux";
import { adminRoles, portalConfigurationTypes } from "pages/Prequal/constants";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { TabsOption } from "global";
import { AftermarketPageTabs } from "modules/aftermarketMenuConstructor/types";
import { TabsContainer } from "components/shared/TabsContainer/TabsContainer";
import { CardBackground } from "components/CardBackground/CardBackground";
import { AftermarketMenuTemplatesPage } from "modules/aftermarketMenuConstructor";
import { ProposalProductsCardTypeChip } from "modules/desking/ui/components";
import { AftermarketLicenceType } from "modules/desking/types";

const Q_AFTERMARKET_PRODUCTS = gql`
  query GetAftermarketProducts($accountId: ID!) {
    aftermarketProducts(accountId: $accountId) {
      aftermarketProductId
      productName
      aftermarketVendorApiChannel
      partnerLinks {
        partnerLinkId
        name
      }
      productCategory
      productDescriptionExternal
      criteria
      productDescriptionInternal
      productType
      createdDateTime
    }
  }
`;

const fuseOptions = {
  keys: ["productName", "productCategory", "productType", "productDescriptionExternal", "productCost"],
  threshold: 0.3,
};

interface Props {
  account: any;
  userProfile: any;
  portalConfiguration: any;
}

const ListAftermarketProducts = ({ userProfile, account, portalConfiguration }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<any>("productName");
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [initialTab, setInitialTab] = useState<AftermarketPageTabs>(AftermarketPageTabs.MenuTemplates);
  const isPartnerAccount = account.accountType === "Partner";

  const { loading, data: { aftermarketProducts } = { aftermarketProducts: [] } } = useQuery(Q_AFTERMARKET_PRODUCTS, {
    variables: { accountId: account.id },
  });

  const [canWrite, setCanWrite] = useState(
    _.get(
      portalConfiguration,
      `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.assignedOnly`,
      []
    ).includes(userProfile.vendorContactRole)
  );
  const [canRead, setCanRead] = useState(
    _.get(
      portalConfiguration,
      `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.readOnly`,
      []
    ).includes(userProfile.vendorContactRole)
  );

  const aftermarketLicenceType = _.get<string>(
    portalConfiguration,
    `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.aftermarketLicenceType`,
    AftermarketLicenceType.Core
  );

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    console.log(portalConfiguration);
    if (portalConfiguration && userProfile) {
      setIsSuperAdmin([adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole));
      setCanWrite(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.assignedOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );

      setCanRead(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.jsonDefinition.config.rolesSettings.readOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
    }
  }, [portalConfiguration, userProfile]);

  const fuse = new Fuse(aftermarketProducts, fuseOptions);

  const aftermarketProductsWithCost = (aftermarketProducts: any) => {
    return aftermarketProducts.map((product: any) => {
      const costUnformatted = _.get(product, "criteria[0].event.params.DEALERCOST", 0);
      const productCost = costUnformatted ? `$ ${formatMoney(costUnformatted)}` : "N/A";
      return {
        ...product,
        productCost,
      };
    });
  };

  useEffect(() => {
    if (!_.isEmpty(aftermarketProducts)) {
      setFilteredProducts(aftermarketProductsWithCost(aftermarketProducts));
    }
  }, [aftermarketProducts]);

  useEffect(() => {
    const found = searchText
      ? fuse.search(searchText).map(result => result.item)
      : aftermarketProductsWithCost(aftermarketProducts);
    if (found) {
      setFilteredProducts(found);
    }
  }, [searchText]);

  const handleChangeTab = (newValue: AftermarketPageTabs) => {
    history.push(
      `/aftermarket${
        newValue === AftermarketPageTabs.Products
          ? ""
          : `/${newValue === AftermarketPageTabs.MenuTemplates ? "menu" : "dashboard"}`
      }`
    );
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/aftermarket":
        setInitialTab(AftermarketPageTabs.Products);
        break;
      case "/aftermarket/menu":
        setInitialTab(AftermarketPageTabs.MenuTemplates);
        break;
      case "/aftermarket/dashboard":
        setInitialTab(AftermarketPageTabs.Dashboard);
        break;
      default:
        setInitialTab(AftermarketPageTabs.Products);
        break;
    }
  }, [location.pathname]);

  const handleRequestSort = (property: keyof (typeof aftermarketProducts)[0]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedProducts = _.orderBy(
      filteredProducts,
      [
        product => {
          if (property === "createdDateTime") {
            return new Date(parseInt(product[property]));
          }
          return product[property]?.toString().toLowerCase();
        },
      ],
      [isAsc ? "asc" : "desc"]
    );

    setFilteredProducts(sortedProducts);
  };

  const isAddProductBtnDisabled =
    !isSuperAdmin && (!canWrite || aftermarketLicenceType === AftermarketLicenceType.Core);

  const tabs: TabsOption<AftermarketPageTabs>[] = [
    {
      label: AftermarketPageTabs.Products,
      value: AftermarketPageTabs.Products,
      isVisible: true,
      component: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h6">LIST</Typography>
              <Button
                variant="contained"
                color="primary"
                disabled={isAddProductBtnDisabled}
                onClick={() => history.push("/aftermarket/add")}
                startIcon={<AddCircleOutlineIcon />}>
                ADD AFTERMARKET PRODUCT
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search"
              margin="normal"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {!isPartnerAccount && (
                      <TableCell>
                        <TableHead>Vendor</TableHead>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "productName"}
                        direction={orderBy === "productName" ? order : "asc"}
                        onClick={() => handleRequestSort("productName")}>
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "productCategory"}
                        direction={orderBy === "productCategory" ? order : "asc"}
                        onClick={() => handleRequestSort("productCategory")}>
                        Category
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "productDescriptionExternal"}
                        direction={orderBy === "productDescriptionExternal" ? order : "asc"}
                        onClick={() => handleRequestSort("productDescriptionExternal")}>
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "productCost"}
                        direction={orderBy === "productCost" ? order : "asc"}
                        onClick={() => handleRequestSort("productCost")}>
                        Cost
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width={200} align="center">
                      <TableSortLabel
                        active={orderBy === "createdDateTime"}
                        direction={orderBy === "createdDateTime" ? order : "asc"}
                        onClick={() => handleRequestSort("createdDateTime")}>
                        Created Date
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((product: any) => (
                    <TableRow
                      className={classes.tableRow}
                      key={product.aftermarketProductId}
                      onClick={() => history.push(`/aftermarket/edit/${product.aftermarketProductId}`)}>
                      {!isPartnerAccount && <TableCell>{getProductVendorName(product, account.name)}</TableCell>}
                      <TableCell>{product.productName}</TableCell>
                      <TableCell>
                        <ProposalProductsCardTypeChip
                          productCategory={product.productCategory}
                          productType={product.productType}
                        />
                      </TableCell>
                      <TableCell>{product.productDescriptionExternal}</TableCell>
                      <TableCell>{product.productCost}</TableCell>
                      <TableCell align="center">
                        {format(new Date(parseInt(product.createdDateTime)), "MM/dd/yyyy HH:ii:ss")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ),
    },
    {
      label: AftermarketPageTabs.MenuTemplates,
      value: AftermarketPageTabs.MenuTemplates,
      isVisible: true,
      component: <AftermarketMenuTemplatesPage />,
    },
    {
      label: AftermarketPageTabs.Dashboard,
      value: AftermarketPageTabs.Dashboard,
      isVisible: true,
      component: (
        <Typography variant="h6">
          Please contact your TRNSACT Account Manager to set up your aftermarket product dashboard.
        </Typography>
      ),
    },
  ];

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <>
        <CardContainer title="Aftermarket" containerClassName={classes.aftermarketContainerClassName}></CardContainer>
        <TabsContainer<AftermarketPageTabs>
          tabs={tabs}
          initialTab={initialTab}
          TabsLabelWrapper={CardBackground}
          onTabChange={handleChangeTab}
        />
      </>
    );
  }
};

const mapStateToProps = (state: Props) => ({
  account: state.account,
  userProfile: state.userProfile,
  portalConfiguration: state.portalConfiguration,
});

export default connect(mapStateToProps, null)(ListAftermarketProducts);

const useStyles = makeStyles(() => ({
  tableRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
  aftermarketContainerClassName: {
    gap: 0,
    marginBottom: 6,
    boxShadow: "none",
  },
}));
