import React from "react";
import { useDrag } from "react-dnd";
import { ControlTypes } from "../../untils/types";
import { Element } from "./Element";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@mui/material";
import { ocaLegacyReferenceFields } from "../../../../pages/Prequal/constants";
import _ from "lodash";
const useStyles = makeStyles(() => ({
  draggableContainer: {
    cursor: "move",
    fontSize: "12px",
    backgroundColor: "#d9d5e1",
    borderRadius: "10px",
    border: "1px dashed #9596b7",
    margin: "5px",
  },
  draggableElement: {
    pointerEvents: "none",
  },
  fieldIdentifierTag: {
    border: "1px solid #000",
    display: "inline-block",
    width: "100%",
    padding: "5px 0",
    backgroundColor: "#8dcb5d",
    borderRadius: "5px",
    marginBottom: "10px",
    textAlign: "center",
    boxShadow: "2px 3px #a19121",
  },
  labelLegacy: {
    backgroundColor: "#eb2521",
    boxShadow: "none",
  },
  applicableSectionTypes: {
    margin: "10px 0 0",
    fontWeight: "bold",
    padding: "0 3px",
  },
}));

export function DraggableControl({ item, selectNewElement, blockName }) {
  const classes = useStyles();
  const [{ handlerId, isDragging }, drag] = useDrag(() => {
    let type = ControlTypes.CONTROL;
    if (item.type === "owners-array") {
      type = ControlTypes.OWNERS;
    }
    if (item.type === "signatures-simple") {
      type = ControlTypes.SIGNATURE;
    }
    return {
      type,
      item,
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        };
      },
    };
  });
  const isTitle = _.get(item, "type", null) === "title";
  const isSpacerField = _.get(item, "type", null) === "spacer-field";
  const isField = !isTitle && !isSpacerField;
  const isLegacyField = _.includes(ocaLegacyReferenceFields, item.config.fieldName);
  const applicableSectionTypes = _.some(item.sectionTypes) ? item.sectionTypes : ["common"];

  const getSectionTypeHumanReadable = sectionType => {
    const sectionTypeHumanReadable = {
      common: "Simple",
      "co-applicant": "Co-Applicant",
      "owners-array": "Owners",
      "dynamic-reference": "Dynamic Reference",
      "dynamic-signatures": "Dynamic Signatures",
    };
    return sectionTypeHumanReadable[sectionType] ? sectionTypeHumanReadable[sectionType] : sectionType;
  };

  return (
    <Tooltip title={blockName} placement="top-start">
      <div ref={drag} className={classes.draggableContainer} data-handler-id={handlerId}>
        <div className={classes.draggableElement} style={{ opacity: isDragging ? 0.5 : 1 }}>
          {isField ? (
            <span className={[classes.fieldIdentifierTag, isLegacyField ? classes.labelLegacy : ""].join(" ")}>
              API ID: <b>{item.config.fieldName}</b>
              {isLegacyField && <span> - (Legacy field)</span>}
            </span>
          ) : null}
          <Element item={item} selectNewElement={selectNewElement} />
          {isField && (
            <p className={classes.applicableSectionTypes}>
              Applicable block types: {_(applicableSectionTypes).map(getSectionTypeHumanReadable).join(", ")}
            </p>
          )}
        </div>
      </div>
    </Tooltip>
  );
}
