import gql from "graphql-tag";

export const GET_AFTERMARKET_PRODUCTS = gql`
  query GetAftermarketProducts($accountId: ID!) {
    aftermarketProducts(accountId: $accountId) {
      partnerLinks {
        name
      }
      id: aftermarketProductId
      aftermarketVendorApiChannel
    }
  }
`;
