import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import Tile from "../../components/Tile/Tile";
import { Business } from "@mui/icons-material";
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Autocomplete,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ColorSchemaPicker from "../../components/ColorSchemaPicker";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import { defaultColorSchema, getS3ImageUrl, vendorContactRoleHumanReadable } from "utils";
import { adminRoles } from "../Prequal/constants";
import _ from "lodash";

const UPSERT_LOCATION = gql`
  mutation ($input: UpsertLocationInput!, $file: Upload) {
    upsertLocation(input: $input, file: $file)
  }
`;

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

export default function CreateLocationModal(props) {
  const { locationsList, account, closeCreateLocationModal, locationDetails } = props;

  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);

  const userProfile = useSelector(state => state.userProfile);
  const vp = useSelector(state => state.vp);

  const [isUpsertingLocation, setUpsertingLocation] = useState();
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [locationName, setLocationName] = useState(_.get(locationDetails, "location.locationName", undefined));
  const [primaryContact, setPrimaryContact] = useState(_.get(locationDetails, "location.ocaContact", undefined));
  let [locationType, setLocationType] = useState(_.get(locationDetails, "location.category", undefined));
  const [logoFile, setLogoFile] = useState(null);
  const locationLogo = _.get(locationDetails, "location.logo");
  const [locationLogoUrl, setLocationLogoUrl] = useState(getS3ImageUrl(locationLogo));
  const locationColorSchema = _.get(locationDetails, "location.colorSchema");
  // Take locationColorSchema (when editing location), VP styles, or defaultColorSchema
  const [colorSchema, setColorSchema] = useState(_.merge({}, vp?.styles || defaultColorSchema, locationColorSchema));
  const [creditManagerId, setCreditManagerId] = useState(_.get(locationDetails, "location.creditManagerId"));
  const [salesManagerId, setSalesManagerId] = useState(_.get(locationDetails, "location.salesManagerId"));
  const [ocaShowOnlyChildLocations, setOcaShowOnlyChildLocations] = useState(
    _.get(locationDetails, "location.ocaShowOnlyChildLocations", false)
  );
  const [ocaHidden, setOcaHidden] = useState(_.get(locationDetails, "location.ocaHidden", false));
  const { isDealerUser } = useContext(CommonDataContext);

  const superAdminRoles = [adminRoles.super, adminRoles.singleAccountOnly];
  const isUserAbleToUpdateLogoAndOCAColors = _.includes(superAdminRoles, userProfile.adminRole);
  const isUserAbleToUpdateOCABehaviour = _.includes(superAdminRoles, userProfile.adminRole);

  const parentLocationFromLocationDetail = _.get(locationDetails, "location.parentLocation", undefined);
  let parentLocationForEditForm = undefined;
  if (parentLocationFromLocationDetail) {
    parentLocationForEditForm = locationsList.find(
      location => location.locationId === parentLocationFromLocationDetail.locationId
    );
  }
  const [parentLocation, setParentLocation] = useState(parentLocationForEditForm);

  const childLocationsForEditForm = locationsList.filter(l =>
    _.get(locationDetails, "location.childrenLocation", []).find(cl => cl.locationId === l.locationId)
  );
  const [childLocations, setChildLocations] = useState(childLocationsForEditForm);

  const locationDetailsCategory = _.get(locationDetails, "location.category", undefined);
  const [childLocationsDisabled, setChildLocationsDisabled] = useState(
    locationDetailsCategory === "Branch" || !locationDetails ? true : false
  );
  const [parentLocationDisabled, setParentLocationDisabled] = useState(
    locationDetailsCategory === "Master" ? true : false
  );

  const handleParentLocationChange = event => setParentLocation(event.target.value);

  const ocaContactFromLocationDetail = _.get(locationDetails, "location.ocaContact", undefined);
  let primaryContactForEditForm = undefined;
  if (ocaContactFromLocationDetail) {
    primaryContactForEditForm = _.get(account, "contacts", []).find(
      contact => contact.dynamicsContactId === ocaContactFromLocationDetail.dynamicsContactId
    );
  }

  const contactsListToAssign = isDealerUser
    ? _(account.contacts)
        .filter("vendorContactRole")
        .orderBy(["vendorContactRole", contact => contact.fullName.toLowerCase()])
        .value()
    : _(account.contacts)
        .orderBy([contact => contact.fullName.toLowerCase()])
        .value();

  const [upsertLocationRequest] = useMutation(UPSERT_LOCATION, {
    context: { authRequired: true },
  });

  const handleSubmit = async () => {
    try {
      setSubmitWasClicked(true);

      if (!locationName || locationName.trim() === "" || !locationType || !primaryContact) {
        return;
      }

      setUpsertingLocation(true);

      const input = {
        locationName,
        category: locationType,
        ocaContactId: primaryContact.id,
        creditManagerId,
        salesManagerId,
        ocaShowOnlyChildLocations,
        ocaHidden,
        colorSchema,
      };

      if (parentLocation) {
        input.locationParentId = parentLocation.locationId;
      }

      if (childLocations) {
        input.locationChildrenIds = childLocations.map(cl => cl.locationId);
      }

      if (input.category === "Branch") {
        input.locationChildrenIds = [];
      }

      if (locationDetails) {
        input.locationId = _.get(locationDetails, "location.locationId", undefined);
      }

      const upsertLocationResult = await upsertLocationRequest({
        variables: {
          input,
          file: logoFile,
          context: { authRequired: true },
        },
      });

      closeCreateLocationModal({
        locationId: _.get(upsertLocationResult, "data.upsertLocation", undefined),
        locationWasNewlyCreated: !locationDetails,
      });
    } catch (e) {
      console.log(`ERROR: ${e.message}`);
      setErrorMessage(
        "There was a problem creating your location. Please try again. If this error persists contact support."
      );
      setUpsertingLocation(false);
      setOpenErrorSnackbar(true);
    }
  };

  function handleErrorSnackbarClose() {
    setOpenErrorSnackbar(false);
  }

  const handleLocationNameChange = event => setLocationName(event.target.value);

  const handleLocationTypeChange = event => {
    setLocationType(event.target.value);
    locationType = event.target.value;
    updateChildAndParentLocationChoices();
  };

  function updateChildAndParentLocationChoices() {
    setParentLocationDisabled(locationsList.filter(filterPossibleParents).length === 0);
    setChildLocationsDisabled(locationsList.filter(filterPossibleChildLocations).length === 0);
  }

  function createPossibleChildrenLocations() {
    const possibleChildLocations = locationsList.filter(filterPossibleChildLocations);
    let regions = possibleChildLocations.filter(l => l.category === "Region");
    let branches = possibleChildLocations.filter(l => l.category === "Branch");

    if (regions.length > 0) {
      regions = regions.map(l => (
        <MenuItem key={l.locationId} value={l} classes={{ root: classes.menuItemSubItem }}>
          {l.locationName}
        </MenuItem>
      ));
      regions = [
        <MenuItem disabled classes={{ root: classes.menuItemHeader }}>
          Regions
        </MenuItem>,
      ].concat(regions);
    }

    if (branches.length > 0) {
      branches = branches.map(l => (
        <MenuItem key={l.locationId} value={l} classes={{ root: classes.menuItemSubItem }}>
          {l.locationName}
        </MenuItem>
      ));
      branches = [
        <MenuItem disabled classes={{ root: classes.menuItemHeader }}>
          Branches
        </MenuItem>,
      ].concat(branches);
    }

    return regions.concat(branches);
  }

  const handleChildLocationChange = event => {
    const {
      target: { value },
    } = event;
    setChildLocations(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const filterPossibleParents = location => {
    if (locationType === "Master") {
      return false; // master can't have parents
    }
    if (location.locationId === _.get(locationDetails, "location.locationId")) {
      return false; // current location
    }
    if (_.some(childLocations, childLocation => childLocation.locationId === location.locationId)) {
      return false; // child location
    }
    return true;
  };

  const filterPossibleChildLocations = location => {
    if (location.category === "Master") {
      return false; // master can't be a child
    }
    if (location.locationId === _.get(locationDetails, "location.locationId")) {
      return false; // current location
    }
    if (parentLocation?.locationId === location?.locationId) {
      return false; // parent location
    }
    return true;
  };

  const uploadFileChanged = ({
    target: {
      files: [file],
    },
  }) => {
    if (file.size > 5242880) {
      setErrorMessage("File exceeds 5MB limit! Please upload a smaller file.");
      setOpenErrorSnackbar(true);
      return;
    }
    setLogoFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => setLocationLogoUrl(e.target.result);
  };

  const uploadLocationLogoHandler = event => {
    const file = document.getElementById("file");
    file.click();
  };

  return (
    <div style={modalStyle} className={classes.surface}>
      <Tile
        title={locationDetails ? "Edit Location" : "Create New Location"}
        maxHeightLimitByScreen={true}
        titleIcon={<Business />}>
        {isUpsertingLocation && <span className={classes.wrapperLoader}>{<LinearProgress size={34} />}</span>}
        {!isUpsertingLocation && (
          <div className={classes.formContainer}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2} className={classes.formContainerGridContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    value={locationName}
                    label="Location Name"
                    placeholder="Enter location..."
                    onChange={handleLocationNameChange}
                    error={!locationName && submitWasClicked}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={contactsListToAssign}
                      groupBy={contact => vendorContactRoleHumanReadable[contact.vendorContactRole]}
                      getOptionLabel={contact => `${contact.fullName} - ${contact.email}`}
                      renderInput={params => <TextField {...params} label="Primary Contact" />}
                      defaultValue={primaryContactForEditForm || null}
                      onChange={(event, newValue) => setPrimaryContact(newValue)}
                      disableClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={contactsListToAssign}
                      groupBy={contact => vendorContactRoleHumanReadable[contact.vendorContactRole]}
                      getOptionLabel={contact => `${contact.fullName} - ${contact.email}`}
                      renderInput={params => <TextField {...params} label="Finance Manager" />}
                      value={_.find(contactsListToAssign, { id: creditManagerId }) || null}
                      onChange={(event, newValue) => setCreditManagerId(_.get(newValue, "id"))}
                      disableClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={contactsListToAssign}
                      groupBy={contact => vendorContactRoleHumanReadable[contact.vendorContactRole]}
                      getOptionLabel={contact => `${contact.fullName} - ${contact.email}`}
                      renderInput={params => <TextField {...params} label="Sales Manager" />}
                      value={_.find(contactsListToAssign, { id: salesManagerId }) || null}
                      onChange={(event, newValue) => setSalesManagerId(_.get(newValue, "id"))}
                      disableClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      style={!locationType && submitWasClicked ? { color: "red" } : {}}
                      htmlFor="locationType">
                      Location Type
                    </InputLabel>
                    <Select
                      fullWidth
                      id="locationType"
                      label="Location Type"
                      onChange={handleLocationTypeChange}
                      disabled={_.get(locationDetails, "location.category", "") === "Master" ? true : false}
                      value={locationType}
                      inputProps={{
                        name: "locationType",
                        id: "locationType",
                      }}>
                      {_.get(locationDetails, "location.category", "") === "Master" && (
                        <MenuItem disabled={true} value={"Master"}>
                          Master
                        </MenuItem>
                      )}
                      {locationsList && !locationsList.find(location => location.category === "Master") && (
                        <MenuItem value={"Master"}>Master</MenuItem>
                      )}
                      <MenuItem value={"Region"}>Region</MenuItem>
                      <MenuItem value={"Branch"}>Branch</MenuItem>
                      <MenuItem value={"Division"}>Division</MenuItem>
                      <MenuItem value={"Department"}>Department</MenuItem>
                      <MenuItem value={"State"}>State</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="parentLocationId">
                      Assign Parent Location
                    </InputLabel>
                    <Select
                      labelId="parentLocationId"
                      label="Assign Parent Location"
                      onChange={handleParentLocationChange}
                      disabled={parentLocationDisabled}
                      inputProps={{
                        name: "parentLocationId",
                        id: "parentLocationId",
                        defaultValue: parentLocationForEditForm,
                      }}>
                      {locationsList &&
                        locationsList.filter(filterPossibleParents).map(location => (
                          <MenuItem key={location.locationId} value={location}>
                            {location.locationName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!childLocationsDisabled && (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="simple-select">
                        Assign Child Locations
                      </InputLabel>
                      <Select
                        labelId="child-locations-label"
                        id="child-locations"
                        multiple
                        value={childLocations}
                        onChange={handleChildLocationChange}
                        disabled={childLocationsDisabled}>
                        {createPossibleChildrenLocations()}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {isUserAbleToUpdateOCABehaviour && _.get(locationDetails, "location.category", "") !== "Master" && (
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ocaShowOnlyChildLocations}
                            onChange={() => setOcaShowOnlyChildLocations(!ocaShowOnlyChildLocations)}
                            name="Show only child locations on OCA"
                            color="primary"
                          />
                        }
                        label="Show only child locations on OCA"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ocaHidden}
                            onChange={() => setOcaHidden(!ocaHidden)}
                            name="Hide Location from OCA"
                            color="primary"
                          />
                        }
                        label="Hide Location from OCA"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container xs={12}>
                  <InputLabel>OCA Color Schema:</InputLabel>
                  {isUserAbleToUpdateLogoAndOCAColors ? (
                    <ColorSchemaPicker colorSchema={colorSchema} setColorSchema={setColorSchema} />
                  ) : (
                    <span style={{ marginLeft: "10px" }}>Please contact your Admin to change</span>
                  )}
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Grid item container xs={12}>
                    <input
                      accept="image/*"
                      id="file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={uploadFileChanged}
                    />
                    <InputLabel>Location Logo</InputLabel>
                    {!isUserAbleToUpdateLogoAndOCAColors && (
                      <span style={{ marginLeft: "10px" }}>Please contact your Admin to change</span>
                    )}
                  </Grid>
                  {locationLogoUrl && (
                    <Grid item xs={4} container alignItems="center" style={{ margin: "16px 0 0" }}>
                      <img src={locationLogoUrl} alt="Location Logo" id="logo" className={classes.locationLogo} />
                    </Grid>
                  )}
                  <Grid item container xs={8} style={{ alignSelf: "flex-end" }}>
                    {isUserAbleToUpdateLogoAndOCAColors && (
                      <Button startIcon={<PhotoCameraIcon />} variant="outlined" onClick={uploadLocationLogoHandler}>
                        Upload
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Stack direction="row" gap="1rem" justifyContent="flex-end">
                <Button color="error" onClick={closeCreateLocationModal}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} data-test-id="submit-btn">
                  Confirm
                </Button>
              </Stack>
            </form>

            <Snackbar
              style={{ marginTop: "50px" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openErrorSnackbar}
              onClose={handleErrorSnackbarClose}>
              <Alert severity="error" onClose={handleErrorSnackbarClose} variant="filled">
                {errorMessage}
              </Alert>
            </Snackbar>
          </div>
        )}
      </Tile>
    </div>
  );
}

const useStyles = makeStyles({
  surface: {
    position: "absolute",
    minWidth: 600,
    maxWidth: "90%",
  },
  menuItemHeader: {
    color: "black",
    fontWeight: 900,
    opacity: "1 !important",
  },
  menuItemSubItem: {
    paddingLeft: "35px",
  },
  locationLogo: {
    width: "220px",
    objectFit: "contain",
  },
});
