import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProductsFiltersAftermarketPartner, ProductsFiltersDealer, ShowTypes } from "../types";

export function proposalProductsFilterDealer(product: ProposalProduct, filters: ProductsFiltersDealer) {
  const searchResult = product.title?.toLowerCase()?.includes(filters?.search?.toLowerCase() ?? "");

  const showResult = (product.aftermarketProduct?.productCategory as string) === filters?.showOnly;

  const vendorsResult = filters?.vendors?.includes(product.aftermarketProduct?.aftermarketVendorApiChannel as string);

  return (
    searchResult &&
    (filters?.showOnly === ShowTypes.All || showResult) &&
    (filters?.vendors.length ? vendorsResult : true)
  );
}

export function proposalProductsFiltersAftermarketPartner(
  product: ProposalProduct,
  filters: ProductsFiltersAftermarketPartner
) {
  const searchResult = product.title?.toLowerCase()?.includes(filters?.search?.toLowerCase() ?? "");

  const showResult = (product.aftermarketProduct?.productCategory as string) === filters?.showOnly;

  return searchResult && (filters?.showOnly === ShowTypes.All || showResult);
}
