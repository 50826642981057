import { ModalsKeys } from "global";
import { Button } from "@mui/material";
import { logError } from "utils/logger";
import { useModal } from "hooks/useModal";
import { useMutation } from "@apollo/client";
import { useNotifications } from "modules/notification";
import { isPhoneValid } from "modules/creditApplicationDetails/lib";
import { getOperationName } from "@apollo/client/utilities";
import { CREATE_PROPOSAL_V2, GET_PROPOSALS_TABLE_DATA } from "../api";
import { CreateProposalResponse, CreateProposalVariables } from "../api/types";

export function useCreateProposal() {
  const { showNotification } = useNotifications();

  const { handleOpen: handleOpenConfirmationModal, handleClose: handleCloseConfirmationModal } = useModal(
    ModalsKeys.Confirmation
  );

  const [onCreateProposal, { loading: isProposalLoading }] = useMutation<
    CreateProposalResponse,
    CreateProposalVariables
  >(CREATE_PROPOSAL_V2, {
    refetchQueries: [getOperationName(GET_PROPOSALS_TABLE_DATA)!],
  });

  const showPhoneNumberNotificationModal = () => {
    handleOpenConfirmationModal({
      title: "Incorrect phone number",
      message: "Please check and set the correct phone number for customer.",
      actionButtons: (
        <Button size="small" color="primary" variant="outlined" onClick={handleCloseConfirmationModal}>
          Close
        </Button>
      ),
    });
  };

  const handleCreateProposal = async (variables: CreateProposalVariables, resetDesking?: VoidFunction) => {
    try {
      const { sendViaText, phoneNumber } = variables.input.sendProposalData ?? {};

      if (sendViaText && (!phoneNumber || !isPhoneValid(phoneNumber))) {
        showPhoneNumberNotificationModal();
        return;
      }

      const { data } = await onCreateProposal({ variables });

      if (data?.createProposalV2?.id) {
        showNotification("Proposal created!");
        resetDesking?.();
      } else {
        showNotification(
          "Proposal not created, Buy Rate, Amount Financed, and/or Term are missing, please check and try again!",
          {
            type: "warning",
            duration: 5000,
          }
        );
      }
    } catch (error) {
      logError(error);
    }
  };
  return {
    isProposalLoading,
    handleCreateProposal,
  };
}
