import React from "react";
import { Box, TableCell, Typography } from "@mui/material";

interface JsonRendererProps {
  data: unknown;
  level?: number;
}

const JsonRenderer = ({ data, level = 0 }: JsonRendererProps) => {
  if (typeof data === "string" || typeof data === "number" || typeof data === "boolean") {
    return <Typography>{String(data)}</Typography>;
  }

  if (Array.isArray(data)) {
    return (
      <ul className="ml-4 list-disc">
        {data.map((item, index) => (
          <li key={index}>
            <JsonRenderer data={item} level={level + 1} />
          </li>
        ))}
      </ul>
    );
  }

  if (data instanceof Object) {
    return (
      <Box>
        {Object.entries(data).map(([key, value]) => (
          <Box style={{ display: "flex", gap: "0.5rem" }} key={key}>
            <Typography>{key}:</Typography>
            <JsonRenderer data={value} level={level + 1} />
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

export const DynamicJsonViewer = ({ json }: { json: Record<string, any> }) => {
  return (
    <TableCell>
      <JsonRenderer data={json} />
    </TableCell>
  );
};
