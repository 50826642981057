import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { lightGreen, orange, teal } from "@mui/material/colors";
import { MarkupValues } from "./MarkupValues";
import { formatCurrency } from "../../../../../utils";
import { CommonMenuPriceValues } from "../../../types";

export const CardPriceWithBg = ({ isTotal = false, markup, retailCost, cost }: CommonMenuPriceValues) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardPrice}>
      <Box className={clsx("priceBlock", "cost")}>
        {isTotal && (
          <Typography component="span" variant="body2" color="textSecondary">
            Total
          </Typography>
        )}

        <Typography component="span" variant="body2" color="textSecondary">
          Cost
        </Typography>

        <Typography component="span">{formatCurrency(cost, true)}</Typography>
      </Box>

      <Box className={clsx("priceBlock", "markup")}>
        {isTotal && (
          <Typography component="span" variant="body2" color="textSecondary">
            Total
          </Typography>
        )}

        <Typography component="span" variant="body2" color="textSecondary">
          Markup
        </Typography>

        <Typography component="span">
          <MarkupValues markup={markup} />
        </Typography>
      </Box>

      <Box className={clsx("priceBlock", "price")}>
        {isTotal && (
          <Typography component="span" variant="body2" color="textSecondary">
            Total
          </Typography>
        )}

        <Typography component="span" variant="body2" color="textSecondary">
          Retail Price
        </Typography>

        <Typography component="span">{formatCurrency(retailCost, true)}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>({
  cardPrice: {
    gap: "4px",
    display: "flex",
    padding: "4px",
    justifyContent: "space-around",
    borderTop: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",

    "& .priceBlock": {
      flex: 1,
      padding: "4px",
      display: "flex",
      maxWidth: "33%",
      overflow: "hidden",
      borderRadius: "2px",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",

      "& .MuiTypography-root": {
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    "& .cost": {
      backgroundColor: orange["50"],
    },

    "& .markup": {
      backgroundColor: teal["50"],
    },

    "& .price": {
      backgroundColor: lightGreen["50"],
    },
  },
});
