import { Nullable, ValueOf } from "global";
import { PaymentOptionsConfigurationTermsAndRatesInput, ProposalSaleType } from "@trnsact/trnsact-shared-types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";
import { AftermarketMenu, FinanceProgramFormValues, Markup, MarkupMethods, ProductConfig, Structures } from "../types";
import {
  CalculatePaymentCashVariables,
  CalculatePaymentFinanceVariables,
  CalculatePaymentMenuVariables,
} from "../api/types";

interface ConvertParams {
  proposalMenu: Nullable<AftermarketMenu>;
  proposalProductConfigurationsInMenu: ProductConfig;
  program: ValueOf<FinanceProgramFormValues, "financeQuote">;
  saleDetails: ValueOf<FinanceProgramFormValues, "saleDetails">;
  submission: ValueOf<FinanceProgramFormValues, "submission">;
  financeProgramDescription: string;
}

export function collectFinanceVariablesForCalc({
  program,
  submission,
  saleDetails,
  proposalMenu,
  financeProgramDescription,
  proposalProductConfigurationsInMenu,
}: ConvertParams): CalculatePaymentFinanceVariables {
  const prepareMenu: CalculatePaymentMenuVariables = {
    name: proposalMenu?.name ?? "",
    description: proposalMenu?.description ?? "",
    menuOptions: prepareMenuOptionsForAPI(
      proposalMenu?.menuOptions ?? [],
      financeProgramDescription,
      proposalProductConfigurationsInMenu
    ),
  };

  const terms: PaymentOptionsConfigurationTermsAndRatesInput[] = [];

  if (program.specifyRate && program.specifyRates.length > 0) {
    terms.push(...program.specifyRates.map(termValue => ({ term: Number(termValue.term), rate: +termValue.rate })));

    if (program.customTermTemporaryValue) {
      terms.push({
        term: +program.customTermTemporaryValue,
        rate: 0,
      });
    }
  } else {
    terms.push(
      ...[...program.terms, program.customTermTemporaryValue]
        .filter(Boolean)
        .map(t => ({ term: Number(t), rate: +program.commonRate }))
    );
  }

  let dealerMarkup: Nullable<Markup> = null;

  if (program.structure !== Structures.None) {
    if (program.markupMethod === MarkupMethods.MarkupPercentage) {
      dealerMarkup = { identifier: "Dealer Markup", markupPercentage: Number(program.markupRate) };
    } else if (program.markupMethod === MarkupMethods.MarkupDollar) {
      dealerMarkup = { identifier: "Dealer Markup", markupFlat: Number(program.markupRate) };
    }
  }

  let startDate: string | undefined = undefined;

  try {
    startDate = new Date(program.startDate).toISOString().split("T")[0];
  } catch (e: any) {
    console.error("convertProgramToCalculateVariables: Error converting start date to ISO string", e.message);
    startDate = new Date().toISOString().split("T")[0];
  }

  const convertedProgram = {
    input: {
      finance: {
        saleType: proposalMenu ? ProposalSaleType.FinanceWithAftermarketMenu : ProposalSaleType.FinanceOnly,
        salesTaxPercentage: 0,
        numberOfAdvancePayments: Number(program.advancePayment),
        financeProgramType: program.programType,
        rateType: program.rateType,
        financeProgramName: program.programName,
        amountFinanced: saleDetails.financeAmount,
        downPaymentAmount: saleDetails.downPayment,
        residualPercentage: Number(program.residual),
        maxApprovalAmount: submission.maxApprovalAmount,
        daysToFirstPayment: Number(program.daysToFirstPayment),
        startDate,
        menu: proposalMenu ? prepareMenu : undefined,
        customerRate:
          program.structure !== Structures.None && program.markupMethod === MarkupMethods.CustomerRate
            ? Number(program.markupRate)
            : null,
        dealerMarkup,
        paymentPeriodTiming: program.paymentCalculationMethod,
        markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
        terms,
        flatFees: program.fee.map(feeValue => ({
          identifier: feeValue.identifier,
          financed: feeValue.financed,
          fee: Number(feeValue.fee),
        })),
      },
    },
  };
  return convertedProgram;
}

export function collectCashVariablesForCalc({
  program,
  saleDetails,
  proposalMenu,
  financeProgramDescription,
  proposalProductConfigurationsInMenu,
}: ConvertParams): CalculatePaymentCashVariables {
  const prepareMenu: CalculatePaymentMenuVariables = {
    name: proposalMenu?.name ?? "",
    description: proposalMenu?.description ?? "",
    menuOptions: prepareMenuOptionsForAPI(
      proposalMenu?.menuOptions ?? [],
      financeProgramDescription,
      proposalProductConfigurationsInMenu
    ),
  };

  return {
    input: {
      cash: {
        menu: prepareMenu,
        amountFinanced: saleDetails.financeAmount,
        saleType: ProposalSaleType.CashWithAftermarketMenu,
        flatFees: program.fee.map(feeValue => ({
          identifier: feeValue.identifier,
          financed: feeValue.financed,
          fee: Number(feeValue.fee),
        })),
      },
    },
  };
}
