import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";
function getBlurHanlder(type) {
  switch (type) {
    case "float":
      return value => {
        const parsed = parseFloat(value);
        const float = Number.isNaN(parsed) ? 0 : parsed;

        return float.toFixed(2);
      };
    case "integer":
      return value => value.replace(/[^0-9]/g, "");
    default:
      return value => value;
  }
}

function getInitialValue(type) {
  return ["float", "integer"].includes(type) ? 0 : "";
}

function getType(type) {
  return ["float", "integer"].includes(type) ? "number" : "text";
}

export function TypedField({ fieldConfig }) {
  const [value, setValue] = useState(getInitialValue(fieldConfig.type));

  const type = getType(fieldConfig.type);

  return (
    <TextField
      style={{ pointerEvents: "none" }}
      disabled={fieldConfig.disabled}
      fullWidth={fieldConfig.fullWidth}
      variant="standard"
      type={type}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      helperText={_.get(fieldConfig, "helperText.en", "")}
      required={fieldConfig.required}
    />
  );
}
