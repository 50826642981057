import { useCallback } from "react";
import { ValueOf } from "global";
import { logError } from "utils/logger";
import { useMutation } from "@apollo/client";
import { TRIGGER_CREDIT_REPORT_BUSINESS_PULL, TRIGGER_WEBHOOK, TRIGGER_CREDIT_REPORT_CONSUMER_PULL } from "../api";
import {
  PullBusinessCreditReportResponse,
  PullBusinessCreditReportVariables,
  PullConsumerCreditReportResponse,
  PullConsumerCreditReportVariables,
  WebhookVariables,
} from "../api/types";

export function useHealthCheck() {
  const [pullBusinessCreditReport, { loading: isBusinessCreditReportPilling }] = useMutation<
    PullBusinessCreditReportResponse,
    PullBusinessCreditReportVariables
  >(TRIGGER_CREDIT_REPORT_BUSINESS_PULL, { context: { authRequired: false } });

  const [pullConsumerCreditReport, { loading: isConsumerCreditReportPilling }] = useMutation<
    PullConsumerCreditReportResponse,
    PullConsumerCreditReportVariables
  >(TRIGGER_CREDIT_REPORT_CONSUMER_PULL, { context: { authRequired: false } });

  const [triggerWebhook, { loading: isWebhookTriggering }] = useMutation<void, WebhookVariables>(TRIGGER_WEBHOOK, {
    context: { authReqpullBusinessCreditReportuired: false },
  });

  const handlePullBusinessCreditReport = useCallback(
    async (variables: ValueOf<PullBusinessCreditReportVariables, "input">) => {
      try {
        await pullBusinessCreditReport({ variables: { input: variables } });
      } catch (error) {
        logError(error);
      }
    },
    [pullBusinessCreditReport]
  );

  const handlePullConsumerCreditReport = useCallback(
    async (variables: ValueOf<PullConsumerCreditReportVariables, "input">) => {
      try {
        await pullConsumerCreditReport({ variables: { input: variables } });
      } catch (error) {
        logError(error);
      }
    },
    [pullConsumerCreditReport]
  );

  const handleTriggerWebhook = useCallback(
    async (variables: WebhookVariables) => {
      try {
        await triggerWebhook({ variables });
      } catch (error) {
        logError(error);
      }
    },
    [triggerWebhook]
  );

  return {
    isWebhookTriggering,
    handleTriggerWebhook,
    isConsumerCreditReportPilling,
    isBusinessCreditReportPilling,
    handlePullConsumerCreditReport,
    handlePullBusinessCreditReport,
  };
}
