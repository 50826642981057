import React from "react";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Radio, Typography } from "@mui/material";
import { allFacts } from "../../../../constants";
import { FinanceProgramForm } from "../../../../schema";
import { FinanceCriteriaOptions } from "../../../../types";
import { mapCriteriaFormRulesToValue } from "../../../../lib";
import { AutocompleteField } from "../../../../../../components/form";

interface Props {
  isReadOnly: boolean;
  criteriaOptions: FinanceCriteriaOptions[];
}

export const CriteriaAutocompleteField = ({ criteriaOptions, isReadOnly }: Props) => {
  const classes = useStyles();

  const { control, setValue } = useFormContext<FinanceProgramForm>();

  const handleChange = (e: React.ChangeEvent<{}>, value: FinanceCriteriaOptions | null) => {
    setValue("program.third.criteria", value);

    if (value && Array.isArray(value?.formRules)) {
      setValue("program.third.conditions", mapCriteriaFormRulesToValue(value.formRules));
    } else {
      setValue("program.third.conditions", []);
    }
  };

  return (
    <AutocompleteField<FinanceCriteriaOptions, false, FinanceProgramForm>
      control={control}
      onChange={handleChange}
      options={criteriaOptions}
      name="program.third.criteria"
      disabled={isReadOnly || !criteriaOptions?.length}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      textFieldProps={{
        label: "Import criteria",
        placeholder: "Search by name...",
      }}
      renderOption={(props, option, state, ownerState) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} {...optionProps} className={classes.option}>
            <Box className="section">
              <Radio size="small" checked={state.selected} />

              <Typography component="span" variant="body1">
                {option.label}
              </Typography>
            </Box>

            <Box className="section">
              <Typography component="span" variant="body2">
                Criteria:{" "}
                {!!option?.formRules?.length
                  ? option?.formRules
                      .map((rule: any) => allFacts.find(({ value }) => value === rule.fact)?.label)
                      .filter(Boolean)
                      .join(", ")
                  : "-"}
              </Typography>
            </Box>

            <Box className={clsx("section", "sectionThree")}>
              <Typography component="span" variant="caption" color="textSecondary">
                {option.createdDateTime}
              </Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  option: {
    width: "100%",
    display: "flex",
    alignItems: "center",

    "& .section": {
      flex: 1,
      gap: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    "& .sectionThree": {
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },
});
