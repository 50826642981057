import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "mui-rff";
import gql from "graphql-tag";
import { Form } from "react-final-form";
import { Grid, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CurrencyInput from "../../components/CurrencyInput";
import FileUploadModal from "./DecisionFileUploadModal";
import { useMutation } from "@apollo/client";
import Button from "components/CustomButtons/Button";
import MuiButton from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AddOutlined } from "@mui/icons-material";
import { theme } from "../../../../theme";
import { SET_CS_REFETCH } from "../../../../redux/types";

const POST_CREDIT_DECISION = gql`
  mutation ($entityId: String!, $fieldsObj: LenderCreditDecision) {
    postLenderCreditDecision(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;

const decisionOption = [
  { value: 803370000, label: "Awaiting Review" },
  { value: 803370001, label: "Requested Additional Information" },
  { value: 803370004, label: "Pre-Approved" },
  { value: 803370002, label: "Declined" },
  { value: 803370003, label: "Approved" },
];

export const CreditSubmissionsDecisionForm_V2 = props => {
  const { refetch } = props;
  const creditId = props.cs.creditSubId.toString(); // Dynamics ID

  const classes = useStyles();

  const dispatch = useDispatch();
  const refetchCS = useSelector(state => state.cs.refetch);

  const [editDecision, setEditDecision] = useState(false);
  const [triggerCreateProposalV2, setTriggerCreateProposalV2] = useState(false);
  const [showContingencies, setShowContingencies] = useState(false);

  const [postCreditDecision] = useMutation(POST_CREDIT_DECISION);

  const approvedAmount = _.get(props, "cs.approvedAmount");
  const rate = _.get(props, "cs.approvedRate");

  const decisionSelected = _.find(decisionOption, { label: props.cs.decision })?.value;
  const [decisionValue, setDecisionValue] = useState(decisionSelected);

  const validate = values => {
    const errors = {};
    if (!decisionValue) {
      errors.decision = "Required";
    }
    if (values.decision === 803370003) {
      if (!values.approvedAmount) {
        errors.approvedAmount = "Required";
      }
      if (values.rate === "" || values.rate === null) {
        errors.rate = "Required";
      }
    } else if (values.approvedAmount === "$0") {
      errors.approvedAmount = "Amount Approved cannot be $0";
    }
    return errors;
  };

  const onSubmit = async values => {
    try {
      values.rate = parseFloat(values.rate) || 0;
      values.approvedAmount = isNaN(values.approvedAmount) ? 0 : values.approvedAmount;

      const decision = isNaN(decisionValue)
        ? _.get(_.find(decisionOption, { label: decisionValue }), "label", decisionOption[0].value)
        : decisionValue;

      await props.handleOpenModal();
      await props.handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Submitting your request to lenders...",
      });

      setTriggerCreateProposalV2(true);

      await postCreditDecision({
        variables: {
          entityId: creditId,
          fieldsObj: {
            ...values,
            decision,
            masterCreditSubmissionId:
              !!props.cs.masterCreditSubmissionId && props.cs.masterCreditSubmissionId
                ? props.cs.masterCreditSubmissionId
                : null,
          },
        },
      });
      await props.handleSubmissionMessage({
        title: "Success",
        message: "Submitted Successfully",
      });
      setEditDecision(false);
      refetch();
      dispatch({
        type: SET_CS_REFETCH,
        payload: { refetch: !refetchCS },
      });
    } catch (err) {
      console.log(err);
      props.handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          decision: decisionValue,
          approvedAmount: approvedAmount,
          rate: rate,
          notes: _.get(props, "cs.notes"),
          contingencies: _.get(props, "cs.contingencies"),
        }}
        render={({ handleSubmit, form, submitting, rate, approvedAmount }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth sx={{ maxWidth: "calc(50% - 0.5rem)", marginBottom: "1rem" }} variant="standard">
                <InputLabel htmlFor="decision">Decision</InputLabel>
                <Select
                  name="decision"
                  value={decisionValue}
                  onChange={e => {
                    setDecisionValue(e.target.value);
                  }}>
                  {decisionOption.map(({ value, label }, i) => (
                    <MenuItem value={value} key={i}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {!!decisionValue && (
                <>
                  <div className={classes.primaryColor}>
                    {decisionValue === 803370003 && (
                      <Stack direction="row" gap="1rem">
                        <CurrencyInput name="approvedAmount" value={approvedAmount} label="Approved Amount" />

                        <TextField
                          fullWidth
                          name="rate"
                          label="Rate"
                          fieldProps={{
                            value: rate,
                            formatOnBlur: true,
                            format: val => (val ? parseFloat(val).toFixed(2) : ""),
                            parse: val => (val.match(/^\d*\.?\d*$/g, "") ? val : val.slice(0, -1)),
                          }}
                        />
                      </Stack>
                    )}

                    {decisionValue !== 803370000 && (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <TextField
                            name="notes"
                            type="text"
                            label={
                              decisionValue === 803370003 ? "Notes" : decisionValue === 803370002 ? "Reason" : "Request"
                            }
                            defaultValue={props.cs.notes}
                            fullWidth
                            multiline
                            rows={7}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    )}

                    {decisionValue === 803370003 && (
                      <>
                        {showContingencies === false ? (
                          <Grid container spacing={4} style={{ display: "flex", alignItems: "center" }}>
                            <Grid item>
                              <MuiButton
                                onClick={() => setShowContingencies(true)}
                                style={{ color: theme.palette.primary.main }}>
                                <AddOutlined />
                                Add Contingencies
                              </MuiButton>
                            </Grid>
                            <Grid item>
                              <FileUploadModal cs={props.cs} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <TextField
                                name="contingencies"
                                type="text"
                                label="Contingencies (if applicable)"
                                fullWidth
                                multiline
                                rows={7}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <Button onClick={() => setShowContingencies(false)} variant="contained">
                                Cancel
                              </Button>
                              <Grid item>
                                <FileUploadModal cs={props.cs} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </div>

                  {decisionValue !== 803370000 && (
                    <Grid container style={{ display: "flex", gap: 8, justifyContent: "flex-end", marginTop: 24 }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setDecisionValue(null);
                          setShowContingencies(false);
                          form.reset();
                        }}>
                        Cancel
                      </Button>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        {submitting ? "Submitting" : "Submit"}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </form>
          );
        }}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  ...styles,
  ...{
    primaryColor: {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiInputLabel-shrink, & .MuiButtonLabel": {
        color: theme.palette.primary.main,
      },
    },
  },
  container: {
    paddingLeft: "8px !important",
  },
}));
