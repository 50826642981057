import React from "react";
import Tile from "../../components/Tile/Tile";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Mutation } from "@apollo/client/react/components";
import Button from "../../components/CustomButtons/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { legalStructureOption, tibOptions } from "../../utils";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import gql from "graphql-tag";
import analytics from "../../analytics";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { validationTypes } from "../../utils";

class CreditApp extends React.Component {
  createCreditApp = gql`
    mutation ($creditApplication: CreateCreditApplicationInput!) {
      createCreditApplication(input: $creditApplication) {
        id
      }
    }
  `;

  state = {
    form: {
      equipmentItems: [{ id: Date.now(), quantity: "", description: "" }],
      ownershipItems: [{ id: Date.now(), firstName: "", lastName: "", percOwner: "", ssn: "" }],
      numberOfOwners: 1,
      fields: {
        amountRequested: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        businessName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        address: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        city: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        legalStructure: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        dba: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        state: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        postalCode: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        tib: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        poc: {
          value: "other",
          validationStatus: "success",
          validations: [{ type: "required" }],
        },
        firstName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        lastName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        email: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }, { type: "email" }],
        },
        phone: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        title: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
      },
    },
  };

  inputProps(fieldName, onChangeFunc) {
    const value = this.state.form.fields[fieldName].value;
    const onChange = event => {
      this.setValue(event, fieldName);
      this.validateField(fieldName);
      if (!!onChangeFunc) {
        onChangeFunc(event);
      }
    };
    const endAdornment =
      this.state.form.fields[fieldName].validationStatus === "error" ? (
        <InputAdornment position="end">
          <CloseIcon />
        </InputAdornment>
      ) : (
        ""
      );
    return { value, onChange, endAdornment };
  }
  setValue(e, fieldName) {
    const formState = this.state.form;
    const field = formState.fields[fieldName];
    let value = e.target.value;
    if (fieldName === "amountRequested") {
      value = +value || 0;
    }
    formState.fields[fieldName] = { validationStatus: field.validationStatus, validations: field.validations, value };
    this.setState({ form: formState });
  }
  validateField(fieldName) {
    const formState = this.state.form;
    let validationStatus = "success";
    const validations = formState.fields[fieldName].validations;
    const value = formState.fields[fieldName].value;
    validations.forEach(validation => {
      if (!validationTypes[validation.type](value, validation.value)) {
        validationStatus = "error";
      }
    });
    formState.fields[fieldName].validationStatus = validationStatus;
    this.setState({ form: formState });
  }
  checkFormValidation() {
    const fields = this.state.form.fields;
    let validationStatus = true;
    Object.keys(fields).forEach(fieldName => {
      this.validateField(fieldName);
      if (fields[fieldName].validationStatus !== "success") {
        validationStatus = false;
      }
    });
    const equipmentItems = this.state.form.equipmentItems;
    equipmentItems.forEach(item => {
      if (!parseInt(item.quantity) || !item.description) {
        validationStatus = false;
      }
    });
    const ownershipItems = this.state.form.ownershipItems;
    ownershipItems.forEach(item => {
      if (!item.firstName || !item.lastName || !item.percOwner || !item.ssn) {
        validationStatus = false;
      }
    });
    return validationStatus;
  }
  highlightInvalidFields() {
    const formState = this.state.form;
    Object.keys(formState.fields).forEach(fieldName => {
      if (formState.fields[fieldName].validationStatus !== "success") {
        formState.fields[fieldName].validationStatus = "error";
      }
    });
    this.setState({ form: formState });
  }

  addEquipmentItem() {
    const formState = this.state.form;
    formState.equipmentItems.push({
      id: Date.now(),
      quantity: "",
      description: "",
    });
    this.setState({ form: formState });
  }
  removeEquipmentItem(id) {
    const formState = this.state.form;
    formState.equipmentItems = formState.equipmentItems.filter(item => item.id !== id);
    this.setState({ form: formState });
  }
  addOwnershipItem() {
    const formState = this.state.form;
    formState.ownershipItems.push({
      id: Date.now(),
      firstName: "",
      lastName: "",
      percOwner: "",
      ssh: "",
    });
    formState.numberOfOwners++;
    this.setState({ form: formState });
  }
  removeLastOwnershipItem() {
    const formState = this.state.form;
    formState.ownershipItems.length = formState.ownershipItems.length - 1;
    formState.numberOfOwners--;
    this.setState({ form: formState });
  }
  setOwner(e) {
    this.setValue(e, "poc");
    const formState = this.state.form;
    const ownerIndex = formState.fields.poc.value;
    if (ownerIndex === "0" || ownerIndex === "1") {
      formState.fields.firstName.value = formState.ownershipItems[parseInt(ownerIndex)].firstName;
      formState.fields.lastName.value = formState.ownershipItems[parseInt(ownerIndex)].lastName;
    } else {
      formState.fields.firstName.value = "";
      formState.fields.lastName.value = "";
    }
    this.setState({ form: formState });
  }
  setOwnerOption(e, index, optionName) {
    const formState = this.state.form;
    let value = e.target.value;
    if (optionName === "percOwner") {
      value = +value || 0;
    }
    formState.ownershipItems[index][optionName] = value;
    this.setState({ form: formState });
  }
  setEquipmentOption(e, index, optionName) {
    const formState = this.state.form;
    formState.equipmentItems[index][optionName] = e.target.value;
    this.setState({ form: formState });
  }

  render() {
    const classes = this.props.classes;
    const queryContext = { authRequired: true };

    return (
      <React.Fragment>
        <Mutation mutation={this.createCreditApp} context={queryContext}>
          {(createCreditApp, { loading }) => (
            <Tile>
              <div>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.checkFormValidation()) {
                      this.highlightInvalidFields();
                      return false;
                    }
                    analytics.track("credit_application-submit_attempted");
                    const equipmentDescription = this.state.form.equipmentItems
                      .map(({ quantity, description }) => {
                        return `${quantity} - ${description}`;
                      })
                      .join(", \n");
                    const owners = this.state.form.ownershipItems.map(item => {
                      return {
                        firstName: item.firstName,
                        lastName: item.lastName,
                        percOwner: item.percOwner,
                        ssn: item.ssn,
                      };
                    });
                    const { poc } = this.state.form.fields;
                    const values = {
                      owners,
                      ccg_transaction_equipmentdescription: equipmentDescription,
                      ssn: owners[0].ssn,
                    };
                    const fields = this.state.form.fields;
                    Object.keys(fields).forEach(fieldName => (values[fieldName] = fields[fieldName].value));
                    if (typeof poc === "number") {
                      values.firstName = this.state.form.ownershipItems[poc].firstName;
                      values.lastName = this.state.form.ownershipItems[poc].lastName;
                    }

                    delete values.poc;

                    const updatedValues = { ...values, dcrType: "Credit App - Portal" };
                    return new Promise(async (resolve, reject) => {
                      const result = await createCreditApp({
                        variables: { creditApplication: updatedValues },
                      });
                      if (result.data.createCreditApplication.id) {
                        resolve((window.location.href = "/receipt"));
                      } else {
                        reject((window.location.href = "/app-error/"));
                      }
                    });
                  }}>
                  <div>
                    <h3>Equipment</h3>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <CustomInput
                          labelText="Amount Requested"
                          id="amountRequested"
                          formControlProps={{ fullWidth: true }}
                          success={this.state.form.fields.amountRequested.validationStatus === "success"}
                          error={this.state.form.fields.amountRequested.validationStatus === "error"}
                          inputProps={{ type: "text", name: "amountRequested", ...this.inputProps("amountRequested") }}
                        />
                      </Grid>
                    </Grid>
                    {this.state.form.equipmentItems.map((item, index) => (
                      <Grid container spacing={4} key={index}>
                        <Grid item xs={4} sm={2} md={1} lg={1}>
                          <CustomInput
                            labelText="Quantity"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              value: this.state.form.equipmentItems[index].quantity,
                              onChange: e => this.setEquipmentOption(e, index, "quantity"),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={9} md={10} lg={10}>
                          <CustomInput
                            labelText="Description"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              value: this.state.form.equipmentItems[index].description,
                              onChange: e => this.setEquipmentOption(e, index, "description"),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={1}
                          md={1}
                          lg={1}
                          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {index ? (
                            <Button
                              color="danger"
                              simple
                              style={{ padding: "12px" }}
                              onClick={() => this.removeEquipmentItem(item.id)}>
                              <DeleteIcon style={{ margin: "0" }} />
                            </Button>
                          ) : (
                            <Button disabled color="danger" simple style={{ padding: "12px" }}>
                              <DeleteIcon style={{ margin: "0" }} />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <Button color="primary" onClick={() => this.addEquipmentItem()}>
                      Add item
                    </Button>
                  </div>
                  <div>
                    <h3>Business</h3>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                              labelText="Legal Business Name"
                              id="businessName"
                              formControlProps={{ fullWidth: true }}
                              success={this.state.form.fields.businessName.validationStatus === "success"}
                              error={this.state.form.fields.businessName.validationStatus === "error"}
                              inputProps={{ type: "text", name: "businessName", ...this.inputProps("businessName") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Business Address"
                              id="address"
                              formControlProps={{ fullWidth: true }}
                              success={this.state.form.fields.address.validationStatus === "success"}
                              error={this.state.form.fields.address.validationStatus === "error"}
                              inputProps={{ type: "text", name: "address", ...this.inputProps("address") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="City"
                              id="city"
                              formControlProps={{ fullWidth: true }}
                              success={this.state.form.fields.city.validationStatus === "success"}
                              error={this.state.form.fields.city.validationStatus === "error"}
                              inputProps={{ type: "text", name: "city", ...this.inputProps("city") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel htmlFor="legalStructure" className={classes.selectLabel}>
                                Legal Structure
                              </InputLabel>
                              <Select
                                classes={{ select: classes.select }}
                                value={this.state.form.fields.legalStructure.value}
                                onChange={e => this.setValue(e, "legalStructure")}
                                inputProps={{ name: "legalStructure", id: "legalStructure" }}>
                                {legalStructureOption.map(({ value, label }, i) => (
                                  <MenuItem value={value} key={i} classes={{ root: classes.selectMenuItem }}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                              labelText="Doing Business As"
                              id="dba"
                              formControlProps={{ fullWidth: true }}
                              success={this.state.form.fields.dba.validationStatus === "success"}
                              error={this.state.form.fields.dba.validationStatus === "error"}
                              inputProps={{ type: "text", name: "dba", ...this.inputProps("dba") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CustomInput
                              labelText="State"
                              id="state"
                              formControlProps={{ fullWidth: true }}
                              success={this.state.form.fields.state.validationStatus === "success"}
                              error={this.state.form.fields.state.validationStatus === "error"}
                              inputProps={{ type: "text", name: "state", ...this.inputProps("state") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CustomInput
                              labelText="ZIP"
                              id="postalCode"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{ type: "text", name: "postalCode", ...this.inputProps("postalCode") }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel htmlFor="tib" className={classes.selectLabel}>
                                Years in Business
                              </InputLabel>
                              <Select
                                classes={{ select: classes.select }}
                                value={this.state.form.fields.tib.value}
                                onChange={e => this.setValue(e, "tib")}
                                inputProps={{ name: "tib", id: "tib" }}>
                                {tibOptions.map(({ value, label }, i) => (
                                  <MenuItem value={value} key={i} classes={{ root: classes.selectMenuItem }}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.itemsBlock}>
                    <h3>Ownership</h3>
                    {this.state.form.ownershipItems.map((item, index) => (
                      <Grid container spacing={4} key={index}>
                        <Grid item xs={12} sm={2} md={4} lg={1}>
                          <CustomInput
                            labelText="Title"
                            id={"ownerTitle-" + index}
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              value: this.state.form.fields.title.value,
                              onChange: e => this.setValue(e, "title"),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                          <CustomInput
                            labelText="First Name"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", onChange: e => this.setOwnerOption(e, index, "firstName") }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                          <CustomInput
                            labelText="LastName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", onChange: e => this.setOwnerOption(e, index, "lastName") }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                          <CustomInput
                            labelText="Percent Ownership"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", onChange: e => this.setOwnerOption(e, index, "percOwner") }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                          <CustomInput
                            labelText="Social Security"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", onChange: e => this.setOwnerOption(e, index, "ssn") }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    {this.state.form.numberOfOwners <= 1 ? (
                      <Button color="primary" onClick={() => this.addOwnershipItem()}>
                        Additional Ownership
                      </Button>
                    ) : (
                      <Button color="primary" onClick={() => this.removeLastOwnershipItem()}>
                        Remove Owner
                      </Button>
                    )}
                  </div>
                  <div>
                    <h3>Point of Contact</h3>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                          <InputLabel htmlFor="poc" className={classes.selectLabel}>
                            Point of Contact
                          </InputLabel>
                          <Select
                            classes={{ select: classes.select }}
                            value={this.state.form.fields.poc.value}
                            onChange={e => this.setOwner(e)}
                            inputProps={{ name: "poc", id: "poc" }}>
                            <MenuItem value="0" classes={{ root: classes.selectMenuItem }}>
                              Owner 1
                            </MenuItem>
                            {this.state.form.numberOfOwners > 1 ? (
                              <MenuItem value="1" classes={{ root: classes.selectMenuItem }}>
                                Owner 2
                              </MenuItem>
                            ) : null}
                            <MenuItem value="other" classes={{ root: classes.selectMenuItem }}>
                              Other - Enter Below
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="First Name"
                          id="pointOfContactFirstName"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{ type: "text", ...this.inputProps("firstName") }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="pointOfContactLastName"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{ type: "text", ...this.inputProps("lastName") }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Email"
                          id="pointOfContactEmail"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{ type: "text", ...this.inputProps("email") }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Phone Number"
                          id="phone"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{ type: "text", ...this.inputProps("phone") }}
                        />
                      </Grid>
                    </Grid>
                    <Button color="primary" type="submit" disabled={loading}>
                      {loading ? "Submitting" : "Submit"}
                    </Button>
                  </div>
                </form>
              </div>
            </Tile>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

export default withStyles({ ...styles })(CreditApp);
