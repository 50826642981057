import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid2,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FinanceProgramForm } from "../../../../schema";
import { get, isEmpty } from "lodash";
import { documentsByKey } from "../../../../../../constants";
import { SelectField } from "../../../../../../components/form";
interface Props {
  isReadOnly: boolean;
}

export const SubmissionSettings = ({ isReadOnly }: Props) => {
  const { control, getValues, setValue, watch } = useFormContext<FinanceProgramForm>();
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([documentsByKey.credit_report.key]);

  useEffect(() => {
    let initialDocuments = [documentsByKey.credit_report.key];
    if (!isEmpty(getValues("program.fourth.submissionSettings.excludedDocuments"))) {
      initialDocuments = getValues("program.fourth.submissionSettings.excludedDocuments");
    }

    setSelectedDocuments(initialDocuments);
    setValue("program.fourth.submissionSettings.excludedDocuments", initialDocuments);
  }, []);
  const handleChange = (event: any) => {
    setSelectedDocuments(event.target.value);
    setValue("program.fourth.submissionSettings.excludedDocuments", event.target.value);
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography component="span" variant="body1">
          Hide for <b>this</b> lender's Finance Program
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <FormControl fullWidth>
          <Select
            labelId="document-multiselect-label"
            multiple
            value={selectedDocuments}
            onChange={handleChange}
            renderValue={selected => selected.map(s => get(documentsByKey, `[${s}].text`)).join(", ")}>
            {Object.values(documentsByKey).map(doc => (
              <MenuItem key={doc.key} value={doc.key}>
                <Checkbox checked={selectedDocuments.indexOf(doc.key as never) > -1} />
                <ListItemText primary={doc.text} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};
