import { ValueOf } from "global";
import { invoiceCalculations } from "modules/invoice";
import { VendorOpportunity } from "modules/vendorOpportunity";
import { equipmentMileageFormatter } from "./equipmentMileageFormatter";
import {
  Equipment,
  FinanceProgramFormValues,
  MarkupMethods,
  MarkupType,
  PaymentCalculationMethods,
  ProgramTypes,
  RateTypes,
  SaleType,
  SendVia,
  Structures,
} from "../types";
import { EQUIPMENT_CLASS, EQUIPMENT_MAKE } from "@trnsact/business-criteria";
import * as moment from "moment";

export const sendViaInitialValues = {
  type: SendVia.Email,
};

export const displaySettingsInitialValues = {
  productPrice: true,
  productDetails: true,
};

export function getDefaultValues(
  voData: VendorOpportunity,
  creditAppData: any,
  equipmentData: Equipment[]
): FinanceProgramFormValues {
  const equipmentInitialValues = convertVOEquipmentInitialValues(equipmentData);
  const customerInitialValues = convertVOCustomerInitialValues(creditAppData);
  const saleDetailsInitialValues = convertVOSaleDetailsInitialValues(voData);
  const financeQuoteInitialValues = convertVOFinanceQuoteInitialValues(voData);

  return {
    financeProgramDescription: "",
    sendVia: sendViaInitialValues,
    customer: customerInitialValues,
    equipment: equipmentInitialValues,
    saleDetails: saleDetailsInitialValues,
    financeQuote: financeQuoteInitialValues,
    submission: {
      selectedSubmissionId: "",
      maxApprovalAmount: null,
    },
    displaySettings: displaySettingsInitialValues,
  };
}

function getFactValueAdjustedToMatchEnum(factValue: string, enumOption: Record<string, string>): string {
  const adjustedToMatchEnumOptionKey = Object.keys(enumOption).find(key => {
    return enumOption[key as keyof typeof enumOption].toLowerCase() === factValue?.toLowerCase();
  });

  return adjustedToMatchEnumOptionKey ? enumOption[adjustedToMatchEnumOptionKey as keyof typeof enumOption] : factValue;
}

function convertVOEquipmentInitialValues(equipmentData: Equipment[]): ValueOf<FinanceProgramFormValues, "equipment"> {
  const equipment = equipmentData?.[0];

  const makeOption = getFactValueAdjustedToMatchEnum(equipment?.make, EQUIPMENT_MAKE);
  const classOption = getFactValueAdjustedToMatchEnum(equipment?.class, EQUIPMENT_CLASS);

  return {
    selectedEquipment: equipment?.equipmentId ?? "",
    quantity: (equipment?.quantity ?? "").toString(),
    year: equipment?.year ?? "",
    make: makeOption ?? "",
    model: equipment?.model ?? "",
    class: classOption ?? "",
    serialNumberVin: equipment?.serialNumberVin ?? "",
    stockId: equipment?.stockId ?? "",
    newUsed: equipment?.newUsed ?? "",
    mileage: equipmentMileageFormatter(equipment?.mileage, "toView"),
    description: equipment?.additionalDescription ?? "",
    totalWholesaleCost: (equipment?.totalWholesaleCost ?? "").toString(),
    retailPrice: (equipment?.retailPrice ?? "").toString(),
  };
}

function convertVOCustomerInitialValues(creditAppData: any): ValueOf<FinanceProgramFormValues, "customer"> {
  const customerData = creditAppData?.creditApplicationOwner?.[0];

  const phone =
    (customerData?.phone || customerData?.mobilePhone || customerData?.homePhone || customerData?.workPhone) ?? "";

  return {
    phone,
    email: customerData?.email ?? "",
    state: customerData?.state ?? "",
    selectedCustomer: customerData?.ownerPgId,
    firstAndLastName: (customerData?.firstName ?? "") + " " + (customerData?.lastName ?? ""),
  };
}

function convertVOSaleDetailsInitialValues(vo: VendorOpportunity): ValueOf<FinanceProgramFormValues, "saleDetails"> {
  const { netRate, totalFees, invoiceTotalValue, financeAmount } = invoiceCalculations.byFullVO(vo);

  return {
    netRate,
    financeAmount,
    fees: totalFees,
    saleType: SaleType.Financing,
    invoiceTotal: invoiceTotalValue,
    downPayment: vo.downPayment ?? 0,
    salesTax: vo.salesTaxOnInvoice ?? 0,
    equipmentSubtotal: parseFloat(vo.equipmentCost ?? "0"),
  };
}

function convertVOFinanceQuoteInitialValues(vo: VendorOpportunity): ValueOf<FinanceProgramFormValues, "financeQuote"> {
  const startDate = moment.default(new Date());
  const firstPaymentDate = moment.default(new Date()).add(30, "days");

  return {
    fee: [],
    menu: "",
    commonRate: "",
    terms: [],
    program: "",
    residual: "",
    markupRate: "",
    programName: "",
    specifyRates: [],
    dealerMarkup: "",
    advancePayment: "",
    daysToFirstPayment: "30",
    startDate: startDate.format("YYYY-MM-DD"),
    firstPaymentDate: firstPaymentDate.format("YYYY-MM-DD"),
    specifyRate: false,
    specifyRatePerTerm: "",
    structure: Structures.None,
    subsidyType: MarkupType.Flat,
    customTermTemporaryValue: null,
    programType: ProgramTypes.Loan,
    rateType: RateTypes.InterestRate,
    createProposalWithExistingProgram: null,
    markupMethod: MarkupMethods.MarkupDollar,
    amount: String(vo?.invoiceTotal ?? 0),
    paymentCalculationMethod: PaymentCalculationMethods.Arrears,
  };
}
