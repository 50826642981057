import moment from "moment";
import { FinanceProgram } from "@trnsact/trnsact-shared-types";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { FinanceProgramForm } from "../schema";
import { LenderOptions, MarkupMethods, Structures } from "../types";
import { mapCriteriaFormRulesToValue } from "./mapCriteriaFormRulesToValue";

export function mapFinanceProgramEntityToFormValues(
  financeProgramEntity: FinanceProgram,
  lenderProfiles: LenderProfiles[],
  dealerProfiles: any[] = []
): FinanceProgramForm["program"] {
  const isSpecifyRate =
    new Set(financeProgramEntity?.paymentOptionsConfiguration?.terms?.map((value: any) => value.rate.toString()) ?? [])
      .size > 1;

  const defineStructureValue = () => {
    if (financeProgramEntity?.paymentOptionsConfiguration?.dealerMarkup) return Structures.Markup;
    if (financeProgramEntity?.paymentOptionsConfiguration?.customerRate) return Structures.Subsidy;

    return Structures.None;
  };

  return {
    id: financeProgramEntity?.financeProgramId ?? null,
    first: {
      dealers: dealerProfiles
        ? dealerProfiles?.reduce<any[]>((acc, dealerProfile) => {
            const isIncluded = !!financeProgramEntity?.financeProgramToLenderProfiles
              ?.map(value => value?.lenderProfileId)
              .includes(dealerProfile.lenderProfiles[0]?.lenderProfileId ?? "");

            if (isIncluded) {
              acc.push({
                ...dealerProfile,
                value: dealerProfile.id,
                label: dealerProfile.name.replace("VP - ", ""),
              });
            }

            return acc;
          }, [])
        : [],
      name: financeProgramEntity?.nameInternal ?? "",
      notes: financeProgramEntity?.paymentOptionsConfiguration?.notes ?? "",
      guidelines: financeProgramEntity?.paymentOptionsConfiguration?.guidelines,
      displayName: financeProgramEntity?.paymentOptionsConfiguration?.financeProgramName ?? "",
      lenders: lenderProfiles?.reduce<LenderOptions[]>((acc, lenderProfile) => {
        const isIncluded = !!financeProgramEntity?.financeProgramToLenderProfiles
          ?.map(value => value?.lenderProfileId)
          .includes(lenderProfile?.id ?? "");

        if (isIncluded) {
          acc.push({
            ...lenderProfile,
            value: lenderProfile.id!,
            label: lenderProfile.lenderName!,
          });
        }

        return acc;
      }, []),
    },
    second: {
      isSpecifyRate,
      method: MarkupMethods.Markup,
      structure: defineStructureValue(),
      programType: financeProgramEntity?.paymentOptionsConfiguration?.type,
      rateType: financeProgramEntity?.paymentOptionsConfiguration?.rateType,
      rate: financeProgramEntity?.paymentOptionsConfiguration?.terms?.[0]?.rate,
      residual: financeProgramEntity?.paymentOptionsConfiguration?.residualPercentage ?? 0,
      customerRate: financeProgramEntity?.paymentOptionsConfiguration?.customerRate ?? 0,
      dealerMarkup: financeProgramEntity?.paymentOptionsConfiguration?.dealerMarkup ?? 0,
      isValidityDates: !!financeProgramEntity?.validDateEnd && !!financeProgramEntity?.validDateStart,
      paymentCalculationMethod: financeProgramEntity?.paymentOptionsConfiguration?.paymentPeriodTiming,
      advancePayment: financeProgramEntity?.paymentOptionsConfiguration?.numberOfAdvancePayments?.toString(),
      daysToFirstPayment: financeProgramEntity?.paymentOptionsConfiguration?.daysToFirstPayment?.toString(),
      terms: financeProgramEntity?.paymentOptionsConfiguration?.terms?.map((value: any) => value.term.toString()),
      endDate: financeProgramEntity?.validDateEnd ? moment(Number(financeProgramEntity.validDateEnd)).toDate() : null,
      specifyRates: financeProgramEntity?.paymentOptionsConfiguration?.terms?.map((value: any) => ({
        ...value,
        term: value.term.toString(),
      })),
      fee: financeProgramEntity?.paymentOptionsConfiguration?.flatFees?.map((feeValue: any) => ({
        amount: feeValue?.fee,
        description: feeValue?.identifier,
        isFinanced: feeValue?.financed,
      })),
      startDate: financeProgramEntity?.validDateStart
        ? moment(Number(financeProgramEntity.validDateStart)).toDate()
        : null,
    },
    third: {
      criteria: financeProgramEntity?.prescreenCriteria?.prescreenCriteriaId
        ? {
            label: financeProgramEntity.prescreenCriteria.name,
            value: financeProgramEntity.prescreenCriteria.prescreenCriteriaId,
          }
        : null,
      conditions: mapCriteriaFormRulesToValue(financeProgramEntity?.prescreenCriteria?.jsonCriteria?.formRules),
    },
    fourth: {
      submissionSettings: {
        excludedDocuments: financeProgramEntity.submissionSettings?.excludedDocuments ?? [],
      },
    },
    fifth: {
      insuranceCoverage: {
        coverageRequirements: financeProgramEntity?.insuranceCoverage?.coverageRequirements,
        certificateRequirements: financeProgramEntity?.insuranceCoverage?.certificateRequirements,
        otherInstructions: financeProgramEntity?.insuranceCoverage?.otherInstructions,
      },
    },
    sixth: {},
  };
}
