import React from "react";
import Grid from "@mui/material/Grid";
import Quote from "./Quote";

const PQTView = () => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <Grid>
        <Quote />
      </Grid>
    </div>
  );
};

export default PQTView;
