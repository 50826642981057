import React from "react";
import { TablePagination } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const styles = {
  paginationStyles: {
    borderBottom: "none",
    fontSize: 12,
    color: "rgba(0,0,0,0.6)",

    "& p": {
      fontSize: 12,
      color: "rgba(0,0,0,0.6)",
    },

    "& .MuiToolbar-gutters": {
      padding: 0,
    },
  },
};

const useStyles = makeStyles(styles);

export default props => {
  const { pageSize, resolvedData, page, onPageChange, onPageSizeChange, pageSizeOptions } = props;
  const classes = useStyles();

  return (
    <TablePagination
      className={classes.paginationStyles}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={pageSizeOptions}
      count={resolvedData.length}
      onPageChange={(e, page) => onPageChange(page)}
      onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
    />
  );
};
