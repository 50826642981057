import React, { PropsWithChildren, useCallback, useRef } from "react";
import { Engine } from "json-rules-engine";
import { useDispatch } from "react-redux";
import { CustomOperators } from "@trnsact/business-criteria";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { deskingActions } from "../../model";
import { Criteria } from "../../types";
import { jsonRulesEngineContext as JsonRulesEngineContext } from "./jsonRulesEngineContext";

export const RulesEngineProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();

  const productConfigurationRulesEngine = useRef<Engine>(new Engine());

  productConfigurationRulesEngine.current.addOperator("length", CustomOperators.length);

  const handleAddProductsRules = useCallback((product: ProposalProduct) => {
    const rules = (product.aftermarketProduct?.criteria ?? []) as Criteria[];

    rules.forEach(rule => {
      productConfigurationRulesEngine.current.addRule({
        ...rule,
        conditions: { all: rule.conditions.jsonRules.conditions.all },
      });
    });
  }, []);

  const handleRunEngin = useCallback(() => {
    dispatch(deskingActions.runJsonEngine());
  }, [dispatch]);

  return (
    <JsonRulesEngineContext.Provider
      value={{ engine: productConfigurationRulesEngine.current, handleAddProductsRules, handleRunEngin }}>
      {children}
    </JsonRulesEngineContext.Provider>
  );
};
