import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { CellDefaultProps } from "../../../types";

export const CustomerCell = ({ proposal }: CellDefaultProps) => {
  return (
    <Link to={`/vo/${proposal.vendorOpportunityId}`} target="_blank" style={{ color: blue[700] }}>
      <Typography align="center" component="span" variant="body2">
        {proposal.businessName}
      </Typography>
    </Link>
  );
};
