import * as _ from "lodash";
import moment from "moment";
import { creditAppTypes } from "./pages/Prequal/constants";
const config = require("./config.js");

export function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}
export const translateStageCodes = code => {
  const dynamicsCreditSubmissionStatusCodes = {
    804790000: "Submitted to Lender",
    804790001: "Lender Reviewing",
    804790002: "Lender - Additional Info Requested",
    804790003: "Lender Approved",
    804790004: "Lender Declined",
    804790005: "Dealer Accepted",
    804790006: "Dealer Cancelled",
    804790007: "Docs Out",
    804790008: "Docs in",
    804790009: "Funding Review",
    804790010: "Funding Items Requested",
    804790011: "Customer Cancelled",
    804790013: "Lender Pre-Approved",
    804790020: "Funded",
    105: "Draft Application Created",
    100: "Awaiting Transmission to Lender",
    300: "Submission Failed",
    101: "Awaiting PreQual Transmission to Lender",
  };
  return code in dynamicsCreditSubmissionStatusCodes ? dynamicsCreditSubmissionStatusCodes[code] : "";
};
export const formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
  if (typeof amount === "number") {
    amount = amount.toString();
  }
  if (!amount) return null;
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    amount = amount.replace(/,/g, ""); // 1125, but a string, so convert it to number
    amount = parseInt(amount, 10);

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const parseIntMoneyString = moneyString => parseFloat(moneyString.split(",").join(""));

export const parseCurrency = value => {
  value = value.replace(/\$/g, "");
  value = value.replace(/,/g, "");
  const lastCharacter = value[value.length - 1];
  if (/\d/.test(lastCharacter)) {
    if (value.includes(".")) {
      const decimalLocation = value.indexOf(".");
      const trailingDigits = value.slice(decimalLocation + 1);
      if (trailingDigits.length <= 2) {
        return value;
      } else {
        // Truncate third trailing digit
        return value.slice(0, -1);
      }
    } else {
      return value;
    }
  } else if (lastCharacter === ".") {
    // Check that there is only one decimal
    if ((value.match(/\./g) || []).length > 1) {
      // Remove extra decimal
      return value.slice(0, -1);
    } else {
      return value;
    }
  } else {
    return value.slice(0, -1);
  }
};

export const isTwoDecimalsFormat = event => {
  const regex = new RegExp(/^\d*\.?\d{0,2}$/g);
  const specialKeys = ["Backspace", "Tab", "End", "Home", "-", "ArrowLeft", "ArrowRight", "Del", "Delete"];

  if (specialKeys.indexOf(event.key) !== -1) {
    return;
  }

  const current = event.target.value.replaceAll(",", "");
  const position = event.target.selectionStart;
  const next = [current.slice(0, position), event.key === "Decimal" ? "." : event.key, current.slice(position)].join(
    ""
  );

  if (next && !String(next).match(regex)) {
    return false;
  }

  return true;
};

export const formatNumberWithDelimiter = (value, formatZero = false) => {
  if (isNaN(value)) {
    return "";
  }

  if (typeof value === "number") value = value.toString();
  if (formatZero && value === "0") {
    return "0";
  }
  if (value && value !== "0") {
    // Remove commas
    value = value.replace(/,/g, "");
    if (value.includes(".")) {
      const decimalLocation = value.indexOf(".");

      // Add thousands separators
      let digitsBeforeDecimal = value.slice(0, decimalLocation);
      digitsBeforeDecimal = Number(digitsBeforeDecimal).toLocaleString();

      const trailingDigits = value.slice(decimalLocation + 1);
      if (trailingDigits.length <= 2) {
        return `${digitsBeforeDecimal}.${trailingDigits}`;
      } else {
        // Truncate third trailing digit
        return `${digitsBeforeDecimal}.${trailingDigits.slice(0, 2)}`;
      }
    } else {
      // Add thousands separator
      const valueWithCommas = Number(value).toLocaleString();
      return `${valueWithCommas}`;
    }
  } else return "";
};

export const formatCurrency = (value, formatZero = false) => {
  if (typeof value === "number") value = value.toString();
  if (formatZero && value === "0") {
    return "$0";
  }
  if (value && value !== "$" && value !== "0") {
    // Remove commas and dollar sign
    value = value.replace(/\$/g, "");
    value = value.replace(/,/g, "");
    if (value.includes(".")) {
      const decimalLocation = value.indexOf(".");

      // Add thousands separators
      let digitsBeforeDecimal = value.slice(0, decimalLocation);
      digitsBeforeDecimal = Number(digitsBeforeDecimal).toLocaleString();

      const trailingDigits = value.slice(decimalLocation + 1);
      if (trailingDigits.length <= 2) {
        return `$${digitsBeforeDecimal}.${trailingDigits.padEnd(2, "0")}`;
      } else {
        // Truncate third trailing digit
        return `$${digitsBeforeDecimal}.${trailingDigits.slice(0, 2)}`;
      }
    } else {
      // Add thousands separator
      const valueWithCommas = Number(value).toLocaleString();
      return `$${valueWithCommas}`;
    }
  } else return "";
};

export const formatPercentage = value => {
  if (!value && value !== 0) {
    return "";
  }
  return value + "%";
};

export const getNested = (theObject, path, separator) => {
  // Allows strings to be used to retrieve nested objects
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, theObject);
  } catch (err) {
    return undefined;
  }
};

export const legalStructureOption = [
  { value: "803370001", label: "Corporation" },
  { value: "803370002", label: "LLC" },
  { value: "803370000", label: "Sole Proprietorship" },
  { value: "803370003", label: "Partnership" },
  { value: "100000000", label: "Municipality" },
  { value: "100000001", label: "Owner Operator" },
  { value: "111111111", label: "Individual / Owner Operator" },
  { value: "100000002", label: "Non-Profit Corporation" },
  { value: "100000003", label: "Publicly Traded Company" },
  { value: "100000004", label: "Unknown" },
];

export const tibOptions = [
  { value: "803370000", label: "Pre-Startup" },
  { value: "803370001", label: "0-6 Months" },
  { value: "803370002", label: "6-12 Months" },
  { value: "803370003", label: "1-2 Years" },
  { value: "803370004", label: "2-3 Years" },
  { value: "803370005", label: "3-5 Years" },
  { value: "803370006", label: "5-10 Years" },
  { value: "803370007", label: "10+ Years" },
];

export const prequalOption = [
  { value: "100000003", label: "Approved" },
  { value: "100000002", label: "Pre-Approved" },
  { value: "100000000", label: "Probation Review" },
  { value: "100000004", label: "Declined" },
  { value: "100000005", label: "Review Required" },
];

export const toTitleCase = str => {
  if (!str) {
    return "";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const camelToSpaces = str => {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const formatSSN = val => {
  if (!_.isString(val)) {
    return val;
  }
  var formatVal = val.replace(/\D/g, "").substr(0, 9);
  var newVal = "";

  if (formatVal.length > 4) {
    val = formatVal;
  }

  if (formatVal.length > 3 && formatVal.length < 6) {
    newVal += val.substr(0, 3) + "-";
    formatVal = formatVal.substr(3);
  }

  if (formatVal.length > 5) {
    newVal += formatVal.substr(0, 3) + "-";
    newVal += formatVal.substr(3, 2) + "-";
    formatVal = formatVal.substr(5);
  }

  newVal += formatVal;
  return newVal;
};

export const numberWithCommas = x => {
  if (x.includes(".")) {
    const indexOfPoint = x.indexOf(".");
    const valueBeforePoint = x.slice(0, indexOfPoint);
    const valueAfterPoint = x.slice(indexOfPoint);
    return valueBeforePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + valueAfterPoint.toString();
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertToDate = dateString => {
  let newDate = new Date(dateString);
  let dd = newDate.getDate();
  let mm = newDate.getMonth() + 1;
  let yyyy = newDate.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return `${mm}-${dd}-${yyyy}`;
};

export const validationTypes = {
  email: value => {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  },
  minLen: (value, length) => {
    return String(value).length >= length;
  },
  maxLen: (value, length) => {
    return String(value).length <= length;
  },
  required: value => {
    return !!value && String(value).length > 0;
  },
  select: value => {
    return !!value;
  },
  requiredPhone: value => {
    return !!value && String(value).length > 0 && String(value).length >= 14;
  },
};

export const validateValue = (value, validations) => {
  let status = true;
  validations.forEach(validation => {
    if (!validationTypes[validation.type](value, validation.value)) {
      status = false;
    }
  });
  return status;
};

export const vendorContactRoleHumanReadable = {
  sales_rep: "Sales Representative",
  sales_mgr: "Sales Manager",
  admin: "Administrator",
  executive: "Executive",
  credit_mgr: "Credit Manager",
  support_person: "Support Person",
  //applicant: "Applicant",
};
export const vendorSalesGroupSubsetContactRole = {
  sales_rep: "Sales Rep",
  sales_mgr: "Sales Manager",
  credit_mgr: "Credit Manager",
};

export const salesGroups = {
  sales_rep: "Sales Rep",
  sales_mgr: "Sales Manager",
  finance_manager: "Finance Manager",
};

export const vendorContactRole = {
  sales_rep: "sales_rep",
  sales_mgr: "sales_mgr",
  admin: "admin",
  executive: "executive",
  credit_mgr: "credit_mgr",
  support_person: "support_person",
  //applicant: "applicant",
};

export const nonCreditMgrsRoles = Object.values(vendorContactRole).filter(role => role !== "credit_mgr");

export const vendorContactRoleEnumValue = {
  sales_rep: 100000000,
  sales_mgr: 100000001,
  admin: 100000002,
  executive: 100000003,
  credit_mgr: 100000004,
  support_person: 100000005,
};

export const lenderContactRole = {
  account_mgr: "account_mgr",
  sales_asst: "sales_asst",
  credit_officer: "credit_officer",
  funding_coord: "funding_coord",
  sales_mgr: "sales_mgr",
  executive: "executive",
};

export const lenderContactRoleEnumValue = {
  [lenderContactRole.account_mgr]: 100,
  [lenderContactRole.sales_asst]: 200,
  [lenderContactRole.credit_officer]: 300,
  [lenderContactRole.funding_coord]: 400,
  [lenderContactRole.sales_mgr]: 500,
  [lenderContactRole.executive]: 600,
};

export const legalStructureList = {
  "": "",
  803370001: "Corporation",
  803370002: "LLC",
  803370000: "Sole Proprietorship",
  100000000: "Municipal",
  100000001: "Owner Operator",
  100000002: "Non-Profit Corporation",
  803370003: "Partnership",
  100000003: "Publicly Traded Company",
  100000004: "Unknown",
};

export const statesAndProvincesList = {
  "": "",
  ALABAMA: "AL",
  ALASKA: "AK",
  // "AMERICAN SAMOA": "AS",
  ARIZONA: "AZ",
  ARKANSAS: "AR",
  CALIFORNIA: "CA",
  COLORADO: "CO",
  CONNECTICUT: "CT",
  DELAWARE: "DE",
  "DISTRICT OF COLUMBIA": "DC",
  // "FEDERATED STATES OF MICRONESIA": "FM",
  FLORIDA: "FL",
  GEORGIA: "GA",
  // "GUAM GU": "GU",
  HAWAII: "HI",
  IDAHO: "ID",
  ILLINOIS: "IL",
  INDIANA: "IN",
  IOWA: "IA",
  KANSAS: "KS",
  KENTUCKY: "KY",
  LOUISIANA: "LA",
  MAINE: "ME",
  // "MARSHALL ISLANDS": "MH",
  MARYLAND: "MD",
  MASSACHUSETTS: "MA",
  MICHIGAN: "MI",
  MINNESOTA: "MN",
  MISSISSIPPI: "MS",
  MISSOURI: "MO",
  MONTANA: "MT",
  NEBRASKA: "NE",
  NEVADA: "NV",
  "NEW HAMPSHIRE": "NH",
  "NEW JERSEY": "NJ",
  "NEW MEXICO": "NM",
  "NEW YORK": "NY",
  "NORTH CAROLINA": "NC",
  "NORTH DAKOTA": "ND",
  "NORTHERN MARIANA ISLANDS": "MP",
  OHIO: "OH",
  OKLAHOMA: "OK",
  OREGON: "OR",
  // "PALAU": "PW",
  PENNSYLVANIA: "PA",
  // "PUERTO RICO": "PR",
  "RHODE ISLAND": "RI",
  "SOUTH CAROLINA": "SC",
  "SOUTH DAKOTA": "SD",
  TENNESSEE: "TN",
  TEXAS: "TX",
  UTAH: "UT",
  VERMONT: "VT",
  // "VIRGIN ISLANDS": "VI",
  VIRGINIA: "VA",
  WASHINGTON: "WA",
  "WEST VIRGINIA": "WV",
  WISCONSIN: "WI",
  WYOMING: "WY",
  // "ARMED FORCES AFRICA \ CANADA \ EUROPE \ MIDDLE EAST": "AE",
  // "ARMED FORCES AMERICA (EXCEPT CANADA)": "AA",
  // "ARMED FORCES PACIFIC": "AP",
};

export const salesTaxExemptList = {
  "": "",
  Yes: true,
  No: false,
};

export const setFieldValue = (value, fieldName, fields, setFields, label, type, options) => {
  // "fields" and "setFields" - values of useState hook with structure
  //  {
  //    name: {
  //      value: '',
  //        validationStatus: '',
  //        format: formatFunc,
  //        validations: [{type: validationType}]
  //    }
  //  }
  const field = fields[fieldName];
  const newFieldsObj = { ...fields };
  newFieldsObj[fieldName] = {};
  let validationStatus;
  if (!!field.format && typeof field.format === "function") {
    newFieldsObj[fieldName].format = field.format;
    value = field.format(value);
  }
  if (!!field.validations && field.validations.length) {
    validationStatus = validateValue(value, field.validations) ? "success" : "error";
    newFieldsObj[fieldName].validations = field.validations;
    newFieldsObj[fieldName].validationStatus = validationStatus;
  }
  newFieldsObj[fieldName].value = value;
  if (label) {
    newFieldsObj[fieldName].label = label;
  }
  if (type === "select" && options) {
    newFieldsObj[fieldName].type = type;
    newFieldsObj[fieldName].selectOptions = options;
  }
  setFields(newFieldsObj);
};

export const checkObjNotClear = obj => {
  return !!obj && Object.keys(obj).length;
};

export const REGISTRATION_STATUSES = {
  active: "active",
  pending_approval: "pending_approval",
  approved: "approved",
  blocked: "blocked",
};

export const registrationStatusHumanReadable = {
  pending_approval: "Pending Approval",
  approved: "Approved",
  active: "Active",
  blocked: "Blocked",
  null: "N/A",
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getURLParameter = (paramKey, defaultValue) => {
  const paramValue = new URL(window.location.href).searchParams.get(paramKey);
  if (paramValue) return paramValue;
  if (defaultValue) return defaultValue;
  return undefined;
};

export const elementReady = selector => {
  return new Promise((resolve, reject) => {
    let el = document.querySelector(selector);
    if (el) {
      resolve(el);
      return;
    }
    new MutationObserver((mutationRecords, observer) => {
      // Query for elements matching the specified selector
      Array.from(document.querySelectorAll(selector)).forEach(element => {
        resolve(element);
        //Once we have resolved we don't need the observer anymore.
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
};

export const cnhLenderProducts = [
  { value: "9502", label: "Low Rate - Program # 9502" },
  { value: "717", label: "Standard Rate - Program #717" },
  { value: "995", label: "Standard Rate - Program #995" },
];

export const cnhMakeOptions = [
  "A G",
  "AG Input Financing",
  "AGCO",
  "ALO",
  "AMI",
  "ASV",
  "ATI INC",
  "Ag-Meier",
  "Allen Engineering",
  "Allied",
  "Allis-Chalmers",
  "Alloway",
  "Amco",
  "Armstrong Ag",
  "Artic Cat",
  "Artsway",
  "Astec",
  "Athens",
  "Atlas Copco",
  "Auburn",
  "Austin Western",
  "Austoft",
  "Badger",
  "Bandit",
  "Beaudoin",
  "Belarus",
  "Bell",
  "Belshe",
  "Bergmann",
  "Big Bud",
  "Blue Diamond",
  "Bobcat",
  "Bomag",
  "Bourgault",
  "Bradco",
  "Brandt",
  "Brent",
  "Brillion",
  "Brown-Case",
  "Buffalo",
  "Buhler",
  "Bush Hog",
  "C P Enterprises",
  "Capital",
  "Capital-Direct Sale",
  "Case",
  "Case IH",
  "Caterpillar",
  "Chamberlain",
  "Champion",
  "Chrysler",
  "Claas",
  "Craig",
  "Crost Boster",
  "Crustbuster",
  "Cub Cadet",
  "DMI",
  "DMI-Goodfield",
  "DMI-Saskatoon",
  "Daewoo",
  "Degelman",
  "Deutz",
  "Deutz-Allis",
  "Ditchwitch",
  "Donahue",
  "Doosan",
  "Dresser",
  "Duratech",
  "Dynapac",
  "Eagle",
  "Eby",
  "Eicher",
  "Esco",
  "FELLING",
  "FFC",
  "Farm Hand",
  "Farmhand",
  "Featherlite",
  "Fendt",
  "Ferranti",
  "Fiat",
  "Fiat-Allis",
  "Flexicoil",
  "Ford",
  "Freeman",
  "Freightliner",
  "GERINGHOFF",
  "GRYB",
  "Gearmore",
  "Gehl",
  "Gleaner",
  "Glencoe",
  "GrassHopper",
  "Gravely",
  "Great Bend",
  "Great Plains",
  "Grizzly",
  "Grouser Products",
  "Grove",
  "H S",
  "HLA",
  "Hamm",
  "Haybuster",
  "Hesston",
  "Hiniker",
  "Hinomoto",
  "Hitachi",
  "Hoelscher",
  "Honey Bee",
  "Horwood Bagshaw",
  "Hudson",
  "Huerlimann",
  "Husky",
  "Husqvarna",
  "Hydra-Mac",
  "Hydro-Ax",
  "Hydromac",
  "Hyundai",
  "Ingersoll Rand",
  "Interstate",
  "Intl Harvester",
  "Iseki",
  "JCB",
  "JCB Excavators LTD",
  "JLG",
  "John Deere",
  "John Shearer",
  "K-Line AG",
  "Kawasaki",
  "Kelley",
  "Kemper",
  "Kewanee",
  "Kinze",
  "Kioti",
  "Knight",
  "Kobelco",
  "Kobelco MFG",
  "Koehring",
  "Kolberg",
  "Komatsu",
  "Kongskilde",
  "Krause",
  "Krone",
  "Kubota",
  "Kuhn",
  "Kuhn Farm Machinery",
  "Kvernland",
  "L G Product Co.",
  "L.T.S.",
  "LE Tourneau",
  "Lamborghini",
  "Landini",
  "Landoll",
  "Landpride",
  "Lee Boy",
  "Leon",
  "Leon's MFG",
  "Liebherr",
  "Link Belt",
  "Long",
  "Lull",
  "M W",
  "Macdon",
  "Magnum",
  "Mahindra",
  "Manitou",
  "Massey-Ferguson",
  "McCormick",
  "McMillian/ Quipnet",
  "Melroe",
  "Mengele",
  "Michigan (CLARK)",
  "Miller",
  "Minneapolis Moline",
  "Monosem",
  "Morbark",
  "Morris",
  "Moxy Trucks",
  "Mustang",
  "NEW LEADER",
  "NPK",
  "Namco",
  "Natl Acct GT 60 Day",
  "Natl Acct LE 60 Day",
  "New Holland",
  "New Idea",
  "O K",
  "Old Case PRE UMS",
  "Oliver",
  "Orthman",
  "Other",
  "P H",
  "PJB",
  "Paladin Attachments",
  "Parker",
  "Partner Programs",
  "Pequea",
  "Pettibone",
  "Phiber MFG",
  "Poettinger",
  "Polaris",
  "Pottinger",
  "Power-Box",
  "Powerscreen",
  "Pro Ag",
  "Pronovost",
  "Rental Yard DLR",
  "Revolving ACCTS DLR",
  "Reynolds",
  "Rhino",
  "Roper",
  "Rossi",
  "Rylind",
  "S.A.M.E.",
  "Salford",
  "Same",
  "Samsung",
  "Schlueter",
  "Schulte",
  "Shelboen Reynolds",
  "Shibaura",
  "Simplicity",
  "Stanley",
  "Steiger",
  "Steyr",
  "Stone",
  "Strautmann",
  "Strickland",
  "Sukup",
  "Sullair",
  "Summers",
  "Sunflower",
  "Supreme",
  "Supreme Intl",
  "TRK Attachments Inc",
  "Takeuchi",
  "Tampo",
  "Taylor-Pittsburg",
  "Terex",
  "Thomas",
  "Thwaites",
  "Titan Implement",
  "Towmaster",
  "Trac-Technik",
  "Trail King",
  "Tramac",
  "Trojan",
  "Tye",
  "Tyler",
  "Universal",
  "Unverferth",
  "Valmet",
  "Vermeer",
  "Versatile",
  "Vibromax",
  "Vicon",
  "Volvo",
  "Wacker",
  "Waldon",
];

export const dtfMakeOptions = [
  { make: "DODGE COMMERCIA", type: "Car" },
  { make: "ISUZU MED DUTY", type: "Car" },
  { make: "CHEVROLET TRUCK", type: "Car" },
  { make: "DODGE TRUCK", type: "Car" },
  { make: "FORD TRUCK", type: "Car" },
  { make: "GMC LIGHT DUTY", type: "Car" },
  { make: "Volvo", type: "Car" },
  { make: "smart", type: "Car" },
  { make: "Spartan", type: "Car" },
  { make: "Hino", type: "Car" },
  { make: "Kenworth", type: "Car" },
  { make: "Nissan", type: "Car" },
  { make: "Lincoln", type: "Car" },
  { make: "International", type: "Car" },
  { make: "Isuzu", type: "Car" },
  { make: "Ford", type: "Car" },
  { make: "Dodge", type: "Car" },
  { make: "FORD COMMERCIAL", type: "Car" },
  { make: "CHEVROLET COMME", type: "Car" },
  { make: "GMC MEDIUM DUTY", type: "Car" },
  { make: "MERCEDES-BENZ C", type: "Car" },
  { make: "VOLVO COMMERCIA", type: "Car" },
  { make: "TRAILERS", type: "Car" },
  { make: "Mercedes-Benz", type: "Car" },
  { make: "Hyundai", type: "Car" },
  { make: "Toyota", type: "Car" },
  { make: "Peterbilt", type: "Car" },
  { make: "Mack", type: "Car" },
  { make: "Chevrolet", type: "Car" },
  { make: "GMC", type: "Car" },
  { make: "Ram", type: "Car" },
  { make: "VOLVO COMMERCIAL", type: "Tractor" },
  { make: "Kalmar", type: "Tractor" },
  { make: "CHEVROLET COMME", type: "Tractor" },
  { make: "Autocar", type: "Tractor" },
  { make: "Volvo", type: "Tractor" },
  { make: "Capacity of Texas", type: "Tractor" },
  { make: "Kenworth", type: "Tractor" },
  { make: "Mac", type: "Tractor" },
  { make: "International", type: "Tractor" },
  { make: "FCC", type: "Tractor" },
  { make: "Western Star Trucks", type: "Tractor" },
  { make: "Freightliner", type: "Tractor" },
  { make: "Mitsubishi Fuso", type: "Tractor" },
  { make: "Western Star", type: "Tractor" },
  { make: "FORD COMMERCIAL", type: "Tractor" },
  { make: "GMC MEDIUM DUTY", type: "Tractor" },
  { make: "CATERPILLAR", type: "Tractor" },
  { make: "VOLVO COMMERCIA", type: "Tractor" },
  { make: "Sterling", type: "Tractor" },
  { make: "Hino", type: "Tractor" },
  { make: "Peterbilt", type: "Tractor" },
  { make: "Mack", type: "Tractor" },
  { make: "Ford", type: "Tractor" },
  { make: "Ottawa", type: "Tractor" },
  { make: "VOLVO COMMERCIAL", type: "Truck" },
  { make: "Greenkraft", type: "Truck" },
  { make: "Kalmar", type: "Truck" },
  { make: "ISUZU MED DUTY", type: "Truck" },
  { make: "CHEVROLET TRUCK", type: "Truck" },
  { make: "DODGE TRUCK", type: "Truck" },
  { make: "FORD TRUCK", type: "Truck" },
  { make: "GMC LIGHT DUTY", type: "Truck" },
  { make: "Autocar", type: "Truck" },
  { make: "Volvo", type: "Truck" },
  { make: "Spartan", type: "Truck" },
  { make: "Capacity of Texas", type: "Truck" },
  { make: "Peterbilt", type: "Truck" },
  { make: "Kenworth", type: "Truck" },
  { make: "Nissan", type: "Truck" },
  { make: "International", type: "Truck" },
  { make: "Isuzu", type: "Truck" },
  { make: "Dodge", type: "Truck" },
  { make: "Ottawa", type: "Truck" },
  { make: "FCC", type: "Truck" },
  { make: "Western Star Trucks", type: "Truck" },
  { make: "Freightliner", type: "Truck" },
  { make: "Mitsubishi Fuso", type: "Truck" },
  { make: "Western Star", type: "Truck" },
  { make: "FORD COMMERCIAL", type: "Truck" },
  { make: "DODGE COMMERCIA", type: "Truck" },
  { make: "CHEVROLET COMME", type: "Truck" },
  { make: "GMC MEDIUM DUTY", type: "Truck" },
  { make: "CATERPILLAR", type: "Truck" },
  { make: "MERCEDES-BENZ C", type: "Truck" },
  { make: "VOLVO COMMERCIA", type: "Truck" },
  { make: "TRAILERS", type: "Truck" },
  { make: "Mercedes-Benz", type: "Truck" },
  { make: "Vantage", type: "Truck" },
  { make: "Hyundai", type: "Truck" },
  { make: "Sterling", type: "Truck" },
  { make: "Hino", type: "Truck" },
  { make: "Toyota", type: "Truck" },
  { make: "Mack", type: "Truck" },
  { make: "Lincoln", type: "Truck" },
  { make: "Chevrolet", type: "Truck" },
  { make: "GMC", type: "Truck" },
  { make: "Ford", type: "Truck" },
  { make: "Ram", type: "Truck" },
  { make: "Hyundai Translead", type: "Van" },
  { make: "Battisti Customs", type: "Van" },
  { make: "FORD TRUCK", type: "Van" },
  { make: "Eldorado", type: "Van" },
  { make: "International", type: "Van" },
  { make: "Dorsey", type: "Van" },
  { make: "Dodge", type: "Van" },
  { make: "Freightliner", type: "Van" },
  { make: "Mitsubishi Fuso", type: "Van" },
  { make: "Caleche", type: "Van" },
  { make: "FORD COMMERCIAL", type: "Van" },
  { make: "DODGE COMMERCIA", type: "Van" },
  { make: "GMC MEDIUM DUTY", type: "Van" },
  { make: "MERCEDES-BENZ C", type: "Van" },
  { make: "Mercedes-Benz", type: "Van" },
  { make: "Inland Venture", type: "Van" },
  { make: "Isuzu", type: "Van" },
  { make: "Chevrolet", type: "Van" },
  { make: "GMC", type: "Van" },
  { make: "Ford", type: "Van" },
  { make: "Arboc Spirit of Freedom", type: "Bus" },
  { make: "Startrans", type: "Bus" },
  { make: "Elkhart Coach", type: "Bus" },
  { make: "Caio", type: "Bus" },
  { make: "Diamond Coach", type: "Bus" },
  { make: "Glaval", type: "Bus" },
  { make: "Champion Bus", type: "Bus" },
  { make: "Collins Bus", type: "Bus" },
  { make: "Turtle Top Odyssey", type: "Bus" },
  { make: "Turtle Top Bus", type: "Bus" },
  { make: "Van Hool", type: "Bus" },
  { make: "Volvo", type: "Bus" },
  { make: "Hino", type: "Bus" },
  { make: "Eldorado", type: "Bus" },
  { make: "MCI", type: "Bus" },
  { make: "International", type: "Bus" },
  { make: "Blue Bird", type: "Bus" },
  { make: "Dodge", type: "Bus" },
  { make: "Freightliner", type: "Bus" },
  { make: "Thomas Built Buses", type: "Bus" },
  { make: "Caleche", type: "Bus" },
  { make: "World Trans", type: "Bus" },
  { make: "Starcraft", type: "Bus" },
  { make: "Chevrolet", type: "Bus" },
  { make: "GMC", type: "Bus" },
  { make: "Ford", type: "Bus" },
  { make: "Ram", type: "Bus" },
  { make: "Kalmar", type: "Chassis" },
  { make: "Gallegos Trailers", type: "Chassis" },
  { make: "Volvo", type: "Chassis" },
  { make: "International", type: "Chassis" },
  { make: "Freightliner", type: "Chassis" },
  { make: "FORD COMMERCIAL", type: "Chassis" },
  { make: "Hino", type: "Chassis" },
  { make: "Isuzu", type: "Chassis" },
  { make: "Ford", type: "Chassis" },
  { make: "TRAILERS", type: "Emergency Vehicle" },
  { make: "FMI", type: "Trailer" },
  { make: "Van Raden Industries", type: "Trailer" },
  { make: "Coras", type: "Trailer" },
  { make: "COMERCIALIZADORA NIMKA", type: "Trailer" },
  { make: "AlphaHD Trailers", type: "Trailer" },
  { make: "Edgewood", type: "Trailer" },
  { make: "LOHR", type: "Trailer" },
  { make: "Hyundai Translead", type: "Trailer" },
  { make: "Fryfogle Manufactuing Inc", type: "Trailer" },
  { make: "Extreme Trailers LLC", type: "Trailer" },
  { make: "COUNTRYSIDE TANK COMPANY", type: "Trailer" },
  { make: "HICKS MANUFACTURING, LLC", type: "Trailer" },
  { make: "Mississippi Tank Company", type: "Trailer" },
  { make: "Covered Wagon", type: "Trailer" },
  { make: "Maurer", type: "Trailer" },
  { make: "ITI", type: "Trailer" },
  { make: "Magnolia Trailer Inc.", type: "Trailer" },
  { make: "XL Specialized", type: "Trailer" },
  { make: "Greenkraft", type: "Trailer" },
  { make: "Tytal", type: "Trailer" },
  { make: "ALFAB INC", type: "Trailer" },
  { make: "Mate", type: "Trailer" },
  { make: "Whit-Log, Inc.", type: "Trailer" },
  { make: "Exa-Industrial", type: "Trailer" },
  { make: "SB SOUTHERN WELDING", type: "Trailer" },
  { make: "ROGERS", type: "Trailer" },
  { make: "Galyean", type: "Trailer" },
  { make: "Gallegos Trailers", type: "Trailer" },
  { make: "Pelletier", type: "Trailer" },
  { make: "Beall Trailers", type: "Trailer" },
  { make: "PMI", type: "Trailer" },
  { make: "TRAIL-EZE", type: "Trailer" },
  { make: "Armor Lite", type: "Trailer" },
  { make: "MTC", type: "Trailer" },
  { make: "Ranco", type: "Trailer" },
  { make: "Tankko", type: "Trailer" },
  { make: "LBT, Inc.", type: "Trailer" },
  { make: "Heil Trailer", type: "Trailer" },
  { make: "Vanguard National Trailer", type: "Trailer" },
  { make: "Direct Trailer LP", type: "Trailer" },
  { make: "Mule Tuf Trailer", type: "Trailer" },
  { make: "Midwest Trailers", type: "Trailer" },
  { make: "Hackney / Kidron", type: "Trailer" },
  { make: "Load Trail", type: "Trailer" },
  { make: "X-L Specialized", type: "Trailer" },
  { make: "Dynaweld", type: "Trailer" },
  { make: "smart", type: "Trailer" },
  { make: "Spartan", type: "Trailer" },
  { make: "Capacity of Texas", type: "Trailer" },
  { make: "Hino", type: "Trailer" },
  { make: "International Trailer", type: "Trailer" },
  { make: "Infinity Trailers Sales", type: "Trailer" },
  { make: "Superior Trailer", type: "Trailer" },
  { make: "Star Trailers", type: "Trailer" },
  { make: "Silver Eagle", type: "Trailer" },
  { make: "Strick Trailers", type: "Trailer" },
  { make: "Kenworth", type: "Trailer" },
  { make: "Merritt Equipment", type: "Trailer" },
  { make: "IMCO", type: "Trailer" },
  { make: "Maxey", type: "Trailer" },
  { make: "Mac", type: "Trailer" },
  { make: "Lincoln", type: "Trailer" },
  { make: "Lufkin", type: "Trailer" },
  { make: "Kentucky", type: "Trailer" },
  { make: "Kruz", type: "Trailer" },
  { make: "Kalyn Siebert", type: "Trailer" },
  { make: "Wabash National", type: "Trailer" },
  { make: "Jet", type: "Trailer" },
  { make: "International", type: "Trailer" },
  { make: "Hilbilt", type: "Trailer" },
  { make: "Fruehauf", type: "Trailer" },
  { make: "Great Dane", type: "Trailer" },
  { make: "Great Lakes", type: "Trailer" },
  { make: "Global Trailer", type: "Trailer" },
  { make: "Etnyre", type: "Trailer" },
  { make: "Edge", type: "Trailer" },
  { make: "East", type: "Trailer" },
  { make: "Stoughton", type: "Trailer" },
  { make: "Barrett Trailers", type: "Trailer" },
  { make: "Alpine", type: "Trailer" },
  { make: "Ace Trailers", type: "Trailer" },
  { make: "Aluminum Trailer", type: "Trailer" },
  { make: "Aspen Trailers", type: "Trailer" },
  { make: "Rhodes", type: "Trailer" },
  { make: "Kaufman Trailers", type: "Trailer" },
  { make: "Sundowner Trailer", type: "Trailer" },
  { make: "Fontaine", type: "Trailer" },
  { make: "Brenner Tank", type: "Trailer" },
  { make: "Freightliner", type: "Trailer" },
  { make: "Faymonville", type: "Trailer" },
  { make: "STNTE", type: "Trailer" },
  { make: "DEMCO", type: "Trailer" },
  { make: "Retesa", type: "Trailer" },
  { make: "GTC", type: "Trailer" },
  { make: "Prestige", type: "Trailer" },
  { make: "Pro-Haul", type: "Trailer" },
  { make: "XPO Trailers", type: "Trailer" },
  { make: "Semiremolques el Puma SA de CV", type: "Trailer" },
  { make: "Carmex", type: "Trailer" },
  { make: "Advance Engineered Products", type: "Trailer" },
  { make: "Felling", type: "Trailer" },
  { make: "TRAILERS", type: "Trailer" },
  { make: "Manac Trailers USA", type: "Trailer" },
  { make: "Mac Trailer", type: "Trailer" },
  { make: "Load King", type: "Trailer" },
  { make: "Pitts", type: "Trailer" },
  { make: "Heil", type: "Trailer" },
  { make: "1 Custom Trailer", type: "Trailer" },
  { make: "Cheetah Chassis", type: "Trailer" },
  { make: "Towmaster", type: "Trailer" },
  { make: "4-Star Trailers", type: "Trailer" },
  { make: "Vantage", type: "Trailer" },
  { make: "Reinke", type: "Trailer" },
  { make: "Travis Body & Trailer", type: "Trailer" },
  { make: "Globe Trailer", type: "Trailer" },
  { make: "Talbert", type: "Trailer" },
  { make: "Trout River", type: "Trailer" },
  { make: "Midland", type: "Trailer" },
  { make: "Manac", type: "Trailer" },
  { make: "Hyundai", type: "Trailer" },
  { make: "Doepker", type: "Trailer" },
  { make: "Deloupe", type: "Trailer" },
  { make: "280 Trailers", type: "Trailer" },
  { make: "Inland Venture", type: "Trailer" },
  { make: "Wilson Trailer", type: "Trailer" },
  { make: "Viking Trailers", type: "Trailer" },
  { make: "Utility", type: "Trailer" },
  { make: "Dragon Product", type: "Trailer" },
  { make: "Transcraft", type: "Trailer" },
  { make: "Trail King", type: "Trailer" },
  { make: "Timpte", type: "Trailer" },
  { make: "Transport Designs", type: "Trailer" },
  { make: "TrailStar", type: "Trailer" },
  { make: "Troxell", type: "Trailer" },
  { make: "Trailboss Trailers", type: "Trailer" },
  { make: "Ti-Brook", type: "Trailer" },
  { make: "Starcraft", type: "Trailer" },
  { make: "Reitnouer", type: "Trailer" },
  { make: "Reliance Trailer", type: "Trailer" },
  { make: "R-Way", type: "Trailer" },
  { make: "Fontaine Trailer", type: "Trailer" },
  { make: "Ravens", type: "Trailer" },
  { make: "Trailmobile", type: "Trailer" },
  { make: "Polar Tank Trailer", type: "Trailer" },
  { make: "Load Ride Trailers", type: "Trailer" },
  { make: "Vanguard", type: "Trailer" },
  { make: "Peerless", type: "Trailer" },
  { make: "Pratt", type: "Trailer" },
  { make: "Benson", type: "Trailer" },
  { make: "Peterbilt", type: "Trailer" },
  { make: "Mack", type: "Trailer" },
  { make: "Landoll", type: "Trailer" },
  { make: "Ledwell", type: "Trailer" },
  { make: "Isuzu", type: "Trailer" },
  { make: "GMC", type: "Trailer" },
  { make: "Mclendon Trailers", type: "Trailer" },
  { make: "Lohr Equip & Welding", type: "Trailer" },
  { make: "Featherlite", type: "Trailer" },
  { make: "Dorsey", type: "Trailer" },
  { make: "Doonan Specialized Trailer", type: "Trailer" },
  { make: "Doonan Trailer", type: "Trailer" },
  { make: "Dragon ESP", type: "Trailer" },
  { make: "Cozad Trailer Sales", type: "Trailer" },
  { make: "Carrier Trailer", type: "Trailer" },
  { make: "Columbia Northwest", type: "Trailer" },
  { make: "Clark Trailers", type: "Trailer" },
  { make: "Cottrell", type: "Trailer" },
  { make: "Clement", type: "Trailer" },
  { make: "Western Trailers", type: "Trailer" },
  { make: "CPS Trailer", type: "Trailer" },
  { make: "Construction Trailer Specialists", type: "Trailer" },
  { make: "Ottawa", type: "Trailer" },
  { make: "Eager Beaver", type: "Trailer" },
  { make: "Classic", type: "Trailer" },
  { make: "Other", type: "Equipment/Accessories" },
];

export const dtfAssetTypeOptions = [
  {
    name: "Car",
  },
  {
    name: "Tractor",
  },
  {
    name: "Truck",
  },
  {
    name: "Van",
  },
  {
    name: "Bus",
  },
  {
    name: "Chassis",
  },
  {
    name: "Emergency Vehicle",
  },
  {
    name: "Trailer",
  },
  {
    name: "Equipment/Accessories",
  },
];

export const cnhAssetTypeOptions = [
  {
    name: "Auto Guidance System",
    options: [],
  },
  {
    name: "Combine",
    options: [],
  },
  {
    name: "Combine Head",
    options: [],
  },
  {
    name: "Compact Excavator",
    options: ["E17C", "E18B", "E26C", "E27B", "E30B", "E30C", "E33C", "E35B", "E37C", "E50B", "E55BX", "E57C", "EC37C"],
  },
  {
    name: "Compact Track Loader",
    options: [
      "C175",
      "C227",
      "C232",
      "C234",
      "C237",
      "C247 T4",
      "C238",
      "C245",
      "C327",
      "C332",
      "C334",
      "C337",
      "C338",
      "C345",
      "C362",
      "W60",
    ],
  },
  {
    name: "Compact Wheel Loader",
    options: ["110T", "W50B", "W50C", "W80B", "W80C"],
  },
  {
    name: "Cotton Picker",
    options: [],
  },
  {
    name: "Cotton Stripper",
    options: [],
  },
  {
    name: "Crawler Dozer",
    options: ["D85B", "D95B"],
  },
  {
    name: "Excavator",
    options: [],
  },
  {
    name: "Farm Loader",
    options: [],
  },
  {
    name: "Farm Utility Vehicle",
    options: [],
  },
  {
    name: "Forklift",
    options: ["F50C"],
  },
  {
    name: "Grain Cart",
    options: [],
  },
  {
    name: "Grain Drill",
    options: [],
  },
  {
    name: "Hay and Forage",
    options: [],
  },
  {
    name: "Mower",
    options: [],
  },
  {
    name: "P.T. Forage Harvester",
    options: [],
  },
  {
    name: "P.T. Sprayer",
    options: [],
  },
  {
    name: "Planter",
    options: [],
  },
  {
    name: "Power Unit",
    options: [],
  },
  {
    name: "S.P. Forage Harv Header",
    options: [],
  },
  {
    name: "S.P. Forage Harvester",
    options: [],
  },
  {
    name: "S.P. Sprayer",
    options: [],
  },
  {
    name: "S.P. Windrower",
    options: [],
  },
  {
    name: "S.P. Windrower Header",
    options: [],
  },
  {
    name: "Skid Steer",
    options: [
      "L185",
      "L213",
      "L215",
      "L216",
      "L218",
      "L220",
      "L221",
      "L223",
      "L225",
      "L228",
      "L230",
      "L234",
      "L313",
      "L316",
      "L318",
      "L320",
      "L321",
      "L328",
      "L334",
    ],
  },
  {
    name: "Skid Steer Attachment",
    options: ["Skid Steer Attachments"],
  },
  {
    name: "Soil Preparation",
    options: [],
  },
  {
    name: "Spreader",
    options: [],
  },
  {
    name: "Tractor 60 PTO HP and below",
    options: [],
  },
  {
    name: "Tractor 61-99 PTO HP",
    options: [],
  },
  {
    name: "Tractor Loader",
    options: ["U80C", "U80D"],
  },
  {
    name: "Tractor Loader Backhoe",
    options: [
      "B100C SLA",
      "B100C TC",
      "B110B",
      "B110C",
      "B110C SLA",
      "B110C T4 FINAL",
      "B110C TC",
      "B110D",
      "B110SCP",
      "B115CTC",
      "B75D",
      "B95",
      "B95B",
      "B95B Canada",
      "B95C",
      "B95C T4 FINAL",
      "B95D",
    ],
  },
  {
    name: "Tractor_100 PTO HP and higher",
    options: [],
  },
  {
    name: "Tractor_4WD",
    options: [],
  },
  {
    name: "Tractor_Ldr/Attch 60 HP and below",
    options: [],
  },
  {
    name: "Trailer",
    options: [],
  },
  {
    name: "Truck",
    options: [],
  },
  {
    name: "_ATV",
    options: [],
  },
  {
    name: "_Ag Equip Other",
    options: [],
  },
  {
    name: "_Commercial Mower",
    options: [],
  },
  {
    name: "_Construction Attachment",
    options: [],
  },
  {
    name: "_Construction Equip Other",
    options: [],
  },
  {
    name: "_Dealer Business System",
    options: [],
  },
  {
    name: "_Dealer Rolling Stock",
    options: [],
  },
  {
    name: "_General Equity",
    options: [],
  },
  {
    name: "_Irrigation Equip",
    options: [],
  },
  {
    name: "_Service Tool",
    options: [],
  },
];

export const cnhOccupationOptions = [
  {
    optionLabel: "Full Time Farmer",
    optionValue: "full_time_farmer",
  },
  {
    optionLabel: "Part-time Farmer",
    optionValue: "part_time_farmer",
  },
  {
    optionLabel: "Custom National Operator",
    optionValue: "custom_national_operator",
  },
  {
    optionLabel: "Custom Regional Operator",
    optionValue: "custom_regional_operator",
  },
  {
    optionLabel: "Lawn & Ground Care",
    optionValue: "lawn_ground_care",
  },
  {
    optionLabel: "Construction",
    optionValue: "construction",
  },
  {
    optionLabel: "Excavating/Grading/Trenching",
    optionValue: "excavating_grading_trenching",
  },
  {
    optionLabel: "Logging",
    optionValue: "logging",
  },
  {
    optionLabel: "Rental Yard",
    optionValue: "rental_yard",
  },
  {
    optionLabel: "Landscape",
    optionValue: "landscape",
  },
  {
    optionLabel: "Road & Street",
    optionValue: "road_street",
  },
  {
    optionLabel: "Building Contractor",
    optionValue: "building_contractor",
  },
  {
    optionLabel: "Dealer",
    optionValue: "dealer",
  },
  {
    optionLabel: "Dealer Principal",
    optionValue: "dealer_principal",
  },
];

export const FAST_TRACK_STATUS = {
  AWAITING_LENDER_APPROVAL: "AWAITING_LENDER_APPROVAL",
  AWAITING_DEALER_APPROVAL: "AWAITING_DEALER_APPROVAL",
  LENDER_DECLINED: "LENDER_DECLINED",
  LENDER_APPROVED: "LENDER_APPROVED",
  PROPOSAL_SENT: "PROPOSAL_SENT",
  PROPOSAL_VIEWED: "PROPOSAL_VIEWED",
  PROPOSAL_ACCEPTED: "PROPOSAL_ACCEPTED",
};

export const CREDIT_SUBMISSION_SOURCE = {
  PORTAL_BY_DEALER: 100,
  PORTAL_BY_LENDER: 300,
  FAST_TRACK_AUTO: 600,
  FAST_TRACK_MANUAL: 500,
  CRM_BY_USER: 200,
};

export const CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE = {
  100: "Dealer",
  300: "Lender",
  600: "Fast Track - Auto",
  500: "Fast Track - Manual",
  200: "System",
};

export const humanizeBoolValue = value => {
  switch (true) {
    case value === true:
      return "Yes";
    case value === false:
      return "No";
    default:
      return null;
  }
};

export const getCreditAppSelectList = (keyValueHashMap, isEmptyOptionNeeded) => {
  const creditAppSelectList = _.map(keyValueHashMap, (value, dbKey) => {
    return {
      text: value,
      value: dbKey,
    };
  });
  if (isEmptyOptionNeeded) {
    creditAppSelectList.unshift({ text: "None", value: null });
  }
  return creditAppSelectList;
};

export const fleetSegmentsKeyValueHashMap = {
  general_freight: "Dry Van - General Freight",
  truckload: "Truckload (TL)",
  less_the_truckload: "Less the Truckload (LTL)",
  other: "Other",
};

export const fleetOperationKeyValueHashMap = {
  local: "Local",
  regional: "Regional",
  interstate: "Interstate",
  intrastate: "Intrastate",
};

export const monthsKeyValueHashMap = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const ownershipTypeKeyValueHashMap = {
  ownership: "Ownership, economic interest, voting rights or shares > 25%",
  effective_control: "Person who exercises effective control",
  executive_management: "BODs, Executive Management",
};

export const operatingLineKeyValueHashMap = {
  accounts_receivable: "Accounts Receivable",
  all_assets: "All Assets",
  personal_guaranty: "Personal Guaranty ",
  other: "Other",
};

export const industryTypeKeyValueHashMap = {
  agriculture: "Agriculture",
  beverage: "Beverage",
  construction: "Construction",
  crane: "Crane",
  delivery: "Delivery",
  food_grocery: "Food/Grocery",
  forestry: "Forestry",
  hazardous_toxic: "Hazardous/Toxic",
  manufacturing: "Manufacturing",
  material_handling: "Material Handling",
  mining: "Mining",
  mixer: "Mixer (concrete)",
  municipal: "Municipal",
  other: "Other",
  refuse: "Refuse",
  rental_house: "Rental House",
  tank: "Tank",
  tow_recovery: "Tow Recovery",
  transportation: "Transportation",
  oilfield: "Oilfield",
};

export const getS3ImageUrl = imageUrl => {
  if (!imageUrl) {
    return "";
  }
  return `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${imageUrl}`;
};

export const defaultColorSchema = {
  color: "#1473e6",
  secColor: "#1473e6",
  textColor: "#000000",
  panelTextColor: "#ffffff",
};

export const filterContactsByRoles = (contacts, roles, currentUserId) => {
  return _.filter(contacts, contact => {
    return _.includes(roles, contact.vendorContactRole) && (currentUserId ? contact.id !== currentUserId : true);
  });
};

export const checkIfIndividualCreditApp = applicationType => {
  const individualsApplicationTypes = _.map(
    [creditAppTypes.individual, creditAppTypes.individualWithSecondGuarantor],
    _.toLower
  );
  return _.includes(individualsApplicationTypes, _.toLower(applicationType));
};

// Returns array of 100 years from today to 100 years ago
export const getYearsArray = () => {
  const maxYear = new Date().getFullYear() + 1;
  const numOfYears = maxYear - 100;
  let years = [];

  for (let i = maxYear; i >= numOfYears; i--) {
    years.push(i);
  }
  return years;
};

// Returns array of months of the year both abbreviated and full name
export const getMonthsArray = () => {
  return moment.months().map((long, i) => ({
    num: i + 1,
    short: moment.monthsShort()[i],
    long,
  }));
};

// Returns array of the days of the month [1 - 31]
export const getDaysArray = () => {
  return Array(31)
    .fill()
    .map((x, i) => i + 1);
};

// Returns array of all states with abbreviation
export const getStatesArray = () => [
  { name: "Alabama", abbr: "AL" },
  { name: "Alaska", abbr: "AK" },
  { name: "Arizona", abbr: "AZ" },
  { name: "Arkansas", abbr: "AR" },
  { name: "California", abbr: "CA" },
  { name: "Colorado", abbr: "CO" },
  { name: "Connecticut", abbr: "CT" },
  { name: "Delaware", abbr: "DE" },
  { name: "Florida", abbr: "FL" },
  { name: "Georgia", abbr: "GA" },
  { name: "Hawaii", abbr: "HI" },
  { name: "Idaho", abbr: "ID" },
  { name: "Illinois", abbr: "IL" },
  { name: "Indiana", abbr: "IN" },
  { name: "Iowa", abbr: "IA" },
  { name: "Kansas", abbr: "KS" },
  { name: "Kentucky", abbr: "KY" },
  { name: "Louisiana", abbr: "LA" },
  { name: "Maine", abbr: "ME" },
  { name: "Maryland", abbr: "MD" },
  { name: "Massachusetts", abbr: "MA" },
  { name: "Michigan", abbr: "MI" },
  { name: "Minnesota", abbr: "MN" },
  { name: "Mississippi", abbr: "MS" },
  { name: "Missouri", abbr: "MO" },
  { name: "Montana", abbr: "MT" },
  { name: "Nebraska", abbr: "NE" },
  { name: "Nevada", abbr: "NV" },
  { name: "New hampshire", abbr: "NH" },
  { name: "New jersey", abbr: "NJ" },
  { name: "New mexico", abbr: "NM" },
  { name: "New york", abbr: "NY" },
  { name: "North carolina", abbr: "NC" },
  { name: "North dakota", abbr: "ND" },
  { name: "Ohio", abbr: "OH" },
  { name: "Oklahoma", abbr: "OK" },
  { name: "Oregon", abbr: "OR" },
  { name: "Pennsylvania", abbr: "PA" },
  { name: "Rhode island", abbr: "RI" },
  { name: "South carolina", abbr: "SC" },
  { name: "South dakota", abbr: "SD" },
  { name: "Tennessee", abbr: "TN" },
  { name: "Texas", abbr: "TX" },
  { name: "Utah", abbr: "UT" },
  { name: "Vermont", abbr: "VT" },
  { name: "Virginia", abbr: "VA" },
  { name: "Washington", abbr: "WA" },
  { name: "West virginia", abbr: "WV" },
  { name: "Wisconsin", abbr: "WI" },
  { name: "Wyoming", abbr: "WY" },
];

export const getCountriesArray = () => [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: `Cote D'Ivoire`, code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: `Korea, Democratic People's Republic of`, code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: `Lao People's Democratic Republic`, code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const OpportunityTypes = {
  CASH_SALE: "Cash Sale",
  CREDIT_APP: "Credit App",
};
export const getHeadersFromAuth = async function (authInstance) {
  const cognito = await authInstance.currentSession();
  const idToken = cognito.getIdToken().getJwtToken();
  const accessToken = cognito.getAccessToken().getJwtToken();

  return {
    "x-dcr-api-token": idToken,
    "x-dcr-api-user-access-token": accessToken,
  };
};

export const areAllRatesSame = rates => {
  if (rates.length === 0) {
    return true;
  }
  const firstRate = rates[0];
  return rates.every(rate => rate === firstRate);
};

export const isEmailValid = email => {
  if (!email) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isVideoOrImage = url => {
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  const extension = url.split(".").pop().toLowerCase();

  if (videoExtensions.includes(extension)) {
    return "video";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else {
    return "unknown";
  }
};

export const getProductVendorName = (product, accountName) => {
  const vendorName = product.aftermarketVendorApiChannel;
  const partnerLinkName = product.partnerLinks[0]?.name;
  return partnerLinkName
    ? partnerLinkName.replace(new RegExp(accountName, "i"), "").replace(/-\s*$/, "").trim()
    : vendorName;
};
