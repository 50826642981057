import { Location, UserProfile } from "@trnsact/trnsact-shared-types";
import { SelectOption } from "global";
import { vendorContactRoleHumanReadable, vendorSalesGroupSubsetContactRole } from "utils";

const getUserRolesSelectOptions = (): SelectOption[] =>
  Object.keys(vendorContactRoleHumanReadable).map(key => ({
    label: vendorContactRoleHumanReadable[key as keyof typeof vendorContactRoleHumanReadable],
    value: key,
  }));

const getUserRolesSubSetSelectOptions = (): SelectOption[] =>
  Object.keys(vendorSalesGroupSubsetContactRole).map(key => ({
    label: vendorSalesGroupSubsetContactRole[key as keyof typeof vendorSalesGroupSubsetContactRole],
    value: key,
  }));

const getUsersSelectOptions = (users: UserProfile[]): SelectOption[] =>
  users.map(user => ({ label: user.fullName!, value: user.id }));

const getLocationsSelectOptions = (locations: Location[]): SelectOption[] =>
  locations.map(location => ({ label: location.locationName!, value: location.locationId! }));

export { getUserRolesSelectOptions, getUsersSelectOptions, getLocationsSelectOptions, getUserRolesSubSetSelectOptions };
