import React, { useEffect, useState } from "react";

import {
  CreditApplication,
  CreditApplicationEntityType,
  CreditApplicationOwner,
  CreditBureauReport,
  CreditBureauType,
  CreditPullSettings,
  CreditReporterCode,
  CreditSubmission,
  SoftPullInDealerPortal,
  VendorOpportunity,
  VendorProfile,
} from "@trnsact/trnsact-shared-types";

import Tooltip from "@mui/material/Tooltip";
import Button from "components/CustomButtons/Button";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import CreditReportDetails from "../pages/VendorOpportunity/CreditReportDetails";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { GetApp, Refresh } from "@mui/icons-material";
import { theme } from "../theme";

import TRANS_UNION_LOGO from "assets/img/credit-bureau-logos/TRANS_UNION.png";
import EXPERIAN_LOGO from "assets/img/credit-bureau-logos/EXPERIAN.png";
import EQUIFAX_LOGO from "assets/img/credit-bureau-logos/EQUIFAX.png";
import _ from "lodash";

const TRIGGER_CREDIT_REPORT_CONSUMER_PULL = gql`
  mutation ($input: TriggerCreditReportConsumerPullInput!) {
    triggerCreditReportConsumerPull(input: $input) {
      creditReportConsumerId
      externalReportId
      vendorOpportunityId
      ownerPgDynamicsId
      ownerPgId
      cbrDynamicsId
      reportType
      score
      machineReadableReport {
        bureauType
        scoreType
        creditBureauType
        humanReadableReportDocumentId
        ownerPgId
        fields {
          key
          value
        }
        ####
        ficoScore
        publicRecordCount
        pastAmountDue
        totalInquiryCount
        satisfactoryAccountsCount
        installmentBalance
        monthlyPayment
        inquiryCountLastSixMonths
        derogAccountsCount
        realEstateBalance
        realEstatePayment
        tradelineCount
        pastDerogAccountsCount
        collectionRecordsCount
        revolvingAvailPercent
        paidAccountsCount
        oldestTradeline
        bankruptcyRecordCount
        revolvingBalance
        revolvingTotalCredit
        errorDescription
      }
      humanReadableReportDocumentId
      errorDescription
      createdDateTime
    }
  }
`;

type CbrsByOwnerPgId = { ownerPgId: string; cbrs: CreditBureauReport[] }[];

type TriggerCreditReportConsumerPullInput = {
  input: {
    dynamicsCreditApplicationId: string | undefined | null;
    ownerPgId: string;
    ownerPgDynamicsId: string | undefined | null;
    creditReporterCode: CreditReporterCode.LegacyCrm | CreditReporterCode.Cbc;
    creditBureauType: CreditBureauType;
    creditSubmissionId: null | undefined | string;
    creditSubmissionDynamicsId: null | undefined | string;
  };
};

const useStyles = makeStyles({
  cbrSoftPullInfoIcon: {
    marginLeft: "5px",
    color: "#AEADAE",
    "&:hover": {
      color: "#6B6A6B",
    },
  },
  creditBureauConsumerReportContainer: {
    paddingBottom: "8px",
    marginLeft: "12px",
    textAlign: "left",
  },
  wrapperLinearProgressLoader: {
    width: "75%",
    margin: "auto",
  },
  creditBureauReportError: {
    fontWeight: "bold",
    color: "red",
    fontSize: "10px",
  },
});

export function GuarantorListWithCreditPull({
  vp,
  vo,
  creditApp,
  creditSubmission,
  isCreditReportsHiddenForUserRole,
  refetchCreditApp,
  handleOpenCreditReportDocument,
}: {
  vp: VendorProfile;
  vo: VendorOpportunity;
  creditApp: CreditApplication;
  creditSubmission?: CreditSubmission | null;
  isCreditReportsHiddenForUserRole: boolean;
  refetchCreditApp: () => void;
  handleOpenCreditReportDocument: (
    pgCbrs: { ownerPgId: string; cbrs: CreditBureauReport[] } | undefined,
    cbr: CreditBureauReport
  ) => void;
}) {
  const classes = useStyles();
  const customerSummaryTableData: any[] = [];

  // TODO: TO FIX TYPES
  /*   type ReportTypeKey = `${string}-${CreditBureauType}`;
  interface ReportData {
    [key: ReportTypeKey]: string;
  } */

  const [cbrPullLoading, setCbrPullLoading] = useState<any /* ReportData */>({});
  const [openCreditPullErrorSnackBar, setOpenCreditPullErrorSnackBar] = useState(false);
  const [openCreditBureauReportModal, setOpenCreditBureauReportModal] = useState(false);
  const [creditBureauReportBeingViewed, setCreditBureauReportBeingViewed] = useState(false);

  const [creditPullErrorSnackBarText, setCreditPullErrorSnackBarText] = useState(null);
  const [repullAlert, setRepullAlert] = useState(false);

  const [cbrsByOwnerPgId, setCbrsByOwnerPgId] = useState<CbrsByOwnerPgId>([]);

  const handleCloseCreditReportDetailsModal = () => setOpenCreditBureauReportModal(false);

  function handleCreditPullErrorSnackBarClose() {
    setOpenCreditPullErrorSnackBar(false);
  }
  const [triggerCreditReportConsumerPull] = useMutation(TRIGGER_CREDIT_REPORT_CONSUMER_PULL, {
    context: { authRequired: true },
  });

  const CbrSoftPullTooltip = withStyles({
    tooltip: {
      backgroundColor: "#373739",
      fontSize: "12px",
    },
  })(Tooltip);

  function BoldText({ text }: { text: string }) {
    return <b>{text}</b>;
  }

  useEffect(() => {
    if (_.isEmpty(creditApp?.creditApplicationOwner)) {
      return;
    }
    const personalGuarantors = creditApp.creditApplicationOwner?.filter((o: any) => o.pg);

    let cbrs: CbrsByOwnerPgId = [];
    personalGuarantors?.forEach(cao => {
      if (cao && cao.ownerPgId && cao.personalGuarantor && cao.personalGuarantor.mostRecentCbrs) {
        const cbrWithPgInfo = {
          ownerPgId: cao.ownerPgId,
          cbrs: cao.personalGuarantor.mostRecentCbrs,
        };
        // @ts-expect-error TS is complaining that Maybe<CreditBureauReport>[] is not assignable to CreditBureauReport[]
        cbrs.push(cbrWithPgInfo);
      }
    });
    setCbrsByOwnerPgId(cbrs);
  }, [creditApp]);

  async function triggerCbrSoftPull(
    ownerPgId: string,
    pgoIndex: number,
    ownerPgDynamicsId: string | null,
    creditBureauType: any
  ) {
    setOpenCreditPullErrorSnackBar(false);
    // setDoneRefetchingCreditApp(false);
    setCbrPullLoading((prevData: any) => ({
      ...prevData,
      [`${ownerPgId}-${creditBureauType}`]: true,
    }));
    console.log("#######################################");
    console.log(
      `OWNER PG ID: ${ownerPgId} | OWNER PG DYNAMICS ID: ${ownerPgDynamicsId} | CREDIT BUREAU TYPE: ${creditBureauType}`
    );
    const variables: TriggerCreditReportConsumerPullInput = {
      input: {
        dynamicsCreditApplicationId: creditApp.dynamicsId,
        ownerPgId: ownerPgId,
        ownerPgDynamicsId: ownerPgDynamicsId,
        creditReporterCode:
          vp.softPullInDealerPortal === SoftPullInDealerPortal.CbcEquifaxSoftSummaryOnly ||
          vp.softPullInDealerPortal === SoftPullInDealerPortal.CbcEquifaxSoftFullReport
            ? CreditReporterCode.Cbc
            : CreditReporterCode.LegacyCrm,
        creditBureauType: creditBureauType,
        creditSubmissionId: undefined, // PG
        creditSubmissionDynamicsId: undefined, // Dynamics
      },
    };
    if (creditSubmission) {
      if (creditSubmission.creditSubmissionId) {
        variables.input.creditSubmissionId = creditSubmission.creditSubmissionId;
        variables.input.creditSubmissionDynamicsId = creditSubmission.creditSubId;
      } else {
        console.log("Credit Submission ID is null, cannot pull credit report.");
        return;
      }
    }
    console.log(JSON.stringify({ variables }));
    console.log("#######################################");
    const triggerCreditReportConsumerPullResult = await triggerCreditReportConsumerPull({
      variables,
    });

    // console.log(`cbrPullLoading: ${JSON.stringify(cbrPullLoading)}`);
    // console.log(`ownerPgId: ${ownerPgId} | creditBureauType: ${creditBureauType}`);

    let cbrs: CbrsByOwnerPgId = cbrsByOwnerPgId;

    if (!cbrs) {
      cbrs = [];
    }
    let foundPg = false;
    for (let i = 0; i < cbrs.length; i++) {
      if (cbrs[i].ownerPgId === ownerPgId) {
        foundPg = true;
        cbrs[i].cbrs.push(
          triggerCreditReportConsumerPullResult.data.triggerCreditReportConsumerPull.machineReadableReport
        );
      }
    }
    if (!foundPg) {
      cbrs.push({
        ownerPgId: ownerPgId,
        cbrs: [triggerCreditReportConsumerPullResult.data.triggerCreditReportConsumerPull.machineReadableReport],
      });
    }
    setCbrsByOwnerPgId(cbrs);

    setCbrPullLoading((prevData: any) => ({
      ...prevData,
      [`${ownerPgId}-${creditBureauType}`]: false,
    }));

    await refetchCreditApp();
  }

  const handleConsumerCreditBureauReportRepull = (
    pgo: CreditApplicationOwner,
    pgoIndex: number,
    cps: any /*  CreditPullSettings */
  ) => {
    const lastRepull = localStorage.getItem("lastRepull");

    if (!cps) {
      setRepullAlert(true);
      return;
    }

    const personalGuarantorId = _.get(pgo, "personalGuarantor.personalGuarantorId", null);
    const personalGuarantorDynamicsId = _.get(pgo, "personalGuarantor.personalGuarantorDynamicsId", null);

    if (personalGuarantorId && (!lastRepull || lastRepull != new Date().toDateString())) {
      localStorage.setItem("lastRepull", new Date().toDateString());
      triggerCbrSoftPull(personalGuarantorId, pgoIndex, personalGuarantorDynamicsId, cps.creditBureauType);
    } else {
      setRepullAlert(true);
    }
  };

  function handleOpenCreditReportDetails(pgDetails: CbrsByOwnerPgId | undefined, cbr: any) {
    console.log(`handleOpenCreditReportDetails CALLED`);
    // console.log(`handleOpenCreditReportDetails pgDetails: ${JSON.stringify(pgDetails)}`)
    // console.log(`handleOpenCreditReportDetails cbr: ${JSON.stringify(cbr)}`)
    const cao = creditApp?.creditApplicationOwner?.find(cao => {
      const ownerPgId = _.get(pgDetails, "ownerPgId", null);
      if (!cao || !ownerPgId) {
        return false;
      }
      return cao.ownerPgId === ownerPgId;
    });
    if (!cao) {
      return;
    }
    cbr.pgDetails = {
      firstName: cao.firstName,
      lastName: cao.lastName,
    };
    setCreditBureauReportBeingViewed(cbr);
    setOpenCreditBureauReportModal(true);
  }

  if (vp && vo.entityType !== CreditApplicationEntityType.CashSale) {
    if (
      vp.softPullInDealerPortal === SoftPullInDealerPortal.OnLegacyCrm ||
      vp.softPullInDealerPortal === SoftPullInDealerPortal.CbcEquifaxSoftSummaryOnly ||
      vp.softPullInDealerPortal === SoftPullInDealerPortal.CbcEquifaxSoftFullReport ||
      vp.softPullInDealerPortal === SoftPullInDealerPortal.Preview
    ) {
      if (!creditApp) {
        return <LinearProgress />;
      }
      const personalGuarantorOwners: CreditApplicationOwner[] = [];
      if (creditApp.creditApplicationOwner) {
        creditApp.creditApplicationOwner.forEach(o => {
          if (o && o.pg) {
            // @ts-ignore Check Typings
            personalGuarantorOwners.push(o);
          }
        });
      }

      if (personalGuarantorOwners.length === 0) {
        customerSummaryTableData.push("", "Corp-Only");
      } else {
        !isCreditReportsHiddenForUserRole &&
          personalGuarantorOwners.forEach((pgo, pgoIndex) => {
            let action = null;

            if (vp.softPullInDealerPortal === SoftPullInDealerPortal.Preview) {
              action = (
                <div style={{ paddingLeft: "10%" }}>
                  {
                    <div>
                      <CbrSoftPullTooltip
                        placement="top-start"
                        title={
                          <div style={{ backgroundColor: "#373739" }}>
                            <span style={{ fontWeight: "bolder" }}>
                              {`Your account is not yet set up to run soft credit pulls.`}
                            </span>
                            <span style={{ whiteSpace: "pre-line" }}>
                              {`\n\n`}
                              <a target="_blank" rel="noopener noreferrer" href="https://info.dcr.ai/softpulls">
                                Click here
                              </a>{" "}
                              to watch a video and learn more about how you can get this functionality in your portal!
                            </span>
                          </div>
                        }>
                        <span>
                          {/* @ts-ignore */}
                          <Button color="grayColor" size="sm">
                            Pull Credit
                          </Button>
                        </span>
                      </CbrSoftPullTooltip>

                      <CbrSoftPullTooltip
                        placement="top-start"
                        style={{ display: "inline" }}
                        title={
                          <div style={{ backgroundColor: "#373739" }}>
                            <span style={{ fontWeight: "bolder" }}>
                              {`Your account is not yet set up to run soft credit pulls.`}
                            </span>
                            <span style={{ whiteSpace: "pre-line" }}>
                              {`\n\n`}
                              <a target="_blank" rel="noopener noreferrer" href="https://info.dcr.ai/softpulls">
                                Click here
                              </a>{" "}
                              to watch a video and learn more about how you can get this functionality in your portal!
                            </span>
                          </div>
                        }>
                        <div style={{ display: "inline", paddingTop: "8px", position: "absolute" }}>
                          <InfoIcon className={classes.cbrSoftPullInfoIcon} />
                        </div>
                      </CbrSoftPullTooltip>
                    </div>
                  }
                </div>
              );
            } else {
              if (_.some(vp.creditPullSettings)) {
                const creditPullButtons = vp?.creditPullSettings?.map(cps => {
                  const creditBureauText = cps?.creditBureauType?.replace("_", " ");
                  let creditBureauLogo;

                  if (!cps) {
                    return null;
                  }

                  if (cps.creditBureauType === CreditBureauType.TransUnion) {
                    creditBureauLogo = TRANS_UNION_LOGO;
                  } else if (cps.creditBureauType === CreditBureauType.Experian) {
                    creditBureauLogo = EXPERIAN_LOGO;
                  } else if (cps.creditBureauType === CreditBureauType.Equifax) {
                    creditBureauLogo = EQUIFAX_LOGO;
                  }

                  let mostRecentCbrForThisCreditBureauType: CreditBureauReport | undefined = undefined;

                  const pgCbrs = cbrsByOwnerPgId.find(cbrByOwnerPgId => {
                    return cbrByOwnerPgId.ownerPgId === pgo.ownerPgId;
                  });

                  if (pgCbrs && pgCbrs.cbrs) {
                    mostRecentCbrForThisCreditBureauType = pgCbrs.cbrs.find(mrCbr => {
                      if (mrCbr && cps) {
                        // @ts-ignore This type does exist, not sure why it's reporting it doesn't
                        return mrCbr.creditBureauType === cps.creditBureauType;
                      }
                      return false;
                    });
                  }

                  //This is a valid key
                  if (cbrPullLoading[`${pgo.ownerPgId}-${cps.creditBureauType}`]) {
                    return (
                      <div className={classes.creditBureauConsumerReportContainer}>
                        <img height="22px" width="22px" src={creditBureauLogo} />
                        {
                          <LinearProgress
                            className={classes.wrapperLinearProgressLoader}
                            style={{
                              position: "relative",
                              top: "50%",
                              transform: "translateY(-285%)",
                            }}
                          />
                        }
                      </div>
                    );
                  } else if (
                    mostRecentCbrForThisCreditBureauType &&
                    !mostRecentCbrForThisCreditBureauType.errorDescription
                  ) {
                    return (
                      <div className={classes.creditBureauConsumerReportContainer}>
                        <img height="22px" width="22px" src={creditBureauLogo} />
                        <span>
                          {/* <CheckIcon size="sm" style={{ color: "#4CAF50" }} /> */}
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenCreditReportDocument(pgCbrs, mostRecentCbrForThisCreditBureauType)
                            }>
                            <GetApp style={{ fill: theme.palette.primary.main }} />
                          </IconButton>
                        </span>
                        <span
                          style={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            // @ts-ignore
                            handleOpenCreditReportDetails(pgCbrs, mostRecentCbrForThisCreditBureauType)
                          }>
                          Details
                        </span>
                        <span>
                          <IconButton
                            onClick={() => {
                              //This is a valid type
                              handleConsumerCreditBureauReportRepull(pgo, pgoIndex, cps);
                            }}
                            size="large">
                            <Refresh />
                          </IconButton>
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ paddingBottom: "8px", textAlign: "left" }}>
                        {/* @ts-ignore */}
                        <Button
                          color="primary"
                          size="sm"
                          style={{
                            width: "168px",
                            textAlign: "left",
                            justifyContent: "left",
                            height: "28px",
                            padding: "4px",
                          }}
                          onClick={() => {
                            const personalGuarantorId = _.get(pgo, "personalGuarantor.personalGuarantorId", null);
                            const personalGuarantorDynamicsId = _.get(
                              pgo,
                              "personalGuarantor.personalGuarantorDynamicsId",
                              null
                            );
                            if (personalGuarantorId) {
                              triggerCbrSoftPull(
                                personalGuarantorId,
                                pgoIndex,
                                personalGuarantorDynamicsId,
                                cps.creditBureauType
                              );
                            } else {
                              console.log(
                                `Personal Guarantor ID or Dynamics ID is null for ${pgo.firstName} ${pgo.lastName}`
                              );
                            }
                          }}>
                          <img height="22px" width="22px" src={creditBureauLogo} />
                          <span style={{ paddingLeft: "8px" }}>Pull {creditBureauText}</span>
                        </Button>
                        {mostRecentCbrForThisCreditBureauType &&
                          mostRecentCbrForThisCreditBureauType.errorDescription && (
                            <div className={classes.creditBureauReportError}>
                              {creditBureauText} Error: {mostRecentCbrForThisCreditBureauType.errorDescription}
                            </div>
                          )}
                      </div>
                    );
                  }
                });
                action = creditPullButtons;
              }
            }
            // data-owner-pg-id is to be able to determine outside of the component to which owner action belongs
            customerSummaryTableData.push([
              <span style={{ paddingLeft: "8%" }} data-owner-pg-id={pgo.ownerPgId}>
                {pgo.firstName} {pgo.lastName}
              </span>,
              action,
            ]);
          });
      }

      customerSummaryTableData.push(
        <>
          <Snackbar
            style={{ marginTop: "50px" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openCreditPullErrorSnackBar}
            onClose={handleCreditPullErrorSnackBarClose}>
            <Alert severity="error" onClose={handleCreditPullErrorSnackBarClose} variant="filled">
              <div>{creditPullErrorSnackBarText && creditPullErrorSnackBarText}</div>
              <div>
                {!creditPullErrorSnackBarText && (
                  <div>
                    Sorry, there seems to be some issue with the service that is used to perform this function. Please
                    try again later. If the issue persists, please contact your Customer Success Manager.
                  </div>
                )}
              </div>
            </Alert>
          </Snackbar>
          <Snackbar
            style={{ marginTop: "50px" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={repullAlert}
            onClose={() => setRepullAlert(false)}>
            <Alert severity="info" onClose={() => setRepullAlert(false)} variant="filled">
              <p>Your repull limit has been reached for today. Please try again tomorrow.</p>
            </Alert>
          </Snackbar>

          <Modal
            open={openCreditBureauReportModal}
            // onClose={handleCloseEquipmentInputModal}
            aria-labelledby="Credit Report Details"
            aria-describedby="CBR Details">
            <div>
              <CreditReportDetails
                creditBureauReportWithPgDetails={creditBureauReportBeingViewed}
                handleClose={handleCloseCreditReportDetailsModal}
              />
            </div>
          </Modal>
        </>
      );
    }
  }

  return customerSummaryTableData;
}
