import { gql } from "@apollo/client";

const GET_CREDIT_SUBMISSIONS = gql`
  query creditSubmissionsByMasterCreditSubmissionId($id: ID!) {
    creditSubmissionsByMasterCreditSubmissionId(id: $id) {
      creditSubmissionId
      voId
      creditSubId
      submissionDate
      decision
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      draft
      creditSubStage
      transactionSummary
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterCreditSubmissionId
      syndicatedBy {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterLenderName
      displayStatus
    }
  }
`;

const SUBMIT_APP = gql`
  mutation($input: CreditSubmissionInputs!) {
    createCreditSubmissions(input: $input)
  }
`;

const QUERY_USER_DOCS = gql`
  query($docId: ID, $VOId: ID, $creditSubId: ID) {
    documents(docId: $docId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      creditSubId
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
    lenderDecisionLetters(creditSubId: $creditSubId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;

export { SUBMIT_APP, GET_CREDIT_SUBMISSIONS, QUERY_USER_DOCS };
