import React from "react";
import { Typography } from "@mui/material";

interface Props {
  value: string | number | null | undefined;
  formatter?: (value: string | number) => string;
}

export const SimpleTextCell = ({ value, formatter }: Props) => {
  if (typeof value === "object" || value === null || value === undefined || value === "") {
    return (
      <Typography align="center" component="span" variant="body2">
        -
      </Typography>
    );
  }

  return (
    <Typography align="center" component="span" variant="body2">
      {formatter ? formatter(value) : value}
    </Typography>
  );
};
