import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const EmptyList = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="span" variant="h6">
        List of products is empty
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
