import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";
export const PostalCodeField = ({ fieldConfig }) => {
  const [value, setValue] = useState(undefined);
  return (
    <TextField
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant={fieldConfig.variant}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      helperText={_.get(fieldConfig, "helperText.en", "")}
      required={fieldConfig.required}
      value={value}
      style={{ pointerEvents: "none" }}
    />
  );
};
