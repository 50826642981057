import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { FormControl } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../components/CustomButtons/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import FormHelperText from "@mui/material/FormHelperText";
// import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 600,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 600,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
}));

const CREATE_NEW_PARTNER_LINK = gql`
  mutation ($input: PartnerLinkInput!) {
    partnerCreatePartnerLink(input: $input) {
      success
      errorMessage
    }
  }
`;

const useUiStyles = makeStyles(styles);

const AddDealers = ({ account, vp }) => {
  const classes = useStyles();
  const uiClasses = useUiStyles();
  const [email, setEmail] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [emailError, setEmailError] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [dealerName, setDealerName] = useState("");
  const [dealerContactName, setDealerContactName] = useState("");
  const [dealerNameError, setDealerNameError] = useState("");
  const [dealerContactNameError, setDealerContactNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [dealerIdError, setDealerIdError] = useState("");
  const [dealerIdErrorMessage, setDealerIdErrorMessage] = useState("Required");
  const [dealerLogo, setDealerLogo] = useState("");
  const [dealerLogoError, setDealerLogoError] = useState("");
  const [dealerLogoErrorMessage, setDealerLogoErrorMessage] = useState("Required");
  const [dealerWebsite, setDealerWebsite] = useState("");
  const [dealerWebsiteError, setDealerWebsiteError] = useState("");
  const [dealerWebsiteErrorMessage, setDealerWebsiteErrorMessage] = useState("Required");
  const [dealerStreet, setDealerStreet] = useState("");
  const [dealerStreetError, setDealerStreetError] = useState("");
  const [dealerStreetErrorMessage, setDealerStreetErrorMessage] = useState("Required");
  const [dealerCity, setDealerCity] = useState("");
  const [dealerCityError, setDealerCityError] = useState("");
  const [dealerCityErrorMessage, setDealerCityErrorMessage] = useState("Required");
  const [dealerState, setDealerState] = useState("");
  const [dealerStateError, setDealerStateError] = useState("");
  const [dealerStateErrorMessage, setDealerStateErrorMessage] = useState("Required");
  const [dealerZip, setDealerZip] = useState("");
  const [dealerZipError, setDealerZipError] = useState("");
  const [dealerZipErrorMessage, setDealerZipErrorMessage] = useState("Required");
  const [dealerPhone, setDealerPhone] = useState("");
  const [dealerPhoneError, setDealerPhoneError] = useState("");
  const [dealerPhoneErrorMessage, setDealerPhoneErrorMessage] = useState("Required");
  const [salesPerson, setSalesPerson] = useState("");
  const [salesPersonError, setSalesPersonError] = useState("");
  const [salesPersonErrorMessage, setSalesPersonErrorMessage] = useState("Required");
  const [salesPhone, setSalesPhone] = useState("");
  const [salesPhoneError, setSalesPhoneError] = useState("");
  const [salesPhoneErrorMessage, setSalesPhoneErrorMessage] = useState("Required");
  const [salesEmail, setSalesEmail] = useState("");
  const [salesEmailError, setSalesEmailError] = useState("");
  const [salesEmailErrorMessage, setSalesEmailErrorMessage] = useState("Required");

  const handleChangedEmail = event => {
    setEmail(event.target.value);
  };
  const handleChangedDealerName = event => {
    setDealerName(event.target.value);
  };
  const handleChangeSetDealerContactName = event => {
    setDealerContactName(event.target.value);
  };
  const handleChangedDescription = event => {
    setDescription(event.target.value);
  };
  const handleChangeSetDealerId = event => {
    setDealerId(event.target.value);
  };
  const handleChangeSetDealerLogo = event => {
    setDealerLogo(event.target.value);
  };
  const handleChangeSetDealerWebsite = event => {
    setDealerWebsite(event.target.value);
  };
  const handleChangeSetDealerStreet = event => {
    setDealerStreet(event.target.value);
  };
  const handleChangeSetDealerState = event => {
    setDealerState(event.target.value);
  };
  const handleChangeSetDealerCity = event => {
    setDealerCity(event.target.value);
  };
  const handleChangeSetDealerZip = event => {
    setDealerZip(event.target.value);
  };
  const handleChangeSetDealerPhone = event => {
    const formattedPhone = formatPhone(event.target.value);
    setDealerPhone(formattedPhone);
  };
  const handleChangeSetSalesPerson = event => {
    console.log("event", event);
    setSalesPerson(event.target.value);
  };
  const handleChangeSetSalesPhone = event => {
    setSalesPhone(event.target.value);
  };
  const handleChangeSetSalesEmail = event => {
    setSalesEmail(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    sendPartnerLink();
  };
  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };
  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };
  const [createPartnerLink] = useMutation(CREATE_NEW_PARTNER_LINK, {
    context: { authRequired: true },
  });

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const validate = () => {
    if (
      !email &&
      !dealerName &&
      !dealerContactName &&
      !dealerId &&
      !dealerWebsite &&
      !dealerStreet &&
      !dealerState &&
      !dealerZip &&
      !dealerPhone &&
      !salesEmail
    ) {
      setEmailError(true);
      setDealerNameError(true);
      setDealerPhoneError(true);
      return false;
    }
    if (!email) {
      setEmailError(true);
      return false;
    }
    if (!dealerName) {
      setDealerNameError(true);
      return false;
    } else {
      setDealerNameError(false);
    }
    if (!dealerContactName) {
      setDealerPhoneError(true);
      return false;
    } else {
      setDealerPhoneError(false);
    }
    if (!dealerContactName) {
      setDealerContactNameError(true);
      return false;
    } else {
      setDealerContactNameError(false);
    }
    if (!dealerWebsite) {
      setDealerWebsiteError(true);
      return false;
    } else {
      setDealerWebsiteError(false);
    }
    if (!dealerStreet) {
      setDealerStreetError(true);
      return false;
    } else {
      setDealerStreetError(false);
    }
    if (!dealerState) {
      setDealerStateError(true);
      return false;
    } else {
      setDealerStateError(false);
    }
    if (!dealerZip) {
      setDealerZipError(true);
      return false;
    } else {
      setDealerZipError(false);
    }
    if (!dealerPhone) {
      setDealerPhoneError(true);
      return false;
    } else {
      setDealerPhoneError(false);
    }
    if (!salesEmail) {
      setSalesEmailError(true);
      return false;
    } else {
      setSalesEmailError(false);
    }
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("");
      return true;
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
      return false;
    }
  };
  const sendPartnerLink = async () => {
    if (validate()) {
      try {
        await handleOpenModal();
        await handleSubmissionMessage({
          title: "Sending",
          message: "Please wait. Sending your request...",
        });
        await createPartnerLink({
          variables: {
            input: {
              dealerName: dealerName,
              dealerContact: dealerContactName,
              dealerEmail: email,
              dealerID: dealerId,
              dealerWebsite: dealerWebsite,
              dealerStreet: dealerStreet,
              dealerState: dealerState,
              dealerZip: dealerZip,
              dealerPhone: dealerPhone,
              salesPersonEmail: salesEmail,
              dealerLogo: dealerLogo,
            },
          },
        });
        await handleSubmissionMessage({
          title: "Success",
          message: "Request Sent Successfully. Please allow 24 hours to process.",
        });
      } catch (err) {
        console.log(err);
        await handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    }
  };

  const cegSalesReps = [
    {
      value: "tmckeon@cegltd.com",
      label: "tmckeon@cegltd.com",
    },
    {
      value: "khogeboom@cegltd.com",
      label: "khogeboom@cegltd.com",
    },
    {
      value: "rmckeon@cegltd.com",
      label: "rmckeon@cegltd.com",
    },
    {
      value: "ebryden@cegltd.com",
      label: "ebryden@cegltd.com",
    },
    {
      value: "tmckeonjr@cegltd.com",
      label: "tmckeonjr@cegltd.com",
    },
    {
      value: "rolivier@cegltd.com",
      label: "rolivier@cegltd.com",
    },
    {
      value: "jmckeon@cegltd.com",
      label: "jmckeon@cegltd.com",
    },
    {
      value: "jphillips@cegltd.com",
      label: "jphillips@cegltd.com",
    },
    {
      value: "dennishogeboom@cegltd.com",
      label: "dennishogeboom@cegltd.com",
    },
    {
      value: "sswanson@cegltd.com",
      label: "sswanson@cegltd.com",
    },
    {
      value: "pkiel@cegltd.com",
      label: "pkiel@cegltd.com",
    },
  ];

  return (
    <Grid container>
      <Grid>
        <Paper>
          <CardHeader title="Add New Dealer" />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <React.Fragment>
                  <TableRow>
                    <TableCell>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerNameError === true}
                          label="Dealer Name"
                          value={dealerName}
                          onChange={handleChangedDealerName}
                          required={true}
                          type="text"
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerIdError === true}
                          label="Partner Dealer ID"
                          value={dealerId}
                          onChange={handleChangeSetDealerId}
                          required={true}
                          type="text"
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerContactNameError === true}
                          label="Dealer Contact"
                          value={dealerContactName}
                          onChange={handleChangeSetDealerContactName}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={emailError === true}
                          label="Dealer Contact Email"
                          value={email}
                          onChange={handleChangedEmail}
                          type="text"
                          required={true}
                          helperText={emailErrorMessage}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerPhoneError === true}
                          label="Dealer Contact Phone"
                          value={dealerPhone}
                          onChange={handleChangeSetDealerPhone}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerLogoError === true}
                          label="Dealer Logo Link"
                          value={dealerLogo}
                          onChange={handleChangeSetDealerLogo}
                          type="text"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerWebsiteError === true}
                          label="Dealer Website"
                          value={dealerWebsite}
                          onChange={handleChangeSetDealerWebsite}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerStreetError === true}
                          label="Dealer Street Address"
                          value={dealerStreet}
                          onChange={handleChangeSetDealerStreet}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerCityError === true}
                          label="Dealer City"
                          value={dealerCity}
                          onChange={handleChangeSetDealerCity}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerStateError === true}
                          label="Dealer State"
                          value={dealerState}
                          onChange={handleChangeSetDealerState}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <TextField
                          error={dealerZipError === true}
                          label="Dealer Zip Code"
                          value={dealerZip}
                          onChange={handleChangeSetDealerZip}
                          type="text"
                          required={true}
                          helperText="Required"
                        />
                      </FormControl>
                      <FormControl className={classes.formControlLenders}>
                        <InputLabel id="credit-sub-select">Assigned Sales Person Email *</InputLabel>
                        <Select
                          value={salesEmail}
                          onChange={handleChangeSetSalesEmail}
                          label="Assigned Sales Person Email"
                          required={true}
                          helperText="Required">
                          {cegSalesReps.map(({ value, label }, i) => (
                            <MenuItem value={value} label={label} key={i}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                      <Button color="primary" className={classes.submitButton} onClick={handleSubmit} type="submit">
                        Send Dealer Request
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              </TableBody>
            </Table>
          </CardContent>
        </Paper>
      </Grid>
      <MessageModal
        isOpen={openMessageModal}
        handleCloseModal={handleCloseModal}
        title={submissionMessage.title}
        message={submissionMessage.message}
      />
    </Grid>
  );
};

export default AddDealers;
