import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { productsCalculationManager } from "./productsCalcManager";
import { ProductPrisingFields } from "../types";

interface Values {
  cost: number;
  retailCost: number;
  rateFactor: number;
  markupValue: number;
  markupType: MarkupType;
}

interface RulesResults {
  costNextValue: number;
  markupNextValue: number;
  rateFactorNextValue: number;
  retailCostNextValue: number;
}

interface Options {
  values: Values;
  nextValue: number;
  equipmentCost: number;
  field: ProductPrisingFields;
  proposalProduct: ProposalProduct;
}

export function proposalProductUpdateDependentFields({
  field,
  values,
  nextValue,
  equipmentCost,
  proposalProduct,
}: Options) {
  const { cost, retailCost, markupValue, markupType } = values;

  const rules: Record<ProductPrisingFields, () => RulesResults> = {
    cost: () => {
      const markup = productsCalculationManager.getMarkupValueByType(markupValue, markupType);

      let costNextValue = nextValue;
      let markupNextValue = markup;
      let retailCostNextValue = retailCost;

      if (!markup && !proposalProduct.retailCost) {
        retailCostNextValue = nextValue;
      }

      if (markup && !proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markup;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markup;
        }
      }

      if (markup && proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markup;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markup;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
        rateFactorNextValue: productsCalculationManager.calculateRateFactor(costNextValue, equipmentCost),
      };
    },
    "markup.markup": () => {
      const markup = productsCalculationManager.getMarkupValueByType(markupValue, markupType);

      let costNextValue = cost;
      let markupNextValue = nextValue;
      let retailCostNextValue = retailCost;

      if (cost && !retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = cost + cost * markup;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = cost + markup;
        }
      }

      if (!cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          costNextValue = retailCost - markup * 100;
        }

        if (markupType === MarkupType.Flat) {
          costNextValue = retailCost - markup;
        }
      }

      if (cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = markup * cost + cost;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + cost;
        }
      }

      if (!markup && retailCost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = retailCost - markup;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = (retailCost - markupValue) / 100;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
        rateFactorNextValue: productsCalculationManager.calculateRateFactor(costNextValue, equipmentCost),
      };
    },
    retailCost: () => {
      const markup = productsCalculationManager.getMarkupValueByType(markupValue, markupType);

      let costNextValue = cost;
      let markupNextValue = markup;
      let retailCostNextValue = nextValue;

      if (markup && !cost) {
        if (markupType === MarkupType.Percentage) {
          costNextValue = nextValue / (1 + markup);
        }

        if (markupType === MarkupType.Flat) {
          costNextValue = nextValue - markup;
        }
      }

      if (markup && cost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = ((nextValue - cost) / cost) * 100;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = nextValue - cost;
        }
      }

      if (!markup && cost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = ((nextValue - cost) / cost) * 100;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = nextValue - cost;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
        rateFactorNextValue: productsCalculationManager.calculateRateFactor(costNextValue, equipmentCost),
      };
    },
    rateFactor: () => {
      const markup = productsCalculationManager.getMarkupValueByType(markupValue, markupType);

      let costNextValue = productsCalculationManager.calculateCostByRateFactor(nextValue, equipmentCost);
      let markupNextValue = markup;
      let retailCostNextValue = retailCost;

      if (!markup && !proposalProduct.retailCost) {
        retailCostNextValue = costNextValue;
      }

      if (markup && !proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = costNextValue + costNextValue * markup;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = costNextValue + markup;
        }
      }

      if (markup && proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = costNextValue + costNextValue * markup;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = costNextValue + markup;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
        rateFactorNextValue: nextValue,
      };
    },
  };

  return rules[field]();
}
