import React, { useEffect, useState } from "react";
import Tile from "components/Tile/Tile";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddNewConfigurableItem } from "./AddNewConfigurableItem";
import { Section } from "./ConfigurableItems/Section";
import { configurableItemsTypes, portalConfigurationTypes } from "../../Prequal/constants";

import { getItemDefaultConfig } from "../../../services/creditAppModalService";

const styles = {
  toggleButtonGroup: {
    "& .Mui-selected": {
      backgroundColor: "#5a78e1",
      color: "white",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#4360c7",
    },
  },
};
const useStyles = makeStyles(styles);

export default function CreditAppDetailModal({
  jsonDefinition,
  setJSONDefinition,
  editConfigurableItem,
  deletePortalConfiguration,
  loadCurrentOCATemplate,
  movingElementSettings,
  setMovingElementSettings,
  selectedConfigurationType,
  setSelectedConfigurationType,
}) {
  const classes = useStyles();
  const [sections, setSections] = useState(jsonDefinition.sections);

  useEffect(() => {
    if (!jsonDefinition) {
      return;
    }
    setSections(jsonDefinition.sections);
  }, [jsonDefinition]);

  const addNewSection = () => {
    setJSONDefinition(prevState => {
      const sectionDefaultConfig = getItemDefaultConfig(configurableItemsTypes.section);
      return {
        ...prevState,
        sections: [...prevState.sections, sectionDefaultConfig],
      };
    });
  };

  const handleTemplateTypeToggle = (event, configType) => {
    if (configType !== null) {
      setSelectedConfigurationType(configType);
    }
  };

  return (
    <Tile
      title={jsonDefinition.config.title}
      titleButtons={[
        {
          text: "Set Current OCA Template",
          props: {
            style: { backgroundColor: "#5a78e1", padding: 5 },
          },
          func: () => loadCurrentOCATemplate(),
        },
        {
          text: "Delete Custom Configuration",
          props: {
            style: { backgroundColor: "#c62626", padding: 5 },
          },
          func: () => deletePortalConfiguration(),
        },
      ]}>
      <ToggleButtonGroup
        className={classes.toggleButtonGroup}
        value={selectedConfigurationType}
        exclusive
        onChange={handleTemplateTypeToggle}
        aria-label="Template type toggle"
        style={{ width: "100%" }}>
        <ToggleButton value={portalConfigurationTypes.CADMCommercial} aria-label="commercial" style={{ width: "50%" }}>
          Commercial
        </ToggleButton>
        <ToggleButton value={portalConfigurationTypes.CADMIndividual} aria-label="individual" style={{ width: "50%" }}>
          Individual
        </ToggleButton>
      </ToggleButtonGroup>
      {sections.map((section, index) => (
        <Section
          key={section.id}
          section={section}
          sectionIndex={index}
          setJSONDefinition={setJSONDefinition}
          setSections={setSections}
          editConfigurableItem={editConfigurableItem}
          movingElementSettings={movingElementSettings}
          setMovingElementSettings={setMovingElementSettings}
        />
      ))}
      <AddNewConfigurableItem displayLabel={"Add New Section"} onClickFn={addNewSection} />
    </Tile>
  );
}
