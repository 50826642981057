import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { SelectOption } from "global";
import debounce from "lodash/debounce";
import Search from "@mui/icons-material/Search";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, InputAdornment, TextFieldProps } from "@mui/material";
import { FormInput, MultiSelect } from "../../form";
import { useViewTypeContext } from "../../../contexts/contentViewType";

type BaseOption = { [key: string]: { label: string; values: SelectOption[] } };

interface Props<Options> {
  options?: Options;
  disabled?: boolean;
  withReset?: boolean;
  extraCbForReset?: VoidFunction;
  onSearchChange: (filter: "search", nextValue: string) => void;
  onOptionsChange?: (filter: keyof Options, nextValue: string[]) => void;
  searchInputProps?: Omit<TextFieldProps, "value" | "onChange">;
}

export const FiltersPanel = <Options extends BaseOption>({
  options,
  onSearchChange,
  extraCbForReset,
  disabled = false,
  withReset = false,
  searchInputProps = {},
  onOptionsChange = () => {},
}: Props<Options>) => {
  const classes = useStyles();

  const { isDesktop } = useViewTypeContext();

  const [searchValue, setSearchValue] = useState<string>("");

  const [optionsValue, setOptionsValue] = useState<Partial<Record<keyof Options, string[]>> | undefined>(() => {
    return (
      options &&
      Object.keys(options).reduce<Partial<Record<keyof Options, string[]>>>((acc, key) => {
        const typedKey = key as keyof Options;

        acc[typedKey] = ["all"];
        return acc;
      }, {})
    );
  });

  const debouncedSearchChange = useCallback(
    debounce((value: string) => {
      onSearchChange("search", value);
    }, 300),
    [onSearchChange]
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    debouncedSearchChange(value);
  };

  const handleSelectChange = (key: keyof Options, selected: string[]) => {
    const isAllAfterOther = selected.includes("all") && selected.length > 1 && selected[selected.length - 1] === "all";
    const isOtherAfterAll = selected.includes("all") && selected.length > 1;

    let newSelected = selected;

    switch (true) {
      case isAllAfterOther:
        newSelected = ["all"];
        break;
      case isOtherAfterAll:
        newSelected = selected.filter(value => value !== "all");
        break;
      default:
        newSelected = selected;
    }

    setOptionsValue(prev => prev && { ...prev, [key]: newSelected });
    onOptionsChange(key, newSelected);
  };

  const handleRest = () => {
    setSearchValue("");
    debouncedSearchChange("");

    if (typeof extraCbForReset === "function") extraCbForReset();
  };

  return (
    <Box className={classes.containerWithRest}>
      <Box
        className={clsx(classes.container, {
          [classes.containerDesktop]: isDesktop,
          [classes.containerMobile]: !isDesktop,
        })}>
        <FormInput
          label="Search"
          variant="outlined"
          disabled={disabled}
          placeholder="Enter value..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={handleSearchChange}
          {...searchInputProps}
        />

        {!!options &&
          Object.entries(options).map(([key, { values, label }]) => (
            <MultiSelect
              key={key}
              label={label}
              value={optionsValue?.[key] ?? []}
              disabled={disabled || !values.length}
              options={[{ value: "all", label: "Show all" }, ...values]}
              onChange={selected => {
                handleSelectChange(key, selected.map(String));
              }}
            />
          ))}
      </Box>

      {withReset && (
        <Box>
          <Button disabled={disabled} onClick={handleRest}>
            Reset Filters
          </Button>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  containerWithRest: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
  },
  containerMobile: {
    flexDirection: "column",
  },
  containerDesktop: {
    justifyContent: "space-between",
  },
});
