import { formatCurrency } from "utils";
import { InvoiceFormValues } from "../model";
import { invoiceCalculations } from "../lib";
import { RenderDetailsConfig } from "../types";

export const invoiceFormInitialValues: InvoiceFormValues = {
  netTrade: 0,
  downPayment: 0,
  invoiceTotal: 0,
  equipmentCost: 0,
  netFinanceAmount: 0,
  salesTaxOnInvoice: 0,
  tradeOwedBalanceAmount: 0,
  freightLaborInstallation: 0,
  manufacturerRebateAmount: 0,
  tradeAllowanceGrossTradeIn: 0,
  invoiceWarrantyAndInsuranceCharges: 0,
};

export const invoiceRenderFieldLabels: RenderDetailsConfig = {
  invoiceWarrantyAndInsuranceCharges: {
    order: 0,
    label: "Other Charges (Installation, shipping, etc)",
    formatter: invoice => formatCurrency(invoice.invoiceWarrantyAndInsuranceCharges, true),
  },
  invoiceTotal: {
    order: 3,
    label: "Invoice Total",
    formatter: invoice => {
      const { invoiceTotal } = invoiceCalculations.byInvoice(invoice);
      return formatCurrency(invoiceTotal, true);
    },
  },
  downPayment: {
    order: 1,
    label: "Trade-in / Down Payment",
    formatter: invoice => formatCurrency(invoice.downPayment, true),
  },
  salesTaxOnInvoice: {
    order: 2,
    label: "Sales Tax",
    formatter: invoice => formatCurrency(invoice.salesTaxOnInvoice, true),
  },
};
