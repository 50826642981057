import { useContext } from "react";
import { jsonRulesEngineContext } from "./jsonRulesEngineContext";

export function useRulesEngine() {
  const context = useContext(jsonRulesEngineContext);

  if (!context) throw new Error("Please use this hook in provider");

  return context;
}
