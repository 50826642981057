import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { DateFormats } from "../../../constants";

interface Props {
  value: string;
}

export const DateCell = ({ value }: Props) => {
  return (
    <Typography component="span" variant="body2">
      {moment(value).format(DateFormats.DateTimeShort12Hour)}
    </Typography>
  );
};
