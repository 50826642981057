import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FormControlProps, RadioGroupProps, RadioProps } from "@mui/material";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FormRadioGroup } from "../FormRadioGroup";

interface RadioGroupOptions {
  value: string;
  label: string;
}

interface Props<FormValues extends FieldValues = FieldValues> extends RadioGroupProps {
  label?: string;
  radioProps?: RadioProps;
  control: Control<FormValues>;
  options: RadioGroupOptions[];
  formControlProps?: FormControlProps;
  name: UseControllerProps<FormValues>["name"];
}

export const RadioGroupField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  options,
  radioProps,
  formControlProps,
  ...radioGroupProps
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <FormRadioGroup
          label={label}
          innerRef={ref}
          options={options}
          radioProps={radioProps}
          formControlProps={formControlProps}
          {...field}
          {...radioGroupProps}
        />
      )}
    />
  );
};
