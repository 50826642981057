import { Notification } from "./types";

const addNotification = (payload: { notification: Notification }) =>
  ({
    type: "NOTIFICATION_ADD",
    payload,
  } as const);

const removeNotification = (payload: { id: number }) =>
  ({
    type: "REMOVE_ADD",
    payload,
  } as const);

export const notificationsActions = { addNotification, removeNotification };
