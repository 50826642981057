import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { Check } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// theme components
import Button from "components/CustomButtons/Button";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import _ from "lodash";
import { Grid, TextField } from "@mui/material";
import { CustomSwitch } from "../../components/CustomSwitch/CustomSwitch";
import { blue } from "@mui/material/colors";

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: 20,
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 125,
    maxWidth: "70%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: "-0,56px",
  },
  draftCheckbox: {
    position: "absolute",
    right: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(2),
    paddingLeft: 0,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: "60%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  transactionContainer: {
    paddingBottom: "10px",
  },
  transactionInput: {
    borderRadius: "10px",
    marginTop: "20px",
    "& .MuiFormLabel-root": {
      color: "#000000",
      fontWeight: "400",
    },
  },
  createSubHeader: {
    marginTop: 8,
    display: "inline-block",
  },
  optionText: {
    fontSize: 14,
    lineHeight: "16px",
    color: "#333",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  optionSubText: {
    color: "#7A7A7A",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "400",
  },
  transactionHeader: {
    fontSize: 20,
    color: "#000",
    lineHeight: "20px",
    fontWeight: 300,
    marginRight: 30,
  },
  selectItem: {
    border: "1px solid #DADCDF",
    borderRadius: "7px",
    padding: 5,
    position: "relative",
    marginRight: 20,
  },
  selectItemText: {
    display: "inline-block",
    borderBottom: "1px solid #DADCDF",
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
  },
  selectItemSubText: {
    display: "inline-block",
    color: "#737373",
    fontSize: "12px",
    fontWeight: 400,
  },
  selectLender: {
    minWidth: 220,
    "& .MuiSelect-root": {
      overflowX: "auto",
    },
  },
}));

const SUBMIT_APP = gql`
  mutation ($input: CreditSubmissionInputs!) {
    createCreditSubmissions(input: $input)
  }
`;

// const lenders = [
//   [''                                    , 'Tracking Only - Requires RouteOne Entry', 'Ford Motor Credit'                   , 'N/A'],
//   ['81F5C112-4E7F-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Test Lender #1'                      , 'Alex Sawatzke'],
//   ['10802EC9-1C80-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Toyota Industries Commercial Finance', 'Vince Monteleone'],
//   [''                                    , 'Submission by Email'                    , 'Hitachi Capital'                     , 'Jay Saul'],
//   ['3D120421-1D80-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Isuzu Finance'                       , 'Bob Mooney'],
//   ['414D9E62-8EE7-E011-9518-005056A20000', 'Submission by Email'                    , 'CalFund'                             , 'N/A'],
//   [''                                    , 'Submission by Email'                    , 'Ascentium Capital'                   , 'N/A'],
//   [''                                    , 'Submission by Email'                    , 'AP Finance'                          , 'N/A'],
//   ["F42A8825-D6E7-E211-9509-005056A20000", 'Tracking Only - Requires RouteOne Entry', 'Ally Bank'                           , "Alex Sawatzke"],
//   ["C62AB074-9E7E-EA11-8119-005056B05A0F", 'Submission by Email'                    , 'Test Lender #2'                      , 'Alex Sawatzke'],
// ];

// const lenderList =  {
//   'Ford Motor Credit': ['','Tracking Only - Requires RouteOne Entry', 'N/A'],
//   'Test Lender #1': ['81F5C112-4E7F-EA11-8119-005056B05A0F', 'Submission by Email', 'Alex Sawatzke'],
//   'Toyota Industries Commercial Finance': ['10802EC9-1C80-EA11-8119-005056B05A0F','Submission by Email', 'Vince Monteleone'],
//   'Hitachi Capital': ['','Submission by Email','Jay Saul'],
//   'Isuzu Finance': ['3D120421-1D80-EA11-8119-005056B05A0F','Submission by Email', 'Bob Mooney'],
//   'CalFund': ['414D9E62-8EE7-E011-9518-005056A20000','Submission by Email', 'N/A'],
//   'Ascentium Capital': ['','Submission by Email', 'N/A'],
//   'AP Finance': ['','AP', 'N/A'],
//   'Ally Bank': ["F42A8825-D6E7-E211-9509-005056A20000", "Tracking Only - Requires RouteOne Entry", "Alex Sawatzke"],
//   'Test Lender #2': ['C62AB074-9E7E-EA11-8119-005056B05A0F', 'Submission by Email', 'Alex Sawatzke'],
// }

export default function LenderSubmission(props) {
  const setOpenEquipmentInputModal = props.setOpenEquipmentInputModal;
  const setInvoiceEquipmentTabPage = props.setInvoiceEquipmentTabPage;
  const creditSubmissions = props.creditSubmissions;
  const userProfile = useSelector(state => state.userProfile);
  const classes = { ...useStyles(), ...useUiStyles() };
  const [lenderId, setLenderId] = useState("");
  const [checkedLenderName, setCheckedLenderName] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [isAddEquipment, setAddEquipment] = useState(false);
  const [isSummaryIncluded, setSummaryIncluded] = useState(false);
  const [transactionSummary, setTransactionSummary] = useState(null);
  const [transactionSummaryList, setTransactionSummaryList] = useState(undefined);
  const [transactionLender, setTransactionLender] = useState(null);
  const [errors, setErrors] = useState({
    model: false,
    make: false,
    year: false,
    truckClass: false,
    wholesaleCost: false,
    totalWholesaleCost: false,
    retailPrice: false,
  });
  const handleClose = () => {
    setOpenAlert(false);
  };

  const toyotaMinimumDataValidation = () => {
    let result = false;

    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      setErrors([]);
      if (checkedLenderName.find(item => item.submissionMethod.toLowerCase().includes("toyota"))) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalized.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalized.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalized.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalized.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalized.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        setErrors(fieldsAnalized);

        if (result) {
          setErrorType("MissingData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const routeOneDataValidation = () => {
    let result = false;
    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      if (checkedLenderName.find(item => item.submissionMethod.toLowerCase().includes("routeone"))) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalized.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalized.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalized.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalized.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalized.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        if (result) {
          setErrorType("No equipment");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const handleSubmit = event => {
    event.preventDefault();
    // setInvoiceEquipmentTabPage(0);
    const valid = toyotaMinimumDataValidation();
    if (!valid) {
      submitCreditApp().then(res => {
        console.log(res);
      });
    }
  };

  const handleSelectLender = lenderProfileId => {
    const selected = checkedLenderName.find(lender => lender.lenderProfileId === lenderProfileId);

    if (selected) {
      setCheckedLenderName(prevData => prevData.filter(lender => lender.lenderProfileId !== lenderProfileId));
    }
  };

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const submitCreditApp = async () => {
    try {
      await props.handleOpenModal();
      await props.handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Submitting your request to lenders...",
      });

      const creditSubmissionsInfo = checkedLenderName.map(lender => {
        return {
          lenderProfileId: lender.lenderProfileId,
          lenderContactId: lender.lenderContactId,
          transactionSummary,
          createDraftOnly: false,
          //accountId: props.account.id,
          accountId: props.cs.dealerId,
          submittedLender: lender.lenderAccountId,
          dynamicsCreditApplicationId: props.cs.dynamicsCreditApplicationId,
          dynamicsVOId: props.cs.dynamicsVOId,
          dynamicsUserId: userProfile.dynamicsContactId, // we should probably pass a dynamicsUserId from original cs here
          amountRequested: props.cs.invoiceTotal,
          masterCreditSubmissionId: props.cs.creditSubId,
          syndicatedBy: userProfile.dynamicsContactId,
          source: "300",
          display: 200,
        };
      });

      await creditSubmissionRequest({
        variables: {
          input: {
            creditSubmissions: creditSubmissionsInfo,
          },
        },
      });
      // Removes all contents of the array so that checked lender names is reset after every lender submission
      while (checkedLenderName.length) {
        checkedLenderName.pop();
      }
      await props.handleSubmissionMessage({
        title: "Success",
        message: "Submitted Successfully",
      });
      await props.refetch();
    } catch (err) {
      console.log(err);

      props.handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleChangeCheckedLenderName = event => {
    setCheckedLenderName(prevData => {
      console.log({ prevData, newData: event.target.value });
      return prevData.concat(event.target.value);
    });
  };

  useEffect(() => {
    setLenderId(checkedLenderName.lenderAccountId);
    routeOneDataValidation();
  }, [checkedLenderName]);

  useEffect(() => {
    if (isAddEquipment) {
      setInvoiceEquipmentTabPage(0);
      const valid = toyotaMinimumDataValidation();
      if (!valid) {
        submitCreditApp().then(res => {
          console.log(res);
        });
      }
      setAddEquipment(false);
    }
  }, [props.equipmentData]);

  useEffect(() => {
    setTransactionSummary(null);
  }, [isSummaryIncluded]);

  useEffect(() => {
    if (creditSubmissions) {
      const transactionInfo = creditSubmissions
        .map(obj => ({ lenderName: obj.lenderName, transactionSummary: obj.transactionSummary }))
        .filter(obj => obj.transactionSummary);
      setTransactionSummaryList(_.uniqWith(transactionInfo, _.isEqual));
    }
  }, [creditSubmissions]);

  return (
    <div className={classes.container}>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {errorType === "MissingData" ? (
          <>
            <DialogTitle id="alert-dialog-title">{"Missing data for Lender"}</DialogTitle>
            <DialogContent>
              You need to provide the following information in order to submit to this lender:
              <div>
                <ul>
                  {errors.make ? <li>Manufacturer</li> : null}
                  {errors.model ? <li>Model</li> : null}
                  {errors.year ? <li>Year</li> : null}
                  {errors.truckClass ? <li>Truck Class</li> : null}
                  {errors.wholesaleCost ? <li>Wholesale Cost</li> : null}
                  {errors.totalWholesaleCost ? <li>Total Wholesale Cost</li> : null}
                  {errors.retailPrice ? <li>Retail Price</li> : null}
                  {errors.attachmentsCost ? <li>Attachment Unit Cost</li> : null}
                  {errors.attachmentsModel ? <li>Attachment Model</li> : null}
                  {errors.attachmentsMake ? <li>Attachment Manufacturer</li> : null}
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{"No equipment data found"}</DialogTitle>
            <DialogContent>
              Before submitting to RouteOne you may add equipment details if you have them available
            </DialogContent>
            <DialogActions style={{ padding: "24px", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  handleClose();
                  setAddEquipment(true);
                  setInvoiceEquipmentTabPage(1);
                  setOpenEquipmentInputModal(true);
                }}
                color="primary"
                autoFocus>
                Add equipment details
              </Button>
              <Button
                onClick={e => {
                  handleSubmit(e);
                  handleClose();
                  setErrorType("");
                }}
                color="primary"
                autoFocus>
                Submit without equipment details
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Grid container direction="column">
        <Grid item xs={4}>
          <h4 className={classes.createSubHeader}>Create submission</h4>
        </Grid>
        {props.lp ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={[classes.selectFormControl, classes.formControlLenders].join(" ")} fullWidth>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!checkedLenderName.length && (
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Select Lenders
                  </InputLabel>
                )}
                <Select
                  multiline
                  classes={{ select: classes.select }}
                  className={classes.selectLender}
                  value={checkedLenderName[0] || ""}
                  renderValue={() =>
                    checkedLenderName.map(lender => {
                      return (
                        <Grid
                          key={`select_${lender.lenderProfileId}`}
                          container
                          direction="column"
                          className={classes.selectItem}>
                          <Grid item>
                            <span className={classes.selectItemText}>{lender.lenderName}</span>
                          </Grid>
                          <Grid item>
                            <span className={classes.selectItemSubText}>{lender.submissionMethod}</span>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                  onChange={handleChangeCheckedLenderName}
                  inputProps={{ name: "lenderSelect", id: "lender-select" }}
                  style={{ marginBottom: 20 }}>
                  {!!props &&
                    props.lp.length &&
                    props.lp.map(item => {
                      return (
                        <MenuItem
                          key={`item_${item.lenderProfileId}`}
                          value={item}
                          classes={{ root: classes.selectMenuItem }}
                          onClick={() => handleSelectLender(item.lenderProfileId)}>
                          <Grid container alignItems="center">
                            <Checkbox
                              checked={checkedLenderName.find(
                                lender => lender.lenderProfileId === item.lenderProfileId
                              )}
                              tabIndex={-1}
                              onChange={() => handleSelectLender(item.lenderProfileId)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item className={classes.optionText}>
                                  {item.lenderName}
                                </Grid>
                                <Grid item className={classes.optionSubText}>
                                  {item.submissionMethod}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </FormControl>
            <Grid container justifyContent="space-between" alignItems="center" style={{ minHeight: "50px" }}>
              <Grid item xs={8}>
                <span className={classes.transactionHeader}>Include a Transaction Summary?</span>
                <CustomSwitch onText="yes" offText="no" onChange={setSummaryIncluded} />
              </Grid>
              {isSummaryIncluded && transactionSummaryList.length > 0 && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="summary-select" style={{ textTransform: "uppercase" }}>
                      ADD Existing Summary?
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="summary-select"
                      onChange={e => {
                        setTransactionLender(e.target.value);
                        setTransactionSummary(e.target.value.transactionSummary);
                      }}
                      renderValue={e => (
                        <Grid
                          key={transactionLender.lenderName}
                          container
                          justifyContent="space-between"
                          direction="column"
                          className={classes.selectItem}>
                          <span className={classes.selectItemText}>{transactionLender.lenderName}</span>
                          <span className={classes.selectItemSubText}>{transactionLender.transactionSummary}</span>
                        </Grid>
                      )}>
                      {transactionSummaryList.map(item => (
                        <MenuItem
                          key={item.lenderName + item.transactionSummary}
                          value={item}
                          classes={{ root: classes.selectMenuItem }}>
                          <Grid container alignItems="center">
                            <Checkbox
                              checked={
                                transactionLender &&
                                transactionLender.transactionSummary === item.transactionSummary &&
                                transactionLender.lenderName === item.lenderName
                              }
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item className={classes.optionText}>
                                  {item.lenderName}
                                </Grid>
                                <Grid item className={classes.optionSubText}>
                                  {item.transactionSummary}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" className={classes.transactionContainer}>
              {isSummaryIncluded && (
                <TextField
                  className={classes.transactionInput}
                  value={transactionSummary}
                  label="TRANSACTION SUMMARY (TO BE SHARED WITH YOUR LENDER(S) UPON SUBMISSION)"
                  multiline
                  placeholder="Add summary here"
                  type="text"
                  variant="outlined"
                  fullWidth
                  rows={2}
                  maxRows={4}
                  onChange={e => setTransactionSummary(e.currentTarget.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            </Grid>
            {/* {enabledForDraftApplications ? (
            <div className={classes.draftCheckbox}>
              Draft Apps Only
              <Checkbox
                size="small"
                color="default"
                checked={createDraftOnly}
                inputProps={{
                  "aria-label": "create draft",
                }}
                onChange={handleDisableDraftApplications}
              />
            </div>
          ) : null} */}
            <Button className={classes.submitButton} onClick={handleSubmit} data-test-id="submit-to-lender-btn">
              Submit
            </Button>
          </Grid>
        ) : (
          "No lenders available. Please contact our team."
        )}
      </Grid>
    </div>
  );
}
