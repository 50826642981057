import { useMemo } from "react";
import { useSelector } from "react-redux";
import { adminRoles } from "pages/Prequal/constants";
import { PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { PortalConfigurationRolesSettingsKeys } from "types";
import { usePortalConfigurationChecks } from "modules/portalConfigurations";
import { menuConstructorSelectors } from "../model";

export function useAftermarketPermissions() {
  const userProfile = useSelector((state: any) => state.userProfile);
  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu);

  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const isSuperAdmin = [adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole);

  const { hasWriteAccess, hasReadOnlyAccess } = useMemo(() => {
    // hotfix for now - make current( and all) shared menus read only. No need useMemo once this logic will be unhardcoded
    if (!!proposalMenu?.linkedFromCriteria) {
      return {
        hasWriteAccess: false,
        hasReadOnlyAccess: true,
      };
    }
    return {
      hasWriteAccess:
        checkPortalConfigEntryForCurrentUserRole(
          PortalConfigurationType.Aftermarket,
          PortalConfigurationRolesSettingsKeys.AssignedOnly
        ) || isSuperAdmin,
      hasReadOnlyAccess: checkPortalConfigEntryForCurrentUserRole(
        PortalConfigurationType.Aftermarket,
        PortalConfigurationRolesSettingsKeys.ReadOnly
      ),
    };
  }, [proposalMenu, isSuperAdmin]);

  return {
    hasWriteAccess,
    hasReadOnlyAccess,
  };
}
