import React, { useEffect, useMemo, useState } from "react";
import { grey } from "@mui/material/colors";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForceUpdate } from "hooks/useForceUpdate";
import { FormProvider, useForm } from "react-hook-form";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { Form } from "./Form";
import { Stepper } from "./Stepper";
import { StepperActions } from "./StepperActions";
import { FinanceCriteriaOptions, FinanceProgramFormMode, FinanceProgramFormSteps } from "../../types";
import { FinanceProgramForm, financeProgramSchema, initialFormValue } from "../../schema";
import { SelectOption } from "../../../../global";

interface Props {
  isReadOnly?: boolean;
  targetLenders?: string[];
  onCancel?: VoidFunction;
  onFormChanged: VoidFunction;
  isMultipleLenders?: boolean;
  userType?: "lender" | "dealer";
  lendersOptions: LenderProfiles[];
  dealerVendorProfileOptions?: any[];
  program?: FinanceProgramForm["program"];
  criteriaOptions: FinanceCriteriaOptions[];
  onSubmit: (formValue: FinanceProgramForm) => Promise<void>;
  onStepChanged?: (currentStep: FinanceProgramFormSteps) => void;
}

export const FinanceProgram = ({
  program,
  onSubmit,
  onCancel,
  onStepChanged,
  targetLenders,
  onFormChanged,
  isReadOnly = false,
  ...options
}: Props) => {
  const classes = useStyles();

  const [isSubmitActionInProgress, setIsSubmitActionInProgress] = useState<boolean>(false);

  const targetLendersOptions = useMemo(() => {
    if (!targetLenders) return [];

    return targetLenders.reduce<SelectOption[]>((acc, lenderId) => {
      const foundLender = options.lendersOptions.find(({ id }) => id === lenderId);

      if (foundLender) {
        acc.push({ value: lenderId, label: foundLender?.lenderName ?? "" });
      }

      return acc;
    }, []);
  }, [targetLenders, options.lendersOptions]);

  const form = useForm<FinanceProgramForm>({
    mode: "all",
    shouldFocusError: true,
    resolver: zodResolver(financeProgramSchema),
    defaultValues: {
      step: initialFormValue.step,
      program: program ?? {
        ...initialFormValue.program,
        first: { ...initialFormValue.program.first, lenders: targetLendersOptions },
      },
    },
  });

  const mode = useMemo(() => (program ? FinanceProgramFormMode.Update : FinanceProgramFormMode.Create), [program]);

  useForceUpdate([form.formState.errors]);

  const handleSubmit = (formValue: FinanceProgramForm) => {
    setIsSubmitActionInProgress(true);

    onSubmit(formValue).finally(() => {
      setIsSubmitActionInProgress(false);
    });
  };

  useEffect(() => {
    const subscription = form.watch((value, info) => {
      onStepChanged?.(value?.step ?? FinanceProgramFormSteps.First);
      onFormChanged();
    });

    return subscription.unsubscribe;
  }, [form]);

  return (
    <form className={classes.container} onSubmit={form.handleSubmit(handleSubmit)} noValidate>
      <FormProvider {...form}>
        <Stepper isReadOnly={isReadOnly} />

        <Form isReadOnly={isReadOnly} {...options} />

        <StepperActions
          mode={mode}
          onCancel={onCancel}
          isReadOnly={isReadOnly}
          isSubmitActionInProgress={isSubmitActionInProgress}
        />
      </FormProvider>
    </form>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  container: {
    gap: "24px",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "& .formSection": {
      gap: "16px",
      height: "100%",
      display: "flex",
      overflowX: "hidden",
      paddingRight: "4px",
      flexDirection: "column",

      "& .formRow": {
        gap: "16px",
        display: "flex",
      },

      "& .arrayField": {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
      },

      "& .arrayFieldHeader": {
        gap: "8px",
        padding: "8px",
        display: "flex",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: grey["A100"],

        "& .customIconBtn": {
          "&.MuiButtonBase-root": {
            borderRadius: "4px",
            backgroundColor: palette.common.white,
          },
        },
      },

      "& .conditionsFields": {
        flex: 2,
        gap: "8px",
        display: "flex",
      },
    },

    "& .formSectionWithCustomLabel": {
      gap: "9px",
      display: "flex",
      flexDirection: "column",
    },
  },
}));
