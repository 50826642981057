import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export function AutocompleteField({ fieldConfig }) {
  return (
    <Autocomplete
      id={fieldConfig.fieldName}
      options={fieldConfig.fieldOptions}
      style={{ pointerEvents: "none" }}
      renderInput={params => (
        <TextField {...params} label={fieldConfig.displayLabel.en} required={fieldConfig.required} />
      )}
    />
  );
}
