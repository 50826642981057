import React from "react";
import usStates from "jsonData/usStates.json";
import { Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { formatFloatInput, formatPhoneInput, formatPostalCodeInput, formatSSNInput } from "formatters";
import { FormInputSelect, FormInput } from "components/form";
import { ContactFormValues } from "../../../types";

interface Props {
  contactFormValues: ContactFormValues;
  setContactFormValues: any;
}

export const AdditionalInformationForm = ({ contactFormValues, setContactFormValues }: Props) => {
  const classes = useStyles();

  return (
    <Grid item container spacing={2} className={classes.additionalInformationContainer}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.additionalInformationHeader}>
          Additional Information
        </Typography>
        <Typography variant="subtitle2" className={classes.additionalInformationText}>
          If you've received this information already, add below. Otherwise will be request via new Task to Applicant.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            sx={{ width: "100% " }}
            label="Date of Birth"
            value={contactFormValues.dateOfBirth ? moment(contactFormValues.dateOfBirth) : null}
            onChange={date =>
              setContactFormValues({ ...contactFormValues, dateOfBirth: date ? date.toISOString() : null })
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          label="Social Security Number"
          size="medium"
          variant="standard"
          value={contactFormValues.ssn}
          formatter={formatSSNInput}
          onChange={value => setContactFormValues({ ...contactFormValues, ssn: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Job Title"
          fullWidth
          value={contactFormValues.title}
          onChange={e => setContactFormValues({ ...contactFormValues, title: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          label="Ownership %"
          size="medium"
          variant="standard"
          className={classes.requiredInput}
          value={contactFormValues.percOwner}
          formatter={(value: string) => formatFloatInput(value, 2, 100)}
          onChange={value => setContactFormValues({ ...contactFormValues, percOwner: value })}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          label="Work Phone"
          size="medium"
          variant="standard"
          className={classes.requiredInput}
          value={contactFormValues.workPhone}
          formatter={formatPhoneInput}
          onChange={value => setContactFormValues({ ...contactFormValues, workPhone: value })}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          label="Mobile Phone"
          size="medium"
          variant="standard"
          className={classes.requiredInput}
          value={contactFormValues.mobilePhone}
          formatter={formatPhoneInput}
          onChange={value => setContactFormValues({ ...contactFormValues, mobilePhone: value })}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          label="Home Phone"
          size="medium"
          variant="standard"
          className={classes.requiredInput}
          value={contactFormValues.homePhone}
          formatter={formatPhoneInput}
          onChange={value => setContactFormValues({ ...contactFormValues, homePhone: value })}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="Home Address"
          fullWidth
          value={contactFormValues.address}
          onChange={e => setContactFormValues({ ...contactFormValues, address: e.target.value })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="City"
          fullWidth
          value={contactFormValues.city}
          onChange={e => setContactFormValues({ ...contactFormValues, city: e.target.value })}
        />
      </Grid>
      <Grid item xs={3}>
        <FormInputSelect
          label="State"
          size="medium"
          variant="standard"
          options={usStates}
          value={contactFormValues.state}
          onChange={value => setContactFormValues({ ...contactFormValues, state: value })}
        />
      </Grid>
      <Grid item xs={3}>
        <FormInput
          label="Postal Code"
          size="medium"
          variant="standard"
          value={contactFormValues.postalCode}
          formatter={formatPostalCodeInput}
          onChange={value => setContactFormValues({ ...contactFormValues, postalCode: value })}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  additionalInformationHeader: {
    textTransform: "none",
    fontSize: 15,
  },
  additionalInformationText: {
    textTransform: "none",
    fontSize: 12,
    color: "#0000008a",
  },
  requiredInput: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
  additionalInformationContainer: {
    //marginTop: 15,
  },
});
