import React, { SyntheticEvent } from "react";
import { Typography } from "@mui/material";
import { TableCell } from "./TableCell";

interface Props {
  value: string;
  formatter?: (value: string) => string;
  onCellClick?: (event: SyntheticEvent) => void;
}

export const DefaultTableCell = ({ value, onCellClick = () => {}, formatter }: Props) => {
  return (
    <TableCell onCellClick={onCellClick}>
      <>
        {value ? (
          <Typography variant="body2">{formatter ? formatter(value) : value}</Typography>
        ) : (
          <Typography content="span" variant="body2" color="textPrimary">
            —
          </Typography>
        )}
      </>
    </TableCell>
  );
};
