import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";

export function PhoneField({ fieldConfig }) {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      disabled={fieldConfig.disabled}
      name={fieldConfig.fieldName}
      fullWidth={fieldConfig.fullWidth}
      variant={fieldConfig.variant}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      error={false}
      style={{ pointerEvents: "none" }}
      required={fieldConfig.required}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      value={value}
    />
  );
}
