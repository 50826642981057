import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { useSaleMode } from "../context";
import { FinanceProgramFormValues } from "../types";
import { deskingActions, deskingSelectors } from "../model";
import { CALCULATE_CASH_PAYMENTS, CALCULATE_FINANCE_PAYMENTS } from "../api";
import { collectCashVariablesForCalc, collectFinanceVariablesForCalc } from "../lib";
import { CalculatePaymentCashVariables, CalculatePaymentFinanceVariables, CalculatingResponse } from "../api/types";

export function useCalculations() {
  const dispatch = useDispatch();

  const { isCash, isFinancing } = useSaleMode();

  const { control } = useFormContext<FinanceProgramFormValues>();
  const [financeQuote, saleDetails, financeProgramDescription, submission] = useWatch({
    control,
    name: ["financeQuote", "saleDetails", "financeProgramDescription", "submission"],
  });

  const recalculateStep = useSelector(deskingSelectors.recalculateStep);
  const proposalMenu = useSelector(deskingSelectors.proposalMenuByCurrentTerm);
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);

  const {
    loading: isCalculating,
    refetch: recalculateMultiplePayments,
    called,
  } = useQuery<CalculatingResponse, CalculatePaymentFinanceVariables>(CALCULATE_FINANCE_PAYMENTS, {
    skip: isCash || !financeQuote.createProposalWithExistingProgram,
    onCompleted(response) {
      if (!Array.isArray(response?.calculateMultiplePayments)) return;

      const isMenuExist = !!response?.calculateMultiplePayments
        .map(({ paymentOptions }) => !!paymentOptions?.[0]?.menu)
        ?.filter(Boolean)?.length;

      const results = response.calculateMultiplePayments[0]?.paymentOptions;

      if (isMenuExist) dispatch(deskingActions.setMenuPricing({ results }));
      else dispatch(deskingActions.setFinancePricing({ results }));
    },
    notifyOnNetworkStatusChange: true,
    variables: collectFinanceVariablesForCalc({
      program: financeQuote,
      submission,
      saleDetails,
      proposalMenu,
      financeProgramDescription,
      proposalProductConfigurationsInMenu,
    }),
  });

  useQuery<CalculatingResponse, CalculatePaymentCashVariables>(CALCULATE_CASH_PAYMENTS, {
    skip: isFinancing || !proposalMenu,
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (!Array.isArray(response?.calculateMultiplePayments)) return;
      const results = response.calculateMultiplePayments[0]?.paymentOptions;
      dispatch(deskingActions.setMenuPricing({ results }));
    },
    variables: collectCashVariablesForCalc({
      program: financeQuote,
      submission,
      saleDetails,
      proposalMenu,
      financeProgramDescription,
      proposalProductConfigurationsInMenu,
    }),
  });

  useEffect(() => {
    if (isFinancing && called) {
      recalculateMultiplePayments();
    }
  }, [recalculateStep, isFinancing, called]);

  return {
    isCalculating,
  };
}
