import gql from "graphql-tag";

export const GET_VENDOR_OPPORTUNITY_TASKS = gql`
  query VendorOpportunityTask($VOId: ID!, $limit: Int) {
    vendorOpportunityTask(VOId: $VOId, limit: $limit) {
      status
      taskId
      priority
      regarding
      assignedToUser
      createdDateTime
      completedDateTime
    }
  }
`;
