import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableSearch from "../../../../components/Table/TableSearch";
import TableSelect from "../../../../components/Table/TableSelect";
import { Query } from "@apollo/client/react/components";
import analytics from "analytics";
import { formatCurrency } from "utils";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Tile from "components/Tile/Tile";
import moment from "moment";
import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import DragIndicator from "assets/img/drag-indicator.svg";
import { ViewList as ViewListIcon } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CS_LIST, OPTIONS, STATUS_TYPES, UPDATE_CS, getStatus } from "../../services/CreditSubmissions";
import { theme } from "../../../../theme";
import { FormInputSelect } from "../../../../components/form";

const _ = require("lodash");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: "pointer",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
    boxSizing: "border-box",
  },
  optionsContainer: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  searchForm: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "15px",
    height: "30px",
    width: "250px",
  },
  displayStatusMenu: {
    padding: 0,
  },
}));

function CreditSubmissionsList(props) {
  useEffect(() => {
    analytics.page("Credit Submissions Table");
  }, []);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const decisionRef = useRef({ prevdecision: undefined, currentSelect: "" });
  const stageRef = useRef({ prevStage: undefined, currentSelect: "" });
  const statusRef = useRef({ prevDisplay: undefined, currentSelect: STATUS_TYPES.ACTIVE_100 });
  const prevData = useRef([]);
  const updatedVORef = useRef(null);

  const [savedListSettings, setSavedListSettings] = useState(
    JSON.parse(window.localStorage.getItem("transactionListSettings")) === null
      ? {
          filter: [],
          sorting: [],
          page: 0,
          pageSize: 10,
        }
      : JSON.parse(window.localStorage.getItem("transactionListSettings"))
  );

  const handleSaveSettings = (aspect, e) => {
    let saved = {};
    saved[aspect] = e;
    setSavedListSettings({ ...savedListSettings, ...saved });
  };

  function handleCSRowClick(csId) {
    props.history.push(`/cs/${csId}`);
  }
  const throttleSetSearchRef = _.throttle(text => {
    searchRef.current = { ...searchRef.current, currentSearch: text };
  }, 500);

  const handleSearchChange = text => {
    setSearch(text);
    throttleSetSearchRef(text);
    setSavedListSettings({ ...savedListSettings, page: 0 });
    setTimeout(() => {
      const pageNumberInput = document.querySelector('[aria-label="jump to page"]');
      if (pageNumberInput) {
        pageNumberInput.value = 1;
      }
    });
  };

  const { refetch } = useQuery(GET_CS_LIST, {
    fetchPolicy: "no-cache",
  });

  const [updateCS] = useMutation(UPDATE_CS, {
    context: { authRequired: true },
  });

  const onUpdateCS = async payload => {
    try {
      await updateCS({
        variables: payload,
      });
    } catch (error) {
      window.NREUM.noticeError(error);
    }
  };

  function getSearchedData(data) {
    const { prevSearch, currentSearch } = searchRef.current;

    if (prevSearch === currentSearch) {
      return prevData.current;
    }

    if (currentSearch.length === 0) {
      return data;
    }

    const lowerCaseSearch = currentSearch.toLowerCase();
    const searchData = data.creditSubmissions.filter(el => {
      if (
        typeof el.applicantName === "string" ||
        typeof el.contactName === "string" ||
        typeof el.dealerName === "string" ||
        typeof el.amountRequested === "number" ||
        typeof el.approvedAmount === "number"
      ) {
        return (
          (el.applicantName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.contactName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.dealerName || "").toLowerCase().includes(lowerCaseSearch) ||
          String(el.amountRequested || "").includes(lowerCaseSearch) ||
          formatCurrency((el.amountRequested || 0).toFixed(2))
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(el.approvedAmount || "").includes(lowerCaseSearch) ||
          formatCurrency((el.approvedAmount || 0).toFixed(2))
            .toLowerCase()
            .includes(lowerCaseSearch)
        );
      }

      return false;
    });
    prevData.current = searchData;
    return { ...data, creditSubmissions: searchData };
  }

  const renderMenuOptions = original => {
    if (original.archiveStatusId !== STATUS_TYPES.ACTIVE_100) {
      return OPTIONS.status
        .filter(option => !Array.isArray(option.value))
        .map(option => ({
          label:
            option.value === STATUS_TYPES.ACTIVE_100
              ? getStatus(STATUS_TYPES.UNARCHIVED).label
              : getStatus(option.value).label,
          value: option.value,
        }));
    } else {
      return OPTIONS.status
        .filter(option => !Array.isArray(option.value))
        .map(option => ({ label: option.title, value: option.value }));
    }
  };

  const handleOptionClick = async (value, creditSubId) => {
    try {
      await onUpdateCS({
        input: {
          dynamicsCreditSubmissionId: creditSubId,
          archiveStatusId: value,
        },
      });

      updatedVORef.current = creditSubId;
      updatedVORef.current.archiveStatusId = value;
    } catch (error) {
      window.NREUM.noticeError(error);
    }
  };

  const [columnsFilters, setColumnsFilters] = useState([
    {
      header: "Status",
      value: [""],
    },
    {
      header: "Display",
      value: [""],
    },
  ]);

  const filterBySelectFields = originalData => {
    const { currentSelect: currentDecisionSelect } = decisionRef.current;
    const { currentSelect: currentStageSelect } = stageRef.current;
    const { currentSelect: currentStatusSelect } = statusRef.current;
    let newData = { ...originalData };

    if (currentDecisionSelect?.length !== 0) {
      newData.creditSubmissions = newData.creditSubmissions.filter(el => el.decision === currentDecisionSelect);
    }

    if (currentStageSelect?.length !== 0) {
      newData.creditSubmissions = newData.creditSubmissions.filter(el => el.stage === currentStageSelect);
    }

    if (currentStatusSelect?.length !== 0) {
      if (Array.isArray(currentStatusSelect)) {
        newData.creditSubmissions = newData.creditSubmissions.filter(el =>
          currentStatusSelect.includes(el.archiveStatusId)
        );
      } else {
        newData.creditSubmissions = newData.creditSubmissions.filter(el => el.archiveStatusId === currentStatusSelect);
      }
    }

    return newData;
  };

  function createFiltersColumns(sel, header) {
    const filter = ({ filter, onChange }) => {
      return sel.length > 0 ? (
        <Select
          multiple
          className={classes.filterSelect}
          renderValue={() => {
            return columnsFilters.find(col => col.header === header).value[0] !== ""
              ? columnsFilters
                  .find(col => col.header === header)
                  .value.join(", ")
                  .replace(
                    STATUS_TYPES.ARCHIVED_DUPLICATE_201,
                    getStatus(STATUS_TYPES.ARCHIVED_DUPLICATE_201).menuLabel
                  )
                  .replace(STATUS_TYPES.ACTIVE_100, getStatus(STATUS_TYPES.ACTIVE_100).menuLabel)
                  .replace(STATUS_TYPES.ARCHIVED_TESTING_200, getStatus(STATUS_TYPES.ARCHIVED_TESTING_200).menuLabel)
                  .replace(STATUS_TYPES.ARCHIVED_OTHER_202, getStatus(STATUS_TYPES.ARCHIVED_OTHER_202).menuLabel)
              : "Show All";
          }}
          onChange={e => {
            let value =
              e.target.value.length > 1 && e.target.value[0] === ""
                ? e.target.value.filter(val => val !== "")
                : e.target.value;
            if (value.includes("")) value = [""];
            onChange(value);
            setColumnsFilters(prevArray =>
              prevArray.map(col =>
                col.header === header
                  ? {
                      ...col,
                      value: value,
                    }
                  : col
              )
            );
          }}
          value={_.get(
            columnsFilters.find(col => col.header === header),
            "value",
            []
          )}
          style={{ width: "100%" }}
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}>
          <MenuItem value="">
            <Checkbox
              checked={
                _.get(
                  columnsFilters.find(col => col.header === header),
                  "value",
                  null
                ) &&
                _.get(
                  columnsFilters.find(col => col.header === header),
                  "value"
                ).includes("")
              }
              style={{ color: theme.palette.primary.main }}
            />
            <ListItemText primary="Show All" />
          </MenuItem>
          {sel.map((item, index) => {
            return (
              <MenuItem key={"stage-" + index} value={item.value} name={item.title}>
                <Checkbox
                  checked={
                    _.get(
                      columnsFilters.find(col => col.header === header),
                      "value",
                      null
                    ) &&
                    _.get(
                      columnsFilters.find(col => col.header === header),
                      "value"
                    ).includes(item.value)
                  }
                  style={{ color: theme.palette.primary.main }}
                />
                <ListItemText primary={item.title} />
              </MenuItem>
            );
          })}
        </Select>
      ) : null;
    };
    return filter;
  }

  return (
    <Query context={{ authRequired: true }} query={GET_CS_LIST} fetchPolicy="no-cache">
      {({ loading, error, data, refetch }) => {
        if (loading) return "Loading...";
        if (error) return <h3>{String(error)}</h3>;
        let list = [];
        function processCSListData(csListData) {
          try {
            if (csListData && _.isArray(csListData.creditSubmissions)) {
              list = csListData.creditSubmissions.filter(
                cs => cs.lenderDisplayNotify !== "FT_CREATED_HIDE_IN_LP_UNTIL_PROPOSAL_SENT"
              );
              csListData.creditSubmissions.map(cs => {
                if (!updatedVORef.current) {
                  return cs;
                }
                if (updatedVORef.current.csId === cs.id) {
                  const updated = updatedVORef.current;
                  updatedVORef.current = null;
                  return updated;
                }
                return cs;
              });
            }
          } catch (error) {
            window.NREUM.noticeError(error);
          }
        }

        processCSListData(getSearchedData(filterBySelectFields(data)));

        return (
          <div className={classes.root}>
            <GridContainer>
              <GridItem xs={12}>
                <Tile titleIcon={<ViewListIcon />}>
                  <TableHeader
                    filterComps={
                      <>
                        <TableSearch
                          setPageNumber={changePage => {
                            setSavedListSettings({ ...savedListSettings, page: changePage });
                          }}
                          setSavedListSettings={setSavedListSettings}
                          searchRef={searchRef}
                          savedListSettings={savedListSettings}
                        />
                        <TableSelect
                          setPageNumber={changePage => {
                            setSavedListSettings({ ...savedListSettings, page: changePage });
                          }}
                          setSavedListSettings={setSavedListSettings}
                          selectRef={decisionRef}
                          savedListSettings={savedListSettings}
                          id="decision"
                          label="Decision"
                          options={OPTIONS.decision.map(item => ({ value: item, text: item }))}
                          width={200}
                        />
                        <TableSelect
                          setPageNumber={changePage => {
                            setSavedListSettings({ ...savedListSettings, page: changePage });
                          }}
                          setSavedListSettings={setSavedListSettings}
                          selectRef={stageRef}
                          savedListSettings={savedListSettings}
                          id="stage"
                          label="Stage"
                          options={OPTIONS.stage.map(item => ({ value: item, text: item }))}
                          width={200}
                        />
                        <TableSelect
                          setPageNumber={changePage => {
                            setSavedListSettings({ ...savedListSettings, page: changePage });
                          }}
                          setSavedListSettings={setSavedListSettings}
                          selectRef={statusRef}
                          savedListSettings={savedListSettings}
                          id="status"
                          label="Display"
                          options={OPTIONS.status.map(item => ({ value: item.value, text: item.title }))}
                          width={200}
                        />
                      </>
                    }
                  />
                  <Table
                    data={list}
                    page={savedListSettings.page}
                    onFilteredChange={e => {
                      handleSaveSettings("filter", e);
                    }}
                    onSortedChange={e => {
                      handleSaveSettings("sorting", e);
                    }}
                    getTdProps={(state, rowInfo, column) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        e.preventDefault();

                        if (column?.id !== "display") {
                          handleCSRowClick(rowInfo.original.creditSubId);
                        }
                      },
                    })}
                    columns={[
                      {
                        Header: "Date",
                        accessor: "submissionDate",
                        Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
                        filterable: false,
                        sortMethod: (a, b) => {
                          const dateA = new Date(a).getTime();
                          const dateB = new Date(b).getTime();

                          return dateA - dateB;
                        },
                      },
                      { Header: "Source", accessor: "dealerName" },
                      { Header: "Source Contact", accessor: "contactName" },
                      { Header: "Applicant", accessor: "applicantName" },
                      {
                        Header: "Requested Amount",
                        accessor: "amountRequested",
                        Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
                      },
                      {
                        Header: "Approval Amount",
                        accessor: "approvedAmount",
                        Cell: props => <div>{props.value ? formatCurrency(props.value.toFixed(2)) : ""}</div>,
                      },
                      {
                        Header: "Decision",
                        accessor: "decision",
                      },
                      {
                        Header: "Stage",
                        accessor: "stage",
                      },
                      {
                        Header: "Display",
                        accessor: "archiveStatusId",
                        id: "display",
                        Cell: props => (
                          <FormInputSelect
                            size="small"
                            variant="standard"
                            value={props?.value}
                            onChange={async nextValue => {
                              await handleOptionClick(nextValue, props?.original?.creditSubId);
                              await refetch();
                            }}
                            options={renderMenuOptions(props?.original)}
                          />
                        ),
                      },
                    ]}
                    onPageChange={changePage => {
                      setSavedListSettings({ ...savedListSettings, page: changePage });
                    }}
                    onPageSizeChange={changePageSize => {
                      setSavedListSettings({ ...savedListSettings, pageSize: changePageSize, page: 0 });
                    }}
                    pageSize={savedListSettings.pageSize}
                    defaultPageSize={savedListSettings.pageSize}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    customCellClasses={[classes.right]}
                    customClassesForCells={[5]}
                    className=" -highlight"
                  />
                </Tile>
              </GridItem>
            </GridContainer>
          </div>
        );
      }}
    </Query>
  );
}

export default CreditSubmissionsList;
