import { PartnerLink } from "@trnsact/trnsact-shared-types/src/generated";

export class AftermarketProductManagementHelpers {
  static getPartnerLinkLabel(partnerLink: PartnerLink): string {
    // TODO: I understand this is silly, we should use account name instead.
    //       Account name is currently not available. We should add it to the
    //       return value from the API.
    if (partnerLink.name?.includes("National Truck Protect")) {
      return partnerLink.name.split(" - ")[1];
    }
    if (partnerLink.name?.includes(" - PTG") || partnerLink.name?.includes(" - Premier Truck Group")) {
      return partnerLink.name.split(" - ")[0];
    }
    if (partnerLink.name?.includes(" - ")) {
      return partnerLink.name.split(" - ")[1];
    }
    if (partnerLink.name) {
      return partnerLink.name;
    }
    return "Partner Vendor";
  }
}
