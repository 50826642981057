import React, { ReactNode, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CurrencyInputField, FormRadioGroup, InterestInputField } from "components/form";
import { FinanceProgramFormValues, MarkupMethods, MarkupType, Structures } from "../../../../../../types";
import { markupOptions, markupProposalOptions, styleFormRadioGroupProps } from "../../../../../../constants";

type FieldsByConfigurationType = Record<
  Structures,
  { label: string; radioGroupField: ReactNode; valueField: ReactNode } | null
>;

export const StructureConfiguration = () => {
  const classes = useStyles();

  const { control, setValue, resetField } = useFormContext<FinanceProgramFormValues>();
  const financeQuote = useWatch({ control, name: "financeQuote" });

  const [renderType, setRenderType] = useState<"buttons" | "fields">("buttons");

  useEffect(() => {
    setRenderType(financeQuote.structure !== Structures.None ? "fields" : "buttons");
  }, [financeQuote.structure, financeQuote.createProposalWithExistingProgram]);

  const handleChangeMarkup = () => {
    setRenderType("fields");
    setValue("financeQuote.structure", Structures.Markup);
    setValue("financeQuote.markupMethod", MarkupMethods.MarkupPercentage);
  };

  const handleChangeSubsidy = () => {
    setRenderType("fields");
    setValue("financeQuote.structure", Structures.Subsidy);
  };

  const handleChangeRemove = () => {
    resetField("financeQuote.markupRate");
    resetField("financeQuote.subsidyType");
    resetField("financeQuote.markupMethod");

    setRenderType("buttons");
  };

  const subsidyValueInputsByType: Record<MarkupType, ReactNode> = {
    [MarkupType.Percentage]: (
      <InterestInputField
        control={control}
        label="Customer Rate (%)"
        name="financeQuote.markupRate"
        textFieldProps={{ fullWidth: true }}
      />
    ),
    [MarkupType.Flat]: (
      <CurrencyInputField
        control={control}
        label="Customer Rate ($)"
        name="financeQuote.markupRate"
        textFieldProps={{ fullWidth: true }}
      />
    ),
  };

  const markupFields: Record<MarkupMethods, ReactNode> = {
    [MarkupMethods.MarkupPercentage]: (
      <InterestInputField
        control={control}
        label="Dealer Markup (%)"
        name="financeQuote.markupRate"
        textFieldProps={{ fullWidth: true }}
      />
    ),
    [MarkupMethods.MarkupDollar]: (
      <CurrencyInputField
        control={control}
        label="Dealer Markup ($)"
        name="financeQuote.markupRate"
        textFieldProps={{ fullWidth: true }}
      />
    ),
    [MarkupMethods.CustomerRate]: (
      <InterestInputField
        control={control}
        label="Customer Rate (%)"
        name="financeQuote.markupRate"
        textFieldProps={{ fullWidth: true }}
      />
    ),
  };

  const fieldsByConfigurationType: FieldsByConfigurationType = {
    [Structures.None]: null,
    [Structures.Markup]: {
      label: "Set Markup",
      radioGroupField: (
        <Controller
          control={control}
          name="financeQuote.markupMethod"
          render={({ field }) => (
            <FormRadioGroup row options={markupOptions} {...styleFormRadioGroupProps} {...field} />
          )}
        />
      ),
      valueField: markupFields[financeQuote.markupMethod],
    },
    [Structures.Subsidy]: {
      label: "Subsidy Type",
      radioGroupField: (
        <Box className="row">
          <Controller
            control={control}
            name="financeQuote.subsidyType"
            render={({ field }) => (
              <FormRadioGroup row options={markupProposalOptions} {...styleFormRadioGroupProps} {...field} />
            )}
          />
        </Box>
      ),
      valueField: subsidyValueInputsByType[financeQuote.subsidyType],
    },
  };

  const renderByType: Record<"buttons" | "fields", ReactNode> = {
    buttons: (
      <Box className={classes.btnsContainer}>
        <Button variant="contained" size="small" onClick={handleChangeMarkup}>
          Configure Markup
        </Button>
        <Button variant="contained" size="small" onClick={handleChangeSubsidy}>
          Configure Subsidy
        </Button>
      </Box>
    ),
    fields: (
      <Box className={classes.fieldsContainer}>
        <Box className="radioSection">
          <Box className="radioSectionField">
            <Typography component="span" variant="subtitle2">
              {fieldsByConfigurationType[financeQuote.structure]?.label}
            </Typography>

            {fieldsByConfigurationType[financeQuote.structure]?.radioGroupField}
          </Box>

          <Button variant="text" size="small" style={{ color: red["500"] }} onClick={handleChangeRemove}>
            Remove
          </Button>
        </Box>

        {fieldsByConfigurationType[financeQuote.structure]?.valueField}
      </Box>
    ),
  };

  return <>{renderByType[renderType]}</>;
};

const useStyles = makeStyles({
  btnsContainer: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
  },
  fieldsContainer: {
    padding: "0.75rem",
    borderRadius: "2px",
    backgroundColor: grey["100"],

    "& .radioSection": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& .radioSectionField": {
        gap: "1rem",
        display: "flex",
        alignItems: "center",
      },
    },
  },
});
