import React from "react";
import { InputField } from "components/form";
import { useFormContext, useWatch } from "react-hook-form";
import { FinanceProgramFormValues } from "../../../../../../types";
import { FinanceFieldSelector } from "../../../../../components/finance/FinanceFieldSelector";

export const FinanceExistingProgramFields = () => {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const program = useWatch({ control, name: "financeQuote.program" });

  const isProgramSelected = Boolean(program);

  return (
    <>
      <FinanceFieldSelector />

      {isProgramSelected && (
        <InputField control={control} label="Finance Program Name" name="financeQuote.programName" />
      )}
    </>
  );
};
