import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FormInput, FormInputSelect } from "./form";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { BaseModalProps, ValueOf } from "../global";
import { UploadedFileView } from "./shared/UploadedFileView/UploadedFileView";

interface Form {
  isSubmitted: boolean;
  value: { type: string; description: string };
}

interface Props extends BaseModalProps {
  isUploaded: boolean;
  fileData: File | undefined;
  onUpload: (formData: ValueOf<Form, "value">, file: File) => void;
}

export const InvoiceQuoteDescriptionInputModal = ({ isUploaded, fileData, isOpen, onClose, onUpload }: Props) => {
  const classes = useStyles();

  const [form, setForm] = useState<Form>({
    isSubmitted: false,
    value: { type: "", description: "" },
  });

  const handleChangeFormState = (nextState: boolean) => {
    setForm(prev => ({ ...prev, isSubmitted: nextState }));
  };

  const handleChangeForm = (key: string, value: string) => {
    setForm(prev => ({
      ...prev,
      value: { ...prev.value, [key]: value },
    }));
  };

  const handleClose = () => {
    onClose();
    handleChangeFormState(false);
  };

  const handleOk = () => {
    if (!fileData) return;

    if (Object.values(form.value).every(Boolean)) {
      onClose();
      onUpload(form.value, fileData);

      handleChangeFormState(false);
    } else {
      handleChangeFormState(true);
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
          <Typography component="span" variant="h6">
            Upload Invoice/Quote
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {fileData && <UploadedFileView onDelete={onClose} size={fileData?.size} name={fileData?.name} />}

          <Typography component="span" variant="subtitle2">
            Please Describe The Uploading File
          </Typography>

          <form className={classes.form}>
            <FormInputSelect
              required
              label="Type"
              size="small"
              variant="standard"
              value={form.value.type}
              onChange={newValue => handleChangeForm("type", newValue)}
              error={form.isSubmitted && !form.value.type}
              helperText={form.isSubmitted && !form.value.type ? "Please Select A Type!" : ""}
              options={[
                { label: "Quote", value: "quote" },
                { label: "Invoice", value: "invoice" },
              ]}
            />

            <FormInput
              required
              variant="standard"
              label="Invoice/Quote Number"
              value={form.value.description}
              onChange={newValue => handleChangeForm("description", newValue)}
              error={form.isSubmitted && !form.value.description}
              helperText={
                form.isSubmitted && !form.value.description ? "Please Provide An Invoice Or Quote Number" : ""
              }
            />
          </form>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={isUploaded} onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>

        <Button disabled={!fileData || isUploaded} onClick={handleOk} color="primary" variant="outlined">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  form: {
    gap: "8px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
});
