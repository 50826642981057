import React, { useState } from "react";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Typography } from "@mui/material";
import { AddDealerDialog } from "./AddDealerDialog";
import { ContentViewType } from "../../../../../global";
import clsx from "clsx";
import { isDesktop, isMobile } from "../../../../../utils/contentViewType";

interface Props {
  setVODealer: any;
  isUpdatingVO: boolean;
  vpAssignedByLender: any;
  setVpAssignedByLender: any;
  isVpAssignedToVoByLender: any;
  contentViewType: ContentViewType;
  vendorProfilesAssociatedToLender: any;
}

export const DealerTab = ({
  setVODealer,
  isUpdatingVO,
  contentViewType,
  vpAssignedByLender,
  setVpAssignedByLender,
  isVpAssignedToVoByLender,
  vendorProfilesAssociatedToLender,
}: Props) => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const isVpAssigned = isVpAssignedToVoByLender && vpAssignedByLender;

  return (
    <>
      <Box className={classes.container}>
        <Box
          className={clsx(classes.panel, {
            [classes.panelDesktop]: isDesktop(contentViewType),
            [classes.panelMobile]: isMobile(contentViewType),
          })}>
          <Typography component="span" variant="subtitle2">
            Dealer
          </Typography>

          {!isVpAssigned && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={isUpdatingVO}
              onClick={() => {
                setIsDialogOpen(true);
              }}>
              {isUpdatingVO ? "Adding..." : "Add dealer"}
            </Button>
          )}
        </Box>

        <Typography component="span" variant="body2">
          {isVpAssigned ? `${vpAssignedByLender.name}` : "No Associated Dealer"}
        </Typography>
      </Box>

      <AddDealerDialog
        isOpen={isDialogOpen}
        onConfirm={setVODealer}
        dealerValue={vpAssignedByLender}
        onSelectChanged={setVpAssignedByLender}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        dealerOptions={_.sortBy(vendorProfilesAssociatedToLender, [vp => vp.name.replace("VP - ", "")]).map(vp => ({
          label: vp.name.replace("VP - ", ""),
          value: vp,
        }))}
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  panel: {
    display: "flex",
    justifyContent: "space-between",
  },
  panelDesktop: {
    alignItems: "center",
    flexDirection: "row",
  },
  panelMobile: {
    gap: "4px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
