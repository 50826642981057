import gql from "graphql-tag";

export const CALCULATE_FINANCE_PAYMENTS = gql`
  query CalculateFinancePayments($input: [CalculateMultiplePaymentsInput]) {
    calculateMultiplePayments(input: $input) {
      paymentOptions {
        paymentAmountPerPeriod
        dealerMarkup
        financeProfit
        paymentAmountPerPeriodWithoutDealerMarkup
        amountDueToday
        overages
        rate
        term
        customerRate
        subsidyAmount
        subsidyPercentage
        costOfDeferredPayments
        menu {
          name
          proposalMenuId
          description
          menuOptions {
            name
            description
            type
            menuOptionDetail
            ordinal
            payment {
              paymentAmountPerPeriod
              financeProfit
              dealerMarkup
              paymentAmountPerPeriodWithoutDealerMarkup
              rate
              term
              customerRate
              subsidyAmount
              subsidyPercentage
              aftermarketProfit
              totalProfit
              profitPercentage
              productCost
              amountDueToday
              costOfDeferredPayments
            }
            titleColor
            titleBgColor
            products {
              cost
              title
              addons {
                category
                cost
                markup {
                  markup
                  type
                }
                retailCost
                thirdPartyId
                title
              }
              markup {
                markup
                type
              }
              criteria
              retailCost
              thirdPartyUrl
              proposalProductId
              aftermarketProfit
              aftermarketProduct {
                aftermarketProductId
                aftermarketVendorApiChannel
                criteria
                productCategory
                productDescriptionExternal
                productDescriptionInternal
                productName
                productType
                createdDateTime
                modifiedDateTime
                config {
                  criteriaValues
                  productConfig
                }
                aftermarketProductCatalogDetails {
                  productSummary
                  limitationsOfLiability
                  eligibilityAndInclusions
                  legalDisclaimer
                  paragraphDescription
                  coverageDetails
                }
              }
            }
          }
        }
        term
      }
    }
  }
`;
