import React, { useEffect } from "react";
import { ModalsKeys } from "global";
import { Box } from "@mui/material";
import { logError } from "utils/logger";
import { useModal } from "hooks/useModal";
import { blue } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { invoiceCalculations } from "modules/invoice";
import { FormProvider, useForm } from "react-hook-form";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PanelForm } from "./PanelForm";
import { useCreateProposal } from "../../../hooks";
import { useSaleMode } from "../../../context";
import { PanelFormActions } from "./PanelFormActions";
import { equipmentDependentFields } from "../../../constants";
import { deskingActions, deskingSelectors } from "../../../model";
import { GET_EQUIPMENT, GET_FINANCE_PROGRAMS } from "../../../api";
import { Equipment, FinanceProgramFormValues, PanelProps, SaleType, SubmitOptions } from "../../../types";
import { collectVariablesForCreateProposal, equipmentMileageFormatter, getDefaultValues } from "../../../lib";
import {
  EquipmentsResponse,
  EquipmentsVariables,
  FinanceProgramsResponse,
  FinanceProgramsVariables,
} from "../../../api/types";

export const PanelFormContainer = ({
  vo,
  invoice,
  actions,
  accountId,
  creditApplication,
  updateInvoiceAction,
}: PanelProps) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingConfirmSaveDraftDialog);
  const { onChangeSaleType } = useSaleMode();

  const dispatch = useDispatch();
  const builder = useSelector(deskingSelectors.builder);
  const productsConfigInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);

  useEffect(() => {
    if (creditApplication?.location) {
      dispatch(
        deskingActions.setCreditAppLocationData({
          creditAppLocationData: creditApplication?.location,
        })
      );
    }
  }, [dispatch, creditApplication]);

  const { isProposalLoading, handleCreateProposal } = useCreateProposal();

  const { data: equipmentData, refetch: refetchEquipmentData } = useQuery<
    EquipmentsResponse<Equipment>,
    Partial<EquipmentsVariables>
  >(GET_EQUIPMENT, {
    skip: !vo.vendorOpportunityId,
    notifyOnNetworkStatusChange: true,
    variables: { VOId: vo.vendorOpportunityId },
    onCompleted(response) {
      if (!Array.isArray(response?.equipments)) return;
      dispatch(deskingActions.setEquipmentData({ equipments: response.equipments }));
    },
  });

  const form = useForm<FinanceProgramFormValues>({
    defaultValues: getDefaultValues(vo, creditApplication, equipmentData?.equipments ?? []),
  });

  const [getEquipmentDataById] = useLazyQuery<EquipmentsResponse, EquipmentsVariables>(GET_EQUIPMENT, {
    onCompleted(response) {
      const equipment = response?.equipments?.[0];

      form.setValue("equipment.selectedEquipment", equipment?.equipmentId);
      form.setValue(`equipment.mileage`, equipmentMileageFormatter(equipment?.mileage, "toView"));

      equipmentDependentFields.forEach(field => form.setValue(`equipment.${field}`, equipment?.[field] ?? ""));

      dispatch(deskingActions.setCurrentEquipmentData({ id: equipment?.equipmentId }));
    },
  });

  useQuery<FinanceProgramsResponse, FinanceProgramsVariables>(GET_FINANCE_PROGRAMS, {
    skip: !accountId,
    variables: { accountId },
    onCompleted(response) {
      if (!Array.isArray(response?.financePrograms)) return;
      dispatch(deskingActions.setFinancePrograms({ programs: response.financePrograms }));
    },
  });

  const handleResetDesking = () => {
    form.reset();
    onChangeSaleType(SaleType.Financing);
    dispatch(deskingActions.resetStructureAndMenuOptions());
  };

  const handleSubmitForm = async ({ isDraft }: SubmitOptions) => {
    try {
      const variables = collectVariablesForCreateProposal({
        isDraft,
        menuByTerms: builder,
        productsConfigInMenu,
        formData: form.getValues(),
        voId: vo.vendorOpportunityId,
      });

      if (isDraft) handleOpen({ onSaveAsDraft: () => handleCreateProposal(variables, handleResetDesking) });
      else await handleCreateProposal(variables, handleResetDesking);
    } catch (error) {
      logError(error);
    }
  };

  // TODO: Not the best solution, need to decide on synchronization
  useEffect(() => {
    if (!invoice) return;

    const { invoiceTotal, netFinanceAmount, netTrade, totalFees } = invoiceCalculations.byInvoice(invoice);

    form.setValue("saleDetails.fees", totalFees);
    form.setValue("saleDetails.netRate", netTrade);
    form.setValue("saleDetails.invoiceTotal", invoiceTotal);
    form.setValue("saleDetails.financeAmount", netFinanceAmount);
    form.setValue("saleDetails.downPayment", invoice.downPayment);
    form.setValue("saleDetails.salesTax", invoice.salesTaxOnInvoice);
    form.setValue("saleDetails.equipmentSubtotal", invoice.equipmentCost);
  }, [form, invoice]);

  return (
    <Box className={classes.formContainer}>
      <FormProvider {...form}>
        <PanelForm
          vo={vo}
          accountId={accountId}
          creditApplication={creditApplication}
          updateInvoiceAction={updateInvoiceAction}
          actions={{
            onEquipmentCreated: async equipmentId => {
              await refetchEquipmentData();
              if (actions?.onEquipmentCreated) await actions.onEquipmentCreated(equipmentId);

              await getEquipmentDataById({ variables: { VOId: vo.vendorOpportunityId, id: equipmentId } });
            },
            onEquipmentUpdated: async equipmentId => {
              await refetchEquipmentData();
              if (actions?.onEquipmentUpdated) await actions.onEquipmentUpdated(equipmentId);
            },
            onSyncEquipment: async () => {
              const { data } = await refetchEquipmentData();

              dispatch(deskingActions.setEquipmentData({ equipments: data?.equipments }));

              const id = data?.equipments?.[0]?.equipmentId;

              if (!id) return;

              await getEquipmentDataById({ variables: { VOId: vo.vendorOpportunityId, id } });
            },
          }}
        />
      </FormProvider>

      <PanelFormActions onSubmitForm={handleSubmitForm} isProposalLoading={isProposalLoading} />
    </Box>
  );
};

const useStyles = makeStyles(({ palette: { primary } }) => ({
  formContainer: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",

    "& .productName": {
      textTransform: "capitalize",
    },

    "& .row": {
      gap: "8px",
      display: "flex",

      "& > *": {
        flex: 1,
      },
    },

    "& .section": {
      gap: "6px",
      display: "flex",
      flexDirection: "column",

      "& .actions": {
        gap: "2px",
        display: "flex",
        alignItems: "center",
      },

      "& .sectionHeader": {
        display: "flex",
        minHeight: "56px",
        borderRadius: "2px",
        position: "relative",
        padding: "13px 16px",
        alignItems: "center",
        backgroundColor: blue["50"],
        justifyContent: "space-between",

        "& .collapse": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .actionPanel": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiButton-contained": {
          color: primary.main,
          borderRadius: "var(--borderRadius, 4px)",
          background: "var(--common-white_states-main, #FFF)",
          boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
          "&.Mui-disabled": {
            boxShadow: "none",
            color: primary.light,
          },
        },
      },

      "& .sectionFields": {
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
      },
    },

    "& .sectionHidden": {
      height: 0,
      visibility: "hidden",
    },
  },
}));
