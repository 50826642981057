import React from "react";
import { Box } from "@mui/material";

import Button from "components/CustomButtons/Button.js";

export function AddNewConfigurableItem({ displayLabel, onClickFn }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button onClick={onClickFn}>{displayLabel}</Button>
    </Box>
  );
}
