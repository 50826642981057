import React, { ReactNode, ErrorInfo, Component } from "react";
import { Stack } from "@mui/material";
import errorImage from "assets/img/error-sad.png";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  loaderDots: string;
};

export class ErrorHandler extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static state = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      loaderDots: "",
    };
  }

  static getDerivedStateFromError() {
    setTimeout(() => {
      window.location.href = "/vo";
    }, 55000);

    return { hasError: true };
  }

  componentDidCatch(_: Error, errorInfo: ErrorInfo) {
    console.error(errorInfo);
    (window as any).NREUM.noticeError(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack gap="0.5rem" alignItems="center" justifyContent="center" paddingTop="5rem">
          <img src={errorImage} height={75} width={75} />

          <h3 style={{ fontWeight: "bold" }}>Something went wrong.</h3>

          <div style={{ textAlign: "left" }}>
            <div style={{ fontSize: "16px" }}>
              <p>Oops, sorry about that! We’ve encountered an error while trying to load the page.</p>
              <p>Our engineers have been notified</p>
              <p>In the meantime, we’re redirecting you to the homepage.</p>
              <p style={{ fontWeight: "bolder" }}>Thank you for your patience!</p>
            </div>

            <div style={{ paddingTop: "15px" }}>
              If the issue persists, please send a brief description of how you encountered your issue to:{" "}
              <a href="mailto:support@trnsact.com" style={{ textDecoration: "underline" }}>
                support@trnsact.com
              </a>
            </div>

            <div style={{ fontStyle: "italic" }}>Please include screenshots if you can!</div>
          </div>
        </Stack>
      );
    }

    return this.props.children;
  }
}
