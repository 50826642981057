import React from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SubmitOptions } from "../../../types";
import { DeskingTestIds } from "../../../constants";

interface Props {
  isProposalLoading: boolean;
  onSubmitForm: (options: SubmitOptions) => Promise<void>;
}

export const PanelFormActions = ({ onSubmitForm, isProposalLoading }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.actions} data-testid={DeskingTestIds.PanelFormActions}>
      <Button variant="contained" disabled={isProposalLoading} onClick={() => onSubmitForm({ isDraft: false })}>
        Send
      </Button>

      <Button disabled={isProposalLoading} onClick={() => onSubmitForm({ isDraft: true })}>
        Save as draft
      </Button>
    </Box>
  );
};

const useStyles = makeStyles({
  actions: {
    gap: "8px",
    display: "flex",
  },
});
