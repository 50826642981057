import { Payment } from "@trnsact/trnsact-shared-types";

export function prepareLenderPaymentOptionsForRender(payments: Payment[]) {
  payments.sort((paymentA, paymentB) => paymentA.term! - paymentB.term!);

  return payments.reduce<Payment[]>((acc, paymentOption) => {
    paymentOption?.paymentCriteria?.menu?.menuOptions?.forEach((options: any) => {
      if (paymentOption.paymentCriteriaSelected?.name === options.name) {
        acc.push(paymentOption);
      } else {
        acc.push({
          ...paymentOption,
          paymentCriteriaSelected: null,
          paymentCriteria: {
            ...paymentOption.paymentCriteria,
            menu: {
              ...paymentOption.paymentCriteria.menu,
              menuOption: options,
            },
          },
        });
      }
    });

    return acc;
  }, []);
}
