import React, { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import { DocumentContext } from "../../contexts/DocumentContext";
import { DCR_API_BASE_SERVER_URL } from "config";
import DocumentsCard from "./DocumentsCard";
import MessageModal from "components/MessageModal";
import Tile from "components/Tile/Tile";
import { formatCurrency, getNested } from "utils";
import CreditSubmissionsCard from "./CreditSubmissionsCard";
import ScoreCard from "../../components/ScoreCard";
import _ from "lodash";
import ApplicationSummary from "./ApplicationSummary";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { InvoiceEquipmentDetailsCard } from "./InvoiceEquipmentDetailCard";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { isItemHasRestrictionForRole } from "../../../../services/creditAppModalService";
import HealthCheckCard from "../../../../pages/VendorOpportunity/HealthCheckCard";
import {
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_OPEN,
  CHAT_V2_RESET,
  CHAT_V2_SELECTED_CONVERSATION_SID,
  CHAT_V2_SET_CREDIT_SUBMISSION,
  CHAT_V2_SET_TARGET_CREDIT_SUBMISSION_ID,
  SET_CHAT_TARGET_RECIPIENT,
  SET_VO,
} from "../../redux/types";
import { Q_VO } from "../../../../queries/common";
import SpecificTasks from "./SpecificTasks";
import { ConversationsHistory } from "./ConversationsHistory";
import { io } from "socket.io-client";
import CSProposalList from "./CSProposalList";
import ManageSyndications from "./ManageSyndications";
import mixpanel from "../../mixpanel";
import ActivityList from "./ActivityCard";
import FDRList from "./FDRCard";
import TransactionSummary from "./TransactionSummaryCard";

import { checkIfIndividualCreditApp } from "../../../../../src/utils";
import { portalConfigurationTypes } from "../../../../../src/pages/Prequal/constants";
import { LenderWaterfallResults } from "../../../../../src/pages/VendorOpportunity/LenderWaterfallResults";
import { assignFieldsOCAConfigFromActiveTemplate } from "../../../../../src/services/creditAppModalService";
import { getDefaultPortalConfigurationByType, rolesSettingsKeys } from "../../../../../src/services/pcwService";

const queryContext = { authRequired: true };
const GET_CREDIT_SUBMISSION = gql`
  query ($csId: ID!) {
    creditSubmission(dynamicsId: $csId) {
      creditSubmissionId
      applicant
      amountRequested
      businessName
      creditSubId
      stage
      submissionDate
      decision
      dealer
      contactUserProfileId
      contact
      transactionId
      contactName
      contactPhone
      pgTransactionId
      voId
      dynamicsVOId
      pgTransactionId
      dealerVendorProfile
      contactEmail
      invoiceTotal
      equipmentDescription
      equipmentApprovalStatus
      equipmentCondition
      equipmentCost
      freightLaborInstallation
      downPayment
      salesTaxOnInvoice
      dealerId
      lenderAccountId
      transactionSummary
      transactionSummaryBy
      dynamicsCreditApplicationId
      dynamicsApplicantId
      applicantAddress
      lenderDisplayNotify
      dynamicsLenderProfileId
      proposalV2Options
      lenderProfiles {
        lenderName
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        submissionMethod
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderChannelCode
      }
      masterCreditSubmissionId
      syndicatedBy {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterLenderName
      approvedRate
      notes
      contingencies
      approvedAmount
      lenderName
      fastTrackStatus
      fastTrackResults {
        creditSubmissionId
        fastTrackId
        applicationStatus
        rate
        approvedBy
        outputs {
          key
          value
        }
        inputs {
          key
          value
        }
      }
    }
  }
`;

const Q_CREDIT_APP = gql`
  query ($VOId: ID!) {
    creditApplication(VOId: $VOId) {
      id
      dynamicsId
      amountRequested
      firstName
      lastName
      email
      businessName
      title
      phone
      bizPhone
      businessAddressCounty
      address
      city
      state
      stateOfIncorp
      postalCode
      equipmentDescription
      dba
      applicationType
      applyingFor
      creditLineAmountRequested
      creditLineStatus
      creditLineNotes
      industryType
      taxId
      createdDateTime
      ocaTemplateId
      ocaTemplate {
        vendorProfileId
        jsonDefinition
        name
      }
      location {
        locationId
        locationName
        category
      }
      creditApplicationOwner {
        ownerPgId
        email
        creditApplicationId
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        prevCounty
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        driversLicenseNum
        dlStateIssued
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employerEmail
        employmentStatus
        workPhone
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureTitle
        signatureDate
        dlUploaded
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        countryOfResidence
        ownershipType
        firstTimeFinancing
        nearestRelativeFirstName
        nearestRelativeLastName
        nearestRelativePhone
        nearestRelativeCity
        nearestRelativeZip
        prevAddress
        prevAddress2
        prevCity
        prevState
        prevCounty
        prevPostalCode
        reasonForDeclining
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
        ipAddress
        personalGuarantor {
          personalGuarantorId
          personalGuarantorDynamicsId
          personalGuarantorName
          mostRecentCbrs {
            bureauType
            creditBureauType
            creditReportConsumerId
            auditLog
            humanReadableReportDocumentId
            ownerPgId
            scoreType
            ficoScore
            fields {
              key
              value
            }
            publicRecordCount
            pastAmountDue
            totalInquiryCount
            satisfactoryAccountsCount
            installmentBalance
            monthlyPayment
            inquiryCountLastSixMonths
            derogAccountsCount
            realEstateBalance
            realEstatePayment
            tradelineCount
            pastDerogAccountsCount
            collectionRecordsCount
            revolvingAvailPercent
            paidAccountsCount
            oldestTradeline
            bankruptcyRecordCount
            revolvingBalance
            revolvingTotalCredit
            errorDescription
            createdDateTime
            modifiedDateTime
          }
        }
      }
    }
  }
`;

const QUERY_PORTAL_CONFIGURATION = gql`
  query ($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

const QUERY_ACCOUNT_BY_VP_ID = gql`
  query ocaTemplateAccount($vendorProfileId: ID!) {
    accountByVPId(vendorProfileId: $vendorProfileId) {
      name
      vendorProfileId
      accountType
    }
  }
`;

const Q_EQUIPMENT = gql`
  query Equipments($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      attachments
      wholesaleCost
      totalWholesaleCost
      retailPrice
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
      hours
      type
      tradeInValue
      amountOwedOnTradeIn
      equipmentIdx
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  defaultTextWeight: {
    fontWeight: 300,
  },
  a: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[1],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const invoiceDetailAPIKey = [
  {
    label: "Equipment Cost (Subtotal)",
    apiKey: "equipmentCost",
    format: "currency",
  },
  {
    label: "Other Charges (Installation, shipping, etc)",
    apiKey: "freightLaborInstallation",
    format: "currency",
  },
  {
    label: "Trade-in / Down Payment",
    apiKey: "downPayment",
    format: "currency",
  },
  {
    label: "Sales Tax",
    apiKey: "salesTaxOnInvoice",
    format: "currency",
  },
  { label: "Invoice Total", apiKey: "invoiceTotal", format: "currency" },
];

const C_GET_CONVERSATIONS_BY_VO = gql`
  query getConversationsForVendorOpportunity($vendorOpportunityId: ID!, $creditSubmissionId: ID) {
    getConversationsForVendorOpportunity(
      vendorOpportunityId: $vendorOpportunityId
      creditSubmissionId: $creditSubmissionId
    ) {
      sid
      vendorOpportunityId
      creditSubmissionId
      participants {
        sid
        userProfileId
        notifyByEmail
        notifyBySMS
        notifyByWhatsapp
        identity
        mobilePhoneNumber
        mugshot
        email
        isDealer
        isApplicant
        isLender
        conversationCreator
      }
      messages {
        participantSid
        userProfileId
        body
        author
        delivery {
          total
          read
          sent
          delivered
          failed
          undelivered
        }
      }
      task {
        regarding
      }
      topic
      createdAt
      taskId
    }
  }
`;

const C_ADD_PARTICIPANT = gql`
  mutation AddConversationParticipant($input: ConversationParticipantInput!) {
    addConversationParticipant(input: $input)
  }
`;

const C_CREATE_CONVERSATION = gql`
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      sid
      vendorOpportunityId
      creditSubmissionId
      taskId
      topic
      createdBy
      createdAt
      messages {
        body
      }
      participants {
        sid
        identity
        userProfileId
      }
    }
  }
`;

const CreditSubmissionDetails = withRouter(({ history, ...props }) => {
  const [lastDocument, setLastDocument] = useState();
  const dispatch = useDispatch();

  const relatedUsers = useSelector(state => state.relatedUsers);
  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);
  const chat = useSelector(state => state.chat);
  const refetchCS = useSelector(state => state.cs.refetch);
  const lenderProfile = useSelector(state => state.lenderProfile);
  const vp = useSelector(state => state.vp);

  const classes = useStyles();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [openCreditAppModal, setOpenCreditAppModal] = useState(false);
  const [chatCreating, setChatCreating] = useState(false);
  const [handleSubmitToLenderByProfileId, setHandleSubmitToLenderByProfileId] = useState(false);

  const [appSummary, setAppSummary] = useState();
  const [creating, setCreating] = useState(false);
  const [showScoreCard, setShowScoreCard] = useState(false);

  const [vendorProfile, setVendorProfile] = useState(null);
  const [location, setLocation] = useState(null);
  const [CADMData, setCADMData] = useState(null);
  const [portalConfigurations, setPortalConfigurations] = useState(null);
  const [CADMPortalConfiguration, setCADMPortalConfiguration] = useState(null);
  const [isCreditReportsHiddenForUserRole, setIsCreditReportsHiddenForUserRole] = useState(true);
  const [documents, setDocuments] = useState([]);

  const [showHealthCheck, setShowHealthCheck] = useState(false);
  const [isHealthCheckHiddenForUserRole, setIsHealthCheckHiddenForUserRole] = useState(true);

  const [triggerRefetchDocs, setTriggerRefetchDocs] = useState("");

  const ioRef = useRef(null);

  const vo = useSelector(state => state.vo);
  console.log(vo);
  const reduxConversations = useSelector(state => state.chatV2.conversations);

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleClose = () => {
    setOpenCreditAppModal(false);
  };

  const [getCreditSubmission, { data: creditSubmissionData, refetch, loading: loadingCreditSubmission }] = useLazyQuery(
    GET_CREDIT_SUBMISSION,
    {
      context: { authRequired: true },
      fetchPolicy: "no-cache",
      variables: { csId: props.match.params.id },
    }
  );

  const [
    getConversationsByVOId,
    { data: conversations, refetch: refetchConversations, loading: conversationsLoading, stopPollingConversations },
  ] = useLazyQuery(C_GET_CONVERSATIONS_BY_VO);

  const [addParticipant] = useMutation(C_ADD_PARTICIPANT, {
    context: { authRequired: true },
  });

  const [createConversation] = useMutation(C_CREATE_CONVERSATION, {
    context: { authRequired: true },
  });

  const [getPortalConfigurations, { data: portalConfigurationData, loading: portalConfigurationDataLoading }] =
    useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
      fetchPolicy: "no-cache",
    });

  const [
    getCADMPortalConfigurations,
    { data: CADMPortalConfigurationData, loading: CADMPortalConfigurationDataLoading },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [getOcaTemplateAccount, { data: ocaTemplateAccountData }] = useLazyQuery(QUERY_ACCOUNT_BY_VP_ID, {
    context: { authRequired: true },
  });

  const [getCreditAppData, { data: creditAppData }] = useLazyQuery(Q_CREDIT_APP, { fetchPolicy: "no-cache" });

  const [getEquipmentData, { data: equipmentData }] = useLazyQuery(Q_EQUIPMENT);

  const [getVoData, { data: voData }] = useLazyQuery(Q_VO, { fetchPolicy: "no-cache" });

  const handleOpenCreditReportDocument = (pgDetails, cbr) => {
    const reportDoc = documents.find(d => d.key === cbr.humanReadableReportDocumentId);
    if (reportDoc) {
      window.open(reportDoc.link, "_newtab");
    }
  };

  const handleCreateConversation = useCallback(() => {
    console.log("CONVERSATIONS ADD CONVERSATION CLICKED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: ADD CONVERSATION CLICKED");

    dispatch({
      type: CHAT_V2_RESET,
    });
    dispatch({
      type: CHAT_V2_OPEN,
      payload: true,
    });
    //});
  }, [creditSubmissionData, vo, createConversation, userProfile, dispatch, conversations]);

  useEffect(() => {
    if (vo && vo.vendorOpportunityId) {
      getConversationsByVOId({
        variables: {
          vendorOpportunityId: vo.vendorOpportunityId,
        },
      });
    }

    return () => {
      stopPollingConversations && stopPollingConversations();
    };
  }, [vo, getConversationsByVOId, stopPollingConversations]);

  useEffect(() => {
    if (conversations && conversations.getConversationsForVendorOpportunity) {
      dispatch({
        type: CHAT_V2_CONVERSATIONS,
        payload: conversations.getConversationsForVendorOpportunity,
      });
    }
  }, [conversations, dispatch]);

  useEffect(() => {
    if (!userProfile.id) {
      return;
    }

    ioRef.current = io(DCR_API_BASE_SERVER_URL, {
      path: "/notifications",
      transports: ["websocket"],
      secure: true,
      withCredentials: true,
      auth: {
        userId: userProfile.id,
      },
    });

    try {
      ioRef.current.connect();
      ioRef.current.on("onMessageAdded", data => {
        refetchConversations();
      });
    } catch (error) {}

    ioRef.current.on("onParticipantAdded", data => {
      try {
        if (data) {
          refetchConversations();
        }
      } catch (error) {
        console.log(error);
      }
    });

    ioRef.current.on("onParticipantRemoved", data => {
      refetchConversations();
    });

    return () => {
      ioRef.current && ioRef.current.disconnect();
    };
  }, [userProfile, refetchConversations]);

  useEffect(() => {
    if (creditSubmissionData) {
      refetch();
    }
  }, [refetchCS]);

  useEffect(() => {
    dispatch({
      type: CHAT_V2_RESET,
    });

    dispatch({
      type: CHAT_V2_CONVERSATIONS,
      payload: [],
    });
  }, [dispatch]);

  useEffect(() => {
    if (creditSubmissionData && creditSubmissionData.creditSubmission) {
      dispatch({
        type: CHAT_V2_SET_CREDIT_SUBMISSION,
        payload: creditSubmissionData.creditSubmission,
      });
    }
  }, [dispatch, creditSubmissionData]);

  useEffect(() => {
    if (
      conversationsLoading ||
      !vo ||
      !vo.vendorOpportunityId ||
      !creditSubmissionData ||
      !creditSubmissionData.creditSubmission ||
      !userProfile ||
      !conversations
    ) {
      return;
    }

    if (conversations.getConversationsForVendorOpportunity?.length) {
      return;
    }

    const input = {
      vendorOpportunityId: vo.vendorOpportunityId,
      topic: "RE: " + creditSubmissionData.creditSubmission.applicant,
      initialParticipant: {
        participantUserProfileId: userProfile.id,
        identity: `${userProfile.fullName}`,
        phoneNumber: null,
        email: userProfile.email,
        notifyByEmail: Boolean(userProfile.email),
        notifyBySMS: false,
        notifyByWhatsapp: false,
      },
    };

    createConversation({ variables: { input } });
  }, [conversationsLoading, vo, creditSubmissionData, userProfile, conversations, createConversation]);

  const handleChatWithDealerButtonClick = () => {
    if (!creditSubmissionData || !creditSubmissionData.creditSubmission) {
      return;
    }

    if (conversations && conversations.getConversationsForVendorOpportunity) {
      const userId = userProfile.id;
      const dealerId = creditSubmissionData.creditSubmission.contactUserProfileId;

      const conversation = conversations.getConversationsForVendorOpportunity.find(conv => {
        if (conv.participants.length !== 2) {
          return false;
        }

        const currentUserParticipant = conv.participants.find(c => c.userProfileId === userId);
        const vendorParticipant = conv.participants.find(c => c.userProfileId === dealerId);

        return Boolean(currentUserParticipant) && Boolean(vendorParticipant);
      });

      if (conversation) {
        dispatch({
          type: CHAT_V2_SELECTED_CONVERSATION_SID,
          payload: conversation.sid,
        });
        dispatch({
          type: CHAT_V2_OPEN,
          payload: true,
        });
        return;
      }
    }

    setChatCreating(true);

    const input = {
      vendorOpportunityId: vo.vendorOpportunityId,
      topic: "RE: " + creditSubmissionData.creditSubmission.applicant,
      initialParticipant: {
        participantUserProfileId: userProfile.id,
        identity: userProfile.fullName,
        phoneNumber: null,
        email: userProfile.email,
        notifyByEmail: Boolean(userProfile.email),
        notifyBySMS: false,
        notifyByWhatsapp: false,
      },
    };

    createConversation({
      variables: {
        input,
      },
    }).then(({ data }) => {
      if (data && data.createConversation && data.createConversation.sid && creditSubmissionData.creditSubmission) {
        const input = {
          conversationSid: data.createConversation.sid,
          participantUserProfileId: creditSubmissionData.creditSubmission.contactUserProfileId || null,
          identity: creditSubmissionData.creditSubmission.contact,
          email: creditSubmissionData.creditSubmission.contactEmail,
          notifyByEmail: Boolean(creditSubmissionData.creditSubmission.contactEmail),
          notifyBySMS: false,
        };

        addParticipant({
          variables: {
            input,
          },
        }).then(participant => {
          const updatedConversations =
            conversations && conversations.getConversationsForVendorOpportunity
              ? conversations.getConversationsForVendorOpportunity
              : [];

          const createdConversation = data.createConversation;
          createdConversation.participants.push({
            sid: participant.addConversationParticipant,
            identity: creditSubmissionData.creditSubmission.contact,
            userProfileId: creditSubmissionData.creditSubmission.contactUserProfileId || null,
          });
          updatedConversations.push(createdConversation);

          dispatch({
            type: CHAT_V2_CONVERSATIONS,
            payload: updatedConversations,
          });

          dispatch({
            type: CHAT_V2_SELECTED_CONVERSATION_SID,
            payload: data.createConversation.sid,
          });

          dispatch({
            type: CHAT_V2_OPEN,
            payload: true,
          });

          setChatCreating(false);
        });
      }
    });
  };

  useEffect(() => {
    if (creditSubmissionData) {
      const cs = creditSubmissionData.creditSubmission;

      console.log(`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`);
      console.log(`Fast Track Status: ${cs.fastTrackStatus}`);
      console.log(`Fast Track Results: ${JSON.stringify(cs.fastTrackResults)}`);
      console.log(`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`);

      const voId = _.get(cs, "voId", "");
      const creditSubId = _.get(cs, "creditSubId", "");
      const customerName = _.get(cs, "applicant", "");
      const contactUserProfileId = _.get(cs, "contactUserProfileId", null);
      const customerDynamicsId = _.get(cs, "dynamicsApplicantId", "");

      const targetRecipient = _.find(relatedUsers, {
        id: contactUserProfileId,
      });

      if (targetRecipient) {
        dispatch({
          type: SET_CHAT_TARGET_RECIPIENT,
          payload: {
            targetRecipient: [
              {
                id: targetRecipient.id,
                accountId: targetRecipient.account.id,
                mughshot: targetRecipient.mugshot,
                vendorContactRole: targetRecipient.vendorContactRole,
                email: targetRecipient.email,
                firstName: targetRecipient.firstName,
                lastName: targetRecipient.lastName,
                fullName: targetRecipient.fullName,
                mobilePhoneNumber: targetRecipient.mobilePhoneNumber,
                company: targetRecipient.account.name,
              },
            ],
          },
        });
      }

      dispatch({
        type: CHAT_V2_SET_TARGET_CREDIT_SUBMISSION_ID,
        payload: creditSubId,
      });

      getVoData({
        variables: {
          voId,
        },
      });

      getCreditAppData({
        variables: {
          VOId: voId,
        },
      });
      getEquipmentData({
        variables: {
          VOId: voId,
        },
      });
    } else {
      getCreditSubmission();
    }
  }, [creditSubmissionData, relatedUsers, getCreditSubmission, dispatch]);

  const refetchDocs = () => {
    console.log("triggered");
    setTriggerRefetchDocs(new Date().getTime());
  };

  useEffect(() => {
    const whiteListIdAccount = [
      "f366505b-3b92-eb11-812f-066ef92796c9",
      "81f5c112-4e7f-ea11-8119-005056b05a0f",
      "c62ab074-9e7e-ea11-8119-005056b05a0f",
      "790be67b-09c9-ed11-816c-066ef92796c9",
    ];
    if (account && account.dynamicsAccountId) {
      setShowScoreCard(whiteListIdAccount.includes(account.dynamicsAccountId));
    }
  }, [account]);

  useEffect(() => {
    if (_.every([equipmentData, vo?.vendorOpportunityId, CADMPortalConfiguration, vendorProfile])) {
      setCADMData({
        equipmentData,
        vo,
        CADMPortalConfiguration,
        vendorProfile: _.get(vendorProfile, "vendorProfile"),
      });
    }
  }, [equipmentData, vo, CADMPortalConfiguration, vendorProfile]);

  useEffect(() => {
    if (_.get(vendorProfile, "vendorProfile.id")) {
      getPortalConfigurations({
        variables: {
          vendorProfileId: _.get(vendorProfile, "vendorProfile.id"),
          types: [portalConfigurationTypes.documents],
        },
      });
    }

    if (_.get(vendorProfile, "vendorProfile.ofacRedFlagComplianceAccess", false)) {
      setShowHealthCheck(true);
    }
  }, [vendorProfile]);

  useEffect(() => {
    if (!creditAppData) {
      return;
    }
    if (_.get(creditAppData, "creditApplication.ocaTemplate.vendorProfileId")) {
      getOcaTemplateAccount({
        variables: {
          vendorProfileId: creditAppData.creditApplication.ocaTemplate?.vendorProfileId,
        },
      });
    }
    setLocation(_.get(creditAppData, "creditApplication.location"));
  }, [creditAppData]);

  useEffect(() => {
    // We need to take a vp from oca related account since credit app could be submitted from both the lender's and dealer's templates
    const ocaTemplateAccount = _.get(ocaTemplateAccountData, "accountByVPId");
    if (_.isEmpty(ocaTemplateAccount)) {
      return;
    }
    /*     const ocaTemplateAccountType = _.get(ocaTemplateAccountData, "ocaTemplateAccount.accountType");
    if (isLenderUser && (ocaTemplateAccountType === "Vendor")) {
    }
    if (isDealerUser && (ocaTemplateAccountType === "Lender")) {
    } */
    const vpId = ocaTemplateAccount.vendorProfileId || account.vendorProfileId;
    getCADMPortalConfigurations({
      variables: {
        vendorProfileId: vpId,
        types: [portalConfigurationTypes.CADMIndividual, portalConfigurationTypes.CADMCommercial],
      },
    });
  }, [ocaTemplateAccountData]);

  useEffect(() => {
    if (!CADMPortalConfigurationData || !creditAppData) {
      return;
    }
    const applicationType = _.get(creditAppData, "creditApplication.applicationType");
    const portalConfigurations = _.get(CADMPortalConfigurationData, "portalConfigurations") || [];
    const ocaTemplateJsonDefinition = _.get(creditAppData, "creditApplication.ocaTemplate.jsonDefinition");
    const isIndividualCreditApp = checkIfIndividualCreditApp(applicationType);
    const CADMConfigurationTypeToUse = isIndividualCreditApp
      ? portalConfigurationTypes.CADMIndividual
      : portalConfigurationTypes.CADMCommercial;
    const CADMConfigurationToUse = _.find(portalConfigurations, { type: CADMConfigurationTypeToUse });
    if (_.some(CADMConfigurationToUse)) {
      assignFieldsOCAConfigFromActiveTemplate(CADMConfigurationToUse.jsonDefinition, ocaTemplateJsonDefinition);
    }
    setCADMPortalConfiguration(CADMConfigurationToUse);
  }, [CADMPortalConfigurationData, creditAppData]);

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }
    const portalConfigurations = _.get(portalConfigurationData, "portalConfigurations") || [];
    const portalConfigurationsToSetByDefault = [portalConfigurationTypes.documents];
    _.forEach(portalConfigurationsToSetByDefault, portalConfigurationType => {
      if (!_.find(portalConfigurations, { type: portalConfigurationType })) {
        portalConfigurations.push(
          getDefaultPortalConfigurationByType(portalConfigurationType, account.vendorProfileId)
        );
      }
    });
    setPortalConfigurations(portalConfigurations);

    const isCreditReportsHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurations, `${portalConfigurationTypes.creditReports}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );
    setIsCreditReportsHiddenForUserRole(isCreditReportsHiddenForUserRole);

    const isHealthCheckHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurations, `${portalConfigurationTypes.healthCheck}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );
    setIsHealthCheckHiddenForUserRole(isHealthCheckHiddenForUserRole);
  }, [portalConfigurationData]);

  useEffect(() => {
    if (!voData?.vendorOpportunity) {
      return;
    }
    dispatch({
      type: SET_VO,
      payload: voData.vendorOpportunity,
    });
  }, [voData]);

  const callbackSubmissionFinished = dynamicsCreditSubmissionId => {
    console.log(`Finished submission with ID ${dynamicsCreditSubmissionId}`);
    //Send mutation to update Lender Waterfall
    setHandleSubmitToLenderByProfileId(null);
  };

  return (
    <Query query={GET_CREDIT_SUBMISSION} context={queryContext} variables={{ csId: props.match.params.id }}>
      {({ loading, error, data }) => {
        const submissionDataLoaded = !!data && !!data.creditSubmission && Object.keys(data.creditSubmission).length;

        if (submissionDataLoaded) {
          const cs = data.creditSubmission;

          if (error) return <Typography>{String(error)}</Typography>;

          const dateTimeZone = cs.createdDate;
          const formattedDate = new Date(Date.parse(dateTimeZone));
          const parseTableData = ({ label, apiKey, format }, i) => {
            const rowData = getNested(cs, apiKey);
            if (rowData) {
              if (format === "upperCase") {
                let formattedString;
                if (rowData.includes("_")) {
                  formattedString = cs[apiKey]
                    .toLowerCase()
                    .split("_")
                    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ");
                } else {
                  formattedString = rowData
                    .toLowerCase()
                    .split(" ")
                    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ");
                }
                return [label, formattedString];
              } else if (format === "currency") {
                const formattedString = formatCurrency(rowData);
                return [label, formattedString];
              } else if (format === "date") {
                let inputDate = rowData;
                inputDate = inputDate.split("-");
                const outputDate = new Date(inputDate[0], inputDate[1], inputDate[2]).toLocaleDateString();
                return [label, outputDate];
              } else {
                return [label, rowData];
              }
            } else {
              return [label, null];
            }
          };

          function BoldText({ text }) {
            return <b>{text}</b>;
          }

          const invoiceDetailTableBody = invoiceDetailAPIKey.map(parseTableData);
          invoiceDetailTableBody.forEach(item => (item[0] = <BoldText text={item[0]} />));

          return (
            <DocumentContext.Provider
              value={{
                lastDoc: lastDocument,
                setLastDocument,
              }}>
              <div>
                {/* {chat.enabled ? <ChatWidget /> : null} */}
                <Backdrop className={classes.backdrop} open={loadingCreditSubmission}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container direction="column">
                  <Grid item lg={12}>
                    <Grid
                      justifyContent="space-between"
                      container
                      style={{
                        paddingBottom: "10px",
                      }}>
                      <Grid>
                        <Breadcrumbs aria-label="breadcrumb" style={{ flex: 1 }}>
                          <a href="/cs" className={classes.defaultTextWeight + " " + classes.a}>
                            List View
                          </a>
                          <span className={classes.defaultTextWeight}>Detail</span>
                        </Breadcrumbs>
                      </Grid>
                      <Grid justifyContent="space-between" container>
                        <Grid item xs={12}>
                          <h1>Submission Details: {cs.applicant}</h1>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                    <ApplicationSummary
                      cs={cs}
                      vo={vo}
                      lendersVendorProfile={vp}
                      creditApp={creditAppData}
                      isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
                      refetchCreditApp={() => {
                        getCreditAppData({
                          variables: {
                            VOId: _.get(creditSubmissionData, "creditSubmission.voId", null),
                          },
                        });
                        refetchDocs();
                      }}
                      handleOpenCreditReportDocument={handleOpenCreditReportDocument}
                      openCreditAppModal={openCreditAppModal}
                      handleClose={handleClose}
                      appSummary={appSummary}
                      setOpenCreditAppModal={setOpenCreditAppModal}
                      setAppSummary={setAppSummary}
                      vendorProfile={vendorProfile}
                      setVendorProfile={setVendorProfile}
                      CADMData={CADMData}
                      location={location}
                    />
                  </Grid>
                  <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                    <InvoiceEquipmentDetailsCard invoiceDetailTableBody={invoiceDetailTableBody} cs={cs} />
                    {cs.transactionSummary && <TransactionSummary cs={cs} />}
                  </Grid>
                </Grid>

                {showHealthCheck &&
                  creditAppData?.creditApplication?.creditApplicationOwner?.length > 0 &&
                  !isHealthCheckHiddenForUserRole && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Tile title="Health Check" useCollapsing>
                        <HealthCheckCard
                          owners={creditAppData.creditApplication.creditApplicationOwner}
                          refetchCreditApp={() =>
                            getCreditAppData({
                              variables: {
                                VOId: _.get(creditSubmissionData, "creditSubmission.voId", null),
                              },
                            })
                          }
                          vo={vo}
                          vp={vendorProfile}
                          creditApp={creditAppData.creditApplication}
                          full={true}
                        />
                      </Tile>
                    </Grid>
                  )}

                <Grid container spacing={1}>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <CreditSubmissionsCard cs={cs} />
                    <MessageModal
                      isOpen={openMessageModal}
                      handleCloseModal={handleCloseModal}
                      title={submissionMessage.title}
                      message={submissionMessage.message}
                    />
                  </Grid>
                  {vp?.allowSyndication && (
                    <>
                      <Grid item xs={12}>
                        <ManageSyndications
                          // setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
                          equipmentData={invoiceDetailTableBody}
                          cs={cs}
                          handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
                          callbackSubmissionFinished={callbackSubmissionFinished}
                          // setOpenEquipmentInputModal={setOpenEquipmentInputModal}
                        />
                      </Grid>
                    </>
                  )}
                  {vo.vendorOpportunityId && vp?.allowSyndication && (
                    <Grid item xs={12}>
                      <LenderWaterfallResults
                        creditSubmissions={cs}
                        vendorOpportunityId={vo.vendorOpportunityId}
                        lenderProfiles={vp.lenderProfiles?.filter(profile => profile.id)}
                        accountId={account.id}
                        handleSubmitToLenderByProfileId={setHandleSubmitToLenderByProfileId}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <Grid container direction="column">
                      {showScoreCard && (
                        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                          <ScoreCard cs={cs} />
                        </Grid>
                      )}
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <DocumentsCard
                          cs={cs}
                          refetchDocs={refetchDocs}
                          CADMData={CADMData}
                          portalConfigurations={portalConfigurations}
                          handleSetDocuments={docs => {
                            setDocuments(docs);
                          }}
                          triggerRefetchDocs={triggerRefetchDocs}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CSProposalList
                      cs={cs}
                      applicant={cs.applicant}
                      voId={cs.voId}
                      showOnlyLenderPrograms={
                        account.dynamicsAccountId === "10106e7c-d667-eb11-812d-066ef92796c9" ||
                        account.dynamicsAccountId === "f970df4b-81f6-ed11-816e-066ef92796c9" ||
                        account.dynamicsAccountId === "f8c9b561-d4fd-e011-9cc1-005056a20000"
                      }
                    />
                  </Grid>
                  {/*                   {(account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" ||
                    account.dynamicsAccountId === "c62ab074-9e7e-ea11-8119-005056b05a0f" ||
                    // AMUR
                    account.dynamicsAccountId === "f7c0c2a3-87f4-eb11-813b-066ef92796c9" ||
                    // AMUR END
                    // FCB
                    account.dynamicsAccountId === "10106e7c-d667-eb11-812d-066ef92796c9" ||
                    account.dynamicsAccountId === "f970df4b-81f6-ed11-816e-066ef92796c9" ||
                    account.dynamicsAccountId === "f8c9b561-d4fd-e011-9cc1-005056a20000") && (
                    // FCB - END

                    <Grid item xs={12}>
                      <CSProposalList
                        cs={cs}
                        applicant={cs.applicant}
                        voId={cs.voId}
                        showOnlyLenderPrograms={
                          account.dynamicsAccountId === "10106e7c-d667-eb11-812d-066ef92796c9" ||
                          account.dynamicsAccountId === "f970df4b-81f6-ed11-816e-066ef92796c9" ||
                          account.dynamicsAccountId === "f8c9b561-d4fd-e011-9cc1-005056a20000"
                        }
                      />
                    </Grid>
                  )} */}
                  <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                    <SpecificTasks
                      cs={cs}
                      account={account}
                      // vendorProfile={vp}
                      userProfile={userProfile}
                      relatedUsers={relatedUsers}
                      refetchDocs={refetchDocs}
                      // refetchCreditApp={refetchCreditApp}
                    />
                  </Grid>
                  {chat.enabled && (
                    <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                      <ConversationsHistory
                        loading={conversationsLoading || creating}
                        conversations={reduxConversations}
                        onConversationAdd={handleCreateConversation}
                      />
                    </Grid>
                  )}
                  {account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" && (
                    <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                      <ActivityList vo={vo} account={account} creditSubId={cs.creditSubId} />
                    </Grid>
                  )}
                  {account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" && (
                    <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                      <FDRList vo={vo} account={account} dynamicsTransactionId={cs.transactionId} cs={cs} />
                    </Grid>
                  )}
                </Grid>
              </div>
            </DocumentContext.Provider>
          );
        } else {
          return <>{loading ? <CircularProgress /> : null} </>;
        }
      }}
    </Query>
  );
});

// const mapStateToProps = (state) => ({
//   account: state.account,
//   relatedUsers: state.relatedUsers,
//   userProfile: state.userProfile
// });

// const mapDispatchToProps = {
//   setRefetch,
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CreditSubmissionDetails);
export default CreditSubmissionDetails;
