import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "components/CustomButtons/Button";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { Check } from "@mui/icons-material";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { rejectHiddenDocumentTypeOptions } from "../services/pcwService";
import { documentsByKey } from "../pages/Prequal/constants";

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  primaryBorderColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function FileDescriptionInputModal(props) {
  const classes = { ...useStyles(), ...useUiStyles() };
  const userProfile = useSelector(state => state.userProfile);
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    props.setFileType(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.fileType === "other" && props.fileDescription === "") return;
    setOpen(false);
    props.handleCloseFileDescriptionInputModal();
  };

  const handleChangeDocDescription = event => {
    props.setFileDescription(event.target.value || "");
  };

  useEffect(() => {
    setOpen(props.openFileDescriptionInputModal);
  }, [props.openFileDescriptionInputModal]);

  const documentTypeOptionsBase = [documentsByKey.quote, documentsByKey.invoice];

  const documentTypeOptionsFull = [
    documentsByKey.quote,
    documentsByKey.invoice,
    documentsByKey.buyers_order,
    documentsByKey.decision_letter,
    documentsByKey.insurance_certificate,
    documentsByKey.driver_license,
    documentsByKey.bank_statements,
    documentsByKey.tax_returns,
    documentsByKey.equipment_specs,
    documentsByKey.sales_tax_exemption,
    documentsByKey.credit_app_scan,
    documentsByKey.financial,
    documentsByKey.other,
  ];

  let documentTypeOptions = props.equipmentUpload ? documentTypeOptionsBase : documentTypeOptionsFull;
  documentTypeOptions = rejectHiddenDocumentTypeOptions(
    props.documentsPortalConfiguration,
    documentTypeOptions,
    userProfile.vendorContactRole
  );

  return (
    <div>
      <Dialog disableEscapeKeyDown open={props.openFileDescriptionInputModal} onClose={handleClose}>
        <DialogTitle>Please Describe The Uploading File</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
              <InputLabel htmlFor="demo-dialog-native">Document Type</InputLabel>
              <Select
                color="primary"
                classes={{ select: classes.select }}
                value={props.fileType}
                onChange={handleChange}
                inputProps={{ name: "lenderSelect", id: "lender-select" }}>
                {documentTypeOptions.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    style={{ padding: 0 }}
                    classes={{ root: classes.selectMenuItem }}>
                    <Checkbox
                      checked={props.fileType === item.value}
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{ checked: classes.checked }}
                    />
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Document Description"
                multiline
                rows={4}
                value={props.fileDescription}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeDocDescription}
                required={props.fileType === "other"}
                error={props.fileType === "other"}
                helperText={props.fileType === "other" ? "Please describe your document" : false}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" simple>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" simple>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
