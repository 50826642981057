import React, { useCallback } from "react";
import { formatCurrency } from "utils";
import debounce from "lodash/debounce";
import { Nullable, ValueOf } from "global";
import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { CurrencyInputField, SelectField } from "components/form";
import { CreditLineStatuses } from "../../types";
import { CreditLineFormValues } from "../model";
import { generateCreditLineLog } from "../lib";
import { UpdateVOVariables } from "../../api/types";
import { creditStatusValues } from "../constants";

interface Props {
  userName: string;
  amount: Nullable<number>;
  status: CreditLineStatuses;
  onCreditLineUpdate: <Key extends keyof ValueOf<UpdateVOVariables, "fieldsObj">>(
    data: Record<Key, ValueOf<UpdateVOVariables, "fieldsObj">[Key]>
  ) => Promise<void>;
}

export const CreditLineForm = ({ status, userName, amount, onCreditLineUpdate }: Props) => {
  const { setValue, control, getValues } = useFormContext<CreditLineFormValues>();

  const debouncedUpdateAmount = useCallback(
    debounce(async (nextAmountValue: number) => {
      await onCreditLineUpdate({
        creditLineAmountRequested: nextAmountValue,
      });
    }, 500),
    [onCreditLineUpdate]
  );

  const handelChangeAmount = async (nextAmountValue: number) => {
    setValue("amount", nextAmountValue);
    await debouncedUpdateAmount(nextAmountValue);
  };

  const handelChangeStatus = async (nextStatusValue: CreditLineStatuses) => {
    setValue("status", nextStatusValue);

    const logMessage = generateCreditLineLog({
      userName,
      currentStatus: status,
      nextStatus: nextStatusValue,
      prevNotes: getValues("allNotes"),
    });

    setValue("allNotes", logMessage);

    await onCreditLineUpdate({
      creditLineNotes: logMessage,
      creditLineStatus: nextStatusValue,
    });
  };

  return (
    <Box className="lineSection">
      <Typography component="span" variant="subtitle2">
        Line Requested {formatCurrency(amount, true)}
      </Typography>

      <CurrencyInputField
        name="amount"
        control={control}
        label="Approved Line"
        customOnChange={handelChangeAmount}
        textFieldProps={{ fullWidth: true }}
      />

      <SelectField
        label="Status"
        control={control}
        name="status"
        options={creditStatusValues}
        extraAction={async nextValue => {
          await handelChangeStatus(nextValue as CreditLineStatuses);
        }}
      />
    </Box>
  );
};
