import { Nullable } from "global";
import { createContext } from "react";
import { SaleType, SaleStructureType } from "../../types";

export interface SaleModeValues {
  isCash: boolean;
  isFinancing: boolean;
  saleType: SaleType;
  saleStructureType: SaleStructureType;
}

interface SaleModeContext extends SaleModeValues {
  onChangeSaleType: (saleType: SaleType) => void;
  onChangeStructureType: (structureType: SaleStructureType) => void;
}

export const saleModeContext = createContext<Nullable<SaleModeContext>>(null);
