import React, { useRef, useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ChecklistTemplate from "../../components/ChecklistTemplate/ChecklistTemplate";
import { ChecklistItem } from "../../components/ChecklistTemplate/type";
import { UPDATE_VO } from "../../components/ChecklistTemplate/queries";

interface Props {
  voId: string;
  refetchVO: any;
  voChecklist: ChecklistItem[];
}

export const VOChecklistCard = ({ voId, voChecklist, refetchVO }: Props) => {
  const [checklist, setChecklist] = useState<ChecklistItem[]>(voChecklist ?? []);

  const [saveVOSuccess, setSaveVOSuccess] = useState<boolean>(false);
  const [saveVOError, setSaveVOError] = useState<boolean>(false);

  const [updateVO] = useMutation(UPDATE_VO, {
    context: { authRequired: true },
    onCompleted() {
      setSaveVOSuccess(true);
    },
    onError() {
      setSaveVOError(true);
    },
  });

  const updateAndRefetchVO = async (checklist: ChecklistItem[]) => {
    await updateVO({
      variables: { VOId: voId, fieldsObj: { checklist } },
    });

    await refetchVO({ voId });
  };

  const updateAndRefetchVODebounced = useRef(_.debounce(updateAndRefetchVO, 500));

  const onChecklistSave = async (checklistToSave: ChecklistItem[]) => {
    setChecklist(checklistToSave); // to reflect on UI first
    updateAndRefetchVODebounced.current(checklistToSave);
  };

  return (
    <>
      <ChecklistTemplate checklist={checklist} onChecklistSave={onChecklistSave} />

      <Snackbar open={saveVOSuccess} autoHideDuration={3000} onClose={() => setSaveVOSuccess(state => !state)}>
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>

      <Snackbar open={saveVOError} autoHideDuration={3000} onClose={() => setSaveVOError(state => !state)}>
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
};
