import React from "react";
import ReactQuill from "react-quill";
import { Box, Tooltip, Typography } from "@mui/material";

interface Props {
  guidelines: any;
}

export const Description = ({ guidelines }: Props) => {
  return (
    <Box className="description">
      <Typography component="span" variant="subtitle2">
        Finance Program Description
      </Typography>

      <Tooltip arrow title="Customize the Finance Program Description as it appears on proposals (Optional).">
        <Box className="content">
          <ReactQuill theme="bubble" readOnly value={guidelines} />
        </Box>
      </Tooltip>
    </Box>
  );
};
