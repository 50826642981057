import React from "react";
import { Grid, Typography } from "@mui/material";
import { ContactPayloadInput } from "modules/creditApplicationDetailsActions/types";

interface Props {
  contactToSave: ContactPayloadInput;
}

export const AuthorizationTaskConfirmationBodyLayout = ({ contactToSave }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography>
          <strong>What to Expect:</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Typography>Contact will receive an email with a secure link to:</Typography>
            <ul>
              {contactToSave.isOwnerPg && (
                <li>
                  <Typography>Provide guarantor information</Typography>
                </li>
              )}
              <li>
                <Typography>Sign your Credit Application</Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography>A Task will be created to track completion</Typography>
          </li>
          <li>
            <Typography>You will receive a Task Completion Notification once completed</Typography>
          </li>
          <li>
            <Typography>
              New signor information will be automatically added to your existing Credit Application
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};
