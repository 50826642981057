import React from "react";
import { Box, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions, deskingSelectors } from "../../../model";

export const MenuFields = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray);

  const handleUnselectProduct = (id: string) => {
    dispatch(deskingActions.unselectProduct({ productId: id }));
  };

  return (
    <Box className={classes.container}>
      <Box className="field">
        <Box className="selectedValues">
          {!!selectedProducts?.length &&
            selectedProducts.map(product => (
              <Chip
                size="small"
                label={product.title}
                key={product.proposalProductId}
                onDelete={() => {
                  handleUnselectProduct(product.proposalProductId);
                }}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",

    "& .field": {
      gap: "4px",
      display: "flex",
      flexDirection: "column",

      "& .selectedValues": {
        gap: "4px",
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
});
