import { Nullable } from "global";
import { VendorOpportunity } from "modules/vendorOpportunity";
import { Invoice } from "../types";

export function collectInvoiceFromVO(vo: VendorOpportunity): Nullable<Invoice> {
  if (!vo) return null;

  return {
    downPayment: vo.downPayment || 0,
    invoiceTotal: vo.invoiceTotal || 0,
    equipmentCost: Number(vo.equipmentCost || 0),
    salesTaxOnInvoice: vo.salesTaxOnInvoice || 0,
    tradeOwedBalanceAmount: vo.tradeOwedBalanceAmount || 0,
    freightLaborInstallation: vo.freightLaborInstallation || 0,
    manufacturerRebateAmount: vo.manufacturerRebateAmount || 0,
    tradeAllowanceGrossTradeIn: vo.tradeAllowanceGrossTradeIn || 0,
    invoiceWarrantyAndInsuranceCharges: vo.invoiceWarrantyAndInsuranceCharges || 0,
  };
}
