import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Popper } from "@mui/material";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import "react-dropdown-tree-select/dist/styles.css";
import _ from "lodash";

const useStyles = makeStyles({
  locationsAutocompleteRoot: {
    width: "100%",
  },
  locationsTreeViewPopper: {
    width: "150px",
    zIndex: "1",
    "& .react-dropdown-tree-select .dropdown .dropdown-content": {
      minWidth: "300px",
      height: "450px",
      overflowY: "scroll",
    },
    "& .infinite-scroll-component": {
      overflow: "unset !important",
    },
    "& .react-dropdown-tree-select .dropdown .dropdown-trigger": {
      display: "none",
    },
    /*     "& .checkbox-item": {
          display: "none",
        }, */
  },
  locationsAutocompletePlaceholder: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderBottom: "1px solid #0000006b",
    height: "17px",
  },
  locationsTreeViewArrowIcon: {
    top: "calc(50% - 12px)",
    color: "rgba(0, 0, 0, 0.54)",
    right: "0",
    position: "absolute",
    pointerEvents: "none",
  },
});

export default function LocationsTreeViewCheckboxes({
  tree,
  treeSelectOptions,
  currentSelectedValues,
  getSelectedValuesDisplayLabel,
  /*   setActitvePopperContainer, */
}) {
  const classes = useStyles();
  const popperContainer = useRef(null);
  const popperRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popperRef]);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    /*     setActitvePopperContainer(popperContainer); */
  };

  const isPopoverVisible = Boolean(anchorEl);

  return (
    <div className={classes.locationsAutocompleteRoot}>
      <div ref={popperContainer} className={classes.locationsAutocompletePlaceholder} onClick={handleClick}>
        {_(currentSelectedValues).map(getSelectedValuesDisplayLabel).value().join(", ")}
        {!isPopoverVisible && <ArrowDropDownIcon className={classes.locationsTreeViewArrowIcon} />}
        {isPopoverVisible && <ArrowDropUpIcon className={classes.locationsTreeViewArrowIcon} />}
      </div>
      <Popper
        ref={popperRef}
        open={true}
        anchorEl={popperContainer.current}
        placement="bottom-start"
        style={{ width: 150, display: isPopoverVisible ? "block" : "none" }}
        className={classes.locationsTreeViewPopper}>
        <div className={classes.locationsTreeViewContainer}>
          <LocationsTreeViewDropdownMemoized tree={tree} treeSelectOptions={treeSelectOptions} />
        </div>
      </Popper>
    </div>
  );
}

const LocationsTreeViewDropdownMemoized = React.memo(
  function LocationsTreeViewDropdownMemoized({ tree, treeSelectOptions }) {
    return <LocationsTreeViewDropdown tree={tree} treeSelectOptions={treeSelectOptions} />;
  },
  (props, nextProps) => _.isEqual(nextProps.options, props.options)
);
