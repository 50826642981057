import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useNotifications } from "modules/notification";
import FinanceProgramCard from "components/FinanceProgramCard";
import { checkProposalRequiredFields, parseProposal } from "services/proposalsService";
import { CREATE_QUOTE } from "./api";
import { validationTypes } from "../../utils";
import { QuoteInfoSection } from "./QuoteInfoSection";

export const PQTPage = () => {
  const { push } = useHistory();
  const { showNotification } = useNotifications();

  const vp = useSelector(state => state.vp);
  const account = useSelector(state => state.account);

  const [createQuote, { loading: createQuoteLoading }] = useMutation(CREATE_QUOTE, {
    context: { authRequired: true },
  });

  const [formValid, setFormValid] = useState(false);

  const [quoteInfo, setQuoteInfo] = useState({
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    equipmentDescription: "",
    fullName: "",
  });

  const [error, setError] = useState({
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
    equipmentDescription: false,
  });

  useEffect(() => {
    const collectedErrors = {
      ...error,
      email: quoteInfo.email ? !validationTypes["email"](quoteInfo.email) : false,
      phone: quoteInfo.phone ? !validationTypes["requiredPhone"](quoteInfo.phone) : false,
      firstName: quoteInfo.firstName ? !validationTypes["minLen"](quoteInfo.firstName, 2) : false,
      lastName: quoteInfo.lastName ? !validationTypes["minLen"](quoteInfo.lastName, 2) : false,
      equipmentDescription: quoteInfo.equipmentDescription
        ? !validationTypes["minLen"](quoteInfo.equipmentDescription, 3)
        : false,
    };

    const { businessName, fullName, ...requiredFields } = quoteInfo;

    setFormValid(
      Object.values(collectedErrors).every(v => v === false) && Object.values(requiredFields).every(v => !_.isEmpty(v))
    );

    setError(collectedErrors);
  }, [quoteInfo]);

  const handleQuoteInputChange = event => {
    const { value, name } = event.target;

    setQuoteInfo({ ...quoteInfo, [name]: value });
  };

  const handleCreateQuote = async program => {
    const parsedProposal = parseProposal(program);

    const errorMessage = [];

    if (!formValid) {
      errorMessage.push("Quote info form section is either incomplete or has errors");
    }

    if (!checkProposalRequiredFields(parsedProposal)) {
      errorMessage.push("Finance Program form is either incomplete or has errors");
    }

    if (errorMessage.length) {
      showNotification(errorMessage.join(", "), { type: "error" });
    } else {
      const variables = {
        input: {
          sendQuote: true,
          financeProgramId: program.financeProgramId,
          paymentOptions: { finance: { ...parsedProposal, saleType: "FINANCE_ONLY" } },
          info: { ...quoteInfo, fullName: `${quoteInfo.firstName} ${quoteInfo.lastName}` },
        },
      };

      const { data } = await createQuote({ variables });

      if (data.createQuote.success) {
        push(`/proposalDetail/${data.createQuote.id}?from=/pqt`);
      }
    }
  };

  return (
    <FinanceProgramCard
      vp={vp}
      isVOView
      vo={null}
      account={account}
      quoteToolModeEnabled
      style={{ overflowX: "hidden" }}
      cardTitle="Select Finance Program"
      submitCallback={handleCreateQuote}
      externalLoadingState={createQuoteLoading}
      quoteInfoComponent={<QuoteInfoSection quoteInfo={quoteInfo} error={error} onChange={handleQuoteInputChange} />}
    />
  );
};
