import React from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  boxContainer: {
    maxHeight: "22px",
  },
  actionsContainer: {
    flexWrap: "nowrap",
  },
  icon: {
    cursor: "pointer",
    //marginLeft: "8px",
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
  importExportIcon: {
    color: "#5a78e1",
  },
  mergeTypeIcon: {
    color: "#5a78e1",
  },
  settingsIcon: {
    color: "#5a78e1",
  },
  deleteIcon: {
    color: "#c62626",
  },
  removeIcon: {
    color: "#ff0000cf",
  },
}));

export function ConfigurableItemActions({
  itemType,
  warningMessage = null,
  onEdit,
  onMove = null,
  onMerge = null,
  onDelete,
  onRemove = null,
  onRemoveCustomIcon = null,
  disabled = false,
}) {
  const classes = useStyles();

  const tooltipValues = {
    settings: _.startCase(itemType + " settings"),
    move: _.startCase("Move " + itemType),
    merge: _.startCase("Merge " + itemType),
    delete: _.startCase("Delete " + itemType),
    remove: _.startCase("Remove " + itemType),
  };

  return (
    <Box className={classes.boxContainer}>
      <Grid container spacing={1} className={classes.actionsContainer}>
        {warningMessage && (
          <Tooltip title={warningMessage} aria-label={warningMessage} placement="top">
            <Grid item xs>
              <ReportProblemOutlinedIcon className={`${classes.icon} ${classes.reportProblemIcon}`} disabled />
            </Grid>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title={tooltipValues.settings} aria-label={tooltipValues.settings} placement="top">
            <Grid item xs>
              <SettingsIcon className={`${classes.icon} ${classes.settingsIcon}`} onClick={onEdit} disabled />
            </Grid>
          </Tooltip>
        )}
        {onMove && (
          <Tooltip title={tooltipValues.move} aria-label={tooltipValues.move} placement="top">
            <Grid item xs>
              <ImportExportIcon className={`${classes.icon} ${classes.importExportIcon}`} onClick={onMove} disabled />
            </Grid>
          </Tooltip>
        )}
        {onMerge && (
          <Tooltip title={tooltipValues.merge} aria-label={tooltipValues.merge} placement="top">
            <Grid item xs>
              <MergeTypeIcon className={`${classes.icon} ${classes.mergeTypeIcon}`} onClick={onMerge} disabled />
            </Grid>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title={tooltipValues.delete} aria-label={tooltipValues.delete} placement="top">
            <Grid item xs>
              <DeleteIcon className={`${classes.icon} ${classes.deleteIcon}`} onClick={onDelete} disabled />
            </Grid>
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip title={tooltipValues.remove} aria-label={tooltipValues.remove} placement="top">
            <Grid item xs>
              {onRemoveCustomIcon ? (
                React.createElement(onRemoveCustomIcon, {
                  className: `${classes.icon} ${classes.removeIcon}`,
                  onClick: onRemove,
                })
              ) : (
                <RemoveCircleOutlineIcon
                  className={`${classes.icon} ${classes.removeIcon}`}
                  onClick={onRemove}
                  disabled
                />
              )}
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Box>
  );
}
