import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Button from "../../components/CustomButtons/Button";
import { useSelector } from "react-redux";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  requiredHint: {
    fontSize: "10px",
    fontWeight: "300",
    textAlign: "right",
    width: "100%",
    margin: 0,
  },
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
});
const useStyles = makeStyles(styles);

const CHANGE_LENDER_PROFILE_CONTACT = gql`
  mutation ($input: ChangeLenderProfileContactInput!) {
    changeLenderProfileContact(input: $input)
  }
`;

export default function ChangeLenderContact({ open, handleClose: handleCloseModal, lenderProfile, contact }) {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const vp = useSelector(state => state.vp);
  const userProfile = useSelector(state => state.userProfile);
  const [changeLenderProfileContact, { loading: mutationLoading }] = useMutation(CHANGE_LENDER_PROFILE_CONTACT, {
    context: { authRequired: true },
  });
  const initialFormValues = {
    lenderContactEmail: "",
    lenderContactFirstName: "",
    lenderContactLastName: "",
    phone: "",
    title: "",
  };

  const initialErrorStates = {
    lenderContactEmail: false,
    lenderContactFirstName: false,
    lenderContactLastName: false,
    phone: false,
    title: false,
  };
  //Fields
  const [values, setValues] = useState(initialFormValues);

  //Contro Inputs
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [havePortal, setHavePortal] = useState(false);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });

    //User selected Manual submission to Portal, this enables Lender's Website fields
    if (prop == "channel") {
      setHavePortal(event.target.value == 200);
    }

    if (submitted) {
      runValidations();
    }
  };
  const resetStates = () => {
    setValues(initialFormValues);
    setErrors(initialErrorStates);
    setSubmitted(false);
  };
  const handleClose = () => {
    handleCloseModal();
    resetStates();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [errors, setErrors] = useState(initialErrorStates);

  let checker = arr => arr.every(v => v === false);

  useEffect(() => {
    setSubmitSuccess(false);
  }, [open]);

  useEffect(() => {
    if (contact) {
      setValues({
        lenderContactEmail: contact.email,
        lenderContactFirstName: contact.firstName,
        lenderContactLastName: contact.lastName,
        phone: contact.phoneNumber,
        title: contact.title,
      });
    }
  }, [contact]);

  const runValidations = () => {
    const emailRegex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errorCollect = {
      ...errors,
      lenderContactFirstName: values.lenderContactFirstName ? false : true,
      lenderContactLastName: values.lenderContactLastName ? false : true,
      lenderContactEmail: !emailRegex.test(String(values.lenderContactEmail).toLowerCase()),
      phone: values.phone ? false : true,
      title: values.title ? false : true,
    };
    setErrors(errorCollect);

    const result = checker(Object.values(errorCollect));
    console.log(result);
    return result;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const validationsPassed = runValidations();
    setSubmitted(true);
    setSubmitSuccess(false);

    if (validationsPassed) {
      try {
        await changeLenderProfileContact({
          variables: {
            input: {
              lenderContactEmail: values.lenderContactEmail,
              lenderContactFirstName: values.lenderContactFirstName,
              lenderContactLastName: values.lenderContactLastName,
              lenderProfileId: lenderProfile.lenderProfileId,
              lenderAccountId: lenderProfile.lenderAccountId,
              title: values.title,
              phone: values.phone,
            },
          },
        });

        setSubmitSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="subtitle1">Change Lender Contact</Typography>
        {handleClose ? (
          <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {/* {!!lenderProfile && lenderProfile.dealerRelationship === "100" ? ( */}
        {(!!lenderProfile && userProfile.vendorContactRole === "credit_mgr") ||
        userProfile.vendorContactRole === "admin" ? (
          <>
            <Snackbar
              open={submitSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleClose}>
              <Alert severity="success" variant="filled">
                Request Sent Successfully. Please allow 24 hours to process.
              </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit}>
              <p className={classes.requiredHint}>
                <b>*</b> required fields
              </p>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: "flex", width: "100%", gap: 12 }}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      style={{ width: "100%" }}
                      error={errors.lenderContactFirstName}
                      label="Contact First Name"
                      name="lenderContactFirstName"
                      value={values.lenderContactFirstName}
                      onChange={handleChange("lenderContactFirstName")}
                      required={true}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      style={{ width: "100%" }}
                      error={errors.lenderContactFirstName}
                      label="Contact Last Name"
                      name="lenderContactLastName"
                      value={values.lenderContactLastName}
                      onChange={handleChange("lenderContactLastName")}
                      required={true}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    style={{ width: "calc(100% - 6px)" }}
                    error={errors.title}
                    label="Contact Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange("title")}
                    required={true}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={errors.lenderContactEmail}
                    label="Contact Email"
                    value={values.lenderContactEmail}
                    onChange={handleChange("lenderContactEmail")}
                    required={true}
                    helperText={errors.lenderContactEmail ? "Valid email is required" : ""}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={errors.phone}
                    label="Contact Phone"
                    value={values.phone}
                    onChange={handleChange("phone")}
                    required={true}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="primary"
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={mutationLoading}>
                    Send Change Request
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          "Please reach out to your account manager to change the lender contact"
        )}
      </DialogContent>
    </Dialog>
  );
}
