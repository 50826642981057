import React from "react";
import { Column } from "react-table";
import { formatCurrency } from "utils";
import { SimpleTextCell } from "../cells";
import { CellArgs, ProductDetailsMappedData, ProductDetailsTableData } from "../../../types";

type DetailsProposalCellArgs = CellArgs<ProductDetailsTableData>;

interface Options {
  totals: ProductDetailsMappedData["totals"];
}

export function createTableColumns({ totals }: Options): Column<ProductDetailsTableData>[] {
  return [
    {
      Header: "Vendor",
      accessor: "vendor",
      Cell: ({ original }: DetailsProposalCellArgs) => <SimpleTextCell value={original.vendor} />,
    },
    {
      Header: "Type",
      accessor: "productCategory",
      Cell: ({ original }: DetailsProposalCellArgs) => <SimpleTextCell value={original.productCategory} />,
    },
    {
      Header: "Cost",
      accessor: "cost",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell formatter={value => formatCurrency(value, true)} value={original.cost} />
      ),
      Footer: () => <SimpleTextCell formatter={value => formatCurrency(value, true)} value={totals.cost} />,
    },
    {
      Header: "Markup",
      accessor: "markup",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell
          value={original.markup}
          formatter={value => (original.markupType === "FLAT" ? formatCurrency(value, true) : `${+value}%`)}
        />
      ),
      Footer: () => <SimpleTextCell formatter={value => formatCurrency(value, true)} value={totals.markup} />,
    },
    {
      Header: "Retail",
      accessor: "retailCost",
      Cell: ({ original }: DetailsProposalCellArgs) => (
        <SimpleTextCell formatter={value => formatCurrency(value, true)} value={original.retailCost} />
      ),
      Footer: () => <SimpleTextCell formatter={value => formatCurrency(value, true)} value={totals.retailCost} />,
    },
  ].map(header => ({
    ...header,
    sortable: false,
    resizable: false,
    headerClassName: "customHeader",
  }));
}
