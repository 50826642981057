import React, { useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { ApolloQueryResult } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useViewTypeContext } from "contexts/contentViewType";
import ConsumerCreditReport from "components/ConsumerCreditReport";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { useHealthCheck } from "../hooks/useHealthCheck";

interface Props {
  full?: boolean;
  creditApp: any;
  userName: string;
  accountId: string;
  cardTitle?: string;
  refetchCreditApp: () => Promise<ApolloQueryResult<any>>;
  softPullInDealerPortal: "CBC_EQUIFAX_SOFT_SUMMARY_ONLY" | "CBC_EQUIFAX_SOFT_FULL_REPORT" | string;
}

// TODO: need refactoring, typing and decomposition
export const HealthCheckCard = (props: Props) => {
  const {
    userName,
    accountId,
    creditApp,
    full = true,
    refetchCreditApp,
    softPullInDealerPortal,
    cardTitle = "Health Check",
  } = props;

  const owners = creditApp.creditApplicationOwner;

  const classes = useStyles({ full });

  const { contentViewType } = useViewTypeContext();

  const [pullingCredit, setPullingCredit] = useState<boolean>(false);
  const [hasRedFlagReport, setHasRedFlagReport] = useState<boolean>(false);

  const { hasConsumerCreditPulled, hasBusinessCreditPulled } = useMemo(
    () => ({
      hasBusinessCreditPulled: !!creditApp?.mostRecentCreditReportOfac?.machineReadableReport,
      hasConsumerCreditPulled: owners.some((owner: any) => !!owner.personalGuarantor?.mostRecentCbrs[0]?.fields),
    }),
    [owners, creditApp]
  );

  const { handleTriggerWebhook, handlePullConsumerCreditReport, handlePullBusinessCreditReport } = useHealthCheck();

  async function triggerCbrSoftPull(ownerPgId: string, ownerPgDynamicsId: string) {
    await handlePullConsumerCreditReport({
      dynamicsCreditApplicationId: creditApp.dynamicsId,
      ownerPgId: ownerPgId,
      ownerPgDynamicsId: ownerPgDynamicsId,
      creditReporterCode:
        softPullInDealerPortal === "CBC_EQUIFAX_SOFT_SUMMARY_ONLY" ||
        softPullInDealerPortal === "CBC_EQUIFAX_SOFT_FULL_REPORT"
          ? "CBC"
          : "LEGACY_CRM",
    });

    setTimeout(async () => {
      await refetchCreditApp();
      setPullingCredit(false);
    }, 1200 * 10);
  }

  async function ownersCreditPull(owners: any) {
    setPullingCredit(true);
    owners.forEach(async (owner: any) => {
      const pgo = owner.personalGuarantor;
      await triggerCbrSoftPull(pgo.personalGuarantorId, pgo.personalGuarantorDynamicsId);
    });
  }

  async function businessCreditPull(creditApp: any) {
    setPullingCredit(true);

    await handlePullBusinessCreditReport({
      creditReporterCode: "CBC_OFAC",
      dynamicsCreditApplicationId: creditApp.dynamicsId,
    });

    setTimeout(async () => {
      await refetchCreditApp();
      setPullingCredit(false);
    }, 1200 * 10);
  }

  const renderBusinessOFAC = (creditApp: any) => {
    const json = creditApp.mostRecentCreditReportOfac?.machineReadableReport;

    if (!json) return null;

    const OFAC = JSON.parse(json).OFAC;

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <p style={{ marginRight: 16 }}>OFAC:</p>
        <p
          style={
            OFAC === "NOHIT"
              ? {
                  color: "#4caf50",
                  fontWeight: "bold",
                }
              : OFAC === "HIT"
                ? {
                    color: "#ff1744",
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "bold",
                  }
          }>
          {OFAC === "NOHIT" ? "NO HIT" : OFAC}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (hasRedFlagReport && document) {
      if (document.getElementsByClassName("red_flag_verdict_txt").length > 0) {
        // @ts-ignore
        let reportVerdicts = new Array(...document.getElementsByClassName("red_flag_verdict_txt"));

        reportVerdicts.forEach(reportVerdict => {
          reportVerdict.addEventListener("DOMSubtreeModified", (e: any) => {
            const ownerName = e.target.offsetParent?.getElementsByClassName("red_flag_name")[0]?.innerText;
            const owner = [...owners].find(
              owner => `${owner.firstName?.toUpperCase()} ${owner.lastName?.toUpperCase()}` === ownerName
            );

            if (accountId && owner) {
              handleTriggerWebhook({
                accountId,
                entityId: owner.ownerPgId,
                eventType: "CREDIT_REPORT_CONSUMER_UPDATED",
              });
            } else if (accountId) {
              owners.forEach((owner: any) => {
                handleTriggerWebhook({
                  accountId,
                  entityId: owner.ownerPgId,
                  eventType: "CREDIT_REPORT_CONSUMER_UPDATED",
                });
              });
            }
          });
        });
      }

      if (document.getElementsByClassName("red_flag_upload").length > 0) {
        // @ts-ignore
        new Array(...document.getElementsByClassName("red_flag_upload")).forEach(e => (e.style = "display:none;"));
      }
    }
  }, [hasRedFlagReport]);

  if (owners) {
    return (
      <CardContainer title={cardTitle} contentViewType={contentViewType}>
        <Box className={classes.healthCheckContent}>
          {!pullingCredit ? (
            <>
              {creditApp.applicationType?.toLowerCase() === "Commercial".toLowerCase() && (
                <Box>
                  {hasBusinessCreditPulled ? (
                    <div className={classes.businessOFACContainer}>
                      <h5 style={{ fontWeight: "bold", marginBottom: 16 }}>{creditApp.businessName}</h5>

                      {renderBusinessOFAC(creditApp)}
                    </div>
                  ) : (
                    <Button size="small" variant="contained" onClick={() => businessCreditPull(creditApp)}>
                      Check Business OFAC
                    </Button>
                  )}
                </Box>
              )}
              {hasConsumerCreditPulled ? (
                <Box>
                  {owners.map((owner: any) => {
                    let OFAC = null;
                    let RedFlagCompliance = null;

                    const creditReportForThisOwner = owner.personalGuarantor?.mostRecentCbrs?.find((cbr: any) => {
                      return cbr?.creditBureauType !== "OFAC";
                    });

                    if (creditReportForThisOwner?.fields) {
                      const fields = creditReportForThisOwner.fields;
                      RedFlagCompliance = fields.find((field: any) => field.key === "Red Flag Compliance");

                      if (RedFlagCompliance && !hasRedFlagReport) setHasRedFlagReport(true);
                    }

                    for (let i = 0; i < owner.personalGuarantor?.mostRecentCbrs?.length; i++) {
                      OFAC = owner.personalGuarantor?.mostRecentCbrs[i]?.fields?.find(
                        (field: any) => field.key === "OFAC"
                      );
                      if (OFAC) {
                        break;
                      }
                    }

                    return (
                      <div
                        key={`owner_${owner.ownerPgId}`}
                        style={{ display: "flex", width: "100%", flexDirection: "column", flex: 1, padding: "0 16px" }}>
                        <h5 style={{ marginBottom: 16, fontWeight: "bold" }}>
                          {owner.firstName + " " + owner.lastName}
                        </h5>
                        {OFAC && (
                          <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ marginRight: 16 }}>OFAC:</p>
                            <p
                              style={
                                OFAC.value === "NO HIT" || "NOHIT"
                                  ? {
                                      color: "#4caf50",
                                      fontWeight: "bold",
                                    }
                                  : OFAC.value === "HIT"
                                    ? {
                                        color: "#ff1744",
                                        fontWeight: "bold",
                                      }
                                    : {
                                        fontWeight: "bold",
                                      }
                              }>
                              {OFAC.value === "NOHIT" ? "NO HIT" : OFAC.value}
                            </p>
                          </div>
                        )}
                        {RedFlagCompliance && (
                          <div className={classes.redFlagCompliance}>
                            <p>Red Flag Compliance:</p>

                            <ConsumerCreditReport
                              index={0}
                              userName={userName}
                              accountId={accountId}
                              ownerId={owner.ownerPgId}
                              report={RedFlagCompliance.value}
                              auditLog={creditReportForThisOwner.auditLog}
                              creditReportConsumerId={creditReportForThisOwner.creditReportConsumerId}
                              dateReportWasPulled={new Date(creditReportForThisOwner.createdDateTime)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Box>
              ) : (
                <Box>
                  <Button size="small" variant="contained" onClick={() => ownersCreditPull(owners)}>
                    Pull Applicant's OFAC
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <CircularProgress style={{ margin: 16 }} />
          )}
        </Box>
      </CardContainer>
    );
  }

  return null;
};

const useStyles = makeStyles<Theme, { full: boolean }>({
  healthCheckContent: {
    gap: "1rem",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  redFlagCompliance: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
});
