import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export const MarketingDisclosuresCheckbox = ({ fieldConfig }) => {
  const [value, setValue] = useState(false);

  return (
    <FormControlLabel
      label={fieldConfig.displayLabel.en}
      control={
        <Checkbox
          checked={value}
          onChange={(_, checked) => {
            setValue(checked);
          }}
        />
      }
    />
  );
};
