import React, { useState } from "react";
import { TextField } from "@mui/material";
import _ from "lodash";
export function EmailField({ fieldConfig }) {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      style={{ pointerEvents: "none" }}
      disabled={fieldConfig.disabled}
      name={fieldConfig.fieldName}
      fullWidth={fieldConfig.fullWidth}
      variant="standard"
      required={fieldConfig.required}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      error={false}
      value={value}
    />
  );
}
