import React from "react";
import Tile from "../../components/Tile/Tile";
import Table from "../../components/Table/Table";
import { formatMoney } from "../../utils";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import TRANS_UNION_LOGO from "assets/img/credit-bureau-logos/TRANS_UNION.png";
import EXPERIAN_LOGO from "assets/img/credit-bureau-logos/EXPERIAN.png";
import EQUIFAX_LOGO from "assets/img/credit-bureau-logos/EQUIFAX.png";

const useStyles = makeStyles(theme => ({
  surface: {
    position: "absolute",
    minWidth: 600,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 540,
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  subHeader: {
    paddingBottom: "18px",
  },
  subHeaderText: {
    fontWeight: "bold",
    fontSize: "18px",
    paddingBottom: "4px",
  },
  creditBureauName: {
    paddingLeft: "12p",
  },
}));

function CreditReportDetails({
  creditBureauReportWithPgDetails, // { cbr, pgDetails: creditApplicationOwner }
  handleClose,
}) {
  const creditBureauReportTableData = [];

  const classes = useStyles();

  // console.log(`CreditReportDetails creditBureauReportWithPgDetails: ${JSON.stringify(creditBureauReportWithPgDetails)}`);

  if (!creditBureauReportWithPgDetails) {
    return null;
  }

  const creditBureauTypeText = creditBureauReportWithPgDetails.creditBureauType.replace("_", " ");

  let creditBureauLogo;

  if (creditBureauReportWithPgDetails.creditBureauType === "TRANS_UNION") {
    creditBureauLogo = TRANS_UNION_LOGO;
  } else if (creditBureauReportWithPgDetails.creditBureauType === "EXPERIAN") {
    creditBureauLogo = EXPERIAN_LOGO;
  } else if (creditBureauReportWithPgDetails.creditBureauType === "EQUIFAX") {
    creditBureauLogo = EQUIFAX_LOGO;
  }

  const moneyKeys = [
    "ACCOUNTSBALANCE",
    "AMOUNTPASTDUE",
    "AVGMOPAYMENT",
    "COLLECTIONS",
    "CREDITLIMIT",
    "HIGHCREDIT",
    "TOTALREALESTATEBAL",
    "TOTALREVOLVINGBAL",
    "TOTALINSTALLMENTBAL",
    "TOTALINSTALLMENTMO",
  ];

  creditBureauReportWithPgDetails.fields.forEach(cbrField => {
    if (cbrField.key !== "Red Flag Compliance") {
      const valueToDisplay = moneyKeys.includes(cbrField.key) ? `$${formatMoney(cbrField.value)}` : cbrField.value;

      creditBureauReportTableData.push([cbrField.key, valueToDisplay]);
    }
  });

  return (
    <div className={classes.modalStyle}>
      <Tile
        maxHeightLimitByScreen={true}
        title={
          <div style={{ fontWeight: "bold" }}>
            <div>
              {creditBureauReportWithPgDetails.pgDetails.firstName} {creditBureauReportWithPgDetails.pgDetails.lastName}
            </div>
            <div>
              <img height="32px" width="32px" style={{ marginRight: "6px" }} src={creditBureauLogo} />
              {creditBureauTypeText}
            </div>
          </div>
        }
        subtitle={"Credit Report Details"}
        titleButtons={[]}
        titleButtonComponent={
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              handleClose();
            }}
            size="large">
            <CloseIcon />
          </IconButton>
        }>
        <Table tableHeaderColor="primary" tableData={creditBureauReportTableData} colorsCols={["primary"]} />
      </Tile>
    </div>
  );
}

export default CreditReportDetails;
