import React, { ReactNode } from "react";
import clsx from "clsx";
import { Nullable } from "global";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SaleType } from "../../../types";

interface Props {
  isLoading: boolean;
  saleType?: SaleType;
  prices: Record<SaleType, Array<{ label?: string; value: Nullable<number | string> }>>;
}

export const MenuOptionCardFooter = ({ isLoading, prices, saleType = SaleType.Financing }: Props) => {
  const classes = useStyles();

  const renderBySaleType: Record<SaleType, ReactNode> = {
    [SaleType.Cash]: (
      <>
        {prices[saleType].map(({ value }) => (
          <Typography key={value} component="span" variant="h6">
            {value}
          </Typography>
        ))}
      </>
    ),
    [SaleType.Financing]: (
      <Box className={clsx("pricesValueWrapper", { calculating: isLoading })}>
        {prices[saleType]
          .filter(({ value }) => value)
          .map(({ value, label }) => (
            <Box key={value} className="priceValue">
              <Typography component="span" variant="h6">
                {value}
              </Typography>

              <Typography component="span" variant="caption" color="textSecondary">
                {label}
              </Typography>
            </Box>
          ))}
      </Box>
    ),
  };

  return <Box className={classes.price}>{renderBySaleType[saleType]}</Box>;
};

const useStyles = makeStyles({
  price: {
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .pricesValueWrapper": {
      gap: "4px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",

      "& .priceValue": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      },
    },

    "& .calculating": {
      filter: "blur(2px)",
    },
  },
});
