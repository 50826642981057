import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import ReactTable from "react-table";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import logo from "assets/img/trnsact-logo-dark.png";

import { convertToDate } from "../../../../utils";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  mainOptionContainer: {
    padding: "0 5%",
    margin: "0 auto",
  },
  dcrLogo: {
    cursor: "pointer",
    marginTop: "20px",
  },
  filterInput: {
    "& input": {
      height: "20px !important",
      paddingTop: "0 !important",
    },
  },
}));

const GET_AVAILABLE_TEMPLATES_PER_VENDOR = gql`
  query GetAvailableTemplatesForAdmin {
    availableTemplatesForAdmin {
      ocaTemplateId
      name
      vendorProfileName
      active
      createdDateTime
      modifiedDateTime
    }
  }
`;

const GET_OCA_TEMPLATE_BY_ID = gql`
  query getByOcaTemplateId($ocaTemplateId: ID!) {
    getByOcaTemplateId(ocaTemplateId: $ocaTemplateId) {
      ocaTemplateId
      name
      jsonDefinition
      jsonSettings
    }
  }
`;

export const TemplateClone = ({ copyOcaTemplate, setStartFromScratch, setSelectTemplateMode }) => {
  const classes = useStyles();
  const history = useHistory();
  const [availableTemplates, setAvailableTemplates] = useState([]);

  const { data: availableTemplatesData, loading } = useQuery(GET_AVAILABLE_TEMPLATES_PER_VENDOR, {
    fetchPolicy: "no-cache",
  });

  const [getOcaTemplateById, { data: ocaTemplateToCopyData }] = useLazyQuery(GET_OCA_TEMPLATE_BY_ID, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (!availableTemplatesData || _.isEmpty(availableTemplatesData.availableTemplatesForAdmin)) {
      return;
    }
    setAvailableTemplates(_.sortBy(availableTemplatesData.availableTemplatesForAdmin, "vendorProfileName"));
  }, [availableTemplatesData]);

  useEffect(() => {
    const ocaTemplateToCopy = _.get(ocaTemplateToCopyData, "getByOcaTemplateId");
    if (_.isEmpty(ocaTemplateToCopy)) {
      return;
    }
    copyOcaTemplate(ocaTemplateToCopy);
  }, [ocaTemplateToCopyData]);

  const copyTemplateHandler = selectedTemplate => {
    getOcaTemplateById({
      variables: {
        ocaTemplateId: selectedTemplate.ocaTemplateId,
      },
    });
  };

  const getFilterUI = (accessor, filter, onChange) => {
    /*     const options = _(availableTemplates)
          .uniqBy(accessor)
          .map(accessor)
          .compact()
          .value();
        return <select
          className={classes.filterSelect}
          onChange={e => onChange(e.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: "100%" }}
        >
          <option value="">Show All</option>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select> */
    return (
      <TextField
        className={classes.filterInput}
        value={filter ? filter.value : ""}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    );
  };

  const filterMethod = (filter, row) => {
    const rowValue = _.get(row, filter.id);
    return _.includes(_.toLower(rowValue), _.toLower(filter.value));
  };

  const filterMethodActive = (filter, row) => {
    const rowValue = _.get(row, filter.id);
    const displayValueToCompare = rowValue ? "yes" : "no";
    return _.includes(displayValueToCompare, _.toLower(filter.value));
  };

  const filterMethodDate = (filter, row) => {
    const rowValue = _.get(row, filter.id);
    const displayValueToCompare = convertToDate(+rowValue);
    return _.includes(displayValueToCompare, _.toLower(filter.value));
  };

  return (
    <Grid container className={classes.mainOptionContainer} spacing={4}>
      <Grid item xs={4}>
        <img alt="Logo" src={logo} onClick={() => history.push(`/constructor`)} className={classes.dcrLogo} />
      </Grid>
      {loading ? (
        <Grid item xs={12}>
          <div className={classes.circularProgressWrapper}>
            <CircularProgress />
          </div>
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">Select One of the Templates</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={e => {
                setStartFromScratch(true);
                setSelectTemplateMode(true);
              }}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              className={"-striped -highlight "}
              color="primary"
              data={availableTemplates}
              columns={[
                {
                  Header: "VP Name",
                  accessor: "vendorProfileName",
                  resizable: false,
                  filterable: true,
                  Filter: ({ filter, onChange }) => getFilterUI("vendorProfileName", filter, onChange),
                  filterMethod,
                },
                {
                  Header: "Template Name",
                  accessor: "name",
                  resizable: false,
                  filterable: true,
                  Filter: ({ filter, onChange }) => getFilterUI("name", filter, onChange),
                  filterMethod,
                },
                {
                  Header: "Is Active",
                  accessor: "active",
                  resizable: false,
                  Cell: props => <div>{props.value ? "Yes" : "No"}</div>,
                  filterable: true,
                  Filter: ({ filter, onChange }) => getFilterUI("active", filter, onChange),
                  filterMethod: filterMethodActive,
                },
                {
                  Header: "Created On",
                  accessor: "createdDateTime",
                  resizable: false,
                  Cell: props => <div>{props.value ? convertToDate(+props.value) : ""}</div>,
                  filterable: true,
                  Filter: ({ filter, onChange }) => getFilterUI("createdDateTime", filter, onChange),
                  filterMethod: filterMethodDate,
                },
                {
                  Header: "Modified On",
                  accessor: "modifiedDateTime",
                  resizable: false,
                  Cell: props => <div>{props.value ? convertToDate(+props.value) : ""}</div>,
                  filterable: true,
                  Filter: ({ filter, onChange }) => getFilterUI("modifiedDateTime", filter, onChange),
                  filterMethod: filterMethodDate,
                },
                {
                  Header: "",
                  accessor: "",
                  resizable: false,
                  filterable: false,
                  sortable: false,
                  width: 80,
                  Cell: props => (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button color="primary" variant="contained" onClick={e => copyTemplateHandler(props.value)}>
                        Copy
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
