import React from "react";
import Grid from "@mui/material/Grid";
//components
import Table from "components/Table/Table";
import Tile from "../../components/Tile/Tile";

export default function TransactionSummary({ cs }) {
  const transactionSummary = cs.transactionSummary;
  const transactionSummaryBy = cs.transactionSummaryBy;

  let transactionSummaryTileData = {
    title: "Transaction Note From Dealer",
  };

  const transactionSummaryTableData = [
    ["Dealer Contact", transactionSummaryBy ? transactionSummaryBy : "N/A"],
    ["Transaction Summary", transactionSummary],
  ];
  return (
    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
      <Tile {...transactionSummaryTileData}>
        <Table tableHeaderColor="primary" tableData={transactionSummaryTableData} colorsColls={["primary"]} />
      </Tile>
    </Grid>
  );
}
