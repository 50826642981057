import React from "react";
import { grey } from "@mui/material/colors";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PaymentInfoRenderConfig } from "../../../types";
import { PaymentOptionsInfoItem } from "../PaymentOptionsInfoItem";

interface Props {
  renderConfig: PaymentInfoRenderConfig[];
}

export const ProfitDetails = ({ renderConfig }: Props) => {
  const classes = useStyles();

  if (!renderConfig.length) return null;

  return (
    <>
      <Box className={classes.panel}>
        <Typography component="span" variant="subtitle2">
          F&I Profit Detail
        </Typography>
      </Box>

      <Box className={classes.content}>
        {renderConfig.map(option => (
          <PaymentOptionsInfoItem key={option.label} {...option} />
        ))}
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  panel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  content: {
    gap: "6px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",

    "& .infoItem": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});
