import { useEffect, useState } from "react";
import { Grid, Switch, Typography } from "@mui/material";

interface Props {
  settings: any;
  itemConfiguration: any;
  setItemConfiguration: (prevItemConfiguration: any) => {};
}

export function Switcher({ settings, itemConfiguration, setItemConfiguration }: Props) {
  const [value, setValue] = useState<boolean>(!!itemConfiguration[settings.configurationKey]);

  useEffect(() => {
    setItemConfiguration((prevItemConfiguration: any) => ({
      ...prevItemConfiguration,
      [settings.configurationKey]: value,
    }));
  }, [value]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Typography>Off</Typography>
      </Grid>
      <Switch checked={value} onChange={() => setValue(prev => !prev)} color="primary" />
      <Grid item>
        <Typography>On</Typography>
      </Grid>
    </Grid>
  );
}
