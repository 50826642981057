import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { Grid, LinearProgress, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Tile from "../../../components/Tile/Tile";
import { ItemConfigurationPanel } from "../CommonComponents/ItemConfigurationPanel";
import { getDefaultPortalConfigurationByType } from "../../../services/pcwService";
import { configurableItemsTypes, portalConfigurationTypes } from "pages/Prequal/constants";
import _ from "lodash";
import { portalConfigurationsActions } from "../../../redux/portalConfigurationReducer";

const QUERY_PORTAL_CONFIGURATION = gql`
  query ($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

const SAVE_PORTAL_CONFIGURATION = gql`
  mutation ($input: UpsertPortalConfigurationInput!) {
    savePortalConfiguration(input: $input)
  }
`;

const AccessControlContainer = () => {
  const account = useSelector(state => state.account);
  const lenderProfiles = useSelector(state => state.lp);
  const [pcwConfigurations, setPCWConfigurations] = useState([]);
  const [configurableItemsSettings, setConfigurableItemsSettings] = useState([]);
  const [savePortalConfigurationSuccess, setSavePortalConfigurationSuccess] = useState(false);
  const [savePortalConfigurationError, setSavePortalConfigurationError] = useState(false);

  const accessControlPortalConfigurationTypes = [
    portalConfigurationTypes.scorecard,
    portalConfigurationTypes.leads,
    portalConfigurationTypes.opportunities,
    portalConfigurationTypes.userManagement,
    portalConfigurationTypes.securityManagement,
    portalConfigurationTypes.aftermarket,
    portalConfigurationTypes.healthCheck,
    portalConfigurationTypes.creditReports,
    portalConfigurationTypes.documents,
    portalConfigurationTypes.portalSetup,
    portalConfigurationTypes.prescreen,
    portalConfigurationTypes.submissionsHubManagement,
  ];

  const accessControlConfigurableItemsTypes = [
    configurableItemsTypes.scorecard,
    configurableItemsTypes.leads,
    configurableItemsTypes.opportunities,
    configurableItemsTypes.userManagement,
    configurableItemsTypes.securityManagement,
    configurableItemsTypes.aftermarket,
    configurableItemsTypes.healthCheck,
    configurableItemsTypes.creditReports,
    configurableItemsTypes.documents,
    configurableItemsTypes.portalSetup,
    configurableItemsTypes.prescreen,
    configurableItemsTypes.submissionsHubManagement,
  ];

  const configurableItemsTypesToPortalConfigurationTypesMap = {
    [configurableItemsTypes.scorecard]: portalConfigurationTypes.scorecard,
    [configurableItemsTypes.leads]: portalConfigurationTypes.leads,
    [configurableItemsTypes.opportunities]: portalConfigurationTypes.opportunities,
    [configurableItemsTypes.userManagement]: portalConfigurationTypes.userManagement,
    [configurableItemsTypes.securityManagement]: portalConfigurationTypes.securityManagement,
    [configurableItemsTypes.aftermarket]: portalConfigurationTypes.aftermarket,
    [configurableItemsTypes.healthCheck]: portalConfigurationTypes.healthCheck,
    [configurableItemsTypes.creditReports]: portalConfigurationTypes.creditReports,
    [configurableItemsTypes.documents]: portalConfigurationTypes.documents,
    [configurableItemsTypes.portalSetup]: portalConfigurationTypes.portalSetup,
    [configurableItemsTypes.prescreen]: portalConfigurationTypes.prescreen,
    [configurableItemsTypes.submissionsHubManagement]: portalConfigurationTypes.submissionsHubManagement,
  };

  const dispatch = useDispatch();

  const {
    data: portalConfigurationData,
    loading,
    error,
    refetch,
  } = useQuery(QUERY_PORTAL_CONFIGURATION, {
    variables: {
      vendorProfileId: account.vendorProfileId,
      types: accessControlPortalConfigurationTypes,
    },
    fetchPolicy: "no-cache",
  });

  const [savePortalConfiguration] = useMutation(SAVE_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    onCompleted() {
      setSavePortalConfigurationSuccess(true);
    },
    onError() {
      setSavePortalConfigurationError(true);
    },
  });

  const getConfigurableItemSettings = (configurableItemType, portalConfiguration) => {
    const getDefaultConfigurableItemSettings = (configurableItemType, portalConfiguration) => {
      return {
        item: portalConfiguration.jsonDefinition,
        type: configurableItemType,
        extraData: {
          portalConfigurationType: portalConfiguration.type,
        },
      };
    };

    const getScorecardConfigurableItemSettings = defaultConfigurableItemSettings => {
      const scorecardSpecificSettings = {
        extraData: {
          dataByConfigurationKey: {
            lenderProfileId: {
              options: lenderProfiles,
              getOptionLabelFn: lenderProfile => _.get(lenderProfile, "lenderName", ""),
              placeholder: "Choose Lender",
              optionKeyToSave: "lenderProfileId",
            },
          },
        },
      };
      return _.merge({}, defaultConfigurableItemSettings, scorecardSpecificSettings);
    };

    const getDocumentsConfigurableItemSettings = defaultConfigurableItemSettings => {
      const documentsSpecificSettings = {
        extraData: {
          dataByConfigurationKey: {
            creditAppHiddenForLenderProfileIds: {
              options: lenderProfiles,
              getOptionLabelFn: lenderProfile => _.get(lenderProfile, "lenderName", ""),
              //placeholder: "Choose Lenders",
              multiple: true,
              isInversed: true,
              optionKeyToSave: "lenderProfileId",
            },
          },
        },
        configurationKeysToDisplay: [
          "creditAppHiddenForLenderProfileIds",
          /* "creditReportsHiddenForLenderProfileIds", */
          "rolesSettings",
        ],
      };
      return _.merge({}, defaultConfigurableItemSettings, documentsSpecificSettings);
    };

    const getPrescreenConfigurableItemSettings = defaultConfigurableItemSettings => defaultConfigurableItemSettings;
    const getSubmissionsHubManagementConfigurableItemSettings = defaultConfigurableItemSettings =>
      defaultConfigurableItemSettings;

    const getSettingsByConfigurableItemTypeHandlers = {
      [configurableItemsTypes.scorecard]: getScorecardConfigurableItemSettings,
      [configurableItemsTypes.documents]: getDocumentsConfigurableItemSettings,
      [configurableItemsTypes.prescreen]: getPrescreenConfigurableItemSettings,
      [configurableItemsTypes.submissionsHubManagement]: getSubmissionsHubManagementConfigurableItemSettings,
    };

    const defaultConfigurableItemSettings = getDefaultConfigurableItemSettings(
      configurableItemType,
      portalConfiguration
    );

    return getSettingsByConfigurableItemTypeHandlers[configurableItemType]
      ? getSettingsByConfigurableItemTypeHandlers[configurableItemType](defaultConfigurableItemSettings)
      : defaultConfigurableItemSettings;
  };

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }

    const pcwConfigurationsToSet = _.map(accessControlPortalConfigurationTypes, portalConfigurationType => {
      const savedConfiguration = _.find(portalConfigurationData.portalConfigurations, {
        type: portalConfigurationType,
      });
      const configurationToSet = _.some(savedConfiguration)
        ? savedConfiguration
        : getDefaultPortalConfigurationByType(portalConfigurationType, account.vendorProfileId);
      delete configurationToSet.__typename;
      return configurationToSet;
    });

    setPCWConfigurations(pcwConfigurationsToSet);
  }, [portalConfigurationData]);

  useEffect(() => {
    if (_.isEmpty(pcwConfigurations)) {
      return;
    }

    const configurableItemsSettingsToSet = _.map(accessControlConfigurableItemsTypes, configurableItemType => {
      const portalConfigurationType = configurableItemsTypesToPortalConfigurationTypesMap[configurableItemType];
      const portalConfiguration = _.find(pcwConfigurations, { type: portalConfigurationType });
      return getConfigurableItemSettings(configurableItemType, portalConfiguration);
    });

    setConfigurableItemsSettings(configurableItemsSettingsToSet);
  }, [pcwConfigurations]);

  const saveItemConfig = async configurableItemSettings => {
    const portalConfigurationType = _.get(configurableItemSettings, "extraData.portalConfigurationType");

    const configurationToSave = _.find(pcwConfigurations, { type: portalConfigurationType });

    try {
      await savePortalConfiguration({
        variables: {
          input: {
            ...configurationToSave,
            jsonDefinition: configurableItemSettings.item,
          },
        },
      }).then(({ data }) => {
        if (configurationToSave.portalConfigurationId) return;

        setPCWConfigurations(prevPCWConfigurations => {
          return prevPCWConfigurations.map(prevPCWConfiguration => {
            if (prevPCWConfiguration.type === portalConfigurationType) {
              return {
                ...prevPCWConfiguration,
                portalConfigurationId: _.get(data, "savePortalConfiguration", null),
              };
            }
            return prevPCWConfiguration;
          });
        });
      });

      const { data } = await refetch();
      dispatch(portalConfigurationsActions.setConfigData(data?.portalConfigurations));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {loading && <LinearProgress />}

      {error && <h3>{String(error)}</h3>}

      <Grid container spacing={4}>
        {configurableItemsSettings.map(configurableItemSettings => (
          <Grid item sm={12} md={6} key={configurableItemSettings.type}>
            <Tile>
              <ItemConfigurationPanel
                configurableItemSettings={configurableItemSettings}
                onSaveFn={saveItemConfig}
                account={account}
              />
            </Tile>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={savePortalConfigurationSuccess}
        autoHideDuration={3000}
        onClose={() => setSavePortalConfigurationSuccess(state => !state)}>
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>

      <Snackbar
        open={savePortalConfigurationError}
        autoHideDuration={3000}
        onClose={() => setSavePortalConfigurationError(state => !state)}>
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccessControlContainer;
