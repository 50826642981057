import { Nullable } from "global";

export interface VendorOpportunity {
  createdOn: string;
  entityType: string;
  downPayment: number;
  notes: Nullable<any>;
  transactionId: string;
  dynamicsVOStage: number;
  vendorProfileId: string;
  fastTrackStatus: string;
  location: Nullable<any>;
  applicationStage: string;
  checklist: Nullable<any>;
  transactionStage: string;
  dynamicsVOStatus: number;
  orderDate: Nullable<any>;
  dealerMarkUpRate: number;
  salesTaxOnInvoice: number;
  voProposal: Nullable<any>;
  dealerMarkUpAmount: number;
  partnerLogo: Nullable<any>;
  partnerName: Nullable<any>;
  docFeeAmount: Nullable<any>;
  salesManager: Nullable<any>;
  sourceUrl: Nullable<string>;
  statusDetail: Nullable<any>;
  vendorOpportunityId: string;
  deliveryDate: Nullable<any>;
  invoiceTotal: Nullable<any>;
  invoiceStatus: Nullable<any>;
  equipmentCost: Nullable<any>;
  financeManager: Nullable<any>;
  deliveryStatus: Nullable<any>;
  creditLineNotes: Nullable<any>;
  tradeOwedBalanceAmount: number;
  roadHazardAmount: Nullable<any>;
  contactFormNotes: Nullable<any>;
  freightLaborInstallation: number;
  manufacturerRebateAmount: number;
  equipmentCondition: Nullable<any>;
  gapInsuranceAmount: Nullable<any>;
  equipmentModelYear: Nullable<any>;
  tradeAllowanceGrossTradeIn: number;
  equipmentDescription: Nullable<any>;
  expectedDeliveryDate: Nullable<any>;
  dynamicsVendorOpportunityId: string;
  creditLineStatus: CreditLineStatuses;
  accountProfileContact: Nullable<any>;
  extendedWarrantyAmount: Nullable<any>;
  federalExciseTaxAmount: Nullable<any>;
  roadsideCoverageAmount: Nullable<any>;
  equipmentAcceptanceDate: Nullable<any>;
  accountPrimaryContactId: Nullable<any>;
  equipmentApprovalStatus: Nullable<any>;
  partnerProfileDynamicsId: Nullable<any>;
  sourceVendorProfileId: Nullable<string>;
  lengthOfContractInMonths: Nullable<any>;
  accountPrimaryContactName: Nullable<any>;
  invoiceWarrantyAndInsuranceCharges: number;
  creditLineAmountRequested: Nullable<number>;
  physicalDamageCoverageAmount: Nullable<any>;
  potentialCustomer: Nullable<{ dynamicsAccountId: string; id: string; name: string }>;
  fastTrackResults: Nullable<
    {
      fastTrackId: string;
      rate: Nullable<any>;
      inputs: Nullable<any>;
      outputs: Nullable<any>;
      approvedBy: Nullable<any>;
      applicationStatus: Nullable<any>;
      creditSubmissionId: Nullable<any>;
      maxApprovalAmount: Nullable<any>;
    }[]
  >;
  salesRepresentative: Nullable<{
    dynamicsContactId: string;
    email: string;
    firstName: string;
    fullName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    title: string;
  }>;
}

// Credit Line

export enum CreditLineStatuses {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

// Documents

export interface VODocument {
  documentId: string;
  vendorOpportunityId: string;
  source: string;
  docType: string;
  docDescription: string;
  createdBy: string;
  createdDateTime: string;
  docName: string;
  documentCreditSubmission: {
    creditSubmissionId: string;
    lenderAccess: string;
  };
}

export interface VOTask {
  status: string;
  taskId: string;
  priority: string;
  regarding: string;
  assignedToUser: string;
  createdDateTime: string;
  completedDateTime: string;
}
