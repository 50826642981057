import ocaFieldsConfig from "../pages/DynamicOCA/config/fields_sectionized.json";
import referencesOCAFields from "../pages/DynamicOCA/config/References/referencesFields.json";
import equipmentDetailsOCAFields from "../pages/DynamicOCA/config/EquipmentDetails/equipmentDetailsFields.json";

// constants
import {
  applicantBlockTypes,
  coApplicantBlockTypes,
  documentsByKey,
  equipmentTypes,
  ocaSectionTypes,
  offersTypes,
  referenceTypes,
} from "../pages/Prequal/constants";

// libraries
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const ocaFields = _.flatMap(ocaFieldsConfig, "fieldList");

const complexFieldNamesSettersBySectionType = {
  [ocaSectionTypes.dynamicReference]: (fieldName, section) => getReferenceFieldName(fieldName, section.referenceIdx),
  [ocaSectionTypes.equipment]: (fieldName, section) => getEquipmentFieldName(fieldName, section.equipmentIdx),
  [ocaSectionTypes.coApplicant]: fieldName => getCoApplicantFieldName(fieldName),
};

const fieldsDefaultSettingsByRefType = {
  [referenceTypes.customer]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Company Name (Revenue Source)",
          es: "Nombre de la empresa (fuente de ingresos)",
        },
        required: true,
      },
    },
    contactName: {
      config: {
        required: true,
      },
    },
    contactPhone: {
      config: {
        required: true,
      },
    },
    contactFax: {
      config: {
        displayLabel: {
          en: "Fax #",
          es: "Número de fax",
        },
      },
    },
    productType: {
      config: {
        displayLabel: {
          en: "Product/Service/Frieght Type",
          es: "Producto/Servicio/Tipo de Carga",
        },
      },
    },
  },
  [referenceTypes.trade]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Trade Reference",
          es: "Referencia comercial",
        },
        required: true,
      },
    },
    contactName: {
      config: {
        required: true,
      },
    },
    contactFax: {
      config: {
        displayLabel: {
          en: "Fax #",
          es: "Número de fax",
        },
        required: true,
      },
    },
  },
  [referenceTypes.bank]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Bank",
          es: "Banco",
        },
        required: true,
      },
    },
    contactName: {
      config: {
        required: true,
      },
    },
    contactPhone: {
      config: {
        required: true,
      },
    },
  },
  [referenceTypes.loan]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Bank",
          es: "Banco",
        },
        required: true,
      },
    },
  },
  [referenceTypes.loc]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Bank",
          es: "Banco",
        },
        required: true,
      },
    },
  },
  [referenceTypes.insurance]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Insurance Company",
          es: "Compañía de seguros",
        },
        required: true,
      },
    },
    contactName: {
      config: {
        displayLabel: {
          en: "Agent Name",
          es: "Nombre de la agente",
        },
      },
    },
    contactPhone: {
      config: {
        displayLabel: {
          en: "Phone Number",
          es: "Número de teléfono",
        },
      },
    },
    contactFax: {
      config: {
        displayLabel: {
          en: "Fax Number",
          es: "Número de fax",
        },
      },
    },
    renewalDate: {
      config: {
        displayLabel: {
          en: "Policy Renewal Date",
          es: "Fecha de renovación de la póliza",
        },
      },
    },
    productType: {
      config: {
        displayLabel: {
          en: "Policy Type",
          es: "Tipo de póliza",
        },
      },
    },
  },
  [referenceTypes.employment]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Employer",
          es: "Empleador",
        },
      },
    },
    contactEmail: {
      config: {
        displayLabel: {
          en: "Employer Email",
          es: "Correo electrónico del empleador",
        },
      },
    },
    productType: {
      config: {
        displayLabel: {
          en: "Products Hauled",
          es: "Productos transportados",
        },
      },
    },
    years: {
      config: {
        displayLabel: {
          en: "Years At Job",
          es: "Años en el trabajo",
        },
      },
    },
    months: {
      config: {
        displayLabel: {
          en: "Additional Months at Job",
          es: "Meses adicionales en el trabajo",
        },
      },
    },
    salary: {
      config: {
        displayLabel: {
          en: "Gross Monthly Income",
          es: "Ingreso Bruto Mensual",
        },
      },
    },
  },
  [referenceTypes.employmentFuture]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Employer - Future",
          es: "Empleador - Futuro",
        },
      },
    },
    contactEmail: {
      config: {
        displayLabel: {
          en: "Employer Email",
          es: "Correo electrónico del empleador",
        },
      },
    },
    startDate: {
      config: {
        displayLabel: {
          en: "Planned Start Date",
          es: "Fecha de inicio planificada",
        },
      },
    },
    productType: {
      config: {
        displayLabel: {
          en: "Products Hauled",
          es: "Productos transportados",
        },
      },
    },
    salary: {
      config: {
        displayLabel: {
          en: "Gross Monthly Income",
          es: "Ingreso Bruto Mensual",
        },
      },
    },
  },
  [referenceTypes.employmentPast]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Previous Employer",
          es: "Empleador anterior",
        },
      },
    },
    contactEmail: {
      config: {
        displayLabel: {
          en: "Employer Email",
          es: "Correo electrónico del empleador",
        },
      },
    },
    years: {
      config: {
        displayLabel: {
          en: "Years At Job",
          es: "Años en el trabajo",
        },
      },
    },
    months: {
      config: {
        displayLabel: {
          en: "Additional Months at Job",
          es: "Meses adicionales en el trabajo",
        },
      },
    },
    salary: {
      config: {
        displayLabel: {
          en: "Gross Monthly Income",
          es: "Ingreso Bruto Mensual",
        },
      },
    },
  },
  [referenceTypes.corporateGuarantor]: {
    companyName: {
      config: {
        displayLabel: {
          en: "Legal Business Name",
          es: "Nombre comercial legal",
        },
        required: true,
      },
    },
    address: {
      config: {
        displayLabel: {
          en: "Business Address",
          es: "Dirección comercial",
        },
      },
    },
  },
};
const applicantFieldsDefaultSettingsByBlockType = {
  [applicantBlockTypes.contactInformation]: {
    middleName: {
      rules: [
        {
          evalType: "have_value",
          evalValue: ["individual"],
          targetName: "bizOrIndividual",
        },
      ],
    },
    nameSuffix: {
      rules: [
        {
          evalType: "have_value",
          evalValue: ["individual"],
          targetName: "bizOrIndividual",
        },
      ],
    },
  },
  [applicantBlockTypes.previousAddress]: {
    prevAddress: {
      config: {
        displayLabel: {
          en: "Address",
          es: "Dirección",
        },
        required: true,
      },
    },
    prevAddress2: {
      config: {
        displayLabel: {
          en: "Line 2",
          es: "Línea 2",
        },
        required: true,
      },
    },
    prevCity: {
      config: {
        displayLabel: {
          en: "City",
          es: "Ciudad",
        },
        required: true,
      },
    },
    prevState: {
      config: {
        displayLabel: {
          en: "State",
          es: "Estado",
        },
        required: true,
      },
    },
    prevCounty: {
      config: {
        displayLabel: {
          en: "County",
          es: "Condado",
        },
      },
    },
    prevPostalCode: {
      config: {
        displayLabel: {
          en: "Zip",
          es: "Código Postal",
        },
        required: true,
      },
    },
  },
};
const coApplicantFieldsDefaultSettingsByBlockType = applicantFieldsDefaultSettingsByBlockType;
const documentSignBlockFieldsDefaultSettings = {
  addendum: {
    config: {
      columns: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    },
  },
};
const dynamicSignaturesBlockFieldsDefaultSettings = {
  whoIsPresentToSign: {
    config: {
      useDropdown: true,
    },
  },
};
const personalGuaranteeSignaturesBlockFieldsDefaultSettings = {
  htmlOutput: {
    config: {
      selectedSource: "VP - Personal Guarantee Agreement Disclosure",
    },
    rules: [
      {
        evalType: "have_value",
        evalValue: ["Yes"],
        targetName: "areAnyPersonalGuarantorsPresent",
      },
    ],
  },
  whoIsPresentToSignForPersonalGuarantee: {
    config: {
      useDropdown: true,
      signaturesSignorsSetting: "auto-create-signature-task-for-each-signor",
    },
    rules: [
      {
        evalType: "have_value",
        evalValue: ["Yes"],
        targetName: "areAnyPersonalGuarantorsPresent",
      },
    ],
  },
  dynamicSignatureAndTitle: {
    config: {
      selectedApiId: "personalGuaranteeSignature",
      signaturesRequireAgreementsOfElectronicSignature: true,
    },
    rules: [
      {
        evalType: "have_value",
        evalValue: ["Yes"],
        targetName: "areAnyPersonalGuarantorsPresent",
      },
    ],
  },
};

const marketingCommunicationBlockFieldsDefaultSettings = {
  htmlOutput: {
    config: {
      selectedSource: "VP - Marketing Disclosure",
    },
  },
};

const offerFieldsDefaultSettingsByOfferType = {
  [offersTypes.workingCapital]: {
    htmlOutput: {
      config: {
        selectedApiId: "workingCapitalOfferContent",
      },
    },
    typedFileInput: {
      config: {
        defaultFileType: documentsByKey.bank_statements.value,
        helperText: {
          en: "Please upload your last 3 months bank statements.",
          es: "Cargue sus extractos bancarios de los últimos 3 meses.",
        },
      },
    },
  },
};

const getNewPageConfiguration = () => {
  return {
    id: uuidv4(),
    config: {},
    rules: [],
    rulesSatisfactionType: "all",
    sections: [],
  };
};

const getSimpleBlockConfiguration = () => {
  return {
    title: {
      id: uuidv4(),
      config: {
        text: "editTitle",
        fontSize: "20px",
        textAlign: "center",
        displayLabel: {
          en: "Edit Title",
          es: "Edite este Titulo",
        },
      },
    },
    fields: [],
    config: {},
    rulesSatisfactionType: "all",
    rules: [],
  };
};

const isOwnershipSection = sectionConfig => _.get(sectionConfig, "title.type") === "owners-array";

const checkIfDynamicReferenceSection = sectionConfig =>
  _.get(sectionConfig, "sectionType") === ocaSectionTypes.dynamicReference;

const checkIfCoApplicantSection = sectionConfig => _.get(sectionConfig, "sectionType") === ocaSectionTypes.coApplicant;

const checkIfDynamicSignaturesSection = sectionConfig =>
  _.get(sectionConfig, "sectionType") === ocaSectionTypes.dynamicSignatures;

const checkIfMarketingCommunicationSection = sectionConfig =>
  _.get(sectionConfig, "sectionType") === ocaSectionTypes.marketingCommunication;

const checkIfPersonalGuaranteeSignaturesSection = sectionConfig =>
  _.get(sectionConfig, "sectionType") === ocaSectionTypes.personalGuaranteeSignatures;

const checkIfDocumentSignSection = sectionConfig =>
  _.get(sectionConfig, "sectionType") === ocaSectionTypes.documentSign;

const checkIfLegacyReferenceSection = sectionConfig =>
  _.get(sectionConfig, "title.config.text") === ocaSectionTypes.legacyReference;

const checkIfEquipmentSection = sectionConfig => _.get(sectionConfig, "sectionType") === ocaSectionTypes.equipment;

const getOCAFieldsHashMap = ocaFields => _.keyBy(_.cloneDeep(ocaFields), "config.fieldName");

const getReferenceBlockConfiguration = (refType, templateConfig) => {
  const getReferenceIdx = templateConfig => {
    const maxReferenceIdx = _(templateConfig)
      .flatMap("sections")
      .filter({ sectionType: ocaSectionTypes.dynamicReference })
      .map("referenceIdx")
      .max();
    return _.isNil(maxReferenceIdx) ? 0 : maxReferenceIdx + 1;
  };

  const getDisplayLabelSetting = refType => {
    const titlesByRefType = {
      [referenceTypes.customer]: {
        en: "Customer Reference",
        es: "Referencia de cliente",
      },
      [referenceTypes.trade]: {
        en: "Trade Reference",
        es: "Referencia comercial",
      },
      [referenceTypes.bank]: {
        en: "Bank Reference",
        es: "Referencia bancaria",
      },
      [referenceTypes.loan]: {
        en: "Loan Reference",
        es: "Referencia de préstamo",
      },
      [referenceTypes.loc]: {
        en: "LOC Reference",
        es: "Referencia LOC",
      },
      [referenceTypes.insurance]: {
        en: "Insurance Reference",
        es: "Referencia de seguro",
      },
      [referenceTypes.employment]: {
        en: "Employment - Current Reference",
        es: "Empleo - Referencia actual",
      },
      [referenceTypes.employmentFuture]: {
        en: "Employment - Future Reference",
        es: "Empleo - Referencia Futuro",
      },
      [referenceTypes.employmentPast]: {
        en: "Employment - Past Reference",
        es: "Empleo - Referencia pasada",
      },
      [referenceTypes.corporateGuarantor]: {
        en: "Corporate Guarantor Information",
        es: "Información del garante corporativo",
      },
    };
    return titlesByRefType[refType] ? titlesByRefType[refType] : "Reference";
  };

  const getDefaultRules = refType => {
    const rulesByRefType = {
      [referenceTypes.insurance]: [
        {
          evalType: "have_value",
          evalValue: ["provide_own"],
          targetName: "providingInsurance",
        },
      ],
      [referenceTypes.corporateGuarantor]: [
        {
          evalType: "have_value",
          evalValue: ["yes"],
          targetName: "haveCorporateGuarantor",
        },
      ],
    };
    return rulesByRefType[refType] ? rulesByRefType[refType] : [];
  };

  const getDefaultFields = (refType, referenceIdx) => {
    const fieldsByRefType = {
      [referenceTypes.customer]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "contactFax",
        "percRevenue",
        "years",
        "months",
        "paidPerMile",
        "percentGross",
        "productType",
      ],
      [referenceTypes.trade]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "contactFax",
        "accountNum",
        "address",
        "city",
        "state",
        "zip",
      ],
      [referenceTypes.bank]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "accountNum",
        "address",
        "city",
        "state",
        "zip",
        "averageBalance",
      ],
      [referenceTypes.loan]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "accountNum",
        "originalLoanAmt",
        "currentBalance",
        "address",
        "city",
        "state",
        "zip",
        "yearMakeModel",
        "paidOff",
        "trade",
      ],
      [referenceTypes.loc]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "accountNum",
        "currentBalance",
        "monthlyPayment",
        "renewalDate",
        "address",
        "city",
        "state",
        "zip",
        "averageBalance",
        "securedBy",
      ],
      [referenceTypes.insurance]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "contactFax",
        "renewalDate",
        "physicalDamageDeduction",
        "liabilityCoverage",
        "productType",
      ],
      [referenceTypes.employment]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "address",
        "city",
        "state",
        "zip",
        "jobTitle",
        "years",
        "months",
        "industry",
        "salary",
        "productType",
        "driverType",
      ],
      [referenceTypes.employmentFuture]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "address",
        "city",
        "state",
        "zip",
        "jobTitle",
        "startDate",
        "industry",
        "salary",
        "monthlyRevenue",
        "productType",
        "okayToContact",
        "futureEmployerSameAsCurrent",
        "monthlyMiles",
      ],
      [referenceTypes.employmentPast]: [
        "companyName",
        "contactName",
        "contactPhone",
        "contactEmail",
        "address",
        "city",
        "state",
        "zip",
        "jobTitle",
        "years",
        "months",
        "industry",
        "salary",
      ],
      [referenceTypes.corporateGuarantor]: ["companyName", "address", "taxId", "yearBusinessEstablished"],
    };
    return getReferenceFieldsOCAConfiguration(fieldsByRefType[refType], refType, referenceIdx);
  };

  const referenceIdx = getReferenceIdx(templateConfig);
  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(refType),
      },
    },
    rulesSatisfactionType: "all",
    rules: getDefaultRules(refType),
    fields: getDefaultFields(refType, referenceIdx),
    config: { ownerIdx: "not-set" },
    sectionType: ocaSectionTypes.dynamicReference,
    referenceType: refType,
    referenceIdx,
  };
};

const getEquipmentDetailsBlockConfiguration = (equipmentType, templateConfig) => {
  const getEquipmentIdx = templateConfig => {
    const maxEquipmentIdx = _(templateConfig)
      .flatMap("sections")
      .filter({ sectionType: ocaSectionTypes.equipment })
      .map("equipmentIdx")
      .max();
    return _.isNil(maxEquipmentIdx) ? 0 : maxEquipmentIdx + 1;
  };

  const getDisplayLabelSetting = equipmentType => {
    const titlesByEquipmentType = {
      [equipmentTypes.purchase]: {
        en: "Equipment Purchase",
        es: "Compra de equipos",
      },
      [equipmentTypes.tradeIn]: {
        en: "Trade-In Equipment",
        es: "Equipo de intercambio",
      },
    };
    return titlesByEquipmentType[equipmentType] ? titlesByEquipmentType[equipmentType] : "Equipment Details";
  };

  const getDefaultFields = (equipmentType, equipmentIdx) => {
    const fieldsByEquipmentType = {
      [equipmentTypes.purchase]: [
        "quantity",
        "newUsed",
        "year",
        "make",
        "model",
        "mileage",
        "hours",
        "serialNumberVin",
        "stockId",
        "retailPrice",
        "additionalDescription",
      ],
      [equipmentTypes.tradeIn]: [
        "doYouHaveATradeIn",
        "quantity",
        "newUsed",
        "year",
        "make",
        "model",
        "mileage",
        "hours",
        "serialNumberVin",
        "stockId",
        "retailPrice",
        "tradeInValue",
        "amountOwedOnTradeIn",
        "additionalDescription",
      ],
    };
    const fieldsKeys = fieldsByEquipmentType[equipmentType];
    return getEquipmentFieldsOCAConfiguration(fieldsKeys, equipmentType, equipmentIdx);
  };

  const equipmentIdx = getEquipmentIdx(templateConfig);
  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(equipmentType),
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(equipmentType, equipmentIdx),
    config: {},
    sectionType: ocaSectionTypes.equipment,
    equipmentType: equipmentType,
    equipmentIdx,
  };
};

const getOffersBlockConfiguration = offerType => {
  const getDisplayLabelSetting = offerType => {
    const titlesByOfferType = {
      [offersTypes.workingCapital]: {
        en: "Working Capital",
        es: "Capital de trabajo",
      },
    };
    return titlesByOfferType[offerType] ? titlesByOfferType[offerType] : "Offer";
  };

  const getDefaultFields = offerType => {
    const fieldsByOfferType = {
      [offersTypes.workingCapital]: [
        "htmlOutput",
        "workingCapitalRequestedAmount",
        "annualRevenue",
        "averageDailyBalance",
        "workingCapitalOffer",
        "typedFileInput",
      ],
    };
    const fieldsKeys = fieldsByOfferType[offerType];
    const fieldSpecificSettingsByKeys = offerFieldsDefaultSettingsByOfferType[offerType];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(fieldsKeys, fieldSpecificSettingsByKeys, ocaFieldsHashMap);
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(offerType),
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(offerType),
    config: {},
  };
};

const getApplicantBlockConfiguration = blockType => {
  const getDisplayLabelSetting = blockType => {
    const titlesByBlockType = {
      [applicantBlockTypes.contactInformation]: {
        en: "Contact Information",
        es: "Información del contacto",
      },
      [applicantBlockTypes.currentAddress]: {
        en: "Current Address",
        es: "Direccion actual",
      },
      [applicantBlockTypes.previousAddress]: {
        en: "Previous Address",
        es: "Dirección anterior",
      },
      [applicantBlockTypes.employmentInformation]: {
        en: "Employment Information",
        es: "Informacion de Empleo",
      },
      [applicantBlockTypes.nearestRelative]: {
        en: "Nearest Relative",
        es: "Pariente más cercana",
      },
      [applicantBlockTypes.identityVerification]: {
        en: "Identity Verification",
        es: "Verificación de identidad",
      },
    };
    return titlesByBlockType[blockType] ? titlesByBlockType[blockType] : "";
  };

  const getDefaultFields = blockType => {
    const fieldsByBlockType = {
      [applicantBlockTypes.contactInformation]: ["firstName", "middleName", "lastName", "nameSuffix", "phone", "email"],
      [applicantBlockTypes.currentAddress]: [
        "homeAddress",
        "address2",
        "homeCity",
        "homeState",
        "homePostalCode",
        "addressCounty",
        "homeType",
        "homePhone",
        "timeAtAddressYears",
        "timeAtAddressMonths",
        "monthlyHousingPayment",
        "billingAddressDifferentFromBusinessAddress",
      ],
      [applicantBlockTypes.previousAddress]: [
        "prevAddress",
        "prevAddress2",
        "prevCity",
        "prevState",
        "prevCounty",
        "prevPostalCode",
      ],
      [applicantBlockTypes.employmentInformation]: [
        "employerName",
        "title",
        "timeAtJobYears",
        "timeAtJobMonths",
        "employerAddress",
        "employerCity",
        "employerState",
        "employerPhone",
        "employerEmail",
        "grossMonthlyIncome",
      ],
      [applicantBlockTypes.nearestRelative]: [
        "nearestRelativeFirstName",
        "nearestRelativeLastName",
        "nearestRelativePhone",
        "nearestRelativeCity",
        "nearestRelativeZip",
      ],
      [applicantBlockTypes.identityVerification]: [
        "firstName",
        "lastName",
        "dateOfBirth",
        "ssn",
        "uploadDLQuestion",
        "fileInput",
        "signatureDate",
      ],
    };
    const fieldsKeys = fieldsByBlockType[blockType];
    const fieldSpecificSettingsByKeys = applicantFieldsDefaultSettingsByBlockType[blockType];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(fieldsKeys, fieldSpecificSettingsByKeys, ocaFieldsHashMap);
  };

  const getDefaultRules = blockType => {
    const rulesByBlockType = {
      [applicantBlockTypes.previousAddress]: [
        {
          evalType: "lt",
          evalValue: 3,
          targetName: "timeAtAddressYears",
        },
      ],
    };
    return rulesByBlockType[blockType] ? rulesByBlockType[blockType] : [];
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(blockType),
      },
    },
    rulesSatisfactionType: "all",
    rules: getDefaultRules(blockType),
    fields: getDefaultFields(blockType),
    config: {},
    sectionType: ocaSectionTypes.common,
  };
};

const getCoApplicantBlockConfiguration = blockType => {
  const getDisplayLabelSetting = blockType => {
    const titlesByBlockType = {
      [coApplicantBlockTypes.contactInformation]: {
        en: "Co-Applicant Contact Information",
        es: "Información de contacto del cosolicitante",
      },
      [coApplicantBlockTypes.currentAddress]: {
        en: "Co-Applicant Current Address",
        es: "Dirección actual del cosolicitante",
      },
      [coApplicantBlockTypes.previousAddress]: {
        en: "Co-Applicant Previous Address",
        es: "Dirección anterior del cosolicitante",
      },
      [coApplicantBlockTypes.employmentInformation]: {
        en: "Co-Applicant Employment Information",
        es: "Información de empleo del cosolicitante",
      },
      [coApplicantBlockTypes.nearestRelative]: {
        en: "Nearest Relative Of The Co-Applicant Who Doesn't Live With Him",
        es: "Pariente más cercano del cosolicitante que no vive con él",
      },
      [coApplicantBlockTypes.identityVerification]: {
        en: "Co-Applicant Identity Verification",
        es: "Verificación de identidad del cosolicitante",
      },
    };
    return titlesByBlockType[blockType] ? titlesByBlockType[blockType] : "Co-applicant";
  };

  const getDefaultFields = blockType => {
    const fieldsByBlockType = {
      [coApplicantBlockTypes.contactInformation]: [
        "firstName",
        "middleName",
        "lastName",
        "nameSuffix",
        "phone",
        "email",
      ],
      [coApplicantBlockTypes.currentAddress]: [
        "homeAddress",
        "address2",
        "homeCity",
        "homeState",
        "homePostalCode",
        "addressCounty",
        "homeType",
        "homePhone",
        "timeAtAddressYears",
        "timeAtAddressMonths",
        "monthlyHousingPayment",
        "billingAddressDifferentFromBusinessAddress",
      ],
      [coApplicantBlockTypes.previousAddress]: [
        "prevAddress",
        "prevAddress2",
        "prevCity",
        "prevState",
        "prevCounty",
        "prevPostalCode",
      ],
      [coApplicantBlockTypes.employmentInformation]: [
        "employerName",
        "title",
        "timeAtJobYears",
        "timeAtJobMonths",
        "employerAddress",
        "employerCity",
        "employerState",
        "employerPhone",
        "employerEmail",
        "grossMonthlyIncome",
      ],
      [coApplicantBlockTypes.nearestRelative]: [
        "nearestRelativeFirstName",
        "nearestRelativeLastName",
        "nearestRelativePhone",
        "nearestRelativeCity",
        "nearestRelativeZip",
      ],
      [coApplicantBlockTypes.identityVerification]: [
        "firstName",
        "lastName",
        "dateOfBirth",
        "ssn",
        "uploadDLQuestion",
        "fileInput",
        "signatureDate",
      ],
    };

    return getCoApplicantFieldsOCAConfiguration(fieldsByBlockType[blockType], blockType);
  };

  const getDefaultRules = blockType => {
    const defaultRules = [
      {
        evalType: "have_value",
        evalValue: ["yes"],
        targetName: "haveCoApplicant",
      },
    ];

    const rulesByBlockType = {
      [coApplicantBlockTypes.previousAddress]: [
        {
          evalType: "lt",
          evalValue: 3,
          targetName: "owners[1].timeAtAddressYears",
        },
      ],
    };
    return rulesByBlockType[blockType] ? _.concat(defaultRules, rulesByBlockType[blockType]) : defaultRules;
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(blockType),
      },
    },
    rulesSatisfactionType: "all",
    rules: getDefaultRules(blockType),
    fields: getDefaultFields(blockType),
    config: {},
    sectionType: ocaSectionTypes.coApplicant,
  };
};

const getDynamicSignatureBlockConfiguration = () => {
  const getDisplayLabelSetting = () => {
    return {
      en: "Authorization And Acceptance",
      es: "Autorización y Aceptación",
    };
  };

  const getDefaultFields = () => {
    const fieldsKeys = ["whoIsPresentToSign", "dynamicSignatureAndTitle"];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(fieldsKeys, dynamicSignaturesBlockFieldsDefaultSettings, ocaFieldsHashMap);
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(),
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(),
    config: { requireTermsAndConditionsView: false },
    sectionType: ocaSectionTypes.dynamicSignatures,
  };
};

const getPersonalGuaranteeSignaturesBlockConfiguration = () => {
  const getDisplayLabelSetting = () => {
    return {
      en: "Personal Guarantee of Corporate Obligations",
      es: "Garantía Personal de Obligaciones Corporativas",
    };
  };

  const getDefaultFields = () => {
    const fieldsKeys = [
      "areAnyPersonalGuarantorsPresent",
      "htmlOutput",
      "whoIsPresentToSignForPersonalGuarantee",
      "dynamicSignatureAndTitle",
    ];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(
      fieldsKeys,
      personalGuaranteeSignaturesBlockFieldsDefaultSettings,
      ocaFieldsHashMap
    );
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(),
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(),
    config: {},
    sectionType: ocaSectionTypes.personalGuaranteeSignatures,
  };
};

const getMarketingCommunicationBlockConfiguration = () => {
  const getDefaultFields = () => {
    const fieldsKeys = ["htmlOutput", "marketingDisclosures"];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(
      fieldsKeys,
      marketingCommunicationBlockFieldsDefaultSettings,
      ocaFieldsHashMap
    );
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: {
          en: "Marketing Communication Authorization",
          es: "Autorización de comunicación de marketing",
        },
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(),
    config: {
      displayed: true,
    },
    sectionType: ocaSectionTypes.marketingCommunication,
  };
};

const getDocumentSignBlockConfiguration = () => {
  const getDisplayLabelSetting = () => {
    return {
      en: "Document To Sign",
      es: "Documento a firmar",
    };
  };

  const getDefaultFields = () => {
    const fieldsKeys = ["whoIsPresentToSignDocument", "addendum"];
    const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
    return getCommonFieldsOCAConfiguration(fieldsKeys, documentSignBlockFieldsDefaultSettings, ocaFieldsHashMap);
  };

  return {
    title: {
      id: uuidv4(),
      config: {
        fontSize: "20px",
        textAlign: "center",
        displayLabel: getDisplayLabelSetting(),
      },
    },
    rulesSatisfactionType: "all",
    rules: [],
    fields: getDefaultFields(),
    config: {
      pdfTemplateId: null,
      requireAcceptanceToProceed: true,
      requireSigning: true,
      tasksAutoCreation: true,
    },
    sectionType: ocaSectionTypes.documentSign,
  };
};

const getReferencesBlockList = () => ({ title: "Dynamic References", fieldList: referencesOCAFields });

const getEquipmentDetailsBlockList = () => ({ title: "Equipment Details", fieldList: equipmentDetailsOCAFields });

const getReferenceFieldName = (fieldName, referenceIdx) => `references.${referenceIdx}.${fieldName}`;

const getEquipmentFieldName = (fieldName, equipmentIdx) => `equipments.${equipmentIdx}.${fieldName}`;

const getCoApplicantFieldName = fieldName => `owners[1].${fieldName}`;

const getCommonFieldsOCAConfiguration = (fieldsKeys, fieldSpecificSettingsByKeys, ocaFieldsHashMap) => {
  return _.map(fieldsKeys, key => {
    const field = ocaFieldsHashMap[key];
    field.id = uuidv4(); // OCA requires each field to have a uniq id
    return fieldSpecificSettingsByKeys && fieldSpecificSettingsByKeys[key]
      ? _.merge(field, fieldSpecificSettingsByKeys[key])
      : field;
  });
};

const getReferenceFieldsOCAConfiguration = (fieldsKeys, refType, referenceIdx) => {
  const fieldSpecificSettingsByKeys = fieldsDefaultSettingsByRefType[refType];
  const ocaFieldsHashMap = getOCAFieldsHashMap(referencesOCAFields);
  const referenceFieldsOCAConfiguration = getCommonFieldsOCAConfiguration(
    fieldsKeys,
    fieldSpecificSettingsByKeys,
    ocaFieldsHashMap
  );
  return _.forEach(referenceFieldsOCAConfiguration, refField => {
    _.set(refField, "config.fieldName", getReferenceFieldName(refField.config.fieldName, referenceIdx));
  });
};

const getCoApplicantFieldsOCAConfiguration = (fieldsKeys, blockType) => {
  const fieldSpecificSettingsByKeys = coApplicantFieldsDefaultSettingsByBlockType[blockType];
  const ocaFieldsHashMap = getOCAFieldsHashMap(ocaFields);
  const fieldsOCAConfiguration = getCommonFieldsOCAConfiguration(
    fieldsKeys,
    fieldSpecificSettingsByKeys,
    ocaFieldsHashMap
  );
  return _.forEach(fieldsOCAConfiguration, field => {
    _.set(field, "config.fieldName", getCoApplicantFieldName(field.config.fieldName));
  });
};

const getEquipmentFieldsOCAConfiguration = (fieldsKeys, equipmentType, equipmentIdx) => {
  const getFieldsDefaultSettingsByEquipmentType = (equipmentType, equipmentIdx) => {
    const tradeInFieldsDefaultRules = [
      {
        evalType: "have_value",
        evalValue: [true],
        targetName: getEquipmentFieldName("doYouHaveATradeIn", equipmentIdx),
      },
    ];
    const fieldsDefaultSettingsByEquipmentType = {
      [equipmentTypes.tradeIn]: {
        quantity: {
          rules: tradeInFieldsDefaultRules,
        },
        newUsed: {
          rules: tradeInFieldsDefaultRules,
        },
        year: {
          rules: tradeInFieldsDefaultRules,
        },
        make: {
          rules: tradeInFieldsDefaultRules,
        },
        model: {
          rules: tradeInFieldsDefaultRules,
        },
        mileage: {
          rules: tradeInFieldsDefaultRules,
        },
        hours: {
          rules: tradeInFieldsDefaultRules,
        },
        serialNumberVin: {
          rules: tradeInFieldsDefaultRules,
        },
        stockId: {
          rules: tradeInFieldsDefaultRules,
        },
        retailPrice: {
          rules: tradeInFieldsDefaultRules,
        },
        additionalDescription: {
          rules: tradeInFieldsDefaultRules,
        },
        tradeInValue: {
          rules: tradeInFieldsDefaultRules,
        },
        amountOwedOnTradeIn: {
          rules: tradeInFieldsDefaultRules,
        },
      },
    };
    return fieldsDefaultSettingsByEquipmentType[equipmentType];
  };
  const fieldSpecificSettingsByKeys = getFieldsDefaultSettingsByEquipmentType(equipmentType, equipmentIdx);
  const ocaFieldsHashMap = getOCAFieldsHashMap(equipmentDetailsOCAFields);
  const fieldsOCAConfiguration = getCommonFieldsOCAConfiguration(
    fieldsKeys,
    fieldSpecificSettingsByKeys,
    ocaFieldsHashMap
  );
  return _.forEach(fieldsOCAConfiguration, field => {
    _.set(field, "config.fieldName", getEquipmentFieldName(field.config.fieldName, equipmentIdx));
  });
};

export {
  // methods common
  isOwnershipSection,
  checkIfDynamicReferenceSection,
  checkIfCoApplicantSection,
  checkIfLegacyReferenceSection,
  checkIfDocumentSignSection,
  checkIfDynamicSignaturesSection,
  checkIfPersonalGuaranteeSignaturesSection,
  checkIfEquipmentSection,
  checkIfMarketingCommunicationSection,
  complexFieldNamesSettersBySectionType,
  getNewPageConfiguration,
  getSimpleBlockConfiguration,
  // methods references
  getReferenceBlockConfiguration,
  getReferencesBlockList,
  getReferenceFieldName,
  getReferenceFieldsOCAConfiguration,
  // methods equipment details
  getEquipmentDetailsBlockConfiguration,
  getEquipmentDetailsBlockList,
  getEquipmentFieldsOCAConfiguration,
  // methods applicant
  getApplicantBlockConfiguration,
  // methods co-applicant
  getCoApplicantBlockConfiguration,
  getCoApplicantFieldName,
  getCoApplicantFieldsOCAConfiguration,
  // methods signatures
  getDynamicSignatureBlockConfiguration,
  // methods p ersonal guarantee
  getPersonalGuaranteeSignaturesBlockConfiguration,
  // methods document-sign
  getDocumentSignBlockConfiguration,
  // methods offers
  getOffersBlockConfiguration,
  // constants
  getMarketingCommunicationBlockConfiguration,
};
