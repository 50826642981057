import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useHistory } from "react-router-dom";
import {
  BusinessRule,
  ConditionSettingsBoolean,
  ConditionSettingsNumeric,
  ConditionSettingsString,
  FACT_NAMES_BOOLEAN,
  FACT_NAMES_NUMERIC,
  FACT_NAMES_STRING,
} from "@trnsact/business-criteria";
import _ from "lodash";
import {
  AftermarketProduct,
  AftermarketProductCategory,
  AftermarketProductType,
  CANADA_REGION_CODES,
  CreateAftermarketProductInput,
  EQUIPMENT_CONDITION,
  FINANCING_TYPE,
  LEGAL_STRUCTURE,
  US_REGION_CODES,
} from "@trnsact/trnsact-shared-types";
import ChipInput from "components/Generic/ChipInput/ChipInput";
import { AFTERMARKET_PRODUCT_EVENTS } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/aftermarket-product-events";
import { MARKUP_TYPE } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/markup-type";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AftermarketProductManagementHelpers } from "./helpers";
import ReactQuill from "react-quill";
import { M_CREATE_AFTERMARKET_PRODUCTS, Q_AFTERMARKET_PRODUCTS, Q_PARTNER_LINKS } from "./api";
import { PricingSection } from "./PricingSection";
import { Configuration, ConfigurationRow, FactBooleanType, FactNumericType, FactStringType } from "./types";

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 3) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

const formatConditionToSave = (addedCondition: any, factTypes: string[]) => {
  if (
    factTypes.includes(_.get(addedCondition, "conditionSettings.fact")) &&
    !_.get(addedCondition, "conditionSettings.exactMatch").every((item: any) => typeof item === "string")
  ) {
    return addedCondition.conditionSettings.exactMatch.map((item: any) => item.code);
  }
  return addedCondition.conditionSettings.exactMatch;
};
const formatNames = (name: string) => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const getStringFactInitialValues = (configRow: any): string[] => {
  const value = _.get(configRow, `conditionSettings.exactMatch`, [] as string[]);
  if (_.isArray(value)) {
    return value;
  }
  if (_.isString(value) && value !== "") {
    return [value];
  }
  return [];
};

const aftermarketProductCategoryList = Object.keys(AftermarketProductCategory)
  .map(key => ({
    value: AftermarketProductCategory[key as keyof typeof AftermarketProductCategory],
    label: capitalize(AftermarketProductCategory[key as keyof typeof AftermarketProductCategory]),
  }))
  .sort((a, b) => {
    if (a.value === AftermarketProductCategory.None) {
      return 1;
    }
    if (b.value === AftermarketProductCategory.None) {
      return -1;
    }
    return 0;
  });

const factNamesNumeric: FactNumericType[] = Object.keys(FACT_NAMES_NUMERIC).map(key => ({
  value: FACT_NAMES_NUMERIC[key as keyof typeof FACT_NAMES_NUMERIC],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesBoolean: FactBooleanType[] = Object.keys(FACT_NAMES_BOOLEAN).map(key => ({
  value: FACT_NAMES_BOOLEAN[key as keyof typeof FACT_NAMES_BOOLEAN],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesString: FactStringType[] = Object.keys(FACT_NAMES_STRING).map(key => ({
  value: FACT_NAMES_STRING[key as keyof typeof FACT_NAMES_STRING],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const booleanOperatorNames = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const productCatalogQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"], // ,'strike', 'blockquote'
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    // ['link', 'image'],
    // ['clean']
  ],
};

export const AddAftermarketProductPage = () => {
  const account = useSelector((state: any) => state.account);

  const classes = useStyles();

  const { loading: loadingAftermarketProducts, data: { aftermarketProducts } = { aftermarketProducts: [] } } =
    useQuery<{ aftermarketProducts: AftermarketProduct[] }>(Q_AFTERMARKET_PRODUCTS, {
      fetchPolicy: "no-cache",
      variables: { accountId: account.id },
    });

  const [createAftermarketProduct] = useMutation(M_CREATE_AFTERMARKET_PRODUCTS, {
    context: { authRequired: true },
  });

  const [getPartnerLinks, { data: partnerLinks, loading: loadingPartnerLinks }] = useLazyQuery(Q_PARTNER_LINKS, {
    variables: {
      accountId: account.id,
    },
  });

  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  const [availableFacts] = useState<(FactBooleanType | FactNumericType | FactStringType)[]>([
    ...factNamesBoolean,
    ...factNamesNumeric,
    ...factNamesString,
  ]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedVendor, setSelectedVendor] = useState<[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AftermarketProduct[]>([]);

  const [availableVendors, setAvailableVendors] = useState<[]>([]);
  const [productName, setProductName] = useState<string>("");
  const [externalId] = useState<string | null>(null);
  const [productType, setProductType] = useState<string>(AftermarketProductCategory.Gap);
  const [baseOrOption, setBaseOrOption] = useState<AftermarketProductType>(AftermarketProductType.Product);
  const isPartnerAccount = account.accountType === "Partner";

  const [productSummary, setProductSummary] = useState<any>(null);
  const [limitationsOfLiability, setLimitationsOfLiability] = useState<any>(null);
  const [eligibilityAndInclusions, setEligibilityAndInclusions] = useState<any>(null);
  const [legalDisclaimer, setLegalDisclaimer] = useState<any>(null);
  const [paragraphDescription, setParagraphDescription] = useState<any>(null);
  const [coverageDetails, setCoverageDetails] = useState<any>(null);

  const [productLogo, setProductLogo] = useState<File | null>(null);
  const [productLogoPreview, setProductLogoPreview] = useState<string | null>(null);

  const handleProductLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductLogoPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setProductLogoPreview(null);
    }

    setProductLogo(selectedFile);
  };

  const history = useHistory();

  useEffect(() => {
    if (!_.isEmpty(partnerLinks)) {
      const dataReceived = _.get(partnerLinks, "aftermarketDealerPartnerLink", []);
      if (dataReceived?.length > 0) {
        setAvailableVendors(
          dataReceived.map((pl: any) => ({
            label: AftermarketProductManagementHelpers.getPartnerLinkLabel(pl),
            value: pl.partnerLinkId,
          }))
        );
      }
    } else {
      getPartnerLinks();
    }
  }, [partnerLinks, getPartnerLinks]);

  const handleSaveAftermarketProduct = async (e: any) => {
    e.preventDefault();

    let rules = [];

    rules = configurations.map(config => {
      const rule = new BusinessRule({
        name: config.name,
        eventParams: {
          type: AFTERMARKET_PRODUCT_EVENTS.AFTERMARKET_PRODUCT_QUALIFIED,
          params: {
            DEALER_COST: config.dealerCost,
            MARKUP: {
              AMOUNT: config.dealerMarkup,
              TYPE: config.dealerMarkupType,
            },
            RATE_FACTOR: config.rateFactor,
            PRICING_MAX: config.maxPricing,
            PRICING_MIN: config.minPricing,
            SUGGESTED_RETAIL_PRICE: config.suggestedRetailPricing,
          },
        },
      });

      config.configRows.forEach((cond: any) => {
        let addedCondition = _.cloneDeep(cond);

        addedCondition.conditionSettings.exactMatch = formatConditionToSave(addedCondition, [
          "CANADA_REGION",
          "USA_STATE",
          "LEGAL_STRUCTURE",
          "FINANCING_TYPE",
          "EQUIPMENT_CONDITION",
        ]);

        addedCondition.conditionSettings.requiredForQuote = true;

        rule.Conditions.addCondition(addedCondition.conditionSettings);
      });

      return rule;
    });

    try {
      const input: CreateAftermarketProductInput = {
        criteria: rules.map(rule => rule.getRule()),
        partnerLinkIds: selectedVendor.map((vendor: any) => vendor.value),
        productCategory: productType as AftermarketProductCategory, // Yes, productType is the category
        productName,
        productType: baseOrOption,
        externalId: externalId,
        addonIds: selectedOptions.map(option => option.aftermarketProductId),
        productLogo,
        aftermarketProductCatalogDetails: {
          productSummary: productSummary,
          limitationsOfLiability: limitationsOfLiability,
          eligibilityAndInclusions: eligibilityAndInclusions,
          legalDisclaimer: legalDisclaimer,
          paragraphDescription: paragraphDescription,
          coverageDetails: coverageDetails,
        },
      };

      await createAftermarketProduct({
        variables: {
          input,
        },
      });
      history.push("/aftermarket");
    } catch (error) {
      console.log(error);
    }
  };
  const getNextRowElements = (
    configurationIndex: number
  ): ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString | null => {
    const chosenConfiguration = configurations[configurationIndex];
    //Pick configRows from the configuration that are not already selected
    const selectedFacts = new Set(chosenConfiguration.configRows.map(configRow => configRow.conditionSettings.fact));
    const remainingNumericFacts = factNamesNumeric.filter(fact => !selectedFacts.has(fact.value));
    const remainingBooleanFacts = factNamesBoolean.filter(fact => !selectedFacts.has(fact.value));
    const remainingStringFacts = factNamesString.filter(fact => !selectedFacts.has(fact.value));

    if (remainingNumericFacts.length > 0) {
      return { fact: remainingNumericFacts[0].value, minValue: null, maxValue: null };
    } else if (remainingBooleanFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    } else if (remainingStringFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    }

    return null;
  };

  const handleChangeValue = (event: any, configurationIndex: number, rowIndex: number | null, fieldName: string) => {
    if (rowIndex === null) {
      setConfigurations(prev =>
        prev.map((config, index) => {
          if (index !== configurationIndex) return config;
          return { ...config, [fieldName]: event.target.value };
        })
      );
    } else {
      const updateConfigurations = _.cloneDeep(configurations);

      const updatedRows = [...configurations[configurationIndex].configRows];
      // String inputs
      if (_.has(updatedRows, `[${rowIndex}].conditionSettings.exactMatch`)) {
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, event.target.value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
      } else {
        let value = _.isEmpty(event.target.value) ? null : event.target.value;
        if (isNaN(value)) {
          switch (value) {
            case "true":
              value = true;
              break;
            case "false":
              value = false;
              break;
            default:
              return;
          }
        }
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
      }

      setConfigurations(updateConfigurations);
    }
  };

  const handleAddProductionConfiguration = () => {
    //Default initial values per config
    setConfigurations([
      ...configurations,
      {
        name: configurations.length === 0 ? "Base Configuration" : `Configuration ${configurations.length + 1}`,
        configRows: [],
        dealerCostSource: "api",
        dealerCost: 0,
        rateFactor: 0,
        dealerMarkup: 0,
        dealerMarkupType: MARKUP_TYPE.PERCENTAGE,
        minPricing: 0,
        maxPricing: 0,
        suggestedRetailPricing: 0,
        type: AftermarketProductType.Product,
      },
    ]);
  };
  const handleChangeFact = (
    event: SelectChangeEvent<FACT_NAMES_NUMERIC | FACT_NAMES_BOOLEAN | FACT_NAMES_STRING>,
    configurationIndex: number,
    rowIndex: number
  ) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const updatedRows = [...newCriteriaRows];
    const isBoolean = Object.keys(FACT_NAMES_BOOLEAN).includes(String(event.target.value));
    const isNumeric = Object.keys(FACT_NAMES_NUMERIC).includes(String(event.target.value));

    if (isBoolean) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_BOOLEAN,
        value: false,
      };
    } else if (isNumeric) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_NUMERIC,
        minValue: null,
        maxValue: null,
      };
    } else {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_STRING,
        exactMatch: "",
      };
    }
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
    setConfigurations(updateConfigurations);
  };

  const updateBaseOrOption = (type: AftermarketProductType) => {
    if (type === AftermarketProductType.Addon && activeTab === 1) {
      setActiveTab(0);
    }
    setBaseOrOption(type);
  };

  const renderNumericInputByFactType = (fact: string) => {
    switch (fact) {
      case FACT_NAMES_NUMERIC.DEAL_SIZE:
        return CustomCurrencyInput;
      default:
        return undefined;
    }
  };

  const renderStringInputByFactType = (configurationIndex: number, rowIndex: number, configRow: any) => {
    switch (configRow.conditionSettings.fact) {
      case FACT_NAMES_STRING.EQUIPMENT_CONDITION:
        const equipmentConditionOptions = Object.keys(EQUIPMENT_CONDITION).map(key => ({
          code: key,
          label: EQUIPMENT_CONDITION[key as keyof typeof EQUIPMENT_CONDITION],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, equipmentConditionOption) => {
              return handleChangeValue(
                {
                  target: {
                    value: equipmentConditionOption.map(equipCond => equipCond.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={equipmentConditionOptions}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select the Equipment Condition" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.FINANCING_TYPE:
        const listToRenderFinancingType = Object.keys(FINANCING_TYPE).map(key => ({
          code: key,
          label: FINANCING_TYPE[key as keyof typeof FINANCING_TYPE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, financingTypes) => {
              return handleChangeValue(
                {
                  target: {
                    value: financingTypes.map(financingType => financingType.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderFinancingType}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Financing Type" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.LEGAL_STRUCTURE:
        const listToRenderLegalStructure = Object.keys(LEGAL_STRUCTURE).map(key => ({
          label: key,
          code: LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, legalStructures) => {
              return handleChangeValue(
                {
                  target: {
                    value: legalStructures.map(legalStructure => legalStructure.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderLegalStructure}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Legal Structure" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.CANADA_REGION:
        const canadaSelectedValues = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderCanadaStates = _.cloneDeep(
          Object.keys(CANADA_REGION_CODES).map(key => ({
            label: formatNames(key),
            code: CANADA_REGION_CODES[key as keyof typeof CANADA_REGION_CODES],
          }))
        );

        return (
          <Autocomplete
            id="canada-state-autocomplete"
            key={"canada-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderCanadaStates}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={canadaSelectedValues}
            renderInput={params => <TextField {...params} label="Select a Region" variant="outlined" />}
          />
        );

      case FACT_NAMES_STRING.USA_STATE:
        const contained = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderStates = Object.keys(US_REGION_CODES).map(key => ({
          label: formatNames(key),
          code: US_REGION_CODES[key as keyof typeof US_REGION_CODES],
        }));

        return (
          <Autocomplete
            id="usa-state-autocomplete"
            key={"usa-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderStates}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={contained}
            renderInput={params => <TextField {...params} label="Select a State" variant="outlined" />}
          />
        );
      default:
        const initialValues = getStringFactInitialValues(configRow);
        const stringFactInput = (
          <ChipInput
            id={`exact-match-${rowIndex}`}
            onChange={(id: string, value: string[]) =>
              handleChangeValue({ target: { value } }, configurationIndex, rowIndex, "exactMatch")
            }
            value={_.clone(initialValues)}
          />
        );
        return stringFactInput;
    }
  };

  const currentRowIsBooleanFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_BOOLEAN).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsNumericFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_NUMERIC).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsStringFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_STRING).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const getAvailableFactsForRow = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const selectedFacts = new Set(newCriteriaRows.map(configRow => configRow.conditionSettings.fact));
    const currentFact = newCriteriaRows[rowIndex].conditionSettings.fact;
    selectedFacts.delete(currentFact);

    return availableFacts.filter(fact => !selectedFacts.has(fact.value));
  };

  const handleAddMoreCondition = (configurationIndex: number) => {
    const baseCondition = getNextRowElements(configurationIndex);
    if (!baseCondition) {
      alert("No more available facts to add.");
      return;
    }
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = [
      ...previousRows,
      {
        conditionSettings: baseCondition,
      },
    ];
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };

  const handleRemoveConfigurationRow = (configurationIndex: number) => {
    const previousConfigurations = _.cloneDeep(configurations);
    const updatedConfigurations = previousConfigurations.filter((_, i) => i !== configurationIndex);
    setConfigurations(updatedConfigurations);
  };

  const handleRemoveConditionRow = (configurationIndex: number, rowIndex: number) => {
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = previousRows.filter((_, i) => i !== rowIndex);
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };
  if (loadingAftermarketProducts || loadingPartnerLinks) return <div>Loading...</div>;
  return (
    <Grid container spacing={3}>
      {/* Header */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <ArrowBackIosIcon
              fontSize="small"
              cursor={1}
              onClick={() => {
                history.push("/aftermarket");
              }}
            />
            Add Aftermarket Product
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={
              selectedVendor === null ||
              productName === "" ||
              (configurations.length === 0 && account.accountType !== "Partner")
            }
            onClick={handleSaveAftermarketProduct}
            style={{ float: "right", marginBottom: "10px" }}>
            Save
          </Button>
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>
      {/* Step 1: Aftermarket Vendor Section */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary">
            <LooksOneIcon /> {isPartnerAccount ? "Dealer" : "Aftermarket Vendor"}
          </Typography>

          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={[] as any}
            onChange={(event, newValues) => {
              setSelectedVendor(newValues as any);
            }}
            options={availableVendors}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => (
              <TextField
                {...params}
                label={`Select the ${isPartnerAccount ? "Dealers that have access to this product" : "Vendor"}`}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      {/* Step 2: Product Configuration Section */}
      {selectedVendor && (
        <>
          <Paper style={{ padding: "20px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">
                <LooksTwoIcon /> Product Configuration
              </Typography>

              <TextField
                fullWidth
                label="Product Name"
                variant="outlined"
                margin="normal"
                size="small"
                value={productName}
                onChange={e => setProductName(e.target.value)}
                required
              />

              <Grid container spacing={0}>
                <Grid item xs={1}>
                  <Button variant="contained" component="label">
                    Set Logo
                    <input type="file" accept="image/*" hidden onChange={handleProductLogoChange} />
                  </Button>
                </Grid>
                {productLogoPreview && (
                  <Grid item xs={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Typography>New Icon</Typography>
                      <img
                        src={productLogoPreview}
                        alt="Selected product logo"
                        style={{
                          maxWidth: "100%",
                          maxHeight: 64,
                          marginTop: 8,
                          border: "1px solid #ccc",
                          borderRadius: 4,
                        }}
                      />
                      <IconButton color="primary" size="large">
                        <DeleteOutlineIcon
                          color="error"
                          onClick={() => {
                            setProductLogo(null);
                            setProductLogoPreview(null);
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Typography style={{ paddingTop: "8px" }}>Product Type</Typography>
              <RadioGroup
                row
                value={productType}
                onChange={e => setProductType(e.target.value as AftermarketProductCategory)}>
                {aftermarketProductCategoryList.map(({ value, label }) => (
                  <FormControlLabel key={value} value={value} control={<Radio color="primary" />} label={label} />
                ))}
              </RadioGroup>

              <Typography>Product or Addon?</Typography>
              <RadioGroup
                row
                value={baseOrOption}
                onChange={e => updateBaseOrOption(e.target.value as AftermarketProductType)}>
                <FormControlLabel
                  key={AftermarketProductType.Product}
                  value={AftermarketProductType.Product}
                  control={<Radio color="primary" />}
                  label={"Product"}
                />
                <FormControlLabel
                  key={AftermarketProductType.Addon}
                  value={AftermarketProductType.Addon}
                  control={<Radio color="primary" />}
                  label={"Addon"}
                />
              </RadioGroup>
            </Grid>
            <Divider variant="fullWidth" />
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} variant="scrollable">
              <Tab label={`Base ${baseOrOption === AftermarketProductType.Product ? "Product" : "Option"}`} />
              <Tab label="Addons" disabled={baseOrOption === AftermarketProductType.Addon} />
              <Tab label="Product Catalog" />
            </Tabs>

            {/* Product Configuration Details - What goes under "Base Products" tab */}
            {activeTab === 0 && (
              <Grid item xs={12}>
                {configurations.length === 0 && (
                  <Typography variant="body1">
                    Click on "Add Product Configuration" to start adding configurations.
                  </Typography>
                )}
                {configurations.map((configuration: Configuration, configurationIndex: number) => {
                  return (
                    <Box
                      key={`configuration_${configurationIndex}`}
                      border={1}
                      borderColor="grey.400"
                      borderRadius="4px"
                      padding={2}>
                      <Grid container alignItems="center">
                        <IconButton color="primary" size="large">
                          <DeleteOutlineIcon
                            color="error"
                            onClick={() => {
                              handleRemoveConfigurationRow(configurationIndex);
                            }}
                          />
                        </IconButton>
                        <Typography variant="body1">
                          [Configuration #{configurationIndex + 1}] - Product <b>{productName}</b>
                        </Typography>
                      </Grid>
                      {configuration.configRows.map((configRow: ConfigurationRow, rowIndex: number) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            key={rowIndex}
                            justifyContent="flex-start"
                            alignContent="flex-start"
                            alignItems="center">
                            <span style={{ border: "1px dashed #DEDEDE", borderRadius: "50%" }}>
                              <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => {
                                  handleRemoveConditionRow(configurationIndex, rowIndex);
                                }}>
                                <RemoveCircleIcon fontSize="small" color="error" />
                              </IconButton>
                            </span>
                            <Grid item xs={5}>
                              <FormControl fullWidth variant="standard">
                                <InputLabel id={`new-fact-label-${rowIndex}`}>Rule</InputLabel>
                                <Select
                                  labelId={`new-fact-label-${rowIndex}`}
                                  id={`new-fact-${rowIndex}`}
                                  value={configRow.conditionSettings.fact}
                                  onChange={e => handleChangeFact(e, configurationIndex, rowIndex)}
                                  label="Rule">
                                  {getAvailableFactsForRow(configurationIndex, rowIndex).map((fact, index) => (
                                    <MenuItem key={index} value={fact.value}>
                                      {fact.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {currentRowIsBooleanFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={4}>
                                  <FormControl fullWidth variant="standard" size="small">
                                    <InputLabel id={`new-operator-label-${rowIndex}`}></InputLabel>
                                    <Select
                                      labelId={`new-operator-label-${rowIndex}`}
                                      id={`new-operator-${rowIndex}`}
                                      value={_.get(configRow, "conditionSettings.value")}
                                      onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "value")}
                                      label="Option">
                                      {booleanOperatorNames.map(element => (
                                        <MenuItem key={element.label} value={String(element.value)}>
                                          {element.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                            {currentRowIsNumericFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={2}>
                                  <InputLabel id={`min-value-label-${rowIndex}`}>Min Value</InputLabel>
                                  <TextField
                                    id={`min-value-${rowIndex}`}
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    value={_.get(configRow, `conditionSettings.minValue`)}
                                    onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "minValue")}
                                    fullWidth
                                    slotProps={{
                                      input: {
                                        inputComponent: renderNumericInputByFactType(configRow.conditionSettings.fact),
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <InputLabel id={`max-value-label-${rowIndex}`}>Max Value</InputLabel>
                                  <TextField
                                    id={`max-value-${rowIndex}`}
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    value={_.get(configRow, `conditionSettings.maxValue`)}
                                    onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "maxValue")}
                                    fullWidth
                                    slotProps={{
                                      input: {
                                        inputComponent: renderNumericInputByFactType(configRow.conditionSettings.fact),
                                      },
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {currentRowIsStringFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={4}>
                                  {renderStringInputByFactType(configurationIndex, rowIndex, configRow)}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        );
                      })}
                      <Grid container justifyContent="center" spacing={6}>
                        <Grid item xs={12}>
                          {_.isEmpty(configuration.configRows) ? "No conditions added yet." : ""}
                        </Grid>
                        <Grid item>
                          <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleAddMoreCondition(configurationIndex);
                            }}
                            size="small">
                            ADD MORE
                          </Button>
                        </Grid>
                      </Grid>

                      <Divider />

                      <PricingSection
                        handleChangeValue={handleChangeValue}
                        configurationIndex={configurationIndex}
                        configuration={configurations[configurationIndex]}
                      />
                    </Box>
                  );
                })}
              </Grid>
            )}

            {/* Options Details */}
            {activeTab === 1 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  <LooksOneIcon /> Select Options on this Product
                </Typography>

                <Autocomplete
                  size="small"
                  multiple={true}
                  defaultValue={[] as any}
                  onChange={(event, newValues) => {
                    setSelectedOptions(newValues as any);
                  }}
                  options={aftermarketProducts.filter(
                    (product: AftermarketProduct) => product.productType === AftermarketProductType.Addon
                  )}
                  isOptionEqualToValue={(option, value) => option.productName === value.productName}
                  getOptionLabel={(option: AftermarketProduct) => `${capitalize(option.productName)}`}
                  renderInput={params => (
                    <TextField {...params} label="Select Options on this Product" variant="outlined" />
                  )}
                />
              </Grid>
            )}

            {/* Product Catalog Information*/}
            {activeTab === 2 && (
              <Box>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="primary">
                    <LooksOneIcon /> Product Catalog Information
                  </Typography>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Product Summary
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      style={{ minHeight: "300px" }}
                      theme="snow"
                      value={productSummary}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setProductSummary(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Limitations of Liability
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={limitationsOfLiability}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setLimitationsOfLiability(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Eligibility & Inclusions
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={eligibilityAndInclusions}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setEligibilityAndInclusions(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Legal Disclaimer
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={legalDisclaimer}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setLegalDisclaimer(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.catalogInformationGridContainer}>
                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Paragraph Description
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      theme="snow"
                      value={paragraphDescription}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setParagraphDescription(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.catalogInformationGridItem}>
                    <InputLabel id={"guidelines"} className={classes.catalogInformationLabel}>
                      Coverage Details
                    </InputLabel>
                    <ReactQuill
                      className={classes.catalogInformationQuillInput}
                      style={{ minHeight: "300px" }}
                      theme="snow"
                      value={coverageDetails}
                      onChange={(content, delta, source, editor) => {
                        const currentDelta = editor.getContents();
                        setCoverageDetails(currentDelta);
                      }}
                      modules={productCatalogQuillModules}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
        </>
      )}
      {selectedVendor && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddProductionConfiguration}>
            ADD PRODUCT CONFIGURATION
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  catalogInformationGridContainer: {
    paddingTop: "24px",
  },
  catalogInformationGridItem: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  catalogInformationLabel: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  catalogInformationQuillInput: {
    "& .ql-editor": {
      minHeight: "300px", // Set your desired minimum height for the content area
    },
  },
});
