import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GET_PROPOSAL_MENUS, UPDATE_PROPOSAL_MENU } from "modules/aftermarketMenuConstructor/api";
import { ProposalsDataResponse } from "modules/aftermarketMenuConstructor/api/types";
import {
  MenuTemplatesTableFilters,
  ProposalMenuSearchableColumns,
  ProposalMenuSearchableColumnsEnum,
} from "modules/aftermarketMenuConstructor/types";
import { menuTemplatesTableInitialFilters } from "modules/aftermarketMenuConstructor/constants";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { ProposalMenu, ProposalProduct } from "@trnsact/trnsact-shared-types";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { FiltersPanel } from "components/shared/FiltersPanel";
import ConfirmationDialog from "components/ConfirmationDialog";
import _ from "lodash";
import { MenuTemplatesTable } from "../../tables";

export const MenuTemplatesPage = () => {
  const classes = useStyles();

  const history = useHistory();

  const [proposalMenusToShow, setProposalMenusToShow] = useState<ProposalMenu[]>();
  const [tableFilters, setTableFilters] = useState<MenuTemplatesTableFilters>(menuTemplatesTableInitialFilters);
  const [proposalMenuToDelete, setProposalMenuToDelete] = useState<ProposalMenu>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { hasWriteAccess, hasReadOnlyAccess } = useAftermarketPermissions();

  const {
    data: proposalMenusData,
    loading,
    refetch: refetchProposalMenus,
  } = useQuery<ProposalsDataResponse>(GET_PROPOSAL_MENUS);
  const [updateProposalMenu] = useMutation(UPDATE_PROPOSAL_MENU);

  useEffect(() => {
    if (!proposalMenusData?.proposalMenus.length) {
      return;
    }
    let proposalMenusToShow = tableFilters.search
      ? proposalMenusData.proposalMenus?.filter((proposalMenu: ProposalMenu) =>
          Object.values(ProposalMenuSearchableColumnsEnum).some((column: ProposalMenuSearchableColumns) =>
            proposalMenu[column]?.toLowerCase().includes(tableFilters.search.toLowerCase())
          )
        )
      : proposalMenusData.proposalMenus;
    proposalMenusToShow = _.orderBy(proposalMenusToShow, [(p: ProposalMenu) => new Date(p.createdDateTime!)], "desc");
    setProposalMenusToShow(proposalMenusToShow);
  }, [proposalMenusData?.proposalMenus, tableFilters]);

  const onEditProposalMenu = (proposalMenu: ProposalMenu) => {
    history.push(`/aftermarket/menu/edit/${proposalMenu.proposalMenuId}`);
  };

  const onDeleteProposalMenu = (proposalMenuToDelete: ProposalMenu) => {
    setProposalMenuToDelete(proposalMenuToDelete);
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = async (event: any, reason: string) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      await updateProposalMenu({
        variables: {
          input: {
            proposalMenuId: proposalMenuToDelete!.proposalMenuId,
            archived: true,
          },
        },
      });
      await refetchProposalMenus();
    }
  };

  return (
    <>
      {hasWriteAccess || hasReadOnlyAccess ? (
        <>
          <CardContainer
            title="Menu Templates"
            containerClassName={classes.menuTemplatesContainerClassName}
            actionBtn={
              hasWriteAccess && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={() => history.push(`/aftermarket/menu/new`)}>
                  Add Template
                </Button>
              )
            }>
            <FiltersPanel
              onSearchChange={(filter, nextValue) => {
                setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
              }}
            />
            <Box mt={2}>
              <MenuTemplatesTable
                loading={loading}
                onEditProposalMenu={onEditProposalMenu}
                onDeleteProposalMenu={onDeleteProposalMenu}
                tableData={proposalMenusToShow ?? []}
              />
            </Box>
          </CardContainer>

          <ConfirmationDialog
            title="Confirmation"
            message="Are you sure you want to remove Menu?"
            open={isConfirmationModalOpen}
            onClose={onConfiramtionModalClose}
          />
        </>
      ) : (
        <h3>Access Denied</h3>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  menuTemplatesContainerClassName: {
    marginTop: 6,
  },
  actionBtn: {
    borderRadius: "4px",
  },
}));
