import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const translateRuleSatisfactionType = code => {
  switch (code) {
    case "all":
      return "All of these rules need to be satisfied";
    case "at_least_one":
      return "At least one rule needs to be satisfied";
    case "exactly_one":
      return "Exactly one rule should be satisfied";
    case "none":
      return "None of the rules can be satisfied";

    default: {
      console.log("Code not found");
    }
  }
};
const translateDefaultRuleEvaluatorType = code => {
  switch (code) {
    case "have_value":
      return "The parent field has to have any value in order to display the dependent field";
    case "have_not_value":
      return "The parent field has to have no any value in order to display the dependent field";
    case "contain":
      return "The parent field has to contain the value defined in the dependent field in order to display the dependent field. This operator is case sensitive";
    case "eq":
      return "The value in the parent field has to be equal to the value in the dependent field";
    case "lt":
      return "The value in the parent field has to be less than the value in the dependent field (works only with numeric fields)";
    case "gt":
      return "The value in the parent field has to be more than the value in the dependent field (works only with numeric fields)";
    case "gte":
      return "The value in the parent field has to be more or equal to the value in the dependent field (works only with numeric fields)";
    case "lte":
      return "The value in the parent field has to be less or equal the value in the dependent field (works only with numeric fields)";
    case "neq":
      return "Not Equal";

    default: {
      console.log("Code not found");
    }
  }
};

const translateRuleEvaluatorType = rule => {
  switch (rule.type) {
    case undefined:
    case "default":
      return translateDefaultRuleEvaluatorType(rule.evalType);
    case "autofill":
      return "The parent field has to satisfy the rule in order to autofill dependent field";
    case "required":
      return "The parent field has to satisfy the rule in order to consider the dependent field as required";

    default: {
      console.log("Type not found");
    }
  }
};

const useStyles = makeStyles(() => ({
  satisfactionLevel: {
    textTransform: "uppercase",
    fontWeight: "bolder",
  },
}));

export const RuleTooltipDescription = styled(({ className, ...props }) => {
  const classes = useStyles();
  const { rulesSatisfactionType, ...rest } = props;
  return (
    <Tooltip
      {...rest}
      title={
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item>
              <TableContainer component={Paper}>
                <Table aria-label="rules table">
                  <TableHead>
                    <TableRow>
                      <TableCell colspan={3} align="center">
                        <span className={classes.satisfactionLevel}>
                          {translateRuleSatisfactionType(rulesSatisfactionType)}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Parent Field</TableCell>
                      <TableCell align="center">Dependency Expression</TableCell>
                      <TableCell align="center">Target Value(s)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.rules.map(rule => {
                      return (
                        <TableRow key={rule.targetName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row" align="center">
                            {rule.targetName}
                          </TableCell>
                          <TableCell align="center">{translateRuleEvaluatorType(rule)}</TableCell>
                          <TableCell align="center">{JSON.stringify(rule.evalValue)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      }
      classes={{ popper: className }}
      leaveDelay={2000}
    />
  );
})(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    border: "1px solid #dadde9",
    maxWidth: "30vw",
    fontSize: 12,
  },
}));
