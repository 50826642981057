import React from "react";
import { useFormContext } from "react-hook-form";
import { CurrencyInputField } from "components/form";
import { FieldPath } from "react-hook-form/dist/types/path";
import { InvoiceFormValues } from "../../../model";
import { invoiceCalculations } from "../../../lib";

export const CreateUpdateInvoiceForm = () => {
  const { getValues, control, setValue } = useFormContext<InvoiceFormValues>();

  const updateInvoiceTotal = () => {
    const {
      netTrade,
      downPayment,
      equipmentCost,
      salesTaxOnInvoice,
      freightLaborInstallation,
      manufacturerRebateAmount,
      invoiceWarrantyAndInsuranceCharges,
    } = getValues();

    const totalFees = invoiceCalculations.totalFees({
      manufacturerRebateAmount,
      freightLaborInstallation,
      invoiceWarrantyAndInsuranceCharges,
    });

    const commonParams = { equipmentCost, salesTax: salesTaxOnInvoice, totalFees, netRate: netTrade };

    setValue("invoiceTotal", invoiceCalculations.invoiceTotal(commonParams));
    setValue("netFinanceAmount", invoiceCalculations.netFinanceAmount({ ...commonParams, downPayment }));
  };

  const updateNetTrade = () => {
    const { tradeAllowanceGrossTradeIn, tradeOwedBalanceAmount } = getValues();
    const nextNetRate = invoiceCalculations.netRate({ tradeAllowanceGrossTradeIn, tradeOwedBalanceAmount });

    setValue("netTrade", nextNetRate);
  };

  const handleUpdateValueWithDependence = (
    field: FieldPath<InvoiceFormValues>,
    nextValue: number,
    deps: VoidFunction[]
  ) => {
    setValue(field, nextValue);
    deps.forEach(fn => fn());
  };

  return (
    <>
      <CurrencyInputField
        control={control}
        name="equipmentCost"
        label="Equipment Subtotal"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        name="salesTaxOnInvoice"
        label="Sales Tax (if applicable)"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        label="Warranty and Insurance Charges"
        name="invoiceWarrantyAndInsuranceCharges"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        name="freightLaborInstallation"
        label="Other Charges (Installation, shipping, etc)"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        label="Trade Value (Allowance)"
        name="tradeAllowanceGrossTradeIn"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateNetTrade, updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        label="Trade Owed"
        name="tradeOwedBalanceAmount"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateNetTrade, updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField label="Net Trade" name="netTrade" control={control} textFieldProps={{ disabled: true }} />

      <CurrencyInputField
        label="Rebate"
        control={control}
        name="manufacturerRebateAmount"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        name="invoiceTotal"
        label="Invoice Total"
        textFieldProps={{ disabled: true }}
      />

      <CurrencyInputField
        control={control}
        name="downPayment"
        label="Down Payment"
        customOnChange={(nextValue, name) => {
          handleUpdateValueWithDependence(name, nextValue, [updateInvoiceTotal]);
        }}
      />

      <CurrencyInputField
        control={control}
        name="netFinanceAmount"
        label="Net finance amount"
        textFieldProps={{ disabled: true }}
      />
    </>
  );
};
