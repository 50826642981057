import { FieldNotify, Nullable } from "global";
import {
  maxApprovalGreteThenNetFinance,
  maxApprovalGreteThenNetFinanceTooltip,
  maxApprovalLessOrEqualThenNetFinance,
} from "../constants";

export function determineMaxApprovalAmountNotification(
  financeAmount: number,
  submissionAmount: Nullable<number>
): FieldNotify {
  if (financeAmount === null || submissionAmount === null) {
    return { hide: true, message: "" };
  }

  if (submissionAmount < financeAmount) {
    return {
      variant: "error",
      message: maxApprovalGreteThenNetFinance,
      iconTooltip: maxApprovalGreteThenNetFinanceTooltip,
    };
  }

  return {
    variant: "info",
    withIcon: false,
    message: maxApprovalLessOrEqualThenNetFinance,
  };
}
