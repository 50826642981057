import React from "react";
import { Column } from "react-table";
import { DefaultTableCell } from "components/Table/DefaultTableCell";
import { PriorityCell, StatusCell } from "components/TasksCommon/tableCells";
import { VOTask } from "../../types";

export function createTableColumns(): Array<Column<VOTask>> {
  return [
    {
      sortable: false,
      Header: "Created On",
      accessor: "createdDateTime",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Assigned To",
      accessor: "assignedToUser",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Regarding",
      accessor: "regarding",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Completed On",
      accessor: "completedDateTime",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      sortable: false,
      Header: "Status",
      accessor: "status",
      Cell: ({ original: task }: any) => <StatusCell task={task} />,
    },
    {
      sortable: false,
      Header: "Priority",
      accessor: "priority",
      Cell: ({ original: task }: any) => <PriorityCell task={task} />,
    },
  ];
}
