import {
  IntegrationSettingsDealerNumberByLocationForm,
  IntegrationSettingsFormInputs,
} from "modules/aftermarketPartner/types";
import { createTableColumns } from "./tableConfig";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Table } from "components/shared/Table";

export const IntegrationSettingsTable = () => {
  const { control } = useFormContext<IntegrationSettingsFormInputs>();

  const { fields: dealerNumberByLocation } = useFieldArray({
    control,
    name: "dealerNumberByLocation",
  });

  const columns = createTableColumns();

  return <Table<IntegrationSettingsDealerNumberByLocationForm> tableData={dealerNumberByLocation} columns={columns} />;
};
