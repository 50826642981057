import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const GET_WORKFLOWS = gql`
  query GetWorkflows {
    workflows {
      id
      name
      description
      active
      json
      createdOn
      modifiedOn
      lastModifiedBy {
        fullName
      }
    }
  }
`;

interface Workflow {
  id: string;
  name: string;
  active: boolean;
  description: string;
  json: any;
  createdOn: string;
  modifiedOn: string;
  lastModifiedBy: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "24px",
  },
  tableContainer: {
    marginTop: "16px",
  },
  addButton: {
    marginBottom: "16px",
  },
  searchBox: {
    marginBottom: "16px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },
}));

const WorkflowsListPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Workflow | undefined>("name");
  const [filteredRows, setFilteredRows] = useState<Workflow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rows, setRows] = useState<Workflow[]>([]); // Store the original rows

  const { loading, error } = useQuery(GET_WORKFLOWS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      if (data && data.workflows) {
        setRows(data.workflows);
        setFilteredRows(data.workflows);
      }
    },
  });

  useEffect(() => {
    const lowerCaseQuery = searchTerm.toLowerCase();
    setFilteredRows(
      rows.filter(
        row =>
          row.name.toLowerCase().includes(lowerCaseQuery) ||
          row.description.toLowerCase().includes(lowerCaseQuery) ||
          _.get(row, "json.conditions", []).join(", ").toLowerCase().includes(lowerCaseQuery)
      )
    );
  }, [searchTerm, rows]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Workflow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedRows = [...filteredRows].sort((a, b) => {
      const aValue = _.get(a, property, "");
      const bValue = _.get(b, property, "");

      if (aValue < bValue) return isAsc ? -1 : 1;
      if (aValue > bValue) return isAsc ? 1 : -1;
      return 0;
    });

    setFilteredRows(sortedRows);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (id: string) => {
    history.push(`/workflows/edit/${id}`);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              className={classes.addButton}
              onClick={() => history.push("/workflows/add")}>
              Add Workflow
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TextField
        variant="outlined"
        placeholder="Search"
        fullWidth
        className={classes.searchBox}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={event => handleRequestSort(event, "name")}>
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "description"}
                  direction={orderBy === "description" ? order : "asc"}
                  onClick={event => handleRequestSort(event, "description")}>
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "json"}
                  direction={orderBy === "json" ? order : "asc"}
                  onClick={event => handleRequestSort(event, "json")}>
                  Event
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "createdOn"}
                  direction={orderBy === "createdOn" ? order : "asc"}
                  onClick={event => handleRequestSort(event, "createdOn")}>
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "modifiedOn"}
                  direction={orderBy === "modifiedOn" ? order : "asc"}
                  onClick={event => handleRequestSort(event, "modifiedOn")}>
                  Modified
                </TableSortLabel>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  <Chip
                    label={row.active ? "ON" : "OFF"}
                    style={{ backgroundColor: row.active ? "#66BB6A" : "#EF5350", color: "white" }}
                  />
                </TableCell>
                <TableCell>Opportunity Stage is changed: {_.get(row, "json.conditions", []).join(" OR ")}</TableCell>
                <TableCell>{row.createdOn}</TableCell>
                <TableCell>
                  {row.modifiedOn} By <strong>{row.lastModifiedBy.fullName}</strong>
                </TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(row.id)} size="large">
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default WorkflowsListPage;
