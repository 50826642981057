import React, { useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Search from "@mui/icons-material/Search";
import { FormInput, MultiSelect } from "../form";
import { SelectOption } from "../../global";

type BaseOption = { [key: string]: { label: string; values: SelectOption[] } };

interface Props<Options> {
  options: Options;
  onSearchChange: (filter: "search", nextValue: string) => void;
  onOptionsChange: (filter: keyof Options, nextValue: string[]) => void;
}

export const TableFiltersPanel = <Options extends BaseOption>({
  onSearchChange,
  onOptionsChange,
  options,
}: Props<Options>) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const [optionsValue, setOptionsValue] = useState<Partial<Record<keyof Options, string[]>>>(() => {
    return Object.keys(options).reduce<Partial<Record<keyof Options, string[]>>>((acc, key) => {
      const typedKey = key as keyof Options;

      acc[typedKey] = ["all"];
      return acc;
    }, {});
  });

  const classes = useStyles();

  const handleSelectChange = (key: keyof Options, selected: string[]) => {
    const isAllAfterOther = selected.includes("all") && selected.length > 1 && selected[selected.length - 1] === "all";
    const isOtherAfterAll = selected.includes("all") && selected.length > 1;

    let newSelected = selected;

    switch (true) {
      case isAllAfterOther:
        newSelected = ["all"];
        break;
      case isOtherAfterAll:
        newSelected = selected.filter(value => value !== "all");
        break;
      default:
        newSelected = selected;
    }

    setOptionsValue(prev => ({ ...prev, [key]: newSelected }));
    onOptionsChange(key, newSelected);
  };

  return (
    <Box className={classes.container}>
      <FormInput
        label="Search"
        placeholder="Enter value..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        value={searchValue}
        onChange={value => {
          setSearchValue(value);
          onSearchChange("search", value);
        }}
      />

      {!!options &&
        Object.entries(options).map(([key, { values, label }]) => (
          <MultiSelect
            key={key}
            label={label}
            disabled={!values.length}
            value={optionsValue?.[key] ?? []}
            options={[{ value: "all", label: "Show all" }, ...values]}
            onChange={selected => {
              handleSelectChange(key, selected.map(String));
            }}
          />
        ))}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
