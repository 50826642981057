import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import rocketFastTrack from "assets/img/big-rocket.svg";
import rocketBk from "assets/img/rocket-ft.png";
import { theme } from "../theme";

const styles = {
  iconEasyBtn: {
    height: "16px",
    marginRight: "4px",
  },
  btnFunded: {
    lineHeight: 0,
    fontSize: "14px",
    height: "18px",
    zIndex: "100",
    padding: "12px 8px",
    fontWeight: "bold",
    textTransform: "none",
    letterSpacing: 1,
  },
};

const useStyles = makeStyles(styles);

export default ({ open, onClose, showButton = true }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}>
      <DialogTitle style={{ display: "flex" }}>
        <img src={rocketFastTrack} style={{ height: 32, width: 32, objectFit: "contain", marginRight: 8 }} />
        Ready to blast off?
      </DialogTitle>
      <DialogContent style={{ fontWeight: 400 }}>
        <p>
          Use Fast Track to submit to your <b>preferred lender waterfall</b> and receive most decisions in minutes.
          Next, quickly send a financing proposal to your customer on approval.
        </p>
        {showButton && (
          <p style={{ margin: "24px 0", textAlign: "center" }}>
            Select the
            <Button
              size="sm"
              className={classes.btnFunded}
              style={{
                fontWeight: "bold",
                margin: "0 4px",
                backgroundColor: theme.palette.primary.main,
                color: "#ffffff",
              }}>
              <img src={rocketBk} className={classes.iconEasyBtn} alt="" />
              FastTrack It!
            </Button>
            button to submit for fast approval.
          </p>
        )}
      </DialogContent>
      <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Button
          style={{ backgroundColor: theme.palette.primary.main, color: "#ffffff", width: 108, marginBottom: 32 }}
          onClick={() => onClose()}>
          Let's go!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
