import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { ModalsKeys } from "global";
import { useDrag } from "react-dnd";
import { useModal } from "hooks/useModal";
import makeStyles from "@mui/styles/makeStyles";
import { blue, blueGrey, grey } from "@mui/material/colors";
import { Box, LinearProgress, Link, Typography } from "@mui/material";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardHeader } from "./CardHeader";
import { CardPricingSection } from "./CardPricingSection";
import { ProductCatalogDetails } from "../../../catalogDetails/ProductCatalogDetails";
import { DnDTypes, FactToCheck, PrisingConfig, ProductConfig, ProposalProductCardModes } from "../../../../../types";

interface Props {
  logo?: string;
  equipmentCost?: number;
  type: "simple" | "forEdit";
  prisingConfig: PrisingConfig;
  isPricingEditable?: boolean;
  factsSkipped: FactToCheck[];
  mode: ProposalProductCardModes;
  proposalProduct: ProposalProduct;
  isDynamicPricingLoading?: boolean;
  productConfiguration: ProductConfig;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export const ProposalCardContainer = ({
  logo,
  type,
  mode,
  children,
  factsSkipped,
  prisingConfig,
  proposalProduct,
  equipmentCost = 0,
  productConfiguration,
  isPricingEditable = true,
  updateProductConfiguration,
  isDynamicPricingLoading = false,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.ProductCatalogDialog);
  const { handleOpen: handleOpenConstructorPricingInfoDialog } = useModal(ModalsKeys.ConstructorPricingInfoDialog);

  const isConstructorWithSkippedProducts =
    mode === ProposalProductCardModes.Desking ||
    (mode === ProposalProductCardModes.Constructor && factsSkipped.length === 0);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.ProposalProduct,
      item: proposalProduct,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [proposalProduct]
  );

  return (
    <Box className={clsx(classes.card, { [classes.draggingCard]: isDragging, [classes.editCard]: type === "forEdit" })}>
      <Box className={classes.titleAndLogo} {...({ ref: drag } as any)}>
        <CardHeader
          logo={logo}
          proposalProduct={proposalProduct}
          onOpenProductCatalogDialog={() => {
            handleOpen({ proposalProduct, productConfiguration });
          }}
        />
      </Box>

      <ProductCatalogDetails product={proposalProduct} productConfiguration={productConfiguration} />

      {proposalProduct?.thirdPartyUrl && (
        <Typography>
          <Link href={proposalProduct.thirdPartyUrl} target="_blank">
            {proposalProduct.thirdPartyUrl}
          </Link>
        </Typography>
      )}

      {children}

      {isDynamicPricingLoading && <LinearProgress style={{ width: "100%" }} />}

      {!isDynamicPricingLoading && (
        <CardPricingSection
          prisingConfig={prisingConfig}
          equipmentCost={equipmentCost}
          proposalProduct={proposalProduct}
          isPricingEditable={isPricingEditable}
          productConfiguration={productConfiguration}
          updateProductConfiguration={updateProductConfiguration}
          isConstructorWithSkippedProducts={isConstructorWithSkippedProducts}
          onOpenConstructorPricingInfoDialog={() => {
            handleOpenConstructorPricingInfoDialog({ product: proposalProduct, skippedFacts: factsSkipped });
          }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: blueGrey["50"],
  },
  editCard: {
    backgroundColor: blue["50"],
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  titleAndLogo: {
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      cursor: "grab",
    },
  },
});
