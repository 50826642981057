import React from "react";
import NumberFormat from "react-number-format";
import CustomCurrencyInput from "components/CustomCurrencyInput";
import { Box, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { MARKUP_TYPE } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/markup-type";
import { PricingProductConfiguration } from "./types";

interface Props {
  configurationIndex: number;
  configuration: PricingProductConfiguration;
  handleChangeValue: (event: any, configurationIndex: number, rowIndex: number | null, fieldName: string) => void;
}

export const PricingSection = ({ configurationIndex, configuration, handleChangeValue }: Props) => {
  const handleChangeConfiguration = (value: any, key: keyof PricingProductConfiguration) => {
    handleChangeValue({ target: { value } }, configurationIndex, null, key);
  };

  return (
    <Box sx={{ paddingTop: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "1rem" }}>
      <NumberFormat
        fullWidth
        suffix="%"
        size="small"
        decimalScale={6}
        fixedDecimalScale
        variant="outlined"
        allowNegative={false}
        label="Rate Factor (%)"
        customInput={TextField}
        value={configuration?.rateFactor}
        onValueChange={({ floatValue }) => {
          handleChangeConfiguration(floatValue, "rateFactor");
          handleChangeConfiguration(0, "dealerCost");
        }}
      />

      <NumberFormat
        prefix="$"
        fullWidth
        size="small"
        placeholder=""
        isNumericString
        decimalScale={2}
        thousandSeparator
        variant="outlined"
        label="Dealer Cost"
        customInput={TextField}
        value={configuration?.dealerCost}
        id={`dealer-cost-${configurationIndex}`}
        onChange={({ target: { value } }) => {
          handleChangeConfiguration(0, "rateFactor");
          handleChangeConfiguration(value.replace(/[^0-9.]/g, ""), "dealerCost");
        }}
      />

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 8rem", gridGap: "2rem" }}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Dealer Markup $"
          value={configuration?.dealerMarkup}
          id={`dealer-markup-${configurationIndex}`}
          onChange={({ target: { value } }) => handleChangeConfiguration(value, "dealerMarkup")}
          slotProps={
            configuration?.dealerMarkupType === MARKUP_TYPE.PERCENTAGE
              ? undefined
              : { input: { inputComponent: CustomCurrencyInput } }
          }
        />

        <RadioGroup
          row
          value={configuration?.dealerMarkupType}
          id={`dealer-markup-type-${configurationIndex}`}
          onChange={e => handleChangeConfiguration(e.target.value, "dealerMarkupType")}
          name="dealerMarkupType">
          <FormControlLabel value={MARKUP_TYPE.PERCENTAGE} control={<Radio color="primary" />} label="%" />
          <FormControlLabel value={MARKUP_TYPE.FIXED} control={<Radio color="primary" />} label="$" />
        </RadioGroup>
      </Box>

      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Suggested Retail Pricing"
        value={configuration?.suggestedRetailPricing}
        id={`suggestedRetailPricing-${configurationIndex}`}
        slotProps={{ input: { inputComponent: CustomCurrencyInput } }}
        onChange={({ target: { value } }) => {
          handleChangeConfiguration(Number(value), "suggestedRetailPricing");
        }}
      />

      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Min. Pricing"
        value={configuration?.minPricing}
        id={`minPricing-${configurationIndex}`}
        slotProps={{ input: { inputComponent: CustomCurrencyInput } }}
        onChange={({ target: { value } }) => handleChangeConfiguration(value, "minPricing")}
      />

      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Max. Pricing"
        value={configuration?.maxPricing}
        id={`maxPricing-${configurationIndex}`}
        slotProps={{ input: { inputComponent: CustomCurrencyInput } }}
        onChange={({ target: { value } }) => handleChangeConfiguration(value, "maxPricing")}
      />
    </Box>
  );
};
