import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import AddNewLenderModal from "./AddNewLenderModal";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import makeStyles from "@mui/styles/makeStyles";
import Tile from "../../components/Tile/Tile";
import { Grid } from "@mui/material";
import Button from "components/CustomButtons/Button";
import { ShepherdTourContext } from "react-shepherd";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import _ from "lodash";
import TableSearch from "components/Table/TableSearch";
import DragIndicator from "assets/img/drag-indicator.svg";
import { adminRoles, lenderTypesSettings } from "../Prequal/constants";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  lenderActive: {
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  lenderStaged: {
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  lenderInvited: {
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
});

const useStyles = makeStyles(styles);

const statusTitles = {
  Active: "Lender has successfully registered contact.",
  Invited: "Lender contact has been invited to register to Trnsact.",
  Staged: "Lender is connected but there are no contacts invited.",
};

const GET_VP = gql`
  query {
    vendorProfile {
      territoryManagement
      lenderProfiles {
        id
        hasActiveUser
        lenderName
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        submissionMethod
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderChannelCode
        lenderTypeCode
        dealerRelationship
        submissionReviewShowMappingModal # ON_DISPLAYS_MAPPING_MODAL | OFF_NO_REVIEW_SHOWN
        insuranceRequirements
        lenderUserProfile {
          id
          email
          dynamicsContactId
          firstName
          lastName
          fullName
          phoneNumber
          mobilePhoneNumber
          mugshot
          title
          accountId
        }
        lenderProfileUsers {
          lenderUserProfileId
        }
        lenderAccount {
          id
          dynamicsAccountId
          vendorProfileId
          primaryContactId
          name
          address
          phone
          primaryContactName
          primaryContactPhone
          primaryContactEmail
        }
      }
    }
  }
`;

const LenderAdmin = props => {
  const classes = useStyles();
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);
  const tour = useContext(ShepherdTourContext);
  const lps = useSelector(state => state.lp);
  const vp = useSelector(state => state.vp);
  const userProfile = useSelector(state => state.userProfile);

  const [tourEnabled, setTourEnabled] = useState(props.tourEnabled);
  const [lenderList, setLenderList] = useState([]);
  const [lenderProfiles, setLenderProfiles] = useState([]);
  const [componentLoaded, setComponentLoaded] = useState(true);
  const [openAddNewLenderModal, setOpenAddNewLenderModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(20);
  const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

  const isUserAbleToSeeLenderAdmin = isDealerUser ? true : vp?.allowSyndication;
  const adminRolesAvailable = [adminRoles.super, adminRoles.singleAccountOnly];
  const userIsSuperAdmin = _.includes(adminRolesAvailable, userProfile.adminRole);
  if (userIsSuperAdmin) {
    console.log("Current user Is Super Admin");
  }
  const { data: vpData, refetch: refetchData } = useQuery(GET_VP, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  console.log(lenderProfiles);

  useEffect(() => {
    if (vpData?.vendorProfile?.lenderProfiles) {
      setLenderProfiles(vpData.vendorProfile.lenderProfiles);
    }
  }, [vpData]);

  useEffect(() => {
    if (lps?.length > 0 && lenderProfiles.length === 0) {
      setLenderProfiles([...lps]);
    }
  }, [lps]);

  useEffect(() => {
    if (tourEnabled && componentLoaded) {
      tour.start();
    }
  }, [componentLoaded]);

  useEffect(() => {
    if (lenderProfiles.length > 0) {
      setLenderList(filterLenderList(lenderProfiles));
    }
  }, [lenderProfiles, searchRef.current.currentSearch]);

  const handleCloseTour = () => {
    if (tourEnabled) {
      tour.complete();
    }
  };

  const filterLenderList = lenderList => {
    const { currentSearch } = searchRef.current;
    if (currentSearch.length === 0) {
      return lenderList;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = lenderList.filter(el => {
      if (
        typeof el.lenderName === "string" &&
        typeof el.submissionMethod === "string" &&
        typeof (el.lenderUserProfile || {}).fullName === "string" &&
        typeof (el.lenderUserProfile || {}).email === "string" &&
        typeof (el.lenderUserProfile || {}).phoneNumber === "string"
      ) {
        return (
          (el.lenderName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lenderUserProfile.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.submissionMethod || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lenderUserProfile.email || "").includes(lowerCaseSearch) ||
          (el.lenderUserProfile.phoneNumber || "").replace(/[^0-9]/g, "").includes(lowerCaseSearch)
        );
      }

      if (typeof el.lenderName === "string") {
        return (el.lenderName || "").toLowerCase().includes(lowerCaseSearch);
      }

      return false;
    });

    return [...searchData];
  };

  const handleOpenAddNewLenderModal = () => {
    setOpenAddNewLenderModal(true);
  };

  const handleCloseAddNewLenderModal = () => {
    setOpenAddNewLenderModal(false);
  };

  if (!isUserAbleToSeeLenderAdmin) {
    return <h3>Access Denied</h3>;
  }

  const updateStatuses = status => {
    const statuses = {
      pending_approval: "Staged",
      approved: "Staged",
      active: "Active",
      blocked: "Blocked",
    };

    let stat = statuses[status] ? statuses[status] : "Staged";

    return stat;
  };

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <Tile>
            <div data-tour="lenders-table">
              <TableHeader
                filterComps={
                  <TableSearch
                    setPageNumber={setPageNumber}
                    setSavedListSettings={setSavedListSettings}
                    searchRef={searchRef}
                    savedListSettings={savedListSettings}
                  />
                }>
                <Button
                  color="primary"
                  size="sm"
                  data-tour="add-lender-button"
                  onClick={() => {
                    handleCloseTour();
                    return handleOpenAddNewLenderModal();
                  }}>
                  Add New Lender
                </Button>
              </TableHeader>
              <Table
                color="primary"
                data={lenderList.sort((a, b) => a.lenderName.localeCompare(b.lenderName))}
                getTrProps={(state, rowInfo, column, instance) => ({
                  style: { cursor: "pointer" },
                })}
                columns={[
                  {
                    Header: "Name",
                    accessor: "lenderName",
                    id: "lenderName",
                    resizable: false,
                  },
                  // {
                  //   Header: "Lender Contact",
                  //   accessor: "lenderUserProfile.fullName",
                  //   id: "fullName",
                  // },
                  {
                    Header: "Submission Method",
                    accessor: "submissionMethod",
                    id: "submissionMethod",
                    Cell: props =>
                      props.value === "Submission By Email"
                        ? "Secure Email"
                        : props.original.lenderChannelCode !== 100 && props.original.lenderChannelCode !== 200
                          ? "API"
                          : props.value,
                  },
                  // {
                  //   Header: "Contact Email",
                  //   accessor: "lenderUserProfile.email",
                  //   id: "email",
                  // },
                  // {
                  //   Header: "Contact Phone",
                  //   accessor: "lenderUserProfile.phoneNumber",
                  //   id: "phoneNumber",
                  // },
                  {
                    Header: "Lender Type",
                    accessor: "lenderTypeCode",
                    id: "lenderTypeCode",
                    Cell: props => {
                      const lenderTypeSetting = lenderTypesSettingsByValue[props.value];
                      return lenderTypeSetting ? (
                        <>
                          <b style={{ color: lenderTypeSetting.color }}>{_.get(lenderTypeSetting, "letter", "")}</b>
                          <span> {_.get(lenderTypeSetting, "title", "")}</span>
                        </>
                      ) : null;
                    },
                  },
                  {
                    Header: "Status",
                    accessor: "hasActiveUser",
                    id: "hasActiveUser",
                    Cell: props => {
                      const isManualSubmission = Boolean(
                        props.original.submissionMethod === "Manual by Dealer into Lender Portal" ||
                          props.original.submissionMethod === "Submission By Email"
                      );

                      let status;

                      if (isManualSubmission) {
                        if (props.value) {
                          status = "Active";
                        } else {
                          if (!props.original.lenderProfileUsers || props.original.lenderProfileUsers.length === 0) {
                            status = "Staged";
                          } else {
                            status = "Invited";
                          }
                        }
                      } else {
                        status = "Active";
                      }

                      return (
                        <div title={statusTitles[status]} className={classes[`lender${status}`]}>
                          {status}
                        </div>
                      );
                    },
                  },
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    style: { cursor: "pointer" },
                    onClick: e => {
                      e.preventDefault();
                      const dynamicsLenderProfileId = rowInfo.original.lenderProfileId;

                      if (column.id !== "credentials" && column.id !== "changeContact") {
                        window.location.href = `/lenderadmin/${dynamicsLenderProfileId}`;
                      }
                    },
                  };
                }}
                onPageChange={changePage => {
                  setPageNumber(changePage);
                }}
                onPageSizeChange={changePageSize => {
                  setPageSizeValue(changePageSize);
                  setPageNumber(0);
                }}
                page={pageNumber}
                pageSize={pageSizeValue}
                defaultPageSize={savedListSettings.pageSize}
                showPaginationTop={false}
                showPaginationBottom={true}
                customCellClasses={[classes.right]}
                customClassesForCells={[5]}
                className="-highlight"
              />
            </div>
          </Tile>
        </Grid>
      </Grid>
      <AddNewLenderModal
        tourEnabled={tourEnabled}
        open={openAddNewLenderModal}
        userIsSuperAdmin={userIsSuperAdmin}
        handleClose={() => handleCloseAddNewLenderModal()}
        handleOpenAddNewRepModal={handleOpenAddNewLenderModal}
        refetchData={refetchData}
      />
    </div>
  );
};

export default LenderAdmin;
