import React from "react";
import { ModalsKeys } from "global";
import { Box } from "@mui/material";
import { useModal } from "hooks/useModal";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { useSaleMode } from "../../../context";
import { MenuCardsProps } from "../../../types";
import { MenuOptionCard } from "./MenuOptionCard";
import { useMenuOptionTerms } from "../../../hooks";
import { ProposalSettings } from "./ProposalSettings";
import { MenuOptionDetail } from "./MenuOptionDetail";
import { TermsStepperManager } from "../TermsStepperManager";
import { deskingActions, deskingSelectors } from "../../../model";

export const FinanceMenuOption = ({
  builder,
  menuPricing,
  isCalculating,
  isSectionOpen,
  isMAALessThenFinanceAmount,
}: MenuCardsProps) => {
  const classes = useStyles();

  const { isFinancing, saleType } = useSaleMode();

  const { currentTerm, ...termsManagementProps } = useMenuOptionTerms();

  const { handleOpen } = useModal(ModalsKeys.ProductCatalogDialog);

  const dispatch = useDispatch();

  const productsInMenuPrice = useSelector(deskingSelectors.productsInMenuPrice);
  const financeProgramDescription = useSelector(deskingSelectors.financeProgramDescription);
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);

  const handleDropProduct = (product: ProposalProduct, menuOptionName: string) => {
    dispatch(
      deskingActions.addMenuProduct({
        menuType: menuOptionName,
        product,
      })
    );
  };

  const handleRemoveProduct = (productId: string, currentMenuOption: any) => {
    dispatch(
      deskingActions.removeMenuProduct({
        menuName: currentMenuOption.name,
        productId,
      })
    );

    if (selectedMenuOption?.name === currentMenuOption.name && currentMenuOption?.products.length === 1) {
      dispatch(deskingActions.selectMenuOption({ optionName: currentMenuOption.name }));
    }
  };

  const handleSelectMenuOption = (optionName: string) => {
    dispatch(deskingActions.selectMenuOption({ optionName }));
  };

  const handleOpenProductDetailsDialog = (proposalProduct: ProposalProduct) => {
    handleOpen({ proposalProduct });
  };

  const handleMoveMenuOption = (dragIndex: number, hoverIndex: number) => {
    const menuOptions = builder?.[currentTerm]?.menuOptions ?? [];

    const orderedMenuOptions = [...menuOptions];
    orderedMenuOptions.splice(dragIndex, 1);
    orderedMenuOptions.splice(hoverIndex, 0, menuOptions[dragIndex]);
    dispatch(deskingActions.setMenuOptions({ menuOptions: orderedMenuOptions }));
  };

  const handleSwitchProducts = (options: { menuName: string; from: number; to: number }) => {
    dispatch(deskingActions.setOrderOfProductInMenu(options));
  };

  const handleRemoveAddon = (product: ProposalProduct, optionName: string, title: string) => {
    const productConfiguration = proposalProductConfigurationsInMenu?.[optionName]?.[product.proposalProductId];

    if (!productConfiguration || !productConfiguration?.addons?.length) return;

    const currentProductAddons: any[] = productConfiguration?.addons;
    const removeAddonIndex = currentProductAddons.findIndex(addon => addon.title === title);

    if (removeAddonIndex === -1) return;

    const nextAddons = currentProductAddons.filter((_, index) => index !== removeAddonIndex);
    const removedAddonCost = currentProductAddons[removeAddonIndex]?.cost ?? 0;

    dispatch(
      deskingActions.updateProductConfigurationInMenu({
        productId: product.proposalProductId,
        menuName: optionName,
        data: {
          addons: nextAddons,
          cost: Math.max(0, productConfiguration?.cost - removedAddonCost),
          retailCost: Math.max(0, productConfiguration?.retailCost - removedAddonCost),
        },
      })
    );
  };

  return (
    <Box className={classes.cardContainer}>
      {!!builder?.[currentTerm]?.menuOptions?.length && (
        <>
          {isFinancing && <TermsStepperManager label={`${currentTerm} Months`} {...termsManagementProps} />}

          <Box className={classes.cardsContainer}>
            {builder[currentTerm].menuOptions.map((menuOption, menuOptionIdx) => (
              <MenuOptionCard
                saleType={saleType}
                key={menuOption.name}
                menuOptionIdx={menuOptionIdx}
                isCalculating={isCalculating}
                onDropProduct={handleDropProduct}
                onRemoveAddon={handleRemoveAddon}
                moveMenuOption={handleMoveMenuOption}
                onRemoveProduct={handleRemoveProduct}
                selectedMenuOption={selectedMenuOption}
                onSwitchProducts={handleSwitchProducts}
                onSelectMenuOption={handleSelectMenuOption}
                onOpenProductDetailsDialog={handleOpenProductDetailsDialog}
                productsConfiguration={proposalProductConfigurationsInMenu?.[menuOption.name] ?? {}}
                menuOption={{ ...menuOption, payment: menuPricing?.[currentTerm]?.[menuOption.name] ?? {} }}
              />
            ))}
          </Box>

          <MenuOptionDetail
            saleType={saleType}
            isSectionOpen={isSectionOpen}
            menuOption={selectedMenuOption}
            isSelectedMenuOption={!!selectedMenuOption}
            priceOfProductsInMenu={productsInMenuPrice}
            financeProgramDescription={financeProgramDescription}
            isMAALessThenFinanceAmount={isMAALessThenFinanceAmount}
            payment={selectedMenuOption?.name ? menuPricing?.[currentTerm]?.[selectedMenuOption.name] : null}
          />

          <ProposalSettings />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",
  },
});
