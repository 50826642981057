import { gql } from "@apollo/client";

const GET_CREDIT_SUBMISSION_PROPOSALS = gql`
  query($creditSubmissionDynamicsId: ID) {
    proposals(creditSubmissionDynamicsId: $creditSubmissionDynamicsId) {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
    }
  }
`;

const GET_VENDOR_OPPORTUNITY_PROPOSALS = gql`
  query($voId: ID) {
    proposals(voId: $voId) {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
      paymentId
      creditSubmissionDynamicsId
      lenderPaymentOptions {
        lenderId
        lenderType
        payment
        paymentId
        proposalId
        residual
        residualMultiplier
        term
        useMaxApprovedAmount
      }
    }
  }
`;

export { GET_CREDIT_SUBMISSION_PROPOSALS, GET_VENDOR_OPPORTUNITY_PROPOSALS };
