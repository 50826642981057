import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import FinanceBanner1 from "assets/img/wizards/finance_banner_example_1.png";

import _ from "lodash";

const config = require("../../../config");

const SecureOCAInstructions = props => {
  let useStyles = makeStyles({
    previewImg: {
      maxWidth: "30vw",
      borderRadius: "10px",
      margin: "20px auto",
    },
    wrap: {
      padding: 0,
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    frame: {
      minHeight: "800px",
      width: "100%",
      overflow: "hidden",
      position: "relative",
      textAlign: "center",
      "@media (max-width: 768px)": {
        "-ms-zoom": "0.90",
        "-moz-transform": "scale(0.90)",
        "-moz-transform-origin": "0px 0",
        "-o-transform": "scale(0.90)",
        "-o-transform-origin": "0 0",
        "-webkit-transform": "scale(0.90)",
        "-webkit-transform-origin": "0 0",
      },
      "-ms-zoom": "0.80",
      "-moz-transform": "scale(0.80)",
      "-moz-transform-origin": "0px 0",
      "-o-transform": "scale(0.80)",
      "-o-transform-origin": "0 0",
      "-webkit-transform": "scale(0.80)",
      "-webkit-transform-origin": "0 0",
    },
  });
  let classes = useStyles();

  const account = useSelector(state => state.account);
  const vendorProfile = useSelector(state => state.vp);
  const vendorLogo = _.get(vendorProfile, "logo", false);

  return (
    <Grid container spacing={4}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography>
          Embed a secure credit application landing page on your website with your branding. Instead of giving customers
          an insecure paper or PDF credit app, direct them to a secure and compliant digital credit app on your website
          that gives them offers in less than 3 minutes.
        </Typography>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justifyContent="space-evenly">
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid container justifyContent="space-evenly">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <p>This how our customized Finance Landing pages looks like:</p>
                <img className={classes.previewImg} src={FinanceBanner1} />
              </Grid>

              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <p>
                  The "Apply Now" button will take your customer to your Secure
                  Credit Application which you can see here:
                </p>
                <div className={classes.wrap}>
                  <iframe
                    seamless
                    className={classes.frame}
                    src={`https://app.dev.dcrportal.com/oca/?vendorGUID=${account.dynamicsAccountId}`}
                    width="100%"
                    frameBorder="0"
                  />
                </div>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Typography variant="h5">Integration instructions</Typography>
            <ol>
              <li>
                The Financing banner is created by request to our Team via email to{" "}
                <a href="mailto:support@trnsact.com">Support</a>. Our team will accomodate the banner to best match your
                site's color scheme and font for a seamless integration. After the banner is created a link is going to
                be provided for you to continue with step 2.
              </li>
              <li>
                Once the link has been provisioned, create a new section in your site called "Financing" or a sub page
                to direct the customers for financing. For e.g: <b>www.trucks4all.com/financing</b>
              </li>
              <li>
                Find the body tag inside the page you have just created (or inside an existing one). For e.g:{" "}
                <SyntaxHighlighter language="xml">
                  {`<html>
  <body>
    <!-- Iframe goes here -->
  </body>
</html>`}
                </SyntaxHighlighter>
              </li>
              <li>
                Copy and Paste this Iframe element inside the Body element you have just identified:
                <SyntaxHighlighter language="xml">{`<iframe src="LINK_PROVISIONED" width="100%" frameBorder="0"/>`}</SyntaxHighlighter>
              </li>
              <li>
                Now it should looks like this:
                <SyntaxHighlighter language="xml">
                  {`<html>
  <body>
  ...
    <iframe src="LINK_PROVISIONED" width="100%" frameBorder="0"/>
  ...  
  </body>
</html>`}
                </SyntaxHighlighter>
              </li>
            </ol>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecureOCAInstructions;
