import React, { useRef, useState } from "react";
import ReactTable from "react-table";
import { gql } from "@apollo/client";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Query } from "@apollo/client/react/components";
import makeStyles from "@mui/styles/makeStyles";
import Tile from "../../components/Tile/Tile";
import Button from "components/CustomButtons/Button";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import CreateLocationModal from "./CreateLocationModal";
import TableSearch from "components/Table/TableSearch";
import { LinearProgress } from "@mui/material";
import _ from "lodash";
import DragIndicator from "assets/img/drag-indicator.svg";

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-thead.-headerGroups": {
      border: "none",
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
});

const useStyles = makeStyles(styles);

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
      childrenLocation {
        locationId
        locationName
      }
      locationParentName
    }
  }
`;

const LocationList = props => {
  const classes = useStyles();
  const [redirecting, setRedirecting] = useState(false);
  const [openCreateLocationModal, setOpenCreateLocationModal] = useState(false);
  const account = useSelector(state => state.account);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(20);

  const filterLocationData = LocationsList => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return LocationsList;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = (LocationsList || []).filter(el => {
      if (
        typeof el.locationName === "string" ||
        typeof el.category === "string" ||
        typeof el.ocaContact.fullName === "string" ||
        typeof el.locationParentName === "string" ||
        Array.isArray(el.childrenLocation)
      ) {
        return (
          (el.locationName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.category || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.locationParentName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.ocaContact.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.childrenLocation || []).some(loc => loc.locationName.toLowerCase().includes(lowerCaseSearch))
        );
      }

      return false;
    });

    return [...searchData];
  };

  const handleClose = async (upsertModalClosedValue, refetch) => {
    setOpenCreateLocationModal(false);

    if (!_.get(upsertModalClosedValue, "locationWasNewlyCreated", false)) {
      return;
    }
    if (
      _.get(upsertModalClosedValue, "locationWasNewlyCreated", false) &&
      _.get(upsertModalClosedValue, "locationId", undefined)
    ) {
      setRedirecting(true);
      props.history.push(`/locationDetail/${_.get(upsertModalClosedValue, "locationId", undefined)}`);
      setRedirecting(false);
    }
  };

  function handleDetailRowClick(locationId) {
    props.history.push(`/locationDetail/${locationId}`);
  }

  if (redirecting) {
    return (
      <div className={classes.root}>
        <CircularProgress size={16} /> Redirecting to your new Location ...
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {
          <Query context={{ authRequired: true }} query={GET_LOCATIONS} fetchPolicy="no-cache">
            {({ loading, error, data, refetch }) => {
              if (loading) return <LinearProgress />;
              if (error) return <h3>{String(error)}</h3>;

              const filteredLocations = filterLocationData(data.locations);

              return (
                <>
                  <Grid>
                    <Grid item xs={12}>
                      <Tile>
                        {loading && (
                          <span>
                            <CircularProgress size={16} /> Refreshing Location List ...
                          </span>
                        )}
                        <div className={classes.fixReactTable}>
                          <Grid container>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                              <TableSearch
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                searchRef={searchRef}
                                savedListSettings={savedListSettings}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={7}></Grid>
                            <Grid item xs={12} sm={3} md={3} lg={2}>
                              <Button
                                color="primary"
                                disabled={loading}
                                size="sm"
                                onClick={() => setOpenCreateLocationModal(true)}>
                                Add New Location
                              </Button>
                            </Grid>
                          </Grid>
                          <ReactTable
                            color="primary"
                            data={filteredLocations}
                            getTdProps={(state, rowInfo, column, instance) => {
                              return {
                                style: { cursor: "pointer" },
                                onClick: e => {
                                  {
                                    if (rowInfo && rowInfo.original) {
                                      handleDetailRowClick(rowInfo.original.locationId);
                                    }
                                  }
                                },
                              };
                            }}
                            columns={[
                              {
                                headerStyle: {
                                  backgroundColor: "#ffffff",
                                  border: "none",
                                },
                                columns: [
                                  {
                                    Header: "Location",
                                    accessor: "locationName",
                                    resizable: false,
                                  },
                                  {
                                    Header: "Location Type",
                                    accessor: "category",
                                  },
                                  {
                                    Header: "Primary Contact",
                                    accessor: "ocaContact[fullName]",
                                  },
                                  {
                                    Header: "Parent Location",
                                    accessor: "locationParentName",
                                  },
                                  {
                                    Header: "Child Location",
                                    accessor: "childrenLocation",
                                    disableSortBy: true,
                                    Cell: props => (
                                      <div>
                                        {props.value
                                          ? props.value
                                              .sort((a, b) => a.locationName - b.locationName)
                                              .map(el => (
                                                <React.Fragment key={el.locationId}>
                                                  {el.locationName}
                                                  <br />
                                                </React.Fragment>
                                              ))
                                          : ""}
                                      </div>
                                    ),
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={savedListSettings.pageSize}
                            pageSize={pageSizeValue}
                            onPageChange={changePage => {
                              setPageNumber(changePage);
                            }}
                            onPageSizeChange={changePageSize => {
                              setPageSizeValue(changePageSize);
                              setPageNumber(0);
                            }}
                            page={pageNumber}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            customCellClasses={[classes.right]}
                            customClassesForCells={[5]}
                            className="-striped -highlight"
                          />
                        </div>
                      </Tile>
                    </Grid>
                  </Grid>
                  <Modal open={openCreateLocationModal}>
                    <CreateLocationModal
                      locationsList={data.locations}
                      account={account}
                      closeCreateLocationModal={e => handleClose(e, refetch)}
                    />
                  </Modal>
                </>
              );
            }}
          </Query>
        }
      </div>
    );
  }
};

export default LocationList;
