import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Tile from "./Tile/Tile";
import Table from "./Table/Table";
import CardHeader from "./Card/CardHeader";
import Button from "./CustomButtons/Button";
import { cardTitle } from "../assets/jss/material-dashboard-pro-react";
import InvoiceQuoteUploadModal from "./InvoiceQuoteUploadModal";

const QUERY_EQUIPMENTS = gql`
  query ($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
    }
  }
`;

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 400,
    maxWidth: "90%",
  },
  cardIconTitle: {
    ...cardTitle,
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  },
  right: {
    textAlign: "right",
  },
}));

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

export default function Documents(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [equipments, setEquipments] = useState([]);

  // const [openMessageModal, setOpenMessageModal] = useState(false);
  // const handleOpenMessageModal = () => setOpenMessageModal(true);
  // const handleCloseMessageModal = () => setOpenMessageModal(false);

  const { loading, error, data } = useQuery(QUERY_EQUIPMENTS, {
    variables: {
      VOId: props.vo.vendorOpportunityId,
    },
  });

  if (loading) console.log("Loading equipments...");
  if (error) console.log(`Error! ${error.message}`);

  // const mapData = (key, quantity, newUsed, year, make, model, serialNumberVin, additionalDescription) => {
  //   return { key, quantity, newUsed, year, make, model, serialNumberVin, additionalDescription};
  // };
  // Fetch document for the very first time:
  useEffect(() => {
    if (data !== null && data !== undefined && data.equipments !== null) {
      setEquipments(
        data.equipments.map(row => {
          return [
            row.quantity,
            row.newUsed,
            row.year,
            row.make,
            row.model,
            row.serialNumberVin,
            row.stockId,
            row.additionalDescription,
          ];
        })
      );
    }
  }, [data]);

  const tableHead = [
    "Quantity",
    "New/Used",
    "Year",
    "Make",
    "Model",
    "Serial Number/VIN",
    "Stock Id",
    "Additional Description",
  ];

  return (
    <div style={modalStyle} className={classes.surface}>
      <Tile maxHeightLimitByScreen={true}>
        <CardHeader>
          <div className={classes.tileCardHeader}>
            <h3 className={classes.cardIconTitle}>Equipment Details</h3>
            <Button color="primary" size="sm" onClick={props.handleOpenEquipmentInputModal}>
              Add
            </Button>
            <InvoiceQuoteUploadModal vo={props.vo} equipmentUpload={true} buttonDescription="Upload Invoice/Quote" />
          </div>
        </CardHeader>
        {equipments ? (
          <Table
            tableHead={tableHead}
            tableData={equipments}
            tableHeaderColor="primary"
            customHeadCellClasses={[classes.right, classes.right]}
            customHeadClassesForCells={[5, 6]}
            customCellClasses={[classes.right, classes.right]}
            customClassesForCells={[5, 6]}
            colorsColls={["primary"]}
          />
        ) : (
          <div />
        )}
      </Tile>
    </div>
  );
}
