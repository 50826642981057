import React from "react";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { useVODocs } from "../hooks/useVODocs";
import { DocumentsTable } from "./DocumentsTable";

interface Props {
  VOId: string;
}

export const DocumentsCard = ({ VOId }: Props) => {
  const { docs, isVODocsLoading } = useVODocs({ VOId });

  return (
    <CardContainer title="Documents" isLoading={isVODocsLoading}>
      <DocumentsTable
        isLoading={isVODocsLoading}
        tableData={docs.filter(document => document.docType === "Compliance")}
      />
    </CardContainer>
  );
};
