// libraries
import _ from "lodash";

const getDropdownTreeSelectDefaultOptions = options => {
  const defaultOptions = {
    mode: "radioSelect",
    showPartiallySelected: false,
    inlineSearchInput: true,
    texts: { placeholder: "Location" },
  };
  return _.merge({}, defaultOptions, options);
};

const buildTree = locationList => {
  const getTreeNode = (location, depth) => {
    const childLocations = _(locationList)
      .filter(l => l.locationParentId === location.locationId)
      .orderBy("locationName")
      .value();
    return {
      value: location.locationId,
      label: location.locationName,
      checked: location.checked,
      isDefaultValue: location.isDefaultValue,
      className: _.toLower(location.category),
      children: _.isEmpty(childLocations) ? [] : _.map(childLocations, el => getTreeNode(el, depth + 1)),
      expanded: !_.isEmpty(childLocations) && depth === 0 ? true : false, // expand 0 level by default
      original: location,
    };
  };

  const rootElements = _(locationList).reject("locationParentId").orderBy("locationName").value();

  return _.map(rootElements, el => {
    if (el.locationId !== null) {
      return getTreeNode(el, 0);
    } else {
      return {
        value: el.locationId,
        label: el.locationName,
        checked: el.checked,
        isDefaultValue: false,
        className: "",
        children: [],
        expanded: false,
        original: el,
      };
    }
  });
};

export {
  // methods
  buildTree,
  getDropdownTreeSelectDefaultOptions,
};
