import moment from "moment/moment";

interface Options {
  userName: string;
  prevNotes: string;
  nextStatus: string;
  currentStatus: string;
}

export function generateCreditLineLog({ nextStatus, currentStatus, userName, prevNotes }: Options): string {
  const prevNotesValue = prevNotes ? prevNotes + "\n\n" : prevNotes;

  return (
    prevNotesValue + `${moment().format("lll")}: ${userName} changed status from ${currentStatus} to ${nextStatus}`
  );
}
