import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import Button from "components/CustomButtons/Button";
import Tile from "./Tile/Tile";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
  },
}));

export default function MessageModal({ title, message, isOpen, handleCloseModal }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(isOpen);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Tile>
        <h2 id="simple-modal-title">{title}</h2>
        <p id="simple-modal-description">{message}</p>
        {title === "Sending" ? (
          <div />
        ) : (
          <Button onClick={(handleClose, handleCloseModal)} variant="contained" color="primary">
            Done
          </Button>
        )}
      </Tile>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={(handleClose, handleCloseModal)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  );
}
