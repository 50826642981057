import React from "react";
import clsx from "clsx";
import { Button, Chip, Divider, Box, ListItem, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { ContentViewType } from "../../../../global";
import { isDesktop, isMobile } from "../../../../utils/contentViewType";
import { checkIfOwnerIsSimpleContact } from "modules/creditApplicationDetailsActions/lib";

interface Props {
  owner: any;
  phoneToDisplay?: string | null;
  contentViewType?: ContentViewType;
  creditPullComponentByOwnerId: any;
  handleGuaranteeClick: (contact: any) => void;
}

export const ApplicantInfo = ({
  owner,
  phoneToDisplay,
  creditPullComponentByOwnerId,
  contentViewType = ContentViewType.Desktop,
  handleGuaranteeClick,
}: Props) => {
  const history = useHistory();

  const classes = useStyles();

  const handleGoToOwnerPgDetails = () => {
    history.push(`/ownerPgDetails/${owner.ownerPgId}`);
  };

  return (
    <>
      <ListItem className={classes.nestedListItem}>
        <Box className={classes.container}>
          <Box
            className={clsx(classes.mainInfo, {
              [classes.mainInfoMobile]: isMobile(contentViewType),
              [classes.mainInfoDesktop]: isDesktop(contentViewType),
            })}>
            <Box className="info">
              <Box className="infoData">
                <AccountCircleIcon color="primary" />

                <Typography
                  component="span"
                  variant="body1"
                  className={classes.link}
                  onClick={handleGoToOwnerPgDetails}>
                  {`${owner.firstName} ${owner.lastName}`}
                </Typography>

                {!!owner?.title && <Typography component="span">{owner.title}</Typography>}

                {!!owner?.pc && <Chip variant="outlined" size="small" label="POC" />}
                {checkIfOwnerIsSimpleContact(owner) && <Chip variant="outlined" size="small" label="Contact Only" />}
              </Box>

              {creditPullComponentByOwnerId[owner.ownerPgId] && (
                <Box className={classes.creditPullContainer}>
                  {owner.ssn ? (
                    creditPullComponentByOwnerId[owner.ownerPgId][1]
                  ) : (
                    <Typography component="span">Unable to Pull Credit Report - Missing SSN</Typography>
                  )}
                </Box>
              )}
            </Box>

            <Box className="aplicantActions">
              {!owner?.pg && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.actionButton}
                  onClick={() => handleGuaranteeClick(owner)}>
                  Add PG
                </Button>
              )}
            </Box>
          </Box>

          <Box
            className={clsx(classes.infoData, {
              [classes.infoDataMobile]: isMobile(contentViewType),
              [classes.infoDataDesktop]: isDesktop(contentViewType),
            })}>
            {phoneToDisplay && (
              <Box className="infoItem">
                <PhoneIcon fontSize="small" className="icon" />

                <Typography component="span">{phoneToDisplay}</Typography>
              </Box>
            )}

            {owner.email && (
              <Box className="infoItem">
                <EmailIcon fontSize="small" className="icon" />

                <Typography>{owner.email}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ListItem>

      <Divider />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  nestedListItem: {
    display: "flex",
    background: "#fafafa",
    flexDirection: "column",
  },
  container: {
    gap: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    cursor: "pointer",
    color: palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  mainInfo: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",

    "& .info": {
      gap: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      "& .infoData": {
        gap: "8px",
        display: "flex",
        alignItems: "cender",
      },
    },
  },
  mainInfoMobile: {
    gap: "4px",
    flexDirection: "column",
  },
  mainInfoDesktop: {
    flexDirection: "row",
  },
  actionButton: {
    marginLeft: 8,
  },
  creditPullContainer: {
    display: "flex",

    "& > div": {
      padding: "0 !important",
      marginRight: 8,
    },
  },
  infoData: {
    display: "flex",

    "& .infoItem": {
      gap: "8px",
      display: "flex",
      alignItems: "center",

      "& .icon": {
        color: "#666666",
      },
    },
  },
  infoDataMobile: {
    gap: "6px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  infoDataDesktop: {
    gap: "8px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
