import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Fab, Grid, Menu, MenuItem, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuidv4 } from "uuid";
import { Link, animateScroll as scroll } from "react-scroll";
import { RuleTooltipDescription } from "../RuleTooltipDescription/index";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { theme } from "../../../../theme";

import {
  getApplicantBlockConfiguration,
  getCoApplicantBlockConfiguration,
  getDocumentSignBlockConfiguration,
  getDynamicSignatureBlockConfiguration,
  getEquipmentDetailsBlockConfiguration,
  getMarketingCommunicationBlockConfiguration,
  getOffersBlockConfiguration,
  getPersonalGuaranteeSignaturesBlockConfiguration,
  getReferenceBlockConfiguration,
  getSimpleBlockConfiguration,
} from "../../../../services/dynamicOCAService";

import {
  applicantBlockTypes,
  coApplicantBlockTypes,
  equipmentTypes,
  offersTypes,
  referenceTypes,
} from "../../../../pages/Prequal/constants";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  pageInfoPanel: {
    padding: "30px 0",
    position: "fixed",
    top: 0,
    background: "#e6e6e6",
    zIndex: "12",
    width: "calc(100% - 400px) !important",
    flexWrap: "nowrap",
    boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.1)",
  },
  addNewSectionButton: {
    padding: 4,
    marginLeft: "20px",
    background: theme.palette.primary.main,
  },
  sectionList: {
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  sectionListItem: {
    margin: "0 0 0 20px",
    padding: "17px 10px",
    borderRadius: 7,
    color: "#FFFFFF",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  sectionListItemLink: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
  hasDependenciesLbl: {
    fontSize: 13,
    color: "#3870ef",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    textDecorationThickness: "2px",
  },
  parentMenuItem: {
    display: "flex",
  },
}));

export const PageInfoPanel = ({
  addSection,
  pageNumber,
  pageConfig,
  templateConfig,
  setSelectedSection,
  selectedSection,
  setSelectedItem,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dynamicRefMenuAnchorEl, setDynamicRefMenuAnchorEl] = React.useState(null);
  const [equipmentMenuAnchorEl, setEquipmentMenuAnchorEl] = React.useState(null);
  const [offersMenuAnchorEl, setOffersMenuAnchorEl] = React.useState(null);
  const [applicantMenuAnchorEl, setApplicantMenuAnchorEl] = React.useState(null);
  const [сoApplicantMenuAnchorEl, setCoApplicantMenuAnchorEl] = React.useState(null);
  const isMainMenuopen = Boolean(anchorEl);
  const isDynamicRefNestedMenuOpen = Boolean(dynamicRefMenuAnchorEl);
  const isEquipmentNestedMenuOpen = Boolean(equipmentMenuAnchorEl);
  const isOffersNestedMenuOpen = Boolean(offersMenuAnchorEl);
  const isApplicantNestedMenuOpen = Boolean(applicantMenuAnchorEl);
  const isCoApplicantNestedMenuOpen = Boolean(сoApplicantMenuAnchorEl);

  const handleOpenMenuClick = (event, setFn) => {
    setFn(event.currentTarget);
  };
  const handleClose = blockType => {
    switch (blockType) {
      case "references":
        addSection(
          {
            title: {
              id: uuidv4(),
              config: {
                text: "references",
                fontSize: "20px",
                textAlign: "center",
                displayLabel: {
                  en: "Business References",
                  es: "Referencias de la Empresa",
                },
              },
            },
            rulesSatisfactionType: "all",
            rules: [],
            fields: [],
            config: {},
          },
          pageNumber
        );
        break;
      case "simple":
        addSection(getSimpleBlockConfiguration(), pageNumber);
        break;
      case "owners":
        addSection(
          {
            title: {
              id: uuidv4(),
              type: "owners-array",
              config: {
                text: "ownershipInformation",
                fontSize: "20px",
                textAlign: "center",
                displayLabel: {
                  en: "Ownership Information",
                  es: "Información sobre la propiedad",
                },
              },
            },
            config: {},
            rulesSatisfactionType: "all",
            rules: [{ targetName: "ssnExempt", evalType: "eq", evalValue: "no" }],
            fields: [],
          },
          pageNumber
        );
        break;
      case "signature":
        addSection(
          {
            title: {
              id: uuidv4(),
              type: "signatures-simple",

              config: {
                text: "authAndAcceptance",
                fontSize: "20px",
                textAlign: "center",
                displayLabel: {
                  en: "Authorization And Acceptance",
                  es: "Autorización y Aceptación",
                },
              },
            },
            rulesSatisfactionType: "all",
            rules: [
              {
                targetName: "ssnExempt",
                evalType: "eq",
                evalValue: "no",
              },
              {
                targetName: "ownersQuantity",
                evalType: "eq",
                evalValue: 1,
              },
            ],
            config: {},
          },
          pageNumber
        );
        break;
    }

    setAnchorEl(null);
  };
  const handleAddBlock = (getBlockConfigurationFn, blockType) => {
    const blockConfiguration = getBlockConfigurationFn(blockType, templateConfig);
    addSection(blockConfiguration, pageNumber);
    closeAllMenus();
  };

  const closeAllMenus = () => {
    setAnchorEl(null);
    setDynamicRefMenuAnchorEl(null);
    setEquipmentMenuAnchorEl(null);
    setApplicantMenuAnchorEl(null);
    setCoApplicantMenuAnchorEl(null);
  };

  return (
    <Grid container alignItems="center" className={classes.pageInfoPanel}>
      <Grid container alignItems="center" item xs={10}>
        <Grid>
          <Button
            id="basic-button"
            aria-controls={isMainMenuopen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isMainMenuopen ? "true" : undefined}
            onClick={event => handleOpenMenuClick(event, setAnchorEl)}>
            Create Block
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isMainMenuopen}
            onClose={() => {
              handleClose("");
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem
              onClick={() => {
                handleClose("simple");
              }}>
              Simple
            </MenuItem>

            <MenuItem
              id="applicant-information-button"
              aria-controls={isApplicantNestedMenuOpen ? "applicant-information-nested-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isApplicantNestedMenuOpen ? "true" : undefined}>
              <Typography
                onClick={event => handleOpenMenuClick(event, setApplicantMenuAnchorEl)}
                className={classes.parentMenuItem}>
                Applicant Information
                <KeyboardArrowRightIcon />
              </Typography>
              <Menu
                id="applicant-information-nested-menu"
                anchorEl={applicantMenuAnchorEl}
                open={isApplicantNestedMenuOpen}
                onClose={closeAllMenus}
                MenuListProps={{
                  "aria-labelledby": "applicant-information-button",
                }}>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.contactInformation)
                  }>
                  Contact Information
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.currentAddress)}>
                  Current Address
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.previousAddress)}>
                  Previous Address
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.employmentInformation)
                  }>
                  Employment Information
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.nearestRelative)}>
                  Nearest Relative
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getApplicantBlockConfiguration, applicantBlockTypes.identityVerification)
                  }>
                  Identity Verification
                </MenuItem>
              </Menu>
            </MenuItem>

            <MenuItem
              id="co-applicant-information-button"
              aria-controls={isCoApplicantNestedMenuOpen ? "co-applicant-information-nested-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isCoApplicantNestedMenuOpen ? "true" : undefined}>
              <Typography
                onClick={event => handleOpenMenuClick(event, setCoApplicantMenuAnchorEl)}
                className={classes.parentMenuItem}>
                Co-Applicant Information
                <KeyboardArrowRightIcon />
              </Typography>
              <Menu
                id="co-applicant-information-nested-menu"
                anchorEl={сoApplicantMenuAnchorEl}
                open={isCoApplicantNestedMenuOpen}
                onClose={closeAllMenus}
                MenuListProps={{
                  "aria-labelledby": "co-applicant-information-button",
                }}>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.contactInformation)
                  }>
                  Contact Information
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.currentAddress)
                  }>
                  Current Address
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.previousAddress)
                  }>
                  Previous Address
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.employmentInformation)
                  }>
                  Employment Information
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.nearestRelative)
                  }>
                  Nearest Relative
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddBlock(getCoApplicantBlockConfiguration, coApplicantBlockTypes.identityVerification)
                  }>
                  Identity Verification
                </MenuItem>
              </Menu>
            </MenuItem>

            <MenuItem onClick={() => handleAddBlock(getDocumentSignBlockConfiguration)}>Document Sign</MenuItem>

            <MenuItem
              id="dynamic-references-button"
              aria-controls={isDynamicRefNestedMenuOpen ? "dynamic-references-nested-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isDynamicRefNestedMenuOpen ? "true" : undefined}>
              <Typography
                onClick={event => handleOpenMenuClick(event, setDynamicRefMenuAnchorEl)}
                className={classes.parentMenuItem}>
                Dynamic References
                <KeyboardArrowRightIcon />
              </Typography>
              <Menu
                id="dynamic-references-nested-menu"
                anchorEl={dynamicRefMenuAnchorEl}
                open={isDynamicRefNestedMenuOpen}
                onClose={closeAllMenus}
                MenuListProps={{
                  "aria-labelledby": "dynamic-references-button",
                }}>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.customer)}>
                  Customer Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.trade)}>
                  Trade Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.bank)}>
                  Bank Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.loan)}>
                  Loan Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.loc)}>
                  LOC Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.insurance)}>
                  Insurance Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.employment)}>
                  Employment - Current Reference
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.employmentFuture)}>
                  Employment - Future Reference
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.employmentPast)}>
                  Employment - Past Reference
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddBlock(getReferenceBlockConfiguration, referenceTypes.corporateGuarantor)}>
                  Corporate Guarantor
                </MenuItem>
              </Menu>
            </MenuItem>

            <MenuItem
              id="equipment-button"
              aria-controls={isEquipmentNestedMenuOpen ? "equipment-nested-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isEquipmentNestedMenuOpen ? "true" : undefined}>
              <Typography
                onClick={event => handleOpenMenuClick(event, setEquipmentMenuAnchorEl)}
                className={classes.parentMenuItem}>
                Equipment
                <KeyboardArrowRightIcon />
              </Typography>
              <Menu
                id="equipment-nested-menu"
                anchorEl={equipmentMenuAnchorEl}
                open={isEquipmentNestedMenuOpen}
                onClose={closeAllMenus}
                MenuListProps={{
                  "aria-labelledby": "equipment-button",
                }}>
                {/*                 <MenuItem
                  onClick={() => handleAddBlock(getEquipmentDetailsBlockConfiguration, equipmentTypes.viewOnly)}
                >
                  Equipment View Only
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleAddBlock(getEquipmentDetailsBlockConfiguration, equipmentTypes.purchase)}>
                  Equipment Purchase
                </MenuItem>
                <MenuItem onClick={() => handleAddBlock(getEquipmentDetailsBlockConfiguration, equipmentTypes.tradeIn)}>
                  Trade-In
                </MenuItem>
              </Menu>
            </MenuItem>

            <MenuItem
              id="offers-button"
              aria-controls={isOffersNestedMenuOpen ? "offers-nested-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOffersNestedMenuOpen ? "true" : undefined}>
              <Typography
                onClick={event => handleOpenMenuClick(event, setOffersMenuAnchorEl)}
                className={classes.parentMenuItem}>
                Offers
                <KeyboardArrowRightIcon />
              </Typography>
              <Menu
                id="offers-nested-menu"
                anchorEl={offersMenuAnchorEl}
                open={isOffersNestedMenuOpen}
                onClose={closeAllMenus}
                MenuListProps={{
                  "aria-labelledby": "offers-button",
                }}>
                <MenuItem onClick={() => handleAddBlock(getOffersBlockConfiguration, offersTypes.workingCapital)}>
                  Working Capital
                </MenuItem>
              </Menu>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose("owners");
              }}>
              Ownership Info
            </MenuItem>

            <MenuItem onClick={() => handleAddBlock(getDynamicSignatureBlockConfiguration)}>
              Dynamic Signatures
            </MenuItem>

            <MenuItem onClick={() => handleAddBlock(getPersonalGuaranteeSignaturesBlockConfiguration)}>
              Personal Guarantee Signatures
            </MenuItem>

            <MenuItem onClick={() => handleAddBlock(getMarketingCommunicationBlockConfiguration)}>
              Marketing Communication Authorization
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose("references");
              }}>
              References (Legacy)
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose("signature");
              }}>
              Signatures (Legacy)
            </MenuItem>
          </Menu>
        </Grid>
        <Grid className={classes.sectionList}>
          {pageConfig.sections &&
            pageConfig.sections.map((section, index) => {
              const sectionIsNamed = !_.isEmpty(_.get(section, "title.config.displayLabel.en", ""));
              if (sectionIsNamed) {
                return (
                  <Link
                    key={`${section.title}${index}`}
                    style={{
                      background: section.title.id === selectedSection ? theme.palette.primary.main : "grey",
                    }}
                    className={classes.sectionListItem}
                    onClick={() => {
                      if (index === 0) {
                        scroll.scrollToTop();
                      }
                      setSelectedSection(section.title.id);
                    }}
                    spy={true}
                    smooth={true}
                    duration={500}
                    to={index !== 0 ? `${section.title.id}` : ""}>
                    {_.get(section, "title.config.displayLabel.en", "")}
                  </Link>
                );
              } else {
                return null;
              }
            })}
        </Grid>
      </Grid>
      <Grid item xs={2} container spacing={1} direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Fab
            color="primary"
            variant="extended"
            aria-label="edit page"
            size="small"
            onClick={() => setSelectedItem({ ...pageConfig, extras: { page: pageNumber } })}>
            <EditIcon /> Edit Page
          </Fab>
        </Grid>
        {_.size(pageConfig.rules) ? (
          <Grid item>
            <span>
              <RuleTooltipDescription rules={pageConfig.rules} rulesSatisfactionType={pageConfig.rulesSatisfactionType}>
                <Button className={classes.hasDependenciesLbl}>Dependent Page</Button>
              </RuleTooltipDescription>
            </span>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
