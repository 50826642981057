import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: "pointer",
    fontSize: "48px",
    //marginBottom: "16px",
    color: "green",
  },
}));

export function InsertionContainer({ type, onClickFn }) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {/* <h3>Move {type} here</h3> */}
      <AddCircleOutlineIcon className={classes.icon} onClick={onClickFn} />
    </Box>
  );
}
