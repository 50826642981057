import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, Divider } from "@mui/material";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Addon } from "./Addon";
import { ProductLogo } from "./ProductLogo";
import { formatCurrency } from "../../../../../utils";
import { ProductCatalogDetails } from "./ProductCatalogDetails";
import { ProposalProductsCardTypeChip } from "./ProposalProductsCardTypeChip";
import { useProductCatalogDetails } from "../../../hooks/useProductCatalogDetails";

interface Props {
  primaryColor: string;
  product: ProposalProduct;
}

export const MenuOptionConfirmationModalProductCard = ({ product, primaryColor }: Props) => {
  const classes = useStyles();

  const { productCategory, productCatalogDetailsKeysWithData } = useProductCatalogDetails(product);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const aftermarketProductCatalogDetails = product.aftermarketProduct?.aftermarketProductCatalogDetails!;

  return (
    <Box className={classes.productContainer}>
      <Grid container justifyContent="space-between" alignItems="center">
        <ProductLogo product={product} />

        {productCategory && <ProposalProductsCardTypeChip productCategory={productCategory} />}
      </Grid>

      {!!productCatalogDetailsKeysWithData.length ? (
        <Accordion className={classes.accordion} expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography variant="subtitle2">{product.title}</Typography>

            <Typography className={classes.accordionSecondaryHeading} variant="button">
              {isExpanded ? "Less Details" : "More Details"}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Box style={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
              {productCatalogDetailsKeysWithData.map(key => (
                <ProductCatalogDetails key={key} catalogDetails={aftermarketProductCatalogDetails[key]} />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography variant="subtitle2">{product.title}</Typography>
      )}

      <Divider />

      {product.addons?.map((addon: any) => <Addon key={addon.title} addon={addon} primaryColor={primaryColor} />)}

      {product.retailCost > 0 && (
        <Typography variant="subtitle2" align="center">
          Retail Price: {formatCurrency(product.retailCost)}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => {
  return {
    accordion: {
      "&.MuiAccordion-root.MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",

        "&:before": {
          display: "none",
        },
      },
    },
    accordionSummary: {
      "&.MuiAccordionSummary-root.MuiButtonBase-root": {
        padding: 0,
        marginBottom: 6,
        minHeight: "auto",
      },

      "&.MuiAccordionSummary-root .MuiAccordionSummary-content": {
        margin: 0,
        alignItems: "center",
      },
    },
    accordionSecondaryHeading: {
      "&.MuiTypography-root": {
        marginLeft: "auto",
        lineHeight: "normal",
        color: theme.palette.primary.main,
      },
    },
    productContainer: {
      gap: "0.5rem",
      display: "flex",
      padding: "0.5rem",
      background: "#F5F5F5",
      flexDirection: "column",
    },
  };
});
